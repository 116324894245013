import React, { useState } from "react";
import chart from "../../assets/Icons/Chart.svg";
import graph from "../../assets/Icons/activeGraph.svg";
import map from "../../assets/Icons/map.svg";
import mapActive from "../../assets/Icons/map-active.svg";
import pieInactive from "../../assets/Icons/pie-inactive.svg";
import chartActive from "../../assets/Icons/chart-active.svg";
import MapComponent from "./components/map";
import ChartWithPie from "./components/pieChart";
import ChartWithBar from "./components/barChart";

/**
 * Location component displays location-based data using a map, bar chart, or pie chart.
 *
 * @component
 * @example
 * // Example usage of Location component
 * <Location
 *   data={{
 *     totalAccounts: {
 *       accountsByLocation: [
 *         { location: "City1", count: 10 },
 *         { location: "City2", count: 20 },
 *         // ...more locations
 *       ],
 *     },
 *   }}
 *   countrySelected="CountryName"
 * />
 *
 * @param {Object} data - Data object containing location-based information.
 * @param {string} countrySelected - The selected country for which location data is displayed.
 *
 * @returns {JSX.Element} The rendered Location component.
 */

const Location = ({ data, countrySelected }) => {
  const [chartType, setChartType] = useState("MAP");

  const getColorByIndex = (index) => {
    const colors = ["#EBC9F1", "#C3E9FC", "#BDD6AB", "#F6C0B8", "#FFE8AE"];
    const colorIndex = index % colors.length;
    return colors[colorIndex];
  };

  const colors = data?.totalAccounts?.accountsByLocation?.map((_, index) =>
    getColorByIndex(index)
  );

  const renderChartComponent = () => {
    const chartData = data?.totalAccounts?.accountsByLocation;
    switch (chartType) {
      case "MAP":
        return (
          <MapComponent
            colors={colors}
            data={chartData}
            getColorByIndex={getColorByIndex}
            countrySelected={countrySelected}
          />
        );
      case "PIE":
        return (
          <ChartWithPie
            colors={colors}
            data={chartData}
            dataKey={"count"}
            height={400}
          />
        );
      default:
        return (
          <ChartWithBar
            colors={colors}
            data={chartData}
            YAxisKey={"location"}
            BarKey={"count"}
          />
        );
    }
  };

  return (
    <div
      className={`bg-white ${
        chartType === "BAR" ? "h-155" : "h-auto"
      }  rounded-20 flex flex-col w-full pb-5`}
    >
      <div className="flex justify-between p-5">
        <span className="text-black-100 font-ManropeBold text-ft5">
          Location
        </span>
        <div className="flex gap-2.5">
          <img
            src={chartType === "MAP" ? mapActive : map}
            alt="chart"
            onClick={() => setChartType("MAP")}
            className="cursor-pointer"
          />
          <img
            src={chartType === "BAR" ? chartActive : chart}
            alt="chart"
            onClick={() => setChartType("BAR")}
            className="cursor-pointer"
          />
          <img
            src={chartType === "PIE" ? graph : pieInactive}
            alt="graph"
            onClick={() => setChartType("PIE")}
            className="cursor-pointer"
          />
        </div>
      </div>

      {renderChartComponent()}
    </div>
  );
};

export default Location;
