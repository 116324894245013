import Cookies from "universal-cookie";
import utility from "../utility";

const cookies = new Cookies();

export const sessionManager = {
  setDataInCookies,
  getDataFromCookies,
  removeDataFromCookies,
};

function setDataInCookies(data, key, expires = 30) {
  const expireDate = utility.addDays(new Date(), expires);
  cookies.set(key, JSON.stringify(data), { path: "/", expires: expireDate });
}

function getDataFromCookies(key) {
  return cookies.get(key);
}

function removeDataFromCookies(key) {
  cookies.remove(key, { path: "/" });
}
