import React, { useState } from "react";
import calender from "../../../assets/Icons/calender.svg";
import Calendar from "react-calendar";
import Time from "../../../assets/Icons/Time.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import WarningPopup from "../../../common/warningPopup";

/**
 * React component for the general information of creating a new token promotion.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Function} props.setSteps - Function to set the current step.
 * @param {Function} props.setState - Function to set the state of the component.
 * @param {Object} props.state - State object containing token promotion details.
 * @param {Function} props.setUntilSelectTime - Function to set the "Until" select time.
 * @param {string} props.untilSelectTime - "Until" select time state.
 * @param {Function} props.setSelectTime - Function to set the select time.
 * @param {string} props.selectTime - Select time state.
 * @param {Function} props.setUntilDate - Function to set the "Until" date.
 * @param {Date} props.untilDate - "Until" date state.
 * @param {Function} props.setDateStart - Function to set the start date.
 * @param {Date} props.dateStart - Start date state.
 * @param {Function} props.setEndEditTime - Function to set the "End Edit Time" state.
 * @param {Function} props.setStartEditTime - Function to set the "Start Edit Time" state.
 * @param {Function} props.setByDefaultEndTime - Function to set the "By Default End Time" state.
 * @param {Function} props.setByDefaultStartTime - Function to set the "By Default Start Time" state.
 * @returns {JSX.Element} JSX representation of the GeneralToken component.
 *
 * @example
 * // Usage of the GeneralToken component
 * <GeneralToken
 *   setSteps={setSteps}
 *   setState={setState}
 *   state={state}
 *   setUntilSelectTime={setUntilSelectTime}
 *   untilSelectTime={untilSelectTime}
 *   setSelectTime={setSelectTime}
 *   selectTime={selectTime}
 *   setUntilDate={setUntilDate}
 *   untilDate={untilDate}
 *   setDateStart={setDateStart}
 *   dateStart={dateStart}
 *   setEndEditTime={setEndEditTime}
 *   setStartEditTime={setStartEditTime}
 *   setByDefaultStartTime={setByDefaultStartTime}
 *   setByDefaultEndTime={setByDefaultEndTime}
 * />
 */

const GeneralToken = ({
  setSteps,
  setState,
  state,
  setUntilSelectTime,
  untilSelectTime,
  setSelectTime,
  selectTime,
  setUntilDate,
  untilDate,
  setDateStart,
  dateStart,
  setEndEditTime,
  setStartEditTime,
  setByDefaultEndTime,
  setByDefaultStartTime,
}) => {
  const [toggleCalender, setToggleCalender] = useState(false);
  const [toggleUntilCalender, setToggleUntilCalender] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const history = useHistory();
  const [error, setError] = useState({});

  const handleDiscardChanges = () => {
    setShowWarning(false);
    history.push("/promo-and-rewards");
  };

  return (
    <div className="flex flex-col gap-7.5">
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10">
        <p className="text-black-100 font-ManropeMedium text-ft3">
          Name of promotion
        </p>
        <input
          type="text"
          value={state?.name}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue.length <= 50) {
              setError((prev) => ({ ...prev, nameError: false }));
            } else {
              setError((prev) => ({ ...prev, nameError: true }));
            }
            setState((prev) => ({
              ...prev,
              name: e.target.value,
            }));
          }}
          className="h-16.25 w-1/2 px-5 rounded-full bg-grey-50 focus:outline-none text-black-100 font-ManropeRegular text-ft3"
        />
        {error?.nameError && (
          <span className=" text-red-50">Name can't be more than 50</span>
        )}
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10">
        <p className="text-black-100 font-ManropeMedium text-ft3">
          Amount, Dollars
        </p>
        <input
          type="text"
          value={state?.amount}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue === "" || /^[0-9]*$/.test(inputValue)) {
              setState((prev) => ({
                ...prev,
                amount: inputValue === "" ? "" : parseInt(inputValue),
              }));
            }
          }}
          className="px-5 h-16.25 w-1/2 rounded-full bg-grey-50 text-black-100 font-ManropeRegular text-ft3 focus:outline-none"
        />
      </div>
      <hr className="text-grey-50" />
      <div className="flex pl-10 gap-3.75">
        <div className="flex flex-col relative">
          <p className="text-black-100 font-ManropeMedium text-ft3">
            Start Date
          </p>
          <div>
            <div
              onClick={() => setToggleCalender(!toggleCalender)}
              className="flex justify-between px-6.25 py-5 rounded-full bg-grey-50 w-50 cursor-pointer"
            >
              <p>
                {dateStart.getDate() +
                  "." +
                  (dateStart.getMonth() + 1) +
                  "." +
                  dateStart.getFullYear()}
              </p>
              <img src={calender} alt={calender} />
            </div>
            {toggleCalender && (
              <Calendar
                onChange={(value) => {
                  setDateStart(value);
                  setToggleCalender(false);
                  setStartEditTime(false);
                  setByDefaultStartTime(false);
                }}
                value={dateStart}
                className="absolute top-25 left-0 bg-white w-99.5 rounded-20 shadow-inner border-0.5 border-black-10 font-ManropeRegular text-ft3 text-black-100 z-10"
              />
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-black-100 font-ManropeMedium text-ft3">Time</p>
          <div className="flex relative items-center justify-between px-6.25 py-4 rounded-full bg-grey-50 w-50">
            <input
              type="time"
              value={selectTime}
              onChange={(e) => setSelectTime(e.target.value)}
              className="rounded-full px-2 py-1 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 w-full"
            />
            <img
              src={Time}
              alt={"time"}
              className=" absolute right-8 pointer-events-none"
            />
          </div>
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex px-10 gap-3.75">
        <div className="flex flex-col relative cursor-pointer">
          <p className="text-black-100 font-ManropeMedium text-ft3">
            Until Date (optional)
          </p>
          <div>
            <div
              onClick={() => setToggleUntilCalender(!toggleUntilCalender)}
              className="flex justify-between px-6.25 py-5 rounded-full bg-grey-50 w-50"
            >
              <p>
                {untilDate.getDate() +
                  "." +
                  (untilDate.getMonth() + 1) +
                  "." +
                  untilDate.getFullYear()}
              </p>
              <img src={calender} alt={calender} />
            </div>
            {toggleUntilCalender && (
              <Calendar
                onChange={(value) => {
                  setUntilDate(value);
                  setToggleUntilCalender(false);
                  setEndEditTime(false);
                  setByDefaultEndTime(false);
                }}
                value={untilDate}
                className="absolute top-25 left-0 bg-white w-99.5 rounded-20 shadow-inner border-0.5 border-black-10 font-ManropeRegular text-ft3 text-black-100 z-10"
              />
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-black-100 font-ManropeMedium text-ft3">Time</p>
          <div className="flex relative items-center justify-between px-6.25 py-4 rounded-full bg-grey-50 w-50">
            <input
              type="time"
              value={untilSelectTime}
              onChange={(e) => setUntilSelectTime(e.target.value)}
              className="rounded-full px-2 py-1 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 w-full"
            />
            <img
              src={Time}
              alt={"time"}
              className=" absolute right-8 pointer-events-none"
            />
          </div>
        </div>
      </div>
      <hr className="text-grey-50" />
      <footer className="flex justify-end items-end pr-7.5 gap-5 rounded-20 bg-white">
        <button
          onClick={() => setShowWarning(true)}
          className="flex justify-center items-center rounded-full border border-solid border-black-20 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
        >
          Cancel
        </button>

        <button
          onClick={() => setSteps(1)}
          disabled={
            !state?.name ||
            !state?.amount ||
            state?.name?.trim() === "" ||
            error?.nameError
          }
          className={`flex justify-center items-center rounded-full border border-solid border-transparent ${
            !state?.name ||
            !state?.amount ||
            state?.name?.trim() === "" ||
            error?.nameError
              ? "bg-grey-50"
              : "bg-orange-50"
          }  py-5 w-55 text-black-100 font-ManropeBold text-ft2`}
        >
          Create
        </button>
      </footer>
      {showWarning && (
        <WarningPopup
          title="Discard changes ?"
          description="All entered data will be deleted"
          setToggle={setShowWarning}
          handleActivateUser={handleDiscardChanges}
        />
      )}
    </div>
  );
};

export default GeneralToken;
