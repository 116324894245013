import React, { useEffect, useState } from "react";
import PollType from "../../../assets/Icons/poll-type-icon.svg";
import defaultImage from "../../../assets/Icons/signupProfileSmall.png";
import utility from "../../../utility";
import CoverMedia from "./coverMedia";
import LearnMore from "./learnMore";

/**
 * Renders a survey preview or information component.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setStartPoll - Function to start the survey.
 * @param {Object} props.data - Survey data.
 * @param {Object} props.account - User account information.
 * @returns {JSX.Element} SurveyHome component.
 */

const SurveyHome = ({ setStartPoll, data, account }) => {
  const [isLearnMore, setIsLearnMore] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const coverImage =
    data?.post?.coverImage !== undefined ? [data?.post?.coverImage] : undefined;

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = Date.now();
      const timeDifference = data?.info?.activePeriod?.endDate - currentTime;
      const remainingMilliseconds = Math.max(0, timeDifference);

      const remainingDays = Math.floor(
        remainingMilliseconds / (1000 * 60 * 60 * 24)
      );
      const remainingHours = Math.floor(
        (remainingMilliseconds / (1000 * 60 * 60)) % 24
      );
      const remainingMinutes = Math.floor(
        (remainingMilliseconds / (1000 * 60)) % 60
      );

      setTimeLeft({
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
      });
    };

    calculateTimeLeft();

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 60000);

    return () => clearInterval(timer);
  }, [data]);

  const date = `${timeLeft.days ? timeLeft.days : ""}${
    timeLeft.days ? "d" : ""
  } ${timeLeft.hours}hr ${timeLeft?.days ? "" : timeLeft.minutes}${
    timeLeft?.days ? "" : "m"
  }`;

  const hasMedia = data?.post?.coverImage.url || data?.media?.length > 0;

  return (
    <div className="bg-white  relative flex flex-col h-full w-106.25 z-50 p-5 ">
      {!isLearnMore ? (
        <div className="bg-black-100 overflow-y-auto overflow-x-hidden scrollbar-hide w-full h-full">
          {data?.post?.coverImage.url || data?.media?.length > 0 ? (
            <div className="flex justify-center items-center h-35per">
              <CoverMedia
                data={coverImage || data?.media}
                name={data?.creator?.fullName || account?.fullName}
                profilePhoto={
                  data?.creator?.profilePhoto || account?.profilePhoto
                }
                endDate={`${date} left`}
              />
            </div>
          ) : (
            <div className="px-5 pt-5 w-full h-10per">
              {" "}
              <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-2.5">
                  <img
                    src={
                      data?.creator?.profilePhoto ||
                      account?.profilePhoto ||
                      defaultImage
                    }
                    alt="user"
                    className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
                  />
                  <p className="font-ManropeBold text-ft2 text-center text-white">
                    {data?.creator?.fullName || account?.fullName || "Pollpapa"}
                  </p>
                </div>
                <div className="flex items-center justify-center w-auto p-2 rounded-20  bg-white backdrop-blur-xl bg-opacity-10">
                  <p className="font-ManropeBold text-ft2 w-full text-white">
                    {date}
                    left
                  </p>
                </div>
              </div>
            </div>
          )}
          <div
            className={`p-5 flex flex-col ${
              hasMedia ? "h-65per" : "h-90per"
            }  justify-between`}
          >
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-3.75 w-full">
                <div className="flex gap-1">
                  <div className="flex gap-1">
                    {data?.channels?.map((item, index) => (
                      <p
                        key={index}
                        className="flex items-center justify-center  rounded-20 text-black-100 font-ManropeMedium text-ft0 px-1.5"
                        style={{
                          backgroundColor: utility.getRandomColors(index),
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="pt-1 gap-2 inline-block relative">
                  <span className=" text-ft42 font-ManropeBold text-white ">
                    {" "}
                    {data?.post?.surveyName || data?.question}
                  </span>{" "}
                  <span className="inline-flex gap-2 ml-2 mt-1 items-center px-2 py-1 border-1 rounded-20 w-max border-white border-opacity-10 text-ft0 font-ManropeMedium text-white">
                    <img src={PollType} alt="Icon" className="w-4 h-4" />{" "}
                    {utility.capitalizeFirstLetter(
                      data?.post?.surveyType || data?.pollType
                    )}{" "}
                    {utility.capitalizeFirstLetter(
                      data?.type || data?.questionType
                    )}
                  </span>
                </div>
              </div>
              {(data?.post?.description || data?.description) && (
                <div
                  className="cursor-pointer"
                  onClick={() => setIsLearnMore(true)}
                >
                  <span className="font-ManropeBold text-ft2 text-orange-50">
                    Learn more
                  </span>
                  <div className="px-2 py-1 bg-black-50 rounded-full mt-3 w-max text-ft0 font-ManropeMedium text-white">
                    <span>
                      {data?.post?.polls?.length || data?.polls?.length}{" "}
                      questions
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full h-16.25 rounded-full bg-orange-50">
              <button
                onClick={() => setStartPoll(0)}
                className="text-ft2 w-full h-full font-ManropeBold text-black-50 flex justify-center items-center"
              >
                Start
              </button>
            </div>
          </div>
        </div>
      ) : (
        <LearnMore
          description={data?.post?.description || data?.description}
          setIsLearnMore={setIsLearnMore}
        />
      )}
    </div>
  );
};

export default SurveyHome;
