/**
 * @module ProductList
 * @description
 * Module for managing and rendering ProductList.
 *
 * @example
 * // Usage in a React functional component
 * import ProductList from "./ProductList";
 *
 * const MyComponent = () => {
 *   return <ProductList />;
 * };
 *
 * @returns {React.Component} The ProductList component
 */

import React, { useEffect, useState } from "react";
import Table from "../../common/table";
import placeholder from "../../assets/Icons/Placeholder.svg";
import { UserService } from "../../services";
import utility from "../../utility";

/**
 * Functional component for rendering and managing ProductList.
 * @returns {JSX.Element} ProductList component
 */

const ProductList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const headers = [
    { name: "Product Name" },
    { name: "Product Id" },
    { name: "Product Type" },
    { name: "Price (USD)" },
    { name: "Min" },
    { name: "Max" },
  ];

  /**
   * Asynchronously retrieves additional fields and updates the state with the response.
   *
   */
  const getProductList = async () => {
    setLoading(true);

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getAppleProducts()
      );
      const [err, res] = await utility.parseResponse(
        new UserService().getGoogleProducts()
      );
      if (error || !response || err || !res) return;
      const data = [...response, ...res];
      data.sort((a, b) => b.priceInUsd - a.priceInUsd);
      setData(data);

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductList();
    // eslint-disable-next-line
  }, []);

  /**
   * Renders the table body based on the additional list.
   *
   * @return {JSX.Element} The table body JSX element
   */
  const renderTableBody = () => {
    if (data?.length > 0) {
      return data?.map((item) => (
        <tr
          key={item?._id}
          className="hover:bg-grey-350 relative cursor-pointer"
        >
          <td className="py-5 px-5 cursor-pointer">
            <div className="w-50 truncate font-ManropeMedium text-ft2 text-black-50">
              {item.productName}
            </div>
          </td>
          <td className="py-5 px-5 cursor-pointer">
            <div className="w-50 truncate font-ManropeMedium text-ft2 text-black-50">
              {item.productId}
            </div>
          </td>
          <td>
            <div className="font-ManropeMedium text-ft2 text-black-50 w-50">
              {utility.formatText(item?.type)}
            </div>
          </td>
          <td>
            <div className="font-ManropeMedium text-ft2 text-black-50 w-50">
              {item?.priceInUsd && "$"} {item?.priceInUsd}
            </div>
          </td>
          <td>
            <div className="font-ManropeMedium text-ft2 text-black-50 w-40 truncate">
              {item?.min}
            </div>
          </td>
          <td>
            <div className="font-ManropeMedium text-ft2 text-black-50 w-40 truncate">
              {item?.max || "3000+"}
            </div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={7}>
            <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
              <img src={placeholder} alt="placeholder" />
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="w-full h-full">
      <div className={`flex items-center bg-white p-5 rounded-20 h-21.25`}>
        <span className="text-ft5 text-black-100 font-ManropeBold">
          In app products
        </span>
      </div>
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        loading={loading}
        showPagination={false}
      />
    </div>
  );
};

export default ProductList;
