import React, { useEffect, useState } from "react";
import Table from "../../common/table";
import placeholder from "../../assets/Icons/Placeholder.svg";
import left from "../../assets/Icons/Left.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import BlockChainService from "../../services/blockChainService";
import utility from "../../utility";

/**
 * Component for displaying transaction history with sorting and pagination.
 *
 * @component
 * @example
 * <ViewTransactions />
 */
const ViewTransactions = () => {
  const headers = [
    { name: " Tnx ID", endDate: true },
    { name: "Date and time", startDate: true },
    { name: "" },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [sortDate, setSortDate] = useState("");
  const [sortTxn, setSortTxn] = useState("");

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * Handles page change events.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getData = async () => {
    setLoading(true);

    const requestData = {
      limit: pageSize,
      skip: currentPage * pageSize,
      sort: -1,
    };

    if (sortDate || sortTxn) {
      requestData.sortingKey = sortDate || sortTxn;
    }

    try {
      const [error, response] = await utility.parseResponse(
        new BlockChainService().getTokenList(requestData)
      );

      if (error || !response) return;
      setData(response?.tokens);
      setTotalDocs(response.totalCount);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [currentPage, sortDate, sortTxn]);

  /**
   * Renders the body of the table based on fetched transaction data.
   * @returns {JSX.Element} - Rendered table body rows.
   */
  const renderTableBody = () => {
    if (data.length > 0) {
      return data.map((item) => (
        <tr
          key={item._id}
          className="hover:bg-grey-350 relative cursor-pointer"
        >
          <td className="py-5 px-5 w-35per font-ManropeMedium text-ft2 text-black-50">
            <div className="w-85 truncate">{item?.transactionHash || "-"}</div>
          </td>
          <td className="font-ManropeMedium py-5 text-ft2 text-black-50">
            <div className="w-50 truncate">
              {" "}
              {moment(item.addedOn).format("hh:mm A DD MMM[,] YYYY")}
            </div>
          </td>
          <td className="font-ManropeMedium text-ft2 text-black-50">
            <div className="w-30 truncate"></div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={3}>
            <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
              <img src={placeholder} alt="placeholder" className="mt-10per" />
            </div>{" "}
          </td>
        </tr>
      );
    }
  };
  return (
    <div className="flex py-5 w-full h-full overflow-y-auto pr-12.5 gap-5">
      <div className="w-full">
        <div className="bg-white p-5 rounded-20 h-21.25 gap-5 flex items-center">
          <Link to="/token">
            <img src={left} alt="left" className="cursor-pointer" />
          </Link>
          <p className="text-black-100 font-ManropeBold text-ft5">
            Transactions
          </p>
        </div>
        <Table
          headers={headers}
          tableBody={renderTableBody()}
          setPageSize={setPageSize}
          pageSize={pageSize}
          loading={loading}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages - 1}
          totalDocs={totalDocs}
          setCurrentPage={setCurrentPage}
          setStartDate={setSortDate}
          tagStart={"addedOn"}
          startDate={sortDate}
          endDate={sortTxn}
          setEndDate={setSortTxn}
          tagEnd={"transactionHash"}
        />
      </div>
    </div>
  );
};

export default ViewTransactions;
