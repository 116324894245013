import React, { useMemo, useState } from "react";
import { ReactComponent as Close } from "../assets/Icons/close.svg";
import chevronUp from "../assets/Icons/chevronUp.svg";
import chevrondown from "../assets/Icons/Chevrondown.svg";
import checkMark from "../assets/Icons/checkMark.svg";
import {
  reasonDisablePost,
  successMessage,
  timeSuspend,
  toastType,
} from "../constant/commonArray";
import calender from "../assets/Icons/calender.svg";
import Time from "../assets/Icons/Time.svg";
import Popover from "@mui/material/Popover";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { InputAdornment, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { UserService } from "../services";
import ShowToast from "./showToast";
import { useSelector } from "react-redux";
import utility from "../utility";

/**
 * DisablePopUp component for disabling posts and suspending users.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.handleDisable - Function to handle the disable action.
 * @param {Function} props.setDisablePopUp - Function to set the disable popup state.
 * @param {Function} props.setState - Function to set the component state.
 * @param {Function} props.setSelectedTime - Function to set the selected time for suspension.
 * @param {boolean} props.selectTime - Selected time for suspension.
 * @param {Object} props.state - Component state containing disable and suspension details.
 * @param {Date} props.date - Date for custom suspension.
 * @param {Function} props.setDate - Function to set the date for custom suspension.
 * @param {string} props.name - The title of the popup (default: "Disable post").
 * @returns {JSX.Element} DisablePopUp component.
 */

const DisablePopUp = ({
  setToggle = () => {},
  name = " Disable post",
  keyName,
  selectedId,
  getDetails,
  updateUser = () => {},
  suspendedState,
  suspendedName,
}) => {
  const [reasonDropDown, setReasonDropDown] = useState(false);
  const [suspendDropDown, setSuspendDropDown] = useState(false);
  const [timeSuspendDropDown, setTimeSuspendDropDown] = useState(false);
  const [toggleCalender, setToggleCalender] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [timePopoverAnchorEl, setTimePopoverAnchorEl] = useState(null);
  const [reasonPopoverAnchorEl, setReasonPopoverAnchorEl] = useState(null);
  const [selectTime, setSelectedTime] = useState(utility.getDefaultTime());
  const [date, setDate] = useState(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  });
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [state, setState] = useState({
    suspendUser: false,
    suspendReason: "Not Choosen",
    suspendCustomReason: "",
    disableReason: "Not Choosen",
    disableCustomReason: "",
    startDate: 0,
    endDate: 0,
    type: "Permanent",
  });
  const [error, setError] = useState({});

  const useStyles = makeStyles((theme) => ({
    datePickerDay: {
      color: "#191714",
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 400,
    },
  }));

  const classes = useStyles();

  useMemo(() => {
    switch (error?.errorType) {
      case "invalidDate": {
        return setError((prev) => ({
          ...prev,
          dateError: "Date is not valid",
        }));
      }
      case "disablePast": {
        return setError((prev) => ({
          ...prev,
          dateError:
            "The selected time must be in the future for today's date.",
        }));
      }
      case "maxDate": {
        return setError((prev) => ({
          ...prev,
          dateError: "The selected time must can't be beyond the max date.",
        }));
      }
      default: {
        return "";
      }
    }
    // eslint-disable-next-line
  }, [error?.errorType]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setReasonDropDown(!reasonDropDown);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleTimePopoverOpen = (event) => {
    setTimePopoverAnchorEl(event.currentTarget);
    setTimeSuspendDropDown(!timeSuspendDropDown);
  };

  const handleTimePopoverClose = () => {
    setTimePopoverAnchorEl(null);
  };

  const timePopoverOpen = Boolean(timePopoverAnchorEl);
  const timePopoverId = timePopoverOpen ? "time-popover" : undefined;

  const handleReasonPopoverOpen = (event) => {
    setReasonPopoverAnchorEl(event.currentTarget);
    setSuspendDropDown(!suspendDropDown);
  };

  const handleReasonPopoverClose = () => {
    setReasonPopoverAnchorEl(null);
  };

  const reasonPopoverOpen = Boolean(reasonPopoverAnchorEl);
  const reasonPopoverId = reasonPopoverOpen ? "reason-popover" : undefined;

  const handleDisableComment = async () => {
    setLoading(true);
    const combinedDateTime = `${
      date.toISOString().split("T")[0]
    }T${selectTime}`;

    let dateTime = new Date(combinedDateTime);

    if (changeDate) {
      dateTime.setDate(dateTime.getDate() + 1);
    } else {
      dateTime.setDate(dateTime.getDate());
    }

    const epochTime = dateTime.getTime();

    let response = {
      disableReason:
        state.disableReason === "Other"
          ? state.disableCustomReason
          : state.disableReason,
      suspendUser: state.suspendUser,
      disabler: user?.staffId,
    };
    if (state.suspendUser) {
      response.suspendReason =
        state.suspendReason === "Other"
          ? state.suspendCustomReason
          : state.suspendReason;
      response.startDate = Date.now();
      response.endDate = state.type === "Custom" ? epochTime : state?.endDate;
      response.type =
        state.type === "Permanent" ? state?.type?.toUpperCase() : "CUSTOM";
    }

    try {
      response = await new UserService().userDisableComment(
        selectedId,
        response
      );
      if (response?.responseCode === 200) {
        ShowToast({
          message:
            state?.suspendUser && suspendedName
              ? `${successMessage.COMMENT_DISABLED}. ${suspendedName} suspended`
              : successMessage.COMMENT_DISABLED,
          type: toastType.SUCCESS,
        });
        getDetails();
        if (state?.suspendUser) {
          updateUser();
        }
        setToggle(false);
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      setLoading(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDisablePost = async () => {
    setLoading(true);
    const combinedDateTime = `${
      date.toISOString().split("T")[0]
    }T${selectTime}`;

    let dateTime = new Date(combinedDateTime);

    if (changeDate) {
      dateTime.setDate(dateTime.getDate() + 1);
    } else {
      dateTime.setDate(dateTime.getDate());
    }

    const epochTime = dateTime.getTime();

    let response = {
      disableReason:
        state.disableReason === "Other"
          ? state.disableCustomReason
          : state.disableReason,
      suspendUser: state.suspendUser,
      disabler: user?.staffId,
    };
    if (state.suspendUser) {
      response.suspendReason =
        state.suspendReason === "Other"
          ? state.suspendCustomReason
          : state.suspendReason;
      response.startDate = Date.now();
      response.endDate = state.type === "Custom" ? epochTime : state?.endDate;
      response.type =
        state.type === "Permanent" ? state?.type?.toUpperCase() : "CUSTOM";
    }
    try {
      const res = await new UserService().userDisablePost(selectedId, response);
      if (res?.responseCode === 200) {
        ShowToast({
          message:
            state?.suspendUser && suspendedName
              ? `${successMessage.POST_DISABLED}. ${suspendedName} suspended`
              : successMessage.POST_DISABLED,
          type: toastType.SUCCESS,
        });
        getDetails();
        if (state?.suspendUser) {
          updateUser();
        }
        setToggle(false);
      } else {
        ShowToast({
          message: res?.message,
          type: toastType.ERROR,
        });
      }
      setLoading(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const handleTimeChange = (newTime, date) => {
    const selectedDateTime = new Date(`${date.toDateString()} ${newTime}`);

    if (isToday(date) && selectedDateTime < new Date()) {
      setSelectedTime(newTime);
      setError((prev) => ({ ...prev, timeError: true }));
    } else {
      setSelectedTime(newTime);
      setError((prev) => ({ ...prev, timeError: false }));
    }
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white flex flex-col h-80per w-115 p-7.5 rounded-20 relative">
        <div className="bg-white flex justify-between">
          <span className="text-black-100 font-ManropeBold text-ft4">
            {name}
          </span>
          <Close
            onClick={() => {
              setToggle();
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="flex flex-col gap-5  h-full mt-5 mb-5 overflow-y-scroll max-h-155 scrollbar-hide">
          <p className="w-79.75 font-ManropeRegular text-ft3 text-black-100">
            Post will be disable. User receive an notification with your reason
          </p>
          <div className="flex flex-col">
            <p className="font-ManropeRegular text-ft3 text-black-100">
              Reason
            </p>
            <div
              className="relative flex justify-between items-center h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50"
              onClick={(event) => {
                handleClick(event);
              }}
            >
              <div className="flex justify-between items-center w-full cursor-pointer">
                <p
                  className={`${
                    state.disableReason === "Not Choosen"
                      ? "font-ManropeRegular text-ft3 text-black-400"
                      : "font-ManropeRegular text-ft3 text-black-100"
                  }`}
                >
                  {state.disableReason}
                </p>
                <img
                  src={reasonDropDown ? chevronUp : chevrondown}
                  alt="chevrondown"
                  className="cursor-pointer"
                />
              </div>
              <Popover
                id={id}
                open={reasonDropDown}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{
                  height: "30%",
                }}
              >
                {reasonDisablePost.map((value, idx) => (
                  <div
                    key={idx}
                    className="cursor-pointer"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        disableReason: value,
                      }));
                      setReasonDropDown(false);
                    }}
                  >
                    <p className="flex items-center px-5 font-ManropeRegular w-100 h-16.25 text-ft3 text-black-100">
                      {value}
                    </p>
                    <hr
                      className={`${
                        value !== "Other" ? "text-grey-100" : "opacity-0"
                      }`}
                    />
                  </div>
                ))}
              </Popover>
            </div>
            {state.disableReason === "Other" && (
              <div className="flex flex-col mt-6">
                <p className="font-ManropeRegular text-ft3 text-black-100">
                  Type a reason
                </p>
                <div className="flex h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50">
                  <input
                    type="text"
                    className="w-97.5 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none"
                    value={state.disableCustomReason}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= 150) {
                        setError((prev) => ({ ...prev, reasonError: false }));
                      } else {
                        setError((prev) => ({ ...prev, reasonError: true }));
                      }
                      setState((prev) => ({
                        ...prev,
                        disableCustomReason: e.target.value,
                      }));
                    }}
                  />
                </div>
                {error?.reasonError && (
                  <span className=" text-red-50">
                    Reason can't be more than 150
                  </span>
                )}
              </div>
            )}
          </div>
          {suspendedState === "ACTIVE" && (
            <div className="flex h-7.5 items-center gap-3.75 relative self-stretch">
              <input
                type="checkbox"
                id="toggle"
                className={` appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  state.suspendUser
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    suspendUser: true,
                  }))
                }
              />
              {state.suspendUser ? (
                <img
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      suspendUser: false,
                    }))
                  }
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label
                htmlFor="toggle"
                className="font-ManropeRegular text-ft3 text-black-100"
              >
                Suspend user
              </label>
            </div>
          )}
          {state.suspendUser ? (
            <div className="flex flex-col">
              <div className="flex relative flex-col">
                <p className="font-ManropeRegular text-ft3 text-black-100">
                  Time for suspend
                </p>
                <div
                  className="flex h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50 justify-between items-center relative"
                  onClick={(event) => {
                    handleTimePopoverOpen(event);
                  }}
                >
                  <div className="flex w-full justify-between items-center relative cursor-pointer">
                    <p className="font-ManropeRegular text-ft3 text-black-100">
                      {state.type}
                    </p>
                    <img
                      src={timeSuspendDropDown ? chevronUp : chevrondown}
                      alt="chevrondown"
                      className="cursor-pointer"
                    />
                  </div>
                  <Popover
                    id={timePopoverId}
                    open={timeSuspendDropDown}
                    anchorEl={timePopoverAnchorEl}
                    onClose={handleTimePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    style={{
                      height: "30%",
                    }}
                  >
                    {timeSuspend.map((value, idx) => (
                      <div
                        key={idx}
                        className="h-16.25 cursor-pointer"
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            type: value.label,
                            startDate: value.startDate,
                            endDate: value.endDate,
                          }));
                          setTimeSuspendDropDown(false);
                        }}
                      >
                        <p className="flex items-center px-5 font-ManropeRegular w-100 h-16.25 text-ft3 text-black-100">
                          {value.label}
                        </p>
                        <hr
                          className={`${
                            value.label !== "Permanent"
                              ? "text-grey-100"
                              : "opacity-0"
                          }`}
                        />
                      </div>
                    ))}
                  </Popover>
                </div>
              </div>
              {state.type === "Custom" && (
                <div className="flex justify-between gap-3 mt-5">
                  <div className="flex flex-col relative w-1/2">
                    <p className="font-ManropeRegular text-ft3 text-black-100">
                      Until Date
                    </p>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label=""
                          disablePast
                          onError={(newError) =>
                            setError((prev) => ({
                              ...prev,
                              errorType: newError,
                            }))
                          }
                          open={toggleCalender}
                          disableHighlightToday
                          onClose={() => setToggleCalender(false)}
                          onChange={(value) => {
                            const currentDate = new Date();
                            currentDate.setHours(0, 0, 0, 0);
                            const selectedDate = new Date(value);
                            selectedDate.setHours(0, 0, 0, 0);
                            if (selectedDate >= currentDate) {
                              setDate(selectedDate);
                            }
                            handleTimeChange(selectTime, selectedDate);
                            setToggleCalender(false);
                            setChangeDate(true);
                          }}
                          value={date}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { border: "none" },
                              height: "48px",
                              padding: "25px 10px 25px 10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "20px",
                              backgroundColor: "#f3f3f3",
                              border: "none",
                            },
                          }}
                          slotProps={{
                            textField: {
                              InputProps: {
                                endAdornment: (
                                  <InputAdornment
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                      cursor: "pointer",
                                      marginRight: "12px",
                                    }}
                                    position="start"
                                  >
                                    <img
                                      src={calender}
                                      alt=""
                                      onClick={() => {
                                        setToggleCalender(!toggleCalender);
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              },
                            },
                            day: {
                              className: classes.datePickerDay,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2">
                    <p className="font-ManropeRegular text-ft3 text-black-100">
                      Time
                    </p>
                    <div className="flex  relative items-center justify-between px-6.25 py-2.5 rounded-full bg-grey-50">
                      <input
                        type="time"
                        value={selectTime}
                        onChange={(e) => handleTimeChange(e.target.value, date)}
                        className="rounded-full px-2 py-1 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 w-full"
                      />
                      <img
                        src={Time}
                        alt={"time"}
                        className=" absolute right-7 pointer-events-none"
                      />
                    </div>
                  </div>
                </div>
              )}

              {error.timeError && (
                <span className=" text-red-50">
                  The selected time must be in the future for today's date.
                </span>
              )}
              {error?.errorType && (
                <span className=" text-red-50">{error?.dateError}</span>
              )}

              <div className="flex flex-col  mt-5">
                <p className="font-ManropeRegular text-ft3 text-black-100">
                  Reason
                </p>
                <div
                  className="flex  relative justify-between items-center h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50 cursor-pointer"
                  onClick={(event) => {
                    handleReasonPopoverOpen(event);
                  }}
                >
                  <div className="flex justify-between items-center w-full">
                    <p
                      className={`${
                        state.suspendReason === "Not Choosen"
                          ? "font-ManropeRegular text-ft3 text-black-400"
                          : "font-ManropeRegular text-ft3 text-black-100"
                      }`}
                    >
                      {state.suspendReason}
                    </p>

                    <img
                      src={suspendDropDown ? chevronUp : chevrondown}
                      alt="chevrondown"
                      className="cursor-pointer"
                    />
                  </div>
                  <Popover
                    id={reasonPopoverId}
                    open={suspendDropDown}
                    anchorEl={reasonPopoverAnchorEl}
                    onClose={handleReasonPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    style={{
                      height: "30%",
                    }}
                  >
                    {reasonDisablePost.map((value, idx) => (
                      <div
                        key={idx}
                        className="h-16.25 cursor-pointer"
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            suspendReason: value,
                          }));
                          setSuspendDropDown(false);
                        }}
                      >
                        <p className="flex items-center px-5 font-ManropeRegular w-100 h-16.25 text-ft3 text-black-100">
                          {value}
                        </p>
                        <hr
                          className={`${
                            value.label !== "Other"
                              ? "text-grey-100"
                              : "opacity-0"
                          }`}
                        />
                      </div>
                    ))}
                  </Popover>
                </div>
              </div>

              {state.suspendReason === "Other" && (
                <div className="flex flex-col mt-5">
                  <p className="font-ManropeRegular text-ft3 text-black-100">
                    Type a reason
                  </p>
                  <div className="flex h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50">
                    <input
                      type="text"
                      className="w-97.5 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none"
                      value={state.suspendCustomReason}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 150) {
                          setError((prev) => ({
                            ...prev,
                            reasonSuspendedError: false,
                          }));
                        } else {
                          setError((prev) => ({
                            ...prev,
                            reasonSuspendedError: true,
                          }));
                        }
                        setState((prev) => ({
                          ...prev,
                          suspendCustomReason: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {error?.reasonSuspendedError && (
                    <span className=" text-red-50">
                      Reason for suspend can't be more than 150
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <button
          className={`${
            state.disableReason === "Not Choosen" ||
            (state.suspendUser && state.suspendReason === "Not Choosen") ||
            (state.disableReason === "Other" &&
              state?.disableCustomReason === "") ||
            (state.suspendUser &&
              state.suspendReason === "Other" &&
              state?.suspendCustomReason === "") ||
            error?.errorType ||
            error?.reasonSuspendedError ||
            error?.reasonError ||
            error?.timeError ||
            loading
              ? "bg-grey-50"
              : "bg-orange-50"
          }
          rounded-full w-full h-16.25 py-5 flex justify-center items-center text-black-100 font-ManropeBold text-ft3`}
          onClick={() => {
            keyName === "comment"
              ? handleDisableComment()
              : handleDisablePost();
          }}
          disabled={
            state.disableReason === "Not Choosen" ||
            (state.suspendUser && state.suspendReason === "Not Choosen") ||
            (state.disableReason === "Other" &&
              state?.disableCustomReason === "") ||
            (state.suspendUser &&
              state.suspendReason === "Other" &&
              state?.suspendCustomReason === "") ||
            error?.errorType ||
            error?.reasonSuspendedError ||
            error?.reasonError ||
            loading ||
            error?.timeError
          }
        >
          {loading ? (
            <CircularProgress className="text-orange-50" />
          ) : (
            "Disable"
          )}
        </button>
      </div>
    </div>
  );
};

export default DisablePopUp;
