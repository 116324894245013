/**
 * RejectRequestPopUp component for handling the rejection of blue check verification requests.
 * @component
 *
 * @param {Object} props - Component props
 * @param {boolean} props.isRejectPopUp - State to control the visibility of the rejection popup
 * @param {Function} [props.setRejectPopUp] - Function to toggle the rejection popup visibility
 * @param {Function} props.setReason - Function to set the rejection reason
 * @param {string} props.reason - The selected rejection reason
 * @param {string} props.customReasonInput - The custom reason input for rejection
 * @param {Function} props.setCustomReasonInput - Function to set the custom reason input
 * @param {Function} props.handleRejectBlueTick - Function to handle the rejection of the blue tick request
 *
 * @returns {JSX.Element} RejectRequestPopUp component
 */

import React, { useState } from "react";
import { ReactComponent as Close } from "../../assets/Icons/close.svg";
import chevronUp from "../../assets/Icons/chevronUp.svg";
import chevrondown from "../../assets/Icons/Chevrondown.svg";
import { reasonDisablePost } from "../../constant/commonArray";

const RejectRequestPopUp = ({
  isRejectPopUp,
  setRejectPopUp = () => {},
  setReason,
  reason,
  customReasonInput,
  setCustomReasonInput,
  handleRejectBlueTick,
}) => {
  const [reasonDropDown, setReasonDropDown] = useState(false);
  const [error, setError] = useState(false);

  const getButtonClass = (reason, customReasonInput, error) => {
    if (reason === "Not Choosen") {
      return "bg-grey-50";
    } else if (reason === "Other") {
      return customReasonInput && !error ? "bg-orange-50" : "bg-grey-50";
    } else {
      return "bg-orange-50";
    }
  };
  return (
    <div className="bg-grey-100 bg-opacity-70 z-30  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white flex flex-col h-80per w-115 p-7.5 rounded-20">
        <div className="flex justify-between flex-col">
          <div className="flex flex-col gap-5">
            <div className="flex justify-between">
              <p className="text-black-100 font-ManropeBold text-ft4">
                Reject request
              </p>
              <Close
                onClick={() => setRejectPopUp(!isRejectPopUp)}
                className="cursor-pointer"
              />
            </div>
            <p className="text-black-100 font-ManropeRegular text-ft3 w-79.75">
              Request will be rejected. User receive an notification with your
              reason
            </p>
            <div className="flex flex-col">
              <p className="font-ManropeRegular text-ft3 text-black-100">
                Reason
              </p>
              <div
                onClick={() => {
                  setReasonDropDown(!reasonDropDown);
                }}
                className="flex relative justify-between items-center h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50 cursor-pointer"
              >
                <p
                  className={`${
                    reason === "Not Choosen"
                      ? "font-ManropeRegular text-ft3 text-black-400"
                      : "font-ManropeRegular text-ft3 text-black-100"
                  }`}
                >
                  {reason}
                </p>
                <img
                  src={reasonDropDown ? chevronUp : chevrondown}
                  alt="chevrondown"
                  className="cursor-pointer"
                />
                {reasonDropDown && (
                  <div className="flex flex-col absolute top-18 z-20 w-full left-0 rounded-20 bg-white shadow-inner overflow-y-scroll max-h-50vh">
                    {reasonDisablePost.map((value) => (
                      <div
                        className="flex items-center py-4.25 cursor-pointer border-b-0.5 border-gray-light px-5"
                        onClick={() => {
                          setReason(value);
                          setReasonDropDown(false);
                        }}
                      >
                        {value}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {reason === "Other" && (
                <div className="flex flex-col mt-6">
                  <p className="font-ManropeRegular text-ft3 text-black-100">
                    Type a reason
                  </p>
                  <div className="flex h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50">
                    <input
                      type="text"
                      className="w-97.5 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none"
                      value={customReasonInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value?.length <= 150) {
                          setError(false);
                        } else {
                          setError(true);
                        }
                        setCustomReasonInput(value);
                      }}
                    />
                  </div>
                  {error && (
                    <span className=" text-red-50">
                      Reason can't be more than 150
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          disabled={
            reason === "Not Choosen" ||
            error ||
            (reason === "Other" && !customReasonInput)
          }
          onClick={handleRejectBlueTick}
          className={`rounded-full w-full mt-auto py-5 text-black-100 font-ManropeBold text-ft3 ${getButtonClass(
            reason,
            customReasonInput,
            error
          )}`}
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default RejectRequestPopUp;
