import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress } from "@mui/material";

/*....Common loader use to show at center of screen untill the response from api not comes.....*/

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    position: "absolute",
    justifyContent: "center",
    margin: "auto",
    width: "100%",
    zIndex: 1000000,
  },
  wrapper: {
    position: "relative",
  },
}));

export default function Loader() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress style={{ color: "#F6B58F" }} />
    </div>
  );
}
