import React, { useRef } from "react";
import Table from "../../common/table";
import moment from "moment";
import more from "../../assets/Icons/More.svg";
import pause from "../../assets/Icons/pause.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import placeholder from "../../assets/Icons/Placeholder.svg";
import OutsideClickRef from "../../common/outsideClickRef";
import utility from "../../utility";
import PlaceholderWnm from "../../assets/Icons/PlaceholderWnm.png";
import { useSelector } from "react-redux";

/**
 * Renders a table body for active entities and returns a Table component.
 *
 * @component
 * @example
 * const sort = { field: "name", order: "asc" };
 * const data = [{ name: "Entity 1" }, { name: "Entity 2" }];
 * const setCurrentPage = (pageNumber) => console.log(`Current Page: ${pageNumber}`);
 * // ... other props
 * return (
 *   <Active
 *     sort={sort}
 *     data={data}
 *     setCurrentPage={setCurrentPage}
 *     // ... other props
 *   />
 * );
 *
 * @param {object} props - The component props.
 * @param {object} props.sort - The sort object.
 * @param {array} props.data - The array of data to be rendered.
 * @param {function} props.setCurrentPage - The function to set the current page.
 * @param {number} props.currentPage - The current page number.
 * @param {function} props.setPageSize - The function to set the page size.
 * @param {boolean} props.loading - Flag indicating whether the data is still loading.
 * @param {function} props.handlePageChange - The function to handle page change.
 * @param {number} props.pageSize - The size of each page.
 * @param {number} props.totalDocs - The total number of documents.
 * @param {function} props.setSort - The function to set the sort.
 * @param {number} props.totalPages - The total number of pages.
 * @param {function} props.handleMoreClick - The function to handle more click.
 * @param {object} props.selectedUser - The selected user object.
 * @param {boolean} props.suspendUserPopup - Flag indicating whether the suspend user popup is active.
 * @param {function} props.setSupendUserPopup - The function to set the suspend user popup.
 * @param {function} props.renderFilter - The function to render the filter.
 * @param {boolean} props.clickFilter - Flag indicating whether the filter is clicked.
 * @param {function} props.setClickFilter - The function to set the click filter.
 * @param {function} props.setSelectedUser - The function to set the selected user.
 * @param {function} props.setUserId - The function to set the user ID.
 * @return {JSX.Element} The Table component.
 */
const Active = ({
  searchQuery,
  sort,
  data,
  setCurrentPage,
  currentPage,
  setPageSize,
  loading,
  handlePageChange,
  pageSize,
  totalDocs,
  setSort,
  totalPages,
  handleMoreClick,
  selectedUser,
  suspendUserPopup,
  setSupendUserPopup,
  renderFilter,
  clickFilter,
  setClickFilter,
  setSelectedUser,
  setUserId,
  userId,
  selectedFilters,
}) => {
  const history = useHistory();
  const optionsRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const headers = [
    { name: " Company name" },
    { name: "Type", filter: true, filterLength: selectedFilters?.length },
    { name: "Registrated", sort: true },
    { name: "Phone" },
    { name: "Email" },
    { name: "Employees" },
  ];

  /**
   * Renders the table body based on the data.
   *
   * @return {JSX.Element} The table body JSX element
   */
  const renderTableBody = () => {
    if (data.length > 0 && !loading) {
      return data?.map((item) => (
        <tr key={item?._id} className="hover:bg-grey-350 cursor-pointer">
          <td
            className=" py-2.5 px-5"
            onClick={() =>
              userPermissions?.indexOf("business:read:details") > -1 &&
              history.push(`/entity-account/details/${item?._id}?tab=ACTIVE`)
            }
          >
            <div className="flex gap-2.5 items-center w-50">
              <img
                src={item.profilePhoto}
                alt="User"
                className="w-10 flex flex-shrink-0 h-10 rounded-full"
              />
              <div className="flex flex-col w-full">
                <span className="font-ManropeBold text-black-100  text-ft1 w-35 truncate">
                  {item.fullName}
                </span>
                <span className="font-ManropeRegular  text-ft1 text-grey-200 w-35 truncate">
                  {item.userName}
                </span>
              </div>
            </div>
          </td>
          <td
            className="font-ManropeMedium text-ft1 text-black-50"
            onClick={() =>
              userPermissions?.indexOf("business:read:details") > -1 &&
              history.push(`/entity-account/details/${item?._id}?tab=ACTIVE`)
            }
          >
            {utility.capitalizeFirstLetter(item.type)}
          </td>
          <td
            className="font-ManropeMedium text-ft1 text-black-50"
            onClick={() =>
              userPermissions?.indexOf("business:read:details") > -1 &&
              history.push(`/entity-account/details/${item?._id}?tab=ACTIVE`)
            }
          >
            <div className="w-35 truncate">
              {moment(item.addedOn).format("DD MMM[,] YYYY")}
            </div>
          </td>
          <td
            onClick={() =>
              userPermissions?.indexOf("business:read:details") > -1 &&
              history.push(`/entity-account/details/${item?._id}?tab=ACTIVE`)
            }
          >
            <div className="font-ManropeMedium text-ft1 text-black-50 flex gap-1 w-35 truncate">
              <span>+{item.countryCode}</span>
              <span>{item.phone}</span>
            </div>
          </td>
          <td
            className="font-ManropeMedium text-ft1 text-black-50"
            onClick={() =>
              userPermissions?.indexOf("business:read:details") > -1 &&
              history.push(`/entity-account/details/${item?._id}?tab=ACTIVE`)
            }
          >
            <div className="w-35 xl:w-auto truncate">{item.email}</div>
          </td>
          <td
            className="font-ManropeMedium text-ft1 text-black-50"
            onClick={() =>
              userPermissions?.indexOf("business:read:details") > -1 &&
              history.push(`/entity-account/details/${item?._id}?tab=ACTIVE`)
            }
          >
            <div className="w-35 truncate">{item.employees}</div>
          </td>
          <td>
            <div className="w-6.25 relative">
              {userPermissions?.indexOf("business:write:suspend") > -1 && (
                <img
                  src={more}
                  alt="More"
                  className="cursor-pointer"
                  onClick={() => {
                    handleMoreClick(item);
                  }}
                />
              )}
              {userId === item?._id && (
                <OutsideClickRef
                  state={optionsRef}
                  setState={() => setUserId(null)}
                >
                  <div className="absolute right-2 flex flex-col items-start w-75 rounded-20 shadow-inner z-10 bg-white">
                    <button
                      onClick={() => {
                        setSupendUserPopup(!suspendUserPopup);
                        setSelectedUser({
                          id: item?._id,
                          username: item?.userName,
                        });
                      }}
                      className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
                    >
                      <img src={pause} alt="Pause" />
                      <p className="text-ft3 font-ManropeLight text-black-100">
                        Suspend
                      </p>
                    </button>
                  </div>
                </OutsideClickRef>
              )}
            </div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={6}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery || selectedFilters?.length > 0 ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  return (
    <Table
      headers={headers}
      tableBody={renderTableBody()}
      currentPage={currentPage}
      loading={loading}
      handlePageChange={handlePageChange}
      pageSize={pageSize}
      totalDocs={totalDocs}
      setCurrentPage={setCurrentPage}
      setSort={setSort}
      setPageSize={setPageSize}
      sort={sort}
      totalPages={totalPages}
      clickFilter={clickFilter}
      renderFilter={renderFilter()}
      setClickFilter={setClickFilter}
    />
  );
};

export default Active;
