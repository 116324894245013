import React,{useState} from "react";
import { ReactComponent as Close } from "../../assets/Icons/close.svg";

const RewardEdit = ({ onClose }) => {
    const [customThresholdValue, setCustomThresholdValue] = useState("10");
    const [customAmountValue, setCustomAmountValue] = useState("10");
  return (
    <div className="bg-grey-100 bg-opacity-70 z-30  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white flex flex-col justify-between h-75per w-115 p-7.5 rounded-20">
        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <p className="text-black-100 font-ManropeBold text-ft4">
              Creating certain amount of Polls
            </p>
            <Close onClick={() => onClose()} className="cursor-pointer" />
          </div>
          <p className="text-black-100 font-ManropeRegular text-ft3 w-79.75">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim
          </p>
          <div className="flex flex-col">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Threshold (amount of polls)
            </p>
            <div className="flex w-full h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50">
            <input
                    type="text"
                    className="w-full bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none"
                    value={customThresholdValue}
                    onChange={(e) => setCustomThresholdValue(e.target.value)}
                  />
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Amount, USD
            </p>
            <div className="flex w-full h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50">
            <input
                    type="text"
                    className="w-full bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none"
                    value={customAmountValue}
                    onChange={(e) => setCustomAmountValue(e.target.value)}
                  />
            </div>
          </div>
        </div>
        <button className="flex w-full h-16.25 px-6.25 py-2.5 rounded-full bg-orange-50 items-center justify-center text-black-100 font-ManropeBold text-ft3">
          Save
        </button>
      </div>
    </div>
  );
};

export default RewardEdit;
