import React, { useState } from "react";
import Edit from "../../../assets/Icons/Edit.svg";
import EditPopup from "../../../common/editPopup";
import Chevrondown from "../../../assets/Icons/Chevrondown.svg";
import Information from "../../../assets/Icons/Information.svg";
import moment from "moment";
import { UserService } from "../../../services";
import utility from "../../../utility";
import ShowToast from "../../../common/showToast";
import { successMessage, toastType } from "../../../constant/commonArray";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import EditLocationPopup from "./editLocationPopup";
import { useSelector } from "react-redux";

/**
 * DetailsGeneralInfo component displays general information about an entity.
 * It includes details such as company name, username, business type, location, description, and registration date.
 *
 * @component
 * @example
 * const setGeneralExpanded = (expanded) => {
 *   // handle expand/collapse logic
 * };
 *
 * const getEntityDetailsData = () => {
 *   // fetch and update entity details
 * };
 *
 * const details = {
 *   fullName: "Netflix",
 *   userName: "@Netflix",
 *   type: "business",
 *   location: { name: "Berlin, Germany", latitude: 52.5200, longitude: 13.4050 },
 *   bio: "A streaming service company.",
 *   addedOn: "2022-01-30T12:34:56Z",
 *   // ... other entity details
 * };
 *
 * <DetailsGeneralInfo
 *   setGeneralExpanded={setGeneralExpanded}
 *   isGeneralExpanded={true}
 *   details={details}
 *   getEntityDetailsData={getEntityDetailsData}
 *   stateId={123}
 *   keyName="default"
 * />
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setGeneralExpanded - Function to set the expansion state of the component.
 * @param {boolean} props.isGeneralExpanded - Flag indicating whether the general info section is expanded.
 * @param {Object} props.details - Information about the entity.
 * @param {string} props.details.fullName - The name of the entity (e.g., company name).
 * @param {string} props.details.userName - The username associated with the entity.
 * @param {string} props.details.type - The type of business.
 * @param {Object} props.details.location - The location details of the entity.
 * @param {string} props.details.location.name - The name of the location.
 * @param {number} props.details.location.latitude - The latitude of the location.
 * @param {number} props.details.location.longitude - The longitude of the location.
 * @param {string} props.details.bio - A description of the entity.
 * @param {string} props.details.addedOn - The registration date of the entity.
 * @param {Function} props.getEntityDetailsData - Function to fetch and update entity details.
 * @param {string} props.stateId - The ID of the entity state.
 * @param {string} props.keyName - The key name used for editing purposes.
 * @returns {JSX.Element} The rendered DetailsGeneralInfo component.
 */

const DetailsGeneralInfo = ({
  setGeneralExpanded,
  isGeneralExpanded,
  details,
  getEntityDetailsData,
  stateId,
  keyName = "default",
}) => {
  const [edit, setEditPopup] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [state, setState] = useState({
    fullName: details?.fullName,
    userName: details?.userName,
    cityName: "",
    countryName: "",
    location: {
      name: details?.location?.name,
      latitude: details?.location?.latitude,
      longitude: details?.location?.longitude,
    },
  });

  const handleSubmission = async () => {
    setLoading(true);
    let requestData = {};
    if (state.fullName !== details?.fullName) {
      requestData.fullName = state?.fullName;
    }

    if (state.userName !== details?.userName) {
      requestData.userName = state?.userName;
    }
    if (state.location.name !== details?.location?.name) {
      requestData.location = state.location;
      requestData.cityName = state.cityName;
      requestData.countryName = state.countryName;
    }

    try {
      const [err, res] = await utility.parseResponse(
        new UserService().editEntity(stateId || id, requestData)
      );
      if (err || !res) return;
      if (res?.responseCode === 200) {
        ShowToast({
          message: successMessage.USER_UPDATED,
          type: toastType.SUCCESS,
        });
        getEntityDetailsData();
        setEditPopup(null);
      } else {
        ShowToast({
          message: res?.message,
          type: toastType.ERROR,
        });
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const renderEditPopup = () => {
    switch (edit) {
      case "company":
        return (
          <EditPopup
            name={"Company name"}
            setEditPopup={setEditPopup}
            setState={setState}
            value={details?.fullName}
            state={state}
            keyName="fullName"
            handleSubmission={handleSubmission}
            loading={loading}
          />
        );
      case "username":
        return (
          <EditPopup
            name={"Username"}
            setEditPopup={setEditPopup}
            setState={setState}
            state={state}
            value={details?.userName}
            keyName="userName"
            handleSubmission={handleSubmission}
            loading={loading}
          />
        );
      case "location":
        return (
          <EditLocationPopup
            name={"Location"}
            setEditPopup={setEditPopup}
            setState={setState}
            value={details?.location?.name}
            state={state}
            handleSubmission={handleSubmission}
            keyName="location"
            loading={loading}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div
      className={`flex flex-col p-5 cursor-pointer rounded-20 bg-white w-full ${
        isGeneralExpanded ? "h-auto  gap-7.5" : ""
      }`}
    >
      <div
        onClick={() => setGeneralExpanded(!isGeneralExpanded)}
        className="flex items-center gap-2 xl:gap-3.75"
      >
        <div className="w-auto">
          <div className="flex items-center justify-center w-12.5 h-12.5 rounded-full bg-purple-50">
            <img src={Information} alt="Information" />
          </div>
        </div>
        <div className="flex w-full justify-between">
          <p className="text-ft3 2xl:text-ft32 font-ManropeBold text-black-100">
            General Info
          </p>
          <img src={Chevrondown} alt="Chevrondown" className="cursor-pointer" />
        </div>
      </div>
      {isGeneralExpanded && (
        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <div className="flex flex-col w-90per">
              <p className="font-ManropeBold text-ft3 text-black-100">
                Company name
              </p>
              <p className="font-ManropeRegular w-full truncate text-ft3 text-grey-250">
                {details?.fullName || "-"}
              </p>
            </div>
            {keyName !== "violation" &&
              userPermissions?.indexOf("business:write:details") > -1 && (
                <img
                  src={Edit}
                  alt="Edit"
                  onClick={() => setEditPopup("company")}
                  className="cursor-pointer"
                />
              )}
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col w-90per">
              <p className="font-ManropeBold text-ft3 text-black-100">
                Username
              </p>
              <p className="font-ManropeRegular text-ft3 w-full truncate text-grey-250">
                {details?.userName || "-"}
              </p>
            </div>
            {keyName !== "violation" &&
              userPermissions?.indexOf("business:write:details") > -1 &&
              details?.isBlueTickVerified !== "COMPLETED" && (
                <img
                  src={Edit}
                  alt="Edit"
                  onClick={() => setEditPopup("username")}
                  className="cursor-pointer"
                />
              )}
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">
                Business type
              </p>
              <p className="font-ManropeRegular text-ft3 text-grey-250">
                {utility.capitalizeFirstLetter(details?.type) || "-"}
              </p>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">
                Location
              </p>
              <p className="font-ManropeRegular text-ft3 text-grey-250">
                {details?.location?.name || "-"}
              </p>
            </div>
            {keyName !== "violation" &&
              userPermissions?.indexOf("business:write:details") > -1 && (
                <img
                  src={Edit}
                  alt="Edit"
                  onClick={() => setEditPopup("location")}
                  className="cursor-pointer"
                />
              )}
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col w-90per">
              <p className="font-ManropeBold text-ft3 text-black-100">
                Description
              </p>
              <p className="font-ManropeRegular text-ft3 break-all w-full text-grey-250">
                {details?.bio || "-"}
              </p>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">
                Registered
              </p>
              <p className="font-ManropeRegular text-ft3 text-grey-250">
                {moment(details?.addedOn).format("DD MMM[,] YYYY") || "-"}
              </p>
            </div>
          </div>

          {renderEditPopup()}
        </div>
      )}
    </div>
  );
};

export default DetailsGeneralInfo;
