import React, { useEffect, useRef, useState } from "react";
import more from "../../../assets/Icons/More-white.svg";
import copy from "../../../assets/Icons/copy.svg";
import error from "../../../assets/Icons/Error.svg";
import heart from "../../../assets/Icons/Heart.svg";
import message from "../../../assets/Icons/Message.svg";
import group from "../../../assets/Icons/Group.svg";
import upload from "../../../assets/Icons/Upload.svg";
import defaultImage from "../../../assets/Icons/signupProfileSmall.png";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getRandomColor } from "../../../utility";
import OutsideClickRef from "../../../common/outsideClickRef";
import ShowToast from "../../../common/showToast";
import { toastType } from "../../../constant/commonArray";
import { useSelector } from "react-redux";

/**
 * React component representing a post with poll information.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.poll - The poll information associated with the post.
 * @param {Function} props.setDisablePopUp - Function to set the disable pop-up state.
 * @param {Function} props.setSelectedUser - Function to set the selected user.
 * @param {Object} props.selectedUser - The selected user.
 * @param {Function} props.setDisableType - Function to set the disable type state.
 * @param {string} props.id - The post ID.
 * @param {string} props.url - The URL for navigation.
 * @param {Function} props.setPostId - Function to set the post ID.
 * @param {string} [props.keyName="default"] - Key name for additional functionality.
 * @returns {React.Component} The rendered Post component.
 */

const Post = ({
  poll,
  setDisablePopUp,
  setSelectedUser,
  selectedUser,
  setDisableType,
  id,
  url,
  setPostId,
  keyName = "default",
  permission,
}) => {
  const history = useHistory();
  const [colors, setColors] = useState([]);
  const optionsRef = useRef(null);
  const generatedColor = getRandomColor();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tabType = queryParams.get("tab");
  const haveAudit = queryParams.get("from");
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  useEffect(() => {
    const newColors = poll?.channels?.map(() => generatedColor());
    const typeColor = generatedColor();
    setColors([...newColors, typeColor]);
    // eslint-disable-next-line
  }, []);

  function copyToClipboard(userId) {
    navigator.clipboard.writeText(
      `${window.location.origin}${url}/${userId}?userId=${id}`
    );
    ShowToast({ message: "Link copied", type: toastType.SUCCESS });
  }
  const fileExtension = poll?.post?.coverImage?.split(".").pop();

  let dynamicUrl = `${url}/${poll._id}?userId=${id}`;

  if (tabType) {
    dynamicUrl += `&tab=${tabType}`;
  } else if (haveAudit) {
    dynamicUrl += `&from=${haveAudit}`;
  }
  return (
    <div
      className={`flex flex-col justify-center relative ${
        fileExtension === "mp4" ? "mt-5" : ""
      }`}
    >
      {fileExtension === "mp4" && (
        <video
          autoPlay
          muted
          loop
          src={poll?.post?.coverImage}
          className="absolute inset-0  object-cover z-0 w-100 xl:w-137.5 h-full rounded-20"
        >
          <source src={poll?.post?.coverImage} type="video/mp4" />
        </video>
      )}
      {fileExtension !== "mp4" ? (
        <div
          onClick={() => {
            if (url) {
              history.push(dynamicUrl, {
                state: { id, pollState: "polls" },
              });
            }
          }}
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 42.01%, rgba(0, 0, 0, 0.4) 69.22%, rgba(0, 0, 0, 0.5) 84.1%, rgba(0, 0, 0, 0.4) 100%), url(${poll?.post?.coverImage})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
          className={`flex relative flex-col justify-between w-100 xl:w-137.5 h-115 rounded-20 p-5 bg-black-50   bg-cover mt-5 cursor-pointer`}
        >
          <div className="h-full cursor-pointer">
            <div className="flex justify-between relative ">
              <div className="flex gap-2 cursor-pointer w-80per">
                {poll?.channels?.map((channel, index) => (
                  <div
                    style={{ backgroundColor: colors[index] }}
                    className="flex items-center justify-center rounded-20  px-1.5"
                    key={index}
                  >
                    <p className="font-ManropeRegular text-ft0 xl:text-ft2 text-black-100 truncate">
                      {channel}
                    </p>
                  </div>
                ))}

                {/* <div
                  style={{ backgroundColor: colors[colors.length - 1] }}
                  className="flex items-center justify-center w-13.25 rounded-20 b0 px-1"
                >
                  <p className="font-ManropeRegular text-ft0 xl:text-ft2 text-black-100">
                    {poll.type}
                  </p>
                </div> */}
              </div>
              <div className="flex gap-2.5">
                <div
                  style={{ backdropFilter: "blur(7px)" }}
                  className="flex items-center justify-center w-19.25 rounded-20 bg-white backdrop-blur-xl bg-opacity-10"
                >
                  <p className="font-ManropeRegular text-ft2 text-white">
                    {poll?.votes} voted
                  </p>
                </div>
                <div className="relative">
                  {keyName !== "violation" && (
                    <img
                      src={more}
                      alt={more}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedUser != null && setSelectedUser(poll?._id);
                      }}
                    />
                  )}
                  {selectedUser === poll?._id && (
                    <OutsideClickRef
                      state={optionsRef}
                      setState={() => setSelectedUser(null)}
                    >
                      <div className="absolute right-0 top-10 flex flex-col items-start w-75 rounded-20 shadow-inner z-20 bg-white">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(poll?._id);
                          }}
                          className="flex h-16.25 px-5 items-center gap-3.75 self-stretch rounded-t-20 hover:bg-grey-350"
                        >
                          <img src={copy} alt="copy" />
                          <p className="text-ft3 font-ManropeLight text-black-100">
                            Copy Link
                          </p>
                        </button>
                        <hr className="text-grey-50 w-full" />
                        {userPermissions?.indexOf(permission) > -1 && (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              setDisablePopUp != null && setDisablePopUp(true);
                              setPostId(poll?._id);
                              setDisableType != null && setDisableType("poll");
                            }}
                            className="flex h-16.25 px-5 items-center gap-3.75 self-stretch rounded-b-20 hover:bg-grey-350"
                          >
                            <img src={error} alt="error" />
                            <button className="text-ft3 font-ManropeLight text-black-100">
                              Disable Poll
                            </button>
                          </div>
                        )}
                      </div>
                    </OutsideClickRef>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-7.5">
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (url) {
                  history.push(dynamicUrl, {
                    state: { id, pollState: "polls" },
                  });
                }
              }}
              className="flex items-center gap-2.5"
            >
              <img
                src={poll?.creator?.profilePhoto || defaultImage}
                alt="user"
                className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
              />
              <p className="font-ManropeBold text-ft2 text-center text-white">
                {poll?.creator?.fullName}
              </p>
            </div>
            <p className="text-ft5 font-ManropeBold text-white">
              {poll.post.name}
            </p>
            <div
              style={{ backdropFilter: "blur(7px)" }}
              className="flex py-4 px-4.5 items-center justify-between rounded-full bg-white backdrop-blur-xl bg-opacity-10"
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (url) {
                    history.push(dynamicUrl, {
                      state: { id, pollState: "likes" },
                    });
                  }
                }}
                className="flex gap-2 items-center"
              >
                <img src={heart} alt="heart" />
                <span className="font-ManropeBold text-ft1 text-white">
                  {poll.likes}
                </span>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (url) {
                    history.push(dynamicUrl, {
                      state: { id, pollState: "comments" },
                    });
                  }
                }}
                className="flex gap-2 items-center"
              >
                <img src={message} alt="message" />
                <span className="font-ManropeBold text-ft1 text-white">
                  {poll.comments}
                </span>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (url) {
                    history.push(dynamicUrl, {
                      state: { id, pollState: "reposts" },
                    });
                  }
                }}
                className="flex gap-2 items-center"
              >
                <img src={group} alt="group" />
                <span className="font-ManropeBold text-ft1 text-white">
                  {poll.reposts}
                </span>
              </div>
              <div>
                <img src={upload} alt="upload" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            if (url) {
              history.push(dynamicUrl, {
                state: { id, pollState: "polls" },
              });
            }
          }}
          className={`flex relative flex-col justify-between w-100 xl:w-137.5 h-115 rounded-20 p-5 bg-cover cursor-pointer`}
        >
          <div className="h-full cursor-pointer">
            <div className="flex justify-between relative ">
              <div className="flex gap-2 cursor-pointer w-80per">
                {poll?.channels?.map((channel, index) => (
                  <div
                    style={{ backgroundColor: colors[index] }}
                    className="flex items-center justify-center rounded-20  px-1.5"
                    key={index}
                  >
                    <p className="font-ManropeRegular text-ft0 xl:text-ft2 text-black-100 truncate">
                      {channel}
                    </p>
                  </div>
                ))}

                {/* <div
                  style={{ backgroundColor: colors[colors.length - 1] }}
                  className="flex items-center justify-center w-13.25 rounded-20 b0 px-1"
                >
                  <p className="font-ManropeRegular text-ft0 xl:text-ft2 text-black-100">
                    {poll.type}
                  </p>
                </div> */}
              </div>
              <div className="flex gap-2.5">
                <div
                  style={{ backdropFilter: "blur(7px)" }}
                  className="flex items-center justify-center w-19.25 rounded-20 bg-white backdrop-blur-xl bg-opacity-10"
                >
                  <p className="font-ManropeRegular text-ft2 text-white">
                    {poll?.votes} voted
                  </p>
                </div>
                <div className="relative">
                  {keyName !== "violation" && (
                    <img
                      src={more}
                      alt={more}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedUser != null && setSelectedUser(poll?._id);
                      }}
                    />
                  )}
                  {selectedUser === poll?._id && (
                    <OutsideClickRef
                      state={optionsRef}
                      setState={() => setSelectedUser(null)}
                    >
                      <div className="absolute right-0 top-10 flex flex-col items-start w-75 rounded-20 shadow-inner z-20 bg-white">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(poll?._id);
                          }}
                          className="flex h-16.25 px-5 items-center gap-3.75 self-stretch rounded-t-20 hover:bg-grey-350"
                        >
                          <img src={copy} alt="copy" />
                          <p className="text-ft3 font-ManropeLight text-black-100">
                            Copy Link
                          </p>
                        </button>
                        <hr className="text-grey-50 w-full" />
                        {userPermissions?.indexOf(permission) > -1 && (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              setDisablePopUp != null && setDisablePopUp(true);
                              setPostId(poll?._id);
                              setDisableType != null && setDisableType("poll");
                            }}
                            className="flex h-16.25 px-5 items-center gap-3.75 self-stretch rounded-b-20 hover:bg-grey-350"
                          >
                            <img src={error} alt="error" />
                            <button className="text-ft3 font-ManropeLight text-black-100">
                              Disable Poll
                            </button>
                          </div>
                        )}
                      </div>
                    </OutsideClickRef>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-7.5">
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (url) {
                  history.push(dynamicUrl, {
                    state: { id, pollState: "polls" },
                  });
                }
              }}
              className="flex items-center gap-2.5"
            >
              <img
                src={poll?.creator?.profilePhoto || defaultImage}
                alt="user"
                className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
              />
              <p className="font-ManropeBold text-ft2 text-center text-white">
                {poll?.creator?.fullName}
              </p>
            </div>
            <p className="text-ft5 font-ManropeBold text-white">
              {poll.post.name}
            </p>
            <div
              style={{ backdropFilter: "blur(7px)" }}
              className="flex py-4 px-4.5 items-center justify-between rounded-full bg-white backdrop-blur-xl bg-opacity-10"
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (url) {
                    history.push(dynamicUrl, {
                      state: { id, pollState: "likes" },
                    });
                  }
                }}
                className="flex gap-2 items-center"
              >
                <img src={heart} alt="heart" />
                <span className="font-ManropeBold text-ft1 text-white">
                  {poll.likes}
                </span>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (url) {
                    history.push(dynamicUrl, {
                      state: { id, pollState: "comments" },
                    });
                  }
                }}
                className="flex gap-2 items-center"
              >
                <img src={message} alt="message" />
                <span className="font-ManropeBold text-ft1 text-white">
                  {poll.comments}
                </span>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (url) {
                    history.push(dynamicUrl, {
                      state: { id, pollState: "reposts" },
                    });
                  }
                }}
                className="flex gap-2 items-center"
              >
                <img src={group} alt="group" />
                <span className="font-ManropeBold text-ft1 text-white">
                  {poll.reposts}
                </span>
              </div>
              <div>
                <img src={upload} alt="upload" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Post;
