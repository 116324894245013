/**
 * @module ChannelsComponent
 * @description
 * Module for managing and rendering ChannelsComponent.
 *
 * @example
 * // Usage in a React functional component
 * import ChannelsComponent from "./ChannelsComponent";
 *
 * const MyComponent = () => {
 *   return <ChannelsComponent />;
 * };
 *
 * @returns {React.Component} The ChannelsComponent component
 */

import React, { useState, useRef, useEffect } from "react";
import searchIcon from "../../assets/Icons/Search.svg";
import { ReactComponent as AddIcon } from "../../assets/Icons/plus.svg";
import Close from "../../assets/Icons/close.svg";
import add from "../../assets/Icons/Add.svg";
import OutsideClickRef from "../../common/outsideClickRef";
import ChannelPopUp from "./createChannelPopUp";
import ChannelList from "./channelList";
import utility from "../../utility";
import { UserService } from "../../services";
import { useDebouncedCallback } from "use-debounce";
import CreateGroupComponent from "./createGroupPopUp";
import { useSelector } from "react-redux";
import left from "../../assets/Icons/Left.svg";
import { useLocation, useHistory } from "react-router-dom";

/**
 * ChannelsComponent is a functional component that displays a list of channels
 * and provides a search functionality to filter the channels. It also allows
 * creating new channels or groups of channels.
 *
 * @return {JSX.Element} The JSX element representing the ChannelsComponent
 */
const ChannelsComponent = () => {
  const [channelList, setChannelList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [newChannel, setNewChannel] = useState(false);
  const [popup, setPopup] = useState("");
  const createRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const history = useHistory();

  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    setDebounceValue(value);
  }, 500);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
  };

  /**
   * Get the list of channels asynchronously and update the state.
   *
   */
  const getChannelList = async () => {
    setLoading(true);

    try {
      let requestParams = {};
      if (debounceValue) {
        requestParams.searchKey = debounceValue;
      }
      const [error, response] = await utility.parseResponse(
        new UserService().getChannelList(requestParams)
      );

      if (error || !response) return;
      setChannelList(response);

      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChannelList();
    // eslint-disable-next-line
  }, [debounceValue]);

  return (
    <div className="flex flex-col py-5 w-full h-full overflow-y-auto pr-12.5 gap-5">
      <div className="flex justify-between items-center bg-white p-5 rounded-20 gap-5">
        {haveAudit && (
          <div
            onClick={() => {
              if (haveAudit) {
                history.push("/audit-log");
              }
            }}
          >
            <img src={left} alt="left" className="cursor-pointer" />
          </div>
        )}
        <div className="flex p-5 items-center gap-5 rounded-full bg-grey-50 w-full h-11.25">
          <img
            src={searchIcon}
            alt="searchIcon"
            className="opacity-60  w-5 h-5"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            className="font-ManropeLight text-ft3 w-full bg-grey-50 text-black-300 focus:outline-none"
          />
          {!!searchQuery && (
            <img
              onClick={() => {
                setSearchQuery("");
                debouncedSearch("");
              }}
              src={Close}
              alt=""
              className="flex justify-end cursor-pointer w-5 h-5"
            />
          )}
        </div>
        {userPermissions?.indexOf("channels:write:details") > -1 && (
          <div
            className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center relative cursor-pointer"
            onClick={() => {
              setNewChannel(!newChannel);
            }}
          >
            <AddIcon />
          </div>
        )}
        {newChannel && (
          <OutsideClickRef
            state={createRef}
            setState={setNewChannel}
            className={"absolute top-0 right-0 "}
          >
            <div className="flex flex-col absolute w-75 top-29 right-15 rounded-20 shadow-inner z-10 bg-white">
              <button
                className="flex p-5 items-center gap-3.75  w-75 rounded-t-20 hover:bg-grey-50"
                onClick={() => setPopup("NEW_CHANNEL")}
              >
                <img src={add} alt="stop" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  New Channel
                </p>
              </button>
              <hr className="text-grey-50" />
              <button
                className="flex p-5 items-center gap-3.75  w-75 rounded-b-20 hover:bg-grey-50"
                onClick={() => setPopup("NEW_GROUP")}
              >
                <img src={add} alt="stop" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  New group of channel
                </p>
              </button>
            </div>
          </OutsideClickRef>
        )}
      </div>
      <ChannelList
        channelList={channelList}
        getChannelList={getChannelList}
        loading={loading}
        setChannelList={setChannelList}
        searchQuery={searchQuery}
      />
      {popup === "NEW_CHANNEL" && (
        <ChannelPopUp
          handleChannelPopUp={() => setPopup("")}
          title="Create new channel"
          getChannelList={getChannelList}
        />
      )}
      {popup === "NEW_GROUP" && (
        <CreateGroupComponent
          closePopup={() => setPopup("")}
          getChannelList={getChannelList}
        />
      )}
    </div>
  );
};

export default ChannelsComponent;
