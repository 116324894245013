import React, { useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/Icons/plus.svg";
import { award } from "../../constant/commonArray";
import rupee from "../../assets/Icons/rupee.svg";
import rupeeGrey from "../../assets/Icons/rupee-grey.svg";
import more from "../../assets/Icons/More.svg";
import Edit from "../../assets/Icons/Edit.svg";
import Tick from "../../assets/Icons/Tick.svg";
import Delete from "../../assets/Icons/delete.svg";
import RewardEdit from "./rewardEdit";
import WarningPopUp from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
const Reward = () => {
  const [activeDotIndex, setActiveDotIndex] = useState(-1);
  const [isRewardEdit, setRewardEdit] = useState(false);
  const [isRewardDisable, setRewardDisable] = useState(false);
  const [isRewardActivate, setRewardActivate] = useState(false);
  const handleEditClick = () => {
    setActiveDotIndex(-1);
    setRewardEdit(true);
  };
  const handleDisableClick = () => {
    setActiveDotIndex(-1);
    setRewardDisable(true);
  };
  const handleActivateClick = () => {
    setActiveDotIndex(-1);
    setRewardActivate(true);
  };
  const handleActivatePopUp = () => {
    setRewardActivate(!isRewardActivate);
    ShowToast({
      message: "Creating certain amount of Polls activated",
      type: "success",
    });
  };
  const handleDisablePopUp = () => {
    setRewardDisable(!isRewardDisable);
    ShowToast({
      message: "Creating certain amount of Polls disabled",
      type: "success",
    });
  };
  return (
    <div className="py-5 w-full h-full bg-grey-50 overflow-y-auto pr-12.5">
      <div className="flex flex-col gap-5">
        <div className="flex justify-end items-center bg-white p-5 rounded-20">
          <div className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center">
            <AddIcon />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          {award.map((value, index) => (
            <div
              key={index}
              className={`flex justify-between w-full  p-5 rounded-20 relative ${
                value.rewardStatus === "ACTIVE" ? "bg-white" : "bg-grey-350"
              }`}
            >
              <div className="flex items-center gap-4">
                <div
                  className={`flex justify-center items-center rounded-full bg-${value.rewardColor} p-4`}
                >
                  <img src={value.rewardIcon} alt="rewardIcon" />
                </div>
                <p
                  className={`font-ManropeBold font-ft32 ${
                    value.rewardStatus === "ACTIVE"
                      ? "text-black-150"
                      : "text-black-350"
                  }`}
                >
                  {value.rewardStatement}
                </p>
              </div>
              <div className="flex items-center gap-25">
                <div className="flex flex-col">
                  <p className="text-black-600 font-ManropeRegular font-ft2">
                    Threshold
                  </p>
                  <p
                    className={`font-ManropeBold font-ft2 ${
                      value.rewardStatus === "ACTIVE"
                        ? "text-black-150"
                        : "text-black-350"
                    }`}
                  >
                    {value.rewardThreshold}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="text-black-600 font-ManropeRegular font-ft2">
                    Amount
                  </p>
                  <div className="flex">
                    <p
                      className={`font-ManropeBold font-ft2 ${
                        value.rewardStatus === "ACTIVE"
                          ? "text-black-150"
                          : "text-black-350"
                      }`}
                    >
                      {value.rewardAmount}
                    </p>
                    <img
                      src={value.rewardStatus === "ACTIVE" ? rupee : rupeeGrey}
                      alt="rupee"
                    />
                  </div>
                </div>
                <img
                  onClick={() =>
                    setActiveDotIndex(activeDotIndex === index ? -1 : index)
                  }
                  className="cursor-pointer"
                  src={more}
                  alt="more"
                />
                {activeDotIndex === index && (
                  <div className="flex flex-col rounded-20 bg-white absolute right-3 top-23.75 shadow-inner w-75 z-10">
                    <button
                      onClick={() => handleEditClick()}
                      className="flex gap-3.75 p-5"
                    >
                      <img src={Edit} alt="edit" />
                      <p>Edit</p>
                    </button>
                    <hr className="text-grey-50" />
                    <button
                      onClick={() => {
                        if (value.rewardStatus === "ACTIVE") {
                          handleDisableClick();
                        } else {
                          handleActivateClick();
                        }
                      }}
                      className="flex gap-3.75 p-5"
                    >
                      <img
                        src={value.rewardStatus === "ACTIVE" ? Delete : Tick}
                        alt="edit"
                      />
                      <p>
                        {value.rewardStatus === "ACTIVE"
                          ? "Disable"
                          : "Activate"}
                      </p>
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isRewardEdit && (
        <RewardEdit
          onClose={() => {
            setRewardEdit(false);
          }}
        />
      )}
      {isRewardDisable && (
        <WarningPopUp
          title={"Disable awards for ‘Creating certain amount of Polls"}
          description={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
          }
          handleActivateUser={handleDisablePopUp}
          setToggle={setRewardDisable}
        />
      )}
      {isRewardActivate && (
        <WarningPopUp
          title={"Delete Name of Promotion 1"}
          description={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
          }
          handleActivateUser={handleActivatePopUp}
          setToggle={setRewardActivate}
        />
      )}
    </div>
  );
};

export default Reward;
