/**
 * React component representing the dashboard.
 * @function
 * @module UserAccount
 * @returns {JSX.Element} JSX for the Dashboard component.
 */

import React, { useEffect, useRef, useState } from "react";
import searchIcon from "../../assets/Icons/Search.svg";
import Close from "../../assets/Icons/close.svg";
import more from "../../assets/Icons/More.svg";
import pause from "../../assets/Icons/pause.svg";
import tick from "../../assets/Icons/Tick.svg";
import time from "../../assets/Icons/Time.svg";
import { UserService } from "../../services";
import Table from "../../common/table";
import moment from "moment";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import utility from "../../utility";
import DefaultProfile from "../../assets/Icons/default-profile.svg";
import { useDebouncedCallback } from "use-debounce";
import checkMark from "../../assets/Icons/checkMark.svg";
import placeholder from "../../assets/Icons/Placeholder.svg";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import SuspendedUserPopUp from "./details/SuspendedUserPopUp";
import WarningPopup from "../../common/warningPopup";
import OutsideClickRef from "../../common/outsideClickRef";
import PlaceholderWnm from "../../assets/Icons/PlaceholderWnm.png";
import { useSelector } from "react-redux";

/**
 * Dashboard component
 * @function
 * @returns {JSX.Element} JSX for the Dashboard component.
 */
const Dashboard = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [selectedUser, setSelectedUser] = useState({
    id: "",
    username: "",
    keyName: "",
  });
  const [active, setIsActive] = useState(queryParams.get("tab") || "ACTIVE");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const filters = queryParams.get("filters");
    return filters ? JSON.parse(filters) : [];
  });
  const [clickFilter, setClickFilter] = useState(false);
  const [suspendUserPopup, setSupendUserPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [blockedSort, setBlockedSort] = useState("");
  const [seenActivatePopUp, setSeenActivatePopUp] = useState({
    popup: false,
    id: "",
    username: "",
    name: "",
  });
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  const optionsRef = useRef(null);

  const [sort, setSort] = useState(Number(queryParams.get("sort")) || -1);
  const [suspendType, setSuspendType] = useState("");

  const headersActive = [
    { name: "Name" },
    { name: "Registered", sort: true },
    // { name: "Phone" },
    // { name: "Email" },
    { name: "KYC", filter: true, filterLength: selectedFilters?.length },
  ];
  const headerSuspended = [
    { name: "Name" },
    { name: "Registered", sort: true },
    // { name: "Phone" },
    // { name: "Email" },
    { name: "Blocked", startDate: true },
  ];

  const history = useHistory();

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * Handles page change in the paginated table.
   * @function
   * @name handlePageChange
   * @param {number} pageNumber - The selected page number.
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  /**
   * Handles the click event on the "More" button for a user.
   * @function
   * @name handleMoreClick
   * @param {object} user - The user data.
   */
  const handleMoreClick = (user) => {
    if (userId === user?._id) {
      setUserId(null);
    } else {
      setUserId(user?._id);
    }
  };

  /**
   * Toggles the selected filter for KYC status.
   * @function
   * @name toggleSelectedFilter
   * @param {string} filter - The KYC status filter to toggle.
   */

  const toggleSelectedFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
    setCurrentPage(0);
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    setCurrentPage(0);
    setDebounceValue(value);
  }, 500);

  /**
   * Handles the search input change with debouncing.
   * @function
   * @name handleSearch
   * @param {object} e - The input change event.
   */

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPage(0);
  };

  /**
   * Fetches the user list based on current filters and search parameters.
   * @function
   * @name getUserList
   * @async
   */

  const getUserList = async () => {
    setLoading(true);
    let requestParams = {
      status: active,
      sort: sort,
      limit: pageSize,
      skip: currentPage,
    };
    if (selectedFilters.length > 0) {
      requestParams.kycStatus = selectedFilters;
    }
    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getUserList(requestParams)
      );

      if (error || !response) return;
      setUsers(response.usersList);
      setTotalDocs(response?.totalCount);
      setSelectedUser(null);
      setUserId("");
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles user activation.
   * @function
   * @async
   * @name handleActivateUser
   * @param {string} id - The user ID to activate.
   */

  const handleActivateUser = async (id, name) => {
    let response;
    setUpdatingUser(true);
    try {
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: name + " activated",
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getUserList();
      setSeenActivatePopUp((prev) => ({ ...prev, popup: false }));
      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };

  /**
   * Renders the KYC status filter options.
   * @function
   * @name renderFilter
   * @returns {JSX.Element} JSX for the KYC status filter options.
   */

  const renderFilter = () => {
    return (
      clickFilter && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilter(false)}
        >
          <div
            className={`flex w-55 p-5 flex-col items-start gap-5 absolute top-7 -right-20 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("NOT_STARTED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("NOT_STARTED")}
                checked={selectedFilters.includes("NOT_STARTED")}
              />
              {selectedFilters.includes("NOT_STARTED") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("NOT_STARTED");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Not verified
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("COMPLETED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("COMPLETED")}
                checked={selectedFilters.includes("COMPLETED")}
              />
              {selectedFilters.includes("COMPLETED") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("COMPLETED");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Verified
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("PENDING")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("PENDING")}
                checked={selectedFilters.includes("PENDING")}
              />
              {selectedFilters.includes("PENDING") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("PENDING");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                In process
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("REJECTED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("REJECTED")}
                checked={selectedFilters.includes("REJECTED")}
              />
              {selectedFilters.includes("REJECTED") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("REJECTED");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Declined
              </label>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Renders the table body for suspended users.
   * @function
   * @name renderTableBodySuspended
   * @returns {JSX.Element} JSX for the table body of suspended users.
   */

  const handleRedirect = (userData) => {
    userPermissions?.indexOf("user:read:details") > -1 &&
      history.push(`user/userDetails/${userData._id}?tab=SUSPENDED`);
  };

  const renderTableBodySuspended = () => {
    if (users.length > 0 && !loading) {
      return users.map((userData) => {
        return (
          <tr
            key={userData?._id}
            className="hover:bg-grey-350 cursor-pointer gap-4"
          >
            <td
              className="flex gap-5 items-center py-2.5 px-5"
              onClick={() => handleRedirect(userData)}
            >
              {userData?.profilePhoto ? (
                <img
                  src={userData?.profilePhoto}
                  alt="User"
                  className="w-10 h-10 rounded-full"
                />
              ) : (
                <div className="bg-black-100 rounded-full w-10 h-10 relative flex justify-center items-center">
                  <img
                    src={DefaultProfile}
                    alt=""
                    className="absolute w-11.25 h-9.75"
                  />
                </div>
              )}
              <div className={`flex flex-col`}>
                <span className="text-black-100 font-ManropeBold text-ft2 w-35 truncate">
                  {userData.fullName || "-"}
                </span>
                <span className="text-grey-200 font-ManropeRegular text-ft2 w-35 truncate">
                  {userData.userName}
                </span>
              </div>
            </td>
            <td
              className={`text-black-50 font-ManropeMedium text-ft2 w-15per`}
              onClick={() => handleRedirect(userData)}
            >
              <div className="w-35 truncate">
                {moment(userData.addedOn).format("DD MMM[,] YYYY")}
              </div>
            </td>
            {/* <td onClick={() => handleRedirect(userData)}>
              {userData?.phone ? (
                <div className="flex gap-1 text-black-50 font-ManropeMedium text-ft2 w-35 truncate">
                  <span>{formattedCountryCode}</span>
                  <span>{userData?.phone || "-"}</span>
                </div>
              ) : (
                <div className="w-35">-</div>
              )}
            </td> */}
            {/* <td
              className={`text-black-50 font-ManropeMedium text-ft2`}
              onClick={() => handleRedirect(userData)}
            >
              <div className="w-35 xl:w-auto truncate">{userData.email}</div>
            </td> */}
            <td onClick={() => handleRedirect(userData)}>
              <div
                className={`text-black-50 font-ManropeMedium text-ft2  truncate`}
              >
                <span>
                  {moment(userData?.suspensionPeriod?.startDate).format(
                    "D MMM"
                  )}
                  {" - "}
                  {userData?.suspensionType === "PERMANENT"
                    ? "Permanent"
                    : moment(userData?.suspensionPeriod?.endDate).format(
                        "D MMM YYYY HH:mm"
                      )}
                </span>
              </div>
            </td>
            <td>
              <div className="relative">
                {(userPermissions?.indexOf("user:write:activate") > -1 ||
                  userPermissions?.indexOf("user:write:suspend")) > -1 && (
                  <div
                    onClick={() => {
                      handleMoreClick(userData);
                    }}
                    className="w-6.25"
                  >
                    <img src={more} alt="More" className="cursor-pointer" />
                  </div>
                )}
                {userId === userData?._id && (
                  <OutsideClickRef
                    state={optionsRef}
                    setState={() => setUserId(null)}
                  >
                    <div className="absolute right-0 flex flex-col items-start w-75 rounded-20 shadow-inner z-10 bg-white">
                      {userPermissions?.indexOf("user:write:activate") > -1 && (
                        <button
                          onClick={() => {
                            setSeenActivatePopUp((prev) => ({
                              ...prev,
                              popup: true,
                              id: userData?._id,
                              username: userData.userName,
                              name: userData.fullName,
                            }));
                            setSelectedUser("");
                          }}
                          className="flex h-16.25 w-75 px-5 items-center gap-3.75 self-stretch rounded-t-20 hover:bg-grey-350"
                        >
                          <img src={tick} alt="Tick" />
                          <p className="text-ft3 font-ManropeLight text-black-100">
                            Activate
                          </p>
                        </button>
                      )}
                      <hr className="text-grey-50 w-full" />
                      {userPermissions?.indexOf("user:write:suspend") > -1 && (
                        <div
                          onClick={() => {
                            setSupendUserPopup(!suspendUserPopup);
                            setSelectedUser({
                              id: userData?._id,
                              username: userData.userName,
                              keyName: "edit",
                              endDate: userData?.suspensionPeriod?.endDate,
                              reason: userData?.suspensionReason,
                              name: userData?.fullName,
                            });
                            const suspend = utility.getSuspensionType(
                              userData?.suspensionType,
                              userData?.suspensionPeriod?.startDate,
                              userData?.suspensionPeriod?.endDate
                            );
                            setSuspendType(suspend);
                          }}
                          className="flex h-16.25 px-5 items-center gap-3.75 self-stretch  rounded-b-20 hover:bg-grey-350"
                        >
                          <img src={time} alt="Time" />
                          <button className="text-ft3 font-ManropeLight text-black-100">
                            Edit Suspended time
                          </button>
                        </div>
                      )}
                    </div>
                  </OutsideClickRef>
                )}
              </div>
            </td>
          </tr>
        );
      });
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={5}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  /**
   * Renders the table body for active users.
   * @function
   * @name renderTableBodyActive
   * @returns {JSX.Element} JSX for the table body of active users.
   */

  const renderTableBodyActive = () => {
    if (users.length > 0 && !loading) {
      return users.map((userData) => {
        let badgeClassName = "bg-grey-50";

        if (userData.isKYCVerified === "NOT_STARTED") {
          badgeClassName = "bg-yellow-50";
        } else if (userData.status === "DECLINED") {
          badgeClassName = "bg-red-75";
        } else {
          badgeClassName = "bg-green-50";
        }

        return (
          <tr
            key={userData?._id}
            className="hover:bg-grey-350 cursor-pointer gap-4"
          >
            <td
              className="flex gap-5 items-center py-2.5 pl-5"
              onClick={() =>
                userPermissions?.indexOf("user:read:details") > -1 &&
                history.push(`user/userDetails/${userData._id}?tab=ACTIVE`)
              }
            >
              {userData?.profilePhoto ? (
                <img
                  src={userData?.profilePhoto}
                  alt="User"
                  className="w-10 h-10 rounded-full"
                />
              ) : (
                <div className="bg-black-100 rounded-full w-10 h-10 relative flex justify-center items-center">
                  <img
                    src={DefaultProfile}
                    alt=""
                    className="absolute w-11.25 h-9.75"
                  />
                </div>
              )}
              <div className={`flex flex-col`}>
                <span className="text-black-100 font-ManropeBold text-ft2 w-35 truncate">
                  {userData.fullName || "-"}
                </span>
                <span className="text-grey-200 font-ManropeRegular text-ft2 overflow-hidden w-35 truncate">
                  {userData.userName}
                </span>
              </div>
            </td>
            <td
              className={`text-black-50 font-ManropeMedium text-ft2`}
              onClick={() =>
                userPermissions?.indexOf("user:read:details") > -1 &&
                history.push(`user/userDetails/${userData._id}?tab=ACTIVE`)
              }
            >
              <div className="w-35 truncate">
                {moment(userData.addedOn).format("DD MMM[,] YYYY")}
              </div>
            </td>
            {/* <td
              onClick={() =>
                userPermissions?.indexOf("user:read:details") > -1 &&
                history.push(`user/userDetails/${userData._id}?tab=ACTIVE`)
              }
            >
              {userData?.phone ? (
                <div className="flex gap-1 text-black-50 font-ManropeMedium text-ft2 w-35 truncate">
                  <span>{formattedCountryCode}</span>
                  <span>{userData?.phone || "-"}</span>
                </div>
              ) : (
                <div className=" w-35 ">-</div>
              )}
            </td> */}
            {/* <td
              className={`text-black-50 font-ManropeMedium text-ft2`}
              onClick={() =>
                userPermissions?.indexOf("user:read:details") > -1 &&
                history.push(`user/userDetails/${userData._id}?tab=ACTIVE`)
              }
            >
              <div className="w-35 xl:w-auto truncate">{userData.email}</div>
            </td> */}
            <td
              onClick={() =>
                userPermissions?.indexOf("user:read:details") > -1 &&
                history.push(`user/userDetails/${userData._id}?tab=ACTIVE`)
              }
            >
              <div
                className={`h-7 mr-5 flex justify-center items-center text-black-50 font-ManropeMedium text-ft0 w-max px-2 py-1.5 ${badgeClassName} rounded-2xl`}
              >
                <span>
                  {userData.isKYCVerified === "NOT_STARTED"
                    ? "Not verified"
                    : userData?.isKYCVerified}
                </span>
              </div>
            </td>
            <td className="w-5per">
              <div className="relative">
                {userPermissions?.indexOf("user:write:suspend") > -1 && (
                  <div className="w-6.25">
                    <img
                      src={more}
                      alt="More"
                      className="cursor-pointer"
                      onClick={() => {
                        handleMoreClick(userData);
                      }}
                    />
                  </div>
                )}

                {userId === userData?._id && (
                  <OutsideClickRef
                    state={optionsRef}
                    setState={() => setUserId(null)}
                  >
                    <div className="absolute right-0 flex flex-col items-start w-75 rounded-20 shadow-inner z-10 bg-white">
                      <button
                        onClick={() => {
                          setSupendUserPopup(!suspendUserPopup);
                          setSelectedUser({
                            id: userData?._id,
                            username: userData.userName,
                          });
                        }}
                        className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
                      >
                        <img src={pause} alt="Pause" />
                        <p className="text-ft3 font-ManropeLight text-black-100">
                          Suspend
                        </p>
                      </button>
                    </div>
                  </OutsideClickRef>
                )}
              </div>
            </td>
          </tr>
        );
      });
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={5}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery || selectedFilters?.length > 0 ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  const handleTabClick = (newActive) => {
    setIsActive(newActive);
    setCurrentPage(0);
    setDebounceValue("");
    setSearchQuery("");
    setSelectedUser(null);
    setSelectedFilters([]);

    queryParams.set("tab", newActive);
    queryParams.set("sort", sort);
    if (newActive === "SUSPENDED") {
      queryParams.delete("filters");
    }
    history.replace({
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    getUserList();
    queryParams.set("tab", active);
    if (selectedFilters?.length > 0) {
      queryParams.set("filters", JSON.stringify(selectedFilters));
    } else {
      queryParams.delete("filters");
    }
    queryParams.set("sort", sort);
    history.replace({
      search: queryParams.toString(),
    });
    // eslint-disable-next-line
  }, [
    debounceValue,
    pageSize,
    selectedFilters,
    active,
    sort,
    currentPage,
    blockedSort,
  ]);

  return (
    <div className={`w-full h-full overflow-y-auto pr-12.5 py-5`}>
      <div className="flex justify-between bg-white p-5  w-full rounded-20 h-21.25">
        <div className="flex items-center gap-10">
          <p
            onClick={() => {
              handleTabClick("ACTIVE");
            }}
            className={` cursor-pointer ${
              active === "ACTIVE"
                ? "text-ft5 text-black-100 font-ManropeBold"
                : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
            }`}
          >
            Active
          </p>
          <p
            onClick={() => {
              handleTabClick("SUSPENDED");
            }}
            className={` cursor-pointer ${
              active === "SUSPENDED"
                ? "text-ft5 text-black-100 font-ManropeBold"
                : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
            }`}
          >
            Suspended
          </p>
        </div>
        <div className="flex gap-5">
          <div className="flex w-83.75 h-11.25 p-5 items-center gap-3.75 flex-shrink-0 rounded-full bg-grey-50">
            <img
              src={searchIcon}
              alt="searchIcon"
              className="opacity-60 w-5 h-5"
            />
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => handleSearch(e)}
              className="font-ManropeLight text-ft3 w-80per bg-grey-50 text-black-300 focus:outline-none"
            />
            {!!searchQuery && (
              <img
                onClick={() => {
                  setSearchQuery("");
                  debouncedSearch("");
                }}
                src={Close}
                alt=""
                className="flex justify-end cursor-pointer  w-5 h-5"
              />
            )}
          </div>
        </div>
      </div>
      {active === "ACTIVE" ? (
        <Table
          headers={headersActive}
          tableBody={renderTableBodyActive()}
          setPageSize={setPageSize}
          pageSize={pageSize}
          renderFilter={renderFilter()}
          setClickFilter={setClickFilter}
          clickFilter={clickFilter}
          loading={loading}
          setSort={setSort}
          sort={sort}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages - 1}
          totalDocs={totalDocs}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <Table
          headers={headerSuspended}
          tableBody={renderTableBodySuspended()}
          setPageSize={setPageSize}
          pageSize={pageSize}
          setSort={setSort}
          loading={loading}
          sort={sort}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages - 1}
          totalDocs={totalDocs}
          setCurrentPage={setCurrentPage}
          startDate={blockedSort}
          setStartDate={setBlockedSort}
          tagStart={"blockTime"}
        />
      )}
      {suspendUserPopup && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setSupendUserPopup(!suspendUserPopup)}
          username={selectedUser?.username}
          id={selectedUser?.id}
          keyName={selectedUser?.keyName}
          type={suspendType}
          getdetails={getUserList}
          endDate={selectedUser?.endDate}
          reasonType={selectedUser?.reason}
          name={selectedUser?.name}
          setActivatePopUp={setSeenActivatePopUp}
        />
      )}
      {seenActivatePopUp.popup && (
        <WarningPopup
          handleActivateUser={() =>
            handleActivateUser(seenActivatePopUp?.id, seenActivatePopUp?.name)
          }
          setToggle={() =>
            setSeenActivatePopUp({ ...seenActivatePopUp, popup: false })
          }
          title={`Activate ${seenActivatePopUp?.username} ?`}
          description={
            "User will be activated. All created content will be available for other users"
          }
          text={`${seenActivatePopUp.name} activated`}
          loading={updatingUser}
        />
      )}
    </div>
  );
};

export default Dashboard;
