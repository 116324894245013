import React, { useState, useCallback, useEffect } from "react";
import left from "../../../assets/Icons/Left.svg";
import ZoomIn from "../../../assets/Icons/zoom-in.svg";
import ZoomOut from "../../../assets/Icons/zoom-out.svg";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Cropper from "react-easy-crop";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";
import { CircularProgress } from "@mui/material";

/**
 * CropVideo component for handling Video selection and cropping.
 * @component
 * @param {Object} props - Component props.
 * @param {function} props.setImagePopup - Function to control the visibility of the Video popup.
 * @param {function} props.setCroppedImage - Function to set the cropped Video.
 * @param {function} props.setFile - Function to set the selected file.
 * @param {File} props.file - Selected file for Video upload.
 * @param {function} props.setUploadProfile - Function to update the upload profile state.
 * @param {string} [props.edit="edit"] - Edit mode ("edit" or "add").
 * @returns {JSX.Element} React component.
 */

const CropVideo = ({
  setState,
  file,
  setVideoPopup,
  index,
  ffmpeg,
  isScriptLoaded,
  handleAddMedia,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  /**
   * Callback function for when cropping is complete.
   * @param {Object} croppedArea - The cropped area coordinates.
   * @param {Object} croppedAreaPixels - The cropped area in pixels.
   */

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedVideo = useCallback(async () => {
    try {
      setLoading(true);
      ffmpeg.FS("writeFile", "input.mp4", await window.FFmpeg.fetchFile(file));
      await ffmpeg.run(
        "-i",
        "input.mp4",
        "-vf",
        `crop=${croppedAreaPixels.width}:${croppedAreaPixels.height}:${croppedAreaPixels.x}:${croppedAreaPixels.y}`,
        "-c:v",
        "libx264",
        "output.mp4"
      );
      const data = ffmpeg.FS("readFile", "output.mp4");
      const trimmedFile = new File([data.buffer], `cropped_video${index}.mp4`, {
        type: "video/mp4",
      });

      handleAddMedia(trimmedFile, index);
      setLoading(false);
      setVideoPopup({ popup: false, index: "" });
    } catch (error) {
      console.error(error);
    }
  }, [croppedAreaPixels, file, index, setVideoPopup, ffmpeg, handleAddMedia]);

  /**
   * Convert Data URL to File.
   * @param {string} dataUrl - The Data URL to convert.
   * @param {string} fileName - The name of the file.
   * @returns {Promise<File>} A promise with the converted File object.
   */

  const onZoomChange = useCallback((newZoom) => {
    setZoom(newZoom);
  }, []);

  const onZoomIn = () => {
    const newZoom = Math.min(zoom + 0.1, 3);
    setZoom(newZoom);
  };

  const onZoomOut = () => {
    const newZoom = Math.max(zoom - 0.1, 1);
    setZoom(newZoom);
  };

  useEffect(() => {
    setVideoUrl(URL.createObjectURL(file));
  }, [file]);

  return (
    <div
      style={{ zIndex: 1000 }}
      className="bg-grey-100 bg-opacity-70  fixed flex justify-center items-center right-0 top-0 h-full w-full"
    >
      <div className="bg-white overflow-auto flex flex-col h-2/3 w-115 z-50 p-7.5 rounded-20">
        <div className="w-full flex justify-between items-center">
          <div className="flex gap-5">
            <img
              onClick={() => {
                if (!loading) {
                  setVideoPopup({
                    popup: false,
                    indix: null,
                  });
                }
              }}
              src={left}
              alt=""
              className={`cursor-pointer`}
            />
            <span className="text-ft4 font-ManropeBold text-black-100">
              Crop Video
            </span>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              if (!loading) {
                setVideoPopup({
                  popup: false,
                  indix: null,
                });
              }
            }}
          >
            <Close />
          </div>
        </div>

        <div className="h-3/4  mt-5 p-4 text-center">
          <div className="h-80per">
            <div className="bg-grey-500 p-5 w-full h-full relative">
              <Cropper
                video={videoUrl}
                crop={crop}
                zoom={zoom}
                zoomSpeed={4}
                maxZoom={3}
                zoomWithScroll={true}
                showGrid={false}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                className="z-10 w-full h-full"
                cropShape="rect"
              />
            </div>
          </div>
          <div className="h-auto mt-10 flex gap-3 items-center">
            <img
              src={ZoomOut}
              alt=""
              onClick={onZoomOut}
              className="cursor-pointer"
            />

            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="zoom"
              onChange={onZoomChange}
              className="range"
            />

            <img
              src={ZoomIn}
              alt=""
              onClick={onZoomIn}
              className="cursor-pointer"
            />
          </div>
        </div>

        <div className="mt-5">
          <button
            disabled={!isScriptLoaded || loading}
            onClick={() => {
              showCroppedVideo();
            }}
            className={`w-full rounded-full ${
              !isScriptLoaded || loading
                ? "bg-grey-50 text-black-75"
                : "bg-orange-50 text-black-100"
            } text-ft3 font-ManropeBold  h-16.25`}
          >
            {loading ? <CircularProgress className="text-orange-50" /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CropVideo;
