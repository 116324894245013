import { keyTypeConstants } from "../constant/commonArray";
import { sessionManager } from "../managers/sessionManager";
import utility from "../utility";

const { USER, AUTH0_ID_TOKEN } = keyTypeConstants;

const getToken = () => sessionManager.getDataFromCookies(AUTH0_ID_TOKEN);

const storeData = (data) => {
  utility.storeToLocalStorage(USER, data);
};

const clearData = () => {
  localStorage.clear();
  sessionManager.removeDataFromCookies(AUTH0_ID_TOKEN);
};

const token = getToken();

const getUserData = () => {
  if (token) return utility.getDataFromLocalStorage(USER);
  localStorage.clear();
  return null;
};

const data = getUserData();
const userData = !!data ? data : {};

const initialState = {
  isLoggedIn: userData?.isLoggedIn || false,
  name: userData?.name || "",
  email: userData?.email || "",
  profilePicture: userData?.profilePicture || "",
  accessToken: userData?.accessToken || "",
  userId: userData?.userId || "",
  roleDetails: userData?.roleDetails || [],
  phone: userData?.phone || "",
  staffId: userData?.staffId || "",
  countryCode: userData?.countryCode || "",
  gmapsLoaded: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_USER":
      const { isLoggedIn, ...localData } = {
        ...state,
        ...action.payload,
      };
      storeData(localData);
      return { ...state, ...action.payload, isLoggedIn: true };
    case "REMOVE_USER":
      clearData();
      return {
        isLoggedIn: false,
        name: "",
        email: "",
        profilePicture: "",
        accessToken: "",
      };
    default:
      return state;
  }
}
