/**
 * @module PollDetailsCompoenent
 * @description
 * Module for managing and rendering PollDetailsCompoenent.
 *
 * @example
 * // Usage in a React functional component
 * import PollDetailsCompoenent from "./PollDetailsCompoenent";
 *
 * const MyComponent = () => {
 *   return <PollDetailsCompoenent />;
 * };
 *
 * @returns {React.Component} The PollDetailsCompoenent component
 */

import React from "react";
import PollDetails from "./pollDetails";

const PollDetailsCompoenent = () => {
  return (
    <div className="w-full h-full overflow-y-auto pr-12.5 ">
      <PollDetails />
    </div>
  );
};

export default PollDetailsCompoenent;
