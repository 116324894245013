import React, { useEffect, useState } from "react";
import PollType from "../../../assets/Icons/poll-type-icon.svg";
import defaultImage from "../../../assets/Icons/signupProfileSmall.png";
import CoverMedia from "./coverMedia";
import OtherLink from "../../../assets/Icons/other-link-white.svg";
import utility from "../../../utility";
import LearnMore from "./learnMore";
import AudioPreview from "../createPoll/audioPreview";
import LinkPreview from "../createPoll/linkPreview";
import AudioWhite from "../../../assets/Icons/audio-white.svg";

/**
 * OpenQuestion component for displaying a poll or survey question with media and interaction options.
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.type="POLL"] - Type of question (default: "POLL")
 * @param {Array} props.polls - Array of polls or survey questions
 * @param {Function} props.setPreview - Function to toggle preview mode
 * @param {Function} props.setStartPollIndex - Function to set the starting index of polls
 * @param {number} props.startPollIndex - Starting index of the current poll or survey question
 * @param {Object} props.data - Data object containing question details
 * @param {Object} props.account - Account details of the creator
 * @param {Date} props.surveyTime - Time for survey completion
 * @returns {JSX.Element} - Rendered component
 */
const OpenQuestion = ({
  type = "POLL",
  polls,
  setPreview,
  setStartPollIndex,
  startPollIndex,
  data,
  account,
  surveyTime,
}) => {
  const [isLearnMore, setIsLearnMore] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const filterVideoNImages = data?.post?.media?.filter((item) => {
    return item?.type !== "link" && item?.type !== "mp3";
  });
  const filterAudio = data?.post?.media?.filter((item) => {
    return item?.type === "mp3";
  });
  const filterLink = data?.post?.media?.filter((item) => {
    return item?.type === "link";
  });
  const filterVideoNImagesFromMedia = data?.media?.filter((item) => {
    const fileName =
      item instanceof File ? utility.getFileType(item.name) : item.type;

    return fileName !== "audio" && fileName !== "mp3" && fileName !== "link";
  });

  const filterAudioFromMedia = data?.media?.filter((item) => {
    const fileName =
      item instanceof File ? utility.getFileType(item.name) : item.type;

    return fileName === "audio" || fileName === "mp3";
  });

  const filterLinkFromMedia = data?.media?.filter((item) => {
    return !(item instanceof File) && item.type === "link";
  });

  const audioFiles = [...(filterAudioFromMedia || []), ...(filterAudio || [])];

  const linkFiles = [...(filterLinkFromMedia || []), ...(filterLink || [])];
  const media = [
    ...(filterVideoNImages || []),
    ...(filterVideoNImagesFromMedia || []),
  ];

  const allMedia = [...(data?.post?.media || []), ...(data?.media || [])];

  function getClassName(media, type) {
    if (media?.length > 0 && type === "SURVEY") {
      return "h-65per";
    } else if (type === "POLL") {
      return "h-auto";
    } else {
      return "h-90per";
    }
  }

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = Date.now();
      const time = data?.info?.activePeriod?.endDate || surveyTime;
      const timeDifference = time - currentTime;
      const remainingMilliseconds = Math.max(0, timeDifference);

      const remainingDays = Math.floor(
        remainingMilliseconds / (1000 * 60 * 60 * 24)
      );
      const remainingHours = Math.floor(
        (remainingMilliseconds / (1000 * 60 * 60)) % 24
      );
      const remainingMinutes = Math.floor(
        (remainingMilliseconds / (1000 * 60)) % 60
      );

      setTimeLeft({
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
      });
    };

    calculateTimeLeft();

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 60000);

    return () => clearInterval(timer);
  }, [data, surveyTime]);

  const date = `${timeLeft.days ? timeLeft.days : ""}${
    timeLeft.days ? "d" : ""
  } ${timeLeft.hours}hr ${timeLeft?.days ? "" : timeLeft.minutes}${
    timeLeft?.days ? "" : "m"
  }`;

  return (
    <div className="bg-white  relative flex flex-col h-full w-106.25 z-50 p-5 ">
      {!isLearnMore ? (
        <div className="bg-black-100 overflow-y-auto overflow-x-hidden scrollbar-hide w-full h-full">
          {data?.post?.media?.length > 0 || data?.media?.length > 0 ? (
            <div className="flex justify-center items-center h-35per">
              <CoverMedia
                data={media}
                name={data?.creator?.fullName || account?.fullName}
                profilePhoto={
                  data?.creator?.profilePhoto || account?.profilePhoto
                }
                endDate={`${date} left`}
              />
            </div>
          ) : (
            <div className="px-5 pt-5 w-full h-10per">
              {" "}
              <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-2.5">
                  <img
                    src={
                      data?.creator?.profilePhoto ||
                      account?.profilePhoto ||
                      defaultImage
                    }
                    alt="user"
                    className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
                  />
                  <p className="font-ManropeBold text-ft2 text-center text-white">
                    {data?.creator?.fullName || account?.fullName || "Pollpapa"}
                  </p>
                </div>
                <div className="flex items-center justify-center w-auto p-2 rounded-20  bg-white backdrop-blur-xl bg-opacity-10">
                  <p className="font-ManropeBold text-ft2 w-full text-white">
                    {`${date} left`}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div
            className={`p-5 flex flex-col overflow-y-auto justify-between ${getClassName(
              media,
              type
            )} `}
          >
            <div className="flex flex-col gap-5 justify-center items-center">
              <div className="flex flex-col gap-3.75 w-full">
                {type === "POLL" ? (
                  <div className="flex gap-1">
                    {data?.channels?.map((item, index) => (
                      <p
                        key={index}
                        className="flex items-center justify-center rounded-20 text-black-100 font-ManropeMedium text-ft0 px-1.5"
                        style={{
                          backgroundColor: utility.getRandomColors(index),
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                ) : (
                  <div className="px-2 py-1 bg-black-50 rounded-full mt-3 w-max text-ft0 font-ManropeMedium text-white">
                    <span>
                      {" "}
                      Question {startPollIndex + 1} of {polls?.length}
                    </span>
                  </div>
                )}
                <div className="pt-1 gap-2 inline-block relative">
                  <span className="text-ft42 font-ManropeBold text-white ">
                    {" "}
                    {data?.post?.question || data?.question}
                    {type === "POLL" && (
                      <span className="inline-flex gap-2 ml-2 mt-1 items-center px-2 py-1 border-1 rounded-20 w-max border-white border-opacity-10 text-ft0 font-ManropeMedium text-white">
                        <img src={PollType} alt="Icon" className="w-4 h-4" />{" "}
                        {utility.capitalizeFirstLetter(
                          data?.post?.pollType || data?.pollType
                        )}{" "}
                        {utility.capitalizeFirstLetter(
                          data?.type || data?.questionType
                        )}
                      </span>
                    )}
                  </span>{" "}
                </div>
                {(data?.post?.description || data?.description) && (
                  <div onClick={() => setIsLearnMore(!isLearnMore)}>
                    <span className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer">
                      Learn more
                    </span>
                  </div>
                )}
                <div>
                  {(audioFiles?.length > 0 || linkFiles?.length > 0) && (
                    <div className="flex gap-3 items-center">
                      <span
                        onClick={() => setViewDetails(!viewDetails)}
                        className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer"
                      >
                        {!viewDetails ? "View details" : "Hide details"}
                      </span>
                      {audioFiles?.length > 0 && (
                        <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-black-50 items-center">
                          <img src={AudioWhite} alt="" className="h-4 w-4" />
                          <span className="text-white font-ManropeBold text-ft0">
                            {audioFiles?.length || 0}
                          </span>
                        </div>
                      )}
                      {linkFiles?.length > 0 && (
                        <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-black-50 items-center">
                          <img src={OtherLink} alt="" className="h-4 w-4" />
                          <span className="text-white font-ManropeBold text-ft0">
                            {linkFiles?.length || 0}
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  {viewDetails && (
                    <div>
                      {allMedia?.map((item, idx) => {
                        let fileType = "";
                        if (item instanceof File) {
                          fileType = utility.getFileType(item.name);
                        } else {
                          fileType = item?.type;
                        }

                        if (fileType === "audio" || fileType === "mp3") {
                          return (
                            <div className="mt-2" key={idx}>
                              {" "}
                              <AudioPreview
                                fileName={
                                  item?.fileName ||
                                  data?.audioName[idx]?.fileName
                                }
                                item={item}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                      {linkFiles?.map((item, idx) => (
                        <div key={idx} className="mt-2">
                          <LinkPreview
                            link={item?.url}
                            bgColor={"bg-black-50"}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="h-16.25 pr-4 pl-8 rounded-full bg-black-50 w-full flex justify-between items-center">
                  {" "}
                  <input
                    type="text"
                    placeholder="Add an answer..."
                    className=" text-white w-85per bg-transparent  placeholder-white placeholder-opacity-60   font-ManropeRegular text-ft1  h-10 outline-none "
                  />
                  <div className="p-2.5 bg-black-300 rounded-full">
                    <img src={OtherLink} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {type !== "POLL" && (
              <div className="w-full h-16.25 mt-5 rounded-full bg-orange-50">
                <button
                  onClick={() => {
                    if (polls?.length === startPollIndex + 1) {
                      setPreview(false);
                    } else if (polls?.length > startPollIndex) {
                      setStartPollIndex(startPollIndex + 1);
                    }
                    setViewDetails(false);
                  }}
                  className="text-ft2 w-full h-16.25 font-ManropeBold text-black-50 flex justify-center items-center"
                >
                  {polls?.length === startPollIndex + 1 ? "Done" : "Continue"}
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <LearnMore
          description={data?.post?.description || data?.description}
          setIsLearnMore={setIsLearnMore}
        />
      )}
    </div>
  );
};

export default OpenQuestion;
