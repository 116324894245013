import React, { useState } from "react";
import Channel from "../../../../assets/Icons/channel.svg";
import ActivePeriodIcon from "../../../../assets/Icons/active-period.svg";
import Right from "../../../../assets/Icons/Right.svg";
import ToggleSwitch from "../../../../common/component/toggleSwitch";
import ChannelPopup from "./channelPopup";
import ChannelDetailsPopup from "./channelDetailsPopup";
import ActivePeriod from "./activePeriod";
import utility from "../../../../utility";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const numberRegex = new RegExp(/^\d+$/);

/**
 * Setting Component renders settings for creating or editing a poll or survey.
 * @component
 * @param {object} props - Component props
 * @param {Function} props.setState - Function to set the state of the component
 * @param {object} props.state - Current state of the component
 * @param {Function} props.setEndTime - Function to set the end time
 * @param {Date} props.endTime - End time of the active period
 * @param {Function} props.setStartTime - Function to set the start time
 * @param {Date} props.startTime - Start time of the active period
 * @param {Function} props.setEndDate - Function to set the end date
 * @param {Date} props.endDate - End date of the active period
 * @param {Function} props.setStartDate - Function to set the start date
 * @param {Date} props.startDate - Start date of the active period
 * @param {Function} props.setSteps - Function to set the steps of the component
 * @param {Function} props.handleCreatePoll - Function to handle creating a poll
 * @param {Function} props.handleCreateSurvey - Function to handle creating a survey
 * @param {boolean} props.creating - Flag indicating if the component is in the process of creating
 * @param {Function} props.handleEditPoll - Function to handle editing a poll
 * @param {Function} props.handleEditSurvey - Function to handle editing a survey
 * @returns {JSX.Element} Setting component
 */
const Setting = ({
  setState,
  state,
  setEndTime,
  endTime,
  startTime,
  setStartTime,
  setEndDate,
  endDate,
  startDate,
  setStartDate,
  setSteps,
  handleCreatePoll,
  handleCreateSurvey,
  creating,
  handleEditPoll,
  handleEditSurvey,
}) => {
  const [openChannel, setOpenChannel] = useState({
    typeOpen: "",
    name: "",
    data: [],
  });
  const [activePeriodOpen, setActivePeriodOpen] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const pollId = queryParams.get("pollId");

  return (
    <div className="mt-15 flex flex-col">
      <div className="flex gap-4 w-full">
        <div className="flex flex-col w-1/2">
          <div className="flex flex-col gap-5">
            <div
              onClick={() =>
                setOpenChannel((prev) => ({ ...prev, typeOpen: "Channels" }))
              }
              className="h-22.25 rounded-30 bg-grey-50 w-full flex justify-between items-center px-6 cursor-pointer"
            >
              <div className="flex gap-5 items-center">
                <img src={Channel} alt="" />
                <div className="flex flex-col gap-1">
                  <span className="font-ManropeBold text-ft3 text-black-100">
                    Channels
                  </span>
                  <div className="flex gap-1">
                    {state?.channels?.map((item, idx) => (
                      <span
                        key={idx}
                        className="text-black-350 font-ManropeRegular text-ft1"
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <img src={Right} alt="" />
              </div>
            </div>
            <div
              onClick={() => setActivePeriodOpen(true)}
              className="h-22.25 rounded-30 bg-grey-50 w-full flex justify-between items-center px-6 cursor-pointer"
            >
              <div className="flex gap-5 items-center">
                <img src={ActivePeriodIcon} alt="" />
                <div className="flex flex-col gap-1">
                  <span className="font-ManropeBold text-ft3 text-black-100">
                    Active period
                  </span>
                  <div className="flex gap-1 items-center">
                    <span className="text-black-350 font-ManropeRegular text-ft1">
                      {startDate?.getDate()} {utility.formatMonth(startDate)}{" "}
                      {startDate?.getFullYear()}
                    </span>
                    {startDate && endDate && <span>-</span>}
                    <span className="text-black-350 font-ManropeRegular text-ft1">
                      {endDate?.getDate()} {utility.formatMonth(endDate)}{" "}
                      {endDate?.getFullYear()}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <img src={Right} alt="" />
              </div>
            </div>
          </div>

          <div className="pt-15 ">
            <div className="flex items-center justify-between gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Add rewards to poll
              </label>
              <div>
                <ToggleSwitch
                  setValue={() =>
                    setState((prev) => ({
                      ...prev,
                      isRewardAdded: !state?.isRewardAdded,
                      byPassReview: false,
                    }))
                  }
                  value={state?.isRewardAdded}
                />
              </div>
            </div>
            {state?.isRewardAdded && (
              <div>
                <div className="pt-10">
                  <span className="text-ft3 font-ManropeMedium text-black-100">
                    Everyone who votes will receive the reward you have
                    assigned. Each vote will be rewarded while your balance is
                    positive.
                  </span>
                </div>
                <div className="flex flex-col gap-5 pt-5">
                  <input
                    type="text"
                    placeholder="Reward per vote, USD"
                    value={state?.reward?.rewardPerVote}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === "" || numberRegex.test(inputValue)) {
                        setState((prev) => ({
                          ...prev,
                          reward: {
                            ...prev.reward,
                            rewardPerVote: e.target.value,
                          },
                        }));
                      }
                    }}
                    className="rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
                  />
                  <input
                    type="text"
                    placeholder="Budget limit, USD (optional)"
                    value={state?.reward?.budgetLimit}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === "" || numberRegex.test(inputValue)) {
                        setState((prev) => ({
                          ...prev,
                          reward: {
                            ...prev.reward,
                            budgetLimit: e.target.value,
                          },
                        }));
                      }
                    }}
                    className="rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
                  />
                </div>
              </div>
            )}

            {/* {!state?.isForPollpapa && !state?.isRewardAdded && (
              <div className="flex justify-between gap-1 items-center mt-2">
                <label
                  htmlFor=""
                  className="text-black-100 text-ft3 font-ManropeMedium"
                >
                  Submit for approval?
                </label>
                <div>
                  <ToggleSwitch
                    setValue={() =>
                      setState((prev) => ({
                        ...prev,
                        byPassReview: !state?.byPassReview,
                      }))
                    }
                    value={!state?.byPassReview}
                  />
                </div>
              </div>
            )} */}
            <div className="flex items-center justify-between gap-1 mt-2">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Sensitive content
              </label>
              <div>
                <ToggleSwitch
                  setValue={() =>
                    setState((prev) => ({
                      ...prev,
                      isSensitive: !state?.isSensitive,
                    }))
                  }
                  value={state?.isSensitive}
                />
              </div>
            </div>
          </div>

          {/* <div className="flex pt-5 items-center justify-between gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Anonymous poll
              </label>
              <div>
                <ToggleSwitch setValue={() =>
                  setState((prev) => ({
                    ...prev,
                    isAnonymous: !state?.isAnonymous,
                  }))
                }
                  value={state?.isAnonymous}
                />
              </div>
            </div> */}
        </div>
        {state?.reason?.length > 0 && (
          <div className="bg-red-75 rounded-xl p-5 h-max-content w-1/2 flex flex-col gap-1 text-ft1">
            <span className="text-ft2 font-ManropeBold text-black-100">
              Reason:
            </span>
            {state?.reason?.map((item, idx) => (
              <span
                key={idx}
                className="text-ft2 font-ManropeRegular text-black-100"
              >
                {item?.description}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="flex justify-end mt-15 gap-7.5">
        <button
          onClick={() => {
            if (state?.questionType !== "Open Question") {
              setSteps(1);
            } else {
              setSteps(0);
            }
          }}
          className="border border-black-100 border-opacity-20 font-ManropeBold rounded-full w-55 h-16.25 text-ft2"
        >
          Cancel
        </button>
        {pollId ? (
          <button
            onClick={
              state?.questionType === "Survey"
                ? handleEditSurvey
                : handleEditPoll
            }
            disabled={
              creating ||
              state?.channels?.length === 0 ||
              !startDate ||
              !endDate
            }
            className={`${
              creating ||
              state?.channels?.length === 0 ||
              !startDate ||
              !endDate
                ? "bg-grey-50"
                : "bg-orange-50"
            } rounded-full w-55 h-16.25 font-ManropeBold text-ft2`}
          >
            {creating ? (
              <CircularProgress className="text-orange-50" />
            ) : (
              "Submit for Review"
            )}
          </button>
        ) : (
          <button
            onClick={
              state?.questionType === "Survey"
                ? handleCreateSurvey
                : handleCreatePoll
            }
            disabled={
              creating ||
              state?.channels?.length === 0 ||
              !startDate ||
              !endDate
            }
            className={`${
              creating ||
              state?.channels?.length === 0 ||
              !startDate ||
              !endDate
                ? "bg-grey-50"
                : "bg-orange-50"
            } rounded-full w-55 h-16.25 font-ManropeBold text-ft2`}
          >
            {creating ? (
              <CircularProgress className="text-orange-50" />
            ) : (
              "Submit for Review"
            )}
          </button>
        )}
      </div>

      {openChannel.typeOpen === "Channels" && (
        <ChannelPopup
          setOpenChannel={setOpenChannel}
          openChannel={openChannel}
        />
      )}
      {openChannel.typeOpen === "ChannelsDetails" && (
        <ChannelDetailsPopup
          setOpenChannel={setOpenChannel}
          openChannel={openChannel}
          setState={setState}
          state={state}
        />
      )}
      {activePeriodOpen && (
        <ActivePeriod
          endDate={endDate}
          startDate={startDate}
          endTime={endTime}
          startTime={startTime}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          setEndTime={setEndTime}
          setStartTime={setStartTime}
          setActivePeriodOpen={setActivePeriodOpen}
        />
      )}
    </div>
  );
};

export default Setting;
