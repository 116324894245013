import React from "react";
import { ReactComponent as Close } from "../../assets/Icons/close.svg";

/**
 * ImagePopUp component for displaying a popup with image gallery.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {boolean} props.toggleImagePopUp - Flag to toggle the image popup.
 * @param {Function} props.setToggleImagePopUp - Function to set the state of the image popup.
 * @param {Array} props.images - Array of images to display in the gallery.
 * @returns {JSX.Element} ImagePopUp component.
 */

const ImageViewer = ({
  toggleImagePopUp,
  setToggleImagePopUp = () => {},
  image,
}) => {
  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed  right-0 top-0 h-full w-full">
      <div className="flex justify-end pt-7.5 px-7.5">
        <Close
          onClick={() => setToggleImagePopUp(!toggleImagePopUp)}
          className="cursor-pointer"
        />
      </div>
      <div className="flex justify-center mt-10 h-full w-full">
        <div className="relative overflow-hidden z-50 rounded-20  h-80per flex items-center justify-center">
          <img
            src={image}
            alt=""
            className="h-full w-full object-contain rounded-20"
          />
        </div>
      </div>
    </div>
  );
};

export default ImageViewer;
