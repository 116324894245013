import React, { useState } from "react";
import { ReactComponent as AddImage } from "../assets/Icons/paperclip.svg";
import { Languages } from "../constant/commonArray";
import SelectImagePopup from "../modules/entityAccount/createEntity/selectImagePopup";

/**
 * Content component for configuring media, language, heading, and description in an entity or promotion.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.setSteps - Function to set the steps in the content creation process.
 * @param {Object} props.state - The current state of the component.
 * @param {Function} props.setState - Function to set the state of the component.
 * @param {Function} props.setCroppedImage - Function to set the cropped image.
 * @param {string} props.croppedImage - The URL of the cropped image.
 * @param {Function} props.setFile - Function to set the file.
 * @param {string} props.file - The selected file.
 * @param {Function} props.setUploadProfile - Function to set the upload profile.
 * @param {string} props.keyName - The key name for conditional rendering (default or promotion).
 * @returns {JSX.Element} Content component.
 */

const Content = ({
  setSteps = () => {},
  state,
  setState = () => {},
  setCroppedImage = () => {},
  croppedImage,
  setFile,
  file,
  setUploadProfile = () => {},
  keyName = "default",
}) => {
  const [imagePopup, setImagePopup] = useState(false);
  const handleSpanClick = () => {
    setImagePopup(true);
  };

  return (
    <div className="flex flex-col gap-7.5">
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10 gap-2.5">
        <p className="text-black-100 font-ManropeMedium text-ft3">Media</p>
        {!croppedImage ? (
          <div
            onClick={handleSpanClick}
            className="flex gap-2.5 w-107px items-center cursor-pointer"
          >
            <AddImage />
            <span className="text-orange-50 font-ManropeBold text-ft2">
              Add media
            </span>
          </div>
        ) : (
          <div>
            <img src={croppedImage} alt="" className="w-30 h-30 rounded-20" />
          </div>
        )}
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10 gap-22px">
        <div className="flex justify-between items-center">
          <p className="text-black-100 font-ManropeMedium text-ft3">
            Languages
          </p>
          <p className="font-ManropeExtraBold text-orange-50">
            Choose a translation service
          </p>
        </div>
        <div className="flex gap-2.5 overflow-x-scroll scrollbar-hide">
          {Languages.map((item) => (
            <div
              onClick={() =>
                setState((prev) => ({ ...prev, language: item?.name }))
              }
              className={`flex p-2 rounded-20 items-center cursor-pointer ${
                item?.name === state?.language ? "bg-orange-50" : "bg-grey-50"
              }`}
            >
              <p
                className={`text-ft2 text-black-100 ${
                  item?.name === state?.language
                    ? "font-ManropeBold"
                    : "font-ManropeRegular"
                }`}
              >
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10">
        <p className="text-black-100 font-ManropeMedium text-ft3">Heading</p>
        <div className="h-16.25 w-1/2 rounded-full flex items-center justify-between bg-grey-50 px-5">
          <input
            type="text"
            value={state?.heading}
            onChange={(e) =>
              setState((prev) => ({ ...prev, heading: e.target.value }))
            }
            className="focus:outline-none h-full rounded-full bg-grey-50 w-full font-ManropeRegular text-ft3 text-black-100 px-1"
          />
          <p className="text-black-600 font-ManropeRegular text-ft3 pl-2">
            {state?.heading?.length}/100
          </p>
        </div>
        {state?.heading?.length > 100 && (
          <p className="text-red-50 font-ManropeRegular text-ft3 pt-2">
            Heading should not more than 100
          </p>
        )}
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10">
        <p className="text-black-100 font-ManropeMedium text-ft3">
          Description (Optional)
        </p>
        <div className="h-16.25 w-1/2 rounded-full flex items-center justify-between bg-grey-50 px-5">
          <input
            type="text"
            value={state?.description}
            onChange={(e) =>
              setState((prev) => ({ ...prev, description: e.target.value }))
            }
            className="focus:outline-none h-full rounded-full bg-grey-50 w-full font-ManropeRegular text-ft3 text-black-100 px-1"
          />
          <p className="text-black-600 font-ManropeRegular text-ft3 pl-2">
            {state?.description?.length}/200
          </p>
        </div>
        {state?.description?.length > 200 && (
          <p className="text-red-50 font-ManropeRegular text-ft3 pt-2">
            Description should not more than 200
          </p>
        )}
      </div>
      <hr className="text-grey-50" />

      {keyName === "promotion" && (
        <div className="flex flex-col px-10">
          <p className="text-black-100 font-ManropeMedium text-ft3">
            Text in button
          </p>
          <div className="h-16.25 w-1/2 rounded-full flex items-center justify-between bg-grey-50 px-5">
            <input
              type="text"
              value={state?.buttonText}
              onChange={(e) =>
                setState((prev) => ({ ...prev, buttonText: e.target.value }))
              }
              className="focus:outline-none rounded-full h-full bg-grey-50 w-full font-ManropeRegular text-ft3 text-black-100"
            />
            <p className="text-black-600 font-ManropeRegular text-ft3 pl-2">
              {state?.buttonText?.length}/100
            </p>
          </div>
          {state?.buttonText?.length > 100 && (
            <p className="text-red-50 font-ManropeRegular text-ft3 pt-2">
              Text button should not more than 100
            </p>
          )}
        </div>
      )}
      <hr className={`text-grey-50 ${keyName !== "promotion" && "hidden"}`} />
      <footer className="flex justify-end items-end pr-7.5 gap-5 rounded-20 bg-white">
        <button
          onClick={() => setSteps(0)}
          className="flex justify-center items-center rounded-full border border-solid border-black-20 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
        >
          Cancel
        </button>
        <button
          onClick={() => setSteps(2)}
          disabled={
            !croppedImage ||
            !state?.heading ||
            state?.description?.length > 200 ||
            state?.heading?.length > 100 ||
            (keyName === "promotion" && !state?.buttonText) ||
            (keyName === "promotion" && state?.buttonText?.length > 100)
          }
          className={`flex justify-center items-center rounded-full border border-solid border-transparent ${
            !croppedImage ||
            !state?.heading ||
            state?.description?.length > 200 ||
            state?.heading?.length > 100 ||
            (keyName === "promotion" && !state?.buttonText) ||
            (keyName === "promotion" && state?.buttonText?.length > 100)
              ? "bg-grey-50"
              : "bg-orange-50"
          }  py-5 w-55 text-black-100 font-ManropeBold text-ft2`}
        >
          Create
        </button>
      </footer>
      {imagePopup && (
        <SelectImagePopup
          setImagePopup={setImagePopup}
          setCroppedImage={setCroppedImage}
          croppedImage={croppedImage}
          setFile={setFile}
          file={file}
          edit={"add"}
          setUploadProfile={setUploadProfile}
        />
      )}
    </div>
  );
};

export default Content;
