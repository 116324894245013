export default {
  control: {
    backgroundColor: "rgba(243, 243, 243, 1)",
    fontSize: 16,
    fontWeight: "normal",
    height: "30px",
  },

  "&multiLine": {
    control: {
      fontFamily: "monospace",
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      border: "none",
      height: "100%",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 0,
      border: "2px inset transparent",
      marginTop: "19px",
      pointerEvents: "none",
      position: "relative",
      zIndex: 1,
      color: "#f6b58f",
    },
    input: {
      padding: 1,
      border: "none",
      height: "100%",
      outline: "none",
    },
  },

  suggestions: {
    top: "50px",
    borderRadius: "20px",
    width: "100%",
    list: {
      backgroundColor: "white",
      height: "100%",
      maxHeight: "300px",
      overflowY: "auto",
      width: "100%",
      boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.10)",
      fontSize: 14,
      borderRadius: "20px",
      padding: "20px 0 20px 0",
    },
    item: {
      padding: "0px 30px 0px 30px",
      "&focused": {
        backgroundColor: "#F3F3F3",
      },
    },
  },
};
