import React from "react";
import more from "../assets/Icons/More.svg";
import BlueBadge from "../assets/Icons/color-blue.svg";
import GrayBadge from "../assets/Icons/color-gray.svg";
import GreenBadge from "../assets/Icons/color-green.svg";
import defaultImage from "../assets/Icons/signupProfileSmall.png";
import { useSelector } from "react-redux";

/**
 * DetailsProfile component displays user profile information.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {string} props.name - The name of the user.
 * @param {string} props.username - The username of the user.
 * @param {JSX.Element} props.renderPopUp - The JSX element for rendering a popup.
 * @param {Function} props.setUserExpanded - The function to set the expansion state of the user.
 * @param {boolean} props.isUserExpanded - The flag indicating whether the user is expanded.
 * @param {string} props.profile - The profile image source.
 * @param {string} props.profileType - The type of the user profile (COMPLETED, BUSINESS, GOVERNMENT, ORGANIZATION).
 * @param {boolean} props.isDisabled - The flag indicating whether the user profile is disabled.
 * @param {boolean} props.isStaff - The flag indicating whether the user is a staff member.
 * @param {string} props.keyName - The key name for conditional rendering (defaults or entity).
 * @returns {JSX.Element} DetailsProfile component.
 */

const DetailsProfile = ({
  name,
  username,
  renderPopUp,
  setUserExpanded,
  isUserExpanded,
  profile,
  profileType,
  isDisabled = false,
  isStaff = false,
  permissionSuspend,
  permissionActive,
  keyName = "defaults",
  status,
}) => {
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  const badgeMapping = {
    BUSINESS: GrayBadge,
    GOVERNMENT: GreenBadge,
    ORGANIZATION: BlueBadge,
  };

  return (
    <div className="flex gap-3.25 items-center rounded-20 bg-white w-full p-5 h-30 relative">
      <div className="flex items-center 2xl:w-20 2xl:h-20 w-15 h-15 flex-shrink-0">
        <img
          src={profile || defaultImage}
          alt="userProfile"
          className="rounded-full 2xl:w-20 2xl:h-20 w-15 h-15"
        />
      </div>
      <div className="flex w-75per items-center justify-between">
        <div className="w-80per flex">
          <div className="flex flex-col max-w-80per pr-3">
            <p
              className={`${
                isStaff
                  ? "font-InterRegular font-semibold text-ft32 text-black-150"
                  : "font-ManropeBold text-ft3 text-black-100"
              } truncate`}
            >
              {name || "-"}
            </p>
            <p className="font-ManropeRegular text-ft2 text-grey-200 truncate">
              {username || "-"}
            </p>
          </div>
          {profileType === "COMPLETED" ? (
            <img src={BlueBadge} alt="" />
          ) : (
            <img src={badgeMapping[profileType] || ""} alt="" />
          )}
        </div>
        {((userPermissions?.indexOf(permissionSuspend) > -1 &&
          status !== "SUSPENDED") ||
          (userPermissions?.indexOf(permissionActive) > -1 &&
            status !== "ACTIVE")) && (
          <div className="flex h-6 w-6 flex-shrink-0">
            {!isDisabled && (
              <img
                onClick={() => setUserExpanded(!isUserExpanded)}
                src={more}
                alt="More"
                className="cursor-pointer"
              />
            )}
          </div>
        )}

        <div>{renderPopUp}</div>
      </div>
    </div>
  );
};

export default DetailsProfile;
