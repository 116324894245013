import { httpConstants } from "../constant/commonArray";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./userService";

const {
  METHOD_TYPE: { PATCH, POST, PUT },
  API_END_POINT: {
    POLL_APPROVED,
    CREATE_POLL,
    POST_SURVEY,
    EDIT_POLL,
    EDIT_SURVEY,
  },
} = httpConstants;

export default class ContentCreationService {
  baseUrl = process.env.REACT_APP_CONTENT_CREATION_SERVICE;

  approvedPoll = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PATCH,
        this.baseUrl + `/${POLL_APPROVED}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  createPoll = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${CREATE_POLL}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  createSurvey = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${POST_SURVEY}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  editPoll = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_POLL}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  editSurvey = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_SURVEY}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
}
