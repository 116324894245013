/**
 * @module AuditLogComponent
 * @description
 * Module for managing and rendering AuditLogComponent.
 *
 * @example
 * // Usage in a React functional component
 * import AuditLogComponent from "./AuditLogComponent";
 *
 * const MyComponent = () => {
 *   return <AuditLogComponent />;
 * };
 *
 * @returns {React.Component} The AuditLogComponent component
 */

import React, { useEffect, useState } from "react";
import LogList from "./logList";
import { useDebouncedCallback } from "use-debounce";
import { UserService } from "../../services";
import utility from "../../utility";
import searchIcon from "../../assets/Icons/Search.svg";
import Close from "../../assets/Icons/close.svg";
import { useLocation, useHistory } from "react-router-dom";

const AuditLogComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(-1);
  const [debounceValue, setDebounceValue] = useState("");

  const [selectedFilterName, setSelectedFiltersName] = useState(() => {
    const filters = queryParams.get("filtersName");
    return filters ? JSON.parse(filters) : [];
  });
  const [selectedFilterPage, setSelectedFiltersPage] = useState(() => {
    const filters = queryParams.get("filtersPage");
    return filters ? JSON.parse(filters) : [];
  });
  const [selectedFilterAction, setSelectedFiltersAction] = useState(() => {
    const filters = queryParams.get("filtersAction");
    return filters ? JSON.parse(filters) : [];
  });
  const [selectedFilterField, setSelectedFiltersField] = useState(() => {
    const filters = queryParams.get("filtersField");
    return filters ? JSON.parse(filters) : [];
  });
  const [startDate, setStartDate] = useState(
    queryParams.has("startDate")
      ? new Date(parseInt(queryParams.get("startDate"), 10))
      : null
  );
  const [endDate, setEndDate] = useState(
    queryParams.has("endDate")
      ? new Date(parseInt(queryParams.get("endDate"), 10))
      : null
  );

  const [filterList, setFilterList] = useState([]);

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * Handles page change event.
   *
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  /**
   * Function to asynchronously retrieve filters.
   *
   * @return {Promise<void>} A promise that resolves when the filters are successfully retrieved.
   */
  const getFilters = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getFilters()
      );

      if (error || !response) return;

      setFilterList(response);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Retrieves the audit log list based on the provided parameters.
   *
   * @return {Promise<void>} A promise that resolves when the audit log list is retrieved.
   */
  const getAuditList = async () => {
    setLoading(true);
    let requestParams = {
      sort: sort,
      limit: pageSize,
      skip: currentPage,
    };

    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    if (selectedFilterName.length > 0) {
      requestParams.name = selectedFilterName;
    }
    if (selectedFilterPage.length > 0) {
      requestParams.page = selectedFilterPage;
    }
    if (selectedFilterAction.length > 0) {
      requestParams.action = selectedFilterAction;
    }
    if (selectedFilterField.length > 0) {
      requestParams.field = selectedFilterField;
    }
    if (startDate) {
      requestParams.fromDate = startDate.getTime();
    }
    if (endDate) {
      requestParams.toDate = endDate.getTime();
    }

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getAuditLog(requestParams)
      );

      if (error || !response) return;
      setData(response[0].logs || []);
      setTotalDocs(response[0]?.totalCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    setCurrentPage(0);
    setDebounceValue(value);
  }, 500);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    getAuditList();
    setFilters();
    // eslint-disable-next-line
  }, [
    debounceValue,
    pageSize,
    sort,
    currentPage,
    selectedFilterName,
    selectedFilterAction,
    selectedFilterField,
    selectedFilterPage,
    startDate,
    endDate,
  ]);

  /**
   * Sets the filters for the audit log based on the selected filter options.
   *
   * @return {void} This function does not return anything.
   */
  const setFilters = () => {
    if (selectedFilterName?.length > 0) {
      queryParams.set("filtersName", JSON.stringify(selectedFilterName));
    } else {
      queryParams.delete("filtersName");
    }
    if (selectedFilterPage?.length > 0) {
      queryParams.set("filtersPage", JSON.stringify(selectedFilterPage));
    } else {
      queryParams.delete("filtersPage");
    }
    if (selectedFilterAction?.length > 0) {
      queryParams.set("filtersAction", JSON.stringify(selectedFilterAction));
    } else {
      queryParams.delete("filtersAction");
    }
    if (selectedFilterField?.length > 0) {
      queryParams.set("filtersField", JSON.stringify(selectedFilterField));
    } else {
      queryParams.delete("filtersField");
    }
    if (startDate) {
      queryParams.set("startDate", startDate.getTime());
    } else {
      queryParams.delete("startDate");
    }
    if (endDate) {
      queryParams.set("endDate", endDate.getTime());
    } else {
      queryParams.delete("endDate");
    }
    history.replace({
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    getFilters();
  }, []);

  return (
    <div className="w-full h-full overflow-y-auto pr-12.5">
      <div className="py-5 w-full h-full ">
        <div className="flex justify-between bg-white p-5  w-full rounded-20  h-21.25">
          <div className="flex items-center gap-10">
            <p
              className={` cursor-pointer
                  text-ft5 text-black-100 font-ManropeBold
                 
              `}
            >
              Audit Log
            </p>
          </div>
          <div className="flex gap-5">
            <div className="flex w-83.75 h-11.25 p-5 items-center gap-3.75 flex-shrink-0 rounded-full bg-grey-50">
              <img
                src={searchIcon}
                alt="searchIcon"
                className="opacity-60 w-5 h-5"
              />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => handleSearch(e)}
                className="font-ManropeLight text-ft3 w-80per bg-grey-50 text-black-300 focus:outline-none"
              />
              {!!searchQuery && (
                <img
                  onClick={() => {
                    setSearchQuery("");
                    debouncedSearch("");
                  }}
                  src={Close}
                  alt=""
                  className="flex justify-end cursor-pointer  w-5 h-5"
                />
              )}
            </div>
          </div>
        </div>
        <LogList
          searchQuery={searchQuery}
          setPageSize={setPageSize}
          pageSize={pageSize}
          setSort={setSort}
          loading={loading}
          sort={sort}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages - 1 || 0}
          totalDocs={totalDocs || 0}
          setCurrentPage={setCurrentPage}
          data={data}
          setSelectedFiltersName={setSelectedFiltersName}
          selectedFilterName={selectedFilterName}
          selectedFilterPage={selectedFilterPage}
          setSelectedFiltersPage={setSelectedFiltersPage}
          selectedFilterAction={selectedFilterAction}
          setSelectedFiltersAction={setSelectedFiltersAction}
          selectedFilterField={selectedFilterField}
          setSelectedFiltersField={setSelectedFiltersField}
          getAuditList={getAuditList}
          filterList={filterList}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
    </div>
  );
};

export default AuditLogComponent;
