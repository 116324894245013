import { httpConstants, keyTypeConstants } from "../constant/commonArray";
import { sessionManager } from "../managers/sessionManager";
import { httpService } from "../utility/httpService";

const { AUTH0_ID_TOKEN } = keyTypeConstants;

const {
  METHOD_TYPE: { GET, PUT, PATCH, POST, DELETE },
  API_END_POINT: {
    USER_LIST,
    USER_DETAILS,
    POST_LIST,
    USER_EDIT,
    ACOOUNT_DISABLE_POST,
    ACTIVATE_ACCOUNT,
    SUSPEND_USER,
    EDIT_SUSPEND_TIME,
    ACCOUNT_COMMENT_LIST,
    ACCOUNT_VOTES,
    ACCOUNT_LIKES,
    ACCOUNT_REPOST,
    ACCOUNT_COMMENT,
    DISABLE_COMMENT,
    POLL_DETAILS,
    ENTITY_LIST,
    ENTITY_DETAILS,
    EMPLOYEE_LIST,
    ADD_EMPLOYEE,
    ENTITY_EDIT,
    DELETE_EMPLOYEE,
    EDIT_EMPLOYEE,
    GET_REQUEST,
    GET_BLUETICK_REQUEST,
    GET_ENTITY_REQUEST,
    VOILATION_LIST,
    VIOLATION_DETAILS,
    REJECT_VIOLATION,
    REJECT_REQUEST,
    APPROVE_BLUETICK,
    GET_JOB_LIST,
    GET_AD,
    RESET_PASSWORD,
    DUPLICATE_AD,
    STOP_AD,
    DELETE_AD,
    ADS_DETAILS,
    CREATE_AD,
    GET_LOCATION,
    EMAIL_CHECK,
    PROMOTION_LIST,
    DUPLICATE_PROMOTION,
    STOP_PROMOTION,
    DELETE_PROMOTION,
    PROMOTION_DETAILS,
    STAFF_LIST,
    BLOCK_STAFF,
    ACTIVATE_STAFF,
    ROLE_LIST,
    ADD_STAFF,
    DELETE_ROLE,
    GET_ADDITIONAL_LIST,
    DELETE_FIELD,
    FIELD_CREATE,
    PROMOTION_CREATE,
    CHANNEL_LIST,
    DELETE_CHANNEL,
    DELETE_CHANNEL_GROUP,
    CHECK_CHANNEL_NAME,
    GROUP_LIST,
    CHANNEL_CREATE,
    EDIT_CHANNEL,
    CHECK_GROUP_NAME,
    CREATE_GROUP,
    EDIT_GROUP,
    DELETE_JOB,
    DELETE_DEPT,
    DEPT_LIST,
    CHECK_DEPT_NAME,
    DEPT_CREATE,
    CHECK_POSITION,
    CREATE_JOB,
    EDIT_DEPT,
    EDIT_POSITION,
    STAFF_DETAILS,
    UPDATE_STAFF,
    CHECK_STAFF,
    ADD_ROLE,
    UPDATE_ROLE,
    CHANNEL_ICON_LIST,
    CREATE_NEW_ICON,
    STATISTICS,
    INCOME_SPLIT_LIST,
    EDIT_INCOME_PRICE,
    UPDATE_POSITION,
    POLL_LIST,
    LINK_PREVIEW,
    ACTIVITY_LIST,
    EDIT_ACTIVITY_POINT,
    EDIT_INTEREST_POINT,
    INTEREST_LIST,
    STAFF_LOGIN,
    POLL_DETAIL_MANAGEMENT,
    AUDIT_LOGS,
    AUDIT_FILTERS,
    GOOGLE_PRODUCT,
    APPLE_PRODUCT,
  },
} = httpConstants;

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `${sessionManager.getDataFromCookies(AUTH0_ID_TOKEN)}`,
  };
}

export default class UserService {
  baseUrl = process.env.REACT_APP_USER_ADMIN_CONFIG_MICROSERVICE;
  getUserList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${USER_LIST}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getUserDetails = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${USER_DETAILS}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getPostDetails = async (id, requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${POST_LIST}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  userEdit = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${USER_EDIT}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  userDisablePost = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${ACOOUNT_DISABLE_POST}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  userActivateUser = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        PATCH,
        this.baseUrl + `/${ACTIVATE_ACCOUNT}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  userSuspendUser = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PATCH,
        this.baseUrl + `/${SUSPEND_USER}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  userEditSuspendUser = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PATCH,
        this.baseUrl + `/${EDIT_SUSPEND_TIME}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  userAccountComment = async (id, requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${ACCOUNT_COMMENT_LIST}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getUserLikes = async (id, requestData) => {
    const query = new URLSearchParams(requestData).toString();

    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${ACCOUNT_LIKES}/${id}?${query}`)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getUserVotes = async (id, requestData) => {
    const query = new URLSearchParams(requestData).toString();

    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${ACCOUNT_VOTES}/${id}?${query}`)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getUserComment = async (id, requestData) => {
    const query = new URLSearchParams(requestData).toString();

    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${ACCOUNT_COMMENT}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getUserRepost = async (id, requestData) => {
    const query = new URLSearchParams(requestData).toString();

    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${ACCOUNT_REPOST}/${id}?${query}`)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  userDisableComment = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${DISABLE_COMMENT}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  userPollDetails = async (id, requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${POLL_DETAILS}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getEntityList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${ENTITY_LIST}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getEntityDetails = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${ENTITY_DETAILS}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getEmployeeList = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${EMPLOYEE_LIST}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  addEmployee = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${ADD_EMPLOYEE}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  editEntity = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${ENTITY_EDIT}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  deleteEmployee = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `/${DELETE_EMPLOYEE}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  editEmployee = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_EMPLOYEE}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getVoilationActiveList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${VOILATION_LIST}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getRequest = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${GET_REQUEST}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getBlueTickRequestDetails = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${GET_BLUETICK_REQUEST}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getEntityRequestDetails = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${GET_ENTITY_REQUEST}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getViolationDetails = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${VIOLATION_DETAILS}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  rejectViolation = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${REJECT_VIOLATION}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  rejectRequest = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PATCH,
        this.baseUrl + `/${REJECT_REQUEST}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  approveBlueTickRequest = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${APPROVE_BLUETICK}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getJobList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${GET_JOB_LIST}?${query}`)
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getAd = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${GET_AD}?${query}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  forgotPassword = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + `/${RESET_PASSWORD}`, requestData)
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  duplicateAd = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${DUPLICATE_AD}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  stopAd = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(PATCH, this.baseUrl + `/${STOP_AD}/${id}`, {}, getHeaders())
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  deleteAd = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `/${DELETE_AD}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  adsDetails = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${ADS_DETAILS}/${id}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  createAd = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${CREATE_AD}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getLocation = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${GET_LOCATION}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  checkEmail = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${EMAIL_CHECK}?${query}`)
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getPromotionList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${PROMOTION_LIST}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  duplicatePromotion = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${DUPLICATE_PROMOTION}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  stopPromotion = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        PATCH,
        this.baseUrl + `/${STOP_PROMOTION}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  deletePromotion = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `/${DELETE_PROMOTION}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };

  promotionDetails = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${PROMOTION_DETAILS}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getStaffList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${STAFF_LIST}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  blockStaff = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(PUT, this.baseUrl + `/${BLOCK_STAFF}/${id}`, {}, getHeaders())
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  activateStaff = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${ACTIVATE_STAFF}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getRoleList = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${ROLE_LIST}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  createStaff = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${ADD_STAFF}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getStaffDetails = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${STAFF_DETAILS}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  editStaff = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${UPDATE_STAFF}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getCheckStaff = async (id, headers) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${CHECK_STAFF}/${id}`,
        {},
        headers || getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  deleteRole = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `/${DELETE_ROLE}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getAdditionalList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${GET_ADDITIONAL_LIST}?${query}`)
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  deleteField = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `/${DELETE_FIELD}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  createField = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${FIELD_CREATE}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  createPromotion = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${PROMOTION_CREATE}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getChannelList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${CHANNEL_LIST}?${query}`)
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  deleteChannel = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `/${DELETE_CHANNEL}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  deleteChannelGroup = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `/${DELETE_CHANNEL_GROUP}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  checkChannel = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${CHECK_CHANNEL_NAME}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  groupList = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${GROUP_LIST}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  createChannel = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${CHANNEL_CREATE}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  editChannel = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_CHANNEL}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  checkGroup = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${CHECK_GROUP_NAME}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  createGroup = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${CREATE_GROUP}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  editGroup = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_GROUP}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  deleteJob = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `/${DELETE_JOB}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  deleteDepartment = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `/${DELETE_DEPT}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  deptList = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${DEPT_LIST}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  checkDept = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${CHECK_DEPT_NAME}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  createDept = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${DEPT_CREATE}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  editDept = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_DEPT}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  checkPosition = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${CHECK_POSITION}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  createJob = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${CREATE_JOB}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  editPosition = async (id, requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_POSITION}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  createRole = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${ADD_ROLE}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  editRole = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${UPDATE_ROLE}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  channelIconList = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${CHANNEL_ICON_LIST}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  createIcon = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${CREATE_NEW_ICON}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getStatistics = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${STATISTICS}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getIncomeList = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${INCOME_SPLIT_LIST}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  editIncome = async (requestData, id) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_INCOME_PRICE}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  updatePosition = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${UPDATE_POSITION}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getPollList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${POLL_LIST}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getActivityList = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${ACTIVITY_LIST}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getLinkdata = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${LINK_PREVIEW}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  editActivity = async (requestData, id) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_ACTIVITY_POINT}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getInterestList = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${INTEREST_LIST}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  editInterest = async (requestData, id) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${EDIT_INTEREST_POINT}/${id}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  staffLogin = async (requestData) => {
    const type = "login";
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + `/${STAFF_LOGIN}`, requestData, "", type)
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  pollDetailsManagement = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${POLL_DETAIL_MANAGEMENT}/${id}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getAuditLog = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${AUDIT_LOGS}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getFilters = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${AUDIT_FILTERS}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getAppleProducts = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${APPLE_PRODUCT}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getGoogleProducts = async () => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${GOOGLE_PRODUCT}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
}
