import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../assets/Icons/close.svg";
import { eventConstants, toastType } from "../../constant/commonArray";
import chevronUp from "../../assets/Icons/chevronUp.svg";
import chevronDown from "../../assets/Icons/Chevrondown.svg";
import { UserService } from "../../services";
import utility from "../../utility";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import ShowToast from "../../common/showToast";

/**
 * Component for creating or editing job positions.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setPopup - Function to control the visibility of the popup
 * @param {Function} props.getJobLists - Function to fetch the list of jobs
 * @param {string} props.keyName - Key name indicating whether it's for creating or editing a position
 * @param {Object} props.departmentData - Data of the department for which the position is being created or edited
 * @param {Object} props.jobData - Data of the job position being edited
 * @returns {JSX.Element} - CreatePositionPopUp component
 */

const CreatePositionPopUp = ({
  setPopup = () => {},
  getJobLists = () => {},
  keyName = "create",
  departmentData,
  jobData,
}) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [deptList, setDeptList] = useState([]);
  const [isPositionAvailable, setIsPositionAvailable] = useState(false);
  const [debounceValue, setDebounceValue] = useState("");
  const [creating, setCreating] = useState(false);
  const [state, setState] = useState({
    departmentId: departmentData?.departmentId || "",
    departmentName: departmentData?.departmentName || "Not choosen",
    language: jobData?.language || "English",
    jobName: jobData?.name || "",
  });
  const [error, setError] = useState({});

  /**
   * Handles the creation of a new job position.
   *
   * @async
   * @function
   * @returns {void}
   */

  const handleCreatePosition = async () => {
    setCreating(true);

    try {
      const { departmentName, ...rest } = state;
      const [error, response] = await utility.parseResponse(
        new UserService().createJob(rest)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });

        setPopup("");
        getJobLists();
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    }
  };
  const getDeptLists = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deptList()
      );

      if (error || !response) return;
      setDeptList(response);
    } catch (e) {
      console.error(e);
    }
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 300);

  const checkPositionName = async (name) => {
    try {
      const requestData = {
        jobName: name,
      };
      dispatch({ type: eventConstants.SHOW_LOADER });
      const [error, res] = await utility.parseResponse(
        new UserService().checkPosition(requestData)
      );
      if (error || !res) {
        setIsPositionAvailable(false);
      } else {
        if (res?.status) {
          setIsPositionAvailable(false);
        } else {
          setIsPositionAvailable(true);
        }
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  const handleEditPosition = async () => {
    setCreating(true);

    try {
      const { departmentName, ...rest } = state;
      const [error, response] = await utility.parseResponse(
        new UserService().editPosition(jobData?.jobId, rest)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getJobLists();
        setPopup("");
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    }
  };

  const handlePositionName = async (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 50) {
      setError((prev) => ({ ...prev, nameError: false }));
    } else {
      setError((prev) => ({ ...prev, nameError: true }));
    }
    setState((prev) => ({ ...prev, jobName: e.target.value }));
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    getDeptLists();
  }, []);

  useEffect(() => {
    if (!!debounceValue) checkPositionName(debounceValue);
    // eslint-disable-next-line
  }, [debounceValue]);

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 flex justify-center items-center fixed w-full h-full right-0 top-0">
      <div className="flex flex-col justify-between bg-white w-112.5 h-155 shadow-md rounded-20 p-7.5 ">
        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <p className="text-black-100 font-ManropeBold text-ft4">
              {keyName === "edit" ? "Edit" : "Create new"} position
            </p>
            <Close className="cursor-pointer" onClick={() => setPopup("")} />
          </div>

          <div className="flex flex-col">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Department
            </p>
            <div
              className="flex py-5 px-5 rounded-full bg-grey-50 justify-between items-center relative cursor-pointer"
              onClick={() => setDropdownOpen(!isDropdownOpen)}
            >
              <p
                className={`font-ManropeRegular text-ft3 ${
                  state?.departmentName === "Not choosen"
                    ? "text-grey-250"
                    : "text-black-100"
                } `}
              >
                {state?.departmentName}
              </p>
              <img
                src={isDropdownOpen ? chevronUp : chevronDown}
                alt=""
                className="cursor-pointer"
              />
              {isDropdownOpen && (
                <div className="flex flex-col absolute top-20 left-0 bg-white w-full shadow-md rounded-20 gap-5 py-5 overflow-y-auto max-h-50vh">
                  {deptList?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col gap-5 cursor-pointer "
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          departmentName: item.departmentName,
                          departmentId: item?._id,
                        }))
                      }
                    >
                      <div
                        key={item?._id}
                        className="font-ManropeRegular text-ft3 text-black-100 px-5"
                      >
                        {item.departmentName}
                      </div>
                      <hr
                        className={`${
                          index === deptList?.length - 1
                            ? "hidden"
                            : "text-grey-50"
                        }`}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* <div className="flex flex-col gap-2 mt-1">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Languages
            </p>
            <div className="flex gap-2.5">
              <img
                src={ArrowLeft}
                alt="left"
                className="cursor-pointer"
                onClick={() => scrollContainer("left")}
              />
              <div
                ref={containerRef}
                className="flex gap-2.5 overflow-x-scroll scrollbar-hide"
              >
                {Languages.map((value) => (
                  <div
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        language: value.name,
                      }))
                    }
                    key={value.name}
                    className={`flex items-center rounded-20 px-2 py-0.75 text-ft2 text-black-100 cursor-pointer ${
                      value.name?.toLowerCase() ===
                      state?.language?.toLowerCase()
                        ? "bg-orange-50 font-ManropeBold"
                        : "bg-grey-50 font-ManropeRegular"
                    }`}
                  >
                    {value.name}
                  </div>
                ))}
              </div>
              <img
                src={ArrowRight}
                alt="right"
                className="cursor-pointer"
                onClick={() => scrollContainer("right")}
              />
            </div>
          </div> */}
          <div className="flex flex-col mt-1">
            <p>Name of position</p>
            <input
              value={state?.jobName}
              type="text"
              onChange={handlePositionName}
              className={`w-full h-16.25 px-6.25 rounded-full bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none ${
                isPositionAvailable && state?.jobName !== jobData?.name
                  ? "border-1 border-orange-100"
                  : ""
              }`}
            />
            {isPositionAvailable && state?.jobName !== jobData?.name && (
              <p className="text-orange-100 font-ManropeRegular text-ft2">{`This ${"position"} already exists`}</p>
            )}
            {error?.nameError && !isPositionAvailable && (
              <span className=" text-red-50">
                Position name can't be more than 50
              </span>
            )}
          </div>
        </div>
        <button
          disabled={
            state?.departmentName === "Not choosen" ||
            !state?.jobName ||
            isPositionAvailable ||
            Object.values(error).some((value) => value !== false) ||
            (keyName === "edit" &&
              state?.departmentName === departmentData?.departmentName &&
              state?.jobName === jobData?.name &&
              state?.language?.toLowerCase() ===
                jobData?.language?.toLowerCase()) ||
            creating
          }
          className={`flex items-center justify-center rounded-full  h-16.25  font-ManropeBold text-ft3 ${
            state?.departmentName === "Not choosen" ||
            isPositionAvailable ||
            !state?.jobName ||
            creating ||
            Object.values(error).some((value) => value !== false) ||
            (keyName === "edit" &&
              state?.departmentName === departmentData?.departmentName &&
              state?.jobName === jobData?.name &&
              state?.language?.toLowerCase() ===
                jobData?.language?.toLowerCase())
              ? "bg-grey-50 text-grey-250"
              : "cursor-pointer bg-orange-50 text-black-100"
          }`}
          onClick={
            keyName === "edit" ? handleEditPosition : handleCreatePosition
          }
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CreatePositionPopUp;
