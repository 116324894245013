/**
 * @module TokenComponent
 * @example
 * // Usage in a React functional component
 * import TokenComponent from "./TokenComponent";
 *
 * const MyComponent = () => {
 *   return <TokenComponent />;
 * };
 * @returns {React.Component} The TokenComponent
 */

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import EditBFactorPopup from "./editBfactor";
import BlockChainService from "../../services/blockChainService";
import { CircularProgress } from "@mui/material";
import utility from "../../utility";
import { useSelector } from "react-redux";
import Arrow from "../../assets/Icons/orange-arrow.svg";
import moment from "moment";
import left from "../../assets/Icons/Left.svg";

const TokenComponent = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [edit, setEdit] = useState({
    popup: false,
    value: "",
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");

  /**
   * Retrieves data from the dashboard and updates the state accordingly.
   *
   * @return {Promise<void>} - A promise that resolves when the data is retrieved and the state is updated.
   */
  const getData = async () => {
    setLoading(true);

    try {
      const [error, response] = await utility.parseResponse(
        new BlockChainService().getDashboard()
      );

      if (error || !response) return;

      setData(response);
      setEdit((prevEdit) => ({
        ...prevEdit,
        value: response.bFactorValue,
      }));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div className="flex py-5 w-full h-full overflow-y-auto pr-12.5 gap-5">
      <div className="w-full">
        <div className="bg-white p-5 rounded-20 h-21.25 flex items-center">
          {haveAudit && (
            <div
              onClick={() => {
                if (haveAudit) {
                  history.push("/audit-log");
                }
              }}
              className="pb-3"
            >
              <img src={left} alt="left" className="cursor-pointer" />
            </div>
          )}
          <p className="text-black-100 font-ManropeBold text-ft5">
            Token Management
          </p>
        </div>
        <div className="pt-7.5 h-auto grid grid-cols-2 gap-5">
          {userPermissions?.indexOf("token:read:balance") > -1 && (
            <div className="flex  bg-white rounded-20 w-full h-52">
              <div className="px-5per pt-5per flex flex-col gap-2 w-full">
                <div className="w-full flex justify-between">
                  <span className="text-ft3 font-ManropeRegular text-black-100 opacity-60">
                    Admin Wallet Balance
                  </span>
                </div>
                <div className="flex gap-2 items-end">
                  <span className="text-black-100 break-all font-ManropeBold text-ft8">
                    {data?.balance?.balance || 0}
                  </span>
                  <span className="text-black-100 font-ManropeRegular text-ft9 pb-1.5">
                    PPT
                  </span>
                </div>
              </div>
            </div>
          )}
          {userPermissions?.indexOf("token:read:bFactor") > -1 && (
            <div className="flex  bg-white rounded-20 w-full h-52">
              <div className="px-5per pt-5per flex flex-col gap-2 w-full">
                <div className="w-full flex justify-between">
                  <span className="text-ft3 font-ManropeRegular text-black-100 opacity-60">
                    b Factor
                  </span>
                  {userPermissions?.indexOf("token:write:bFactor") > -1 && (
                    <div
                      onClick={() =>
                        setEdit((prev) => ({
                          ...prev,
                          popup: true,
                          value: data?.bFactorValue?.$numberDecimal,
                        }))
                      }
                      className="flex gap-2 cursor-pointer"
                    >
                      <span className="text-ft32 font-ManropeMedium text-orange-50">
                        Edit
                      </span>
                      <img src={Arrow} alt="" />
                    </div>
                  )}
                </div>
                <div className="flex gap-2 items-end">
                  <span className="text-black-100 break-all font-ManropeBold text-ft8">
                    {data?.bFactorValue?.$numberDecimal || 0}
                  </span>
                </div>
              </div>
            </div>
          )}
          {userPermissions?.indexOf("token:read:token") > -1 && (
            <div className="flex  bg-white rounded-20 w-full h-52">
              <div className="px-5per pt-5per flex flex-col gap-2 w-full">
                <div className="w-full flex justify-between">
                  <span className="text-ft3 font-ManropeRegular text-black-100 opacity-60">
                    Tokens Created
                  </span>
                  {userPermissions?.indexOf("token:read:history") > -1 && (
                    <div
                      onClick={() => {
                        history.push("/token/view-transaction");
                      }}
                      className="flex gap-2 cursor-pointer"
                    >
                      <span className="text-ft32 font-ManropeMedium text-orange-50">
                        View Transactions
                      </span>
                      <img src={Arrow} alt="" />
                    </div>
                  )}
                </div>
                <div className="flex gap-2 items-end">
                  <span className="text-black-100 break-all font-ManropeBold text-ft8">
                    {data?.totalTokenCount || 0}
                  </span>
                </div>
              </div>
            </div>
          )}
          {userPermissions?.indexOf("token:read:rValue") > -1 && (
            <div className="flex  bg-white rounded-20 w-full h-52">
              <div className="px-5per pt-5per flex flex-col gap-2 w-full">
                <div className="w-full flex justify-between">
                  <span className="text-ft3 font-ManropeRegular text-black-100 opacity-60">
                    Current R-Value
                  </span>
                  {userPermissions?.indexOf("token:read:rHistory") > -1 && (
                    <div
                      onClick={() => {
                        history.push("/token/rValue-transaction");
                      }}
                      className="flex gap-2 cursor-pointer"
                    >
                      <span className="text-ft32 font-ManropeMedium text-orange-50">
                        View R History
                      </span>
                      <img src={Arrow} alt="" />
                    </div>
                  )}
                </div>
                <div className="flex gap-2 items-end">
                  <span className="text-black-100 break-all font-ManropeBold text-ft8">
                    {data?.rValue?.rCount || 0}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-ft3 font-ManropeRegular text-black-100 opacity-60">
                    Next updated on{" "}
                    {moment(data?.rValue?.nextRupdate).format("DD MMMM YYYY")}
                  </span>
                </div>
              </div>
            </div>
          )}
          {userPermissions?.indexOf("token:read:token") > -1 && (
            <div className="flex  bg-white rounded-20 w-full h-52">
              <div className="px-5per pt-5per flex flex-col gap-2 w-full">
                <div className="w-full flex justify-between">
                  <span className="text-ft3 font-ManropeRegular text-black-100 opacity-60">
                    Failed Transactions
                  </span>
                  {userPermissions?.indexOf("token:read:history") > -1 && (
                    <div
                      onClick={() => {
                        history.push("/token/failed-transaction");
                      }}
                      className="flex gap-2 cursor-pointer"
                    >
                      <span className="text-ft32 font-ManropeMedium text-orange-50">
                        View Transactions
                      </span>
                      <img src={Arrow} alt="" />
                    </div>
                  )}
                </div>
                <div className="flex gap-2 items-end">
                  <span className="text-black-100 break-all font-ManropeBold text-ft8">
                    {data?.failedTokens || 0}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {edit.popup && (
        <EditBFactorPopup
          setEditFactor={setEdit}
          getData={getData}
          editFactor={edit}
        />
      )}
    </div>
  );
};

export default TokenComponent;
