/**
 * CreateComponent component for creating Additional fields.
 * @component
 *
 * @example
 * // Usage example in another component or file
 * import CreateComponent from './createComponent';
 * // ...
 * <CreateComponent />
 *
 * @returns {JSX.Element} CreateComponent component
 */

import React, { useState } from "react";
import { toastType } from "../../constant/commonArray";
import add from "../../assets/Icons/addCirclePlus.svg";
import close from "../../assets/Icons/close.svg";
import menu from "../../assets/Icons/menu.svg";
import WarningPopup from "../../common/warningPopup";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ShowToast from "../../common/showToast";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { UserService } from "../../services";
import utility from "../../utility";
import { CircularProgress } from "@mui/material";

const CreateComponent = () => {
  const history = useHistory();
  const [showWarning, setShowWarning] = useState(false);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState({});

  /**
   * State hook for managing the form state.
   * @type {object}
   * @property {string} language - The selected language.
   * @property {string} name - The field name.
   * @property {string} question - The field question.
   * @property {object[]} options - An array of answer options.
   * @property {string} options[].index - The index of the option.
   * @property {string} options[].value - The value of the option.
   * @property {number} options[].id - The unique identifier of the option.
   */

  const [state, setState] = useState({
    language: "English",
    name: "",
    question: "",
    options: [{ index: "", value: "", id: Date.now() }],
  });

  /**
   * Handles the drag end event and updates the state with the new item order.
   *
   * @param {object} result - The result object containing information about the drag end event.
   * @return {void}
   */
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...state?.options];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setState((prev) => ({ ...prev, options: newItems }));
  };

  /**
   * Adds a new answer to the options array in the state.
   *
   * @param {}
   * @return {}
   */
  const addAnswer = () => {
    const newAnswer = {
      index: "",
      value: "",
      id: Date.now(),
    };
    setState((prev) => ({ ...prev, options: [...state?.options, newAnswer] }));
  };

  /**
   * Removes the answer with the given id from the state options.
   *
   * @param {type} id - The id of the answer to be removed
   * @return {type} undefined
   */
  const removeAnswer = (id) => {
    const updatedAnswers = state?.options?.filter((answer) => answer.id !== id);
    setState((prev) => ({ ...prev, options: updatedAnswers }));
    setError((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
  };

  /**
   * Function to handle discarding changes.
   */
  const handleDiscardChanges = () => {
    setState({
      language: "English",
      name: "",
      question: "",
      options: [{ index: "", value: "", id: 1 }],
    });
    setShowWarning(false);
    history.push("/platform-parameters");
  };

  /**
   * Function to handle the creation of a new field.
   *
   * @return {Promise<void>}
   */
  const handleCreateField = async () => {
    setCreating(true);

    try {
      const modifiedOptions = state?.options?.map((item, i) => ({
        index: String.fromCharCode(i + 65),
        value: item?.value,
      }));
      const requestData = {
        ...state,
        options: modifiedOptions,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().createField(requestData)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        history.push("/platform-parameters");
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    }
  };

  return (
    <div className="py-5 w-full h-full overflow-y-auto pr-12.5">
      <div className="flex flex-col gap-7.5 rounded-20 bg-white">
        <div className="flex flex-col gap-7.5 mt-5">
          <p className="px-10 text-black-100 font-ManropeBold text-ft5">
            Create Additional field
          </p>
          <hr className="text-grey-50" />
        </div>

        {/* <div className="flex flex-col gap-7.5 ">
          <div className="flex flex-col gap-22px px-10">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Languages
            </p>
            <div className="flex gap-2.5 overflow-x-scroll scrollbar-hide">
              {Languages.map((value) => (
                <div
                  key={value.name}
                  onClick={() =>
                    setState((prev) => ({ ...prev, language: value.name }))
                  }
                  className={`flex items-center rounded-20 px-2 py-0.75 text-ft2 text-black-100 cursor-pointer ${
                    value.name === state?.language
                      ? "bg-orange-50 font-ManropeBold"
                      : "bg-grey-50 font-ManropeRegular"
                  }`}
                >
                  {value.name}
                </div>
              ))}
            </div>
          </div>
          <hr className="text-grey-50" />
        </div> */}

        <div className="flex flex-col gap-7.5 ">
          <div className="flex flex-col px-10">
            <label
              htmlFor="Name"
              className="text-black-100 font-ManropeMedium text-ft3"
            >
              Name
            </label>
            <input
              type="text"
              id="Name"
              value={state.name}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 150) {
                  setError((prev) => ({ ...prev, nameError: false }));
                } else {
                  setError((prev) => ({ ...prev, nameError: true }));
                }
                setState((prev) => ({ ...prev, name: e.target.value }));
              }}
              className="h-16.25 w-30per px-6.25 rounded-full bg-grey-50 focus:outline-none"
            />
            {error?.nameError && (
              <span className=" text-red-50">Name can't be more than 150</span>
            )}
          </div>
          <hr className="text-grey-50" />
        </div>

        <div className="flex flex-col gap-7.5 ">
          <div className="flex flex-col px-10">
            <label
              htmlFor="Question"
              className="text-black-100 font-ManropeMedium text-ft3"
            >
              Question
            </label>
            <input
              type="text"
              id="Question"
              value={state.question}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 150) {
                  setError((prev) => ({ ...prev, questionError: false }));
                } else {
                  setError((prev) => ({ ...prev, questionError: true }));
                }
                setState((prev) => ({ ...prev, question: e.target.value }));
              }}
              className="h-16.25 w-30per px-6.25 rounded-full bg-grey-50 focus:outline-none"
            />
            {error?.questionError && (
              <span className=" text-red-50">
                Question can't be more than 150
              </span>
            )}
          </div>
          <hr className="text-grey-50" />
        </div>

        <div className="flex flex-col gap-7.5 ">
          <div className="flex flex-col px-10">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Answers
            </p>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided) => (
                  <div
                    className="flex flex-col gap-3"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state?.options?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className="flex gap-3 items-center">
                              <div className="bg-grey-50 h-16.25 px-6.25 flex justify-between items-center rounded-full w-30per focus:outline-none">
                                <input
                                  type="text"
                                  placeholder="Title"
                                  value={item.value}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    setError((prevErrors) => ({
                                      ...prevErrors,
                                      [item.id]: inputValue.length > 150,
                                    }));
                                    setState((prevState) => ({
                                      ...prevState,
                                      options: prevState?.options?.map(
                                        (prevItem, i) =>
                                          i === index
                                            ? { ...prevItem, value: inputValue }
                                            : prevItem
                                      ),
                                    }));
                                  }}
                                  className="rounded-full w-95per bg-transparent h-full focus:outline-none"
                                />
                                <img
                                  src={menu}
                                  {...provided.dragHandleProps}
                                  alt="menu"
                                  className="w-3.5 h-1.5 cursor-pointer"
                                />
                              </div>
                              {state?.options?.length > 1 && (
                                <img
                                  src={close}
                                  alt="CloseIcon"
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={() => removeAnswer(item.id)}
                                />
                              )}
                            </div>
                            {error[item.id] && (
                              <span className=" text-red-50">
                                Answer can't be more than 150
                              </span>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div
              className="flex gap-2 items-center cursor-pointer pt-5"
              onClick={() => addAnswer()}
            >
              <img src={add} alt="AddPlusIcon" className="w-5 h-5" />
              <p className="text-orange-50 font-ManropeBold text-ft2">
                Add answers
              </p>
            </div>
          </div>
          <hr className="text-grey-50" />
        </div>

        <div className="flex justify-end px-10 pb-5 w-full ">
          <div className="flex  gap-5">
            <button
              className="flex items-center justify-center py-2.5 px-7 w-55 h-16.25 rounded-full text-black-100 text-ft2 font-ManropeBold bg-white border-2 border-grey-50"
              onClick={() => setShowWarning(!showWarning)}
            >
              Cancel
            </button>
            <button
              disabled={
                state?.name?.trim() === "" ||
                state.question?.trim() === "" ||
                creating ||
                state?.options?.some((option) => !option.value?.trim()) ||
                Object.values(error).some((value) => value !== false)
              }
              className={`flex items-center justify-center py-2.5 px-7 w-55 h-16.25 rounded-full text-black-100 text-ft2 font-ManropeBold ${
                state?.name?.trim() === "" ||
                state.question?.trim() === "" ||
                creating ||
                state?.options?.some((option) => !option.value?.trim()) ||
                Object.values(error).some((value) => value !== false)
                  ? "bg-grey-50"
                  : "bg-orange-50"
              } `}
              onClick={handleCreateField}
            >
              {creating ? (
                <CircularProgress className="text-orange-50" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>

        {showWarning && (
          <WarningPopup
            title="Discard changes ?"
            description="All entered data will be deleted"
            setToggle={setShowWarning}
            handleActivateUser={handleDiscardChanges}
          />
        )}
      </div>
    </div>
  );
};

export default CreateComponent;
