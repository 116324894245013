import React from "react";

/**
 * ToggleSwitch component renders a switch that can be toggled on or off.
 * @component
 * @param {Object} props - The properties object.
 * @param {boolean} [props.disable=false] - If true, the switch will be disabled.
 * @param {boolean} props.value - The current value of the switch (on or off).
 * @param {Function} props.setValue - The function to update the switch value.
 * @returns {JSX.Element} The ToggleSwitch component.
 */
const ToggleSwitch = ({ disable = false, value, setValue }) => {
  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={value}
        disabled={disable}
        onChange={setValue}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default ToggleSwitch;
