import ShowToast from "../common/showToast";
import {
  httpConstants,
  keyTypeConstants,
  toastType,
} from "../constant/commonArray";
import { sessionManager } from "../managers/sessionManager";
const { AUTH0_ID_TOKEN } = keyTypeConstants;

const createRequestOptions = (method, data, headers) => {
  const { GET } = httpConstants.METHOD_TYPE;
  const requestOptions = {
    method: method,
    headers: headers || { "Content-Type": "application/json" },
  };

  if (method !== GET) requestOptions.body = JSON.stringify(data);
  return requestOptions;
};

export const httpService = (method, url, data, headers, type) => {
  const { removeDataFromCookies } = sessionManager;
  const requestOptions = createRequestOptions(method, data, headers);
  return fetch(url, requestOptions)
    .then(function handleResponse(response) {
      return response.json().then((data) => {
        if (response.status === 403 && !type) {
          ShowToast({
            message: data.message,
            type: toastType.ERROR,
          });

          setTimeout(() => {
            removeDataFromCookies(AUTH0_ID_TOKEN);
            window.location.href = "/";
          }, 2000);

          return Promise.reject(data.message);
        }
        if (response.status === 500) {
          return Promise.reject(data.message);
        }

        return data;
      });
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
};
