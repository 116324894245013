/**
 * @module ViolationsComponent
 * @example
 * // Usage in a React functional component
 * import ViolationsComponent from "./ViolationsComponent";
 *
 * const MyComponent = () => {
 *   return <ViolationsComponent />;
 * };
 * @returns {React.Component} The ViolationsComponent
 */

import React, { useEffect, useState } from "react";
import Active from "./active";
import Disabled from "./disabled";
import { UserService } from "../../services";
import utility from "../../utility";
import searchIcon from "../../assets/Icons/Search.svg";
import Close from "../../assets/Icons/close.svg";
import { useDebouncedCallback } from "use-debounce";
import { useLocation, useHistory } from "react-router-dom";

/**
 * ViolationsComponent functional component.
 * @returns {JSX.Element} JSX Element representing the ViolationsComponent.
 */

const ViolationsComponent = () => {
  const [loading, setLoading] = useState(false);
  const [activeUser, setIsActiveUser] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [debounceValue, setDebounceValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tabType = queryParams.get("tab");
  const [active, setIsActive] = useState(tabType || "ACTIVE");
  const [sort, setSort] = useState(-1);

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * Handles page changes.
   * @param {number} pageNumber - The selected page number.
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const getActiveVoilationList = async () => {
    setLoading(true);
    let requestParams = {
      status: active,
      sort: sort,
      limit: pageSize,
      skip: currentPage,
    };

    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getVoilationActiveList(requestParams)
      );

      if (error || !response) return;
      setIsActiveUser(response.violationList);
      setTotalDocs(response?.totalCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the search input change with debounce.
   * @param {Object} e - The event object.
   */
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPage(0);
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    setCurrentPage(0);
    setDebounceValue(value);
  }, 500);

  const handleTabClick = (newActive) => {
    setIsActive(newActive);
    setCurrentPage(0);
    setDebounceValue("");
    setSearchQuery("");

    if (queryParams.has("tab")) {
      queryParams.delete("tab");
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  useEffect(() => {
    getActiveVoilationList();
    // eslint-disable-next-line
  }, [debounceValue, pageSize, active, sort, currentPage]);

  return (
    <div className="w-full h-full overflow-y-auto pr-12.5 py-5">
      <div className="flex justify-between bg-white p-5 w-full rounded-20  h-21.25">
        <div className="flex items-center gap-10">
          <p
            onClick={() => {
              handleTabClick("ACTIVE");
            }}
            className={` cursor-pointer ${
              active === "ACTIVE"
                ? "text-ft5 text-black-100 font-ManropeBold"
                : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
            }`}
          >
            Active
          </p>
          <p
            onClick={() => {
              handleTabClick("DISABLED");
            }}
            className={` cursor-pointer ${
              active === "DISABLED"
                ? "text-ft5 text-black-100 font-ManropeBold"
                : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
            }`}
          >
            Disabled
          </p>
        </div>
        <div className="flex w-83.75 h-11.25 p-5 items-center gap-3.75 flex-shrink-0 rounded-full bg-grey-50">
          <img
            src={searchIcon}
            alt="searchIcon"
            className="opacity-60 w-5 h-5"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            className="font-ManropeLight text-ft3 w-80per bg-grey-50 text-black-300 focus:outline-none"
          />
          {!!searchQuery && (
            <img
              onClick={() => {
                setSearchQuery("");
                debouncedSearch("");
              }}
              src={Close}
              alt=""
              className="flex justify-end cursor-pointer  w-5 h-5"
            />
          )}
        </div>
      </div>
      {active === "ACTIVE" ? (
        <Active
          setIsActive={setIsActive}
          searchQuery={searchQuery}
          activeUser={activeUser}
          sort={sort}
          data={activeUser}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPageSize={setPageSize}
          loading={loading}
          handlePageChange={handlePageChange}
          pageSize={pageSize}
          totalDocs={totalDocs}
          setSort={setSort}
          totalPages={totalPages}
        />
      ) : (
        <Disabled
          setIsActive={setIsActive}
          searchQuery={searchQuery}
          sort={sort}
          data={activeUser}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPageSize={setPageSize}
          loading={loading}
          handlePageChange={handlePageChange}
          pageSize={pageSize}
          totalDocs={totalDocs}
          setSort={setSort}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};

export default ViolationsComponent;
