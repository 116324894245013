import React, { useEffect, useRef, useState } from "react";
import left from "../../../assets/Icons/Left.svg";
import table from "../../../assets/Icons/table.svg";
import tableOrange from "../../../assets/Icons/tableOrange.svg";
import vector from "../../../assets/Icons/vector.svg";
import VectorOrange from "../../../assets/Icons/VectorOrange.svg";
import searchIcon from "../../../assets/Icons/Search.svg";
import more from "../../../assets/Icons/More.svg";
import Close from "../../../assets/Icons/close.svg";
import copy from "../../../assets/Icons/copy.svg";
import error from "../../../assets/Icons/Error.svg";
import checkMark from "../../../assets/Icons/checkMark.svg";
import Post from "../../DashboardPage/details/Post";
import UserDetailAsideSection from "../../DashboardPage/details/UserDetailAsideSection";
import Comments from "../../DashboardPage/details/Comments";
import Table from "../../../common/table";
import moment from "moment";
import utility from "../../../utility";
import { UserService } from "../../../services";
import { useParams, useLocation, useHistory } from "react-router-dom";
import placeholder from "../../../assets/Icons/Placeholder.svg";
import ShowToast from "../../../common/showToast";
import { successMessage, toastType } from "../../../constant/commonArray";
import EditPopup from "../../../common/editPopup";
import { useDebouncedCallback } from "use-debounce";
import TableFooter from "../../../common/tableFooter";
import { CircularProgress } from "@mui/material";
import DisablePopUp from "../../../common/disablePopUp";
import OutsideClickRef from "../../../common/outsideClickRef";
import { useSelector } from "react-redux";
import PlaceholderWnm from "../../../assets/Icons/PlaceholderWnm.png";
import WalletService from "../../../services/walletService";
import BlockChainService from "../../../services/blockChainService";

/**
 * UserDetails component displays detailed information about a user, including posts and comments.
 * It allows editing user details, viewing and managing posts, and disabling posts.
 *
 * @component
 * @example
 * // Example usage of UserDetails component
 * <UserDetails />
 *
 * @returns {JSX.Element} JSX Element representing the UserDetails component.
 */
const UserDetails = () => {
  const history = useHistory();
  const [isContactExpanded, setContactExpanded] = useState(false);
  const [isGeneralExpanded, setGeneralExpanded] = useState(false);
  const [phoneChangeSeen, setphoneChangeSeen] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const showPoll = queryParams.get("showPoll") === "true";
  const haveAudit = queryParams.get("from");
  const tabType = queryParams.get("tab");
  const [showPost, setShowPost] = useState(showPoll || false);
  const [showComment, setShowComment] = useState(
    queryParams.get("isComment") === "true" || false
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [postId, setPostId] = useState("");
  const [details, setDetails] = useState([]);
  const [debounceValuePoll, setDebounceValuePoll] = useState("");
  const [pollDetails, setPollDetails] = useState([]);
  const { id } = useParams();
  const [edit, setEditPopup] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPageComment, setCurrentPageComment] = useState(
    Number(queryParams.get("page")) || 0
  );
  const [debounceValueComment, setDebounceValueComment] = useState("");
  const [isDisablePopUp, setDisablePopUp] = useState(false);
  const [currentPagePoll, setCurrentPagePoll] = useState(
    Number(queryParams.get("page")) || 0
  );
  const [totalDocsPoll, setTotalDocsPoll] = useState(0);
  const [sortPoll, setSortPoll] = useState(
    Number(queryParams.get("sort")) || -1
  );
  const [limitPoll, setLimitPoll] = useState(25);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const filters = queryParams.get("status");
    return filters ? JSON.parse(filters) : [];
  });
  const [selectedFilterType, setSelectedFiltersType] = useState(() => {
    const filters = queryParams.get("type");
    return filters ? JSON.parse(filters) : [];
  });
  const [clickFilter, setClickFilter] = useState(false);
  const [clickFilterType, setClickFilterType] = useState(false);
  const totalPagesPoll = Math.ceil(totalDocsPoll / limitPoll);
  const [updating, setUpdating] = useState(false);
  const [seenActivatePopUp, setSeenActivatePopUp] = useState(false);
  const optionsRef = useRef(null);
  const [balance, setBalance] = useState(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    fullName: details.fullName,
    bio: details.bio,
    userName: details?.userName,
    phone: details?.phone,
    countryCode:
      typeof details?.countryCode === "number"
        ? `+${details?.countryCode}`
        : details?.countryCode || "+49",
  });

  const headers = [
    { name: "Name" },
    { name: "Created", sort: true },
    { name: "Status", filter: true, filterLength: selectedFilters?.length },
    {
      name: "Type",
      typeFilter: true,
      typeFilterLength: selectedFilterType?.length,
    },
    { name: "Amount" },
  ];

  /**
   * Handles changes in the active page for the poll details pagination.
   *
   * @param {number} pageNumber - The selected page number.
   * @returns {void}
   */
  const handlePageChangePoll = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPagesPoll) {
      setCurrentPagePoll(pageNumber);
    }
  };

  /**
   * Handles changes in the active page for the comment details pagination.
   *
   * @param {number} pageNumber - The selected page number.
   * @returns {void}
   */

  const renderEditPopup = () => {
    switch (edit) {
      case "name":
        return (
          <EditPopup
            name={"Full Name"}
            setEditPopup={setEditPopup}
            value={details?.fullName}
            setState={setState}
            keyName="fullName"
            handleSubmission={handleSubmission}
            state={state}
            loading={updating}
          />
        );
      case "username":
        return (
          <EditPopup
            name={"Username"}
            setEditPopup={setEditPopup}
            value={details?.userName}
            setState={setState}
            keyName="userName"
            handleSubmission={handleSubmission}
            state={state}
            loading={updating}
          />
        );

      case "bio":
        return (
          <EditPopup
            name={"Bio"}
            setEditPopup={setEditPopup}
            value={details?.bio}
            setState={setState}
            keyName="bio"
            state={state}
            handleSubmission={handleSubmission}
            loading={updating}
          />
        );
      case "phone":
        return (
          <EditPopup
            name={"Phone number"}
            setEditPopup={setEditPopup}
            value={details?.phone}
            countryCode={
              typeof details?.countryCode === "number"
                ? `+${details?.countryCode}`
                : details?.countryCode
            }
            setState={setState}
            keyName="phone"
            state={state}
            handleSubmission={handleSubmission}
            loading={updating}
          />
        );
      default:
        return null;
    }
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    if (showComment) {
      setDebounceValueComment(value);
      setCurrentPageComment(0);
    } else {
      setDebounceValuePoll(value);
      setCurrentPagePoll(0);
    }
  }, 500);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPageComment(0);
    setCurrentPagePoll(0);
  };

  function copyToClipboard(url) {
    navigator.clipboard.writeText(
      `${window.location.origin}/user/pollDetails/${url}?userId=${id}`
    );
    ShowToast({ message: "Link copied", type: toastType.SUCCESS });
  }

  const getUserDetails = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getUserDetails(id)
      );
      if (error || !response) return;
      setDetails(response);
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getPollDetails = async () => {
    setLoadingUser(true);
    try {
      const requestParams = {
        searchKey: debounceValuePoll,
        sort: sortPoll,
        limit: limitPoll,
        skip: currentPagePoll,
      };
      if (selectedFilterType.length > 0) {
        requestParams.type = selectedFilterType;
      }
      if (selectedFilters.length > 0) {
        requestParams.status = selectedFilters;
      }
      const [err, res] = await utility.parseResponse(
        new UserService().getPostDetails(id, requestParams)
      );
      if (err || !res) return;
      setPollDetails(res?.postsList);
      setTotalDocsPoll(res?.totalCount);
      setLoadingUser(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingUser(false);
    }
  };
  const handleActivateUser = async (id) => {
    let response;
    try {
      setSeenActivatePopUp(false);
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getUserDetails();
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
    }
  };

  const handleMoreClick = (id) => {
    if (selectedUser === id) {
      setSelectedUser(null);
    } else {
      setSelectedUser(id);
    }
  };

  const handleSubmission = async () => {
    setUpdating(true);
    let requestData = {};
    if (state.phone !== details.phone) {
      requestData.phone = state?.phone;
    }
    const countryCodeWithoutPlus = state?.countryCode?.replace(/^\+/, "");
    if (
      state?.countryCode &&
      Number(countryCodeWithoutPlus) !== details?.countryCode
    ) {
      const countryCodeNumber = Number(countryCodeWithoutPlus);
      requestData.countryCode = countryCodeNumber;
    }
    if (state.fullName !== details.fullName) {
      requestData.fullName = state?.fullName;
    }
    if (state.bio !== details.bio) {
      requestData.bio = state?.bio;
    }
    if (state.userName !== details.userName) {
      requestData.userName = state?.userName;
    }
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().userEdit(id, requestData)
      );
      if (err || !res) return;
      if (res?.responseCode === 200) {
        ShowToast({
          message: successMessage.USER_UPDATED,
          type: toastType.SUCCESS,
        });
        getUserDetails();
        setEditPopup(false);
      } else {
        ShowToast({
          message: res?.message,
          type: toastType.ERROR,
        });
      }
      setUpdating(false);
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Toggles the selected filter for posts based on their status (ACTIVE, SCHEDULED, CLOSED).
   *
   * @param {string} filter - The filter to be toggled.
   * @returns {void}
   */
  const toggleSelectedFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
    setCurrentPagePoll(0);
  };

  /**
   * Toggles the selected filter for posts based on their type (PUBLIC, PRIVATE, PUBLIC_TARGETED).
   *
   * @param {string} filter - The filter to be toggled.
   * @returns {void}
   */
  const toggleSelectedFilterType = (filter) => {
    setSelectedFiltersType((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
    setCurrentPagePoll(0);
  };

  /**
   * Renders the filter options for post status (ACTIVE, SCHEDULED, CLOSED).
   *
   * @returns {JSX.Element | null} JSX Element representing the filter options or null if the filter is not clicked.
   */
  const renderFilter = () => {
    return (
      clickFilter && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilter(false)}
        >
          <div
            className={`flex w-45 p-5 flex-col items-start gap-5 absolute top-5 right-1 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("ACTIVE")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("ACTIVE")}
                checked={selectedFilters.includes("ACTIVE")}
              />
              {selectedFilters.includes("ACTIVE") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("ACTIVE");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Active
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("SCHEDULED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("SCHEDULED")}
                checked={selectedFilters.includes("SCHEDULED")}
              />
              {selectedFilters.includes("SCHEDULED") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("SCHEDULED");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Scheduled
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("CLOSED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("CLOSED")}
                checked={selectedFilters.includes("CLOSED")}
              />
              {selectedFilters.includes("CLOSED") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("CLOSED");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Closed
              </label>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Renders the filter options for post type (PUBLIC, PRIVATE, PUBLIC_TARGETED).
   *
   * @returns {JSX.Element | null} JSX Element representing the filter options or null if the filter is not clicked.
   */
  const renderFilterType = () => {
    return (
      clickFilterType && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilterType(false)}
        >
          <div
            className={`flex w-45 p-5 flex-col items-start gap-5 absolute top-5 right-1 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilterType.includes("PUBLIC")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilterType("PUBLIC")}
                checked={selectedFilterType.includes("PUBLIC")}
              />
              {selectedFilterType.includes("PUBLIC") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilterType("PUBLIC");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Public
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilterType.includes("PRIVATE")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilterType("PRIVATE")}
                checked={selectedFilterType.includes("PRIVATE")}
              />
              {selectedFilterType.includes("PRIVATE") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilterType("PRIVATE");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Private
              </label>
            </div>
            {/* <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilterType.includes("PUBLIC_TARGETED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilterType("PUBLIC_TARGETED")}
                checked={selectedFilterType.includes("PUBLIC_TARGETED")}
              />
              {selectedFilterType.includes("PUBLIC_TARGETED") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilterType("PUBLIC_TARGETED");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Public Targeted
              </label>
            </div> */}
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Renders the body of the posts table.
   *
   * @returns {JSX.Element} JSX Element representing the body of the posts table.
   */
  const renderTableBody = () => {
    if (pollDetails.length > 0) {
      return pollDetails.map((userData) => (
        <tr key={userData?._id}>
          <td
            className={`text-black-100 font-ManropeMedium text-ft2  py-4 px-5`}
          >
            <div className="w-40 truncate ">{userData?.post?.name || "-"}</div>
          </td>
          <td className={`text-black-100 font-ManropeMedium text-ft2`}>
            <div className="w-32.5 truncate ">
              {moment(userData.addedOn).format("DD MMM[,] YYYY")}
            </div>
          </td>
          <td>
            <div className="flex flex-col w-30 truncate ">
              <span className="text-black-100 font-ManropeMedium text-ft2">
                {utility.getStatus(
                  userData?.activePeriod?.startDate,
                  userData?.activePeriod?.endDate
                )}
              </span>
              <span className="text-ft0 font-ManropeRegular text-grey-400">
                {moment(userData?.activePeriod?.startDate).format("D")}-
                {moment(userData?.activePeriod?.endDate).format("D MMM YYYY")}
              </span>
            </div>
          </td>
          <td>
            <div className="w-32.5 truncate text-black-50 font-ManropeMedium text-ft1">
              {utility.capitalizeFirstLetter(userData?.post?.type)}{" "}
              {utility.capitalizeFirstLetter(userData?.type)}
            </div>
          </td>
          <td>
            <div className="w-20 truncate ">{userData?.amount || "-"}</div>
          </td>
          <td>
            <div className="relative">
              <div className="w-6.25">
                <img
                  src={more}
                  alt="More"
                  className="cursor-pointer"
                  onClick={() => handleMoreClick(userData?._id)}
                />
              </div>

              {selectedUser === userData?._id && (
                <OutsideClickRef
                  state={optionsRef}
                  setState={() => setSelectedUser(null)}
                >
                  <div className="absolute right-0 flex flex-col items-start w-75 rounded-20 shadow-inner z-10 bg-white">
                    <button
                      className="flex h-16.25 px-5 items-center gap-3.75 self-stretch w-full rounded-t-20 hover:bg-grey-350"
                      onClick={() => copyToClipboard(userData?._id)}
                    >
                      <img src={copy} alt="copy" />
                      <p className="text-ft3 font-ManropeLight text-black-100">
                        Copy Link
                      </p>
                    </button>
                    <hr className="text-grey-50 w-full" />
                    {userPermissions?.indexOf("user:write:poll") > -1 && (
                      <button
                        onClick={() => {
                          setDisablePopUp(true);
                          setPostId(userData?._id);
                        }}
                        className="flex h-16.25 px-5 items-center gap-3.75 self-stretch w-full rounded-b-20 hover:bg-grey-350"
                      >
                        <img src={error} alt="error" />
                        <p className="text-ft3 font-ManropeLight text-black-100">
                          Disable Poll
                        </p>
                      </button>
                    )}
                  </div>
                </OutsideClickRef>
              )}
            </div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr className="h-57vh">
          <td colSpan={5}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery ||
              selectedFilters?.length > 0 ||
              selectedFilterType?.length > 0 ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>
          </td>
        </tr>
      );
    }
  };

  useEffect(() => {
    setState({
      fullName: details.fullName,
      bio: details.bio,
      userName: details?.userName,
      phone: details?.phone,
      countryCode:
        typeof details?.countryCode === "number"
          ? `+${details?.countryCode}`
          : details?.countryCode || "+49",
    });
  }, [details]);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPollDetails();
    setAllQuery();
    // eslint-disable-next-line
  }, [
    id,
    debounceValuePoll,
    sortPoll,
    limitPoll,
    currentPagePoll,
    selectedFilterType,
    selectedFilters,
  ]);

  const setAllQuery = () => {
    if (!showComment && selectedFilterType?.length > 0) {
      queryParams.set("type", JSON.stringify(selectedFilterType));
    } else {
      queryParams.delete("type");
    }
    if (!showComment && selectedFilters?.length > 0) {
      queryParams.set("status", JSON.stringify(selectedFilters));
    } else {
      queryParams.delete("status");
    }
    queryParams.set("sort", sortPoll);
    history.replace({
      search: queryParams.toString(),
    });
  };

  const discardQueryComment = (type) => {
    queryParams.delete("status");
    queryParams.delete("type");
    queryParams.delete("sort");
    queryParams.set("isComment", String(type));
    history.replace({
      search: queryParams.toString(),
    });
    setCurrentPageComment(0);
    setSelectedFilters([]);
    setSelectedFiltersType([]);
  };
  const discardQueryPoll = (type) => {
    queryParams.set("sort", sortPoll);
    queryParams.set("isComment", String(type));
    history.replace({
      search: queryParams.toString(),
    });
    setCurrentPagePoll(0);
    setSelectedFilters([]);
    setSelectedFiltersType([]);
  };

  const handleShowPoll = (value) => {
    setShowPost(value);
    queryParams.set("showPoll", String(value));
    history.replace({
      search: queryParams.toString(),
    });
  };

  const getAddress = async () => {
    try {
      const [err, res] = await utility.parseResponse(
        new WalletService().getAddress(id)
      );
      if (err || !res) return;

      const [error, response] = await utility.parseResponse(
        new BlockChainService().getBalance(res?.responseData?.publicKey)
      );
      if (error && !response) return;

      setBalance(response?.balance);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getAddress();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div
      className={`w-full h-full flex gap-5 overflow-y-auto overflow-x-hidden pr-12.5 pb-5`}
    >
      <div className="flex flex-col w-75per">
        <div className="mt-5 h-21.25 px-5 py-5 flex justify-between items-center rounded-20 bg-white row-start-1 row-span-1 gap-1">
          <div className="flex gap-7.5 items-center">
            <img
              src={left}
              alt="left"
              className="cursor-pointer"
              onClick={() => {
                if (haveAudit) {
                  history.push("/audit-log");
                } else {
                  const dynamicUrl = `/user${tabType ? `?tab=${tabType}` : ""}`;
                  history.push(dynamicUrl);
                }
              }}
            />
            <div className="flex gap-10">
              <p
                className={`${
                  showComment
                    ? "font-ManropeMedium text-ft3 text-grey-250 cursor-pointer"
                    : "font-ManropeBold text-ft5 text-black-100"
                }`}
                onClick={() => {
                  setShowComment(false);
                  setSearchQuery("");
                  setDebounceValueComment("");
                  discardQueryPoll(false);
                }}
              >
                Polls
              </p>
              <p
                className={`${
                  showComment
                    ? "font-ManropeBold text-ft5 text-black-100"
                    : "font-ManropeMedium text-ft3 text-grey-250 cursor-pointer"
                }`}
                onClick={() => {
                  setShowComment(true);
                  setSearchQuery("");
                  setDebounceValuePoll("");
                  discardQueryComment(true);
                }}
              >
                Comments
              </p>
            </div>
          </div>
          <div className="flex gap-2 2xl:gap-5">
            {!showComment && (
              <div className="flex gap-2 2xl:gap-5 items-center">
                {showPost ? (
                  <img
                    src={table}
                    alt="table"
                    className="cursor-pointer"
                    onClick={() => {
                      handleShowPoll(false);
                    }}
                  />
                ) : (
                  <img src={tableOrange} alt="tableOrange" />
                )}
                {showPost ? (
                  <img
                    src={VectorOrange}
                    alt="vectorOrange"
                    className="cursor-pointer w-5 h-5"
                  />
                ) : (
                  <img
                    src={vector}
                    alt="vector"
                    className="cursor-pointer w-5 h-5"
                    onClick={() => handleShowPoll(true)}
                  />
                )}
              </div>
            )}
            <div className="flex w-40 xl:w-56 2xl:w-83.75 h-11.25 p-5 items-center gap-3.75 flex-shrink-0 rounded-full bg-grey-50">
              <img
                src={searchIcon}
                alt="searchIcon"
                className="opacity-60 w-5 h-5"
              />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => handleSearch(e)}
                className="font-ManropeLight text-ft3 w-80per bg-grey-50 text-black-300 focus:outline-none"
              />
              {!!searchQuery && (
                <img
                  onClick={() => {
                    setSearchQuery("");
                    debouncedSearch("");
                  }}
                  src={Close}
                  alt=""
                  className="flex justify-end cursor-pointer w-5 h-5"
                />
              )}
            </div>
          </div>
        </div>

        {!showComment ? (
          <>
            {showPost ? (
              <div className="w-full mb-5 mt-5 rounded-20 bg-white">
                {!loadingUser ? (
                  <div className="overflow-y-scroll flex  items-center flex-col scrollbar-hide max-h-77vh ">
                    {pollDetails.length > 0 ? (
                      pollDetails.map((poll) => (
                        <div key={poll._id}>
                          <Post
                            poll={poll}
                            id={id}
                            url={"/user/pollDetails"}
                            setDisablePopUp={setDisablePopUp}
                            setSelectedUser={handleMoreClick}
                            selectedUser={selectedUser}
                            postId={postId}
                            setPostId={setPostId}
                            tabType={tabType}
                            permission={"user:write:poll"}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="flex w-full h-full justify-center items-center gap-7.5 flex-shrink-0 self-center">
                        <img src={placeholder} alt="placeholder" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex justify-center  items-center h-full w-full">
                    <CircularProgress className="text-black-100 w-5 h-5" />
                  </div>
                )}
                <TableFooter
                  currentPage={currentPagePoll}
                  handlePageChange={handlePageChangePoll}
                  pageSize={limitPoll}
                  setPageSize={setLimitPoll}
                  totalPages={totalPagesPoll - 1}
                  totalDocs={totalDocsPoll}
                  setCurrentPage={setCurrentPagePoll}
                />
              </div>
            ) : (
              <Table
                headers={headers}
                tableBody={renderTableBody()}
                loading={loadingUser}
                pageSize={limitPoll}
                setPageSize={setLimitPoll}
                setSort={setSortPoll}
                sort={sortPoll}
                currentPage={currentPagePoll}
                handlePageChange={handlePageChangePoll}
                totalPages={totalPagesPoll - 1}
                totalDocs={totalDocsPoll}
                setCurrentPage={setCurrentPagePoll}
                clickFilter={clickFilter}
                clickFilterType={clickFilterType}
                renderFilter={renderFilter()}
                renderFilterType={renderFilterType()}
                setClickFilterType={setClickFilterType}
                setClickFilter={setClickFilter}
              />
            )}
          </>
        ) : (
          <Comments
            setCurrentPageComment={setCurrentPageComment}
            currentPageComment={currentPageComment}
            debounceValueComment={debounceValueComment}
            getUserDetails={getUserDetails}
            suspendedState={details?.status}
            suspendedName={details?.fullName}
            searchQuery={searchQuery}
            showComment={showComment}
          />
        )}
      </div>
      <UserDetailAsideSection
        isContactExpanded={isContactExpanded}
        setContactExpanded={setContactExpanded}
        setphoneChangeSeen={setphoneChangeSeen}
        isGeneralExpanded={isGeneralExpanded}
        phoneChangeSeen={phoneChangeSeen}
        setGeneralExpanded={setGeneralExpanded}
        details={details}
        setEditPopup={setEditPopup}
        handleActivateUser={handleActivateUser}
        getUserDetails={getUserDetails}
        seenActivatePopUp={seenActivatePopUp}
        setSeenActivatePopUp={setSeenActivatePopUp}
        balance={balance}
      />

      {renderEditPopup()}
      {isDisablePopUp && (
        <DisablePopUp
          setToggle={setDisablePopUp}
          keyName={"post"}
          selectedId={postId}
          getDetails={getPollDetails}
          updateUser={getUserDetails}
          suspendedState={details?.status}
          suspendedName={details?.fullName}
        />
      )}
    </div>
  );
};

export default UserDetails;
