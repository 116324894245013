/**
 * @module ProfileComponent
 * @description
 * Module for managing and rendering additional fields.
 *
 * @example
 * // Usage in a React functional component
 * import ProfileComponent from "./ProfileComponent";
 *
 * const MyComponent = () => {
 *   return <ProfileComponent />;
 * };
 *
 * @returns {React.Component} The ProfileComponent component
 */

import React, { useState } from "react";
import contactProfile from "../../assets/Icons/contactProfile.svg";
import chevronRight from "../../assets/Icons/Right.svg";
import EditPopup from "../../common/editPopup";
import PasswordPopUp from "./passwordPopUp";
import WarningPopUp from "../../common/warningPopup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sessionManager } from "../../managers/sessionManager";
import {
  keyTypeConstants,
  successMessage,
  toastType,
} from "../../constant/commonArray";
import CountryList from "country-list-with-dial-code-and-flag";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
import { FileUploadService, UserService } from "../../services";
import utility from "../../utility";
import ShowToast from "../../common/showToast";
import ResetPopUp from "./resetPopUp";
import Edit from "../../assets/Icons/edit-white.svg";
import SelectImagePopup from "../entityAccount/createEntity/selectImagePopup";

const { AUTH0_ID_TOKEN } = keyTypeConstants;

/**
 * ProfileComponent functional component to display and edit user profile information.
 */
const ProfileComponent = () => {
  const user = useSelector((state) => state.user);
  const [editImage, setEditImage] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { removeDataFromCookies } = sessionManager;
  const [updating, setUpdating] = useState(false);
  const [edit, setEditPopup] = useState(null);
  const [popup, setPopup] = useState("");
  const [isLogoutWarning, setLogoutWarning] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState({});
  const [state, setState] = useState({
    phone: user?.phone,
    countryCode:
      typeof user?.countryCode === "number"
        ? `+${user?.countryCode}`
        : user?.countryCode || "+49",
    name: user?.name,
  });

  let arr = CountryList.getAll();

  arr.sort(
    (a, b) => parseInt(a.data.dial_code, 10) - parseInt(b.data.dial_code, 10)
  );

  const country = CountryList.findOneByDialCode(state.countryCode);
  const flagSvg = CountryFlagSvg[country?.code];

  /**
   * Renders an edit popup based on the value of 'edit'.
   *
   * @return {JSX.Element} The rendered edit popup component.
   */
  const renderEditPopup = () => {
    switch (edit) {
      case "name":
        return (
          <EditPopup
            name={"Name"}
            setEditPopup={setEditPopup}
            value={user?.name}
            setState={setState}
            keyName="name"
            handleSubmission={handleSubmission}
            state={state}
            loading={updating}
          />
        );

      case "phone":
        return (
          <EditPopup
            name={"Phone number"}
            setEditPopup={setEditPopup}
            value={user?.phone}
            countryCode={
              typeof user?.countryCode === "number"
                ? `+${user?.countryCode}`
                : user?.countryCode || "+49"
            }
            setState={setState}
            keyName="phone"
            state={state}
            handleSubmission={handleSubmission}
            arr={arr}
            flagSvg={flagSvg}
            loading={updating}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Handles the submission of the form, including image upload and user details update.
   *
   * @param {Object} image - The image to be uploaded
   * @return {void}
   */
  const handleSubmission = async (image) => {
    setUpdating(true);
    let requestData = {};

    if (state.phone) {
      requestData.countryCode = state?.countryCode;
      requestData.phone = state?.phone;
    }
    if (state.name) {
      requestData.name = state?.name;
    }
    try {
      if (image) {
        const formData = new FormData();
        formData.append("files", image);

        const [error, response] = await utility.parseResponse(
          new FileUploadService().fileUpload(formData)
        );

        if (error || !response) return;
        if (response) {
          requestData.profilePhoto = response?.responseData[0]?.cdnUrl;
        }
      }
      const [err, res] = await utility.parseResponse(
        new UserService().editStaff(user?.staffId, requestData)
      );
      if (err || !res) return;

      if (res?.responseCode === 200) {
        ShowToast({
          message: successMessage.USER_UPDATED,
          type: toastType.SUCCESS,
        });

        getStaffDetails();
      } else {
        ShowToast({
          message: res?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setUpdating(false);
      setEditPopup(null);
      setFile({});
      setCroppedImage(null);
      setImagePopup(false);
    } catch (e) {
      console.error(e);
      setUpdating(false);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Gets the staff details and updates the user information.
   *
   * @param {void}
   * @return {void}
   */
  const getStaffDetails = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getCheckStaff(user?.userId)
      );

      if (error || !response) return;
      const userPayload = {
        name: response?.responseData?.name,
        email: response?.responseData?.email,
        profilePicture: response?.responseData?.profilePhoto,
        roleDetails: response?.responseData?.roleDetails,
        phone: response?.responseData?.phone,
        staffId: response?.responseData?._id,
        countryCode: response?.responseData?.countryCode,
      };

      dispatch({ type: "UPDATE_USER", payload: userPayload });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Logs out the user by removing user data from state and cookies,
   * and redirects to the home page.
   */
  const logOut = () => {
    dispatch({
      type: "REMOVE_USER",
      payload: {},
    });
    removeDataFromCookies(AUTH0_ID_TOKEN);
    history.replace("/");
  };

  return (
    <div className="py-5 w-full h-full overflow-y-auto">
      <div className="flex flex-col w-65per rounded-20 bg-white gap-7.5 py-7.5">
        <div className="mx-10">
          <div
            onClick={() => setImagePopup(true)}
            onMouseEnter={() => setEditImage(true)}
            onMouseLeave={() => setEditImage(false)}
            className="flex rounded-full justify-center items-center w-42.5 h-42.5 relative"
          >
            <img
              src={user?.profilePicture || contactProfile}
              alt=""
              className="absolute rounded-full w-42.5 h-42.5 "
            />
            {editImage && (
              <img src={Edit} alt="" className="absolute z-10 cursor-pointer" />
            )}
          </div>
        </div>
        <hr className="text-grey-50" />
        <div
          className="flex justify-between px-10 cursor-pointer"
          onClick={() => setEditPopup("name")}
        >
          <p className="text-black-150 font-ManropeBold text-ft3">Name</p>
          <div className="flex justify-between gap-7.5">
            <p className="text-black-700 font-ManropeRegular text-ft3">
              {user?.name || "-"}
            </p>
            <img
              src={chevronRight}
              alt="chevronRight"
              className="cursor-pointer"
            />
          </div>
        </div>
        <hr className="text-grey-50" />
        <div className="flex justify-between px-10">
          <p className="text-black-150 font-ManropeBold text-ft3">Email</p>
          <div className="flex justify-between gap-7.5">
            <p className="text-black-700 font-ManropeRegular text-ft3">
              {user?.email || "_"}
            </p>
          </div>
        </div>
        <hr className="text-grey-50" />
        <div
          className="flex justify-between px-10 cursor-pointer"
          onClick={() => setEditPopup("phone")}
        >
          <p className="text-black-150 font-ManropeBold text-ft3">Phone</p>
          <div className="flex justify-between gap-7.5">
            <p className="text-black-700 font-ManropeRegular text-ft3">
              {typeof user?.countryCode === "number"
                ? `+${user?.countryCode}`
                : user?.countryCode}{" "}
              {user?.phone || "-"}
            </p>
            <img
              src={chevronRight}
              alt="chevronRight"
              className="cursor-pointer"
            />
          </div>
        </div>
        <hr className="text-grey-50" />
        <div
          className="flex justify-between px-10 cursor-pointer"
          onClick={() => setPopup("CHANGE_PASSWORD")}
        >
          <p className="text-black-150 font-ManropeBold text-ft3">
            Change password
          </p>
          <img
            src={chevronRight}
            alt="chevronRight"
            className="cursor-pointer"
          />
        </div>
        <hr className="text-grey-50" />
        <div
          className="text-ft3 font-ManropeBold text-orange-50 px-10 cursor-pointer"
          onClick={() => setLogoutWarning(true)}
        >
          Log out
        </div>
        {renderEditPopup()}
        {popup === "CHANGE_PASSWORD" && (
          <PasswordPopUp setState={setPopup} state={popup} />
        )}
        {isLogoutWarning && (
          <WarningPopUp
            title={"Log out?"}
            description={"Are you sure? You want to log out."}
            setToggle={setLogoutWarning}
            handleActivateUser={logOut}
          />
        )}
        {popup === "FORGOT_PASSWORD" && <ResetPopUp setState={setPopup} />}
        {imagePopup && (
          <SelectImagePopup
            setCroppedImage={setCroppedImage}
            croppedImage={croppedImage}
            setState={setState}
            setFile={setFile}
            file={file}
            setImagePopup={setImagePopup}
            handleSubmission={handleSubmission}
            loading={updating}
            keyName="profile"
          />
        )}
      </div>
    </div>
  );
};

export default ProfileComponent;
