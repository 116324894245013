import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import placeholder from "../../../assets/Icons/Placeholder.svg";

/**
 * ChartWithLine component displays a line chart with customizable color and data.
 *
 * @component
 * @example
 * // Example usage of ChartWithLine component
 * <ChartWithLine
 *   data={[
 *     { date: "2024-01-01", count: 50 },
 *     { date: "2024-01-02", count: 30 },
 *     // ...more data
 *   ]}
 *   color="#EBC9F1"
 * />
 *
 * @param {string} color - The color of the line in the chart.
 * @param {Object[]} data - An array of objects containing data for the line chart.
 *
 * @returns {JSX.Element} The rendered ChartWithLine component.
 */

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col bg-white rounded-20 p-5 shadow-md w-61 gap-2">
        <p className="text-black-100 font-ManropeBold text-ft3">{label}</p>
        <p className="text-black-75 font-ManropeRegular text-ft3">
          {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};

const ChartWithLine = ({ color, data }) => {
  const sortedData = data
    ?.slice()
    .sort((a, b) => new Date(a.date) - new Date(b.date));
  return (
    <div className="flex justify-center items-center flex-grow">
      {sortedData?.length > 0 ? (
        <ResponsiveContainer width="98%" height="80%">
          <LineChart
            width={500}
            height={300}
            data={sortedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            className="cursor-pointer"
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              axisLine={false}
              tickCount={1}
              ticks={[
                sortedData[0].date,
                sortedData[sortedData.length - 1].date,
              ]}
              tickLine={false} // Hide X-axis ticks
            />
            <YAxis
              axisLine={false}
              tick={{ fontSize: 12 }}
              dataKey="count"
              tickCount={3}
              tickLine={false}
              padding={{ bottom: 5 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="count"
              stroke={color}
              strokeWidth={5}
              dot={false}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div className="flex justify-center items-center gap-7.5 h-full rounded-20">
          <img src={placeholder} alt="placeholder" />
        </div>
      )}
    </div>
  );
};

export default ChartWithLine;
