/**
 * @module PollListComponent
 * @description
 * Module for managing and rendering PollListComponent.
 *
 * @example
 * // Usage in a React functional component
 * import PollListComponent from "./PollListComponent";
 *
 * const MyComponent = () => {
 *   return <PollListComponent />;
 * };
 *
 * @returns {React.Component} The PollListComponent component
 */

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import searchIcon from "../../../assets/Icons/Search.svg";
import { ReactComponent as AddIcon } from "../../../assets/Icons/plus.svg";
import { useDebouncedCallback } from "use-debounce";
import Close from "../../../assets/Icons/close.svg";
import ActiveList from "./activeList";
import RejectedList from "./rejectedList";
import FinishedList from "./finishedList";
import UserService from "../../../services/userService";
import utility from "../../../utility";
import OutsideClickRef from "../../../common/outsideClickRef";
import checkMark from "../../../assets/Icons/checkMark.svg";
import { useLocation, useHistory, Link } from "react-router-dom";

const PollListComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const history = useHistory();
  const [tab, setTab] = useState("ACTIVE");
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [sort, setSort] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [clickFilter, setClickFilter] = useState(false);
  const [clickFilterType, setClickFilterType] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const filters = queryParams.get("filtersStatus");
    return filters ? JSON.parse(filters) : [];
  });
  const [selectedFilterType, setSelectedFilterType] = useState(() => {
    const filters = queryParams.get("filtersCategory");
    return filters ? JSON.parse(filters) : [];
  });
  const [sortByType, setSortByType] = useState("");
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const optionsRef = useRef(null);

  const totalPages = Math.ceil(totalDocs / pageSize);
  /**
   * Handles page change for pagination.
   *
   * @param {number} pageNumber - The page number to navigate to.
   * @return {void} This function does not return anything.
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  /**
   * Retrieves a list of polls based on the provided parameters,
   * including sorting, pagination, search, and filters.
   *
   * @returns {Promise<void>} A Promise that resolves once the list of polls is fetched and updated.
   */
  const getPollsList = async () => {
    setLoading(true);
    let requestParams = {
      sort: sort,
      limit: pageSize,
      skip: currentPage,
      pollStatus: tab,
    };

    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    if (selectedFilters.length > 0) {
      requestParams.status = selectedFilters;
    }
    if (selectedFilterType.length > 0) {
      requestParams.type = selectedFilterType;
    }
    if (sortByType) {
      requestParams.sortingKey = sortByType;
    }

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getPollList(requestParams)
      );

      if (error || !response) return;

      setData(response?.posts || []);
      setTotalDocs(response?.totalPosts);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    setDebounceValue(value);
    setCurrentPage(0);
  }, 500);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
  };

  /**
   * Toggles the selected filter in the list of filters.
   *
   * @param {any} filter - The filter to toggle.
   * @return {Array} The updated list of selected filters.
   */
  const toggleSelectedFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  /**
   * A function to toggle the selected filter type.
   *
   * @param {type} filter - The filter to toggle.
   * @return {type} The updated selected filter type.
   */
  const toggleSelectedFilterType = (filter) => {
    setSelectedFilterType((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const renderFilter = () => {
    return (
      clickFilter && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilter(false)}
        >
          <div
            className={`flex w-45 p-5 flex-col items-start gap-5 absolute top-5 right-1 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("PUBLISHED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("PUBLISHED")}
                checked={selectedFilters.includes("PUBLISHED")}
              />
              {selectedFilters.includes("PUBLISHED") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("PUBLISHED");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Published
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("ADMIN_REVIEW")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("ADMIN_REVIEW")}
                checked={selectedFilters.includes("ADMIN_REVIEW")}
              />
              {selectedFilters.includes("ADMIN_REVIEW") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("ADMIN_REVIEW");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Admin Review
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("ENTITY_REVIEW")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("ENTITY_REVIEW")}
                checked={selectedFilters.includes("ENTITY_REVIEW")}
              />
              {selectedFilters.includes("ENTITY_REVIEW") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("ENTITY_REVIEW");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Entity Review
              </label>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };

  const renderFilterType = () => {
    return (
      clickFilterType && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilterType(false)}
        >
          <div
            className={`flex w-45 p-5 flex-col items-start gap-5 absolute top-5 right-1 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilterType.includes("POLL")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilterType("POLL")}
                checked={selectedFilterType.includes("POLL")}
              />
              {selectedFilterType.includes("POLL") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilterType("POLL");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Poll
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilterType.includes("SURVEY")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilterType("SURVEY")}
                checked={selectedFilterType.includes("SURVEY")}
              />
              {selectedFilterType.includes("SURVEY") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilterType("SURVEY");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Survey
              </label>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };

  const handleChangeType = (tab) => {
    setTab(tab);
    setSearchQuery("");
    debouncedSearch("");
    setSelectedFilterType([]);
    setSelectedFilters([]);
  };

  const renderListByTab = () => {
    switch (tab) {
      case "ACTIVE":
        return (
          <ActiveList
            searchQuery={searchQuery}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setSort={setSort}
            loading={loading}
            sort={sort}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setTotalDocs={setTotalDocs}
            data={data}
            renderFilter={renderFilter}
            clickFilter={clickFilter}
            setClickFilter={setClickFilter}
            renderFilterType={renderFilterType}
            clickFilterType={clickFilterType}
            setClickFilterType={setClickFilterType}
            sortByType={sortByType}
            setSortByType={setSortByType}
            selectedFilter={selectedFilters}
            selectedFilterType={selectedFilterType}
          />
        );
      case "REJECTED":
        return (
          <RejectedList
            searchQuery={searchQuery}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setTotalDocs={setTotalDocs}
            totalDocs={totalDocs}
            setSort={setSort}
            sort={sort}
            data={data}
            handlePageChange={handlePageChange}
            loading={loading}
            renderFilterType={renderFilterType}
            clickFilterType={clickFilterType}
            setClickFilterType={setClickFilterType}
            sortByType={sortByType}
            setSortByType={setSortByType}
            selectedFilter={selectedFilters}
            selectedFilterType={selectedFilterType}
          />
        );
      default:
        return (
          <FinishedList
            searchQuery={searchQuery}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setTotalDocs={setTotalDocs}
            totalDocs={totalDocs}
            setSort={setSort}
            sort={sort}
            data={data}
            handlePageChange={handlePageChange}
            loading={loading}
            renderFilterType={renderFilterType}
            clickFilterType={clickFilterType}
            setClickFilterType={setClickFilterType}
            sortByType={sortByType}
            setSortByType={setSortByType}
            renderFilter={renderFilter}
            clickFilter={clickFilter}
            setClickFilter={setClickFilter}
            selectedFilter={selectedFilters}
            selectedFilterType={selectedFilterType}
          />
        );
    }
  };

  useEffect(() => {
    getPollsList();
    setFilters();
    // eslint-disable-next-line
  }, [
    debounceValue,
    pageSize,
    tab,
    sort,
    currentPage,
    selectedFilters,
    selectedFilterType,
    sortByType,
  ]);

  const setFilters = () => {
    if (selectedFilters?.length > 0) {
      queryParams.set("filtersStatus", JSON.stringify(selectedFilters));
    } else {
      queryParams.delete("filtersStatus");
    }
    if (selectedFilterType?.length > 0) {
      queryParams.set("filtersCategory", JSON.stringify(selectedFilterType));
    } else {
      queryParams.delete("filtersCategory");
    }
    history.replace({
      search: queryParams.toString(),
    });
  };

  return (
    <div className="w-full h-full overflow-y-auto pr-12.5">
      <div className="py-5 w-full h-full ">
        <div className="flex justify-between bg-white p-5  w-full rounded-20  h-21.25">
          <div className="flex items-center gap-10">
            {userPermissions?.indexOf("poll:read:list:active") > -1 && (
              <p
                onClick={() => {
                  handleChangeType("ACTIVE");
                }}
                className={` cursor-pointer ${
                  tab === "ACTIVE"
                    ? "text-ft5 text-black-100 font-ManropeBold"
                    : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
                }`}
              >
                Active
              </p>
            )}
            {userPermissions?.indexOf("poll:read:list:rejected") > -1 && (
              <p
                onClick={() => {
                  handleChangeType("REJECTED");
                }}
                className={` cursor-pointer ${
                  tab === "REJECTED"
                    ? "text-ft5 text-black-100 font-ManropeBold"
                    : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
                }`}
              >
                Rejected
              </p>
            )}
            {userPermissions?.indexOf("poll:read:list:finished") > -1 && (
              <p
                onClick={() => {
                  handleChangeType("FINISHED");
                }}
                className={` cursor-pointer ${
                  tab === "FINISHED"
                    ? "text-ft5 text-black-100 font-ManropeBold"
                    : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
                }`}
              >
                Finished
              </p>
            )}
          </div>
          <div className="flex gap-5">
            <div className="flex w-83.75 h-11.25 p-5 items-center gap-3.75 flex-shrink-0 rounded-full bg-grey-50">
              <img
                src={searchIcon}
                alt="searchIcon"
                className="opacity-60 w-5 h-5"
              />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => handleSearch(e)}
                className="font-ManropeLight text-ft3 w-80per bg-grey-50 text-black-300 focus:outline-none"
              />
              {!!searchQuery && (
                <img
                  onClick={() => {
                    setSearchQuery("");
                    debouncedSearch("");
                  }}
                  src={Close}
                  alt=""
                  className="flex justify-end cursor-pointer  w-5 h-5"
                />
              )}
            </div>
            {userPermissions?.indexOf("poll:write:create") > -1 && (
              <Link to="/poll-list/create">
                <div className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center">
                  <AddIcon />
                </div>
              </Link>
            )}
          </div>
        </div>
        {renderListByTab()}
      </div>
    </div>
  );
};

export default PollListComponent;
