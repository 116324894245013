import React, { useEffect, useState } from "react";
import Dropdown from "./component/dropdown";
import { ReactComponent as Close } from "../assets/Icons/close.svg";
import {
  eventConstants,
  optionsRole,
  optionsStaff,
  toastType,
} from "../constant/commonArray";
import { UserService } from "../services";
import utility from "../utility";
import { CircularProgress } from "@mui/material";
import ShowToast from "./showToast";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import PhoneNumberDropdown from "./component/phoneNumberDropdown";
import parsePhoneNumberFromString from "libphonenumber-js";

/**
 * AddEmployee component for adding new employees.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.setAddEployeePopup - Function to set the visibility of the Add Employee popup.
 * @param {Function} props.setValueRole - Function to set the role value.
 * @param {Function} props.setValueStaff - Function to set the staff type value.
 * @param {Function} props.setValuePosition - Function to set the position value.
 * @param {Function} props.setValueDepartment - Function to set the department value.
 * @param {string} props.valueRole - Current selected role.
 * @param {string} props.valueStaff - Current selected staff type.
 * @param {string} props.valuePosition - Current selected position.
 * @param {Function} props.handleSave - Function to handle the save action.
 * @param {boolean} props.loadingState - Loading state for the save action.
 * @returns {JSX.Element} AddEmployee component.
 */
const numberRegex = new RegExp(
  /^(?!.*(\d)\1{6})(?!.*1234567890)(?!.*9876543210).*$/
);
const nameRegex = new RegExp(/^[a-zA-Z\s]*$/);
const numberLengthRegex = new RegExp(/^\d{0,15}$/);
const AddEmployee = ({
  setAddEployeePopup,
  setValueRole,
  setValueStaff,
  setValuePosition,
  setValueDepartment,
  valueRole,
  valueStaff,
  valuePosition,
  handleSave,
  loadingState,
  entityEmail,
  employeeInfo,
}) => {
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [debounceValue, setDebounceValue] = useState("");
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const [employeeState, setEmployeeState] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "+49",
  });

  const getJobsList = async () => {
    setLoading(true);
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().getJobList()
      );
      if (err || !res) return;
      const data = transformResponse(res);
      setJobList(data);

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  const transformResponse = (originalRes) => {
    return originalRes
      .map((departmentData) => {
        const { departmentName, jobs } = departmentData;
        if (jobs && jobs.length > 0) {
          return {
            department: departmentName,
            position: jobs.map((position) => position.name),
          };
        }
        return null;
      })
      .filter((department) => department !== null);
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 800);
  const checkEmailAvailable = async () => {
    const requestData = {
      email: debounceValue,
    };
    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      let [error, email] = await utility.parseResponse(
        new UserService().checkEmail(requestData)
      );
      if (error || !email) {
        setIsEmailAvailable(false);
      } else {
        if (email?.available) {
          setIsEmailAvailable(true);
        } else {
          setIsEmailAvailable(false);
        }
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  const handleEmail = (e) => {
    const emailValue = e.target.value;
    const isValidEmail = utility.validateEmail(emailValue);

    if (emailValue.length <= 64) {
      setError((prev) => ({ ...prev, emailLength: false }));
    } else {
      setError((prev) => ({ ...prev, emailLength: true }));
    }
    if (isValidEmail) {
      setError((prev) => ({ ...prev, emailError: false }));
    } else {
      setError((prev) => ({ ...prev, emailError: true }));
    }
    setEmployeeState((prev) => ({
      ...prev,
      email: e.target.value,
    }));

    debouncedSearch(e.target.value);
  };

  const handleCountryCode = (countryCode) => {
    setEmployeeState((prev) => ({
      ...prev,
      countryCode: countryCode,
    }));
  };

  const isValidPhoneNumber = (phoneNumber, countryCode) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      countryCode
    );
    return parsedPhoneNumber && parsedPhoneNumber.isValid();
  };
  function validatePhoneNumber(phoneNumber) {
    return numberRegex.test(phoneNumber);
  }
  useEffect(() => {
    if (debounceValue && utility.validateEmail(debounceValue))
      checkEmailAvailable();
    // eslint-disable-next-line
  }, [debounceValue]);

  useEffect(() => {
    getJobsList();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 fixed flex justify-center items-center right-0 top-0 h-full w-full">
      {!loading ? (
        <div className="bg-white  relative flex flex-col h-2/3 w-115 z-50 py-7.5 rounded-20 ">
          <div className="flex justify-between px-7.5">
            <span className="text-ft4 text-black-100 font-ManropeBold">
              Add Employee
            </span>
            <Close
              onClick={() => setAddEployeePopup(false)}
              className="cursor-pointer"
            />
          </div>
          <div className="w-full flex flex-col gap-6 mt-5 overflow-y-auto scrollbar-hide h-90per px-7.5 overflow-visible">
            <div className="flex flex-col gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Name
              </label>
              <input
                type="text"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 50) {
                    setError((prev) => ({ ...prev, nameError: false }));
                  } else {
                    setError((prev) => ({ ...prev, nameError: true }));
                  }
                  if (nameRegex.test(inputValue)) {
                    setEmployeeState((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                  }
                }}
                value={employeeState?.name}
                className="rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
              />
              {error?.nameError && (
                <span className=" text-red-50">Name can't be more than 50</span>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Email
              </label>
              <input
                type="text"
                value={employeeState?.email}
                onChange={handleEmail}
                className=" rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
              />
              {(!isEmailAvailable ||
                (entityEmail && entityEmail === employeeState?.email) ||
                employeeInfo?.find(
                  (item) => item?.email === employeeState?.email
                )) &&
                !error?.emailError &&
                !error?.emailLength && (
                  <span className=" text-red-50">Email is already taken</span>
                )}
              {error?.emailError && !error?.emailLength && (
                <span className=" text-red-50">
                  Please enter the valid email
                </span>
              )}
              {error?.emailLength && (
                <span className=" text-red-50">
                  Email can't be more than 64
                </span>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Phone
              </label>
              <div className="rounded-full bg-grey-50  px-6 h-16.25 flex items-center">
                <div className="bg-none h-7 items-center flex gap-1 pr-4 border-r border-black-125">
                  <PhoneNumberDropdown
                    setState={handleCountryCode}
                    state={employeeState?.countryCode}
                  />
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (numberLengthRegex.test(inputValue)) {
                      setEmployeeState((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }));
                    }
                  }}
                  value={employeeState?.phone}
                  className="pl-1 w-full outline-none h-full bg-transparent text-black-100 font-ManropeRegular text-ft3"
                />
              </div>
              {(!isValidPhoneNumber(
                `${employeeState?.countryCode}${parseInt(
                  employeeState?.phone
                )}`,
                employeeState?.countryCode
              ) ||
                !validatePhoneNumber(employeeState?.phone)) &&
                employeeState?.phone !== "" && (
                  <span className=" text-red-50">
                    Please enter the valid phone number
                  </span>
                )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Type of staff
              </label>
              <Dropdown
                options={optionsStaff}
                placeHolder={"Not choosen"}
                value={valueStaff}
                setValue={setValueStaff}
                disabled={true}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Role
              </label>
              <Dropdown
                options={optionsRole}
                placeHolder={"Not choosen"}
                value={valueRole}
                setValue={setValueRole}
                keyName="role"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Position
              </label>
              <Dropdown
                options={jobList}
                placeHolder={"Not choosen"}
                value={valuePosition}
                setValue={setValuePosition}
                setValueDepartment={setValueDepartment}
                keyName={"position"}
              />
            </div>
          </div>
          <div className="mt-5 px-7.5">
            <button
              onClick={() => handleSave(employeeState)}
              className={`rounded-full w-full h-16.25 ${
                !valueRole ||
                !valuePosition ||
                !employeeState?.countryCode ||
                !isValidPhoneNumber(
                  `${employeeState?.countryCode}${parseInt(
                    employeeState?.phone
                  )}`,
                  employeeState?.countryCode
                ) ||
                !validatePhoneNumber(employeeState?.phone) ||
                error?.nameError ||
                error?.emailError ||
                error?.emailLength ||
                loadingState ||
                employeeState?.name?.trim() === "" ||
                !isEmailAvailable ||
                entityEmail === employeeState?.email ||
                employeeInfo?.find(
                  (item) => item?.email === employeeState?.email
                )
                  ? "bg-grey-50"
                  : "bg-orange-50 "
              }`}
              disabled={
                !valueRole ||
                !valuePosition ||
                !employeeState?.countryCode ||
                !isValidPhoneNumber(
                  `${employeeState?.countryCode}${parseInt(
                    employeeState?.phone
                  )}`,
                  employeeState?.countryCode
                ) ||
                !validatePhoneNumber(employeeState?.phone) ||
                error?.nameError ||
                error?.emailError ||
                error?.emailLength ||
                loadingState ||
                employeeState?.name?.trim() === "" ||
                !isEmailAvailable ||
                entityEmail === employeeState?.email ||
                employeeInfo?.find(
                  (item) => item?.email === employeeState?.email
                )
              }
            >
              {loadingState ? (
                <div className="flex justify-center  items-center h-full w-full">
                  <CircularProgress className="text-orange-50" />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-white  relative flex flex-col h-2/3 w-115 z-50 py-7.5 rounded-20 ">
          <div className="flex justify-center  items-center h-full w-full">
            <CircularProgress className="text-black-100 w-5 h-5" />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddEmployee;
