import React, { useState, useRef } from "react";
import Edit from "../../../assets/Icons/Edit.svg";
import EditPopup from "../../../common/editPopup";
import Chevrondown from "../../../assets/Icons/Chevrondown.svg";
import Profile from "../../../assets/Icons/profile-icon.svg";
import Error from "../../../assets/Icons/Error.svg";
import WarningPopup from "../../../common/warningPopup";
import ShowToast from "../../../common/showToast";
import { UserService } from "../../../services";
import { toastType } from "../../../constant/commonArray";
import OutsideClickRef from "../../../common/outsideClickRef";
import CountryList from "country-list-with-dial-code-and-flag";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
import utility from "../../../utility";
import { useSelector } from "react-redux";

/**
 * React component for displaying details of an employee.
 *
 * @component
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.employee - Employee data.
 * @param {Function} props.getEntityDetailsData - Function to get entity details data.
 * @param {number} props.index - Index of the employee.
 * @returns {JSX.Element} JSX for the employee details component.
 */

const DetailsEmployeeInfo = ({
  employee,
  getEntityDetailsData,
  index,
  permissionEdit,
  permissionDelete,
}) => {
  const [edit, setEditPopup] = useState(null);
  const [isEmployeeExpanded, setEmployeeExpanded] = useState(false);
  const [isremoveToggle, setRemoveToggle] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const dropdownRef = useRef(null);
  const [updating, setUpdating] = useState(false);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [state, setState] = useState({
    name: employee?.name,
    email: employee?.email,
    phone: employee?.phone,
    countryCode:
      typeof employee?.countryCode === "number"
        ? `+${employee?.countryCode}`
        : employee?.countryCode || "+49",
    staffType: employee?.userName,
    role: employee?.role
      ?.split("_")
      ?.map(
        (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
      )
      ?.join(" "),
    jobPosition: employee?.jobPosition,
  });

  let arr = CountryList.getAll();
  arr.sort(
    (a, b) => parseInt(a.data.dial_code, 10) - parseInt(b.data.dial_code, 10)
  );
  const country = CountryList?.findOneByDialCode(state?.countryCode);
  const flagSvg = CountryFlagSvg[country?.code];

  const colors = ["bg-purple-50", "bg-blue-100", "bg-yellow-50", "bg-green-50"];
  const colorIndex = index % colors.length;
  const selectedColor = colors[colorIndex];

  /**
   * Renders the edit popup based on the selected field.
   *
   * @function
   * @name renderEditPopup
   * @returns {JSX.Element | null} Edit popup component.
   */

  const renderEditPopup = () => {
    switch (edit) {
      case "role":
        return (
          <EditPopup
            name={"Role"}
            setEditPopup={setEditPopup}
            value={employee?.role
              .split("_")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
            setState={setState}
            state={state}
            keyName="role"
            handleSubmission={handleSubmission}
            loading={updating}
          />
        );
      case "name":
        return (
          <EditPopup
            name={"Name"}
            setEditPopup={setEditPopup}
            setState={setState}
            value={employee?.name}
            state={state}
            keyName="name"
            handleSubmission={handleSubmission}
            loading={updating}
          />
        );

      case "phone":
        return (
          <EditPopup
            name={"Phone Number"}
            setEditPopup={setEditPopup}
            setState={setState}
            keyName="phone"
            state={state}
            value={employee?.phone}
            countryCode={
              typeof employee?.countryCode === "number"
                ? `+${employee?.countryCode}`
                : employee?.countryCode
            }
            handleSubmission={handleSubmission}
            arr={arr}
            flagSvg={flagSvg}
            loading={updating}
          />
        );
      case "position":
        return (
          <EditPopup
            name={"Position"}
            setEditPopup={setEditPopup}
            setState={setState}
            state={state}
            value={employee?.jobPosition}
            keyName="jobPosition"
            handleSubmission={handleSubmission}
            loading={updating}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Handles the submission of edited employee details.
   *
   * @function
   * @name handleSubmission
   * @returns {void}
   */

  const handleSubmission = async () => {
    setUpdating(true);
    let requestData = {};
    if (state.phone !== employee.phone) {
      requestData.phone = state?.phone;
    }

    const countryCodeWithoutPlus = state?.countryCode?.replace(/^\+/, "");
    if (
      state?.countryCode &&
      Number(countryCodeWithoutPlus) !== employee?.countryCode
    ) {
      const countryCodeNumber = Number(countryCodeWithoutPlus);
      requestData.countryCode = countryCodeNumber;
    }
    if (state.name !== employee?.name) {
      requestData.name = state?.name;
    }

    if (state.role?.toUpperCase()?.replace(/\s/g, "_") !== employee?.role) {
      requestData.role = state?.role.toUpperCase().replace(/\s/g, "_");
    }
    if (state.jobPosition !== employee?.jobPosition) {
      requestData.jobPosition = state?.jobPosition;
    }

    try {
      const [err, res] = await utility.parseResponse(
        new UserService().editEmployee(employee?._id, requestData)
      );
      if (err || !res) return;

      if (res?.responseCode === 200) {
        ShowToast({
          message: "Employee update successfully",
          type: toastType.SUCCESS,
        });
        getEntityDetailsData();
      } else {
        ShowToast({
          message: res?.message,
          type: toastType.ERROR,
        });
      }

      setUpdating(false);
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Handles the deletion of an employee.
   *
   * @function
   * @name handleDeleteEmployee
   * @param {string} id - Employee ID.
   * @returns {void}
   */

  const handleDeleteEmployee = async (id) => {
    let response;
    try {
      response = await new UserService().deleteEmployee(id);
      if (response?.statusCode === 400) {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      }
      setShowWarningPopup(false);
      setRemoveToggle(false);
      getEntityDetailsData();
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
    }
  };

  /**
   * Formats the text by capitalizing the first letter of each word.
   *
   * @function
   * @name formatText
   * @param {string} text - Text to be formatted.
   * @returns {string} Formatted text.
   */

  const formatText = (text) => {
    return text
      ?.split("_")
      ?.map(
        (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
      )
      ?.join(" ");
  };

  return (
    <div className="relative">
      <div
        className={`flex flex-col p-5 cursor-pointer rounded-20 bg-white w-full ${
          isEmployeeExpanded ? "h-auto  gap-7.5" : ""
        }`}
      >
        <div className="flex items-center gap-2 xl:gap-3.75 relative">
          <div className="w-auto">
            <div
              className={`flex items-center justify-center w-12.5 h-12.5 rounded-full ${selectedColor}`}
            >
              <img
                src={Profile}
                alt="Information"
                onClick={() =>
                  userPermissions?.indexOf(permissionDelete) > -1 &&
                  setRemoveToggle(!isremoveToggle)
                }
              />
            </div>
          </div>
          <div className="flex w-80per justify-between">
            <div
              onClick={() =>
                userPermissions?.indexOf(permissionDelete) > -1 &&
                setRemoveToggle(!isremoveToggle)
              }
              className="flex flex-col w-70per"
            >
              <span className="text-ft3 w-full truncate font-ManropeBold text-black-50">
                {employee.name}
              </span>
              <span className="text-ft3 font-ManropeRegular text-grey-250">
                {formatText(employee?.staffType)}
              </span>
            </div>
            <img
              src={Chevrondown}
              alt="Chevrondown"
              className="cursor-pointer"
              onClick={() => setEmployeeExpanded(!isEmployeeExpanded)}
            />
          </div>
        </div>
        {isEmployeeExpanded && (
          <div className="flex flex-col gap-5">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <p className="font-ManropeBold text-ft3 text-black-100">Role</p>
                <p className="font-ManropeRegular text-ft3 text-grey-250">
                  {formatText(employee?.role)}
                </p>
              </div>
              {userPermissions?.indexOf(permissionEdit) > -1 && (
                <img
                  src={Edit}
                  alt="Edit"
                  onClick={() => setEditPopup("role")}
                  className="cursor-pointer"
                />
              )}
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col w-90per">
                <p className="font-ManropeBold text-ft3 text-black-100">Name</p>
                <p className="font-ManropeRegular w-full truncate text-ft3 text-grey-250">
                  {employee.name}
                </p>
              </div>
              {userPermissions?.indexOf(permissionEdit) > -1 && (
                <img
                  src={Edit}
                  alt="Edit"
                  onClick={() => setEditPopup("name")}
                  className="cursor-pointer"
                />
              )}
            </div>

            <div className="flex justify-between w-full">
              <div className="flex flex-col w-90per">
                <p className="font-ManropeBold text-ft3 text-black-100">
                  Email
                </p>
                <p className="font-ManropeRegular text-ft3 text-grey-250 w-full truncate">
                  {employee.email}
                </p>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex flex-col">
                <p className="font-ManropeBold text-ft3 text-black-100">
                  Phone number
                </p>
                <p className="font-ManropeRegular text-ft3 text-grey-250">
                  {typeof employee?.countryCode === "number"
                    ? `+${employee?.countryCode}`
                    : employee?.countryCode}{" "}
                  {employee.phone}
                </p>
              </div>
              {userPermissions?.indexOf(permissionEdit) > -1 && (
                <img
                  src={Edit}
                  alt="Edit"
                  onClick={() => setEditPopup("phone")}
                  className="cursor-pointer"
                />
              )}
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <p className="font-ManropeBold text-ft3 text-black-100">
                  Position
                </p>
                <p className="font-ManropeRegular text-ft3 text-grey-250">
                  {employee.jobPosition}
                </p>
              </div>
              {userPermissions?.indexOf(permissionEdit) > -1 && (
                <img
                  src={Edit}
                  alt="Edit"
                  onClick={() => setEditPopup("position")}
                  className="cursor-pointer"
                />
              )}
            </div>

            {renderEditPopup()}
          </div>
        )}

        {showWarningPopup && (
          <WarningPopup
            handleActivateUser={() => handleDeleteEmployee(employee?._id)}
            title={`Remove ${employee?.name}?`}
            description={
              "User will be blocked. All created content will be not available for other users"
            }
            setToggle={setShowWarningPopup}
          />
        )}
      </div>
      {isremoveToggle && (
        <OutsideClickRef state={dropdownRef} setState={setRemoveToggle}>
          <div
            onClick={() => setShowWarningPopup(true)}
            className="absolute right-2 top-18 w-auto left-3  rounded-20 shadow-inner z-10 bg-white cursor-pointer"
          >
            <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
              <img src={Error} alt="Error" />
              <p className="text-ft3 font-ManropeLight text-black-100">
                Remove
              </p>
            </button>
          </div>
        </OutsideClickRef>
      )}
    </div>
  );
};

export default DetailsEmployeeInfo;
