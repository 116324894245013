/**
 * @module RequestsComponent
 * @example
 * // Usage in a React functional component
 * import RequestsComponent from "./RequestsComponent";
 *
 * const MyComponent = () => {
 *   return <RequestsComponent />;
 * };
 * @returns {React.Component} The RequestsComponent
 */

import React, { useEffect, useRef, useState } from "react";
import searchIcon from "../../assets/Icons/Search.svg";
import Table from "../../common/table";
import moment from "moment";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import placeholder from "../../assets/Icons/Placeholder.svg";
import utility from "../../utility";
import { UserService } from "../../services";
import { useDebouncedCallback } from "use-debounce";
import DefaultProfile from "../../assets/Icons/default-profile.svg";
import { requestType } from "../../constant/commonArray";
import checkMark from "../../assets/Icons/checkMark.svg";
import OutsideClickRef from "../../common/outsideClickRef";
import PlaceholderWnm from "../../assets/Icons/PlaceholderWnm.png";

/**
 * Fetches the request list from the server and updates the state accordingly.
 *
 * @param {void}
 * @return {Promise<void>}
 */
const RequestsComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [requestData, setRequestData] = useState([]);
  const [sort, setSort] = useState(Number(queryParams.get("sort")) || -1);
  const [debounceValue, setDebounceValue] = useState("");
  const optionsRef = useRef(null);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const filters = queryParams.get("type");
    return filters ? JSON.parse(filters) : [];
  });
  const [clickFilter, setClickFilter] = useState(false);

  const headers = [
    { name: " Name" },
    { name: "Date of request", sort: true },
    { name: "Type", filter: false, filterLength: selectedFilters?.length },
    { name: "Phone" },
    { name: "Email" },
  ];

  const totalPages = Math.ceil(totalDocs / pageSize);
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  /**
   * Fetches the request list from the server and updates the state accordingly.
   *
   * @param {void}
   * @return {Promise<void>}
   */
  const getRequestList = async () => {
    setLoading(true);
    let requestParams = {
      sort: sort,
      limit: pageSize,
      skip: currentPage,
    };

    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    if (selectedFilters?.length > 0) {
      requestParams.type = selectedFilters;
    }
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getRequest(requestParams)
      );

      if (error || !response) return;
      setRequestData(response?.reqestsList);
      setTotalDocs(response?.totalCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    setCurrentPage(0);
    setDebounceValue(value);
  }, 500);

  /**
   * Handles the search input change event.
   *
   * @param {Event} e - the event object
   * @return {void}
   */
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPage(0);
  };

  /**
   * Toggles the selected filter in the list of filters.
   *
   * @param {type} filter - the filter to be toggled
   * @return {type} undefined
   */
  const toggleSelectedFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const handleType = (item) => {
    if (item === "USER") {
      return "User (Blue Check Verification)";
    } else if (item === "BUSINESS") {
      return "Business (Grey Check)";
    } else if (item === "ORGANIZATION") {
      return "Organization (Blue Check)";
    } else if (item === "GOVERNMENT") {
      return "Government (Green Check)";
    }
  };

  const handleNavigation = (item) => {
    switch (item.type) {
      case requestType.USER:
        history.push(`/requests/blue-check/${item._id}`);
        break;
      case requestType.BUSINESS:
      case requestType.ORGANIZATION:
      case requestType.GOVERNMENT:
        history.push(`/requests/account/${item._id}`);
        break;
      default:
        break;
    }
  };

  /**
   * Renders the filter UI for selecting options.
   *
   * @return {JSX.Element} The filter UI component
   */
  const renderFilter = () => {
    return (
      clickFilter && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilter(false)}
        >
          <div
            className={`flex w-75 p-5 flex-col items-start gap-5 absolute top-5 right-2 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("USER")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("USER")}
                checked={selectedFilters.includes("USER")}
              />
              {selectedFilters.includes("USER") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("USER");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                User (Blue Check Verification)
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("BUSINESS")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("BUSINESS")}
                checked={selectedFilters.includes("BUSINESS")}
              />
              {selectedFilters.includes("BUSINESS") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("BUSINESS");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Business (Grey Check)
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("GOVERNMENT")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("GOVERNMENT")}
                checked={selectedFilters.includes("GOVERNMENT")}
              />
              {selectedFilters.includes("GOVERNMENT") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("GOVERNMENT");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Government (Green Check)
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("ORGANIZATION")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("ORGANIZATION")}
                checked={selectedFilters.includes("ORGANIZATION")}
              />
              {selectedFilters.includes("ORGANIZATION") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("ORGANIZATION");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Organization (Blue Check)
              </label>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };
  /**
   * Renders the table body based on the requestData.
   *
   * @return {JSX.Element} The table body JSX element
   */
  const renderTableBody = () => {
    if (requestData?.length > 0) {
      return requestData?.map((item) => (
        <tr
          key={item?._id}
          onClick={() => handleNavigation(item)}
          className="hover:bg-grey-350 cursor-pointer"
        >
          <td className=" py-2.5 px-5">
            <div className="flex gap-2.5 items-center w-60">
              {item?.profilePhoto ? (
                <img
                  src={item?.profilePhoto}
                  alt="User"
                  className="w-10 h-10 rounded-full"
                />
              ) : (
                <div className="bg-black-100 rounded-full w-10 h-10 relative flex justify-center items-center">
                  <img
                    src={DefaultProfile}
                    alt=""
                    className="absolute w-11.25 h-9.75"
                  />
                </div>
              )}
              <div className="flex flex-col">
                <span className="font-ManropeBold text-black-100  text-ft1 w-46.25 truncate">
                  {item.fullName}
                </span>
                <span className="font-ManropeRegular  text-ft1 text-grey-200 w-46.25 truncate">
                  {item.userName}
                </span>
              </div>
            </div>
          </td>
          <td className="font-ManropeRegular text-ft2 text-black-50">
            <div className="w-35 truncate">
              {moment(item.addedon).format("DD MMM[,] YYYY")}
            </div>
          </td>
          <td className="font-ManropeRegular text-ft2 text-black-50">
            <div className="w-50 truncate">{handleType(item.type)}</div>
          </td>
          <td>
            <div className="flex gap-1 font-ManropeRegular text-ft2 text-black-50 w-35 truncate">
              <span>+{item.countryCode}</span>
              <span>{item.phone}</span>
            </div>
          </td>
          <td className="font-ManropeRegular text-ft2 text-black-50">
            <div className="w-35 xl:w-auto truncate">{item.email}</div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={5}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery || selectedFilters?.length > 0 ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  useEffect(() => {
    getRequestList();
    setFilters();
    // eslint-disable-next-line
  }, [debounceValue, pageSize, sort, currentPage, selectedFilters]);

  const setFilters = () => {
    if (selectedFilters?.length > 0) {
      queryParams.set("type", JSON.stringify(selectedFilters));
    } else {
      queryParams.delete("type");
    }
    queryParams.set("sort", sort);
    history.replace({
      search: queryParams.toString(),
    });
  };
  return (
    <div className="py-5 w-full h-full overflow-y-auto pr-12.5">
      <div className="bg-white p-5 rounded-20 h-21.25 flex items-center">
        <div className="flex w-full p-5 items-center flex-shrink-0 gap-5 rounded-full bg-grey-50 h-11.25">
          <img
            src={searchIcon}
            alt="searchIcon"
            className="opacity-60 w-5 h-5"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            className="font-ManropeLight text-ft3 bg-grey-50 text-black-300 focus:outline-none w-full"
          />
        </div>
      </div>
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setSort={setSort}
        loading={loading}
        sort={sort}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalPages={totalPages - 1}
        totalDocs={totalDocs}
        setCurrentPage={setCurrentPage}
        clickFilter={clickFilter}
        renderFilter={renderFilter()}
        setClickFilter={setClickFilter}
      />
    </div>
  );
};

export default RequestsComponent;
