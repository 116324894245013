/**
 * @module StaffComponent
 * @description
 * Module for managing and rendering additional fields.
 *
 * @example
 * // Usage in a React functional component
 * import StaffComponent from "./StaffComponent";
 *
 * const MyComponent = () => {
 *   return <StaffComponent />;
 * };
 *
 * @returns {React.Component} The StaffComponent component
 */

import React, { useEffect, useState } from "react";
import searchIcon from "../../assets/Icons/Search.svg";
import Close from "../../assets/Icons/close.svg";
import { ReactComponent as AddIcon } from "../../assets/Icons/plus.svg";
import Active from "./active";
import Blocked from "./blocked";
import CreateNewStaffComponent from "./createNewStaffComponent";
import RolesPopup from "../roles/rolesPopup";
import { UserService } from "../../services";
import utility from "../../utility";
import { useDebouncedCallback } from "use-debounce";
import { useSelector } from "react-redux";
import left from "../../assets/Icons/Left.svg";
import { useHistory, useLocation } from "react-router-dom";

const StaffComponent = () => {
  const [active, setIsActive] = useState("ACTIVE");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [staffData, setStaffData] = useState([]);
  const [sort, setSort] = useState(-1);
  const [debounceValue, setDebounceValue] = useState("");
  const [selectedFilter, setSelectedFilters] = useState([]);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [isPopupType, setIsPopupType] = useState("");
  const [roleList, setRoleList] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const history = useHistory();

  const totalPages = Math.ceil(totalDocs / pageSize);
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getRoleList = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getRoleList()
      );

      if (error || !response) return;

      setRoleList(response);
    } catch (e) {
      console.error(e);
    }
  };

  const getStaffList = async () => {
    setLoading(true);
    let requestParams = {
      sort: sort,
      limit: pageSize,
      skip: currentPage,
      status: active,
    };

    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    if (selectedFilter.length > 0) {
      requestParams.role = selectedFilter;
    }

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getStaffList(requestParams)
      );

      if (error || !response) return;
      const staffList = response?.staffList;
      const filteredStaffList = staffList?.filter(
        (staff) => !user?.staffId?.includes(staff?._id)
      );
      setStaffData(filteredStaffList);
      setTotalDocs(response?.totalCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    setCurrentPage(0);
    setDebounceValue(value);
  }, 500);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    getStaffList();
    // eslint-disable-next-line
  }, [debounceValue, pageSize, active, sort, currentPage, selectedFilter]);

  useEffect(() => {
    getRoleList();
  }, []);

  return (
    <div className="w-full h-full overflow-y-auto pr-12.5">
      <div className="py-5 w-full h-full ">
        <div className="flex justify-between bg-white p-5  w-full rounded-20  h-21.25">
          <div className="flex items-center gap-10">
            <div className="flex gap-3 items-center">
              {haveAudit && (
                <div
                  onClick={() => {
                    if (haveAudit) {
                      history.push("/audit-log");
                    }
                  }}
                >
                  <img src={left} alt="left" className="cursor-pointer" />
                </div>
              )}
              <p
                onClick={() => {
                  setIsActive("ACTIVE");
                  setCurrentPage(0);
                  setDebounceValue("");
                  setSearchQuery("");
                  setSelectedFilters([]);
                }}
                className={` cursor-pointer ${
                  active === "ACTIVE"
                    ? "text-ft5 text-black-100 font-ManropeBold"
                    : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
                }`}
              >
                Active
              </p>
            </div>
            <p
              onClick={() => {
                setIsActive("BLOCKED");
                setCurrentPage(0);
                setDebounceValue("");
                setSearchQuery("");
                setSelectedFilters([]);
              }}
              className={` cursor-pointer ${
                active === "BLOCKED"
                  ? "text-ft5 text-black-100 font-ManropeBold"
                  : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
              }`}
            >
              Blocked
            </p>
          </div>
          <div className="flex gap-5">
            <div className="flex w-83.75 h-11.25 p-5 items-center gap-3.75 flex-shrink-0 rounded-full bg-grey-50">
              <img
                src={searchIcon}
                alt="searchIcon"
                className="opacity-60 w-5 h-5"
              />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => handleSearch(e)}
                className="font-ManropeLight text-ft3 w-80per bg-grey-50 text-black-300 focus:outline-none"
              />
              {!!searchQuery && (
                <img
                  onClick={() => {
                    setSearchQuery("");
                    debouncedSearch("");
                  }}
                  src={Close}
                  alt=""
                  className="flex justify-end cursor-pointer  w-5 h-5"
                />
              )}
            </div>
            {userPermissions?.indexOf("staff:write:create") > -1 && (
              <div
                className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center cursor-pointer"
                onClick={() => setIsPopupType("CREATE")}
              >
                <AddIcon />
              </div>
            )}
          </div>
        </div>
        {active === "ACTIVE" ? (
          <Active
            setIsActive={setIsActive}
            searchQuery={searchQuery}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setSort={setSort}
            loading={loading}
            sort={sort}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
            data={staffData}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilter}
            getStaffList={getStaffList}
            roleList={roleList}
          />
        ) : (
          <Blocked
            setIsActive={setIsActive}
            searchQuery={searchQuery}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setSort={setSort}
            loading={loading}
            sort={sort}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
            data={staffData}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilter}
            getStaffList={getStaffList}
            roleList={roleList}
          />
        )}
      </div>
      {isPopupType === "CREATE" && (
        <CreateNewStaffComponent
          setIsPopupType={setIsPopupType}
          getStaffList={getStaffList}
        />
      )}
      {isPopupType === "ROLE" && (
        <RolesPopup
          onClose={setIsPopupType}
          heading="Create new role"
          setToggle={() => setIsPopupType("CREATE")}
        />
      )}
    </div>
  );
};

export default StaffComponent;
