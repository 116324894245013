/**
 * React component representing the details page for a user's polls.
 *
 * @module PollDetailsComponent
 * @example
 * // Usage in a React functional component
 * import PollDetailsComponent from "./PollDetailsComponent";
 *
 * const MyComponent = () => {
 *   return <PollDetailsComponent />;
 * };
 *
 * @returns {React.Component} The PollDetailsComponent
 */
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import EditPopup from "../../../common/editPopup";
import Poll from "../../../common/Poll";
import ShowToast from "../../../common/showToast";
import { successMessage, toastType } from "../../../constant/commonArray";
import { UserService } from "../../../services";
import utility from "../../../utility";
import PollHeader from "./pollHeader";
import UserDetailAsideSection from "../details/UserDetailAsideSection";
import { CircularProgress } from "@mui/material";
import DisablePopUp from "../../../common/disablePopUp";
import WalletService from "../../../services/walletService";
import BlockChainService from "../../../services/blockChainService";
import PollLikes from "../../../common/pollLikes";
import PollComment from "../../../common/pollComment";
import PollReposts from "../../../common/pollReposts";
import PollVoted from "../../../common/pollVoted";

const PollDetailsComponent = () => {
  const [isContactExpanded, setContactExpanded] = useState(false);
  const [isGeneralExpanded, setGeneralExpanded] = useState(false);
  const [phoneChangeSeen, setphoneChangeSeen] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const { id } = useParams();
  const location = useLocation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userId = queryParams.get("userId");
  const [details, setDetails] = useState([]);
  const [pollState, setPollState] = useState(
    location?.state?.state?.pollState || "polls"
  );
  const [likeList, setLikesList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [votesList, setVotesList] = useState([]);
  const [repostList, setRepostList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pollDetails, setPollDetails] = useState([]);
  const [state, setState] = useState({
    fullName: details.fullName,
    bio: details.bio,
    userName: details?.userName,
    phone: details?.phone,
    countryCode: details?.countryCode,
  });
  const [isDisablePopUp, setDisablePopUp] = useState(false);
  const [isCommentPopUp, setIsCommentPopUp] = useState(false);
  const [isCommentId, setIsCommentId] = useState("");
  const [showClickableOption, setShowClickableOption] = useState("");
  const [isUserDetailsLoading, setIsUserDetailsLoading] = useState(false);
  const [seenActivatePopUp, setSeenActivatePopUp] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * Handles page change in the paginated table.
   * @function
   * @name handlePageChange
   * @param {number} pageNumber - The selected page number.
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  /**
   * Handles the submission of user details edits.
   *
   * @async
   * @function
   *  @name handleSubmission
   * @returns {Promise<void>}
   */

  const handleSubmission = async () => {
    setEditPopup(false);
    let requestData = {};
    if (state.phone !== details.phone) {
      requestData.phone = state.phone;
    }
    const countryCodeWithoutPlus = state?.countryCode?.replace(/^\+/, "");
    if (
      state?.countryCode &&
      Number(countryCodeWithoutPlus) !== details?.countryCode
    ) {
      const countryCodeNumber = Number(countryCodeWithoutPlus);
      requestData.countryCode = countryCodeNumber;
    }
    if (state.fullName !== details.fullName) {
      requestData.fullName = state?.fullName;
    }
    if (state.bio !== details.bio) {
      requestData.bio = state?.bio;
    }
    if (state.userName !== details.userName) {
      requestData.userName = state?.userName;
    }
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().userEdit(userId, requestData)
      );
      if (err || !res) return;
      if (res?.responseCode === 200) {
        ShowToast({
          message: successMessage.USER_UPDATED,
          type: toastType.SUCCESS,
        });
        getUserData();
        setEditPopup(false);
      } else {
        ShowToast({
          message: res?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Handles the activation of a user.
   *
   * @async
   * @function
   * @name handleActivateUser
   * @param {string} id - The user ID.
   * @returns {Promise<void>}
   */
  const handleActivateUser = async (id) => {
    let response;
    try {
      setSeenActivatePopUp(false);
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getUserData();
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
    }
  };

  /**
   * Retrieves user details from the server.
   *
   * @async
   * @function
   * @name getUserDetails
   * @returns {Promise<void>}
   */

  const getUserData = async () => {
    setIsUserDetailsLoading(true);
    const requestData = {
      userId: userId,
    };

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getUserDetails(userId)
      );
      if (error || !response) return;
      setDetails(response);

      const [err, res] = await utility.parseResponse(
        new UserService().userPollDetails(id, requestData)
      );
      if (err || !res) return;
      setPollDetails(res);
      setIsUserDetailsLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setIsUserDetailsLoading(false);
    }
  };

  const getUserDetails = async () => {
    setIsLoading(true);
    const requestData = {
      limit: pageSize,
      skip: currentPage,
    };

    try {
      switch (pollState) {
        case "likes":
          const [errLikes, resLikes] = await utility.parseResponse(
            new UserService().getUserLikes(id, requestData)
          );
          if (errLikes || !resLikes) return;
          setLikesList(resLikes[0]?.likedUsers);
          setTotalDocs(resLikes[0]?.totalCount);
          break;
        case "voted":
          const [errVotes, resVotes] = await utility.parseResponse(
            new UserService().getUserVotes(id, requestData)
          );
          if (errVotes || !resVotes) return;
          setVotesList(resVotes[0]?.votedUsers);
          setTotalDocs(resVotes[0]?.totalCount);
          break;
        case "reposts":
          const [errRepost, resRepost] = await utility.parseResponse(
            new UserService().getUserRepost(id, requestData)
          );

          if (errRepost || !resRepost) return;
          setRepostList(resRepost[0]?.repostedUsers);
          setTotalDocs(resRepost[0]?.totalCount);
          break;
        case "comments":
          const [errComment, resComment] = await utility.parseResponse(
            new UserService().getUserComment(id, requestData)
          );
          if (errComment || !resComment) return;
          setCommentList(resComment[0]?.comments);
          setTotalDocs(resComment[0]?.totalCount);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateData = () => {
    getUserData();
    getUserDetails();
  };

  /**
   * Retrieves poll details for a user from the server.
   *
   * @async
   * @function
   * @name getPollDetails
   * @returns {Promise<void>}
   */

  /**
   * Handles a click event to show/hide additional user information.
   *
   * @function
   * @param {string} id - The user ID.
   * @returns {void}
   */
  const handleMoreClick = (id) => {
    if (selectedUser === id) {
      setSelectedUser(null);
    } else {
      setSelectedUser(id);
    }
  };

  const renderEditPopup = () => {
    switch (editPopup) {
      case "name":
        return (
          <EditPopup
            name={"Full Name"}
            setEditPopup={setEditPopup}
            value={details?.fullName}
            setState={setState}
            keyName="fullName"
            handleSubmission={handleSubmission}
            state={state}
          />
        );
      case "username":
        return (
          <EditPopup
            name={"Username"}
            setEditPopup={setEditPopup}
            value={details?.userName}
            setState={setState}
            keyName="userName"
            handleSubmission={handleSubmission}
            state={state}
          />
        );

      case "bio":
        return (
          <EditPopup
            name={"Bio"}
            setEditPopup={setEditPopup}
            value={details?.bio}
            setState={setState}
            keyName="bio"
            state={state}
            handleSubmission={handleSubmission}
          />
        );
      case "phone":
        return (
          <EditPopup
            name={"Phone number"}
            setEditPopup={setEditPopup}
            value={details?.phone}
            countryCode={
              typeof details?.countryCode === "number"
                ? `+${details?.countryCode}`
                : details?.countryCode
            }
            setState={setState}
            keyName="phone"
            state={state}
            handleSubmission={handleSubmission}
          />
        );
      default:
        return null;
    }
  };

  const getAddress = async () => {
    try {
      const [err, res] = await utility.parseResponse(
        new WalletService().getAddress(userId)
      );
      if (err || !res) return;

      const [error, response] = await utility.parseResponse(
        new BlockChainService().getBalance(res?.responseData?.publicKey)
      );
      if (error && !response) return;

      setBalance(response?.balance);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const renderPollComponent = () => {
    switch (pollState) {
      case "polls":
        return (
          <Poll
            pollState={pollState}
            details={pollDetails}
            loading={isLoading}
          />
        );
      case "likes":
        return (
          <PollLikes
            loading={isLoading}
            likes={likeList}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
          />
        );
      case "comments":
        return (
          <PollComment
            loading={isLoading}
            comment={commentList}
            permission={"user:write:comment"}
            setIsCommentId={setIsCommentId}
            setIsCommentPopUp={setIsCommentPopUp}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
          />
        );
      case "reposts":
        return (
          <PollReposts
            loading={isLoading}
            repost={repostList}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
          />
        );
      case "voted":
        return (
          <PollVoted
            loading={isLoading}
            votes={votesList}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    getAddress();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line
  }, [pollState, pageSize, currentPage]);

  if (isUserDetailsLoading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  /**
   * Renders the main PollDetailsComponent.
   *
   * @returns {React.ReactNode} - The rendered component.
   */

  return (
    <div className="w-full h-full flex gap-5 overflow-y-auto pr-12.5">
      <div className="flex flex-col w-full">
        <PollHeader
          handleMoreClick={handleMoreClick}
          pollState={pollState}
          setPollState={setPollState}
          details={pollDetails}
          setDisablePopUp={setDisablePopUp}
          setShowClickableOption={setShowClickableOption}
          showClickableOption={showClickableOption}
          url={"/user/userDetails"}
          permission={"user:write:poll"}
          setCurrentPage={setCurrentPage}
        />

        {renderPollComponent()}
      </div>
      <UserDetailAsideSection
        isContactExpanded={isContactExpanded}
        setContactExpanded={setContactExpanded}
        setphoneChangeSeen={setphoneChangeSeen}
        isGeneralExpanded={isGeneralExpanded}
        phoneChangeSeen={phoneChangeSeen}
        setGeneralExpanded={setGeneralExpanded}
        details={details}
        setEditPopup={setEditPopup}
        handleActivateUser={handleActivateUser}
        getUserDetails={getUserData}
        seenActivatePopUp={seenActivatePopUp}
        setSeenActivatePopUp={setSeenActivatePopUp}
        balance={balance}
      />
      {renderEditPopup()}
      {isDisablePopUp && (
        <DisablePopUp
          setToggle={setDisablePopUp}
          keyName={"post"}
          selectedId={id}
          getDetails={updateData}
          updateUser={getUserData}
          suspendedState={details?.status}
          suspendedName={details?.fullName}
        />
      )}
      {isCommentPopUp && (
        <DisablePopUp
          setToggle={setIsCommentPopUp}
          keyName={"comment"}
          selectedId={isCommentId}
          getDetails={getUserDetails}
          updateUser={getUserData}
          name="Disable comment"
          suspendedState={details?.status}
          suspendedName={details?.fullName}
        />
      )}
    </div>
  );
};

export default PollDetailsComponent;
