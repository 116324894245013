/**
 * BlueCheck component for handling blue check verification requests.
 * @component
 *
 * @example
 * // Usage example in another component or file
 * import BlueCheck from './BlueCheck';
 * // ...
 * <BlueCheck />
 *
 * @returns {JSX.Element} BlueCheck component
 */

import React, { useEffect, useState } from "react";
import left from "../../assets/Icons/Left.svg";
import OtherLink from "../../assets/Icons/other-link.svg";
import DetailsProfile from "../../common/detailsProfile";
import DetailsBalance from "../../common/detailsBalance";
import DetailsGeneralInfo from "../entityAccount/details/detailsGeneralInfo";
import DetailsContact from "../../common/detailsContact";
import pause from "../../assets/Icons/pause.svg";
import ImagePopUp from "../../common/imagePopUp";
import RejectRequestPopUp from "./rejectRequestPopUp";
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import utility from "../../utility";
import { UserService } from "../../services";
import { CircularProgress } from "@mui/material";
import ShowToast from "../../common/showToast";
import { linkType, toastType } from "../../constant/commonArray";
import tick from "../../assets/Icons/Tick.svg";
import time from "../../assets/Icons/Time.svg";
import SuspendedUserPopUp from "../DashboardPage/details/SuspendedUserPopUp";
import WarningPopup from "../../common/warningPopup";
import { useSelector } from "react-redux";
import FileImage from "../../assets/Icons/paperclip.svg";
import WalletService from "../../services/walletService";
import BlockChainService from "../../services/blockChainService";

/**
 * BlueCheck component
 *
 * @returns {JSX.Element} BlueCheck component
 */

const BlueCheck = () => {
  const [isGeneralExpanded, setGeneralExpanded] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isUserExpanded, setUserExpanded] = useState(false);
  const [toggleImagePopUp, setToggleImagePopUp] = useState(false);
  const [isRejectPopUp, setRejectPopUp] = useState(false);
  const [requestDetails, setRequestDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [image, setImages] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const [popUpVisible, setPopUpVisible] = useState({
    popUp: false,
    keyName: "",
  });
  const [seenActivatePopUp, setSeenActivatePopUp] = useState(false);
  const [reason, setReason] = useState("Not Choosen");
  const [customReasonInput, setCustomReasonInput] = useState("");
  const [suspendType, setSuspendType] = useState("");
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [balance, setBalance] = useState("");
  /**
   * Toggles the visibility of a pop-up and updates the keyName value.
   *
   * @param {type} value - the new value for the keyName
   * @return {type} undefined
   */
  const togglePopUpVisble = (value) => {
    setPopUpVisible((prev) => ({
      ...prev,
      popUp: !popUpVisible.popUp,
      keyName: value,
    }));
    setUserExpanded(false);
  };

  /**
   * Asynchronously retrieves blue tick request details and updates the state
   *
   * @param {} -
   * @return {}
   */
  const getBlueTickRequestDetails = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getBlueTickRequestDetails(id)
      );

      if (error || !response) return;

      setRequestDetails(response);
      setLoading(false);
      setUserExpanded(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const getAddress = async () => {
    try {
      const [err, res] = await utility.parseResponse(
        new WalletService().getAddress(requestDetails?.requester?._id)
      );
      if (err || !res) return;

      const [error, response] = await utility.parseResponse(
        new BlockChainService().getBalance(res?.responseData?.publicKey)
      );
      if (error && !response) return;

      setBalance(response?.balance);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };
  useEffect(() => {
    if (Object.keys(requestDetails)?.length > 0) getAddress();
    // eslint-disable-next-line
  }, [requestDetails]);

  useEffect(() => {
    getBlueTickRequestDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requestDetails) {
      const suspend = utility.getSuspensionType(
        requestDetails?.requester?.suspension?.type,
        requestDetails?.requester?.suspension?.period?.startDate,
        requestDetails?.requester?.suspension?.period?.endDate
      );
      setSuspendType(suspend);
    }
  }, [requestDetails]);

  /**
   * Renders profile dots based on the status.
   *
   * @param {string} status - The status of the profile
   * @return {JSX.Element} The profile dots component
   */
  const renderProfileDots = (status) => {
    return status === "ACTIVE" && isUserExpanded ? (
      <div>
        {userPermissions?.indexOf("user:write:suspend") > -1 && (
          <div
            onClick={() => togglePopUpVisble("suspend")}
            className="absolute right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-10 bg-white cursor-pointer"
          >
            <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
              <img src={pause} alt="Pause" />
              <p className="text-ft3 font-ManropeLight text-black-100">
                Suspend
              </p>
            </button>
          </div>
        )}
      </div>
    ) : (
      isUserExpanded && status === "SUSPENDED" && (
        <div className="absolute flex flex-col right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-10 bg-white">
          {userPermissions?.indexOf("user:write:activate") > -1 && (
            <button
              onClick={() => setSeenActivatePopUp(!seenActivatePopUp)}
              className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
            >
              <img src={tick} alt="tick" />
              <p className="text-ft3 font-ManropeRegular text-black-100">
                Activate
              </p>
            </button>
          )}
          {userPermissions?.indexOf("user:write:suspend") > -1 && (
            <button
              className="flex h-16.25 px-5 items-center gap-3.75 shadow-custom"
              onClick={() => togglePopUpVisble("edit")}
            >
              <img src={time} alt="time" />
              <p className="text-ft3 font-ManropeRegular text-black-100">
                Edit Suspended time
              </p>
            </button>
          )}
        </div>
      )
    );
  };

  /**
   * Asynchronously handles the approval of a blue tick request.
   *
   * @return {void}
   */
  const handleApproveBlueTick = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().approveBlueTickRequest(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: "Request of " + requestDetails?.fullName + " confirmed",
          type: toastType.SUCCESS,
        });
        history.push("/requests");
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * A function that handles the rejection of a blue tick request.
   *
   * @param {}
   * @return {}
   */
  const handleRejectBlueTick = async () => {
    try {
      let requestData = {
        requestType: "USER",
        requestReason: reason === "Other" ? customReasonInput : reason,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().rejectRequest(id, requestData)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        history.push("/requests");
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Asynchronously handles the activation of a user.
   *
   * @param {type} id - the id of the user to be activated
   * @return {type} undefined
   */
  const handleActivateUser = async (id) => {
    let response;
    setUpdatingUser(true);
    try {
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getBlueTickRequestDetails();
      setSeenActivatePopUp(false);
      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };

  const checkLinkType = (link) => {
    const lowerCaseLink = link?.toLowerCase();
    const foundLink = linkType?.find(
      (item) => item?.name?.toLowerCase() === lowerCaseLink
    );
    return foundLink ? foundLink.icons : OtherLink;
  };

  const getBackgroundColor = (isKYCVerified) => {
    switch (isKYCVerified) {
      case "NOT_STARTED":
        return "bg-yellow-50";
      case "DECLINED":
        return "bg-red-75";
      default:
        return "bg-green-50";
    }
  };

  const getSuspensionText = (requestDetails) => {
    if (requestDetails?.requester?.suspension) {
      const suspendedOnDate = requestDetails?.requester?.suspension?.period
        ?.startDate
        ? new Date(requestDetails?.requester.suspension.period.startDate)
        : null;

      const suspendedTillDate = requestDetails?.requester?.suspension?.period
        ?.endDate
        ? new Date(requestDetails?.requester.suspension.period.endDate)
        : null;
      const startDate = utility.formatDate(suspendedOnDate);
      const endDate = utility.formatDate(suspendedTillDate);

      if (requestDetails.requester.suspension.period?.endDate !== 0) {
        return `Suspended ${startDate} - ${endDate}`;
      }
      return `Suspended ${startDate}`;
    }
    return "-";
  };

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div className="w-full overflow-y-auto mt-5 pb-5 pr-12.5">
      <div className="flex gap-5">
        <div className="flex flex-col w-75per">
          <div className=" h-21.25 px-5 flex justify-between items-center rounded-20 bg-white">
            <div className="flex gap-7.5 items-center">
              <div
                onClick={() => {
                  if (haveAudit) {
                    history.push("/audit-log");
                  } else {
                    history.push("/requests");
                  }
                }}
              >
                <img src={left} alt="left" className="cursor-pointer" />
              </div>

              <p className="text-black-100 font-ManropeBold text-ft5">
                Request for blue check verification
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5 rounded-t-20 mt-5 max-h-70vh overflow-y-auto scrollbar-hide overflow-x-hidden">
            <div className=" flex flex-col rounded-20 bg-white">
              <div className="flex px-10 py-5">
                <p className="text-black-100 font-ManropeBold text-ft42">
                  Identity
                </p>
              </div>
              <hr className="text-grey-50" />
              <div className="flex justify-between py-6 px-10">
                <p className="text-black-150 font-ManropeBold text-ft3">
                  Username
                </p>
                <p className="text-black-100 opacity-60 font-ManropeRegular text-ft3">
                  {requestDetails?.userName}
                </p>
              </div>
              <hr className="text-grey-50" />
              <div className="flex justify-between py-6 px-10">
                <p className="text-black-150 font-ManropeBold text-ft3">
                  Fullname
                </p>
                <p className="text-black-100 opacity-60 font-ManropeRegular text-ft3">
                  {requestDetails?.fullName}
                </p>
              </div>
              <hr className="text-grey-50" />
              {requestDetails?.documents?.length > 0 && (
                <div className="flex justify-between py-5 px-10 items-center">
                  <p className="text-black-150 font-ManropeBold text-ft3">
                    Documents
                  </p>
                  <div className="flex gap-3.75 pl-5 flex-wrap">
                    {requestDetails?.documents?.map((item) => {
                      return (
                        <div
                          key={item?._id}
                          className={` w-30 h-30 rounded-20 cursor-pointer`}
                          onClick={() => {
                            setToggleImagePopUp(!toggleImagePopUp);
                            setImages(requestDetails?.documents);
                          }}
                        >
                          {item?.type === ".pdf" ||
                          item?.type === ".docx" ||
                          item?.type === ".doc" ? (
                            <div
                              className={`w-30 h-30 flex-col rounded-20 justify-end items-center gap-2 flex cursor-pointer`}
                            >
                              <img
                                src={FileImage}
                                alt=""
                                className="w-10 h-10"
                              />
                              <span className="text-ft1 text-center text-black-75 font-ManropeMedium">
                                preview file
                              </span>
                            </div>
                          ) : (
                            <img
                              src={item?.url}
                              alt=""
                              className="w-30 h-30 rounded-20"
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className=" flex flex-col rounded-20 bg-white">
              <div className="flex px-10 py-5">
                <p className="text-black-100 font-ManropeBold text-ft42">
                  Notability
                </p>
              </div>
              <hr className="text-grey-50" />
              <div className="flex justify-between py-6 px-10">
                <p className="text-black-150 font-ManropeBold text-ft3">
                  Categories for contributing
                </p>
                <div className="flex gap-3.75 flex-wrap pl-10">
                  {requestDetails?.channels?.map((channel, index) => (
                    <div
                      key={index}
                      className={`p-2 rounded-20 ${
                        index % 2 === 0 ? "bg-blue-100" : "bg-purple-50"
                      }`}
                    >
                      {channel}
                    </div>
                  ))}
                </div>
              </div>
              <hr className="text-grey-50" />
              <div className="flex justify-between py-6 px-10">
                <p className="text-black-150 font-ManropeBold text-ft3">
                  Location
                </p>
                <p className="text-black-100 opacity-60 font-ManropeRegular text-ft3">
                  {requestDetails?.location?.name}
                </p>
              </div>
              <hr className="text-grey-50" />
              <div className="flex justify-between py-5 px-10 items-center">
                <p className="text-black-150 font-ManropeBold text-ft3">
                  Audience description
                </p>
                <div className="text-black-100 opacity-60 font-ManropeRegular text-ft3 w-92.25 flex justify-end">
                  {requestDetails?.audienceDescription}
                </div>
              </div>
            </div>
            <div className=" flex flex-col mb-5 rounded-20 bg-white">
              <div className="text-black-100 font-ManropeBold text-ft42 px-10 py-5">
                Identity
              </div>
              <hr className="text-grey-50" />
              <div className="flex gap-6 flex-wrap  px-10 py-7.5">
                {requestDetails?.references?.map((item) => (
                  <div className="flex gap-2.5 py-2">
                    <img src={checkLinkType(item?.name)} alt="facebook" />
                    <p className="text-black-100 opacity-60 font-ManropeRegular text-ft3">
                      <a
                        href={item?.link || ""}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {requestDetails?.fullName}
                      </a>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {userPermissions?.indexOf("request:write:bluecheck") > -1 && (
            <footer className="flex justify-end items-end pr-7.5 py-5 gap-5 rounded-20 bg-white">
              <button
                onClick={() => setRejectPopUp(!isRejectPopUp)}
                className="flex justify-center items-center rounded-full border border-solid border-black-20 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
              >
                Reject
              </button>
              <button
                onClick={handleApproveBlueTick}
                className="flex justify-center items-center rounded-full border border-solid border-transparent bg-orange-50 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
              >
                Confirm
              </button>
            </footer>
          )}
        </div>
        <div className="flex flex-col gap-5 relative w-25per">
          <DetailsProfile
            name={requestDetails?.fullName}
            username={requestDetails?.userName}
            setUserExpanded={setUserExpanded}
            isUserExpanded={isUserExpanded}
            renderPopUp={renderProfileDots(requestDetails?.requester?.status)}
            profile={requestDetails?.requester?.profilePhoto}
            profileType={requestDetails?.requester?.isBlueTickVerified}
            permissionActive={"user:write:activate"}
            permissionSuspend={"user:write:suspend"}
            status={requestDetails?.requester?.status}
          />
          <div className="flex flex-col items-start w-full gap-5 rounded-20 bg-white p-5">
            <div className="flex w-full gap-2 justify-between">
              <div className="flex flex-col w-full">
                <p className="font-ManropeRegular text-ft2 text-grey-250">
                  Status
                </p>
                <div
                  className={`flex items-center justify-center rounded-20 w-min px-2 py-1.25 ${
                    requestDetails?.requester?.status === "ACTIVE"
                      ? "bg-green-50"
                      : "bg-red-75"
                  }`}
                >
                  <p
                    className={`font-ManropeRegular text-ft2 text-black-100 ${
                      requestDetails?.requester?.status === "SUSPENDED"
                        ? "w-15 xl:w-auto truncate"
                        : "w-auto"
                    } `}
                  >
                    {utility.capitalizeFirstLetter(
                      requestDetails?.requester?.status
                    )}
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full">
                <p className="font-ManropeRegular text-ft2 text-grey-250">
                  KYC
                </p>
                <div
                  className={`flex items-center  justify-center px-2 py-1.5 rounded-20 w-max ${getBackgroundColor(
                    requestDetails?.requester?.isKYCVerified
                  )} `}
                >
                  <p className="font-ManropeRegular text-ft2 text-black-100">
                    {requestDetails?.requester?.isKYCVerified === "NOT_STARTED"
                      ? "Not verified"
                      : requestDetails?.requester?.isKYCVerified}
                  </p>
                </div>
              </div>
            </div>
            {requestDetails?.requester?.status === "SUSPENDED" && (
              <div className="bg-red-75 rounded-xl p-5 w-full">
                <p className="flex flex-col gap-1 text-black-50 font-ManropeMedium text-ft1">
                  <span>{getSuspensionText(requestDetails)}</span>
                  <div className="flex gap-1.25 flex-wrap">
                    {" "}
                    Suspended by:
                    <b>
                      <div className="break-all">
                        {" "}
                        {requestDetails?.requester?.suspension?.suspenderName ||
                          "-"}{" "}
                      </div>
                    </b>
                  </div>

                  <span className="break-all">
                    {requestDetails?.requester?.suspension?.reason || "-"}
                  </span>
                </p>
              </div>
            )}
            <div className="flex w-full gap-2 justify-between">
              <div className="flex flex-col w-full">
                <p className="font-ManropeRegular text-ft2 text-grey-250">
                  Violations
                </p>
                <p className="font-ManropeBold text-ft2 text-black-100">
                  {requestDetails?.requester?.reports || 0} approved
                </p>
              </div>
              <div className="flex flex-col w-full">
                <p className="font-ManropeRegular text-ft2 text-grey-250">
                  Badge
                </p>
                <p className="font-ManropeBold text-ft2 text-black-100">
                  <p className="font-ManropeBold text-ft2 text-black-100">
                    {requestDetails?.requester?.isBlueTickVerified ===
                    "COMPLETED"
                      ? "Blue"
                      : "-"}
                  </p>
                </p>
              </div>
            </div>
            <div className="flex w-full gap-2 justify-between">
              <div className="flex flex-col w-full">
                <p className="font-ManropeRegular text-ft2 text-grey-250">
                  Followers
                </p>
                <p className="font-ManropeBold text-ft2 text-black-100">
                  {requestDetails?.requester?.followers || 0}
                </p>
              </div>
              <div className="flex flex-col w-full">
                <p className="font-ManropeRegular text-ft2 text-grey-250">
                  Following
                </p>
                <p className="font-ManropeBold text-ft2 text-black-100">
                  {requestDetails?.requester?.following || 0}
                </p>
              </div>
            </div>
            <div className="flex w-full gap-2 justify-between">
              <div className="flex flex-col w-full">
                <p className="font-ManropeRegular text-ft2 text-grey-250">
                  Circles
                </p>
                <p className="font-ManropeBold text-ft2 text-black-100">
                  {requestDetails?.requester?.circles || 0}
                </p>
              </div>
            </div>
          </div>
          <DetailsBalance
            price={balance || 0}
            fontSize={"text-ft6"}
            rewardAmount={requestDetails?.requester?.rewardAmount || 0}
          />{" "}
          <DetailsContact
            details={requestDetails?.requester}
            getDetails={getBlueTickRequestDetails}
            keyName="violation"
          />
          <DetailsGeneralInfo
            isDropDownOpen={isDropDownOpen}
            setIsDropDownOpen={setIsDropDownOpen}
            setGeneralExpanded={setGeneralExpanded}
            isGeneralExpanded={isGeneralExpanded}
            details={requestDetails?.requester}
            stateId={requestDetails?.requester?._id}
            getEntityDetailsData={getBlueTickRequestDetails}
            keyName="violation"
          />
        </div>
      </div>
      {toggleImagePopUp && (
        <ImagePopUp
          toggleImagePopUp={toggleImagePopUp}
          setToggleImagePopUp={setToggleImagePopUp}
          images={image}
        />
      )}
      {isRejectPopUp && (
        <RejectRequestPopUp
          isRejectPopUp={isRejectPopUp}
          setRejectPopUp={setRejectPopUp}
          setReason={setReason}
          reason={reason}
          customReasonInput={customReasonInput}
          handleRejectBlueTick={handleRejectBlueTick}
          setCustomReasonInput={setCustomReasonInput}
        />
      )}

      {popUpVisible.popUp && popUpVisible.keyName === "suspend" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={requestDetails?.userName}
          id={requestDetails?.requester?._id}
          getdetails={getBlueTickRequestDetails}
        />
      )}
      {popUpVisible.popUp && popUpVisible.keyName === "edit" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={requestDetails?.userName}
          id={requestDetails?.requester?._id}
          keyName={popUpVisible.keyName}
          getdetails={getBlueTickRequestDetails}
          endDate={requestDetails?.requester?.suspension?.period?.endDate}
          type={suspendType}
          reasonType={requestDetails?.requester?.suspension?.reason}
          name={requestDetails?.fullName}
          setActivatePopUp={setSeenActivatePopUp}
        />
      )}
      {seenActivatePopUp && (
        <WarningPopup
          handleActivateUser={() =>
            handleActivateUser(requestDetails?.requester?._id)
          }
          title={`Activate ${requestDetails?.userName} ?`}
          description={
            "User will be activated. All created content will be available for other users"
          }
          text={`${requestDetails?.fullName} activated`}
          setToggle={() => setSeenActivatePopUp(false)}
          loading={updatingUser}
        />
      )}
    </div>
  );
};

export default BlueCheck;
