import moment from "moment";
import React, { useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuBurger from "../../assets/Icons/MenuBurger.svg";
import More from "../../assets/Icons/More.svg";
import Edit from "../../assets/Icons/Edit.svg";
import Add from "../../assets/Icons/Add.svg";
import deletes from "../../assets/Icons/delete.svg";
import OutsideClickRef from "../../common/outsideClickRef";
import WarningPopUp from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import { CircularProgress } from "@mui/material";
import placeholder from "../../assets/Icons/Placeholder.svg";
import { UserService } from "../../services";
import utility from "../../utility";
import { toastType } from "../../constant/commonArray";
import CreateDeptPopup from "./createDeptPopup";
import CreatePositionPopUp from "./createPositionPopUp";
import { useSelector } from "react-redux";
import PlaceholderWnm from "../../assets/Icons/PlaceholderWnm.png";

/**
 * EmployeeList is a component for displaying and managing departments and positions in an organization.
 *
 * @component
 * @param {Object} props - React component props.
 * @param {Array} props.jobList - List of departments and their corresponding positions.
 * @param {Function} props.setJobList - Function to set the jobList state.
 * @param {boolean} props.loading - Loading state of the component.
 * @param {Function} props.getJobLists - Function to fetch job lists.
 * @returns {JSX.Element} - The JSX element representing the EmployeeList.
 */

const EmployeeList = ({
  jobList,
  setJobList,
  loading,
  getJobLists = () => {},
  searchQuery,
}) => {
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isjobOpen, setIsJobOpen] = useState(false);
  const [departmentId, setDepartmentId] = useState("");
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [addPosition, setAddPosition] = useState({
    popup: false,
    type: "",
    departmentData: "",
    positionData: "",
  });
  const [jobId, setJobId] = useState("");
  const optionRef = useRef();
  const [deleteJob, setDeleteJob] = useState({
    name: "",
    id: "",
    popup: false,
  });
  const [deleteDept, setDeleteDept] = useState({
    name: "",
    id: "",
    popup: false,
  });
  const [editDept, setEditDept] = useState({
    popup: false,
    data: "",
  });

  /**
   * Handle the drag end event and update the job list accordingly.
   *
   * @param {object} result - The result object from the drag end event
   * @return {void}
   */
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = [...jobList];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setJobList(items);

    const { data, keyName } = findPreviousDataDepartment(items, reorderedItem);
    if (data && keyName !== "first") {
      handleUpdatePosition("DEPARTMENT", result.draggableId, data.position + 1);
    } else {
      handleUpdatePosition("DEPARTMENT", result.draggableId, data.position - 1);
    }
  }

  /**
   * Finds the previous data of a department based on the dragged item.
   *
   * @param {Array} items - List of departments.
   * @param {Object} draggedItem - Dragged department item.
   * @returns {Object} - Object containing previous data and keyName.
   */

  function findPreviousDataDepartment(items, draggedItem) {
    const departmentIndex = items.findIndex(
      (department) => department.departmentId === draggedItem.departmentId
    );
    if (departmentIndex !== -1 && departmentIndex > 0) {
      const previousGroup = items[departmentIndex - 1];
      return { data: previousGroup, keyName: "default" };
    }

    return { data: items[1], keyName: "first" };
  }

  /**
   * Handles the click on a department to toggle its job positions.
   *
   * @param {string} departmentId - Id of the clicked department.
   * @returns {void}
   */
  const handleDepartmentClick = (departmentId) => {
    if (selectedDepartment === departmentId) {
      setIsJobOpen((prev) => !prev);
    } else {
      setSelectedDepartment(departmentId);
      setIsJobOpen(true);
    }
  };

  /**
   * Handles the drag and drop reordering of job positions within a department.
   *
   * @param {Object} result - DragDropContext result object.
   * @param {string} departmentId - Id of the department containing the job positions.
   * @returns {void}
   */

  function handlejobOnDragEnd(result, departmentId) {
    if (!result.destination) return;
    const updatedjobsList = jobList.map((job) => {
      if (job.departmentId === departmentId) {
        const jobs = [...job.jobs];
        const [reorderedItem] = jobs.splice(result.source.index, 1);
        jobs.splice(result.destination.index, 0, reorderedItem);
        const { data, keyName } = findPreviousDataJob(jobs, reorderedItem);

        if (data && keyName !== "first") {
          handleUpdatePosition("JOB", reorderedItem.jobId, data.position + 1);
        } else {
          handleUpdatePosition("JOB", reorderedItem.jobId, data.position - 1);
        }
        return { ...job, jobs: jobs };
      }
      return job;
    });
    setJobList(updatedjobsList);
  }

  /**
   * Finds the previous data of a job position based on the dragged item.
   *
   * @param {Array} items - List of job positions.
   * @param {Object} draggedItem - Dragged job position item.
   * @returns {Object} - Object containing previous data and keyName.
   */

  function findPreviousDataJob(items, draggedItem) {
    const jobIndex = items.findIndex((job) => job.jobId === draggedItem.jobId);

    if (jobIndex !== -1 && jobIndex > 0) {
      return { data: items[jobIndex - 1], keyName: "default" };
    }

    return { data: items[1], keyName: "first" };
  }

  /**
   * Handles the deletion of a job position.
   *
   * @param {string} id - Id of the job position to be deleted.
   * @returns {void}
   */

  const handleDeletejob = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deleteJob(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getJobLists();
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setDeleteJob(null);
      setJobId("");
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Handles the deletion of a department.
   *
   * @param {string} id - Id of the department to be deleted.
   * @returns {void}
   */
  const handleDeleteDept = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deleteDepartment(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getJobLists();
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setDeleteDept(null);
      setDepartmentId("");
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Handles the update of position (reordering) for departments and job positions.
   *
   * @param {string} target - Target type ("DEPARTMENT" or "JOB").
   * @param {string} id - Id of the target (departmentId or jobId).
   * @param {number} position - New position value.
   * @returns {void}
   */

  const handleUpdatePosition = async (target, id, position) => {
    try {
      const requestData = {
        target: target,
        targetId: id,
        position: position,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().updatePosition(requestData)
      );
      if (error || !response?.responseData) return;
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {jobList?.length > 0 ? (
        <DragDropContext
          onDragEnd={(e) => {
            handleOnDragEnd(e);
          }}
        >
          <Droppable droppableId="vertical" isDropDisabled={isjobOpen}>
            {(provided) => (
              <div
                className="flex flex-col gap-2.5"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {jobList?.map((item, index) => (
                  <Draggable
                    key={item.departmentId}
                    draggableId={item.departmentId}
                    index={index}
                    isDragDisabled={isjobOpen}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div
                          className={`flex flex-col rounded-20 bg-white py-5 gap-5`}
                        >
                          <div
                            className={`flex justify-between cursor-pointer px-5`}
                          >
                            <div
                              onClick={() =>
                                handleDepartmentClick(item.departmentId)
                              }
                              className="flex justify-between w-90per"
                            >
                              <div className="flex items-center">
                                <img
                                  {...provided.dragHandleProps}
                                  src={MenuBurger}
                                  alt="MenuBurger"
                                  className="mr-10 w-5 h-5 opacity-20"
                                />
                                <p className="text-black-100 font-ManropeBold text-ft3">
                                  {item.departmentName}
                                </p>
                              </div>
                              <div className="flex items-center">
                                <div className="flex flex-col mr-25">
                                  <p className="text-grey-250 font-ManropeMedium text-ft2">
                                    Positions
                                  </p>
                                  <p className="text-black-50 font-ManropeBold text-ft2">
                                    {item.totalJobs}
                                  </p>
                                </div>
                                <div className="flex flex-col">
                                  <p className="text-grey-250 font-ManropeMedium text-ft2">
                                    Created
                                  </p>
                                  <p className="text-black-50 font-ManropeBold text-ft2">
                                    {moment(item.addedOn).format(
                                      "DD MMM[,] YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="relative flex items-center">
                              {userPermissions?.indexOf(
                                "position:write:details"
                              ) > -1 && (
                                <img
                                  src={More}
                                  alt="More"
                                  onClick={() =>
                                    setDepartmentId(item.departmentId)
                                  }
                                  className="cursor-pointer"
                                />
                              )}
                              {departmentId === item.departmentId && (
                                <OutsideClickRef
                                  state={optionRef}
                                  setState={() => setDepartmentId(null)}
                                >
                                  <div className="flex flex-col absolute w-75 top-10 right-0 rounded-20 shadow-inner z-10 bg-white">
                                    <button
                                      onClick={() =>
                                        setAddPosition((prev) => ({
                                          ...prev,
                                          popup: !addPosition?.popup,
                                          type: "create",
                                        }))
                                      }
                                      className="flex p-5 items-center gap-3.75 w-75 rounded-t-20 hover:bg-grey-350"
                                    >
                                      <img src={Add} alt="stop" />
                                      <p className="text-ft3 font-ManropeRegular text-black-100">
                                        Add position
                                      </p>
                                    </button>
                                    <hr className="text-grey-50 w-full" />
                                    <button
                                      onClick={() =>
                                        setEditDept((prev) => ({
                                          ...prev,
                                          popup: !editDept?.popup,
                                          data: item,
                                        }))
                                      }
                                      className="flex p-5 items-center gap-3.75 w-75 hover:bg-grey-350"
                                    >
                                      <img src={Edit} alt="stop" />
                                      <p className="text-ft3 font-ManropeRegular text-black-100">
                                        Edit
                                      </p>
                                    </button>
                                    <hr className="text-grey-50 w-full" />
                                    <button
                                      onClick={() =>
                                        setDeleteDept((prev) => ({
                                          ...prev,
                                          id: item?.departmentId,
                                          name: item?.departmentName,
                                          popup: true,
                                        }))
                                      }
                                      className="flex p-5 items-center gap-3.75 w-75 rounded-b-20 hover:bg-grey-350"
                                    >
                                      <img src={deletes} alt="deletes" />

                                      <p className="text-ft3 font-ManropeRegular text-black-100">
                                        Delete
                                      </p>
                                    </button>
                                  </div>
                                </OutsideClickRef>
                              )}
                            </div>
                          </div>
                          {isjobOpen &&
                            selectedDepartment === item.departmentId && (
                              <>
                                <hr className="text-grey-50" />
                                <DragDropContext
                                  onDragEnd={(e) => {
                                    handlejobOnDragEnd(e, item.departmentId);
                                  }}
                                >
                                  <Droppable droppableId="departmentId">
                                    {(provided) => (
                                      <div
                                        className="flex flex-col gap-5"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                      >
                                        {item?.jobs?.map((job, index) => {
                                          return (
                                            <Draggable
                                              key={job?.jobId}
                                              draggableId={job?.jobId}
                                              index={index}
                                            >
                                              {(provided) => (
                                                <div
                                                  className="flex flex-col gap-5"
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                >
                                                  <div className="flex justify-between px-5">
                                                    <div className="flex justify-between w-90per">
                                                      <div className="flex items-center">
                                                        <img
                                                          {...provided.dragHandleProps}
                                                          src={MenuBurger}
                                                          alt="MenuBurger"
                                                          className="mr-10 w-5 h-5 opacity-20"
                                                        />
                                                        <div className="flex items-center justify-center mr-5 w-11.25 h-11.25">
                                                          <p className="w-5 h-5 rounded-full bg-purple-50"></p>
                                                        </div>
                                                        <p className="text-black-100 font-ManropeBold text-ft3">
                                                          {job.name}
                                                        </p>
                                                      </div>
                                                      <div className="flex flex-col">
                                                        <p className="text-grey-250 font-ManropeMedium text-ft2">
                                                          Created
                                                        </p>
                                                        <p className="text-black-50 font-ManropeBold text-ft2">
                                                          {moment(
                                                            job.addedOn
                                                          ).format(
                                                            "DD MMM[,] YYYY"
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="relative flex items-center">
                                                      {userPermissions?.indexOf(
                                                        "position:write:details"
                                                      ) > -1 && (
                                                        <img
                                                          src={More}
                                                          alt="More"
                                                          onClick={() =>
                                                            setJobId(job?.jobId)
                                                          }
                                                          className="cursor-pointer"
                                                        />
                                                      )}
                                                      {jobId === job?.jobId && (
                                                        <OutsideClickRef
                                                          state={optionRef}
                                                          setState={() =>
                                                            setJobId(null)
                                                          }
                                                        >
                                                          <div className="flex flex-col absolute w-75 top-10 right-0 rounded-20 shadow-inner z-10 bg-white">
                                                            <hr className="text-grey-50 w-full" />
                                                            <button
                                                              onClick={() =>
                                                                setAddPosition(
                                                                  (prev) => ({
                                                                    ...prev,
                                                                    popup:
                                                                      !addPosition?.popup,
                                                                    type: "edit",
                                                                    departmentData:
                                                                      item,
                                                                    positionData:
                                                                      job,
                                                                  })
                                                                )
                                                              }
                                                              className="flex p-5 items-center gap-3.75 w-75 rounded-t-20 hover:bg-grey-350"
                                                            >
                                                              <img
                                                                src={Edit}
                                                                alt="stop"
                                                              />
                                                              <p className="text-ft3 font-ManropeRegular text-black-100">
                                                                Edit
                                                              </p>
                                                            </button>
                                                            <hr className="text-grey-50 w-full" />
                                                            <button
                                                              onClick={() =>
                                                                setDeleteJob(
                                                                  (prev) => ({
                                                                    ...prev,
                                                                    id: job?.jobId,
                                                                    name: job?.name,
                                                                    popup: true,
                                                                  })
                                                                )
                                                              }
                                                              className="flex p-5 items-center gap-3.75 w-75 rounded-b-20 hover:bg-grey-350"
                                                            >
                                                              <img
                                                                src={deletes}
                                                                alt="deletes"
                                                              />

                                                              <p className="text-ft3 font-ManropeRegular text-black-100">
                                                                Delete
                                                              </p>
                                                            </button>
                                                          </div>
                                                        </OutsideClickRef>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <hr
                                                    className={`${
                                                      index ===
                                                      item?.jobs?.length - 1
                                                        ? "hidden"
                                                        : "text-grey-50"
                                                    }`}
                                                  />
                                                </div>
                                              )}
                                            </Draggable>
                                          );
                                        })}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </>
                            )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
          {searchQuery ? (
            <>
              <img src={PlaceholderWnm} alt="placeholder" className="mt-36.5" />
              <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                No results were found for your search
              </p>
              <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                Try to change your request
              </p>
            </>
          ) : (
            <img src={placeholder} alt="placeholder" className="mt-5per" />
          )}
        </div>
      )}
      {deleteDept?.popup && (
        <WarningPopUp
          description={"Are you sure? You want to delete department"}
          handleActivateUser={() => handleDeleteDept(deleteDept?.id)}
          title={`Delete “${deleteDept?.name}”?`}
          setToggle={() => setDeleteDept(null)}
        />
      )}
      {deleteJob?.popup && (
        <WarningPopUp
          description={"Are you sure? You want to delete job"}
          handleActivateUser={() => handleDeletejob(deleteJob?.id)}
          title={`Delete “${deleteJob?.name}”?`}
          setToggle={() => setDeleteJob(null)}
        />
      )}
      {addPosition?.popup && (
        <CreatePositionPopUp
          setPopup={() => setAddPosition(null)}
          getJobLists={getJobLists}
          keyName={addPosition?.type}
          departmentData={addPosition?.departmentData}
          jobData={addPosition?.positionData}
        />
      )}
      {editDept?.popup && (
        <CreateDeptPopup
          setPopup={() => setEditDept(null)}
          getJobLists={getJobLists}
          data={editDept?.data}
          keyName="edit"
        />
      )}
    </div>
  );
};

export default EmployeeList;
