import React from "react";
import { ReactComponent as Delete } from "../../../../../assets/Icons/delete.svg";
import ShowToast from "../../../../../common/showToast";
import { toastType } from "../../../../../constant/commonArray";
import { ReactComponent as AddImage } from "../../../../../assets/Icons/plus-orange.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import menu from "../../../../../assets/Icons/menu.svg";
import utility from "../../../../../utility";

/**
 * Component for managing and displaying draggable text input fields.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.state - Current state object containing options and answers.
 * @param {Function} props.setState - Function to update the state.
 * @param {Function} props.setErrors - Function to update the errors array.
 * @param {Array} props.errors - Array containing error messages for each answer.
 * @returns {JSX.Element} Rendered component.
 */
const TypeText = ({ state, setState, setErrors, errors }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...state?.options?.answers];
    const newErrors = [...errors];

    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    const [movedError] = newErrors.splice(result.source.index, 1);
    newErrors.splice(result.destination.index, 0, movedError);
    setState((prev) => ({
      ...prev,
      options: { ...prev.options, answers: newItems },
    }));
    setErrors(newErrors);
  };

  /**
   * Handles change in the text input for answers.
   * @param {Object} e - Event object representing the input change event.
   * @param {number} index - Index of the answer being edited.
   */
  const handleAnswersChange = (e, index) => {
    const inputValue = e.target.value;
    const newErrors = [...errors];
    if (inputValue.length > 150) {
      newErrors[index] = "Answer should be less than 150 characters";
    } else {
      newErrors[index] = "";
    }
    setErrors(newErrors);
    setState((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        answers: prev?.options?.answers.map((value, i) =>
          i === index ? { ...value, value: e.target.value } : value
        ),
      },
    }));
  };

  return (
    <div className="flex flex-col gap-5">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <div
              className="flex flex-col gap-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {state?.options?.answers?.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={index?.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="flex gap-5 items-center"
                    >
                      <div className="flex flex-col gap-0.5 w-full">
                        <div className="gap-3 w-full rounded-full bg-grey-50 outline-none px-6 flex justify-between items-center h-16.25 text-black-100 font-ManropeRegular text-ft3">
                          <input
                            type="text"
                            value={state?.options?.answers[index].value}
                            placeholder={`${utility.ordinalText(
                              index + 1
                            )} answer`}
                            onChange={(e) => handleAnswersChange(e, index)}
                            className="focus:outline-none bg-transparent w-95per"
                          />
                          <img
                            src={menu}
                            {...provided.dragHandleProps}
                            alt="menu"
                            className="w-3.5 h-1.5 cursor-pointer"
                          />
                        </div>
                        {errors[index] && (
                          <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                            {errors[index]}
                          </div>
                        )}
                      </div>
                      {state?.options?.answers?.length > 2 && (
                        <Delete
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              options: {
                                ...prev.options,
                                answers: prev?.options?.answers.filter(
                                  (_, i) => i !== index
                                ),
                              },
                            }));
                          }}
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div
        onClick={() => {
          if (state?.options?.answers?.length >= 6) {
            ShowToast({
              type: toastType.ERROR,
              message: "Can't add more than 6 answers",
            });
          } else {
            setState((prev) => ({
              ...prev,
              options: {
                ...prev.options,
                answers: [...prev.options.answers, { value: "" }],
              },
            }));
          }
        }}
        className="flex gap-2.5 items-center cursor-pointer pt-5"
      >
        <AddImage />
        <span className="text-orange-50 font-ManropeBold text-ft2">
          Add answer
        </span>
      </div>
    </div>
  );
};

export default TypeText;
