import React, { useState } from "react";
import checkMark from "../../../assets/Icons/checkMark.svg";

/**
 * Description component for displaying poll description and user interaction.
 * @component
 * @param {Object} props - Component props
 * @param {Array} props.polls - Array of polls
 * @param {Function} props.setPreview - Function to set preview state
 * @param {Function} props.setStartPollIndex - Function to set start poll index
 * @param {number} props.startPollIndex - Current index of the poll
 * @param {Object} props.data - Data object containing question and description
 * @returns {JSX.Element} - Rendered component
 */
const Description = ({
  polls,
  setPreview,
  setStartPollIndex,
  startPollIndex,
  data,
}) => {
  const [selectedData, setSelectedData] = useState("");

  return (
    <div className="bg-white  relative flex flex-col h-full w-106.25 z-50 p-5 ">
      <div className="bg-black-100 overflow-y-auto overflow-x-hidden scrollbar-hide w-full h-full">
        <div className={`p-5 flex flex-col justify-between h-full`}>
          <div className="flex flex-col h-full gap-5 justify-center items-center">
            <div className="flex flex-col gap-3.75 w-full h-full">
              <div className="px-2 py-1 bg-black-50 rounded-full mt-3 w-max text-ft0 font-ManropeMedium text-white">
                <span>
                  Question {startPollIndex + 1} of {polls?.length}
                </span>
              </div>
              <div className="flex justify-between flex-col h-full">
                <div className="pt-1 gap-5 flex flex-col">
                  <span className=" text-ft42 font-ManropeBold text-white ">
                    {" "}
                    {data?.question}
                  </span>{" "}
                  <span className="text-ft2 font-ManropeRegular text-white">
                    {data?.description}
                  </span>
                  <div className="flex gap-3">
                    <div className="flex h-7.5 items-center gap-3.75 self-stretch">
                      <input
                        type="checkbox"
                        className={`relative appearance-none w-4 h-4 rounded-md cursor-pointer ${
                          selectedData.includes("READED")
                            ? "bg-orange-50 border-none"
                            : "bg-transparent border-1 border-white border-opacity-20"
                        }`}
                        onChange={() => {
                          if (selectedData.includes("READED")) {
                            setSelectedData("");
                          } else {
                            setSelectedData("READED");
                          }
                        }}
                        checked={selectedData.includes("READED")}
                      />
                      {selectedData.includes("READED") ? (
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            if (selectedData.includes("READED")) {
                              setSelectedData("");
                            } else {
                              setSelectedData("READED");
                            }
                          }}
                          className="absolute cursor-pointer w-4 h-4"
                          src={checkMark}
                          alt="checkMark"
                        ></img>
                      ) : (
                        ""
                      )}
                    </div>
                    <span className="text-ft2 font-ManropeRegular text-white">
                      I have studied the information and I am ready to continue
                      the survey.
                    </span>
                  </div>
                </div>
                <div className="w-full h-16.25 rounded-full bg-orange-50">
                  <button
                    onClick={() => {
                      if (polls?.length === startPollIndex + 1) {
                        setPreview(false);
                      } else if (polls?.length > startPollIndex) {
                        setStartPollIndex(startPollIndex + 1);
                      }
                    }}
                    className="text-ft2 w-full h-full font-ManropeBold text-black-50 flex justify-center items-center"
                  >
                    {polls?.length === startPollIndex + 1 ? "Done" : "Continue"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
