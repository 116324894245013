import React from "react";
import Hand from "../assets/Icons/hand.png";

/**
 * DetailsBalance component displays details about a balance, including heading, price, and an optional icon or "Top Up" button.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {string} props.heading - The heading for the balance details.
 * @param {string} props.price - The price associated with the balance.
 * @param {boolean} props.icon - Indicates whether to display an icon.
 * @param {string} props.fontSize - The font size for the price text. Defaults to "text-ft5".
 * @param {string} props.keyname - The keyname for conditional rendering (default or balance).
 * @returns {JSX.Element} DetailsBalance component.
 */

const DetailsBalance = ({ price, fontSize = "text-ft6", rewardAmount }) => {
  return (
    <div className="bg-blue-100 flex flex-none rounded-20 w-full h-45 relative">
      <div className=" right-0 z-10 absolute">
        <img src={Hand} alt="" className="rounded-b-20 h-45" />
      </div>
      <div className="flex flex-col h-45 absolute px-5 z-30 gap-2 w-full justify-center">
        <div className="flex flex-col">
          <span className="font-ManropeRegular text-black-100 text-ft2">
            Tokens
          </span>
          <span className={`font-bold text-black-100 text-ft5 break-all`}>
            {price} PPT
          </span>
        </div>
        <div className="flex flex-col">
          <span className="font-ManropeRegular text-black-100 text-ft2">
            Unclaimed amount
          </span>
          <span
            className={`font-bold text-black-100 break-words text-ft5 break-all`}
          >
            {rewardAmount} USD
          </span>
        </div>

        {/* <div className="flex justify-end mt-5">
          {icon ? (
            <img src={Hand} alt="" className="rounded-b-20"></img>
          ) : (
            <button
              className={`bg-white ${
                keyname === "balance"
                  ? "w-11.25 h-11.25"
                  : " w-20 xl:w-107px h-12.5"
              }  rounded-full text-ft2 font-ManropeBold text-black-100 flex justify-center items-center`}
            >
              {keyname === "balance" ? (
                <img src={ArrowRight} alt="arrow" />
              ) : (
                "Top Up"
              )}
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default DetailsBalance;
