import moment from "moment";
import React, { useRef } from "react";
import DetailsProfile from "./detailsProfile";
import OutsideClickRef from "./outsideClickRef";
import chevronRight from "../assets/Icons/Right.svg";
import { Languages } from "../constant/commonArray";
import hand45 from "../assets/Icons/hand45.png";
import deletes from "../assets/Icons/delete.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import stop from "../assets/Icons/Stop.svg";
import more from "../assets/Icons/More.svg";
import utility from "../utility";
import { useSelector } from "react-redux";

/**
 * DetailsAds component displays detailed information about ads or promotions.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Object} props.details - Detailed information about the ad or promotion.
 * @param {Function} props.handleMoreClick - Function to handle the click on the more options.
 * @param {Object} props.selectedItem - The currently selected item.
 * @param {Function} props.setSelectedItem - Function to set the selected item.
 * @param {Function} props.handleDuplicate - Function to handle the duplicate action.
 * @param {Function} props.setPreviewPopUp - Function to set the preview popup.
 * @param {Function} props.setUserExpanded - Function to set the user expansion state.
 * @param {boolean} props.isUserExpanded - Indicates whether the user is expanded.
 * @param {Function} props.setDeletePopUp - Function to set the delete popup.
 * @param {Function} props.renderProfileDots - Function to render profile dots.
 * @param {string} props.id - The unique identifier for the ad or promotion.
 * @param {Object} props.error - Error information.
 * @param {Function} props.setStopPopUp - Function to set the stop popup.
 * @param {number} props.userCount - The count of potential respondents.
 * @param {string} props.keyName - The key name for conditional rendering (ads or promotions).
 * @returns {JSX.Element} DetailsAds component.
 */

const DetailsAds = ({
  details,
  handleMoreClick = () => {},
  selectedItem,
  setSelectedItem = () => {},
  handleDuplicate = () => {},
  setPreviewPopUp = () => {},
  setUserExpanded = () => {},
  isUserExpanded,
  setDeletePopUp,
  renderProfileDots,
  id,
  error,
  setStopPopUp,
  userCount,
  keyName = "ads",
  permissionDuplicate,
  permissionStop,
  permissionDelete,
}) => {
  const optionsRef = useRef(null);
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  return (
    <div className="flex gap-5 w-full">
      <div className="flex flex-col w-75per gap-5">
        <div className="flex items-center w-full p-10 rounded-20 bg-white justify-between relative">
          <p className="text-black-150 font-ManropeBold text-ft5">
            {details?.name}
          </p>
          {(userPermissions?.indexOf(permissionDuplicate) > -1 ||
            userPermissions?.indexOf(permissionStop) > -1 ||
            userPermissions?.indexOf(permissionDelete) > -1) && (
            <img
              onClick={() => handleMoreClick(details)}
              className="cursor-pointer"
              src={more}
              alt="more"
            />
          )}
          {selectedItem?.popUp && selectedItem?.status === "ACTIVE" && (
            <div className="flex flex-col absolute w-75 top-18 right-5 rounded-20 shadow-inner z-10 bg-white">
              <OutsideClickRef
                state={optionsRef}
                setState={() => setSelectedItem(null)}
              >
                <div>
                  {userPermissions?.indexOf(permissionDuplicate) > -1 && (
                    <button
                      onClick={() => {
                        if (keyName === "ads") {
                          history.push(
                            `/advertising/account-create?duplicate=${id}`
                          );
                        } else {
                          handleDuplicate(details?.rewardFormat, id);
                        }
                      }}
                      className="flex p-5 items-center gap-3.75 w-75 rounded-t-20 hover:bg-grey-350"
                    >
                      <img src={stop} alt="stop" />
                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Create duplicate
                      </p>
                    </button>
                  )}
                  <hr className="text-grey-50 w-full" />
                  {userPermissions?.indexOf(permissionStop) > -1 && (
                    <button
                      onClick={() => {
                        setStopPopUp(true);
                      }}
                      className="flex p-5 items-center gap-3.75 w-full rounded-b-20 hover:bg-grey-350"
                    >
                      <img src={error} alt="error" />

                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Stop
                      </p>
                    </button>
                  )}
                </div>
              </OutsideClickRef>
            </div>
          )}
          {selectedItem?.popUp && selectedItem?.status === "PENDING" && (
            <div className="flex flex-col absolute w-75 top-18 right-5 rounded-20 shadow-inner z-10 bg-white">
              <OutsideClickRef
                state={optionsRef}
                setState={() => setSelectedItem(null)}
              >
                <div>
                  {userPermissions?.indexOf(permissionDuplicate) > -1 && (
                    <button
                      onClick={() => {
                        if (keyName === "ads") {
                          history.push(
                            `/advertising/account-create?duplicate=${id}`
                          );
                        } else {
                          handleDuplicate(details?.rewardFormat, id);
                        }
                      }}
                      className="flex p-5 items-center gap-3.75 w-75 rounded-t-20 hover:bg-grey-350"
                    >
                      <img src={stop} alt="stop" />
                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Create duplicate
                      </p>
                    </button>
                  )}
                  <hr className="text-grey-50 w-full" />
                  {userPermissions?.indexOf(permissionDelete) > -1 && (
                    <button
                      onClick={() => {
                        setDeletePopUp(true);
                      }}
                      className="flex p-5 items-center gap-3.75 w-75 rounded-b-20 hover:bg-grey-350"
                    >
                      <img src={deletes} alt="deletes" />

                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Delete
                      </p>
                    </button>
                  )}
                </div>
              </OutsideClickRef>
            </div>
          )}
          {selectedItem?.popUp && selectedItem?.status === "ENDED" && (
            <div className="flex flex-col absolute w-75 top-18 right-5 rounded-20 shadow-inner z-10 bg-white">
              <OutsideClickRef
                state={optionsRef}
                setState={() => setSelectedItem(null)}
              >
                {userPermissions?.indexOf(permissionDuplicate) > -1 && (
                  <div
                    onClick={() => {
                      if (keyName === "ads") {
                        history.push(
                          `/advertising/account-create?duplicate=${id}`
                        );
                      } else {
                        handleDuplicate(details?.rewardFormat, id);
                      }
                    }}
                    className="w-full cursor-pointer"
                  >
                    <button className="flex p-5 items-center gap-3.75">
                      <img src={stop} alt="stop" />
                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Create duplicate
                      </p>
                    </button>
                  </div>
                )}
              </OutsideClickRef>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full rounded-20 bg-white">
          <p className="text-black-100 font-ManropeBold text-ft42 px-10 py-5">
            General
          </p>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">
              Name of {keyName === "ads" ? "Ad" : "promotion"}
            </p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 text-black-600">
                {details?.name}
              </p>
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          {keyName === "ads" && (
            <div className="flex justify-between px-10 py-6">
              <p className="text-black-100 font-ManropeBold text-ft3">Link</p>
              <div className="flex gap-7.5">
                <p className="font-ManropeRegular text-ft3 text-black-600">
                  {details?.link || "-"}
                </p>
                <img src={chevronRight} alt="chevronRight" />
              </div>
            </div>
          )}
          {keyName !== "ads" && (
            <div className="flex justify-between px-10 py-6">
              <p className="text-black-100 font-ManropeBold text-ft3">
                Amount, Dollars
              </p>
              <div className="flex gap-7.5">
                <p className="font-ManropeRegular text-ft3 text-black-600">
                  {details?.amount || "-"}
                </p>
                <img src={chevronRight} alt="chevronRight" />
              </div>
            </div>
          )}
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Start</p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 text-black-600">
                {moment(details?.startDate || "-").format(
                  "D MMM[,] YYYY, h:mm"
                )}
              </p>
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Until</p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 text-black-600">
                {moment(details?.endDate || "-").format("D MMM[,] YYYY, h:mm")}
              </p>
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          {keyName === "ads" && (
            <div className="flex justify-between px-10 py-6">
              <p className="text-black-100 font-ManropeBold text-ft3">
                Budget, Dollars
              </p>
              <div className="flex gap-7.5">
                <p className="font-ManropeRegular text-ft3 text-black-600">
                  {details?.budget || "-"}
                </p>
                <img src={chevronRight} alt="chevronRight" />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full rounded-20 bg-white">
          <p className="text-black-100 font-ManropeBold text-ft42 px-10 py-5">
            Content
          </p>
          <hr className="text-grey-50" />
          <div className="flex items-center justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Media</p>
            <div className="flex gap-7.5">
              {details?.media?.map((item) => (
                <div className="flex rounded-20 w-30 h-30">
                  <img
                    src={item?.url}
                    alt=""
                    className="flex rounded-20 w-30 h-30"
                  />
                </div>
              ))}
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex gap-2.5 px-10 py-6 overflow-x-scroll scrollbar-hide">
            {Languages.map((item) => (
              <div
                className={`flex py-0.75 px-2 rounded-20 items-center ${
                  item.name === utility.capitalizeFirstLetter(details?.language)
                    ? "bg-orange-50  font-ManropeBold"
                    : "bg-grey-50 font-ManropeRegular"
                }`}
              >
                <p className="text-ft2 text-black-100">{item.name}</p>
              </div>
            ))}
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6 gap-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Heading</p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 text-black-600">
                {details?.heading}
              </p>
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6 gap-6">
            <p className="text-black-100 font-ManropeBold text-ft3">
              Description
            </p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 text-black-600">
                {details?.description}
              </p>
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          {keyName !== "ads" && (
            <>
              <hr className="text-grey-50" />
              <div className="flex justify-between px-10 py-6">
                <p className="text-black-100 font-ManropeBold text-ft3">
                  Text in button
                </p>
                <div className="flex gap-7.5">
                  <p className="font-ManropeRegular text-ft3 text-black-600">
                    {details?.buttonText}
                  </p>
                  <img src={chevronRight} alt="chevronRight" />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col w-full rounded-20 bg-white">
          <p className="text-black-100 font-ManropeBold text-ft42 px-10 py-5">
            Audience reach
          </p>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Audience</p>
            <div className="flex gap-7.5">
              {details?.audienceReach?.audience?.length > 0 ? (
                details?.audienceReach?.audience?.map((item) => (
                  <p className="font-ManropeRegular text-ft3 text-black-600">
                    {item || "-"}
                  </p>
                ))
              ) : (
                <p className="font-ManropeRegular text-ft3 text-black-600">
                  {"-"}
                </p>
              )}
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">
              Only verified users
            </p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 text-black-600">
                {details?.verifiedUsers ? "Yes" : "No"}
              </p>
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Location</p>
            <div className="flex gap-7.5 pl-10">
              {details?.audienceReach?.location?.length > 0 ? (
                details?.audienceReach?.location?.map((item) => (
                  <p className="font-ManropeRegular text-ft3 text-black-600">
                    {item || "-"}
                  </p>
                ))
              ) : (
                <p className="font-ManropeRegular text-ft3 text-black-600">
                  {"-"}
                </p>
              )}
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Gender</p>
            <div className="flex gap-7.5">
              {details?.audienceReach?.gender?.length > 0 ? (
                details?.audienceReach?.gender?.map((item) => (
                  <p className="font-ManropeRegular text-ft3 text-black-600">
                    {item || "-"}
                  </p>
                ))
              ) : (
                <p className="font-ManropeRegular text-ft3 text-black-600">
                  {"-"}
                </p>
              )}
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Age</p>
            <div className="flex gap-7.5">
              {details?.audienceReach?.age?.length > 0 ? (
                details?.audienceReach?.age?.map((item) => (
                  <p className="font-ManropeRegular text-ft3 text-black-600">
                    {item || "-"}
                  </p>
                ))
              ) : (
                <p className="font-ManropeRegular text-ft3 text-black-600">
                  {"-"}
                </p>
              )}
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6 gap-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Race</p>
            <div className="flex gap-7.5">
              {details?.audienceReach?.race?.length > 0 ? (
                details?.audienceReach?.race?.map((item) => (
                  <p className="font-ManropeRegular text-ft3 text-black-600">
                    {item || "-"}
                  </p>
                ))
              ) : (
                <p className="font-ManropeRegular text-ft3 text-black-600">
                  {"-"}
                </p>
              )}
              <img src={chevronRight} alt="chevronRight" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-25per gap-5">
        {keyName === "ads" && (
          <DetailsProfile
            name={details?.account?.fullName || "-"}
            username={details?.account?.userName || "-"}
            setUserExpanded={setUserExpanded}
            isUserExpanded={isUserExpanded}
            renderPopUp={renderProfileDots(details?.account?.status)}
            profile={details?.account?.profilePhoto}
            profileType={details?.account?.entityType}
            permissionActive={"business:write:activate"}
            permissionSuspend={"business:write:suspend"}
            status={details?.account?.status}
          />
        )}
        <div className="flex flex-col bg-white rounded-20 p-5 gap-3.75">
          <div className="flex justify-between">
            <p className="text-ft2 font-ManropeRegular text-black-600">
              Status
            </p>
            <p
              className={`rounded-full py-0.75 px-2 ${
                details?.status === "PENDING"
                  ? "bg-blue-100"
                  : details?.status === "ENDED"
                  ? "bg-red-75"
                  : "bg-green-50"
              } `}
            >
              {utility.capitalizeFirstLetter(details?.status)}
            </p>
          </div>
          <div className="flex justify-between gap-1">
            <p className="text-ft2 font-ManropeRegular text-black-600">
              Created
            </p>
            <p className="text-black-100 font-ManropeBold text-ft2 whitespace-nowrap">
              {moment(details?.addedOn || "-").format("D MMM[,] YYYY, h:mm")}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-ft2 font-ManropeRegular text-black-600">
              creator
            </p>
            <p className="text-black-100 font-ManropeBold text-ft2">
              {details?.creatorName}
            </p>
          </div>
        </div>
        <div className="flex w-full rounded-20 px-5 pt-5 bg-white">
          <div className="flex flex-col justify-between pb-5 gap-5">
            <p className="rounded-20 bg-yellow-50 py-0.75 px-2 text-black-100 font-ManropeMedium text-ft0 whitespace-nowrap">
              Amount spent
            </p>
            <p className="text-black-100 font-ManropeBold text-ft52">0</p>
          </div>
          <div className="ml-auto mt-auto">
            <img src={hand45} alt="hand45" />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col rounded-20 bg-white p-5 gap-5 w-1/2">
            <p
              className="text-black-100 font-ManropeMedium text-ft0 rounded-20 py-0.75 px-2 bg-purple-50"
              style={{ width: "max-content" }}
            >
              Link clicks
            </p>
            <p className="text-black-100 text-ft52 font-ManropeBold">
              {details?.linkClicks || 0}
            </p>
          </div>
          <div className="flex flex-col rounded-20 bg-white px-3 py-5 xl:px-5 gap-5 w-1/2">
            <p
              className="text-black-100 font-ManropeMedium text-ft0 rounded-20 py-0.75 px-1 xl:px-2 bg-purple-50"
              style={{ width: "max-content" }}
            >
              User Coverage
            </p>
            <p className="text-black-100 text-ft52 font-ManropeBold">0</p>
          </div>
        </div>
        <div className="flex flex-col rounded-20 bg-purple-100 pt-5 pb-3.75 gap-2.5 px-5">
          <p className="text-black-100 font-ManropeBold text-ft2">
            Potential number of respondents:
          </p>
          <p className="text-black-100 font-ManropeBold text-ft7 py-2.5">
            {utility.formatNumber(userCount) || 0}
          </p>
        </div>
        <p
          onClick={() => setPreviewPopUp(true)}
          className="flex justify-center items-center text-black-100 font-ManropeBold text-ft2 cursor-pointer"
        >
          See preview
        </p>
      </div>
    </div>
  );
};

export default DetailsAds;
