import React, { useRef, useEffect } from "react";

/**
 * AudioPreview component for displaying audio file preview.
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.fileName - Name of the audio file.
 * @param {File | Object} props.item - Audio file object or URL.
 * @param {string} [props.bgColor="bg-black-50"] - Background color class name.
 * @returns {JSX.Element} AudioPreview component.
 */

let currentlyPlayingAudio = null;

const AudioPreview = ({ fileName, item, bgColor = "bg-black-50" }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef.current;
    const handlePlay = () => {
      if (currentlyPlayingAudio && currentlyPlayingAudio !== audioElement) {
        currentlyPlayingAudio.pause();
        currentlyPlayingAudio.currentTime = 0;
      }

      currentlyPlayingAudio = audioElement;
    };

    audioElement.addEventListener("play", handlePlay);

    return () => {
      audioElement.removeEventListener("play", handlePlay);
    };
  }, []);
  return (
    <div
      className={`flex flex-col gap-1 p-5 w-full rounded-30 ${bgColor} cursor-pointer`}
    >
      <span
        className={`${
          bgColor !== "bg-black-50" ? "text-black-100" : "text-white"
        }  font-ManropeBold text-ft3`}
      >
        {fileName}
      </span>

      <div className="w-full">
        <audio controls className="w-full" ref={audioRef}>
          <source
            className="w-full"
            src={item instanceof File ? URL.createObjectURL(item) : item.url}
          />
        </audio>
      </div>
    </div>
  );
};

export default AudioPreview;
