import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";
import utility from "../../../utility";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { CircularProgress } from "@mui/material";

/**
 * PreviewPopup component for displaying and trimming media files.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setOpenMedia - Function to control opening/closing of the media popup.
 * @param {Array} props.files - Array of media files to preview or trim.
 * @param {Function} props.setFiles - Function to update the array of media files.
 * @param {number} props.idx - Index of the selected file.
 * @param {Function} props.handleAddMedia - Function to handle adding media files.
 * @param {boolean} props.isScriptLoaded - Boolean indicating if FFmpeg script is loaded.
 * @param {Object} props.ffmpeg - FFmpeg instance for video trimming.
 * @param {Object} props.state - State object containing component state.
 * @returns {JSX.Element} PreviewPopup component JSX.
 */
const PreviewPopup = ({
  setOpenMedia,
  files,
  setFiles,
  idx,
  handleAddMedia,
  isScriptLoaded,
  ffmpeg,
  state,
}) => {
  const [name, setName] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoFileValue, setVideoFileValue] = useState("");
  const [loading, setLoading] = useState(false);
  const videoRef = useRef();
  let initialSliderValue = 0;

  /**
   * Renders the content based on the type of media file.
   * @returns {JSX.Element} Media content JSX based on file type.
   */
  const renderContent = () => {
    if (files.length > 0) {
      const fileType = utility.getFileType(files[0].name);
      if (fileType === "image") {
        return (
          <div className="px-7.5 pt-5 flex justify-center ">
            <img
              src={URL.createObjectURL(files[0])}
              alt="Preview"
              className="w-full h-full px-7.5"
            />
          </div>
        );
      } else if (fileType === "video") {
        return (
          <div className="px-7.5 flex-col pt-5 flex justify-center h-90per">
            <video
              src={videoSrc}
              ref={videoRef}
              controls
              controlsList="nodownload"
              className="max-h-85"
              onTimeUpdate={handlePauseVideo}
            >
              <source src={videoSrc} type={videoFileValue.type} />
            </video>
            <div className="mt-2">
              <Nouislider
                behaviour="tap-drag"
                step={1}
                margin={3}
                limit={30}
                range={{ min: 0, max: videoDuration || 30 }}
                start={[0, videoDuration || 30]}
                connect
                onUpdate={updateOnSliderChange}
              />
            </div>
          </div>
        );
      } else if (fileType === "audio") {
        return (
          <div className="px-7.5 pt-5 flex justify-center h-70per">
            <div className="flex flex-col gap-3 w-full">
              <div className="w-full">
                <input
                  type="text"
                  placeholder="File name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  className="w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
                />
              </div>
              <audio controls className="w-full">
                <source src={URL.createObjectURL(files[0])} />
              </audio>
            </div>
          </div>
        );
      }
    }
  };

  const handleClose = () => {
    if (!loading) {
      setOpenMedia(null);
      setFiles([]);
      setName("");
    }
  };

  useEffect(() => {
    if (files && files.length > 0) {
      const fileName = files[0].name;
      const updatedName = fileName.endsWith(".mp3")
        ? fileName.replace(/\.mp3$/, "")
        : "";
      setName(updatedName);
    } else {
      setName("");
    }
  }, [files]);

  useEffect(() => {
    if (files.length > 0) {
      const fileType = utility.getFileType(files[0].name);
      if (fileType === "video") {
        const file = files[0];
        const blobURL = URL.createObjectURL(file);
        setVideoFileValue(file);
        setVideoSrc(blobURL);
      }
    }
  }, [files]);

  /**
   * Converts seconds to HH:MM:SS format.
   * @param {number} val - Time value in seconds.
   * @returns {string} Formatted time string in HH:MM:SS format.
   */

  const convertToHHMMSS = (val) => {
    const secNum = parseInt(val, 10);
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    let time;
    // eslint-disable-next-line
    if (hours == "00") {
      time = minutes + ":" + seconds;
    } else {
      time = hours + ":" + minutes + ":" + seconds;
    }
    return time;
  };

  useEffect(() => {
    if (videoRef && videoRef.current) {
      const currentVideo = videoRef.current;
      currentVideo.onloadedmetadata = () => {
        setVideoDuration(currentVideo.duration);
        setEndTime(currentVideo.duration);
      };
    }
  }, [videoSrc]);

  /**
   * Updates video time on slider change.
   * @param {Array} values - Slider values.
   * @param {number} handle - Slider handle index.
   */
  const updateOnSliderChange = (values, handle) => {
    let readValue;
    if (handle) {
      readValue = values[handle] | 0;
      if (endTime !== readValue) {
        setEndTime(readValue);
      }
    } else {
      readValue = values[handle] | 0;
      if (initialSliderValue !== readValue) {
        initialSliderValue = readValue;
        if (videoRef && videoRef.current) {
          videoRef.current.currentTime = readValue;
          setStartTime(readValue);
        }
      }
    }
  };

  /**
   * Pauses video when current time reaches end time.
   * @param {Event} e - Video time update event.
   */

  const handlePauseVideo = (e) => {
    const currentTime = Math.floor(e.currentTarget.currentTime);

    if (currentTime === endTime) {
      e.currentTarget.pause();
    }
  };

  const handleTrim = async () => {
    if (isScriptLoaded) {
      setLoading(true);
      const { name, type } = videoFileValue;
      ffmpeg.FS(
        "writeFile",
        name,
        await window.FFmpeg.fetchFile(videoFileValue)
      );
      const videoFileType = type.split("/")[1];

      await ffmpeg.run(
        "-i",
        name,
        "-ss",
        `${convertToHHMMSS(startTime)}`,
        "-to",
        `${convertToHHMMSS(endTime)}`,
        "-acodec",
        "copy",
        "-vcodec",
        "copy",
        `out.${videoFileType}`
      );

      const data = ffmpeg.FS("readFile", `out.${videoFileType}`);
      const trimmedFile = new File(
        [data.buffer],
        `${name.slice(0, -4)}_trimmed.${videoFileType}`,
        { type: videoFileValue.type }
      );

      setLoading(false);
      return trimmedFile;
    }
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center right-0 top-0 h-full w-full">
      <div
        className={`bg-white  relative flex flex-col ${
          files[0]?.name?.includes(".mp3") || files[0]?.name?.includes(".mp4")
            ? "h-max-content"
            : "h-142.5"
        } w-115 z-50  top-30 rounded-20 `}
      >
        <div className="flex w-full justify-between mb-5 px-7.5 pt-7.5">
          <h1 className="font-ManropeBold text-black-100 text-ft4">Preview</h1>
          <Close onClick={handleClose} className="cursor-pointer" />
        </div>
        {renderContent()}
        <div className="h-full flex w-full px-7.5 pb-7.5 justify-end items-end">
          <button
            onClick={async () => {
              if (!!videoSrc) {
                const url = await handleTrim();
                handleAddMedia(url, name || files[0].name);
              } else {
                handleAddMedia(files[0], name || files[0].name);
              }
              setOpenMedia(null);
              setFiles([]);
            }}
            disabled={loading}
            className={`flex items-center justify-center mt-2 rounded-full  w-full h-16.25  font-ManropeBold text-ft2 ${
              loading
                ? "bg-grey-50 text-grey-250"
                : "cursor-pointer bg-orange-50 text-black-100"
            }`}
          >
            {loading ? <CircularProgress className="text-orange-50" /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewPopup;
