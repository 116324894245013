import React, { useState } from "react";
import Description from "./description";
import OpenQuestion from "./openQuestion";
import PollPreviewText from "./pollPreviewText";
import RankingPreviewText from "./rankingPreviewText";
import SurveyHome from "./surverHome";
import TwoSidedPreview from "./twoSidedPreview";

/**
 * Renders different previews based on the type of survey question.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setPreview - Function to set the survey preview.
 * @param {Object} props.data - Survey data.
 * @param {Object} props.account - User account information.
 * @returns {JSX.Element} Survey component.
 */
const Survey = ({ setPreview, data, account }) => {
  const [startPollIndex, setStartPollIndex] = useState(null);
  const polls = data?.post?.polls || data?.polls;

  if (startPollIndex === null || !polls[startPollIndex]) {
    return (
      <SurveyHome
        setStartPoll={setStartPollIndex}
        data={data}
        account={account}
      />
    );
  }

  const poll = polls[startPollIndex];

  switch (poll.questionType.toUpperCase()) {
    case "POLL":
      return (
        <PollPreviewText
          setStartPollIndex={setStartPollIndex}
          type="SURVEY"
          startPollIndex={startPollIndex}
          polls={polls}
          setPreview={setPreview}
          data={poll}
          account={data.creator || account}
          surveyTime={data?.info?.activePeriod?.endDate}
        />
      );
    case "RANKING":
      return (
        <RankingPreviewText
          setStartPollIndex={setStartPollIndex}
          type="SURVEY"
          startPollIndex={startPollIndex}
          polls={polls}
          setPreview={setPreview}
          data={poll}
          account={data.creator || account}
          surveyTime={data?.info?.activePeriod?.endDate}
        />
      );
    case "TWO_SIDED":
    case "TWO-SIDED QUESTION":
      return (
        <TwoSidedPreview
          setStartPollIndex={setStartPollIndex}
          type="SURVEY"
          startPollIndex={startPollIndex}
          polls={polls}
          setPreview={setPreview}
          data={poll}
          account={data.creator || account}
          surveyTime={data?.info?.activePeriod?.endDate}
        />
      );
    case "OPEN_QUESTION":
    case "OPEN QUESTION":
      return (
        <OpenQuestion
          setStartPollIndex={setStartPollIndex}
          type="SURVEY"
          startPollIndex={startPollIndex}
          polls={polls}
          setPreview={setPreview}
          data={poll}
          account={data.creator || account}
          surveyTime={data?.info?.activePeriod?.endDate}
        />
      );
    case "DESCRIPTION":
      return (
        <Description
          setStartPollIndex={setStartPollIndex}
          startPollIndex={startPollIndex}
          polls={polls}
          setPreview={setPreview}
          data={poll}
          account={data.creator || account}
          surveyTime={data?.info?.activePeriod?.endDate}
        />
      );
    default:
      return null;
  }
};

export default Survey;
