import React, { useRef, useState } from "react";
import UpArrow from "../../assets/Icons/up-arrow.svg";
import DownArrow from "../../assets/Icons/down-arrow.svg";
import OutsideClickRef from "../outsideClickRef";
import { ReactComponent as CheckMark } from "../../assets/Icons/checkMark.svg";
import { Popover } from "@mui/material";

/**
 * Dropdown component for selecting options from a list.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {string} props.placeHolder - Placeholder text when no option is selected.
 * @param {Array} props.options - List of options to be displayed in the dropdown.
 * @param {string} props.value - The currently selected option.
 * @param {boolean} props.disabled - Flag to disable the dropdown (default: false).
 * @param {Function} props.setValue - Function to set the selected value.
 * @param {Function} props.setValueDepartment - Function to set the selected department (specific to the 'role' dropdown).
 * @param {string} props.keyName - The key name to identify the type of dropdown (default: "default").
 * @returns {JSX.Element} Dropdown component.
 */

const DropdownList = ({
  options,
  setValue,
  setIsOpen,
  setValueDepartment,
  keyName,
}) => (
  <div className="absolute right-0 mt-2 w-full z-50 max-h-100 overflow-y-auto shadow-dropdownShadow bg-white rounded-20">
    <div className="py-1 flex flex-col">
      {options?.map((option, idx) => (
        <span
          key={idx}
          className={`w-full p-5 text-ft3 font-ManropeRegular hover:bg-grey-350 text-black-150 cursor-pointer ${
            idx === options.length - 1 ? "" : "border-b border-grey-50"
          }`}
          onClick={() => {
            if (keyName === "roleId") {
              setValue((prev) => ({
                ...prev,
                role: option?.role,
                roleId: option?._id,
              }));
            } else if (keyName === "role") {
              setValue(option);
              setValueDepartment(option);
            } else {
              setValue(option);
            }
            setIsOpen(false);
          }}
        >
          {keyName === "roleId" ? option?.role : option}
        </span>
      ))}
    </div>
  </div>
);

const PopoverList = ({
  options,
  setValue,
  setIsOpen,
  setValueDepartment,
  value,
}) => (
  <div className="flex flex-col">
    {options?.map((option, idx) => (
      <div className="w-97.5" key={idx}>
        <div
          className={`p-5 ${
            idx === options.length - 1 ? "" : "border-b border-grey-50"
          }`}
        >
          <span className="font-ManropeBold text-ft3 text-black-100">
            {option.department}
          </span>
        </div>
        {option?.position?.map((item, index) => (
          <div
            key={item}
            className={`py-5 pl-8.75 pr-5 hover:bg-grey-350 cursor-pointer flex justify-between items-center ${
              index === option.position?.length - 1
                ? ""
                : "border-b border-grey-50"
            }`}
            onClick={() => {
              setValue(item);
              setValueDepartment(option);
              setIsOpen(false);
            }}
          >
            <span className="font-ManropeRegular text-ft3 text-black-100">
              {item}
            </span>
            {value === item && <CheckMark />}
          </div>
        ))}
      </div>
    ))}
  </div>
);

const Dropdown = ({
  placeHolder,
  options,
  value,
  disabled = false,
  setValue = () => {},
  setValueDepartment = () => {},
  keyName = "default",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
    !disabled && setIsOpen(!isOpen);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const popoverOpen = Boolean(popoverAnchorEl);
  const popoverId = popoverOpen ? "popover" : undefined;

  return (
    <OutsideClickRef state={dropdownRef} setState={setIsOpen}>
      <div
        className="relative w-full rounded-full bg-grey-50 outline-none px-6 h-16.25"
        onClick={handlePopoverOpen}
      >
        <div
          onClick={() => !disabled && setIsOpen(!isOpen)}
          className="w-full h-full flex justify-between items-center cursor-pointer"
        >
          <span
            className={`w-full h-full flex items-center font-ManropeRegular ${
              value ? "text-black-100" : "text-black-75"
            } text-ft3`}
          >
            {value || placeHolder}
          </span>
          <img src={isOpen ? UpArrow : DownArrow} alt="" className="w-6 h-6" />
        </div>

        {isOpen &&
          (keyName === "default" ||
          keyName === "roleId" ||
          keyName === "role" ? (
            <DropdownList
              options={options}
              setValue={setValue}
              setIsOpen={setIsOpen}
              keyName={keyName}
              setValueDepartment={setValueDepartment}
              value={value}
            />
          ) : (
            <Popover
              id={popoverId}
              open={isOpen}
              anchorEl={popoverAnchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              style={{ height: "30%" }}
            >
              <PopoverList
                options={options}
                setValue={setValue}
                setIsOpen={setIsOpen}
                setValueDepartment={setValueDepartment}
                value={value}
              />
            </Popover>
          ))}
      </div>
    </OutsideClickRef>
  );
};

export default Dropdown;
