import React, { useState } from "react";
import UpArrow from "../../../assets/Icons/up-arrow.svg";
import DownArrow from "../../../assets/Icons/down-arrow.svg";
import utility from "../../../utility";
import CheckMark from "../../../assets/Icons/checkMark.svg";
import ImageViewer from "../../../common/component/imageViewer";

/**
 * React component for rendering a two-sided type.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {Array} props.details - The details of the two-sided items.
 * @returns {React.ReactNode} - The rendered component.
 */
const TypeTwoSided = ({ details }) => {
  const [showDisc, setShowDisc] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openImage, setOpenImage] = useState("");

  return (
    <div className="w-full h-full flex flex-col gap-5">
      {details.map((item, idx) => (
        <div key={idx} className="flex flex-col gap-5 w-full">
          <div className="flex gap-5 items-center">
            {item?.cover && (
              <div
                onClick={() => {
                  setOpenPopup(!openPopup);
                  setOpenImage(item?.cover);
                }}
                style={{
                  border: `${
                    item.yourOption
                      ? `2px solid ${utility.getRandomColors(idx)}`
                      : ""
                  }`,
                }}
                className="flex flex-shrink-0 rounded-15px p-0.5"
              >
                <img
                  src={item?.cover?.replace(/\s/g, "%20")}
                  alt=""
                  className="w-15 h-15 rounded-15px"
                />
              </div>
            )}
            <div className="flex gap-3 items-center w-full">
              {!item?.cover && item?.yourOption && (
                <div className="w-4.5 h-4.5 rounded-full bg-green-50 p-0.5">
                  <img src={CheckMark} alt="check" />
                </div>
              )}
              <div className="flex flex-col w-full">
                <div className="flex justify-between">
                  <div className="flex gap-1">
                    <p className="text-black-100 font-ManropeBold text-ft3">
                      {item.title}
                    </p>
                    {item.description && showDisc !== idx ? (
                      <img
                        onClick={() => setShowDisc(idx)}
                        src={DownArrow}
                        alt="chevronUp"
                        className="cursor-pointer"
                      />
                    ) : (
                      showDisc === idx && (
                        <img
                          onClick={() => setShowDisc(null)}
                          src={UpArrow}
                          alt="chevronUp"
                          className="cursor-pointer"
                        />
                      )
                    )}
                  </div>
                  <div className="flex gap-1">
                    <p className="text-black-100 font-ManropeBold text-ft3">
                      {item.totalVotes || 0}
                    </p>
                    <p className="text-black-75 font-ManropeRegular text-ft3">{`(${
                      item.totalVotePercentage || 0
                    }%)`}</p>
                  </div>
                </div>
                <div className="flex mt-1 w-full h-1.25 rounded-20">
                  <div
                    style={{
                      width: `${item?.totalVotePercentage || 0}%`,
                      backgroundColor: utility.getRandomColors(idx),
                    }}
                    className={`h-1.25px rounded-20`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {showDisc === idx && (
            <div>
              <span className="text-ft3 font-ManropeRegular text-black-50">
                {item.description}
              </span>
            </div>
          )}
        </div>
      ))}
      {openPopup && (
        <ImageViewer
          toggleImagePopUp={openPopup}
          setToggleImagePopUp={setOpenPopup}
          image={openImage}
        />
      )}
    </div>
  );
};

export default TypeTwoSided;
