import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/Icons/plus.svg";
import Table from "../../common/table";
import { failureMessage, toastType } from "../../constant/commonArray";
import more from "../../assets/Icons/More.svg";
import deletes from "../../assets/Icons/delete.svg";
import edit from "../../assets/Icons/Edit.svg";
import placeholder from "../../assets/Icons/Placeholder.svg";
import WarningPopup from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import OutsideClickRef from "../../common/outsideClickRef";
import RolesPopup from "./rolesPopup";
import utility from "../../utility";
import { UserService } from "../../services";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import left from "../../assets/Icons/Left.svg";

/**
 * RolesComponent displays a list of roles, allowing users to create, edit, and delete roles.
 *
 * @component
 * @returns {JSX.Element} - The JSX element representing the RolesComponent.
 */

const RolesComponent = () => {
  /**
   * Ref for the options dropdown.
   * @type {React.RefObject<HTMLElement>}
   */
  const optionsRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  /**
   * State to manage the selected role item.
   * @type {Object}
   * @property {string} role - The role name.
   * @property {string} staffNo - The number of staff assigned to the role.
   * @property {string} id - The ID of the selected role.
   */

  const [selectedItem, setSelectedItem] = useState({
    role: "",
    staffNo: "",
    id: "",
  });

  const [roleId, setRoleId] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const headers = [{ name: "Role" }, { name: "Access" }, { name: "Staff" }];

  /**
   * State to manage the roles popup.
   * @type {Object}
   * @property {boolean} popup - Indicates whether the popup is open or not.
   * @property {string} name - The name of the popup.
   * @property {string} role - The role name.
   * @property {string} roleid - The role ID.
   * @property {Array} access - The access array.
   */

  const [rolesPopup, setRolesPopup] = useState({
    popup: false,
    name: "",
    role: "",
    roleid: "",
    access: [],
  });
  const [isDeletePopUp, setDeletePopUp] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const history = useHistory();

  const handleDeleteRole = async (id) => {
    if (selectedItem && selectedItem?.role === "Super admin") {
      setDeletePopUp(!isDeletePopUp);
      ShowToast({
        message: failureMessage.CANT_DELETE_ROLE,
        type: toastType.ERROR,
      });
      return;
    } else if (selectedItem && selectedItem?.staffNo > 0) {
      setDeletePopUp(!isDeletePopUp);
      ShowToast({
        message: failureMessage.CANT_DELETE_STAFF,
        type: toastType.ERROR,
      });
      return;
    }
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deleteRole(id)
      );
      if (error || !response) return;
      if (response?.success) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      getRoleList();
      setDeletePopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  const getRoleList = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getRoleList()
      );

      if (error || !response) return;

      setRoleList(response);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getRoleList();
  }, []);

  const renderTableBody = () => {
    if (roleList.length > 0) {
      return roleList.map((item) => (
        <tr
          key={item?._id}
          className="hover:bg-grey-350 relative cursor-pointer"
        >
          <td className="py-5 px-5 font-ManropeRegular text-ft2 text-black-50 w-1/4">
            {utility.capitalizeFirstLetter(item?.role)}
          </td>
          <td className="font-ManropeRegular text-ft2 text-black-50 w-3/5">
            <div>
              {item?.access?.map((value, index) => (
                <span key={index}>
                  {value.name ? value?.name : value}
                  {item?.access?.length - 1 === index ? "" : ", "}
                </span>
              ))}
            </div>
          </td>
          <td className="font-ManropeRegular text-ft2 text-black-50 pl-2">
            {item?.totalStaffs}
          </td>
          <td>
            {userPermissions?.indexOf("roles:write:details") > -1 && (
              <img
                src={more}
                alt="More"
                onClick={() => {
                  setRoleId(item?._id);
                }}
                className="cursor-pointer"
              />
            )}
            {roleId === item?._id && (
              <OutsideClickRef
                state={optionsRef}
                setState={() => setRoleId(null)}
                className={"absolute top-0 right-0 "}
              >
                <div className="flex flex-col absolute w-75 top-13 right-16 rounded-20 shadow-inner z-10 bg-white">
                  <button
                    className="flex p-5 items-center gap-3.75"
                    onClick={() => {
                      setRolesPopup({
                        popup: true,
                        name: "Role",
                        role: item?.role,
                        access: item?.access,
                        roleId: item?._id,
                      });
                    }}
                  >
                    <img src={edit} alt="edit" />
                    <p className="text-ft3 font-ManropeRegular text-black-100">
                      Edit
                    </p>
                  </button>
                  <hr className="text-grey-50" />

                  <button
                    onClick={() => {
                      setSelectedItem((prev) => ({
                        ...prev,
                        id: item?._id,
                        role: item?.role,
                        staffNo: item?.totalStaffs,
                      }));
                      setDeletePopUp(true);
                    }}
                    className="flex p-5 items-center gap-3.75"
                  >
                    <img src={deletes} alt="deletes" />
                    <p className="text-ft3 font-ManropeRegular text-black-100">
                      Delete
                    </p>
                  </button>
                </div>
              </OutsideClickRef>
            )}
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={7}>
            <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
              <img src={placeholder} alt="placeholder" />
            </div>{" "}
          </td>
        </tr>
      );
    }
  };
  return (
    <div className="py-5 w-full h-full overflow-y-auto pr-12.5">
      {(userPermissions?.indexOf("roles:write:details") > -1 || haveAudit) && (
        <div
          className={`flex ${
            haveAudit ? "justify-between" : "justify-end"
          } items-center bg-white p-5 rounded-20 gap-5`}
        >
          {haveAudit && (
            <div
              onClick={() => {
                if (haveAudit) {
                  history.push("/audit-log");
                }
              }}
            >
              <img src={left} alt="left" className="cursor-pointer" />
            </div>
          )}
          {userPermissions?.indexOf("roles:write:details") > -1 && (
            <div
              className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center relative cursor-pointer"
              onClick={() => {
                setRolesPopup({
                  name: "Create new role",
                  popup: true,
                });
              }}
            >
              <AddIcon />
            </div>
          )}
        </div>
      )}
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        loading={loading}
        showPagination={false}
      />
      {isDeletePopUp && (
        <WarningPopup
          title={`Delete role "${selectedItem?.role}"?`}
          description={"Are you sure? You want to delete role"}
          handleActivateUser={() => handleDeleteRole(selectedItem?.id)}
          setToggle={setDeletePopUp}
        />
      )}
      {rolesPopup.popup && (
        <RolesPopup
          setToggle={() => setRolesPopup({ popup: false })}
          heading={rolesPopup.name}
          data={rolesPopup}
          getRoleList={getRoleList}
        />
      )}
    </div>
  );
};

export default RolesComponent;
