import React, { useState } from "react";
import close from "../../assets/Icons/close.svg";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import { CircularProgress } from "@mui/material";
import BlockChainService from "../../services/blockChainService";

/**
 * EditBFactorPopup is a component for editing the b Factor value used in income calculations.
 *
 * @component
 * @param {Object} props - React component props.
 * @param {Object} props.editFactor - Object containing data related to the b Factor popup state.
 * @param {number} props.editFactor.value - Current b Factor value.
 * @param {Function} props.setEditFactor - Function to update the editFactor state.
 * @param {Function} props.getData - Function to fetch updated data after editing.
 * @returns {JSX.Element} - JSX element representing the EditBFactorPopup component.
 */

const bFactorRegex = new RegExp(/^\d*\.?\d{0,4}$/);

const EditBFactorPopup = ({ editFactor, setEditFactor, getData }) => {
  /**
   * Handles the update of the b Factor value.
   * Makes an API call to update the b Factor value and handles success or error responses.
   *
   * @async
   * @returns {void}
   */

  const [state, setState] = useState({
    value: editFactor?.value || 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Handles the edit income operation by making an API call.
   *
   * @async
   * @returns {void}
   */
  const handleBValue = async () => {
    let response;
    setLoading(true);
    try {
      response = await new BlockChainService().updateBeta(
        parseFloat(state?.value)
      );
      if (response?.statusCode === 400) {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      }
      getData();
      setEditFactor((prev) => ({ ...prev, popup: false }));
      setLoading(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Renders the component JSX.
   *
   * @returns {JSX.Element} - The JSX element representing the EditIncomePopup component.
   */

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center  right-0 top-0 h-full w-full">
      <div className="flex flex-col bg-white rounded-20 shadow-md w-112.5 h-93 p-7.5 top-30 absolute">
        <div className="flex w-full justify-between items-center mb-5">
          <span className="font-ManropeBold text-ft4 text-black-100">
            Edit b Factor Value
          </span>
          <img
            onClick={() => setEditFactor((prev) => ({ ...prev, popup: false }))}
            src={close}
            alt="close"
            className="cursor-pointer"
          />
        </div>

        <div className="flex flex-col gap-7.5 overflow-y-auto scrollbar-hide max-h-43per">
          <div className="flex flex-col">
            <label className="font-ManropeMedium text-ft3 text-black-100">
              Enter Value
            </label>
            <input
              type="text"
              onChange={(e) => {
                const inputValue = e.target.value;
                if (bFactorRegex.test(inputValue)) {
                  const numericValue = inputValue;
                  if (numericValue >= 0.0001 && numericValue <= 0.9999) {
                    setState((prev) => ({
                      ...prev,
                      value: inputValue === "" ? "" : numericValue,
                    }));
                    setError(null);
                  } else {
                    setError("Input value must be between 0.0001 and 0.9999");
                    setState((prev) => ({
                      ...prev,
                      value: inputValue === "" ? "" : inputValue,
                    }));
                  }
                }
              }}
              value={state?.value}
              className="h-16.25 px-6.25 rounded-full bg-grey-50 focus:outline-none"
            />
            {error && <span className=" text-red-50">{error}</span>}
          </div>
        </div>
        <div className="mt-2">
          <span className="text-ft2 font-ManropeRegular text-black-50">
            Current value: {editFactor?.value}
          </span>
        </div>
        <button
          onClick={handleBValue}
          disabled={
            loading ||
            !state?.value ||
            !!error ||
            parseFloat(editFactor?.value) === parseFloat(state?.value)
          }
          className={`h-16.25 px-6.25 rounded-full ${
            !state?.value ||
            loading ||
            !!error ||
            parseFloat(editFactor?.value) === parseFloat(state?.value)
              ? "bg-grey-50"
              : "bg-orange-50"
          }  mt-auto text-black-100 font-ManropeBold text-ft2`}
        >
          {loading ? (
            <div className="flex justify-center  items-center h-full w-full">
              <CircularProgress className="text-orange-50" />
            </div>
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditBFactorPopup;
