import { httpConstants } from "../constant/commonArray";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./userService";

const {
  METHOD_TYPE: { GET, PUT, POST },
  API_END_POINT: {
    TOKEN_MANAGMENT,
    UPDATE_B_VALUE,
    TOKEN_LIST,
    R_HISTORY,
    GET_BALANCE,
    R_CALCULATE,
    MINT_TRANSFER,
    TOKEN_TRANFER,
  },
} = httpConstants;

export default class BlockChainService {
  baseUrl = process.env.REACT_APP_BLOCKCHAINSERVICE;
  getDashboard = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${TOKEN_MANAGMENT}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  updateBeta = async (value) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        this.baseUrl + `/${UPDATE_B_VALUE}?value=${value}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  getTokenList = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${TOKEN_LIST}?${query}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getRHistory = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${R_HISTORY}?${query}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getBalance = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${GET_BALANCE}/${id}`, {}, getHeaders())
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  calulateR = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${R_CALCULATE}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };

  mintTransfer = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${MINT_TRANSFER}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  TokenTransfer = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${TOKEN_TRANFER}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
}
