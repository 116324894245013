import React from "react";
import Left from "../../../../assets/Icons/Left.svg";
import checkMark from "../../../../assets/Icons/checkMark.svg";
import placeholder from "../../../../assets/Icons/Placeholder.svg";
import ShowToast from "../../../../common/showToast";
import { toastType } from "../../../../constant/commonArray";

/**
 * ChannelDetailsPopup Component displays channel details and allows selection of channels.
 * @component
 * @param {object} props - Component props
 * @param {Function} props.setOpenChannel - Function to set the open state of the channel
 * @param {object} props.openChannel - Object containing information about the open channel
 * @param {Function} props.setState - Function to set the state of the component
 * @param {object} props.state - Current state of the component
 * @returns {JSX.Element} ChannelDetailsPopup component
 */

const ChannelDetailsPopup = ({
  setOpenChannel,
  openChannel,
  setState,
  state,
}) => {
  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white  relative flex flex-col h-1/2 w-115 z-50 p-7.5 rounded-20 ">
        <div className="flex gap-5">
          <img
            onClick={() =>
              setOpenChannel((prev) => ({
                ...prev,
                typeOpen: "Channels",
              }))
            }
            src={Left}
            alt=""
            className="cursor-pointer"
          />
          <span className="text-black-100 font-ManropeBold text-ft4">
            {openChannel?.name}
          </span>
        </div>
        <div
          className={`mt-7 flex flex-col gap-5 h-80per overflow-y-auto scrollbar-hide`}
        >
          {openChannel?.data?.length > 0 ? (
            openChannel?.data?.map((item) => (
              <div
                key={item?.channelId}
                className="flex justify-between items-center"
              >
                <span className="text-ft2 font-ManropeRegular text-black-100">
                  {item?.name}
                </span>
                <div
                  onClick={() => {
                    if (
                      state?.channels?.length > 1 &&
                      !state?.channels?.includes(item?.name)
                    ) {
                      ShowToast({
                        message: "Maximum 2 channels allowed",
                        type: toastType.ERROR,
                      });
                      return;
                    }
                    if (state?.channels?.includes(item?.name)) {
                      setState((prev) => ({
                        ...prev,
                        channels: state.channels.filter(
                          (value) => value !== item?.name
                        ),
                      }));
                    } else {
                      setState((prev) => ({
                        ...prev,
                        channels: [...state.channels, item?.name],
                      }));
                    }
                  }}
                  className="relative flex items-center"
                >
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    className={`appearance-none w-5 h-5 rounded-md cursor-pointer ${
                      state?.channels?.includes(item?.name)
                        ? "bg-orange-50"
                        : "bg-white border-2 border-grey-100"
                    }`}
                  />
                  {state?.channels?.includes(item?.name) && (
                    <img
                      className="absolute cursor-pointer w-5 h-5"
                      src={checkMark}
                      alt="checkMark"
                    />
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center h-full">
              <img src={placeholder} alt="placeholder" className="h-full" />
            </div>
          )}
        </div>
        <div>
          <button
            onClick={() =>
              setOpenChannel((prev) => ({
                ...prev,
                typeOpen: "",
              }))
            }
            disabled={state?.channels?.length === 0}
            className={`w-full ${
              state?.channels?.length === 0 ? "bg-grey-50" : "bg-orange-50"
            }  rounded-full mt-7.5 h-16.25 flex justify-center items-center font-ManropeBold text-ft1 text-black-50`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChannelDetailsPopup;
