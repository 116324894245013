import React, { useEffect, useState, useRef } from "react";
import PollType from "../../../assets/Icons/poll-type-icon.svg";
import CoverMedia from "./coverMedia";
import defaultImage from "../../../assets/Icons/signupProfileSmall.png";
import menu from "../../../assets/Icons/menu-white.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  sortableContainer,
  sortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import utility from "../../../utility";
import LearnMore from "./learnMore";
import AudioPreview from "../createPoll/audioPreview";
import LinkPreview from "../createPoll/linkPreview";
import OtherLink from "../../../assets/Icons/other-link-white.svg";
import AudioWhite from "../../../assets/Icons/audio-white.svg";

/**
 * Renders a preview of a ranking or poll text with media and options.
 * @component
 * @param {Object} props - Component props.
 * @param {function} props.setStartPollIndex - Callback to set the starting poll index.
 * @param {string} [props.type="POLL"] - Type of preview, either "POLL" or "SURVEY".
 * @param {number} props.startPollIndex - Index of the starting poll.
 * @param {Array} props.polls - Array of polls.
 * @param {function} props.setPreview - Callback to set the preview state.
 * @param {Object} props.data - Data object containing poll or survey details.
 * @param {Object} props.account - Account details of the creator.
 * @param {Array} props.images - Array of images for the poll or survey.
 * @param {Date} props.surveyTime - Time of survey.
 * @returns {JSX.Element} Rendered component.
 */

const RankingPreviewText = ({
  setStartPollIndex,
  type = "POLL",
  startPollIndex,
  polls,
  setPreview,
  data,
  account,
  images,
  surveyTime,
}) => {
  const [isLearnMore, setIsLearnMore] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const filterVideoNImages = data?.post?.media?.filter((item) => {
    return item?.type !== "link" && item?.type !== "mp3";
  });
  const filterAudio = data?.post?.media?.filter((item) => {
    return item?.type === "mp3";
  });
  const filterLink = data?.post?.media?.filter((item) => {
    return item?.type === "link";
  });
  const filterVideoNImagesFromMedia = data?.media?.filter((item) => {
    const fileName =
      item instanceof File ? utility.getFileType(item.name) : item.type;

    return fileName !== "audio" && fileName !== "mp3" && fileName !== "link";
  });

  const filterAudioFromMedia = data?.media?.filter((item) => {
    const fileName =
      item instanceof File ? utility.getFileType(item.name) : item.type;

    return fileName === "audio" || fileName === "mp3";
  });

  const filterLinkFromMedia = data?.media?.filter((item) => {
    return !(item instanceof File) && item.type === "link";
  });

  const audioFiles = [...(filterAudioFromMedia || []), ...(filterAudio || [])];

  const linkFiles = [...(filterLinkFromMedia || []), ...(filterLink || [])];
  const media = [
    ...(filterVideoNImages || []),
    ...(filterVideoNImagesFromMedia || []),
  ];

  const mediaType = data?.post?.options?.type || data?.options?.type;
  const allMedia = [...(data?.post?.media || []), ...(data?.media || [])];

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = Date.now();
      const time = data?.info?.activePeriod?.endDate || surveyTime;
      const timeDifference = time - currentTime;
      const remainingMilliseconds = Math.max(0, timeDifference);

      const remainingDays = Math.floor(
        remainingMilliseconds / (1000 * 60 * 60 * 24)
      );
      const remainingHours = Math.floor(
        (remainingMilliseconds / (1000 * 60 * 60)) % 24
      );
      const remainingMinutes = Math.floor(
        (remainingMilliseconds / (1000 * 60)) % 60
      );

      setTimeLeft({
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
      });
    };

    calculateTimeLeft();

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 60000);

    return () => clearInterval(timer);
  }, [data, surveyTime]);

  const date = `${timeLeft.days ? timeLeft.days : ""}${
    timeLeft.days ? "d" : ""
  } ${timeLeft.hours}hr ${timeLeft?.days ? "" : timeLeft.minutes}${
    timeLeft?.days ? "" : "m"
  }`;

  const getHeight = () => {
    const height =
      media?.length > 0 && type === "SURVEY" && mediaType !== "IMAGE"
        ? "h-65per"
        : type === "POLL"
        ? "h-auto"
        : "h-90per";
    return height;
  };

  return (
    <div className="bg-white  relative flex flex-col h-full w-106.25 z-50 p-5 ">
      {!isLearnMore ? (
        <div className="bg-black-100 overflow-y-auto overflow-x-hidden scrollbar-hide w-full h-full">
          {media?.length > 0 && mediaType !== "IMAGE" ? (
            <div className="flex justify-center items-center h-35per">
              <CoverMedia
                data={media}
                name={data?.creator?.fullName || account?.fullName}
                profilePhoto={
                  data?.creator?.profilePhoto || account?.profilePhoto
                }
                endDate={`${date} left`}
              />
            </div>
          ) : (
            <div className="px-5 pt-5 w-full">
              {" "}
              <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-2.5">
                  <img
                    src={
                      data?.creator?.profilePhoto ||
                      account?.profilePhoto ||
                      defaultImage
                    }
                    alt="user"
                    className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
                  />
                  <p className="font-ManropeBold text-ft2 text-center text-white">
                    {data?.creator?.fullName || account?.fullName || "Pollpapa"}
                  </p>
                </div>
                <div className="flex items-center justify-center w-auto p-2 rounded-20  bg-white backdrop-blur-xl bg-opacity-10">
                  <p className="font-ManropeBold text-ft2 w-full text-white">
                    {date}
                    left
                  </p>
                </div>
              </div>
            </div>
          )}
          <div
            className={`p-5 flex flex-col overflow-y-auto justify-between ${getHeight()} `}
          >
            <div className="flex flex-col  gap-5 justify-center items-center">
              <div className="flex flex-col gap-3.75 w-full">
                {type === "POLL" ? (
                  <div className="flex gap-1">
                    {data?.channels?.map((item, index) => (
                      <p
                        key={index}
                        className="flex items-center justify-center  rounded-20 text-black-100 font-ManropeMedium text-ft0 px-1.5"
                        style={{
                          backgroundColor: utility.getRandomColors(index),
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                ) : (
                  <div className="px-2 py-1 bg-black-50 rounded-full mt-3 w-max text-ft0 font-ManropeMedium text-white">
                    <span>
                      {" "}
                      Question {startPollIndex + 1} of {polls?.length}
                    </span>
                  </div>
                )}
                <div className="pt-1 gap-2 inline-block relative">
                  <span className=" text-ft42 font-ManropeBold text-white ">
                    {" "}
                    {data?.post?.question || data?.question}
                  </span>{" "}
                  {type === "POLL" && (
                    <span className="inline-flex gap-2 ml-2 mt-1 items-center px-2 py-1 border-1 rounded-20 w-max border-white border-opacity-10 text-ft0 font-ManropeMedium text-white">
                      <img src={PollType} alt="Icon" className="w-4 h-4" />{" "}
                      {utility.capitalizeFirstLetter(
                        data?.post?.pollType || data?.pollType
                      )}{" "}
                      {utility.capitalizeFirstLetter(
                        data?.post?.questionType || data?.questionType
                      )}
                    </span>
                  )}
                </div>
                {(data?.post?.description || data?.description) && (
                  <div onClick={() => setIsLearnMore(!isLearnMore)}>
                    <span className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer">
                      Learn more
                    </span>
                  </div>
                )}

                <div>
                  {(audioFiles?.length > 0 || linkFiles?.length > 0) && (
                    <div className="flex gap-3 items-center">
                      <span
                        onClick={() => setViewDetails(!viewDetails)}
                        className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer"
                      >
                        {!viewDetails ? "View details" : "Hide details"}
                      </span>
                      {audioFiles?.length > 0 && (
                        <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-black-50 items-center">
                          <img src={AudioWhite} alt="" className="h-4 w-4" />
                          <span className="text-white font-ManropeBold text-ft0">
                            {audioFiles?.length || 0}
                          </span>
                        </div>
                      )}
                      {linkFiles?.length > 0 && (
                        <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-black-50 items-center">
                          <img src={OtherLink} alt="" className="h-4 w-4" />
                          <span className="text-white font-ManropeBold text-ft0">
                            {linkFiles?.length || 0}
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  {viewDetails && (
                    <div>
                      {allMedia?.map((item, idx) => {
                        let fileType = "";
                        if (item instanceof File) {
                          fileType = utility.getFileType(item.name);
                        } else {
                          fileType = item?.type;
                        }

                        if (fileType === "audio" || fileType === "mp3") {
                          return (
                            <div className="mt-2" key={idx}>
                              {" "}
                              <AudioPreview
                                fileName={
                                  item?.fileName ||
                                  data?.audioName[idx]?.fileName
                                }
                                item={item}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                      {linkFiles?.map((item, idx) => (
                        <div key={idx} className="mt-2">
                          <LinkPreview
                            link={item?.url}
                            bgColor={"bg-black-50"}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {data?.post?.options?.type === "IMAGE" ||
                data?.options?.type === "IMAGE" ? (
                  <ImageCheckbox
                    data={
                      images?.length > 0
                        ? images
                        : data?.images?.length > 0
                        ? data?.images
                        : data?.post?.options?.answers?.length > 0
                        ? data?.post?.options?.answers
                        : data?.options?.answers?.length > 0
                        ? data?.options?.answers
                        : data?.post?.options?.answers
                    }
                  />
                ) : (
                  <TextCheckbox
                    data={
                      data?.post?.options?.answers || data?.options?.answers
                    }
                    openAnser={data?.post?.openAnswer || data?.openAnswer}
                  />
                )}
              </div>
            </div>
            {type !== "POLL" && (
              <div className="w-full h-16.25 mt-5 rounded-full bg-orange-50">
                <button
                  onClick={() => {
                    if (polls?.length === startPollIndex + 1) {
                      setPreview(false);
                    } else if (polls?.length > startPollIndex) {
                      setStartPollIndex(startPollIndex + 1);
                    }
                    setViewDetails(false);
                  }}
                  className="text-ft2 w-full h-16.25 font-ManropeBold text-black-50 flex justify-center items-center"
                >
                  {polls?.length === startPollIndex + 1 ? "Done" : "Continue"}
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <LearnMore
          description={data?.post?.description || data?.description}
          setIsLearnMore={setIsLearnMore}
        />
      )}
    </div>
  );
};

export default RankingPreviewText;

const TextCheckbox = ({ data }) => {
  const [newData, setNewData] = useState(data);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...newData];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setNewData(newItems);
  };

  return (
    <div className="flex flex-col gap-2">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <div
              className="flex flex-col gap-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {newData?.map((item, idx) => (
                <Draggable key={idx} draggableId={idx.toString()} index={idx}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="flex gap-5 w-full"
                    >
                      <div className="flex h-7.5 w-full items-center justify-between self-stretch">
                        <div className="flex gap-3.75">
                          <span className="font-ManropeBold text-ft2 text-white">
                            {idx + 1}
                          </span>
                          <span className="font-ManropeRegular text-ft2 text-white">
                            {item.value}
                          </span>
                        </div>
                        <img {...provided.dragHandleProps} src={menu} alt="" />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const ImageCheckbox = ({ data }) => {
  const helperContainerRef = useRef(null);
  const [newData, setNewData] = useState(data);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedImages = arrayMove(newData, oldIndex, newIndex);
    setNewData(updatedImages);
  };

  return (
    <div
      ref={helperContainerRef}
      className="flex h-full w-full flex-wrap gap-4 overflow-x-hidden"
    >
      <SortableContainer onSortEnd={onSortEnd} axis="xy" useDragHandle={true}>
        {newData?.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            idx={index}
            index={index}
            value={value}
          />
        ))}
      </SortableContainer>
      ;
    </div>
  );
};

const SortableItem = sortableElement(({ value, idx }) => {
  return (
    <div style={{ zIndex: 999 }} className="  w-40 h-40">
      <div className="relative">
        <img
          src={value instanceof File ? URL.createObjectURL(value) : value.value}
          alt=""
          className="w-40 h-40  rounded-20"
        />
        <div className="cursor-pointer flex gap-1 absolute left-2 top-2 rounded-20 bg-black-100 bg-opacity-70  px-3 py-0.5 w-13">
          <span className="text-white text-ft2 font-ManropeBold">
            {idx + 1}
          </span>
          <DragHandle />
        </div>
      </div>
    </div>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <div className="bg-transparent flex flex-wrap gap-3 w-full">{children}</div>
  );
});

const DragHandle = SortableHandle(() => {
  return <img src={menu} alt="menu" className="cursor-pointer" />;
});
