/**
 * @module PromotionsComponent
 * @description
 * Module for managing and rendering additional fields.
 *
 * @example
 * // Usage in a React functional component
 * import PromotionsComponent from "./PromotionsComponent";
 *
 * const MyComponent = () => {
 *   return <PromotionsComponent />;
 * };
 *
 * @returns {React.Component} The PromotionsComponent component
 */

import React, { useEffect, useRef, useState } from "react";
import search from "../../assets/Icons/Search.svg";
import { ReactComponent as AddIcon } from "../../assets/Icons/plus.svg";
import Table from "../../common/table";
import { toastType } from "../../constant/commonArray";
import moment from "moment";
import more from "../../assets/Icons/More.svg";
import deletes from "../../assets/Icons/delete.svg";
import stop from "../../assets/Icons/Stop.svg";
import token from "../../assets/Icons/Token.svg";
import add from "../../assets/Icons/Add.svg";
import tick from "../../assets/Icons/Tick-2.svg";
import error from "../../assets/Icons/Error.svg";
import placeholder from "../../assets/Icons/Placeholder.svg";
import { Link, useHistory } from "react-router-dom";
import WarningPopup from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import OutsideClickRef from "../../common/outsideClickRef";
import { UserService } from "../../services";
import utility from "../../utility";
import Close from "../../assets/Icons/close.svg";
import { useDebouncedCallback } from "use-debounce";
import checkMark from "../../assets/Icons/checkMark.svg";
import { useSelector } from "react-redux";

/**
 * Functional component for managing promotions.
 */
const PromotionsComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState({
    id: "",
    name: "",
  });
  const [createPopup, setCreatePopup] = useState(false);
  const [isStopPopUp, setStopPopUp] = useState(false);
  const [isDeletePopUp, setDeletePopUp] = useState(false);
  const [promotionLists, setPromotionList] = useState([]);
  const [debounceValue, setDebounceValue] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [clickFilter, setClickFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [sort, setSort] = useState(-1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const history = useHistory();
  const createRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * A function to handle page changes.
   *
   * @param {number} pageNumber - the page number to navigate to
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const headers = [
    { name: " Name of promotion" },
    { name: "Status", filter: true },
    { name: "Created", sort: true },
    { name: "Start date", startDate: true },
    { name: "End date", endDate: true },
  ];

  /**
   * Toggles the selected filter in the list of filters.
   *
   * @param {type} filter - description of the filter to toggle
   * @return {type} description of the return value
   */
  const toggleSelectedFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };
  /**
   * Handle click event for more option.
   *
   * @param {Object} item - The item to be handled
   * @return {void}
   */
  const handleMoreClick = (item) => {
    if (selectedItem?.id === item?._id) {
      setSelectedItem(null);
    } else {
      setSelectedItem({
        id: item?._id,
        name: item?.name,
      });
    }
  };

  /**
   * Async function to handle stopping promotion.
   *
   * @param {type} id - The ID of the promotion to be stopped
   * @return {type} undefined
   */
  const handleStopPromotion = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().stopPromotion(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getPromotion();
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setSelectedItem({
        id: "",
        name: "",
      });
      setStopPopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Asynchronously handles the deletion of a promotion.
   *
   * @param {string} id - The ID of the promotion to be deleted
   * @return {void}
   */
  const handleDeletePromotion = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deletePromotion(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getPromotion();
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setSelectedItem({
        id: "",
        name: "",
      });
      setDeletePopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Handles the redirect for duplicate items based on the item type and id.
   *
   * @param {*} item - the type of item for redirection
   * @param {*} id - the id of the duplicated item
   */
  const handleDuplicateRedirect = (item, id) => {
    switch (item) {
      case "FREE_TOKENS":
        history.push(`/promo-and-rewards/create-token?duplicate=${id}`);
        break;
      case "VOTES":
        history.push(`/promo-and-rewards/create-votes?duplicate=${id}`);
        break;
      case "CONTENT":
        history.push(`/promo-and-rewards/create-content?duplicate=${id}`);
        break;
      default:
    }
  };

  /**
   * Renders the filter based on the selected filters.
   *
   * @return {JSX.Element} The JSX for rendering the filter
   */
  const renderFilter = () => {
    return (
      clickFilter && (
        <div
          className={`flex w-45 p-5 flex-col items-start gap-5 absolute top-5 right-5 z-10 rounded-20 bg-white shadow-inner`}
        >
          <div className="flex h-7.5 items-center gap-3.75 self-stretch">
            <input
              type="checkbox"
              className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                selectedFilters.includes("ENDED")
                  ? "bg-orange-50 border-none"
                  : "bg-white border-2 border-grey-100"
              }`}
              onChange={() => toggleSelectedFilter("ENDED")}
              checked={selectedFilters.includes("ENDED")}
            />
            {selectedFilters.includes("ENDED") ? (
              <img
                onClick={() => toggleSelectedFilter("ENDED")}
                className="absolute cursor-pointer"
                src={checkMark}
                alt="checkMark"
              ></img>
            ) : (
              ""
            )}
            <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
              Ended
            </label>
          </div>
          <div className="flex h-7.5 items-center gap-3.75 self-stretch">
            <input
              type="checkbox"
              className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                selectedFilters.includes("PENDING")
                  ? "bg-orange-50 border-none"
                  : "bg-white border-2 border-grey-100"
              }`}
              onChange={() => toggleSelectedFilter("PENDING")}
              checked={selectedFilters.includes("PENDING")}
            />
            {selectedFilters.includes("PENDING") ? (
              <img
                onClick={() => toggleSelectedFilter("PENDING")}
                className="absolute cursor-pointer"
                src={checkMark}
                alt="checkMark"
              ></img>
            ) : (
              ""
            )}
            <label className="font-ManropeLight text-ft3 text-black-100">
              Pending
            </label>
          </div>
          <div className="flex h-7.5 items-center gap-3.75 self-stretch">
            <input
              type="checkbox"
              className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                selectedFilters.includes("ACTIVE")
                  ? "bg-orange-50 border-none"
                  : "bg-white border-2 border-grey-100"
              }`}
              onChange={() => toggleSelectedFilter("ACTIVE")}
              checked={selectedFilters.includes("ACTIVE")}
            />
            {selectedFilters.includes("ACTIVE") ? (
              <img
                onClick={() => toggleSelectedFilter("ACTIVE")}
                className="absolute cursor-pointer"
                src={checkMark}
                alt="checkMark"
              ></img>
            ) : (
              ""
            )}
            <label className="font-ManropeLight text-ft3 text-black-100">
              Active
            </label>
          </div>
        </div>
      )
    );
  };

  /**
   * Renders the table body based on the promotionLists.
   *
   * @return {JSX.Element} The table body JSX element
   */
  const renderTableBody = () => {
    if (promotionLists.length > 0) {
      return promotionLists.map((item) => (
        <tr
          key={item._id}
          className="hover:bg-grey-350 relative cursor-pointer"
        >
          <td
            onClick={() =>
              history.push(`/promo-and-rewards/details/${item?._id}`)
            }
            className="py-2.5 px-5 font-ManropeRegular text-ft2 text-black-50"
          >
            {item.name}
          </td>
          <td
            className="font-ManropeRegular text-ft2 text-black-50"
            onClick={() =>
              history.push(`/promo-and-rewards/details/${item?._id}`)
            }
          >
            {utility.capitalizeFirstLetter(item.status)}
          </td>
          <td
            className="font-ManropeRegular text-ft2 text-black-50"
            onClick={() =>
              history.push(`/promo-and-rewards/details/${item?._id}`)
            }
          >
            {moment(item.addedOn).format("DD MMM[,] YYYY")}
          </td>
          <td
            className="font-ManropeRegular text-ft2 text-black-50"
            onClick={() =>
              history.push(`/promo-and-rewards/details/${item?._id}`)
            }
          >
            {moment(item.startDate).format("DD MMM[,] YYYY HH:mm")}
          </td>
          <td
            className="font-ManropeRegular text-ft2 text-black-50"
            onClick={() =>
              history.push(`/promo-and-rewards/details/${item?._id}`)
            }
          >
            {moment(item.endDate).format("DD MMM[,] YYYY HH:mm")}
          </td>
          <td>
            {(userPermissions?.indexOf("promo:write:duplicate") > -1 ||
              userPermissions?.indexOf("promo:write:stop") > -1 ||
              userPermissions?.indexOf("promo:write:delete") > -1) && (
              <img
                src={more}
                alt="More"
                onClick={() => {
                  handleMoreClick(item);
                }}
                className="cursor-pointer"
              />
            )}
          </td>
          {selectedItem?.id === item?._id && item?.status === "ACTIVE" && (
            <OutsideClickRef
              state={createRef}
              setState={() => setSelectedItem(null)}
            >
              <div className="flex flex-col absolute w-75 top-13 right-5 rounded-20 shadow-inner z-10 bg-white">
                {userPermissions?.indexOf("promo:write:duplicate") > -1 && (
                  <button
                    onClick={() =>
                      handleDuplicateRedirect(item?.rewardFormat, item?._id)
                    }
                    className="flex p-5 items-center gap-3.75"
                  >
                    <img src={stop} alt="stop" />
                    <p className="text-ft3 font-ManropeRegular text-black-100">
                      Create duplicate
                    </p>
                  </button>
                )}
                <hr className="text-grey-50" />
                {userPermissions?.indexOf("promo:write:stop") > -1 && (
                  <button
                    onClick={() => {
                      setStopPopUp(true);
                    }}
                    className="flex p-5 items-center gap-3.75"
                  >
                    <img src={error} alt="error" />

                    <p className="text-ft3 font-ManropeRegular text-black-100">
                      Stop
                    </p>
                  </button>
                )}
              </div>
            </OutsideClickRef>
          )}
          {selectedItem?.id === item?._id && item?.status === "PENDING" && (
            <OutsideClickRef
              state={createRef}
              setState={() => setSelectedItem(null)}
            >
              <div className="flex flex-col absolute w-75 top-13 right-5 rounded-20 shadow-inner z-10 bg-white">
                {userPermissions?.indexOf("promo:write:duplicate") > -1 && (
                  <button
                    onClick={() =>
                      handleDuplicateRedirect(item?.rewardFormat, item?._id)
                    }
                    className="flex p-5 items-center gap-3.75"
                  >
                    <img src={stop} alt="stop" />
                    <p className="text-ft3 font-ManropeRegular text-black-100">
                      Create duplicate
                    </p>
                  </button>
                )}
                <hr className="text-grey-50" />
                {userPermissions?.indexOf("promo:write:delete") > -1 && (
                  <button
                    onClick={() => {
                      setDeletePopUp(true);
                    }}
                    className="flex p-5 items-center gap-3.75"
                  >
                    <img src={deletes} alt="deletes" />

                    <p className="text-ft3 font-ManropeRegular text-black-100">
                      Delete
                    </p>
                  </button>
                )}
              </div>
            </OutsideClickRef>
          )}
          {selectedItem?.id === item?._id && item?.status === "ENDED" && (
            <OutsideClickRef
              state={createRef}
              setState={() => setSelectedItem(null)}
            >
              <div className="flex flex-col absolute w-75 top-13 right-5 rounded-20 shadow-inner z-10 bg-white">
                {userPermissions?.indexOf("promo:write:duplicate") > -1 && (
                  <button
                    onClick={() =>
                      handleDuplicateRedirect(item?.rewardFormat, item?._id)
                    }
                    className="flex p-5 items-center gap-3.75"
                  >
                    <img src={stop} alt="stop" />
                    <p className="text-ft3 font-ManropeRegular text-black-100">
                      Create duplicate
                    </p>
                  </button>
                )}
              </div>
            </OutsideClickRef>
          )}
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={7}>
            <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
              <img src={placeholder} alt="placeholder" />
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  /**
   * An asynchronous function to retrieve promotions and update the state accordingly.
   *
   * @return {void}
   */
  const getPromotion = async () => {
    setLoading(true);
    let requestParams = {
      sort: sort,
      limit: pageSize,
      skip: currentPage,
    };

    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    if (startDate) {
      requestParams.sortingKey = startDate;
    }
    if (endDate) {
      requestParams.sortingKey = endDate;
    }
    if (selectedFilters) {
      requestParams.status = selectedFilters;
    }
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getPromotionList(requestParams)
      );

      if (error || !response) return;
      setPromotionList(response?.promotionList);
      setTotalDocs(response?.totalCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setCurrentPage(0);
    setDebounceValue(value);
  }, 500);

  /**
   * A function that handles the search input change
   *
   * @param {Event} e - the event object
   * @return {void}
   */
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    getPromotion();
    // eslint-disable-next-line
  }, [
    debounceValue,
    pageSize,
    sort,
    currentPage,
    startDate,
    endDate,
    selectedFilters,
  ]);

  return (
    <div className="py-5 w-full h-full overflow-y-auto pr-12.5">
      <div className="flex justify-between items-center bg-white p-5 rounded-20 gap-5">
        <div className="flex p-5 items-center gap-5 rounded-full bg-grey-50 w-full h-11.25">
          <img src={search} alt="searchIcon" className="opacity-60 w-5 h-5" />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            className="font-ManropeLight text-ft3 w-full bg-grey-50 text-black-300 focus:outline-none"
          />
          {!!searchQuery && (
            <img
              onClick={() => {
                setSearchQuery("");
                debouncedSearch("");
              }}
              src={Close}
              alt=""
              className="flex justify-end cursor-pointer  w-5 h-5"
            />
          )}
        </div>
        {userPermissions?.indexOf("promo:write:create") > -1 && (
          <div
            className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center relative cursor-pointer"
            onClick={() => {
              setCreatePopup(!createPopup);
            }}
          >
            <AddIcon />
          </div>
        )}
        {createPopup && (
          <OutsideClickRef
            state={createRef}
            setState={setCreatePopup}
            className={"absolute top-0 right-0 "}
          >
            <div className="flex flex-col absolute w-75 top-33.5 right-15 rounded-20 shadow-inner z-10 bg-white">
              <Link to="/promo-and-rewards/create-token">
                <button className="flex p-5 items-center gap-3.75">
                  <img src={token} alt="stop" />
                  <p className="text-ft3 font-ManropeRegular text-black-100">
                    Free token
                  </p>
                </button>
              </Link>
              <hr className="text-grey-50" />
              <Link to="/promo-and-rewards/create-content">
                <button className="flex p-5 items-center gap-3.75">
                  <img src={add} alt="stop" />
                  <p className="text-ft3 font-ManropeRegular text-black-100">
                    Create content
                  </p>
                </button>
              </Link>
              <hr className="text-grey-50" />
              <Link to="/promo-and-rewards/create-votes">
                <button className="flex p-5 items-center gap-3.75">
                  <img src={tick} alt="stop" />
                  <p className="text-ft3 font-ManropeRegular text-black-100">
                    Votes
                  </p>
                </button>
              </Link>
            </div>
          </OutsideClickRef>
        )}
      </div>
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        currentPage={currentPage}
        loading={loading}
        handlePageChange={handlePageChange}
        pageSize={pageSize}
        totalDocs={totalDocs}
        setCurrentPage={setCurrentPage}
        setSort={setSort}
        setPageSize={setPageSize}
        sort={sort}
        totalPages={totalPages}
        setStartDate={setStartDate}
        startDate={startDate}
        setEndDate={setEndDate}
        clickFilter={clickFilter}
        tagStart={"startDate"}
        setClickFilter={setClickFilter}
        renderFilter={renderFilter()}
        endDate={endDate}
      />
      {isDeletePopUp && (
        <WarningPopup
          title={`Delete ${selectedItem?.name}`}
          description={"Are you sure? You want to delete Ad"}
          handleActivateUser={() => handleDeletePromotion(selectedItem?.id)}
          setToggle={setDeletePopUp}
        />
      )}
      {isStopPopUp && (
        <WarningPopup
          title={`Stop ${selectedItem?.name}`}
          description={"Are you sure? You want to stop Ad"}
          handleActivateUser={() => handleStopPromotion(selectedItem?.id)}
          setToggle={setStopPopUp}
        />
      )}
    </div>
  );
};

export default PromotionsComponent;
