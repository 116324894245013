import React, { useRef, useState } from "react";
import Add from "../../../assets/Icons/Add.svg";
import Download from "../../../assets/Icons/document-download.svg";
import * as XLSX from "xlsx";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ShowToast from "../../../common/showToast";
import { toastType } from "../../../constant/commonArray";
import { UserService } from "../../../services";
import MistakePopup from "../../../common/mistakePopup";
import OutsideClickRef from "../../../common/outsideClickRef";
import utility from "../../../utility";
import { useSelector } from "react-redux";

/**
 * EntityUserInfo component displays information related to an entity or employees.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {string} props.keyName - The key name for conditional rendering (defaults or entity).
 * @param {Array<Object>} props.details - The array of details to be displayed.
 * @param {Function} props.setIsInfo - The function to set the information type (info or employee).
 * @param {string} props.info - The information type (info or employee).
 * @param {Function} props.setAddEmployee - The function to set the visibility of the "Add Employee" section.
 * @param {boolean} props.addEmployee - The flag indicating whether the "Add Employee" section is visible.
 * @param {Function} props.setAddEployeePopup - The function to set the visibility of the "Add Employee" popup.
 * @param {boolean} props.addEployeePopup - The flag indicating whether the "Add Employee" popup is visible.
 * @param {Function} props.getEntityDetailsData - The function to fetch entity details data.
 * @param {Object} props.entityDetails - The details of the entity.
 * @returns {JSX.Element} DetailsInfo component.
 */

const EntityUserInfo = ({
  setIsInfo = () => {},
  info = "info",
  setAddEmployee = () => {},
  addEmployee,
  setAddEployeePopup = () => {},
  addEployeePopup,
  getEntityDetailsData = () => {},
  entityDetails,
  permission,
}) => {
  const optionRef = useRef(null);
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const [popUp, setPopUp] = useState({
    popUp: false,
    data: "",
    count: 0,
  });
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    if (!file.name.endsWith(".xlsx")) {
      ShowToast({
        message: "Invalid file format. Please upload an Excel file (.xlsx).",
        type: toastType.ERROR,
      });
      return;
    }

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        const jsonDataWithId = jsonData.map((item) => ({
          ...item,
          entityId: id,
          name: item?.name,
          email: item?.email,
          countryCode: item?.countryCode,
          phone: item?.phone,
          staffType: item?.staffType?.toUpperCase()?.replace(/\s/g, "_"),
          role: item?.role?.toUpperCase()?.replace(/\s/g, "_"),
          jobPosition: item?.jobPosition,
        }));
        handleSave(jsonDataWithId);
      };
      reader.readAsBinaryString(file);
    }
  };
  const downloadErrorExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "error_data.xlsx");
    setPopUp({ popUp: false });
  };

  const handleSave = async (data) => {
    try {
      const res = await new UserService().addEmployee(data);
      if (res?.failedEmployees?.length > 0) {
        ShowToast({
          message: res?.failureMessage,
          type: toastType.ERROR,
        });
        setPopUp({
          popUp: true,
          data: res?.failedEmployees,
          count: res?.failedCount,
        });
      } else {
        ShowToast({
          message: res?.successMessage,
          type: toastType.SUCCESS,
        });
        getEntityDetailsData();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const getTextColor = (type) => {
    switch (type) {
      case "BUSINESS":
        return "Grey";
      case "GOVERNMENT":
        return "Green";
      default:
        return "Blue";
    }
  };

  const suspendedOnDate = entityDetails?.suspension?.period?.startDate
    ? new Date(entityDetails.suspension.period.startDate)
    : null;

  const suspendedTillDate = entityDetails?.suspension?.period?.endDate
    ? new Date(entityDetails.suspension.period.endDate)
    : null;

  const isSuspended = entityDetails?.suspension;
  const suspendedFromDate = isSuspended
    ? utility.formatDate(suspendedOnDate)
    : "";
  const suspendedTillDateText =
    isSuspended && entityDetails?.suspension?.period?.endDate !== 0
      ? ` - ${utility.formatDate(suspendedTillDate)}`
      : "";

  const suspensionStatus = isSuspended
    ? `Suspended ${suspendedFromDate}${suspendedTillDateText}`
    : "-";

  return (
    <div className="flex flex-col items-start  p-5 gap-5 rounded-20 bg-white">
      <div className="flex gap-3 xl:gap-10">
        <span
          onClick={() => setIsInfo("info")}
          className={` cursor-pointer ${
            info === "info"
              ? "font-ManropeBold text-ft4 xl:text-ft5 text-black-100 "
              : "font-ManropeMedium text-ft3 text-black-350"
          }`}
        >
          Info
        </span>
        <span
          onClick={() => setIsInfo("employee")}
          className={`cursor-pointer ${
            info === "employee"
              ? "font-ManropeBold text-ft4 xl:text-ft5 text-black-100 "
              : "font-ManropeMedium text-ft3 text-black-350"
          }`}
        >
          Employees
        </span>
      </div>

      {info === "info" ? (
        <div className="flex flex-col items-start w-full gap-5 rounded-20 bg-white">
          <div className="flex w-full gap-2 justify-between">
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Status
              </p>
              <div
                className={`flex items-center justify-center rounded-20 w-min px-2 py-1.25 ${
                  entityDetails?.status === "ACTIVE"
                    ? "bg-green-50"
                    : "bg-red-75"
                }`}
              >
                <p
                  className={`font-ManropeRegular text-ft2 text-black-100 ${
                    entityDetails?.status === "SUSPENDED"
                      ? "w-15 xl:w-auto truncate"
                      : "w-auto"
                  } `}
                >
                  {entityDetails?.status &&
                    entityDetails?.status.charAt(0).toUpperCase() +
                      entityDetails?.status.slice(1).toLowerCase()}
                </p>
              </div>
            </div>

            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Reports
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {entityDetails?.reports || 0} approved
              </p>
            </div>
          </div>
          {entityDetails?.status === "SUSPENDED" && (
            <div className="bg-red-75 rounded-xl p-5 w-full">
              <div className="flex flex-col gap-1 text-black-50 font-ManropeMedium text-ft1">
                <span>{suspensionStatus}</span>
                <div className="flex gap-1.25 flex-wrap">
                  {" "}
                  Suspended by:
                  <b>
                    <div className="break-all">
                      {entityDetails?.suspension?.suspenderName || "-"}{" "}
                    </div>
                  </b>
                </div>
                <span className="break-all">
                  {entityDetails?.suspension?.reason || "-"}
                </span>
              </div>
            </div>
          )}
          <div className="flex w-full gap-2 justify-between">
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Badge
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {getTextColor(entityDetails?.type)}
              </p>
            </div>
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Following
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {entityDetails?.following || 0}
              </p>
            </div>
          </div>
          <div className="flex w-full gap-2 justify-between">
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Followers
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {entityDetails?.followers || 0}
              </p>
            </div>
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Circles
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {entityDetails?.circles || 0}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full relative">
          {userPermissions?.indexOf(permission) > -1 && (
            <button
              onClick={() => setAddEmployee(!addEmployee)}
              className="font-ManropeBold w-full text-ft2 text-black-100 bg-orange-50 rounded-full h-12.5 mt-5"
            >
              + Add Employee
            </button>
          )}
          {addEmployee && (
            <div className="absolute right-1 top-20 w-auto left-1  rounded-20 shadow-inner z-10 bg-white">
              <OutsideClickRef
                state={optionRef}
                setState={() => setAddEmployee(false)}
              >
                <div className="w-full">
                  <button
                    onClick={() => setAddEployeePopup(!addEployeePopup)}
                    className="flex h-16.25 p-5 items-center gap-3.75 self-stretch"
                  >
                    <img src={Add} alt="Pause" />
                    <p className="text-ft3 font-ManropeLight text-black-100">
                      Manually
                    </p>
                  </button>
                </div>
                <div className="w-full  border-t border-grey-100">
                  <button
                    onClick={handleClick}
                    className="flex h-16.25 p-5  items-center gap-3.75 self-stretch"
                  >
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="hidden"
                      accept=".xlsx"
                      onChange={handleFileUpload}
                    />
                    <img src={Download} alt="Pause" />
                    <p className="text-ft3 font-ManropeLight text-black-100">
                      Upload EXL document
                    </p>
                  </button>
                </div>
              </OutsideClickRef>
            </div>
          )}
        </div>
      )}
      {popUp?.popUp && (
        <MistakePopup popUp={popUp} downloadErrorExcel={downloadErrorExcel} />
      )}
    </div>
  );
};

export default EntityUserInfo;
