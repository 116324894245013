/**
 * IncomeSplitList is a component that displays a list of income split rules.
 *
 * @component
 * @returns {JSX.Element} - The JSX element representing the IncomeSplitList.
 */

import React, { useEffect, useRef, useState } from "react";
import more from "../../assets/Icons/More.svg";
import OutsideClickRef from "../../common/outsideClickRef";
import Edit from "../../assets/Icons/Edit.svg";
import EditIncomePopup from "./editIncomePopup";
import { UserService } from "../../services";
import utility from "../../utility";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const IncomeSplitList = () => {
  /**
   * State to manage the selected user ID for displaying options.
   * @type {string|null}
   */
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [editRules, setEditRules] = useState({
    popup: false,
    data: [],
  });
  const optionRef = useRef();

  /**
   * Fetches the income data from the server.
   *
   * @async
   * @returns {void}
   */

  const getIncomeData = async () => {
    setLoading(true);

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getIncomeList()
      );

      if (error || !response) return;
      setData(response);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getIncomeData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  /**
   * Renders the component JSX.
   *
   * @returns {JSX.Element} - The JSX element representing the IncomeSplitList component.
   */

  return (
    <div className="w-full flex flex-col gap-3">
      {data?.map((item) => (
        <div
          key={item?._id}
          className="px-5 py-10 bg-white flex  rounded-20 gap-2"
        >
          <span className="text-ft3 font-ManropeBold text-black-150  w-50per">
            {utility.formatText(item?.name)} detailed results
          </span>

          {/* <div className="flex gap-10 w-25per">
            <span className="text-ft2 font-ManropeRegular text-grey-250">
              Unit price per vote, Dollar
            </span>
            <span className="font-ManropeBold text-ft2 text-black-150">
              {item?.unitPrice || "No rules"}
            </span>
          </div> */}
          <div className="flex gap-10 w-25per">
            <span className="text-ft2 font-ManropeRegular text-grey-250">
              Admin part %
            </span>
            <span className="font-ManropeBold text-ft2 text-black-150">
              {item?.adminShare || "No rules"}
            </span>
          </div>
          <div className="flex gap-10 w-25per">
            <span className="text-ft2 font-ManropeRegular text-grey-250">
              Creator %
            </span>
            <span className="font-ManropeBold text-ft2 text-black-150">
              {item?.creatorShare || "No rules"}
            </span>
          </div>
          <div className="relative">
            {userPermissions?.indexOf("income:write:details") > -1 && (
              <img
                onClick={() => setSelectedUserId(item?._id)}
                src={more}
                alt="More"
                className="cursor-pointer"
              />
            )}
            {selectedUserId === item?._id && (
              <OutsideClickRef
                state={optionRef}
                setState={() => setSelectedUserId(null)}
              >
                <div
                  onClick={() =>
                    setEditRules((prev) => ({
                      ...prev,
                      popup: true,
                      data: item,
                    }))
                  }
                  className="flex absolute top-10 right-0 rounded-20 bg-white shadow-inner p-5 z-20 w-75 gap-3.75 cursor-pointer"
                >
                  <img src={Edit} alt="Edit" />
                  <p className="text-black-100 font-ManropeRegular text-ft3">
                    Edit
                  </p>
                </div>
              </OutsideClickRef>
            )}
          </div>
        </div>
      ))}
      {editRules?.popup && (
        <EditIncomePopup
          editRules={editRules}
          setEditRules={setEditRules}
          getIncomeData={getIncomeData}
        />
      )}
    </div>
  );
};

export default IncomeSplitList;
