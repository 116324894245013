import React from "react";
import { ReactComponent as Delete } from "../../../../../../assets/Icons/delete.svg";
import ShowToast from "../../../../../../common/showToast";
import { toastType } from "../../../../../../constant/commonArray";
import { ReactComponent as AddImage } from "../../../../../../assets/Icons/plus-orange.svg";
import utility from "../../../../../../utility";

/**
 * TypeText component for managing text-based poll answers.
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.state - Component state object.
 * @param {Function} props.setState - Function to set component state.
 * @param {number} props.idx - Index of the poll category.
 * @param {Function} props.setErrorsPoll - Function to set poll errors.
 * @param {Array<Array<string>>} props.errorsPoll - Errors array for polls.
 * @returns {JSX.Element} TypeText component JSX.
 */

const TypeText = ({ state, setState, idx, setErrorsPoll, errorsPoll }) => {
  /**
   * Handles change in answers input field.
   * @param {Object} e - Event object.
   * @param {number} index - Index of the answer.
   */
  const handleAnswersChange = (e, index) => {
    const inputValue = e.target.value;
    setErrorsPoll((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[idx] = newErrors[idx] || [];
      newErrors[idx][index] =
        inputValue.length > 150
          ? "Answer should be less than 150 characters"
          : "";
      return newErrors;
    });
    setState((prev) => ({
      ...prev,
      polls: prev.polls.map((poll, i) =>
        i === idx
          ? {
              ...poll,
              options: {
                ...poll.options,
                answers: poll.options.answers.map((answer, j) =>
                  j === index ? { ...answer, value: e.target.value } : answer
                ),
              },
            }
          : poll
      ),
    }));
  };
  /**
   * Handles click event to delete an answer.
   * @param {number} index - Index of the answer to delete.
   */
  const handleDelete = (index) => {
    setState((prev) => ({
      ...prev,
      polls: prev.polls.map((poll, i) =>
        i === idx
          ? {
              ...poll,
              options: {
                ...poll.options,
                answers: poll.options.answers.filter((_, j) => j !== index),
              },
            }
          : poll
      ),
    }));
    setErrorsPoll((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[idx].splice(index, 1);
      return newErrors;
    });
  };
  return (
    <div className="flex flex-col gap-5">
      {state?.polls[idx]?.options?.answers?.map((item, index) => (
        <div className="flex gap-5 items-center" key={index}>
          <div className="flex flex-col gap-0.5 w-full">
            <div className="w-full rounded-full bg-grey-50 outline-none px-6 flex justify-between items-center h-16.25 text-black-100 font-ManropeRegular text-ft3">
              <input
                type="text"
                value={item.value}
                placeholder={`${utility.ordinalText(index + 1)} answer`}
                onChange={(e) => handleAnswersChange(e, index)}
                className="focus:outline-none bg-transparent w-95per"
              />
            </div>

            {errorsPoll[idx]?.[index] && (
              <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                {errorsPoll[idx]?.[index]}
              </div>
            )}
          </div>
          {state?.polls[idx]?.options?.answers?.length > 2 && (
            <Delete
              onClick={() => {
                handleDelete(index);
              }}
              className="cursor-pointer"
            />
          )}
        </div>
      ))}
      <div
        onClick={() => {
          if (state?.polls[idx]?.options?.answers?.length >= 6) {
            ShowToast({
              type: toastType.ERROR,
              message: "Can't add more than 6 answers",
            });
          } else {
            setState((prev) => ({
              ...prev,
              polls: prev.polls.map((poll, i) =>
                i === idx
                  ? {
                      ...poll,
                      options: {
                        ...poll.options,
                        answers: [
                          ...(poll.options.answers || []),
                          { value: "" },
                        ],
                      },
                    }
                  : poll
              ),
            }));
            setErrorsPoll((prevErrors) => {
              const newErrors = [...prevErrors];
              newErrors[idx] = [...(newErrors[idx] || []), ""];
              return newErrors;
            });
          }
        }}
        className="flex gap-2.5 items-center cursor-pointer pt-5"
      >
        <AddImage />
        <span className="text-orange-50 font-ManropeBold text-ft2">
          Add answer
        </span>
      </div>
    </div>
  );
};

export default TypeText;
