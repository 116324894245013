import React, { useEffect, useRef, useState } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Poll from "../../../common/Poll";
import ShowToast from "../../../common/showToast";
import { toastType } from "../../../constant/commonArray";
import { UserService } from "../../../services";
import utility from "../../../utility";
import PollHeader from "../../DashboardPage/pollDetails/pollHeader";
import { CircularProgress } from "@mui/material";
import DisablePopUp from "../../../common/disablePopUp";
import AddEmployee from "../../../common/addEmployee";
import DetailsGeneralInfo from "./detailsGeneralInfo";
import DetailsBalance from "../../../common/detailsBalance";
import DetailsProfile from "../../../common/detailsProfile";
import tick from "../../../assets/Icons/Tick.svg";
import pause from "../../../assets/Icons/pause.svg";
import time from "../../../assets/Icons/Time.svg";
import SuspendedUserPopUp from "../../DashboardPage/details/SuspendedUserPopUp";
import WarningPopup from "../../../common/warningPopup";
import OutsideClickRef from "../../../common/outsideClickRef";
import EmployeeList from "./employeeList";
import EntityUserInfo from "./entityUserInfo";
import { useSelector } from "react-redux";
import WalletService from "../../../services/walletService";
import BlockChainService from "../../../services/blockChainService";
import PollReposts from "../../../common/pollReposts";
import PollVoted from "../../../common/pollVoted";
import PollComment from "../../../common/pollComment";
import PollLikes from "../../../common/pollLikes";

/**
 * Component for rendering details of a specific entity in a poll.
 *
 * @component
 * @example
 * // Example usage of EntityPollDetailsComponent
 * <EntityPollDetailsComponent />
 */

const EntityPollDetailsComponent = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const { id } = useParams();
  const location = useLocation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userId = queryParams.get("userId");
  const [pollState, setPollState] = useState(
    location?.state?.state?.pollState || "polls"
  );
  const [likeList, setLikesList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [votesList, setVotesList] = useState([]);
  const [repostList, setRepostList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [seenActivatePopUp, setSeenActivatePopUp] = useState(false);
  const [pollDetails, setPollDetails] = useState([]);
  const [isDisablePopUp, setDisablePopUp] = useState(false);
  const [isInfo, setIsInfo] = useState("info");
  const [showClickableOption, setShowClickableOption] = useState("");
  const [isGeneralExpanded, setGeneralExpanded] = useState(false);
  const [isUserExpanded, setUserExpanded] = useState(false);
  const [entityDetails, setEntityDetails] = useState([]);
  const [valueStaff, setValueStaff] = useState("Company staff");
  const [addEmployeePopup, setAddEployeePopup] = useState(false);
  const [valueRole, setValueRole] = useState(null);
  const [valuePosition, setValuePosition] = useState(null);
  const [suspendType, setSuspendType] = useState("");
  const [addEmployee, setAddEmployee] = useState(false);
  const [valueDepartment, setValueDepartment] = useState(null);
  const [isCommentId, setIsCommentId] = useState("");
  const [userDetailsLoading, setuserDetailsLoading] = useState(false);
  const [isCommentDisable, setIsCommentDisable] = useState(false);
  const [creatingEmployee, setCreatingEmployee] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [balance, setBalance] = useState(null);
  const optionsRef = useRef(null);
  const [popUpVisible, setPopUpVisible] = useState({
    popUp: false,
    keyName: "",
  });
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * Handles page change in the paginated table.
   * @function
   * @name handlePageChange
   * @param {number} pageNumber - The selected page number.
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getEntityDetailsData = async () => {
    setuserDetailsLoading(true);
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().getEntityDetails(userId)
      );
      if (err || !res) return;
      setEntityDetails(res);
      const [error, response] = await utility.parseResponse(
        new UserService().userPollDetails(id)
      );
      if (error || !response) return;
      setPollDetails(response);

      setuserDetailsLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setuserDetailsLoading(false);
    }
  };

  const getUserDetails = async () => {
    setIsLoading(true);
    const requestData = {
      limit: pageSize,
      skip: currentPage,
    };

    try {
      switch (pollState) {
        case "likes":
          const [errLikes, resLikes] = await utility.parseResponse(
            new UserService().getUserLikes(id, requestData)
          );
          if (errLikes || !resLikes) return;
          setLikesList(resLikes[0]?.likedUsers);
          setTotalDocs(resLikes[0]?.totalCount);
          break;
        case "voted":
          const [errVotes, resVotes] = await utility.parseResponse(
            new UserService().getUserVotes(id, requestData)
          );
          if (errVotes || !resVotes) return;
          setVotesList(resVotes[0]?.votedUsers);
          setTotalDocs(resVotes[0]?.totalCount);
          break;
        case "reposts":
          const [errRepost, resRepost] = await utility.parseResponse(
            new UserService().getUserRepost(id, requestData)
          );

          if (errRepost || !resRepost) return;
          setRepostList(resRepost[0]?.repostedUsers);
          setTotalDocs(resRepost[0]?.totalCount);

          break;
        case "comments":
          const [errComment, resComment] = await utility.parseResponse(
            new UserService().getUserComment(id, requestData)
          );
          if (errComment || !resComment) return;
          setCommentList(resComment[0]?.comments);
          setTotalDocs(resComment[0]?.totalCount);

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateData = () => {
    getUserDetails();
    getEntityDetailsData();
  };

  const handleMoreClick = (id) => {
    if (selectedUser === id) {
      setSelectedUser(null);
    } else {
      setSelectedUser(id);
    }
  };

  /**
   * Renders profile dots based on user status and expanded state.
   *
   * @param {string} status - User status.
   * @returns {JSX.Element | null} - JSX for profile dots.
   */

  const renderProfileDots = (status) => {
    return status === "ACTIVE" && isUserExpanded ? (
      <OutsideClickRef
        state={optionsRef}
        setState={() => setUserExpanded(false)}
      >
        {userPermissions?.indexOf("business:write:suspend") > -1 && (
          <div
            onClick={() => togglePopUpVisble("suspend")}
            className="absolute right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-20 bg-white cursor-pointer"
          >
            <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
              <img src={pause} alt="Pause" />
              <p className="text-ft3 font-ManropeLight text-black-100">
                Suspend
              </p>
            </button>
          </div>
        )}
      </OutsideClickRef>
    ) : (
      isUserExpanded && status === "SUSPENDED" && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setUserExpanded(false)}
        >
          <div className="absolute flex flex-col right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-20 bg-white">
            {userPermissions?.indexOf("business:write:activate") > -1 && (
              <button
                onClick={() => setSeenActivatePopUp(!seenActivatePopUp)}
                className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
              >
                <img src={tick} alt="tick" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  Activate
                </p>
              </button>
            )}
            {userPermissions?.indexOf("business:write:suspend") > -1 && (
              <button
                className="flex h-16.25 px-5 items-center gap-3.75 shadow-custom"
                onClick={() => togglePopUpVisble("edit")}
              >
                <img src={time} alt="time" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  Edit Suspended time
                </p>
              </button>
            )}
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Toggles the visibility of the popup.
   *
   * @param {string} value - Key value for the popup.
   */
  const togglePopUpVisble = (value) => {
    setPopUpVisible((prev) => ({
      ...prev,
      popUp: !popUpVisible.popUp,
      keyName: value,
    }));
    setUserExpanded(false);
  };

  /**
   * Handles the save event for creating a new employee.
   *
   * @param {Object} data - Employee data.
   */

  const handleSave = async (data) => {
    setCreatingEmployee(true);
    const newEmployee = {
      entityId: userId,
      name: data?.name,
      email: data?.email,
      countryCode: data?.countryCode,
      phone: data?.phone,
      staffType: valueStaff.toUpperCase().replace(/\s/g, "_"),
      role: valueRole.toUpperCase().replace(/\s/g, "_"),
      jobPosition: valuePosition,
    };
    try {
      const res = await new UserService().addEmployee([newEmployee]);
      if (res?.failedEmployees?.length > 0) {
        ShowToast({
          message: res?.failureMessage,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: res?.successMessage,
          type: toastType.SUCCESS,
        });
      }
      setValueRole(null);
      setValuePosition(null);
      setValueDepartment(null);
      setAddEployeePopup(false);
      setCreatingEmployee(false);
      getEntityDetailsData();
    } catch (e) {
      console.error(e);
      setCreatingEmployee(false);
    } finally {
      setCreatingEmployee(false);
    }
  };

  /**
   * Handles the activate user event.
   *
   * @param {string} id - User ID.
   */
  const handleActivateUser = async (id) => {
    let response;
    setUpdatingUser(true);
    try {
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getEntityDetailsData();
      setSeenActivatePopUp(false);
      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };

  const getAddress = async () => {
    try {
      const [err, res] = await utility.parseResponse(
        new WalletService().getAddress(userId)
      );
      if (err || !res) return;

      const [error, response] = await utility.parseResponse(
        new BlockChainService().getBalance(res?.responseData?.publicKey)
      );
      if (error && !response) return;

      setBalance(response?.balance);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const renderPollComponent = () => {
    switch (pollState) {
      case "polls":
        return (
          <Poll
            pollState={pollState}
            details={pollDetails}
            loading={isLoading}
          />
        );
      case "likes":
        return (
          <PollLikes
            loading={isLoading}
            likes={likeList}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
          />
        );
      case "comments":
        return (
          <PollComment
            loading={isLoading}
            comment={commentList}
            permission={"business:write:comment"}
            setIsCommentId={setIsCommentId}
            setIsCommentPopUp={setIsCommentDisable}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
          />
        );
      case "reposts":
        return (
          <PollReposts
            loading={isLoading}
            repost={repostList}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
          />
        );
      case "voted":
        return (
          <PollVoted
            loading={isLoading}
            votes={votesList}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages - 1}
            totalDocs={totalDocs}
            setCurrentPage={setCurrentPage}
          />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    getAddress();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (entityDetails) {
      const suspend = utility.getSuspensionType(
        entityDetails?.suspension?.type,
        entityDetails?.suspension?.period?.startDate,
        entityDetails?.suspension?.period?.endDate
      );
      setSuspendType(suspend);
    }
  }, [entityDetails]);

  useEffect(() => {
    getEntityDetailsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line
  }, [pollState, pageSize, currentPage]);

  if (userDetailsLoading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div className="w-full h-full flex gap-5 overflow-y-auto pr-12.5">
      <div className="flex flex-col w-75per">
        <PollHeader
          handleMoreClick={handleMoreClick}
          pollState={pollState}
          setPollState={setPollState}
          details={pollDetails}
          setDisablePopUp={setDisablePopUp}
          setShowClickableOption={setShowClickableOption}
          showClickableOption={showClickableOption}
          url={"/entity-account/details"}
          permission={"business:write:poll"}
          setCurrentPage={setCurrentPage}
        />

        {renderPollComponent()}
      </div>
      <div className="flex flex-col gap-5 relative w-25per mt-5">
        <DetailsProfile
          name={entityDetails?.fullName}
          username={entityDetails?.userName}
          setUserExpanded={setUserExpanded}
          isUserExpanded={isUserExpanded}
          renderPopUp={renderProfileDots(entityDetails?.status)}
          profile={entityDetails?.profilePhoto}
          profileType={entityDetails?.type}
          keyName="entity"
          permissionActive={"business:write:activate"}
          permissionSuspend={"business:write:suspend"}
          status={entityDetails?.status}
        />
        <EntityUserInfo
          setIsInfo={setIsInfo}
          info={isInfo}
          setAddEmployee={setAddEmployee}
          addEmployee={addEmployee}
          addEmployeePopup={addEmployeePopup}
          setAddEployeePopup={setAddEployeePopup}
          getEntityDetailsData={getEntityDetailsData}
          entityDetails={entityDetails}
          permission={"business:write:employee:add"}
        />
        {isInfo === "info" && (
          <div>
            <DetailsBalance
              price={balance || 0}
              fontSize={"text-ft6"}
              rewardAmount={entityDetails?.rewardAmount || 0}
            />
          </div>
        )}

        {isInfo === "info" ? (
          <div className="relative">
            <DetailsGeneralInfo
              isDropDownOpen={isDropDownOpen}
              setIsDropDownOpen={setIsDropDownOpen}
              setGeneralExpanded={setGeneralExpanded}
              isGeneralExpanded={isGeneralExpanded}
              details={entityDetails}
              stateId={userId}
              getEntityDetailsData={getEntityDetailsData}
            />
          </div>
        ) : (
          <EmployeeList
            id={userId}
            permissionDelete={"business:write:employee:remove"}
            permissionEdit={"business:write:employee:edit"}
          />
        )}

        {addEmployeePopup && (
          <AddEmployee
            setAddEployeePopup={setAddEployeePopup}
            setValueRole={setValueRole}
            setValueStaff={setValueStaff}
            setValuePosition={setValuePosition}
            setValueDepartment={setValueDepartment}
            valueRole={valueRole}
            valueStaff={valueStaff}
            valueDepartment={valueDepartment}
            valuePosition={valuePosition}
            handleSave={handleSave}
            loadingState={creatingEmployee}
          />
        )}
      </div>{" "}
      {popUpVisible.popUp && popUpVisible.keyName === "suspend" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={entityDetails?.userName}
          id={entityDetails?._id}
          getdetails={getEntityDetailsData}
        />
      )}
      {popUpVisible.popUp && popUpVisible.keyName === "edit" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={entityDetails?.userName}
          id={entityDetails?._id}
          keyName={popUpVisible.keyName}
          getdetails={getEntityDetailsData}
          type={suspendType}
          endDate={entityDetails?.suspension?.period?.endDate}
          reasonType={entityDetails?.suspension?.reason}
          name={entityDetails?.fullName}
          setActivatePopUp={setSeenActivatePopUp}
        />
      )}
      {seenActivatePopUp && (
        <WarningPopup
          handleActivateUser={() => handleActivateUser(entityDetails?._id)}
          title={`Activate ${entityDetails?.userName} ?`}
          description={
            "User will be activated. All created content will be available for other users"
          }
          setToggle={() => setSeenActivatePopUp(false)}
          text={`${entityDetails?.fullName} activated`}
          loading={updatingUser}
        />
      )}
      {isDisablePopUp && (
        <DisablePopUp
          setToggle={setDisablePopUp}
          keyName={"post"}
          selectedId={id}
          getDetails={updateData}
          updateUser={getEntityDetailsData}
          suspendedState={entityDetails?.status}
          suspendedName={entityDetails?.fullName}
        />
      )}
      {isCommentDisable && (
        <DisablePopUp
          setToggle={setIsCommentDisable}
          keyName={"comment"}
          selectedId={isCommentId}
          getDetails={getUserDetails}
          name="Disable comment"
          updateUser={getEntityDetailsData}
          suspendedState={entityDetails?.status}
          suspendedName={entityDetails?.fullName}
        />
      )}
    </div>
  );
};

export default EntityPollDetailsComponent;
