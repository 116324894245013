import React, { useRef, useState } from "react";
import UpArrow from "../../assets/Icons/up-arrow.svg";
import DownArrow from "../../assets/Icons/down-arrow.svg";
import CountryList from "country-list-with-dial-code-and-flag";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
import OutsideClickRef from "../outsideClickRef";
import { Popover, TextField } from "@mui/material";

/**
 * PhoneNumberDropdown component renders a dropdown for selecting phone numbers based on country dial codes.
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.state - The current selected dial code.
 * @param {Function} props.setState - The function to update the selected dial code.
 * @returns {JSX.Element} The PhoneNumberDropdown component.
 */
const PhoneNumberDropdown = ({ state, setState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);

  let arr = CountryList.getAll();
  arr.sort(
    (a, b) => parseInt(a.data.dial_code, 10) - parseInt(b.data.dial_code, 10)
  );
  const uniqueValuesMap = new Map();
  arr.forEach((item) => {
    uniqueValuesMap.set(item.data.dial_code, item.data);
  });

  const uniqueValues = Array.from(uniqueValuesMap.values());
  const country = CountryList?.findOneByDialCode(state);
  const flagSvg = CountryFlagSvg[country?.code];

  const [rolePopoverAnchorEl, setRolePopoverAnchorEl] = useState(null);

  const handleRolePopoverOpen = (event) => {
    setRolePopoverAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleRolePopoverClose = () => {
    setRolePopoverAnchorEl(null);
    setIsOpen(false);
    setSearchQuery("");
  };

  const filteredCountries = uniqueValues.filter((option) =>
    option.dial_code.includes(searchQuery)
  );

  const rolePopoverOpen = Boolean(rolePopoverAnchorEl);
  const rolePopoverId = rolePopoverOpen ? "role-popover" : undefined;

  return (
    <div className="rounded-full bg-grey-50 h-16.25 flex items-center">
      <div className="flex w-full gap-4 items-center">
        <img
          src={`data:image/svg+xml;base64,${btoa(flagSvg)}`}
          alt="countryFlag"
          className="w-4 h-4"
        />
        <div className="flex flex-col gap-1">
          <div className="relative">
            <OutsideClickRef state={dropdownRef} setState={setIsOpen}>
              <div
                onClick={(event) => {
                  handleRolePopoverOpen(event);
                }}
              >
                <div className="w-18 h-full flex justify-between items-center cursor-pointer">
                  {state ? (
                    <div
                      className={` h-full flex items-center gap-3 text-black-100 `}
                    >
                      <span> {state}</span>
                    </div>
                  ) : (
                    <span className="text-ft3 text-black-75 w-full h-full flex items-center font-ManropeRegular">
                      Not chosen
                    </span>
                  )}
                  {!isOpen ? (
                    <img src={DownArrow} alt="" className="w-6 h-6" />
                  ) : (
                    <img src={UpArrow} alt="" className="w-6 h-6" />
                  )}
                </div>
                {isOpen && (
                  <Popover
                    id={rolePopoverId}
                    open={isOpen}
                    anchorEl={rolePopoverAnchorEl}
                    onClose={handleRolePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    style={{ height: "30%" }}
                  >
                    <div className="py-1 flex flex-col">
                      <TextField
                        variant="standard"
                        size="small"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        fullWidth
                        className="px-2 w-20"
                        onClick={(e) => e.stopPropagation()}
                        InputProps={{
                          disableUnderline: true, 
                          style: {
                            border: "none", 
                            padding: "4px 0 0 4px", 
                          },
                        }}
                        inputProps={{
                          style: {
                            fontSize: "0.875rem", 
                          },
                        }}
                      />

                      {filteredCountries.length > 0 ? (
                        filteredCountries.map((option, idx) => (
                          <span
                            key={idx}
                            className={`w-full px-2 py-1 hover:bg-grey-350 text-black-150 cursor-pointer ${
                              idx === filteredCountries.length - 1
                                ? ""
                                : "border-b border-grey-50"
                            }`}
                            onClick={() => {
                              setState(option.dial_code);
                              setIsOpen(false);
                              setSearchQuery("");
                            }}
                          >
                            {option.dial_code}
                          </span>
                        ))
                      ) : (
                        <span className="px-2 py-1 text-grey-100">
                          No match found
                        </span>
                      )}
                    </div>
                  </Popover>
                )}
              </div>
            </OutsideClickRef>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberDropdown;
