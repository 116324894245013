import React, { useEffect, useRef, useState } from "react";
import {
  eventConstants,
  failureMessage,
  toastType,
} from "../../constant/commonArray";
import { ReactComponent as Close } from "../../assets/Icons/close.svg";
import { ReactComponent as AddIcon } from "../../assets/Icons/AddOrange.svg";
import { ReactComponent as Delete } from "../../assets/Icons/delete.svg";
import ShowToast from "../../common/showToast";
import { FileUploadService, UserService } from "../../services";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { CircularProgress } from "@mui/material";
import utility from "../../utility";
import { useDropzone } from "react-dropzone";

/**
 * CreateGroupComponent allows users to create or edit a group of channels.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.closePopup - Function to close the group creation popup.
 * @param {Function} props.getChannelList - Function to fetch the channel list.
 * @param {Object} props.data - Data of the group (if editing an existing group).
 * @param {string} props.keyName - Key indicating if the action is to "create" or "edit" a group.
 * @returns {JSX.Element} - The JSX element representing the CreateGroupComponent.
 */

const CreateGroupComponent = ({
  closePopup,
  getChannelList = () => {},
  data,
  keyName = "create",
}) => {
  const dispatch = useDispatch();
  const [isGroupAvailable, setIsGroupAvailable] = useState(false);
  const [debounceValue, setDebounceValue] = useState("");
  const [creating, setCreating] = useState(false);
  const [icons, setIcons] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    groupName: data?.groupName || "",
    language: data?.language || "English",
    icon: data?.icon || "",
  });
  const [error, setError] = useState({});

  const fileInputRef = useRef(null);

  /**
   * Handle the dropped files, check for file errors, and update the files state.
   *
   * @param {array} acceptedFiles - array of accepted files
   * @param {array} fileRejections - array of rejected files
   * @return {void}
   */
  const { getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 5 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED,
          type: toastType.ERROR,
        });
      } else {
        const updatedFiles = [...files, ...acceptedFiles];
        setFiles(updatedFiles);
      }
    },
  });

  /**
   * Handle the click event on the span element.
   *
   */
  const handleSpanClick = () => {
    fileInputRef.current.click();
  };

  /**
   * A function that handles the delete click event.
   *
   * @param {number} index - the index of the item to delete
   * @return {void}
   */
  const handleDeleteClick = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  /**
   * Asynchronously handles the creation of a group.
   *
   * @param {string} groupName - The name of the group to be created
   * @return {void}
   */
  const handleCreateGroup = async (groupName) => {
    setCreating(true);
    let url = "";
    try {
      if (files?.length > 0) {
        url = await handleEditIcon();
      }
      const requestData = {
        ...state,
        icon: url || state.icon,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().createGroup(requestData)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: "Group of channels '" + groupName + "' added",
          type: toastType.SUCCESS,
        });
        getChannelList();
        closePopup();
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    } finally {
      setCreating(false);
    }
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 300);

  /**
   * Asynchronously checks the group name availability and sets the group availability state.
   *
   * @param {string} name - The name of the group to be checked
   * @return {void}
   */
  const checkGroupName = async (name) => {
    try {
      const requestData = {
        groupName: name,
      };
      dispatch({ type: eventConstants.SHOW_LOADER });
      const [error, res] = await utility.parseResponse(
        new UserService().checkGroup(requestData)
      );
      if (error || !res) {
        setIsGroupAvailable(false);
      } else {
        if (res?.status) {
          setIsGroupAvailable(false);
        } else {
          setIsGroupAvailable(true);
        }
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  /**
   * Handles the group name input change and triggers a debounced search.
   *
   * @param {Event} e - the event object
   * @return {Promise<void>} a promise that resolves to undefined
   */
  const handleGroupName = async (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 50) {
      setError((prev) => ({ ...prev, nameError: false }));
    } else {
      setError((prev) => ({ ...prev, nameError: true }));
    }
    setState((prev) => ({ ...prev, groupName: e.target.value }));
    debouncedSearch(e.target.value);
    setIsGroupAvailable(false);
  };

  /**
   * Handles the editing of a group.
   *
   * @param {}
   * @return {}
   */
  const handleEditGroup = async () => {
    setCreating(true);
    let url = "";
    try {
      if (files?.length > 0) {
        url = await handleEditIcon();
      }
      const requestData = {
        ...state,
        icon: url || state.icon,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().editGroup(data?.groupId, requestData)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getChannelList();
        closePopup();
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    } finally {
      setCreating(false);
    }
  };

  /**
   * Function to asynchronously retrieve an icon list and update the state with the response.
   *
   * @param {void} - No parameters
   * @return {void} - No return value
   */
  const getIconList = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().channelIconList()
      );

      if (error || !response) return;
      setIcons(response);

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  /**
   * This function handles the edit icon asynchronously. It sets the creating state to true,
   * then tries to upload a file, create an icon, and display a toast message based on the
   * response. Finally, it sets the creating state to false.
   *
   * @return {Promise<string>} The CDN URL of the uploaded file.
   */
  const handleEditIcon = async () => {
    try {
      const formData = new FormData();
      formData.append("files", files[0]);

      const [err, res] = await utility.parseResponse(
        new FileUploadService().fileUpload(formData)
      );

      if (err || !res) return;
      const requestData = {
        url: res?.responseData[0]?.cdnUrl,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().createIcon(requestData)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }

      return res?.responseData[0]?.cdnUrl;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!!debounceValue) checkGroupName(debounceValue);

    // eslint-disable-next-line
  }, [debounceValue]);

  useEffect(() => {
    getIconList();
  }, []);

  const isGroupNameEmpty = state?.groupName?.trim() === "";
  const isFilesEmpty = files?.length === 0 && !state?.icon;
  const isEditingSameGroup =
    keyName === "edit" &&
    state?.groupName?.trim() === data?.groupName?.trim() &&
    state?.language === data?.language &&
    state?.icon === data?.icon &&
    files?.length === 0;
  const isGroupAvailableMismatch =
    isGroupAvailable &&
    state?.groupName?.toLowerCase() !== data?.groupName?.toLowerCase();

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 flex justify-center items-center fixed w-full h-full right-0 top-0">
      {!loading ? (
        <div className="flex flex-col justify-between bg-white w-112.5 h-155 shadow-md rounded-20 p-7.5 gap-5">
          <div className="flex flex-col gap-5 overflow-y-scroll scrollbar-hide">
            <div className="flex justify-between">
              <p className="text-black-100 font-ManropeBold text-ft4">
                {`${
                  keyName === "edit" ? "Edit group of" : "Create new group of"
                } channels`}
              </p>
              <Close className="cursor-pointer" onClick={closePopup} />
            </div>
            {/* <div className="flex flex-col gap-2 mt-1">
              <p className="text-black-100 font-ManropeMedium text-ft3">
                Languages
              </p>
              <div className="flex gap-2.5">
                <img
                  src={ArrowLeft}
                  alt="left"
                  onClick={() => scrollContainer("left")}
                  className="cursor-pointer"
                />
                <div
                  ref={containerRef}
                  className="flex gap-2.5 overflow-x-scroll scrollbar-hide"
                >
                  {Languages.map((value) => (
                    <div
                      onClick={() =>
                        setState((prev) => ({ ...prev, language: value?.name }))
                      }
                      key={value.name}
                      className={`flex items-center rounded-20 px-2 py-0.75 text-ft2 text-black-100 cursor-pointer ${
                        value.name === state?.language
                          ? "bg-orange-50 font-ManropeBold"
                          : "bg-grey-50 font-ManropeRegular"
                      }`}
                    >
                      {value.name}
                    </div>
                  ))}
                </div>
                <img
                  src={ArrowRight}
                  alt="right"
                  onClick={() => scrollContainer("right")}
                  className="cursor-pointer"
                />
              </div>
            </div> */}
            <div className="flex flex-col mt-1">
              <p>Name of channel</p>
              <input
                value={state?.groupName}
                type="text"
                onChange={handleGroupName}
                className={`w-full h-16.25 px-6.25 rounded-full bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none ${
                  isGroupAvailable &&
                  state?.groupName?.toLowerCase() !==
                    data?.groupName?.toLowerCase()
                    ? "border-1 border-orange-100"
                    : ""
                }`}
              />
              {isGroupAvailable &&
                state?.groupName?.toLowerCase() !==
                  data?.groupName?.toLowerCase() && (
                  <p className="text-orange-100 font-ManropeRegular text-ft2">
                    This group name already exists
                  </p>
                )}
              {error?.nameError && !isGroupAvailable && (
                <span className=" text-red-50">
                  Group name can't be more than 50
                </span>
              )}
            </div>
            <div className="flex flex-col mt-1">
              <p className="text-black-100 font-ManropeMedium text-ft3">Icon</p>
              <div className="flex gap-3 flex-wrap">
                {icons.map((value, idx) => (
                  <div
                    key={idx}
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        icon: value?.url,
                      }))
                    }
                    className={`flex w-13 h-13 p-1 items-center justify-center rounded-full cursor-pointer ${
                      state?.icon === value?.url && files?.length <= 0
                        ? "border-1 border-orange-100"
                        : ""
                    }`}
                  >
                    <img
                      src={value.url}
                      alt="icon"
                      className="w-11.25 h-11.25 rounded-20"
                    />
                  </div>
                ))}
              </div>
            </div>
            {files?.length > 0 ? (
              <div className="flex gap-3 flex-wrap mt-3">
                <div className="flex gap-1 flex-col ">
                  <span className="text-black-100 font-ManropeMedium text-ft3">
                    Upload Icon
                  </span>

                  <div className="w-13 h-13 p-1 rounded-full relative border-1 border-orange-100">
                    <img
                      src={URL.createObjectURL(files[0])}
                      alt=""
                      className="flex w-10.75 h-10.75 rounded-full"
                    />
                    <div className="absolute flex justify-center rounded-20 items-center right-2 top-2  w-9 h-9">
                      <Delete
                        onClick={() => handleDeleteClick(0)}
                        className="w-4 h-4 cursor-pointer bg-white bg-opacity-40 rounded-20"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                onClick={handleSpanClick}
                className="flex gap-2.5 items-center cursor-pointer"
              >
                <AddIcon />
                <p className="text-orange-50 font-ManropeBold text-ft2">
                  Add Icon
                </p>
                <input
                  {...getInputProps()}
                  ref={fileInputRef}
                  key={files.length}
                  type="file"
                  className="hidden"
                  accept=".png, .jpg, .jpeg"
                />
              </div>
            )}
          </div>
          <button
            disabled={
              isGroupNameEmpty ||
              isFilesEmpty ||
              isEditingSameGroup ||
              isGroupAvailableMismatch ||
              creating ||
              Object.values(error).some((value) => value !== false)
            }
            className={`flex items-center justify-center rounded-full h-16.25 font-ManropeBold text-ft3 ${
              isGroupNameEmpty ||
              isFilesEmpty ||
              isEditingSameGroup ||
              isGroupAvailableMismatch ||
              creating ||
              Object.values(error).some((value) => value !== false)
                ? " bg-grey-50 text-grey-250"
                : "cursor-pointer bg-orange-50 text-black-100"
            }`}
            onClick={
              keyName === "edit"
                ? handleEditGroup
                : () => {
                    handleCreateGroup(state?.groupName);
                  }
            }
          >
            Save
          </button>
        </div>
      ) : (
        <div className="flex flex-col justify-between bg-white w-112.5 h-155 shadow-md rounded-20 p-7.5 gap-5">
          <div className="flex justify-center  items-center h-full w-full">
            <CircularProgress className="text-black-100 w-5 h-5" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateGroupComponent;
