import React, { useState } from "react";
import ChartWithLine from "./components/lineChart";

/**
 * Polls component displays charts for polls, surveys, and votes based on the selected type.
 *
 * @component
 * @example
 * // Example usage of Polls component
 * <Polls
 *   data={{
 *     polls: {
 *       pollsByDate: [
 *         { date: "2022-01-01", value: 10 },
 *         { date: "2022-01-02", value: 20 },
 *         // ...more poll data
 *       ],
 *     },
 *     surveys: {
 *       surveysByDate: [
 *         { date: "2022-01-01", value: 5 },
 *         { date: "2022-01-02", value: 15 },
 *         // ...more survey data
 *       ],
 *     },
 *     votes: {
 *       votesByDate: [
 *         { date: "2022-01-01", value: 8 },
 *         { date: "2022-01-02", value: 18 },
 *         // ...more vote data
 *       ],
 *     },
 *   }}
 * />
 *
 * @param {Object} data - Data object containing poll, survey, and vote information.
 *
 * @returns {JSX.Element} The rendered Polls component.
 */

const Polls = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState("POLL");

  const renderChartComponent = (color) => {
    switch (activeIndex) {
      case "POLL":
        return <ChartWithLine data={data?.polls?.pollsByDate} color={color} />;
      case "SURVEY":
        return (
          <ChartWithLine data={data?.surveys?.surveysByDate} color={color} />
        );
      default:
        return <ChartWithLine data={data?.votes?.votesByDate} color={color} />;
    }
  };

  return (
    <div className="flex flex-col p-5 gap-10 w-full h-509px rounded-20 bg-white">
      <div className="flex gap-10 items-center">
        <p
          onClick={() => setActiveIndex("POLL")}
          className={`cursor-pointer ${
            "POLL" === activeIndex
              ? "text-black-100 font-ManropeBold text-ft5"
              : "text-black-75 font-ManropeMedium text-ft3"
          }`}
        >
          Polls
        </p>
        <p
          onClick={() => setActiveIndex("SURVEY")}
          className={`cursor-pointer ${
            "SURVEY" === activeIndex
              ? "text-black-100 font-ManropeBold text-ft5"
              : "text-black-75 font-ManropeMedium text-ft3"
          }`}
        >
          Survey
        </p>
        <p
          onClick={() => setActiveIndex("VOTES")}
          className={`cursor-pointer ${
            "VOTES" === activeIndex
              ? "text-black-100 font-ManropeBold text-ft5"
              : "text-black-75 font-ManropeMedium text-ft3"
          }`}
        >
          Votes
        </p>
      </div>
      {renderChartComponent("#EBC9F1")}
    </div>
  );
};

export default Polls;
