import React, { useState } from "react";
import ChartWithLine from "./components/lineChart";

/**
 * Users component displays charts representing user or business accounts over time.
 *
 * @component
 * @example
 * // Example usage of Users component
 * <Users
 *   data={{
 *     userAccounts: {
 *       usersByDate: [
 *         { addedOn: "2022-01-01T12:00:00", date: "2022-01-01", value: 100 },
 *         // ...more user data
 *       ],
 *     },
 *     businessAccounts: {
 *       entitiesByDate: [
 *         { addedOn: "2022-01-01T12:00:00", date: "2022-01-01", value: 50 },
 *         // ...more business data
 *       ],
 *     },
 *   }}
 * />
 *
 * @param {Object} data - Data object containing information about user and business accounts.
 *
 * @returns {JSX.Element} The rendered Users component.
 */

const Users = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState("USER");

  return (
    <div className="flex flex-col p-5 gap-10 w-full h-509px rounded-20 bg-white">
      <div className="flex gap-10 items-center">
        <p
          onClick={() => setActiveIndex("USER")}
          className={`cursor-pointer ${
            "USER" === activeIndex
              ? "text-black-100 font-ManropeBold text-ft5"
              : "text-black-75 font-ManropeMedium text-ft3"
          }`}
        >
          Users
        </p>
        {/* <p
          onClick={() => setActiveIndex("BUSINESS")}
          className={`cursor-pointer ${
            "BUSINESS" === activeIndex
              ? "text-black-100 font-ManropeBold text-ft5"
              : "text-black-75 font-ManropeMedium text-ft3"
          }`}
        >
          Business
        </p> */}
      </div>
      {"USER" === activeIndex ? (
        <ChartWithLine
          data={data?.userAccounts?.usersByDate}
          color={"#CEC0F6"}
        />
      ) : (
        <ChartWithLine
          data={data?.businessAccounts?.entitiesByDate}
          color={"#CEC0F6"}
        />
      )}
    </div>
  );
};

export default Users;
