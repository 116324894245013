import React, { useMemo, useState } from "react";
import Dropdown from "../../../../common/component/dropdown";
import { ReactComponent as Delete } from "../../../../assets/Icons/delete.svg";
import { ReactComponent as UpArrow } from "../../../../assets/Icons/up-arrow.svg";
import { ReactComponent as DownArrow } from "../../../../assets/Icons/down-arrow.svg";
import {
  categoryOptionSurvey,
  textData,
  toastType,
} from "../../../../constant/commonArray";
import { ReactComponent as AddImage } from "../../../../assets/Icons/paperclip.svg";
import { ReactComponent as AddPoll } from "../../../../assets/Icons/plus-orange.svg";
import AdddMedia from "../addMediaPopup";
import LinkPopup from "../linkPopup";
import PreviewPopup from "../previewPopup";
import Poll from "./options/poll/poll";
import RankingPoll from "./options/ranking/rankingPoll";
import TwoSidedPoll from "./options/twoSided/twoSidedPoll";
import LinkPreview from "../linkPreview";
import ShowToast from "../../../../common/showToast";
import PreviewTagsMentions from "../previewTagsMentions";
import AudioPreview from "../audioPreview";
import { ReactComponent as Edit } from "../../../../assets/Icons/Edit.svg";
import utility from "../../../../utility";
import AudioFileName from "../audioFileNameEdit";
import CropVideo from "../videoCropper";
import CropImage from "../cropper";
import { ReactComponent as Crop } from "../../../../assets/Icons/crop.svg";

/**
 * Survey component for managing polls and related media.
 *
 * @component
 * @param {object} props - Component props
 * @param {function} props.setSteps - Function to set current step
 * @param {object} props.state - Current state object
 * @param {function} props.setState - Function to set state
 * @param {object} props.ffmpeg - FFMPEG object for media processing
 * @param {boolean} props.isScriptLoaded - Flag indicating if script is loaded
 * @returns {JSX.Element} Survey component
 */

const Survey = ({ setSteps, state, setState, ffmpeg, isScriptLoaded }) => {
  const [open, setOpen] = useState(false);
  const [openMedia, setOpenMedia] = useState(null);
  const [files, setFiles] = useState([]);
  const [idx, setIdx] = useState("");
  const [errors, setErrors] = useState([]);
  const [errorsPoll, setErrorsPoll] = useState([]);
  const [errorsRanking, setErrorRanking] = useState([]);
  const [errorsTwoSided, setErrorTwoSided] = useState([]);
  const [selectFile, setSelectedFile] = useState([]);
  const [audioValues, setAudioValues] = useState({
    name: "",
    index: null,
  });
  const [imagePopup, setImagePopup] = useState({
    popup: false,
    index: "",
  });
  const [videoPopup, setVideoPopup] = useState({
    popup: false,
    index: "",
  });

  const handleDeleteClick = (index) => {
    setState((prevState) => {
      const updatedPollCategory = [...prevState.polls];
      updatedPollCategory.splice(index, 1);
      return { ...prevState, polls: updatedPollCategory };
    });
  };

  const handleAddPoll = () => {
    if (state?.polls?.length === 12) {
      ShowToast({
        message: "You can add only 12 polls",
        type: toastType.ERROR,
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        polls: [
          ...prevState.polls,
          {
            questionType: "Poll",
            question: "",
            description: "",
            media: [],
            images: [],
            audioName: [],
            answers: [
              {
                title: "",
                description: "",
                cover: [],
              },
              {
                title: "",
                description: "",
                cover: [],
              },
            ],
            options: {
              name: "Text",
              answers: textData,
              type: "TEXT",
            },
            multipleOptions: false,
            openAnswer: false,
          },
        ],
      }));
    }
  };

  const handleDeleteClickMedia = (idx, index) => {
    setState((prevState) => {
      const updatedPollCategory = [...prevState.polls];
      updatedPollCategory[idx].media.splice(index, 1);
      updatedPollCategory[idx].audioName.splice(index, 1);
      return { ...prevState, polls: updatedPollCategory };
    });
  };

  const handleAddMedia = (file, name) => {
    setState((prevState) => {
      const updatedPollCategory = [...prevState.polls];
      updatedPollCategory[idx].media = [
        ...updatedPollCategory[idx].media,
        file,
      ];
      updatedPollCategory[idx].audioName = [
        ...updatedPollCategory[idx].audioName,
        { fileName: !!name ? name : "" },
      ];
      return { ...prevState, polls: updatedPollCategory };
    });
  };

  const handleOnChangeDescription = (e, idx) => {
    const updatedErrors = [...errors];
    if (e.target.value.length > 150) {
      updatedErrors[idx] = {
        ...updatedErrors[idx],
        description: "Description should be less than 150 characters",
      };
    } else {
      updatedErrors[idx] = {
        ...updatedErrors[idx],
        description: "",
      };
    }
    setErrors(updatedErrors);
    setState((prevState) => ({
      ...prevState,
      polls: prevState.polls.map((el, i) =>
        i === idx ? { ...el, description: e.target.value } : el
      ),
    }));
  };

  const handleEditName = (audioIdx, name) => {
    setState((prev) => ({
      ...prev,
      polls: prev.polls.map((poll, i) => {
        if (i === idx) {
          return {
            ...poll,
            audioName: poll.audioName.map((item, j) => {
              if (j === audioIdx) {
                return { ...item, fileName: name };
              }
              return item;
            }),
          };
        }
        return poll;
      }),
    }));
  };

  const handleCroppedImageInitial = (image) => {
    setFiles([image]);
    setOpenMedia("PREVIEW");
  };

  const handleAddBrowseMedia = (file, index) => {
    setState((prevState) => {
      const updatedPolls = [...prevState.polls];
      updatedPolls[idx].media = prevState.polls[idx].media.map((item, i) =>
        i === index ? file : item
      );
      return { ...prevState, polls: updatedPolls };
    });
  };

  const renderQuestionTypeComponent = (item, idx) => {
    switch (item?.questionType) {
      case "Poll":
        return (
          <Poll
            setState={setState}
            state={state}
            setSteps={setSteps}
            idx={idx}
            setErrorsPoll={setErrorsPoll}
            errorsPoll={errorsPoll}
          />
        );
      case "Two-Sided Question":
        return (
          <TwoSidedPoll
            setSteps={setSteps}
            state={state}
            setState={setState}
            idx={idx}
            setErrorTwoSided={setErrorTwoSided}
            errorsTwoSided={errorsTwoSided}
          />
        );
      case "Ranking":
        return (
          <RankingPoll
            setState={setState}
            state={state}
            setSteps={setSteps}
            idx={idx}
            setErrorRanking={setErrorRanking}
            errorsRanking={errorsRanking}
          />
        );
      default:
        return null;
    }
  };

  const isAnySideError = errorsTwoSided?.some((sideErrors) =>
    sideErrors?.some((error) => !!error.title || !!error.description)
  );

  const isAnyPollError = errorsPoll?.some((error) =>
    error?.some((item) => !!item)
  );

  const isAnyRankingError = errorsRanking?.some((error) =>
    error?.some((item) => !!item)
  );

  const isAnyQuestionError =
    errors?.some((item) => !!item?.question) ||
    errors?.some((item) => !!item?.description);

  const isPollMissingQuestion = state?.polls?.some((poll) => !poll.question);

  const isPollOptionError = state?.polls?.some((poll) => {
    if (poll.questionType === "Poll" || poll.questionType === "Ranking") {
      if (poll.options?.type !== "IMAGE") {
        return poll.options?.answers?.some((item) => !item?.value);
      } else {
        return (
          poll.images?.length < 2 ||
          poll.images?.some((item) => item?.hasOwnProperty("dummyImages"))
        );
      }
    }
    return false;
  });

  const isTwoSidedQuestionError = state?.polls?.some((poll) => {
    if (poll.questionType === "Two-Sided Question") {
      return (
        poll.answers?.some((item) => !item?.title) ||
        poll.answers?.some((item) => !item?.description)
      );
    }
    return false;
  });

  const handleCategoryChange = (value, idx) => {
    setState((prevState) => ({
      ...prevState,
      polls: prevState.polls.map((el, i) =>
        i === idx
          ? {
              ...el,
              questionType: value,
              question: "",
              description: "",
              media: [],
              images: [],
              audioName: [],
              answers: [
                {
                  title: "",
                  description: "",
                  cover: [],
                },
                {
                  title: "",
                  description: "",
                  cover: [],
                },
              ],
              options:
                value === "Two-Sided Question"
                  ? undefined
                  : {
                      type: "TEXT",
                      answers: textData,
                      name: "Text",
                    },
              multipleOptions: false,
              openAnswer: false,
            }
          : el
      ),
    }));
    setErrorRanking((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[idx] = [];
      return newErrors;
    });
    setErrorsPoll((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[idx] = [];
      return newErrors;
    });
    setErrorTwoSided((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[idx] = [];
      return newErrors;
    });
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[idx] = [];
      return newErrors;
    });
  };

  const handleQuestionChange = (e, idx) => {
    const updatedErrors = [...errors];
    if (e.target.value.length > 150) {
      updatedErrors[idx] = {
        ...updatedErrors[idx],
        question: "Question should be less than 150 characters",
      };
    } else {
      updatedErrors[idx] = {
        ...updatedErrors[idx],
        question: "",
      };
    }
    setErrors(updatedErrors);
    setState((prevState) => ({
      ...prevState,
      polls: prevState.polls.map((el, i) =>
        i === idx ? { ...el, question: e.target.value } : el
      ),
    }));
  };

  const isButtonDisabled =
    isAnySideError ||
    isAnyPollError ||
    isAnyRankingError ||
    isAnyQuestionError ||
    isPollMissingQuestion ||
    isPollOptionError ||
    isTwoSidedQuestionError;

  return (
    <div className="mt-10">
      <div className="flex gap-4 w-full">
        <div className="w-1/2 flex flex-col">
          <div
            onClick={handleAddPoll}
            className="flex gap-2.5 items-center cursor-pointer pt-5"
          >
            <AddPoll />
            <span className="text-orange-50 font-ManropeBold text-ft2">
              Add Poll
            </span>
          </div>
          {state?.polls?.map((item, idx) => (
            <div key={idx} className="mt-15">
              <div
                onClick={() => setOpen(open === idx ? null : idx)}
                className="flex justify-between cursor-pointer"
              >
                <div className="flex flex-col">
                  <span className="font-ManropeBold text-ft4 text-black-100">
                    {item?.questionType || "Poll"}
                  </span>
                </div>
                <div className="flex gap-5">
                  {state?.polls?.length > 2 && (
                    <Delete
                      onClick={() => handleDeleteClick(idx)}
                      className="w-6 h-6 cursor-pointer"
                    />
                  )}
                  <div className="cursor-pointer">
                    {open === idx ? <UpArrow /> : <DownArrow />}
                  </div>
                </div>
              </div>
              {open === idx && (
                <div className="w-full flex flex-col gap-7.5 mt-7.5">
                  <div>
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor=""
                        className="text-black-100 text-ft3 font-ManropeMedium"
                      >
                        Category
                      </label>
                      <div>
                        <Dropdown
                          options={categoryOptionSurvey}
                          placeHolder={"Not choosen"}
                          value={item.questionType}
                          setValue={(value) => handleCategoryChange(value, idx)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 pt-5">
                      <label
                        htmlFor=""
                        className="text-black-100 text-ft3 font-ManropeMedium"
                      >
                        Question
                      </label>
                      <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-0.5">
                          <input
                            type="text"
                            placeholder="Question"
                            value={item?.question || ""}
                            onChange={(e) => handleQuestionChange(e, idx)}
                            className="rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
                          />
                          {errors[idx]?.question && (
                            <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                              {errors[idx]?.question}
                            </div>
                          )}
                        </div>
                        {item?.questionType !== "Two-Sided Question" && (
                          <PreviewTagsMentions
                            error={errors[idx]?.description}
                            value={item?.description || ""}
                            onChange={handleOnChangeDescription}
                            idx={idx}
                          />
                        )}
                      </div>
                      <div className="w-50per">
                        {item?.media?.map((value, index) => (
                          <MediaItem
                            key={index}
                            item={value}
                            poll={item}
                            index={index}
                            handleDeleteClickMedia={handleDeleteClickMedia}
                            setOpenMedia={setOpenMedia}
                            idx={idx}
                            setAudioValues={setAudioValues}
                            setImagePopup={setImagePopup}
                            setVideoPopup={setVideoPopup}
                            setIdx={setIdx}
                            setSelectedFile={setSelectedFile}
                            setFiles={setFiles}
                          />
                        ))}
                      </div>

                      {item?.questionType !== "Two-Sided Question" &&
                        item?.media?.length < 6 && (
                          <div
                            onClick={() => {
                              setOpenMedia("MEDIA");
                              setIdx(idx);
                            }}
                            className="flex gap-2.5 items-center cursor-pointer pt-5"
                          >
                            <AddImage />
                            <span className="text-orange-50 font-ManropeBold text-ft2">
                              Add Media
                            </span>
                          </div>
                        )}
                    </div>
                    <div>{renderQuestionTypeComponent(item, idx)}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {state?.reason?.length > 0 && (
          <div className="bg-red-75 rounded-xl p-5 h-max-content w-1/2 flex flex-col gap-1 text-ft1">
            <span className="text-ft2 font-ManropeBold text-black-100">
              Reason:
            </span>
            {state?.reason?.map((item, idx) => (
              <span
                key={idx}
                className="text-ft2 font-ManropeRegular text-black-100"
              >
                {item?.description}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="flex justify-end mt-15 gap-7.5">
        <button
          onClick={() => setSteps(0)}
          className="border border-black-100 border-opacity-20 font-ManropeBold rounded-full w-55 h-16.25 text-ft2"
        >
          Cancel
        </button>
        <button
          disabled={isButtonDisabled}
          onClick={() => {
            setSteps(2);
          }}
          className={`${
            isButtonDisabled ? "bg-grey-50" : "bg-orange-50"
          } rounded-full w-55 h-16.25 font-ManropeBold text-ft2`}
        >
          Next
        </button>
      </div>
      {openMedia === "MEDIA" && (
        <AdddMedia
          setOpenMedia={setOpenMedia}
          openMedia={openMedia}
          setFiles={setFiles}
          files={files}
          setImagePopup={setImagePopup}
          setSelectedFile={setSelectedFile}
          handleAddMedia={handleAddMedia}
          type="poll"
        />
      )}
      {openMedia === "LINK" && (
        <LinkPopup
          setOpenMedia={setOpenMedia}
          handleAddMedia={handleAddMedia}
        />
      )}
      {openMedia === "EDIT" && (
        <AudioFileName
          state={state}
          files={files.length > 0 ? files : []}
          setOpenMedia={setOpenMedia}
          setFiles={setFiles}
          handleEditName={handleEditName}
          index={audioValues?.index}
          audioName={audioValues?.name}
          setState={setState}
        />
      )}
      {openMedia === "PREVIEW" && (
        <PreviewPopup
          state={state}
          files={files.length > 0 ? files : []}
          setOpenMedia={setOpenMedia}
          setFiles={setFiles}
          idx={idx}
          handleAddMedia={handleAddMedia}
          isScriptLoaded={isScriptLoaded}
          ffmpeg={ffmpeg}
        />
      )}

      {videoPopup.popup && (
        <CropVideo
          setState={setState}
          file={selectFile}
          setVideoPopup={setVideoPopup}
          index={videoPopup.index}
          isScriptLoaded={isScriptLoaded}
          ffmpeg={ffmpeg}
          handleAddMedia={handleAddBrowseMedia}
        />
      )}

      {imagePopup.popup && (
        <CropImage
          setState={setState}
          file={selectFile}
          setImagePopup={setImagePopup}
          index={imagePopup.index}
          handleCroppedImage={
            imagePopup.index?.toString()
              ? handleAddBrowseMedia
              : handleCroppedImageInitial
          }
        />
      )}
    </div>
  );
};

export default Survey;

/**
 * Functional component for rendering media items within a poll.
 *
 * @component
 * @param {object} props - Component props
 * @param {object} props.item - Media item object
 * @param {number} props.index - Index of the media item
 * @param {function} props.handleDeleteClickMedia - Function to handle media item deletion
 * @param {function} props.setOpenMedia - Function to set media popup state
 * @param {number} props.idx - Index of the poll item
 * @param {function} props.setAudioValues - Function to set audio values
 * @param {function} props.setImagePopup - Function to set image popup state
 * @param {function} props.setVideoPopup - Function to set video popup state
 * @param {function} props.setIdx - Function to set current index
 * @param {function} props.setSelectedFile - Function to set selected file
 * @param {function} props.setFiles - Function to set files state
 * @param {object} props.poll - Poll object containing media items
 * @returns {JSX.Element} MediaItem component
 */

const MediaItem = React.memo(
  ({
    item,
    index,
    handleDeleteClickMedia,
    setOpenMedia,
    idx,
    setAudioValues,
    setImagePopup,
    setVideoPopup,
    setIdx,
    setSelectedFile,
    setFiles,
    poll,
  }) => {
    let fileType;

    if (item instanceof File) {
      fileType = utility.getFileType(item?.name);
    }
    const haveImage =
      fileType === "image" ||
      fileType === "gif" ||
      item?.type === "png" ||
      item?.type === "jpg" ||
      item?.type === "jpeg" ||
      item?.type === "gif";

    const renderMedia = useMemo(() => {
      if (haveImage) {
        return (
          <img
            src={item instanceof File ? URL.createObjectURL(item) : item?.url}
            alt="Preview"
          />
        );
      } else if (fileType === "video" || item?.type === "mp4") {
        return (
          <video
            controls
            controlsList="nodownload"
            src={item instanceof File ? URL.createObjectURL(item) : item?.url}
          >
            <source
              src={item instanceof File ? URL.createObjectURL(item) : item?.url}
            />
          </video>
        );
      } else if (fileType === "audio" || item?.type === "mp3") {
        return (
          <AudioPreview
            fileName={poll?.audioName[index]?.fileName}
            item={item}
            bgColor={"bg-grey-50"}
          />
        );
      } else if (item?.type === "link") {
        return <LinkPreview link={item?.url} />;
      } else {
        return null;
      }
    }, [item, fileType, index, poll.audioName, haveImage]);

    return (
      <div key={index} className="flex gap-5 items-center py-3">
        {renderMedia}
        <div className="flex gap-5">
          {(fileType === "audio" || item?.type === "mp3") && (
            <Edit
              className="cursor-pointer"
              onClick={() => {
                setOpenMedia("EDIT");
                setFiles([item]);
                setAudioValues({
                  name: item?.audioName[index]?.fileName,
                  index: index,
                });
                setIdx(idx);
              }}
            />
          )}
          {item?.type !== "link" &&
            fileType === "image" &&
            fileType !== "gif" && (
              <Crop
                onClick={() => {
                  setImagePopup({
                    popup: true,
                    index: idx,
                  });
                  setSelectedFile(item);
                }}
                className="cursor-pointer"
              />
            )}
          {item?.type !== "link" && fileType === "video" && (
            <Crop
              onClick={() => {
                setVideoPopup({
                  popup: true,
                  index: idx,
                });
                setSelectedFile(item);
              }}
              className="cursor-pointer"
            />
          )}
          <Delete
            onClick={() => handleDeleteClickMedia(idx, index)}
            className="cursor-pointer"
          />
        </div>
      </div>
    );
  }
);
