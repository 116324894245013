import React, { useState, useEffect } from "react";
import PollType from "../../../assets/Icons/poll-type-icon.svg";
import defaultImage from "../../../assets/Icons/signupProfileSmall.png";
import utility from "../../../utility";

/**
 * Renders a preview component for a two-sided survey question.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setStartPollIndex - Function to set the start index of the poll.
 * @param {number} props.startPollIndex - Index of the current poll.
 * @param {Array} props.polls - Array of polls.
 * @param {Function} props.setPreview - Function to set the survey preview.
 * @param {string} props.type - Type of the survey.
 * @param {Object} props.data - Data of the survey question.
 * @param {Object} props.account - User account information.
 * @param {Date} props.surveyTime - End time of the survey.
 * @returns {JSX.Element} Two-sided survey preview component.
 */

const TwoSidedPreview = ({
  setStartPollIndex,
  startPollIndex,
  polls,
  setPreview,
  type = "POLL",
  data,
  account,
  surveyTime,
}) => {
  const [selectedData, setSelectedData] = useState([]);
  const [clickMore, setClickMore] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const toggleCheckBox = (filter) => {
    setSelectedData((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters;
      } else {
        return [filter];
      }
    });
  };

  const answersExist =
    data?.post?.options?.answers ||
    (data?.options?.answers &&
      data.options.answers.some((item) => item?.value !== ""));

  const dataOptions =
    data?.post?.options?.answers || data?.options?.answers || [];
  const dataAnswers = data?.answers || data?.answer || [];

  const answers = answersExist ? dataOptions : dataAnswers;

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = Date.now();
      const time = data?.info?.activePeriod?.endDate || surveyTime;
      const timeDifference = time - currentTime;
      const remainingMilliseconds = Math.max(0, timeDifference);

      const remainingDays = Math.floor(
        remainingMilliseconds / (1000 * 60 * 60 * 24)
      );
      const remainingHours = Math.floor(
        (remainingMilliseconds / (1000 * 60 * 60)) % 24
      );
      const remainingMinutes = Math.floor(
        (remainingMilliseconds / (1000 * 60)) % 60
      );

      setTimeLeft({
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
      });
    };

    calculateTimeLeft();

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 60000);

    return () => clearInterval(timer);
  }, [data, surveyTime]);

  return (
    <div className="bg-white relative flex flex-col h-full w-106.25 z-50 p-5">
      <div className="bg-black-100 overflow-y-auto overflow-x-hidden scrollbar-hide w-full h-full">
        <div className="px-5 pt-5 w-full h-10per">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center gap-2.5">
              <img
                src={
                  data?.creator?.profilePhoto ||
                  account?.profilePhoto ||
                  defaultImage
                }
                alt="user"
                className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
              />
              <p className="font-ManropeBold text-ft2 text-center text-white">
                {data?.creator?.fullName || account?.fullName}
              </p>
            </div>
            <div className="flex items-center justify-center w-auto p-2 rounded-20 bg-white backdrop-blur-xl bg-opacity-10">
              <p className="font-ManropeBold text-ft2 w-full text-white">
                {`${timeLeft.days ? timeLeft.days : ""}${
                  timeLeft.days ? "d" : ""
                } ${timeLeft.hours || 0}hr ${
                  timeLeft?.days ? "" : timeLeft.minutes || 0
                }${timeLeft?.days ? "" : "m"} left` ?? "-"}{" "}
              </p>
            </div>
          </div>
        </div>

        <div
          className={`p-5 flex flex-col justify-between ${
            clickMore ? "h-auto" : "h-90per"
          }`}
        >
          <div className="flex flex-col gap-5 justify-center items-center">
            <div className="flex flex-col gap-3.75 w-full">
              {type === "POLL" ? (
                <div className="flex gap-1">
                  {data?.channels?.map((item, index) => (
                    <p
                      key={index}
                      className="flex items-center justify-center rounded-20 text-black-100 font-ManropeMedium text-ft0 px-1.5"
                      style={{
                        backgroundColor: utility.getRandomColors(index),
                      }}
                    >
                      {item}
                    </p>
                  ))}
                </div>
              ) : (
                <div className="px-2 py-1 bg-black-50 rounded-full mt-3 w-max text-ft0 font-ManropeMedium text-white">
                  <span>
                    Question {startPollIndex + 1} of {polls?.length}
                  </span>
                </div>
              )}
              <div className="pt-1 gap-2 inline-block relative">
                <span className="text-ft42 font-ManropeBold text-white">
                  {data?.post?.question || data?.question}
                </span>{" "}
                {type === "POLL" && (
                  <span className="inline-flex gap-2 ml-2 mt-1 items-center px-2 py-1 border-1 rounded-20 w-max border-white border-opacity-10 text-ft0 font-ManropeMedium text-white">
                    <img src={PollType} alt="Icon" className="w-4 h-4" />{" "}
                    {utility.capitalizeFirstLetter(
                      data?.post?.pollType || data?.pollType
                    )}{" "}
                    {utility.capitalizeFirstLetter(
                      data?.post?.questionType || data?.questionType
                    )}
                  </span>
                )}
              </div>

              {clickMore ? (
                <TwoSidedWithMore
                  data={answers}
                  toggleCheckBox={toggleCheckBox}
                  selectedData={selectedData}
                />
              ) : (
                <TwoSidedWithImages
                  data={answers}
                  toggleCheckBox={toggleCheckBox}
                  selectedData={selectedData}
                  setClickMore={setClickMore}
                  clickMore={clickMore}
                />
              )}
            </div>
          </div>
          {type !== "POLL" && (
            <div className="w-full h-16.25 rounded-full bg-orange-50">
              <button
                onClick={() => {
                  if (polls?.length === startPollIndex + 1) {
                    setPreview(false);
                  } else if (polls?.length > startPollIndex) {
                    setStartPollIndex(startPollIndex + 1);
                  }
                  setClickMore(false);
                }}
                className="text-ft2 w-full h-full font-ManropeBold text-black-50 flex justify-center items-center"
              >
                {polls?.length === startPollIndex + 1 ? "Done" : "Continue"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TwoSidedWithImages = React.memo(
  ({ data, toggleCheckBox, selectedData, setClickMore, clickMore }) => {
    const hasEmptyCover = data?.some((item) => {
      if (Array.isArray(item?.cover)) {
        return item.cover.length === 0;
      } else {
        return item?.cover === "";
      }
    });
    function getBackgroundImage(item) {
      if (item.cover && item.cover[0] instanceof File) {
        return `url(${URL.createObjectURL(item.cover[0])})`;
      } else if (Array.isArray(item.cover)) {
        return "";
      } else {
        return `url(${item.cover.replace(/\s/g, "%20")})`;
      }
    }

    function getClassNames(item) {
      const coverClass =
        Array.isArray(item?.cover) && item?.cover?.length > 0
          ? "h-72"
          : typeof item?.cover === "string" && !!item?.cover
          ? "h-72"
          : "h-auto";
      return coverClass;
    }

    function getClassNamesCover(item) {
      const coverClass =
        Array.isArray(item?.cover) && item?.cover.length > 0
          ? "top-2 left-2 absolute"
          : typeof item?.cover === "string" && !!item?.cover
          ? "top-2 left-2 absolute"
          : "";
      return coverClass;
    }

    function getClassNamesSided(item) {
      const coverClass = Array.isArray(item?.cover)
        ? item?.cover?.length > 0
          ? "justify-end px-3 w-full"
          : "justify-start flex-col w-90per"
        : typeof item?.cover === "string" && !!item?.cover
        ? "justify-end px-3 w-full"
        : "justify-start flex-col w-90per";
      return coverClass;
    }

    return (
      <div className={`${hasEmptyCover ? "flex-col" : ""} flex gap-5 w-full`}>
        {data?.map((item, idx) => (
          <div
            key={idx}
            style={{
              backgroundImage: getBackgroundImage(item),
            }}
            className={`flex gap-5 relative ${getClassNames(item)} ${
              !hasEmptyCover ? "w-40" : "w-full"
            } rounded-20 bg-cover bg-center`}
          >
            <div
              className={`${getClassNamesCover(
                item
              )} flex justify-start p-1.5 rounded-full bg-black-100 bg-opacity-30`}
            >
              <div
                onClick={() => toggleCheckBox(idx)}
                className={`relative w-4 h-4 ${
                  selectedData.includes(idx)
                    ? "bg-orange-50"
                    : "bg-transparent border-1 border-white border-opacity-60"
                } rounded-full`}
              >
                <div
                  style={{ transform: "translate(-50%, -50%)" }}
                  className={`${
                    selectedData.includes(idx) ? "" : "hidden"
                  } absolute w-65per h-65per bg-black-100 rounded-full top-50per left-50per`}
                ></div>
              </div>
            </div>
            <div
              className={`${getClassNamesSided(
                item
              )} flex pb-3 flex-col gap-1 h-full `}
            >
              <span className="text-ft3 truncate font-ManropeBold text-white">
                {item?.title}
              </span>
              <div className="flex-col inline-block relative">
                <span className="text-ft2 truncate-multiline font-ManropeRegular text-white">
                  {item?.description}
                </span>

                <span
                  onClick={() => setClickMore(!clickMore)}
                  className="inline-flex w-max gap-2 ml-2 items-center font-ManropeBold text-ft1 cursor-pointer text-orange-50"
                >
                  More
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
);

const TwoSidedWithMore = React.memo(
  ({ data, toggleCheckBox, selectedData }) => {
    function getBackground(item) {
      const coverClass =
        item?.cover[0] instanceof File
          ? `url(${URL.createObjectURL(item?.cover[0])})`
          : Array.isArray(item?.cover)
          ? ""
          : `url(${item?.cover?.replace(/\s/g, "%20")})`;
      return coverClass;
    }
    function getClassNames(item) {
      const coverClass =
        Array.isArray(item?.cover) && item?.cover?.length > 0
          ? "flex-col"
          : typeof item?.cover === "string" && !!item?.cover
          ? "flex-col"
          : "flex-row h-auto";
      return coverClass;
    }

    function getClassNamesHeight(item) {
      const coverClass =
        Array.isArray(item?.cover) && item?.cover?.length > 0
          ? "h-81.25"
          : typeof item?.cover === "string" && !!item?.cover
          ? "h-81.25"
          : "h-full";
      return coverClass;
    }
    function getClassNamesCover(item) {
      const coverClass =
        Array.isArray(item?.cover) && item?.cover?.length > 0
          ? "absolute rounded-full bg-black-100 bg-opacity-30 top-2 left-2"
          : typeof item?.cover === "string" && !!item?.cover
          ? "absolute rounded-full bg-black-100 bg-opacity-30 top-2 left-2"
          : "";
      return coverClass;
    }

    function getClassNamesPadding(item) {
      const coverClass =
        Array.isArray(item?.cover) && item?.cover?.length > 0
          ? "px-3"
          : typeof item?.cover === "string" && !!item?.cover
          ? "px-3"
          : "";
      return coverClass;
    }
    return (
      <div>
        {data?.map((item, idx) => (
          <div key={idx} className={`w-full flex ${getClassNames(item)} gap-5`}>
            <div
              style={{
                backgroundImage: getBackground(item),
              }}
              className={`relative ${getClassNamesHeight(
                item
              )} rounded-20 bg-cover bg-center`}
            >
              <div
                className={`${getClassNamesCover(
                  item
                )} flex justify-start p-1.5`}
              >
                <div
                  onClick={() => toggleCheckBox(idx)}
                  className={`relative w-4 h-4 ${
                    selectedData.includes(idx)
                      ? "bg-orange-50"
                      : "bg-transparent border-1 border-white border-opacity-60"
                  } rounded-full`}
                >
                  <div
                    style={{ transform: "translate(-50%, -50%)" }}
                    className={`${
                      selectedData.includes(idx) ? "" : "hidden"
                    } absolute w-65per h-65per bg-black-100 rounded-full top-50per left-50per`}
                  ></div>
                </div>
              </div>
            </div>
            <div
              className={`flex ${getClassNamesPadding(
                item
              )} justify-end pb-3 flex-col gap-1 h-full w-full`}
            >
              <span className="text-ft3 break-all font-ManropeBold text-white">
                {item?.title}
              </span>
              <div className="flex flex-col">
                <span className="text-ft2 break-words font-ManropeRegular text-white">
                  {item?.description}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
);

export default TwoSidedPreview;
