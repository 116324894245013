/**
 * @module EntityAccount
 */

import React, { useEffect, useRef, useState } from "react";
import { UserService } from "../../services";
import utility from "../../utility";
import Active from "./active";
import Suspended from "./suspended";
import searchIcon from "../../assets/Icons/Search.svg";
import { ReactComponent as AddIcon } from "../../assets/Icons/plus.svg";
import {
  Link,
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDebouncedCallback } from "use-debounce";
import Close from "../../assets/Icons/close.svg";
import SuspendedUserPopUp from "../DashboardPage/details/SuspendedUserPopUp";
import WarningPopup from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import checkMark from "../../assets/Icons/checkMark.svg";
import { toastType } from "../../constant/commonArray";
import OutsideClickRef from "../../common/outsideClickRef";
import { useSelector } from "react-redux";
import Rejected from "./rejected";

/**
 * EntityComponent - A React component for managing entities with active and suspended states.
 * @component
 * @example
 * import { EntityComponent } from "./index.js";
 * // ...
 * <EntityComponent />
 *
 * @returns {JSX.Element} React component
 */

const EntityComponent = () => {
  const [entityList, setEntityList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tabType = queryParams.get("tab");
  const [active, setIsActive] = useState(tabType || "ACTIVE");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [sort, setSort] = useState(Number(queryParams.get("sort")) || -1);
  const [suspendUserPopup, setSupendUserPopup] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const filters = queryParams.get("type");
    return filters ? JSON.parse(filters) : [];
  });

  const [clickFilter, setClickFilter] = useState(false);
  const optionsRef = useRef(null);
  const [blockedSort, setBlockedSort] = useState("");
  const [userId, setUserId] = useState("");
  const [suspendType, setSuspendType] = useState("");
  const [updatingUser, setUpdatingUser] = useState(false);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [selectedUser, setSelectedUser] = useState({
    id: "",
    username: "",
    keyName: "",
  });

  const [seenActivatePopUp, setSeenActivatePopUp] = useState({
    popup: false,
    id: "",
    username: "",
    name: "",
  });

  const totalPages = Math.ceil(totalDocs / pageSize);
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleMoreClick = (user) => {
    if (userId === user?._id) {
      setUserId(null);
    } else {
      setUserId(user?._id);
    }
  };

  const toggleSelectedFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const renderFilter = () => {
    return (
      clickFilter && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilter(null)}
        >
          <div
            className={`flex w-45 p-5 flex-col items-start gap-5 absolute top-5 right-5 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("BUSINESS")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("BUSINESS")}
                checked={selectedFilters.includes("BUSINESS")}
              />
              {selectedFilters.includes("BUSINESS") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("BUSINESS");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Business
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("GOVERNMENT")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("GOVERNMENT")}
                checked={selectedFilters.includes("GOVERNMENT")}
              />
              {selectedFilters.includes("GOVERNMENT") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("GOVERNMENT");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Government
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("ORGANIZATION")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("ORGANIZATION")}
                checked={selectedFilters.includes("ORGANIZATION")}
              />
              {selectedFilters.includes("ORGANIZATION") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("ORGANIZATION");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Organization
              </label>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };

  const handleActivateUser = async (id) => {
    let response;
    setUpdatingUser(true);
    try {
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getEntityData();
      setSeenActivatePopUp((prev) => ({ ...prev, popup: false }));
      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };
  const getEntityData = async () => {
    setLoading(true);
    let requestParams = {
      status: active,
      sort: sort,
      limit: pageSize,
      skip: currentPage,
    };

    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    if (selectedFilters?.length > 0) {
      requestParams.type = selectedFilters;
    }
    if (blockedSort) {
      requestParams.sortingKey = blockedSort;
    }
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getEntityList(requestParams)
      );

      if (error || !response) return;
      setEntityList(response?.entitiesList);
      setTotalDocs(response?.totalCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    setCurrentPage(0);
    setDebounceValue(value);
  }, 500);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPage(0);
  };

  const handleTabClick = (newActive) => {
    setIsActive(newActive);
    setCurrentPage(0);
    setDebounceValue("");
    setSearchQuery("");
    setSelectedUser({ id: "", username: "" });
    setClickFilter(false);
    setSelectedFilters([]);

    queryParams.set("tab", newActive);
    history.replace({
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    getEntityData();
    setFilters();
    // eslint-disable-next-line
  }, [
    debounceValue,
    pageSize,
    active,
    sort,
    currentPage,
    selectedFilters,
    blockedSort,
  ]);

  const setFilters = () => {
    if (selectedFilters?.length > 0) {
      queryParams.set("type", JSON.stringify(selectedFilters));
    } else {
      queryParams.delete("type");
    }
    queryParams.set("sort", sort);
    queryParams.set("tab", active);
    history.replace({
      search: queryParams.toString(),
    });
  };
  const clearFilter = () => {
    queryParams.delete("type");
  };

  return (
    <div className="w-full h-full overflow-y-auto pr-12.5">
      <div className="py-5 w-full h-full ">
        <div className="flex justify-between bg-white p-5  w-full rounded-20  h-21.25">
          <div className="flex items-center gap-10">
            <p
              onClick={() => {
                handleTabClick("ACTIVE");
                clearFilter();
              }}
              className={` cursor-pointer ${
                active === "ACTIVE"
                  ? "text-ft5 text-black-100 font-ManropeBold"
                  : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
              }`}
            >
              Active
            </p>
            <p
              onClick={() => {
                handleTabClick("SUSPENDED");
                clearFilter();
              }}
              className={` cursor-pointer ${
                active === "SUSPENDED"
                  ? "text-ft5 text-black-100 font-ManropeBold"
                  : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
              }`}
            >
              Suspended
            </p>
            <p
              onClick={() => {
                handleTabClick("REJECTED");
                clearFilter();
              }}
              className={` cursor-pointer ${
                active === "REJECTED"
                  ? "text-ft5 text-black-100 font-ManropeBold"
                  : "text-ft3 text-black-50 font-ManropeMedium opacity-60"
              }`}
            >
              Rejected
            </p>
          </div>
          <div className="flex gap-5">
            <div className="flex w-83.75 h-11.25 p-5 items-center gap-3.75 flex-shrink-0 rounded-full bg-grey-50">
              <img
                src={searchIcon}
                alt="searchIcon"
                className="opacity-60 w-5 h-5"
              />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => handleSearch(e)}
                className="font-ManropeLight text-ft3 w-80per bg-grey-50 text-black-300 focus:outline-none"
              />
              {!!searchQuery && (
                <img
                  onClick={() => {
                    setSearchQuery("");
                    debouncedSearch("");
                  }}
                  src={Close}
                  alt=""
                  className="flex justify-end cursor-pointer  w-5 h-5"
                />
              )}
            </div>
            {userPermissions?.indexOf("business:write:entity") > -1 && (
              <Link to="/entity-account/create">
                <div className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center">
                  <AddIcon />
                </div>
              </Link>
            )}
          </div>
        </div>

        {active === "ACTIVE" ? (
          <Active
            setIsActive={setIsActive}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            sort={sort}
            data={entityList}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            loading={loading}
            handlePageChange={handlePageChange}
            pageSize={pageSize}
            totalDocs={totalDocs}
            setSort={setSort}
            handleMoreClick={handleMoreClick}
            selectedUser={selectedUser}
            totalPages={totalPages}
            setSupendUserPopup={setSupendUserPopup}
            suspendUserPopup={suspendUserPopup}
            renderFilter={renderFilter}
            clickFilter={clickFilter}
            setUserId={setUserId}
            userId={userId}
            setClickFilter={setClickFilter}
            setSelectedUser={setSelectedUser}
            selectedFilters={selectedFilters}
          />
        ) : active === "SUSPENDED" ? (
          <Suspended
            setIsActive={setIsActive}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            sort={sort}
            data={entityList}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            loading={loading}
            handlePageChange={handlePageChange}
            pageSize={pageSize}
            totalDocs={totalDocs}
            setSort={setSort}
            setBlockedSort={setBlockedSort}
            blockedSort={blockedSort}
            setUserId={setUserId}
            userId={userId}
            totalPages={totalPages}
            handleMoreClick={handleMoreClick}
            selectedUser={selectedUser}
            setSupendUserPopup={setSupendUserPopup}
            suspendUserPopup={suspendUserPopup}
            setSelectedUser={setSelectedUser}
            setSeenActivatePopUp={setSeenActivatePopUp}
            seenActivatePopUp={seenActivatePopUp}
            renderFilter={renderFilter}
            clickFilter={clickFilter}
            setClickFilter={setClickFilter}
            setSuspendType={setSuspendType}
            selectedFilters={selectedFilters}
          />
        ) : (
          <Rejected
            setIsActive={setIsActive}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            sort={sort}
            data={entityList}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            loading={loading}
            handlePageChange={handlePageChange}
            pageSize={pageSize}
            totalDocs={totalDocs}
            setSort={setSort}
            setBlockedSort={setBlockedSort}
            blockedSort={blockedSort}
            totalPages={totalPages}
            setSeenActivatePopUp={setSeenActivatePopUp}
            seenActivatePopUp={seenActivatePopUp}
            renderFilter={renderFilter}
            clickFilter={clickFilter}
            setClickFilter={setClickFilter}
            setSuspendType={setSuspendType}
            selectedFilters={selectedFilters}
          />
        )}
      </div>
      {suspendUserPopup && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setSupendUserPopup(!suspendUserPopup)}
          username={selectedUser?.username}
          id={selectedUser?.id}
          keyName={selectedUser.keyName}
          getdetails={getEntityData}
          type={suspendType}
          endDate={selectedUser?.endDate}
          reasonType={selectedUser?.reason}
          name={selectedUser?.name}
          setActivatePopUp={setSeenActivatePopUp}
        />
      )}
      {seenActivatePopUp.popup && (
        <WarningPopup
          handleActivateUser={() => handleActivateUser(seenActivatePopUp?.id)}
          title={`Activate ${seenActivatePopUp?.username} ?`}
          description={
            "User will be activated. All created content will be available for other users"
          }
          text={`${seenActivatePopUp.name} activated`}
          setToggle={() =>
            setSeenActivatePopUp((prev) => ({ ...prev, popup: false }))
          }
          loading={updatingUser}
        />
      )}
    </div>
  );
};

export default EntityComponent;
