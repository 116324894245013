import React, { useState } from "react";
import utility from "../../../utility";
import CheckMark from "../../../assets/Icons/checkMark.svg";
import ImageViewer from "../../../common/component/imageViewer";

/**
 * React component for rendering a type of poll.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {Array} props.details - The details of the poll items.
 * @param {string} props.image - The type of poll items, either "IMAGE" or another value.
 * @returns {React.ReactNode} - The rendered component.
 */

const TypePoll = ({ details, image }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [openImage, setOpenImage] = useState("");
  /**
   * Generates a random color based on the given index.
   *
   * @function
   * @private
   * @param {number} index - The index to determine the color.
   * @returns {string} - The generated color code.
   */

  return (
    <div className="flex flex-col gap-7.5 w-full">
      {image === "IMAGE" || image === "Images"
        ? details?.map((item, index) => (
            <div key={index} className="flex gap-5">
              <div
                onClick={() => {
                  setOpenPopup(!openPopup);
                  setOpenImage(item?.value);
                }}
                style={{
                  border: `${
                    item.yourOption
                      ? `2px solid ${utility.getRandomColors(index)}`
                      : ""
                  }`,
                }}
                className="flex flex-shrink-0 justify-between rounded-15px p-0.5 cursor-pointer"
              >
                <img
                  src={item?.value.replace(/\s/g, "%20")}
                  alt={item?.rank || "-"}
                  className="w-15 h-15 rounded-15px"
                />
              </div>
              <div className="flex flex-col w-full justify-center">
                <div className="flex w-full justify-between">
                  <div>
                    <p className="text-ft3 font-ManropeBold text-black-100">
                      {index + 1}
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <p className="text-ft3 font-ManropeBold text-black-100">
                      {item.totalVotes || 0}
                    </p>
                    <p className="text-ft2 font-ManropeRegular text-grey-250">
                      {`(${item.totalVotePercentage || 0}%)`}
                    </p>
                  </div>
                </div>

                <div className="flex  w-full h-1.25 bg-black-550 rounded-20">
                  <div
                    style={{
                      width: `${item.totalVotePercentage}%`,
                      backgroundColor: utility.getRandomColors(index),
                    }}
                    className={`h-1.25px rounded-20`}
                  ></div>
                </div>
              </div>
            </div>
          ))
        : details?.map((item, index) => (
            <div key={index} className="flex gap-3 items-center w-full">
              {item?.yourOption && (
                <div className="w-4.5 h-4.5 rounded-full bg-green-50 p-0.5">
                  <img src={CheckMark} alt="check" />
                </div>
              )}
              <div className="flex flex-col w-full">
                <div className="flex gap-1 items-center justify-between">
                  <p className="font-ManropeBold text-ft3 text-black-100">
                    {item?.value}
                  </p>

                  <div className="flex gap-1">
                    <p className="text-ft3 font-ManropeBold text-black-100">
                      {item.totalVotes || 0}
                    </p>
                    <p className="text-ft2 font-ManropeRegular text-grey-250">
                      {`(${item.totalVotePercentage || 0}%)`}
                    </p>
                  </div>
                </div>
                <div className="flex w-full h-1.25 bg-black-550 rounded-20">
                  <div
                    key={index}
                    style={{
                      width: `${item.totalVotePercentage}%`,
                      backgroundColor: utility.getRandomColors(index),
                    }}
                    className={`h-1.25px rounded-20`}
                  ></div>
                </div>
              </div>
            </div>
          ))}
      {openPopup && (
        <ImageViewer
          toggleImagePopUp={openPopup}
          setToggleImagePopUp={setOpenPopup}
          image={openImage}
        />
      )}
    </div>
  );
};

export default TypePoll;
