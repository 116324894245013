import React from "react";
import defaultImage from "../../../assets/Icons/signupProfileSmall.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import utility from "../../../utility";

import SwiperCore, { Pagination } from "swiper";

SwiperCore.use([Pagination]);

/**
 * CoverMedia component for displaying media elements in a Swiper carousel.
 * @component
 * @param {Object} props - Component props
 * @param {Array} props.data - Array of media items to display
 * @param {string} props.name - Name of the user or default text
 * @param {string} props.profilePhoto - URL of the profile photo or default image
 * @param {string} props.endDate - End date text to display
 * @param {string} [props.rounded="rounded-b-30"] - Optional CSS class for rounded corners
 * @returns {JSX.Element} - Rendered component
 */

const CoverMedia = ({
  data,
  name,
  profilePhoto,
  endDate,
  rounded = "rounded-b-30",
}) => {
  /**
   * Generates the appropriate media element based on the item type.
   * @param {Object} item - Media item object
   * @param {string} fileType - Type of file (image or video)
   * @param {string} rounded - CSS class for rounded corners
   * @returns {JSX.Element} - Media element based on item type
   */
  function getMediaElement(item, fileType, rounded) {
    let mediaElement;

    switch (true) {
      case item instanceof File:
        if (fileType === "image") {
          mediaElement = (
            <div
              style={{
                backgroundImage: `url(${URL.createObjectURL(item)})`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="h-full w-full rounded-20"
            ></div>
          );
        } else {
          mediaElement = (
            <video
              autoPlay
              loop
              muted
              className={`h-full w-full ${rounded} object-cover`}
            >
              <source src={URL.createObjectURL(item)} />
            </video>
          );
        }
        break;
      case item.type === "jpg" || item.type === "jpeg" || item.type === "png":
        mediaElement = (
          <div
            style={{
              background: `url(${item.url.replace(/ /g, "%20")})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
            className="h-full w-full rounded-20"
          ></div>
        );
        break;
      case item.type === "gif":
        mediaElement = (
          <div className="h-full w-full rounded-20 flex justify-center">
            <img src={item.url.replace(/ /g, "%20")} alt="" />
          </div>
        );
        break;

      default:
        mediaElement = (
          <video
            autoPlay
            loop
            muted
            poster={item?.thumbnail}
            className={`h-full w-full ${rounded} object-cover`}
          >
            <source src={item.url} />
          </video>
        );
    }

    return mediaElement;
  }

  return (
    <Swiper
      pagination={{ clickable: true }}
      modules={[Pagination]}
      centeredSlides={true}
      className="h-full w-full"
    >
      {data?.map((item, index) => {
        let fileType = "";
        if (item instanceof File) {
          fileType = utility.getFileType(item.name);
        }
        return (
          <SwiperSlide key={index} className="w-full">
            {" "}
            <div className={`h-full ${rounded}`}>
              <div
                style={{
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 42.01%, rgba(0, 0, 0, 0.4) 69.22%, rgba(0, 0, 0, 0.5) 84.1%, rgba(0, 0, 0, 0.4) 100%)`,
                }}
                className={`flex items-end h-full ${rounded} justify-between`}
              >
                {getMediaElement(item, fileType, rounded)}
                <div className="absolute flex pointer-events-none justify-between p-5 items-center w-full">
                  <div className="flex items-center gap-2.5">
                    <img
                      src={profilePhoto || defaultImage}
                      alt="user"
                      className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
                    />
                    <p className="font-ManropeBold text-ft2 text-center text-white">
                      {name || "Pollpapa"}
                    </p>
                  </div>
                  <div className="flex items-center justify-center w-auto p-2 rounded-20  bg-white backdrop-blur-xl bg-opacity-10">
                    <p className="font-ManropeBold text-ft2 w-full text-white">
                      {endDate || "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CoverMedia;
