import React, { useEffect, useMemo, useState } from "react";
import close from "../../../assets/Icons/close.svg";
import chevrondown from "../../../assets/Icons/Chevrondown.svg";
import chevronUp from "../../../assets/Icons/chevronUp.svg";
import calender from "../../../assets/Icons/calender.svg";
import Time from "../../../assets/Icons/Time.svg";
import { reason, timeSuspend, toastType } from "../../../constant/commonArray";
import { UserService } from "../../../services";
import ShowToast from "../../../common/showToast";
import utility from "../../../utility";
import { useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";

/**
 * React component representing a pop-up for suspending a user.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.togglePopUpVisble - Function to toggle the visibility of the pop-up.
 * @param {string} props.username - The username of the user to be suspended.
 * @param {string} props.id - The ID of the user to be suspended.
 * @param {string} props.keyName - Key name for additional functionality.
 * @param {string} props.type - The type of suspension.
 * @param {Function} props.getdetails - Function to get details after suspension.
 * @param {number} props.endDate - The end date of the suspension.
 * @returns {React.Component} The rendered SuspendedUserPopUp component.
 */

const SuspendedUserPopUp = ({
  togglePopUpVisble,
  username,
  id,
  keyName,
  type,
  getdetails = () => {},
  endDate,
  reasonType,
  setActivatePopUp = () => {},
  name,
}) => {
  const [anchorElTime, setAnchorElTime] = useState(null);
  const [anchorElReason, setAnchorElReason] = useState(null);
  const [state, setState] = useState({
    type: type || "Permanent",
    startDate: Date.now(),
    endDate: 0,
    reason: reasonType || "Not Choosen",
  });
  const [stateTime, setStateTime] = useState({
    date: "",
    time: "",
  });
  const [timeSuspendDropDown, setTimeSuspendDropDown] = useState(false);
  const [selectTime, setSelectedTime] = useState(utility.getDefaultTime());
  const [reasonDropDown, setReasonDropDown] = useState(false);
  const [toggleCalender, setToggleCalender] = useState(false);
  const [customReasonInput, setCustomReasonInput] = useState("");
  const [updatingUser, setUpdatingUser] = useState(false);
  const [date, setDate] = useState(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  });

  const [changeDate, setChangeDate] = useState(false);
  const user = useSelector((state) => state.user);
  const [error, setError] = useState({});
  const useStyles = makeStyles((theme) => ({
    datePickerDay: {
      color: "#191714",
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 400,
    },
    datePickerTypography: {
      color: "#28272799",
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 400,
    },
  }));

  const classes = useStyles();

  useMemo(() => {
    switch (error?.errorType) {
      case "invalidDate": {
        return setError((prev) => ({
          ...prev,
          dateError: "Date is not valid",
        }));
      }
      case "disablePast": {
        return setError((prev) => ({
          ...prev,
          dateError:
            "The selected time must be in the future for today's date.",
        }));
      }
      case "maxDate": {
        return setError((prev) => ({
          ...prev,
          dateError: "The selected time must can't be beyond the max date.",
        }));
      }
      default: {
        return "";
      }
    }
    // eslint-disable-next-line
  }, [error?.errorType]);

  const handleTimePopoverOpen = (event) => {
    setAnchorElTime(event.currentTarget);
    setTimeSuspendDropDown(!timeSuspendDropDown);
  };

  const handleTimePopoverClose = () => {
    setAnchorElTime(null);
  };

  const handleReasonPopoverOpen = (event) => {
    setAnchorElReason(event.currentTarget);
    setReasonDropDown(!reasonDropDown);
  };

  const handleReasonPopoverClose = () => {
    setAnchorElReason(null);
  };

  const handleSuspendUser = async () => {
    setUpdatingUser(true);
    const combinedDateTime = `${
      date.toISOString().split("T")[0]
    }T${selectTime}`;
    let dateTime = new Date(combinedDateTime);
    if (changeDate) {
      dateTime.setDate(dateTime.getDate() + 1);
    } else {
      dateTime.setDate(dateTime.getDate());
    }
    const epochTime = dateTime.getTime();
    let resquestData = {
      reason: state.reason === "Other" ? customReasonInput : state.reason,
      startDate: state.startDate,
      endDate: state.type === "Custom" ? epochTime : state?.endDate,
      type: state.type === "Permanent" ? state.type.toUpperCase() : "CUSTOM",
      suspender: user?.staffId,
    };
    try {
      const response = await new UserService().userSuspendUser(
        id,
        resquestData
      );
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        togglePopUpVisble();
        getdetails();
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }

      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };

  const handleEditSuspendUser = async () => {
    setUpdatingUser(true);
    const combinedDateTime = `${
      date.toISOString().split("T")[0]
    }T${selectTime}`;
    let dateTime = new Date(combinedDateTime);
    if (changeDate) {
      dateTime.setDate(dateTime.getDate() + 1);
    } else {
      dateTime.setDate(dateTime.getDate());
    }

    const epochTime = dateTime.getTime();
    let resquestData = {
      startDate: state.startDate,
      endDate: state.type === "Custom" ? epochTime : state?.endDate,
      type: state.type === "Permanent" ? state.type.toUpperCase() : "CUSTOM",
      reason: state.reason === "Other" ? customReasonInput : state.reason,
      suspender: user?.staffId,
    };
    try {
      const response = await new UserService().userEditSuspendUser(
        id,
        resquestData
      );
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        togglePopUpVisble();
        getdetails();
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }

      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };

  useEffect(() => {
    if (endDate && type === "Custom") {
      const epochStartDate = endDate;
      const epocDate = new Date(epochStartDate);
      const hoursStart = epocDate.getHours();
      const minutesStart = epocDate.getMinutes();
      const formattedTimeStart = `${String(hoursStart).padStart(
        2,
        "0"
      )}:${String(minutesStart).padStart(2, "0")}`;
      setStateTime((prev) => ({
        ...prev,
        date: epocDate,
        time: formattedTimeStart,
      }));
      setDate(epocDate);
      setChangeDate(true);
      setSelectedTime(formattedTimeStart);
    }
    // eslint-disable-next-line
  }, []);

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const handleTimeChange = (newTime, date) => {
    const selectedDateTime = new Date(`${date.toDateString()} ${newTime}`);

    if (isToday(date) && selectedDateTime < new Date()) {
      setSelectedTime(newTime);
      setError((prev) => ({ ...prev, timeError: true }));
    } else {
      setSelectedTime(newTime);
      setError((prev) => ({ ...prev, timeError: false }));
    }
  };

  const isReasonNotChosen =
    state?.reason === "Not Choosen" && state?.reason !== "Other";
  const isOtherReasonEmpty = state?.reason === "Other" && !customReasonInput;
  const isEditKey =
    keyName === "edit" &&
    (state?.type === "Custom" && type === "Custom"
      ? stateTime?.date === date && stateTime?.time === selectTime
      : type === state?.type) &&
    state?.reason === reasonType;

  const isButtonDisabled =
    isReasonNotChosen ||
    isOtherReasonEmpty ||
    isEditKey ||
    error?.reasonError ||
    error?.timeError ||
    error?.errorType ||
    updatingUser;

  const buttonClassName = `flex h-16.25 px-6.25 w-full py-5 justify-center items-center rounded-full text-ft2 font-ManropeBold ${
    isButtonDisabled ? "bg-grey-50" : "bg-orange-50 text-black-100"
  }`;

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center">
      <div className="flex flex-col bg-white rounded-xl shadow-md w-112.5 h-80per p-7.5">
        <div className="flex justify-between">
          <p className="font-ManropeBold text-ft4 text-black-100 truncate">
            Suspend {username || "-"}
          </p>
          <img
            src={close}
            alt="close"
            className="cursor-pointer"
            onClick={() => {
              togglePopUpVisble();
            }}
          />
        </div>
        <div className="flex flex-col mt-5 mb-5 gap-5  overflow-y-scroll max-h-155 scrollbar-hide">
          <p className="w-79.75 font-ManropeRegular text-ft3 text-black-100">
            User will be blocked. All created content will be not available for
            other users
          </p>
          <div className="flex flex-col">
            <p className="font-ManropeRegular text-ft3 text-black-100">
              Time for suspend
            </p>
            <div
              className="flex h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50 justify-between items-center relative cursor-pointer"
              onClick={(event) => {
                handleTimePopoverOpen(event);
              }}
            >
              <div className="flex w-full justify-between items-center relative cursor-pointer">
                <p className="font-ManropeRegular text-ft3 text-black-100">
                  {state.type}
                </p>
                <img
                  src={timeSuspendDropDown ? chevronUp : chevrondown}
                  alt="chevrondown"
                  className="cursor-pointer"
                />
              </div>
              <Popover
                open={timeSuspendDropDown}
                anchorEl={anchorElTime}
                onClose={handleTimePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ height: "30%" }}
                disablePortal
              >
                {timeSuspend.map((value) => (
                  <div
                    className="h-16.25 w-97.5 cursor-pointer"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        type: value.label,
                        startDate: value.startDate,
                        endDate: value.endDate,
                      }));
                      setTimeSuspendDropDown(false);
                    }}
                  >
                    <p className="flex items-center px-5 font-ManropeRegular w-97.5 h-16.25 text-ft3 text-black-100">
                      {value.label}
                    </p>
                    <hr
                      className={`${
                        value.label !== "Permanent"
                          ? "text-grey-100"
                          : "opacity-0"
                      }`}
                    />
                  </div>
                ))}
              </Popover>
            </div>
          </div>
          {state.type === "Custom" && (
            <div className="flex justify-between gap-3">
              <div className="flex flex-col relative w-1/2">
                <p className="font-ManropeRegular text-ft3 text-black-100">
                  Until Date
                </p>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label=""
                      disablePast
                      onError={(newError) =>
                        setError((prev) => ({ ...prev, errorType: newError }))
                      }
                      open={toggleCalender}
                      disableHighlightToday
                      onClose={() => setToggleCalender(false)}
                      onChange={(value) => {
                        const currentDate = new Date();
                        currentDate.setHours(0, 0, 0, 0);
                        const selectedDate = new Date(value);
                        selectedDate.setHours(0, 0, 0, 0);
                        if (selectedDate >= currentDate) {
                          setDate(selectedDate);
                        }
                        handleTimeChange(selectTime, selectedDate);
                        setToggleCalender(false);
                        setChangeDate(true);
                      }}
                      value={date}
                      classes={{ typography: classes.datePickerTypography }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { border: "none" },
                          height: "48px",
                          padding: "25px 10px 25px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "20px",
                          backgroundColor: "#f3f3f3",
                          border: "none",
                        },
                      }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment
                                sx={{
                                  width: "40px",
                                  height: "40px",
                                  cursor: "pointer",
                                  marginRight: "12px",
                                }}
                                position="start"
                              >
                                <img
                                  src={calender}
                                  alt=""
                                  onClick={() => {
                                    setToggleCalender(!toggleCalender);
                                  }}
                                />
                              </InputAdornment>
                            ),
                          },
                        },
                        day: {
                          className: classes.datePickerDay,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <p className="font-ManropeRegular text-ft3 text-black-100">
                  Time
                </p>
                <div className="flex relative w-full h-full items-center justify-between rounded-full bg-grey-50 ">
                  <input
                    type="time"
                    value={selectTime}
                    onChange={(e) => handleTimeChange(e.target.value, date)}
                    className="rounded-full px-6.25 py-2.5 h-full bg-grey-50 font-ManropeRegular text-ft3 text-black-100 w-full focus:outline-none"
                  />
                  <img
                    src={Time}
                    alt={"time"}
                    className=" absolute right-9 pointer-events-none"
                  />
                </div>
              </div>
            </div>
          )}
          {error.timeError && (
            <span className=" text-red-50">
              The selected time must be in the future for today's date.
            </span>
          )}
          {error?.errorType && (
            <span className=" text-red-50">{error?.dateError}</span>
          )}

          <div className="flex flex-col relative">
            <p className="font-ManropeRegular text-ft3 text-black-100">
              Reason
            </p>
            <div
              className="flex justify-between items-center h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50 cursor-pointer"
              onClick={(event) => {
                handleReasonPopoverOpen(event);
              }}
            >
              <div className="flex justify-between items-center w-full ">
                <p
                  className={`truncate ${
                    state.reason === "Not Choosen"
                      ? "font-ManropeRegular text-ft3 text-black-400"
                      : "font-ManropeRegular text-ft3 text-black-100"
                  }`}
                >
                  {state.reason}
                </p>

                <img
                  src={reasonDropDown ? chevronUp : chevrondown}
                  alt="chevrondown"
                  className="cursor-pointer"
                />
              </div>
              <Popover
                open={reasonDropDown}
                anchorEl={anchorElReason}
                onClose={handleReasonPopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ height: "30%" }}
                disablePortal
              >
                {reason.map((value) => (
                  <div
                    className="h-16.25 w-97.5 cursor-pointer"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        reason: value,
                      }));
                      setReasonDropDown(false);
                    }}
                  >
                    <p className="flex items-center p-5 w-97.5 h-16.25 text-ft3 text-black-100">
                      {value}
                    </p>
                    <hr
                      className={`${
                        value !== "Other" ? "text-grey-100" : "opacity-0"
                      }`}
                    />
                  </div>
                ))}
              </Popover>
            </div>
          </div>

          {state.reason === "Other" && (
            <div className="flex flex-col">
              <p className="font-ManropeRegular text-ft3 text-black-100">
                Type a reason
              </p>
              <div className="flex h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50">
                <input
                  type="text"
                  className="w-97.5 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none"
                  value={customReasonInput}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 150) {
                      setError((prev) => ({ ...prev, reasonError: false }));
                    } else {
                      setError((prev) => ({ ...prev, reasonError: true }));
                    }
                    setCustomReasonInput(inputValue);
                  }}
                />
              </div>
            </div>
          )}
          {error?.reasonError && (
            <span className=" text-red-50">Reason can't be more than 150</span>
          )}
        </div>
        <div className="mt-auto w-full flex flex-col justify-center items-center">
          <button
            disabled={isButtonDisabled}
            onClick={() => {
              keyName !== "edit"
                ? handleSuspendUser()
                : handleEditSuspendUser();
            }}
            className={buttonClassName}
          >
            {keyName === "edit" ? "Save" : "Suspend"}
          </button>
          {keyName === "edit" && (
            <span
              onClick={() => {
                setActivatePopUp((prev) => ({
                  ...prev,
                  popup: true,
                  id: id,
                  username: username,
                  name: name,
                }));
                togglePopUpVisble();
              }}
              className="my-5 font-ManropeBold text-ft1 text-black-100 cursor-pointer"
            >
              Activate user
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuspendedUserPopUp;
