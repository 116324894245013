import React, { useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { geoFeatures } from "../../../constant/geoFeatures";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import * as d3 from "d3-geo";

/**
 * MapComponent displays a world map with markers and tooltips for specified locations.
 *
 * @component
 * @example
 * // Example usage of MapComponent
 * <MapComponent
 *   colors={["#EBC9F1", "#C3E9FC", "#BDD6AB", "#F6C0B8", "#FFE8AE"]}
 *   data={[
 *     { location: "Country1", count: 1500, latitude: 40, longitude: 10 },
 *     { location: "Country2", count: 800, latitude: -25, longitude: 135 },
 *     // ...more data
 *   ]}
 *   getColorByIndex={(index) => colors[index % colors.length]}
 *   countrySelected="Country1"
 * />
 *
 * @param {string[]} colors - An array of colors used for markers on the map.
 * @param {Object[]} data - An array of objects containing location data.
 * @param {string} data[].location - The name of the location.
 * @param {number} data[].count - The count or registrations for the location.
 * @param {number} data[].latitude - The latitude coordinate of the location.
 * @param {number} data[].longitude - The longitude coordinate of the location.
 * @param {Function} getColorByIndex - A function to get a color based on the index.
 * @param {string} countrySelected - The currently selected country to zoom in on the map.
 *
 * @returns {JSX.Element} The rendered MapComponent.
 */

const initPos = { zoom: 1, coordinates: [0, 0] };

const MapComponent = ({ colors, data, getColorByIndex, countrySelected }) => {
  const [position, setPosition] = useState(initPos);
  const [strokeWidth, setStrokeWidth] = useState(1);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#000",
    },
  }));

  function getTooltipContent(value, name, color) {
    return (
      <div className="flex flex-col bg-white rounded-20 p-5 z-50 absolute shadow-md w-61 gap-2">
        <div className="flex items-center gap-2">
          <div
            style={{ backgroundColor: color }}
            className="w-3 h-3 rounded-full"
          ></div>
          <p className="text-black-100 font-ManropeBold text-ft3">{name}</p>
        </div>
        <p className="text-black-75 text-opacity-60 font-ManropeRegular text-ft3">
          {value} Registrations
        </p>
      </div>
    );
  }

  function countrySelect(country) {
    if (country) {
      const countryToZoom = geoFeatures.features.find(
        (gf) => gf.properties.name?.toLowerCase() === country?.toLowerCase()
      );

      if (countryToZoom) {
        const divB = document.getElementById("mapDiv");

        if (divB) {
          const width = divB.offsetWidth;
          const height = divB.offsetHeight;

          const { geometry } = countryToZoom;
          const center = d3.geoCentroid(geometry);
          const projection = d3
            .geoMercator()
            .fitSize([width, height], geometry)
            .center(center)
            .translate([width / 2, height / 2]);

          const path = d3.geoPath().projection(projection);
          const boundsPath = path.bounds(geometry);

          const hscale = width / (boundsPath[1][0] - boundsPath[0][0]);
          const vscale = height / (boundsPath[1][1] - boundsPath[0][1]);
          const scale = Math.min(hscale, vscale);

          const zoom = scale / 0.8;

          setPosition({ zoom, coordinates: center });
          setStrokeWidth(1 / zoom);
        } else {
          setPosition(initPos);
          setStrokeWidth(1);
        }
      } else {
        setPosition(initPos);
        setStrokeWidth(1);
      }
    } else {
      setPosition(initPos);
      setStrokeWidth(1);
    }
  }

  useEffect(() => countrySelect(countrySelected), [countrySelected]);

  return (
    <div className="h-auto">
      <div className="bg-white overflow-hidden" id="mapDiv">
        <ComposableMap projection="geoMercator">
          <ZoomableGroup
            zoom={position.zoom}
            center={position.coordinates}
            minZoom={0.5}
            maxZoom={5}
            onMoveEnd={(e) => {
              setPosition({ zoom: e.zoom, coordinates: e.coordinates });
              setStrokeWidth(1 / e.zoom);
            }}
          >
            <Geographies geography={geoFeatures}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={
                      countrySelected?.toLowerCase() ===
                      geo.properties.name?.toLowerCase()
                        ? "#D4D4D4"
                        : "#F3F3F3"
                    }
                    strokeWidth={strokeWidth}
                    stroke="#FFFFFF"
                    style={{
                      default: { outline: "none" },
                      hover: { outline: "none" },
                      pressed: { outline: "none" },
                    }}
                  />
                ))
              }
            </Geographies>
            {data?.map(
              ({ location, count, latitude, longitude }, index) =>
                latitude !== 0 &&
                longitude !== 0 &&
                location !== "Other" && (
                  <Marker
                    key={location}
                    coordinates={[longitude, latitude]}
                    className="cursor-pointer"
                  >
                    <LightTooltip
                      title={getTooltipContent(
                        count,
                        location,
                        getColorByIndex(index)
                      )}
                      placement="right-start"
                    >
                      <g fill="none" transform="translate(-14, -24)">
                        <circle cx="12" cy="10" r="3" />
                        <path
                          id="Vector"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.6156 27.7911C19.9935 23.4866 24.2483 17.2328 24.2483 13.0425C24.2483 6.09418 19.0103 0.461426 12.549 0.461426C6.08758 0.461426 0.849609 6.09418 0.849609 13.0425C0.849609 17.2328 5.10445 23.4866 8.48227 27.7911C10.2445 30.0369 11.1257 31.1597 12.549 31.1597C13.9722 31.1597 14.8534 30.0369 16.6156 27.7911ZM12.549 17.9987C10.1259 17.9987 8.1617 16.0358 8.1617 13.6144C8.1617 11.193 10.1259 9.23008 12.549 9.23008C14.972 9.23008 16.9362 11.193 16.9362 13.6144C16.9362 16.0358 14.972 17.9987 12.549 17.9987Z"
                          fill={getColorByIndex(index)}
                        />
                      </g>
                    </LightTooltip>
                  </Marker>
                )
            )}
          </ZoomableGroup>
        </ComposableMap>
      </div>
      <div className="pt-5 flex gap-5 px-5 flex-wrap">
        {data?.map(({ location, count }, index) => {
          const totalPopulation = data.reduce(
            (acc, marker) => acc + marker.count,
            0
          );
          const valueInK = count > 1000 ? count / 1000 : count;
          const percentage = (count / totalPopulation) * 100;

          return (
            <div key={location} className="flex items-center gap-2">
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: getColorByIndex(index) }}
              ></div>
              <div className="flex gap-2">
                <p className="text-black-100 font-ManropeBold text-ft3">
                  {location}
                </p>
                <p className="text-black-75 text-opacity-60 font-ManropeRegular text-ft3">
                  {valueInK > 1000 ? `${valueInK.toFixed(1)}k` : valueInK} (
                  {percentage.toFixed(2)}%)
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MapComponent;
