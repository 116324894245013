import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import "../src/assets/styles/custom.css";
import store from "./store.js";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: `https://${process.env.REACT_APP_SENTRY_KEY}.ingest.us.sentry.io/4507452005154816`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  </BrowserRouter>,
  document.getElementById("app")
);
