import React from "react";
import { ReactComponent as Delete } from "../../../../../assets/Icons/delete.svg";
import ShowToast from "../../../../../common/showToast";
import { toastType } from "../../../../../constant/commonArray";
import { ReactComponent as AddImage } from "../../../../../assets/Icons/plus-orange.svg";
import utility from "../../../../../utility";

/**
 * Component for managing text-based answers in a poll.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.state - Current state of the component.
 * @param {Function} props.setState - Function to update the component state.
 * @param {Function} props.setErrors - Function to update errors related to answers.
 * @param {Array} props.errors - Array containing error messages for each answer.
 * @returns {JSX.Element} Rendered component.
 */
const TypeText = ({ state, setState, setErrors, errors }) => {
  return (
    <div className="flex flex-col gap-5 w-full">
      {state?.options?.answers?.map((item, index) => (
        <div className="flex gap-5 items-center w-full" key={index}>
          <div className="flex flex-col gap-0.5 w-full">
            <div className="rounded-full bg-grey-50 w-full outline-none px-6 flex justify-between items-center h-16.25 text-black-100 font-ManropeRegular text-ft3">
              <input
                type="text"
                value={state?.options?.answers[index].value}
                placeholder={`${utility.ordinalText(index + 1)} answer`}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const newErrors = [...errors];
                  if (inputValue.length > 150) {
                    newErrors[index] =
                      "Answer should be less than 150 characters";
                  } else {
                    newErrors[index] = "";
                  }
                  setErrors(newErrors);
                  setState((prev) => ({
                    ...prev,
                    options: {
                      ...prev.options,
                      answers: prev?.options?.answers.map((value, i) =>
                        i === index
                          ? { ...value, value: e.target.value }
                          : value
                      ),
                    },
                  }));
                }}
                className="focus:outline-none bg-transparent w-95per"
              />
            </div>

            {errors[index] && (
              <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                {errors[index]}
              </div>
            )}
          </div>
          {state?.options?.answers?.length > 2 && (
            <Delete
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  options: {
                    ...prev.options,
                    answers: prev?.options?.answers.filter(
                      (_, i) => i !== index
                    ),
                  },
                }));
              }}
              className="cursor-pointer"
            />
          )}
        </div>
      ))}
      <div
        onClick={() => {
          if (state?.options?.answers?.length >= 6) {
            ShowToast({
              type: toastType.ERROR,
              message: "Can't add more than 6 answers",
            });
          } else {
            setState((prev) => ({
              ...prev,
              options: {
                ...prev.options,
                answers: [...prev.options.answers, { value: "" }],
              },
            }));
          }
        }}
        className="flex gap-2.5 items-center cursor-pointer pt-5"
      >
        <AddImage />
        <span className="text-orange-50 font-ManropeBold text-ft2">
          Add answer
        </span>
      </div>
    </div>
  );
};

export default TypeText;
