import React from "react";
import Table from "../../common/table";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import placeholder from "../../assets/Icons/Placeholder.svg";
import PlaceholderWnm from "../../assets/Icons/PlaceholderWnm.png";

/**
 * Active component displaying a table of active violations.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.loading - Loading state of the component.
 * @param {number} props.totalDocs - Total number of documents.
 * @param {function} props.setPageSize - Function to set the page size.
 * @param {number} props.totalPages - Total number of pages.
 * @param {number} props.sort - Sorting order.
 * @param {number} props.pageSize - Number of items per page.
 * @param {Array} props.data - Array of data to display in the table.
 * @param {function} props.setCurrentPage - Function to set the current page.
 * @param {number} props.currentPage - Current page number.
 * @param {function} props.handlePageChange - Function to handle page changes.
 * @param {function} props.setSort - Function to set the sorting order.
 * @returns {JSX.Element} JSX Element representing the Active component.
 */

const Active = ({
  searchQuery,
  loading,
  totalDocs,
  setPageSize,
  totalPages,
  sort,
  pageSize,
  data,
  setCurrentPage,
  currentPage,
  handlePageChange,
  setSort,
}) => {
  const history = useHistory();

  /**
   * Redirects to moderation details based on the content type.
   * @param {string} content - Content type (e.g., "POST", "COMMENT").
   * @param {string} id - Identifier of the violation.
   */

  const report = (content, id) => {
    if (content === "POST") {
      history.push(`/moderations/post/${id}?tab=ACTIVE`);
    } else if (content === "COMMENT") {
      history.push(`/moderations/comment/${id}?tab=ACTIVE`);
    } else {
      history.push(`/moderations/violationDetails/${id}?tab=ACTIVE`);
    }
  };

  const headers = [
    { name: " Last report", sort: true },
    { name: "Content type" },
    { name: "Violator" },
    { name: "Amount", sort: true },
  ];

  /**
   * Renders the table body based on the data.
   * @returns {JSX.Element} JSX Element representing the table body.
   */
  const renderTableBody = () => {
    if (data.length > 0 && !loading) {
      return data.map((item) => (
        <tr
          key={item?._id}
          onClick={() => report(item?.contentType, item?._id)}
          className="hover:bg-grey-350 cursor-pointer"
        >
          <td className="py-2.5 px-5 font-ManropeRegular text-ft2 text-black-50">
            <div className="w-37.5 truncate">
              {moment(item.lastReport).format("DD MMM[,] YYYY")}
            </div>
          </td>
          <td className="font-ManropeRegular text-ft2 text-black-50">
            <div className="w-37.5 truncate">
              {item.contentType
                ? item.contentType.charAt(0).toUpperCase() +
                  item.contentType.slice(1).toLowerCase()
                : ""}
            </div>
          </td>
          <td className="font-ManropeRegular text-ft2 text-black-50">
            <div className="w-37.5 truncate">
              {item.violater ? `${item.violater}` : ""}
            </div>
          </td>

          <td className="font-ManropeRegular text-ft2 text-black-50">
            <div className="w-37.5 truncate">
              {item.amount ? item.amount : "-"}
            </div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={5}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  return (
    <Table
      headers={headers}
      tableBody={renderTableBody()}
      loading={loading}
      totalPages={totalPages - 1}
      totalDocs={totalDocs}
      setPageSize={setPageSize}
      currentPage={currentPage}
      pageSize={pageSize}
      setCurrentPage={setCurrentPage}
      setSort={setSort}
      sort={sort}
      handlePageChange={handlePageChange}
    />
  );
};

export default Active;
