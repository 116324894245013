import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import placeholder from "../../../assets/Icons/Placeholder.svg";

/**
 * ChartWithBar component displays a bar chart with customizable data, colors, and axis keys.
 *
 * @component
 * @example
 * // Example usage of ChartWithBar component
 * <ChartWithBar
 *   data={[
 *     { location: "A", count: 50 },
 *     { location: "B", count: 30 },
 *     // ...more data
 *   ]}
 *   colors={["#EBC9F1", "#C3E9FC", "#BDD6AB"]}
 *   YAxisKey={"location"}
 *   BarKey={"count"}
 * />
 *
 * @param {Object[]} data - An array of objects containing data for the bar chart.
 * @param {string[]} colors - An array of colors to be used for each bar in the chart.
 * @param {string} YAxisKey - The key to access the Y-axis data in the provided data array.
 * @param {string} BarKey - The key to access the bar data in the provided data array.
 *
 * @returns {JSX.Element} The rendered ChartWithBar component.
 */

const ChartWithBar = ({ data, colors, YAxisKey, BarKey }) => {
  const sortedData = data.slice().sort((a, b) => b[BarKey] - a[BarKey]);

  return (
    <div className="flex justify-center items-center flex-grow">
      {sortedData?.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={sortedData}
            layout="vertical"
            margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
          >
            <XAxis type="number" hide axisLine={false} />
            <YAxis
              dataKey={YAxisKey}
              type="category"
              axisLine={false}
              allowDataOverflow
              tickLine={false}
              tickMargin={10}
            />
            <Bar dataKey={BarKey} barSize={20} fill="#8884d8">
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors?.length]}
                />
              ))}
            </Bar>
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: "transparent" }}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div className="flex justify-center items-center gap-7.5 h-full rounded-20">
          <img src={placeholder} alt="placeholder" />
        </div>
      )}
    </div>
  );
};

export default ChartWithBar;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const color = payload[0].payload.fill;

    return (
      <div className="flex flex-col bg-white rounded-20 p-5 shadow-md w-61 gap-2">
        <div className="flex items-center gap-2">
          <div
            style={{ backgroundColor: color }}
            className="w-3 h-3 rounded-full"
          ></div>
          <p className="text-black-100 font-ManropeBold text-ft3">
            {payload[0].name === "count" ? "Registrations" : payload[0].name}
          </p>
        </div>
        <p className="text-black-75 font-ManropeRegular text-ft3 pl-5">
          {payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
