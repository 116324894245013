import React, { useState } from "react";
import close from "../../assets/Icons/close.svg";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import { UserService } from "../../services";
import utility from "../../utility";
import { CircularProgress } from "@mui/material";

/**
 * EditIncomePopup is a component for editing income split rules.
 *
 * @component
 * @param {Object} props - React component props.
 * @param {Object} props.editRules - Edit rules object containing popup state and data.
 * @param {Function} props.setEditRules - Function to set the editRules state.
 * @param {Function} props.getIncomeData - Function to fetch income data.
 * @returns {JSX.Element} - The JSX element representing the EditIncomePopup.
 */

const numberRegex = new RegExp(/^\d+$/);

const EditIncomePopup = ({ editRules, setEditRules, getIncomeData }) => {
  /**
   * State to manage the input values for unit price, admin share, and creator share.
   * @type {Object}
   * @property {number} unitPrice - The unit price per vote in Dollar.
   * @property {number} adminShare - The admin part percentage.
   * @property {number} creatorShare - The creator part percentage.
   */

  const [state, setState] = useState({
    creatorShare: editRules?.data?.creatorShare || 0,
    adminShare: editRules?.data?.adminShare || 0,
  });
  const [loading, setLoading] = useState(false);

  /**
   * Handles the edit income operation by making an API call.
   *
   * @async
   * @returns {void}
   */
  const handleEditIncome = async () => {
    let response;
    setLoading(true);
    try {
      const requestData = {
        ...state,
        creatorShare: 100 - state?.adminShare,
      };
      response = await new UserService().editIncome(
        requestData,
        editRules?.data?._id
      );
      if (response?.statusCode === 400) {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      }
      getIncomeData();
      setEditRules((prev) => ({ ...prev, popup: false }));
      setLoading(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Renders the component JSX.
   *
   * @returns {JSX.Element} - The JSX element representing the EditIncomePopup component.
   */

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="flex flex-col bg-white rounded-20 shadow-md w-112.5 p-7.5">
        <div className="flex w-full justify-between items-center mb-5">
          <span className="font-ManropeBold text-ft4 text-black-100">
            Edit split rules ‘{utility.formatText(editRules?.data?.name)}’
          </span>
          <img
            onClick={() => setEditRules((prev) => ({ ...prev, popup: false }))}
            src={close}
            alt="close"
            className="cursor-pointer"
          />
        </div>

        <div className="flex flex-col gap-7.5 overflow-y-auto scrollbar-hide max-h-43per">
          <div className="flex flex-col">
            <label className="font-ManropeMedium text-ft3 text-black-100">
              Admin part %
            </label>
            <input
              type="text"
              value={state?.adminShare}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (
                  inputValue === "" ||
                  (numberRegex.test(inputValue) &&
                    inputValue >= 0 &&
                    inputValue <= 100)
                ) {
                  setState((prev) => ({
                    ...prev,
                    adminShare:
                      inputValue === "" ? "" : parseInt(inputValue, 10),
                  }));
                }
              }}
              className="h-16.25 px-6.25 rounded-full bg-grey-50 focus:outline-none"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-ManropeMedium text-ft3 text-black-100">
              Creator part%
            </label>
            <input
              type="text"
              value={100 - state?.adminShare}
              disabled
              className="h-16.25 px-6.25 rounded-full bg-grey-50 focus:outline-none"
            />
          </div>
        </div>
        <button
          onClick={handleEditIncome}
          disabled={
            !state?.adminShare ||
            loading ||
            // !state?.unitPrice ||
            state?.adminShare === editRules?.data?.adminShare
          }
          className={`h-16.25 px-6.25 rounded-full ${
            !state?.adminShare ||
            // !state?.unitPrice ||
            loading ||
            state?.adminShare === editRules?.data?.adminShare
              ? "bg-grey-50"
              : "bg-orange-50"
          }  mt-7.5 text-black-100 font-ManropeBold text-ft2`}
        >
          {loading ? (
            <div className="flex justify-center  items-center h-full w-full">
              <CircularProgress className="text-orange-50" />
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditIncomePopup;
