import React, { useState } from "react";
import { ReactComponent as Close } from "../assets/Icons/close.svg";
import ArrowLeft from "../assets/Icons/arrow-left.svg";
import ArrowRight from "../assets/Icons/arrow-right.svg";
import { Document, Page, pdfjs } from "react-pdf";
import FileViewer from "react-file-viewer";
import { CircularProgress } from "@mui/material";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

/**
 * ImagePopUp component for displaying a popup with image gallery.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {boolean} props.toggleImagePopUp - Flag to toggle the image popup.
 * @param {Function} props.setToggleImagePopUp - Function to set the state of the image popup.
 * @param {Array} props.images - Array of images to display in the gallery.
 * @returns {JSX.Element} ImagePopUp component.
 */
const ImagePopUp = ({ toggleImagePopUp, setToggleImagePopUp, images }) => {
  const [imageChange, setImageChange] = useState(0);
  const [numPages, setNumPages] = useState(null);

  const handleNavigation = (direction) => {
    setImageChange((prev) =>
      direction === "next"
        ? Math.min(prev + 1, images.length - 1)
        : Math.max(prev - 1, 0)
    );
  };

  const getImageSrc = () => {
    const image = images[imageChange];
    return image instanceof File ? URL.createObjectURL(image) : image.url;
  };

  const getFileType = () => {
    const image = images[imageChange];
    return image instanceof File
      ? image.name.split(".").pop()
      : image.type.substring(1);
  };

  const renderContent = () => {
    const image = images[imageChange];
    if (image.type.includes("pdf")) {
      return (
        <Document
          file={{ url: getImageSrc() }}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          onContextMenu={(e) => e.preventDefault()}
          loading={
            <div className="flex justify-center  items-center h-full w-full">
              <CircularProgress className="text-black-100 w-5 h-5" />
            </div>
          }
        >
          {Array.from({ length: numPages }, (_, index) => (
            <Page
              key={index}
              pageNumber={index + 1}
              loading={
                <div className="flex justify-center  items-center h-full w-full">
                  <CircularProgress className="text-black-100 w-5 h-5" />
                </div>
              }
            />
          ))}
        </Document>
      );
    }

    if (image.type.includes("docx") || image.type.includes("doc")) {
      return (
        <FileViewer
          fileType={getFileType()}
          filePath={getImageSrc()}
          onError={(e) => console.error(e)}
        />
      );
    }

    return (
      <img src={getImageSrc()} alt="" className="h-106.25 w-172.5 rounded-20" />
    );
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 fixed right-0 top-0 h-full w-full">
      <div className="flex justify-end pt-7.5 px-7.5">
        <Close
          onClick={() => setToggleImagePopUp(!toggleImagePopUp)}
          className="cursor-pointer"
        />
      </div>
      <div className="flex justify-end items-center h-full w-full">
        <div className="bg-white relative h-106.25 w-172.5 overflow-x-hidden overflow-y-auto flex flex-col z-50 cursor-pointer rounded-20">
          {renderContent()}
        </div>
        <div className="w-30per flex justify-end">
          <div className="flex pr-7.5 gap-2.5 flex-col">
            <span className="flex w-full justify-center">{`${imageChange + 1}/${
              images.length
            }`}</span>
            <div
              onClick={() => handleNavigation("next")}
              className="bg-white w-11 h-11 rounded-xl flex justify-center items-center cursor-pointer"
            >
              <img src={ArrowRight} alt="Next" />
            </div>
            <div
              onClick={() => handleNavigation("prev")}
              className="bg-white w-11 h-11 rounded-xl flex justify-center items-center cursor-pointer"
            >
              <img src={ArrowLeft} alt="Previous" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePopUp;
