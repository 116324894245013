import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";
import { useSelector } from "react-redux";

/**
 * EditLocationPopup component for editing user information.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {string} props.name - The name of the field being edited.
 * @param {string} props.value - The current value of the field being edited.
 * @param {Function} props.setEditPopup - Function to set the edit popup state.
 * @param {Function} props.setState - Function to set the state of the component.
 * @param {string} props.keyName - The key name to identify the type of field being edited.
 * @param {Object} props.state - The state of the component.
 * @param {Function} props.handleSubmission - Function to handle the submission of edited information (default: () => {}).
 * @param {string} props.flagSvg - The SVG code for the country flag.
 * @param {Array} props.arr - Array of countries for rendering the country code dropdown.
 * @param {string} props.countryCode - The country code.
 * @param {boolean} props.loading - Flag to indicate loading state (default: false).
 * @returns {JSX.Element} EditLocationPopup component.
 */

const EditLocationPopup = ({
  name,
  value,
  setEditPopup,
  setState,
  keyName,
  state,
  handleSubmission = () => {},
  loading = false,
}) => {
  const user = useSelector((state) => state.user);
  const handleChange = (address) => {
    setState((prev) => ({
      ...prev,
      location: {
        ...prev.location,
        name: address,
      },
    }));
  };
  const handleSelect = (address) => {
    const parts = address.split(",").map((part) => part.trim());
    setState((prev) => ({
      ...prev,
      cityName: parts[0],
      countryName: parts[parts.length - 1],
    }));

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setState((prev) => ({
          ...prev,
          location: {
            ...prev.location,
            name: address,
            latitude: latLng.lat,
            longitude: latLng.lng,
          },
        }));
      })
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    if (keyName === "location") {
      setState((prev) => ({
        ...prev,
        name: value,
      }));
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white  relative flex flex-col h-2/3 w-115 z-50 p-7.5 rounded-20 ">
        <div className="flex justify-between">
          <span className="text-black-100 font-ManropeBold text-ft4">
            {`Edit ${name}`}
          </span>
          <Close
            onClick={() => setEditPopup(null)}
            className="cursor-pointer"
          />
        </div>
        <div className="mt-7.5 w-full flex flex-col justify-between h-full">
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              {name}
            </label>
            {user.gmapsLoaded && (
              <PlacesAutocomplete
                value={state?.location?.name}
                onChange={handleChange}
                onSelect={handleSelect}
                searchOptions={{ types: ["locality"] }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="relative">
                    <input
                      {...getInputProps({
                        placeholder: "Start type",
                        className:
                          "w-full rounded-full bg-grey-50 outline-none px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3",
                      })}
                    />
                    <div className="max-h-56 overflow-y-auto shadow-xl bg-white w-full mt-2 left-0 rounded-20 absolute">
                      {loading && (
                        <div className="flex justify-center  items-center h-56 w-full">
                          <CircularProgress className="text-black-100 w-5 h-5" />
                        </div>
                      )}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active p-5"
                          : "suggestion-item p-5";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            )}
          </div>

          <div>
            <button
              disabled={value === state?.location?.name || loading}
              onClick={() => handleSubmission()}
              className={`
          rounded-full w-full mt-auto h-16.25 text-black-100 font-ManropeBold text-ft2 ${
            value === state?.location?.name || loading
              ? "bg-grey-50"
              : "bg-orange-50"
          }`}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLocationPopup;
