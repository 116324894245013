import moment from "moment";

const utility = {
  parseResponse,
  getTimeDifference,
  getStatus,
  getDefaultTime,
  capitalizeFirstLetter,
  validateEmail,
  getSuspensionType,
  getDataFromLocalStorage,
  storeToLocalStorage,
  formatNumber,
  formatText,
  formatDate,
  addDays,
  extractFileExtension,
  formatMonth,
  getEpochTime,
  calculateRemainingTime,
  getFileType,
  getRandomColors,
  ordinalText,
};
export default utility;

function parseResponse(promise) {
  return promise
    .then((data) => {
      return [null, data];
    })
    .catch((err) => [err]);
}

function getTimeDifference(timeStampTo) {
  let minFive = 300000;
  let oneDay = 86400000;
  let difference = "";
  let am = " AM";
  let pm = " PM";
  let hh = epochToDate(timeStampTo, "hh");
  let mm = epochToDate(timeStampTo, "mm");
  let dateFormat = epochToDate(timeStampTo, "DD MMM YYYY");
  let hours = new Date(timeStampTo).getHours();
  let timeDifference = new Date().getTime() - timeStampTo;
  if (timeDifference < oneDay) {
    if (timeDifference < minFive) {
      difference = "Just Now";
    } else {
      if (hours < 12) difference = "Today at " + hh + ":" + mm + am;
      else difference = "Today at " + hh + ":" + mm + pm;
    }
  } else {
    if (hours < 12) difference = dateFormat + ", " + hh + ":" + mm + am;
    else difference = dateFormat + ", " + hh + ":" + mm + pm;
  }
  return difference;
}

function epochToDate(timeStamp, timeFormat) {
  timeStamp = Math.floor(timeStamp);
  let dateObject = new Date(timeStamp);
  return moment(dateObject).format(timeFormat);
}

function getStatus(startDate, endDate) {
  const now = Date.now();

  if (startDate > now) {
    return "Scheduled";
  } else if (endDate < now) {
    return "Closed";
  } else {
    return "Active";
  }
}

function getDefaultTime() {
  const defaultTime = new Date();
  defaultTime.setHours(0, 0, 0, 0);
  return defaultTime.toTimeString().slice(0, 5);
}

function capitalizeFirstLetter(str) {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
function validateEmail(email) {
  let reg = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
  return reg.test(email);
}

export const getRandomColor = () => {
  const colors = ["#EBC9F1", "#C3E9FC", "#BDD6AB", "#F6C0B8", "#FFE8AE"];
  let usedColors = new Set();

  return () => {
    if (usedColors.size === colors.length) {
      usedColors.clear();
    }

    let color;
    do {
      color = colors[Math.floor(Math.random() * colors.length)];
    } while (usedColors.has(color));

    usedColors.add(color);
    return color;
  };
};

const currentDate = new Date();
const daysInMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() + 1,
  0
).getDate();

function getSuspensionType(type, startDate, endDate) {
  if (type === "PERMANENT") {
    return "Permanent";
  } else if (type === "CUSTOM") {
    const startDateEpoch = startDate;
    const endDateEpoch = endDate;

    if (startDateEpoch && endDateEpoch) {
      const startDateTime = new Date(startDateEpoch);
      const endDateTime = new Date(endDateEpoch);
      const timeDifference = endDateTime - startDateTime;
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      const monthsDifference = daysDifference / daysInMonth;
      const weeksDifference = daysDifference / 7;

      if (daysDifference === 1) {
        return "1 day";
      } else if (monthsDifference === 1) {
        return "1 month";
      } else if (weeksDifference === 1) {
        return "1 week";
      } else {
        return "Custom";
      }
    }
  }

  return null;
}

function storeToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

function getDataFromLocalStorage(key) {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}

function formatNumber(value) {
  if (value >= 1000) {
    return (value / 1000).toFixed(1) + "k";
  } else {
    return value;
  }
}

function formatText(text) {
  return text
    ?.split("_")
    ?.map(
      (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
    )
    ?.join(" ");
}

function addDays(date, days) {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
}

function formatDate(date) {
  return date
    ? `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    : "";
}

function extractFileExtension(fileName) {
  const parts = fileName.split(".");
  return parts.length > 1 ? parts[parts.length - 1] : "";
}

function formatMonth(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date?.getMonth()];

  return month;
}

function getEpochTime(date, time) {
  const combinedDateTime = `${date.toISOString().split("T")[0]}T${time}`;
  let dateTime = new Date(combinedDateTime);
  dateTime.setDate(dateTime.getDate() + 1);

  return dateTime.getTime();
}

function calculateRemainingTime(endDate) {
  const currentTime = Date.now();
  const timeDifference = endDate - currentTime;
  const remainingMilliseconds = Math.max(0, timeDifference);
  const remainingHours =
    Math.floor(remainingMilliseconds / (1000 * 60 * 60)) % 24;
  const remainingDays = Math.floor(
    remainingMilliseconds / (1000 * 60 * 60 * 24)
  );

  return { remainingDays, remainingHours };
}

function getFileType(filename) {
  const extension = filename.split(".").pop().toLowerCase();
  if (["mp3"].includes(extension)) {
    return "audio";
  } else if (["jpg", "jpeg", "png"].includes(extension)) {
    return "image";
  } else if (["gif"].includes(extension)) {
    return "gif";
  } else if (["mp4"].includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
}

function getRandomColors(index) {
  const colors = ["#EBC9F1", "#C3E9FC", "#BDD6AB", "#F6C0B8", "#FFE8AE"];
  return colors[index % colors.length];
}

function ordinalSuffix(index) {
  const remainderTen = index % 10;
  const remainderHundred = index % 100;

  if (remainderTen === 1 && remainderHundred !== 11) {
    return `${index}st`;
  } else if (remainderTen === 2 && remainderHundred !== 12) {
    return `${index}nd`;
  } else if (remainderTen === 3 && remainderHundred !== 13) {
    return `${index}rd`;
  } else {
    return `${index}th`;
  }
}

function ordinalText(index) {
  const words = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];
  if (index >= 0 && index < words.length) {
    return words[index];
  } else {
    return ordinalSuffix(index);
  }
}
