import React, { useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import left from "../../../assets/Icons/Left.svg";
import copy from "../../../assets/Icons/copy.svg";
import error from "../../../assets/Icons/Error.svg";
import more from "../../../assets/Icons/More.svg";
import ShowToast from "../../../common/showToast";
import OutsideClickRef from "../../../common/outsideClickRef";
import { toastType } from "../../../constant/commonArray";
import { useSelector } from "react-redux";

/**
 * React component representing the header section of a poll.
 *
 * @component
 * @example
 * import PollHeader from "./PollHeader";
 *
 * const MyPollComponent = () => {
 *   return (
 *     <PollHeader
 *       pollState="polls"
 *       setPollState={handleSetPollState}
 *       likes={[]}
 *       comment={[]}
 *       repost={[]}
 *       votes={[]}
 *       setDisablePopUp={handleSetDisablePopUp}
 *       setShowClickableOption={handleSetShowClickableOption}
 *       showClickableOption={false}
 *       url="/polls"
 *     />
 *   );
 * };
 *
 * @param {Object} props - The component props.
 * @param {string} props.pollState - The current poll state ("polls", "likes", "comments", "reposts", "voted").
 * @param {Function} props.setPollState - Function to set the poll state.
 * @param {Array} props.likes - Array of liked items.
 * @param {Array} props.comment - Array of comments.
 * @param {Array} props.repost - Array of reposted items.
 * @param {Array} props.votes - Array of voted items.
 * @param {Function} props.setDisablePopUp - Function to set the disable pop-up state.
 * @param {Function} props.setShowClickableOption - Function to set the show clickable option state.
 * @param {boolean} props.showClickableOption - State indicating whether the clickable options are visible.
 * @param {string} props.url - The URL for the poll.
 * @returns {React.Component} The rendered PollHeader component.
 */

const PollHeader = ({
  pollState,
  setPollState,
  setDisablePopUp,
  setShowClickableOption,
  showClickableOption,
  url,
  details,
  permission,
  setCurrentPage = () => {},
}) => {
  const history = useHistory();
  const optionsRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  /**
   * Displays a toast message indicating that the link has been copied to the clipboard.
   *
   * @function
   * @private
   * @name copyToClipboard
   * @returns {void}
   */
  function copyToClipboard(url) {
    navigator.clipboard.writeText(url);
    ShowToast({ message: "Link copied", type: toastType.SUCCESS });
  }

  return (
    <div className="mt-5 h-21.25 px-5 py-10 flex gap-2.5 xl:gap-7.5 items-center rounded-20 bg-white row-start-1 row-span-1 relative">
      <img
        src={left}
        alt="left"
        className="cursor-pointer"
        onClick={() => {
          history.goBack(-1);
        }}
      />
      <div className="flex justify-between flex-1 flex-shrink-0 flex-basis-0">
        <p
          className={`cursor-pointer ${
            pollState === "polls"
              ? "font-ManropeBold text-ft5 text-black-100"
              : "font-ManropeRegular text-ft3 text-black-350 cursor-pointer"
          }`}
          onClick={() => {
            setPollState("polls");
          }}
        >
          Poll
        </p>
        <div className="flex gap-1">
          <p
            className={`cursor-pointer ${
              pollState === "likes"
                ? "font-ManropeBold text-ft5 text-black-100"
                : "font-ManropeRegular text-ft3 text-black-350 cursor-pointer"
            }`}
            onClick={() => {
              setPollState("likes");
              setCurrentPage(0);
            }}
          >
            Likes
          </p>
          <p
            className={`font-ManropeBold text-ft3 text-black-${
              pollState === "likes" ? "100" : "350"
            }`}
          >
            {details?.likes}
          </p>
        </div>
        <div className="flex  gap-1">
          <p
            className={`cursor-pointer ${
              pollState === "comments"
                ? "font-ManropeBold text-ft5 text-black-100"
                : "font-ManropeRegular text-ft3 text-black-350 cursor-pointer"
            }`}
            onClick={() => {
              setPollState("comments");
              setCurrentPage(0);
            }}
          >
            Comments
          </p>
          <p
            className={`font-ManropeBold text-ft3 text-black-${
              pollState === "comments" ? "100" : "350"
            }`}
          >
            {details?.comments}
          </p>
        </div>
        <div className="flex  gap-1">
          <p
            className={`cursor-pointer ${
              pollState === "reposts"
                ? "font-ManropeBold text-ft5 text-black-100"
                : "font-ManropeRegular text-ft3 text-black-350 cursor-pointer"
            }`}
            onClick={() => {
              setPollState("reposts");
              setCurrentPage(0);
            }}
          >
            Reposts
          </p>
          <p
            className={`font-ManropeBold text-ft3 text-black-${
              pollState === "reposts" ? "100" : "350"
            }`}
          >
            {details?.reposts}
          </p>
        </div>
        <div className="flex  gap-1">
          <p
            className={`cursor-pointer ${
              pollState === "voted"
                ? "font-ManropeBold text-ft5 text-black-100"
                : "font-ManropeRegular text-ft3 text-black-350 cursor-pointer"
            }`}
            onClick={() => {
              setPollState("voted");
              setCurrentPage(0);
            }}
          >
            Voted
          </p>
          <p
            className={`font-ManropeBold text-ft3 text-black-${
              pollState === "voted" ? "100" : "350"
            }`}
          >
            {details?.votes}
          </p>
        </div>
        <img
          src={more}
          alt="more"
          className="cursor-pointer"
          onClick={() => setShowClickableOption(!showClickableOption)}
        />
        {showClickableOption && (
          <div className="absolute right-4 top-15 flex flex-col items-start w-75 rounded-20 shadow-inner z-10 bg-white">
            <OutsideClickRef
              state={optionsRef}
              setState={() => setShowClickableOption(false)}
            >
              <button
                className="flex h-16.25 w-75 px-5 items-center gap-3.75 rounded-t-20 hover:bg-grey-350"
                onClick={() => copyToClipboard(window.location.href)}
              >
                <img src={copy} alt="copy" />
                <p className="text-ft3 font-ManropeLight text-black-100">
                  Copy Link
                </p>
              </button>
              <hr className="text-grey-50 w-full" />
              {userPermissions?.indexOf(permission) > -1 && (
                <div
                  onClick={() => {
                    if (setDisablePopUp != null) {
                      setDisablePopUp(true);
                    }
                  }}
                  className="flex h-16.25 w-75 px-5 items-center gap-3.75 rounded-b-20 hover:bg-grey-350 cursor-pointer"
                >
                  <img src={error} alt="error" />
                  <button className="text-ft3 font-ManropeLight text-black-100">
                    Disable Poll
                  </button>
                </div>
              )}
            </OutsideClickRef>
          </div>
        )}
      </div>
    </div>
  );
};

export default PollHeader;
