import React from "react";

const PageNotFound = () => {
  return (
    <div className="flex justify-center mt-10 h-full bg-white">
      <span className="text-ft7 text-black-100 font-ManropeBold">
        404 Page Not Found
      </span>
    </div>
  );
};

export default PageNotFound;
