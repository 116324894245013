/**
 * React component representing a login page wrapper.
 * @function
 * @module LoginComponent
 * @returns {JSX.Element} JSX for the LoginComponent.
 */

import React, { useEffect } from "react";
import Login from "./login";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sessionManager } from "../../managers/sessionManager";
import { keyTypeConstants, permissionRoutes } from "../../constant/commonArray";
const { AUTH0_ID_TOKEN } = keyTypeConstants;

const LoginComponent = () => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const getToken = sessionManager.getDataFromCookies(AUTH0_ID_TOKEN);
  /**
   * Renders the LoginComponent.
   * @method
   * @memberof LoginComponent
   * @returns {JSX.Element} JSX for the LoginComponent.
   */

  const userPermissions = user?.roleDetails?.access?.map((permission) =>
    permission?.toLowerCase()?.replace(/\s+/g, "")
  );

  const matchingRoute = Object?.entries(permissionRoutes)?.find(
    ([permission]) =>
      userPermissions?.includes(permission?.toLowerCase()?.replace(/\s+/g, ""))
  );

  useEffect(() => {
    if (getToken) history.push(matchingRoute[1] || "/profile");
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="bg-grey-50 w-full h-full">
      <Login />
    </div>
  );
};

export default LoginComponent;
