import React, { useEffect, useRef, useState } from "react";
import checkMark from "../assets/Icons/checkMark.svg";
import WarningPopUp from "./warningPopup";
import { race } from "../constant/commonArray";
import { UserService } from "../services";
import utility from "../utility";
import { useDebouncedCallback } from "use-debounce";
import { CircularProgress } from "@mui/material";
import placeholder from "../assets/Icons/Placeholder.svg";
import OutsideClickRef from "./outsideClickRef";
import Close from "../assets/Icons/close.svg";
import chevronDown from "../assets/Icons/Chevrondown.svg";
import chevronUp from "../assets/Icons/chevronUp.svg";

/**
 * AudienceReach component for configuring audience reach settings.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.setState - Function to set the state of the component.
 * @param {Object} props.state - The current state of the component.
 * @param {Function} props.handleCreateAds - Function to handle the creation of ads.
 * @param {Function} props.setSteps - Function to set the steps in the ad creation process.
 * @param {boolean} props.creating - Boolean indicating whether the ads are currently being created.
 * @returns {JSX.Element} AudienceReach component.
 */

const AudienceReach = ({
  setState,
  state,
  handleCreateAds = () => {},
  setSteps = () => {},
  creating,
}) => {
  const [isMarked, setMarked] = useState(
    state?.audienceReach?.verifiedUsers?.includes(true) || false
  );
  const [isCancelPopUp, setCancelPopUp] = useState(false);
  const gender = ["Nonbinary", "Female", "Male"];
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const age = ["18-24", "25-34", "35-44", "45-54", "55 and older"];
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 500);

  const getLocation = async () => {
    setLoading(true);

    try {
      let requestParam = {
        locationName: debounceValue,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().getLocation(requestParam)
      );
      if (error || !response) return;
      setLocation(response);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    debouncedSearch(value);
    setDropDownOpen(true);
  };

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line
  }, [debounceValue]);

  return (
    <div className="flex flex-col gap-7.5">
      <hr className="text-grey-50" />
      <div className="flex flex-col gap-2.5 px-10 justify-center">
        <p className="text-black-100 font-ManropeMedium text-ft3">Audience</p>
        <div className="flex justify-between">
          <div className="flex gap-2.5">
            <p
              onClick={() =>
                setState((prev) => {
                  const currentAudience = prev.audienceReach?.audience || [];
                  const updatedAudience = currentAudience.includes(
                    "Contributers"
                  )
                    ? currentAudience.filter(
                        (value) => value !== "Contributers"
                      )
                    : [...currentAudience, "Contributers"];

                  return {
                    ...prev,
                    audienceReach: {
                      ...prev.audienceReach,
                      audience: updatedAudience,
                    },
                  };
                })
              }
              className={`flex rounded-20 cursor-pointer ${
                state?.audienceReach?.audience?.includes("Contributers")
                  ? "bg-orange-50 font-ManropeBold"
                  : "bg-grey-50 font-ManropeRegular"
              } px-2.5 py-1.25 text-black-100 text-ft2`}
            >
              Contributers
            </p>
            <p
              onClick={() =>
                setState((prev) => {
                  const currentAudience = prev.audienceReach?.audience || [];
                  const updatedAudience = currentAudience.includes("Entities")
                    ? currentAudience.filter((value) => value !== "Entities")
                    : [...currentAudience, "Entities"];

                  return {
                    ...prev,
                    audienceReach: {
                      ...prev.audienceReach,
                      audience: updatedAudience,
                    },
                  };
                })
              }
              className={`flex rounded-20 cursor-pointer ${
                state?.audienceReach?.audience?.includes("Entities")
                  ? "bg-orange-50 font-ManropeBold"
                  : "bg-grey-50 font-ManropeRegular"
              } px-2.5 py-1.25 text-black-100 text-ft2`}
            >
              Entities
            </p>
          </div>
          <div className="relative flex items-center">
            <input
              type="checkbox"
              onChange={(e) => {
                setMarked(!isMarked);
                const isChecked = e.target.checked;
                setState((prev) => ({
                  ...prev,
                  audienceReach: {
                    ...prev.audienceReach,
                    verifiedUsers: isChecked
                      ? [...(prev?.audienceReach?.verifiedUsers || []), true]
                      : prev.audienceReach?.verifiedUsers?.filter(
                          (value) => value !== true
                        ),
                  },
                }));
              }}
              checked={state?.audienceReach?.verifiedUsers?.includes(true)}
              className={`appearance-none w-6 h-6 rounded-lg bg-white border-2 border-grey-100 cursor-pointer ${
                isMarked
                  ? "bg-orange-50 border-none"
                  : "bg-white border-2 border-grey-100"
              }`}
            />
            <p className="text-black-100 font-ManropeRegular text-ft3 ml-2.5">
              Only verified users
            </p>
            {isMarked ? (
              <img
                onClick={() => setMarked(!isMarked)}
                className="absolute cursor-pointer"
                src={checkMark}
                alt="checkMark"
              ></img>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10">
        <p className="text-black-100 font-ManropeMedium text-ft3">Location</p>
        <div className="relative h-16.25 w-1/2 rounded-full flex items-center justify-between bg-grey-50 px-5">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            placeholder="Start typing"
            className="focus:outline-none text-black-100 text-ft3 font-ManropeRegular rounded-full bg-grey-50 w-full px-1"
          />
          <img
            src={dropDownOpen ? chevronDown : chevronUp}
            alt="chevronDown"
            className="cursor-pointer"
            onClick={() => setDropDownOpen(!dropDownOpen)}
          />

          {!loading && dropDownOpen ? (
            <OutsideClickRef state={dropdownRef} setState={setDropDownOpen}>
              <div className="absolute top-18 max-h-55 overflow-y-auto z-10 left-0 right-0 shadow-inner bg-white rounded-20 w-full">
                {location?.length > 0 ? (
                  location?.map((item) => (
                    <div className="flex gap-2.5 px-5 py-2.5 cursor-pointer justify-between">
                      <div className="flex justify-between">
                        <div className="flex gap-3.75">
                          <div className="flex flex-col">
                            <p className="text-black-100 font-ManropeBold text-ft3">
                              {item?.country}
                            </p>
                            <p className="text-black-600 font-ManropeRegular text-ft2">
                              {item?.countOfUsers}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex items-center">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setState((prev) => {
                              const updatedLocation = isChecked
                                ? [
                                    ...(prev?.audienceReach?.location || []),
                                    item?.country,
                                  ]
                                : (prev.audienceReach?.location || []).filter(
                                    (location) => location !== item?.country
                                  );

                              return {
                                ...prev,
                                audienceReach: {
                                  ...prev.audienceReach,
                                  location: updatedLocation,
                                },
                              };
                            });
                          }}
                          checked={state?.audienceReach?.location?.includes(
                            item?.country
                          )}
                          className={`appearance-none w-6 h-6 rounded-lg bg-white border-2 border-grey-100 cursor-pointer ${
                            state?.audienceReach?.location?.includes(
                              item?.country
                            )
                              ? "bg-orange-50 border-none"
                              : "bg-white border-2 border-grey-100"
                          }`}
                        />
                        {state?.audienceReach?.location?.includes(
                          item?.country
                        ) ? (
                          <img
                            onClick={() => {
                              setState((prev) => {
                                const updatedLocation = (
                                  prev?.audienceReach?.location || []
                                ).filter(
                                  (location) => location !== item?.country
                                );

                                return {
                                  ...prev,
                                  audienceReach: {
                                    ...prev.audienceReach,
                                    location: updatedLocation,
                                  },
                                };
                              });
                            }}
                            className="absolute cursor-pointer"
                            src={checkMark}
                            alt="checkMark"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
                    <img src={placeholder} alt="placeholder" className="h-50" />
                  </div>
                )}
              </div>
            </OutsideClickRef>
          ) : (
            loading &&
            dropDownOpen && (
              <div className="absolute top-18 h-40 overflow-y-auto z-10 shadow-inner bg-white rounded-20 w-full">
                <div className="flex justify-center  items-center h-full w-full">
                  <CircularProgress className="text-black-100 w-5 h-5" />
                </div>
              </div>
            )
          )}
        </div>
        <div className="mt-2.5 flex gap-3 flex-wrap">
          {state?.audienceReach?.location?.map((item) => (
            <div className="flex justify-center items-center px-4 py-2.5 rounded-20 bg-orange-50 min-w-min">
              <span className="font-ManropeBold text-ft1 text-black-50">
                {item}
              </span>
              <img
                onClick={() => {
                  setState((prev) => {
                    const updatedLocation = (
                      prev?.audienceReach?.location || []
                    ).filter((location) => location !== item);

                    return {
                      ...prev,
                      audienceReach: {
                        ...prev.audienceReach,
                        location: updatedLocation,
                      },
                    };
                  });
                }}
                src={Close}
                alt=""
                className="w-4 h-4 cursor-pointer"
              />
            </div>
          ))}
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col gap-2.5 px-10 justify-center">
        <p className="text-black-100 font-ManropeMedium text-ft3">Gender</p>
        <div className="flex gap-2.5">
          {gender?.map((item) => (
            <p
              onClick={() =>
                setState((prev) => {
                  const currentAudience = prev.audienceReach?.gender || [];
                  const updatedAudience = currentAudience.includes(item)
                    ? currentAudience.filter((value) => value !== item)
                    : [...currentAudience, item];

                  return {
                    ...prev,
                    audienceReach: {
                      ...prev.audienceReach,
                      gender: updatedAudience,
                    },
                  };
                })
              }
              className={`flex rounded-20 cursor-pointer ${
                state?.audienceReach?.gender?.includes(item)
                  ? "bg-orange-50 font-ManropeBold"
                  : "bg-grey-50 font-ManropeRegular"
              } px-2.5 py-1.25 text-black-100 text-ft2`}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col gap-2.5 px-10 justify-center">
        <p className="text-black-100 font-ManropeMedium text-ft3">Age</p>
        <div className="flex gap-2.5">
          {age?.map((item) => (
            <p
              onClick={() =>
                setState((prev) => {
                  const currentAudience = prev.audienceReach?.age || [];
                  const updatedAudience = currentAudience.includes(item)
                    ? currentAudience.filter((value) => value !== item)
                    : [...currentAudience, item];

                  return {
                    ...prev,
                    audienceReach: {
                      ...prev.audienceReach,
                      age: updatedAudience,
                    },
                  };
                })
              }
              className={`flex rounded-20 cursor-pointer ${
                state?.audienceReach?.age?.includes(item)
                  ? "bg-orange-50 font-ManropeBold"
                  : "bg-grey-50 font-ManropeRegular"
              } px-2.5 py-1.25 text-black-100 text-ft2`}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col gap-2.5 px-10 justify-center">
        <p className="text-black-100 font-ManropeMedium text-ft3">Race</p>
        <div className="flex flex-wrap w-2/3 gap-2.5">
          {race?.map((item) => (
            <p
              onClick={() =>
                setState((prev) => {
                  const currentAudience = prev.audienceReach?.race || [];
                  const updatedAudience = currentAudience.includes(item)
                    ? currentAudience.filter((value) => value !== item)
                    : [...currentAudience, item];

                  return {
                    ...prev,
                    audienceReach: {
                      ...prev.audienceReach,
                      race: updatedAudience,
                    },
                  };
                })
              }
              className={`flex rounded-20 cursor-pointer ${
                state?.audienceReach?.race?.includes(item)
                  ? "bg-orange-50 font-ManropeBold "
                  : "bg-grey-50 font-ManropeRegular"
              } px-2.5 py-1.25 text-black-100 text-ft2`}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
      <hr className="text-grey-50" />
      <footer className="flex justify-end items-end pr-7.5 gap-5 rounded-20 bg-white">
        <button
          onClick={() => setSteps(1)}
          className="flex justify-center items-center rounded-full border border-solid border-black-20 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
        >
          Cancel
        </button>
        <button
          disabled={creating}
          onClick={handleCreateAds}
          className={`flex justify-center items-center rounded-full border border-solid border-transparent ${
            creating ? "bg-grey-50" : "bg-orange-50"
          }  h-16.25 w-55 text-black-100 font-ManropeBold text-ft2`}
        >
          {creating ? (
            <CircularProgress className="text-orange-50" />
          ) : (
            "Create"
          )}
        </button>
      </footer>
      {isCancelPopUp && (
        <WarningPopUp
          title={"Discard changes ?"}
          description={"All entered data will be deleted"}
          setToggle={setCancelPopUp}
        />
      )}
    </div>
  );
};

export default AudienceReach;
