import React, { useEffect, useState } from "react";
import PollType from "../../../assets/Icons/poll-type-icon.svg";
import CoverMedia from "./coverMedia";
import defaultImage from "../../../assets/Icons/signupProfileSmall.png";
import checkMark from "../../../assets/Icons/checkMark.svg";
import utility from "../../../utility";
import LearnMore from "./learnMore";
import OtherLink from "../../../assets/Icons/other-link-white.svg";
import AudioWhite from "../../../assets/Icons/audio-white.svg";
import LinkPreview from "../createPoll/linkPreview";
import AudioPreview from "../createPoll/audioPreview";

/**
 * Renders the preview of a poll or survey with interactive elements.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setStartPollIndex - Function to set the starting index of polls
 * @param {string} [props.type="POLL"] - Type of preview ('POLL' or 'SURVEY')
 * @param {number} props.startPollIndex - Index of the starting poll
 * @param {Array} props.polls - Array of polls/surveys data
 * @param {Function} props.setPreview - Function to control the preview state
 * @param {Object} props.data - Poll/survey data
 * @param {Object} props.account - User account data
 * @param {Array} props.images - Array of images
 * @param {Date} props.surveyTime - Survey end time
 * @returns {JSX.Element}
 */

const PollPreviewText = ({
  setStartPollIndex,
  type = "POLL",
  startPollIndex,
  polls,
  setPreview,
  data,
  account,
  images,
  surveyTime,
}) => {
  const [isLearnMore, setIsLearnMore] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const [selectedData, setSelectedData] = useState([]);

  const filterVideoNImages = data?.post?.media?.filter((item) => {
    return item?.type !== "link" && item?.type !== "mp3";
  });
  const filterAudio = data?.post?.media?.filter((item) => {
    return item?.type === "mp3";
  });
  const filterLink = data?.post?.media?.filter((item) => {
    return item?.type === "link";
  });
  const filterVideoNImagesFromMedia = data?.media?.filter((item) => {
    const fileName =
      item instanceof File ? utility.getFileType(item.name) : item.type;

    return fileName !== "audio" && fileName !== "mp3" && fileName !== "link";
  });

  const filterAudioFromMedia = data?.media?.filter((item) => {
    const fileName =
      item instanceof File ? utility.getFileType(item.name) : item.type;

    return fileName === "audio" || fileName === "mp3";
  });

  const filterLinkFromMedia = data?.media?.filter((item) => {
    return !(item instanceof File) && item.type === "link";
  });

  const audioFiles = [...(filterAudioFromMedia || []), ...(filterAudio || [])];

  const linkFiles = [...(filterLinkFromMedia || []), ...(filterLink || [])];
  const media = [
    ...(filterVideoNImages || []),
    ...(filterVideoNImagesFromMedia || []),
  ];

  const allMedia = [...(data?.post?.media || []), ...(data?.media || [])];

  const mediaType = data?.post?.options?.type || data?.options?.type;

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = Date.now();
      const time = data?.info?.activePeriod?.endDate || surveyTime;
      const timeDifference = time - currentTime;
      const remainingMilliseconds = Math.max(0, timeDifference);

      const remainingDays = Math.floor(
        remainingMilliseconds / (1000 * 60 * 60 * 24)
      );
      const remainingHours = Math.floor(
        (remainingMilliseconds / (1000 * 60 * 60)) % 24
      );
      const remainingMinutes = Math.floor(
        (remainingMilliseconds / (1000 * 60)) % 60
      );

      setTimeLeft({
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
      });
    };

    calculateTimeLeft();

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 60000);

    return () => clearInterval(timer);
  }, [data, surveyTime]);

  const toggleCheckBox = (filter) => {
    setSelectedData((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters;
      } else {
        return [filter];
      }
    });
  };

  const toggleCheckBoxMultiple = (filter) => {
    setSelectedData((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  function getClassName(media, type) {
    if (media?.length > 0 && type === "SURVEY" && mediaType !== "IMAGE") {
      return "h-65per";
    } else if (type === "POLL") {
      return "h-auto";
    } else {
      return "h-90per";
    }
  }

  function getImageData(images, data) {
    if (images?.length > 0) {
      return images;
    } else if (data?.images?.length > 0) {
      return data.images;
    } else if (data?.post?.options?.answers?.length > 0) {
      return data.post.options.answers;
    } else if (data?.options?.answers?.length > 0) {
      return data.options.answers;
    } else {
      return data.post.options.answers;
    }
  }
  const date = `${timeLeft.days ? timeLeft.days : ""}${
    timeLeft.days ? "d" : ""
  } ${timeLeft.hours}hr ${timeLeft?.days ? "" : timeLeft.minutes}${
    timeLeft?.days ? "" : "m"
  }`;

  return (
    <div className="bg-white  relative flex flex-col h-full w-106.25 z-50 p-5 ">
      {!isLearnMore ? (
        <div className="bg-black-100 overflow-y-auto overflow-x-hidden scrollbar-hide w-full h-full">
          {media?.length > 0 && mediaType !== "IMAGE" ? (
            <div className="flex justify-center items-center h-35per">
              <CoverMedia
                data={media}
                name={data?.creator?.fullName || account?.fullName}
                profilePhoto={
                  data?.creator?.profilePhoto || account?.profilePhoto
                }
                endDate={`${date} left`}
              />
            </div>
          ) : (
            <div className="px-5 pt-5 w-full h-10per">
              {" "}
              <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-2.5">
                  <img
                    src={
                      data?.creator?.profilePhoto ||
                      account?.profilePhoto ||
                      defaultImage
                    }
                    alt="user"
                    className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
                  />
                  <p className="font-ManropeBold text-ft2 text-center text-white">
                    {data?.creator?.fullName || account?.fullName || "Pollpapa"}
                  </p>
                </div>
                <div className="flex items-center justify-center w-auto p-2 rounded-20  bg-white backdrop-blur-xl bg-opacity-10">
                  <p className="font-ManropeBold text-ft2 w-full text-white">
                    {`${date}
                    left `}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div
            className={`p-5 flex flex-col overflow-y-auto justify-between ${getClassName(
              media,
              type
            )} `}
          >
            <div className="flex flex-col  gap-5 justify-center items-center">
              <div className="flex flex-col gap-3.75 w-full">
                {type === "POLL" ? (
                  <div className="flex gap-1">
                    {data?.channels?.map((item, index) => (
                      <p
                        key={index}
                        className="flex items-center justify-center  rounded-20 text-black-100 font-ManropeMedium text-ft0 px-1.5"
                        style={{
                          backgroundColor: utility.getRandomColors(index),
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                ) : (
                  <div className="px-2 py-1 bg-black-50 rounded-full mt-3 w-max text-ft0 font-ManropeMedium text-white">
                    <span>
                      {" "}
                      Question {startPollIndex + 1} of {polls?.length}
                    </span>
                  </div>
                )}
                <div className="pt-1 gap-2 inline-block relative">
                  <span className=" text-ft42 font-ManropeBold text-white ">
                    {" "}
                    {data?.post?.question || data?.question}
                  </span>{" "}
                  {type === "POLL" && (
                    <span className="inline-flex gap-2 ml-2 mt-1 items-center px-2 py-1 border-1 rounded-20 w-max border-white border-opacity-10 text-ft0 font-ManropeMedium text-white">
                      <img src={PollType} alt="Icon" className="w-4 h-4" />{" "}
                      {utility.capitalizeFirstLetter(
                        data?.post?.pollType || data?.pollType
                      )}{" "}
                      {utility.capitalizeFirstLetter(
                        data?.post?.questionType || data?.questionType
                      )}
                    </span>
                  )}
                </div>
                {(data?.post?.description || data?.description) && (
                  <div onClick={() => setIsLearnMore(!isLearnMore)}>
                    <span className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer">
                      Learn more
                    </span>
                  </div>
                )}

                <div>
                  {(audioFiles?.length > 0 || linkFiles?.length > 0) && (
                    <div className="flex gap-3 items-center">
                      <span
                        onClick={() => setViewDetails(!viewDetails)}
                        className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer"
                      >
                        {!viewDetails ? "View details" : "Hide details"}
                      </span>
                      {audioFiles?.length > 0 && (
                        <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-black-50 items-center">
                          <img src={AudioWhite} alt="" className="h-4 w-4" />
                          <span className="text-white font-ManropeBold text-ft0">
                            {audioFiles?.length || 0}
                          </span>
                        </div>
                      )}
                      {linkFiles?.length > 0 && (
                        <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-black-50 items-center">
                          <img src={OtherLink} alt="" className="h-4 w-4" />
                          <span className="text-white font-ManropeBold text-ft0">
                            {linkFiles?.length || 0}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {viewDetails && (
                    <div>
                      {allMedia?.map((item, idx) => {
                        let fileType = "";
                        if (item instanceof File) {
                          fileType = utility.getFileType(item.name);
                        } else {
                          fileType = item?.type;
                        }

                        if (fileType === "audio" || fileType === "mp3") {
                          return (
                            <div className="mt-2" key={idx}>
                              {" "}
                              <AudioPreview
                                fileName={
                                  item?.fileName ||
                                  data?.audioName[idx]?.fileName
                                }
                                item={item}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}

                      {linkFiles?.map((item, idx) => (
                        <div key={idx} className="mt-2">
                          <LinkPreview
                            link={item?.url}
                            bgColor={"bg-black-50"}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {data.post?.multipleOptions || data?.multipleOptions ? (
                  <>
                    {data?.post?.options?.type === "IMAGE" ||
                    data?.options?.type === "IMAGE" ? (
                      <ImageCheckboxWithMultipleChoice
                        data={getImageData(images, data)}
                        toggleCheckBox={toggleCheckBoxMultiple}
                        selectedData={selectedData}
                      />
                    ) : (
                      <TextCheckboxWithMultipleChoice
                        openAnser={data.post?.openAnswer || data?.openAnswer}
                        data={
                          data?.post?.options?.answers ||
                          data?.options?.answers ||
                          data?.options?.answers
                        }
                        toggleCheckBox={toggleCheckBoxMultiple}
                        selectedData={selectedData}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {data?.post?.options?.type === "IMAGE" ||
                    data?.options?.type === "IMAGE" ? (
                      <ImageCheckboxWithSingleChoice
                        data={getImageData(images, data)}
                        toggleCheckBox={toggleCheckBox}
                        selectedData={selectedData}
                      />
                    ) : (
                      <TextCheckboxWithSingleChoice
                        data={
                          data?.post?.options?.answers ||
                          data?.options?.answers ||
                          data?.options?.answers
                        }
                        openAnser={data?.post?.openAnswer || data?.openAnswer}
                        toggleCheckBox={toggleCheckBox}
                        selectedData={selectedData}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            {type !== "POLL" && (
              <div className="w-full h-16.25 mt-5 rounded-full bg-orange-50">
                <button
                  onClick={() => {
                    if (polls?.length === startPollIndex + 1) {
                      setPreview(false);
                    } else if (polls?.length > startPollIndex) {
                      setStartPollIndex(startPollIndex + 1);
                    }
                    setViewDetails(false);
                  }}
                  className="text-ft2 w-full h-16.25 font-ManropeBold text-black-50 flex justify-center items-center"
                >
                  {polls?.length === startPollIndex + 1 ? "Done" : "Continue"}
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <LearnMore
          description={data?.post?.description || data?.description}
          setIsLearnMore={setIsLearnMore}
        />
      )}
    </div>
  );
};

export default PollPreviewText;

const TextCheckboxWithMultipleChoice = ({
  data,
  openAnser,
  toggleCheckBox,
  selectedData,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {data?.map((item, idx) => (
        <div key={idx} className="flex gap-5">
          <div className="flex h-7.5 items-center gap-3.75 self-stretch">
            <input
              type="checkbox"
              className={`relative appearance-none w-4 h-4 rounded-md cursor-pointer ${
                selectedData.includes(idx)
                  ? "bg-orange-50 border-none"
                  : "bg-transparent border-1 border-white border-opacity-20"
              }`}
              onChange={() => toggleCheckBox(idx)}
              checked={selectedData.includes(idx)}
            />
            {selectedData.includes(idx) ? (
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCheckBox(idx);
                }}
                className="absolute cursor-pointer w-4 h-4"
                src={checkMark}
                alt="checkMark"
              ></img>
            ) : (
              ""
            )}
            <label className="font-ManropeLight text-ft2 break-words text-white font-normal">
              {item.value}
            </label>
          </div>
        </div>
      ))}
      {openAnser && (
        <div className="flex gap-3">
          <div className="flex h-7.5 items-center gap-3.75 self-stretch">
            <input
              type="checkbox"
              className={`relative appearance-none w-4 h-4 rounded-md cursor-pointer ${
                selectedData.includes("option")
                  ? "bg-orange-50 border-none"
                  : "bg-transparent border-1 border-white border-opacity-20"
              }`}
              onChange={() => toggleCheckBox("option")}
              checked={selectedData.includes("option")}
            />
            {selectedData.includes("option") ? (
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCheckBox("option");
                }}
                className="absolute cursor-pointer w-4 h-4"
                src={checkMark}
                alt="checkMark"
              ></img>
            ) : (
              ""
            )}
          </div>
          <input
            type="text"
            placeholder="Your option"
            className="bg-black-50 text-white rounded-20 placeholder-white placeholder-opacity-60  w-full  font-ManropeRegular text-ft1 px-4 h-10 outline-none "
          />
        </div>
      )}
    </div>
  );
};

const ImageCheckboxWithMultipleChoice = ({
  data,
  toggleCheckBox,
  selectedData,
}) => {
  return (
    <div className="flex h-full w-full flex-wrap gap-4">
      {data?.map((item, idx) => {
        return (
          <div
            key={idx}
            className="flex w-40 h-40 rounded-20 items-center gap-3.75 self-stretch"
          >
            <div className="relative">
              <img
                src={
                  item instanceof File ? URL.createObjectURL(item) : item.value
                }
                alt=""
                className="w-40 h-40 rounded-20"
              />
              <div className="absolute flex justify-start top-2 left-2 p-2 rounded-full bg-black-100 bg-opacity-30">
                <input
                  type="checkbox"
                  className={`relative appearance-none w-4 h-4 rounded-md cursor-pointer ${
                    selectedData.includes(idx)
                      ? "bg-orange-50 border-none"
                      : "bg-transparent border-1 border-white border-opacity-60"
                  }`}
                  onChange={() => toggleCheckBox(idx)}
                  checked={selectedData.includes(idx)}
                />
                {selectedData.includes(idx) ? (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleCheckBox(idx);
                    }}
                    className="absolute cursor-pointer w-4 h-4"
                    src={checkMark}
                    alt="checkMark"
                  ></img>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TextCheckboxWithSingleChoice = ({
  data,
  openAnser,
  toggleCheckBox,
  selectedData,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {data?.map((item, idx) => (
        <div key={idx} className="flex gap-5">
          <div className="flex h-auto items-center gap-3.75 self-stretch">
            {" "}
            <div
              className={`flex justify-start p-1.5 rounded-full bg-black-100 bg-opacity-30`}
            >
              <div
                onClick={() => toggleCheckBox(idx)}
                className={`relative w-4 h-4 ${
                  selectedData.includes(idx)
                    ? "bg-orange-50"
                    : "bg-transparent border-1 border-white border-opacity-60 "
                } rounded-full `}
              >
                {selectedData.includes(idx) && (
                  <div
                    style={{ transform: "translate(-50%, -50%)" }}
                    className="absolute w-65per h-65per bg-black-100 rounded-full top-50per left-50per "
                  ></div>
                )}
              </div>
            </div>
            <label className="font-ManropeLight text-ft2 break-words text-white font-normal">
              {item?.value}
            </label>
          </div>
        </div>
      ))}
      {openAnser && (
        <div className="flex gap-3">
          <div className="flex h-auto items-center gap-3.75 self-stretch">
            <div
              className={`flex justify-start p-1.5 rounded-full bg-black-100 bg-opacity-30`}
            >
              <div
                onClick={() => toggleCheckBox("option")}
                className={`relative w-4 h-4 ${
                  selectedData.includes("option")
                    ? "bg-orange-50"
                    : "bg-transparent border-1 border-white border-opacity-60 "
                } rounded-full `}
              >
                {selectedData.includes("option") && (
                  <div
                    style={{ transform: "translate(-50%, -50%)" }}
                    className="absolute w-65per h-65per bg-black-100 rounded-full top-50per left-50per "
                  ></div>
                )}
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="Your option"
            className="bg-black-50 text-white rounded-20 placeholder-white placeholder-opacity-60  w-full  font-ManropeRegular text-ft1 px-4 h-10 outline-none "
          />
        </div>
      )}
    </div>
  );
};

const ImageCheckboxWithSingleChoice = ({
  data,
  toggleCheckBox,
  selectedData,
}) => {
  return (
    <div className="flex h-full w-full flex-wrap gap-4">
      {data?.map((item, idx) => (
        <div
          key={idx}
          className="flex w-40 h-40 rounded-20 items-center gap-3.75 self-stretch"
        >
          <div className="relative">
            <img
              src={
                item instanceof File ? URL.createObjectURL(item) : item.value
              }
              alt=""
              className="w-40 h-40 rounded-20"
            />
            <div className="absolute flex justify-start top-2 left-2 p-2 rounded-full bg-black-100 bg-opacity-30">
              <div
                onClick={() => toggleCheckBox(idx)}
                className={`relative w-4 h-4 ${
                  selectedData.includes(idx)
                    ? "bg-orange-50"
                    : "bg-transparent border-1 border-white border-opacity-60 "
                } rounded-full `}
              >
                {selectedData.includes(idx) && (
                  <div
                    style={{ transform: "translate(-50%, -50%)" }}
                    className="absolute w-65per h-65per bg-black-100 rounded-full top-50per left-50per "
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
