import React, { useRef, useState } from "react";
import Table from "../../common/table";
import moment from "moment";
import more from "../../assets/Icons/More.svg";
import placeholder from "../../assets/Icons/Placeholder.svg";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import Error from "../../assets/Icons/Error.svg";
import utility from "../../utility";
import OutsideClickRef from "../../common/outsideClickRef";
import checkMark from "../../assets/Icons/checkMark.svg";
import { UserService } from "../../services";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import WarningPopup from "../../common/warningPopup";
import PlaceholderWnm from "../../assets/Icons/PlaceholderWnm.png";
import defaultImage from "../../assets/Icons/signupProfileSmall.png";
import { useSelector } from "react-redux";

/**
 * Component for rendering the active staff list.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setPageSize - Function to set the page size
 * @param {number} props.pageSize - Current page size
 * @param {Function} props.setSort - Function to set the sorting criteria
 * @param {boolean} props.loading - Loading state of the component
 * @param {Object} props.sort - Current sorting criteria
 * @param {number} props.currentPage - Current page number
 * @param {Function} props.handlePageChange - Function to handle page change
 * @param {number} props.totalPages - Total number of pages
 * @param {number} props.totalDocs - Total number of documents
 * @param {Array} props.data - Data of the active staff
 * @param {Array} props.selectedFilters - Selected filters for staff roles
 * @param {Function} props.setSelectedFilters - Function to set selected filters
 * @param {Function} props.getStaffList - Function to fetch the staff list
 * @returns {JSX.Element} - Active component
 */

const Active = ({
  setPageSize,
  searchQuery,
  pageSize,
  setSort,
  loading,
  sort,
  currentPage,
  handlePageChange,
  totalPages,
  totalDocs,
  setCurrentPage,
  data,
  selectedFilters,
  setSelectedFilters,
  getStaffList,
  roleList,
}) => {
  const headers = [
    { name: " Name" },
    { name: "Role", filter: true },
    { name: "Last Login", sort: true },
    // { name: "Phone" },
    // { name: "Email" },
  ];
  const history = useHistory();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [clickFilter, setClickFilter] = useState(false);
  const optionRef = useRef();
  const [updating, setUpdating] = useState(false);
  const user = useSelector((state) => state.user);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const userPermissions = user?.roleDetails?.access;
  const [blockStaff, setBlockStaff] = useState({
    popup: false,
    name: "",
    id: "",
  });

  const handleMoreIconClick = (id) => {
    if (selectedUserId === id) {
      setSelectedUserId(null);
    } else {
      setSelectedUserId(id);
    }
  };

  /**
   * Handles blocking a staff member.
   *
   * @async
   * @function
   * @param {string} id - Staff member ID
   * @param {string} name - Staff member name
   * @returns {void}
   */

  const handleBlockStaff = async (id, name) => {
    setUpdating(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().blockStaff(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: name + " blocked",
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      getStaffList();
      setSelectedUserId(null);
      setBlockStaff((prev) => ({ ...prev, id: "", name: "", popup: false }));
      setUpdating(false);
    } catch (e) {
      console.error(e);
      setUpdating(false);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Toggles the selected filter for staff roles.
   *
   * @function
   * @param {string} filter - Filter value
   * @returns {void}
   */
  const toggleSelectedFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  // const getCountryCode = (item) => {
  //   if (
  //     item &&
  //     typeof item.countryCode === "string" &&
  //     item.countryCode.includes("+")
  //   ) {
  //     return item.countryCode;
  //   } else if (item) {
  //     return `+${item.countryCode}`;
  //   }

  //   return "";
  // };

  /**
   * Renders the filter options for staff roles.
   *
   * @function
   * @returns {JSX.Element} - Filter options
   */
  const renderFilter = () => {
    return (
      clickFilter && (
        <OutsideClickRef
          state={optionRef}
          setState={() => setClickFilter(false)}
        >
          <div
            className={`flex w-45 max-h-57.5 overflow-y-auto p-5 flex-col items-start gap-5 absolute top-5 right-5 z-10 rounded-20 bg-white shadow-inner`}
          >
            {roleList.map((item) => (
              <div
                key={item?._id}
                className="flex h-7.5 items-center gap-3.75 self-stretch"
              >
                <input
                  type="checkbox"
                  className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                    selectedFilters.includes(item.role)
                      ? "bg-orange-50 border-none"
                      : "bg-white border-2 border-grey-100"
                  }`}
                  onChange={() => toggleSelectedFilter(item.role)}
                  checked={selectedFilters.includes(item.role)}
                />
                {selectedFilters.includes(item.role) ? (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectedFilter(item.role);
                    }}
                    className="absolute cursor-pointer"
                    src={checkMark}
                    alt="checkMark"
                  ></img>
                ) : (
                  ""
                )}
                <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                  {item.role}
                </label>
              </div>
            ))}
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Renders the table body for the active staff list.
   *
   * @function
   * @returns {JSX.Element} - Table body
   */
  const handleRedirect = (item) => {
    userPermissions?.indexOf("staff:read:details") > -1 &&
      history.push(
        `/staff/activity/${item?._id}${haveAudit ? `?from=${haveAudit}` : ""}`
      );
  };

  const renderTableBody = () => {
    if (data?.length > 0 && !loading) {
      return data?.map((item, index) => (
        <tr key={index} className="hover:bg-grey-350 cursor-pointer relative">
          <td onClick={() => handleRedirect(item)} className=" py-2.5 px-5">
            <div className="flex gap-2.5 items-center w-50">
              <img
                src={item.profilePhoto || defaultImage}
                alt="User"
                className="w-10 h-10 rounded-full"
              />
              <span className="font-ManropeBold text-black-100  text-ft1 w-35 truncate">
                {item.name}
              </span>
            </div>
          </td>
          <td
            onClick={() => handleRedirect(item)}
            className="font-ManropeMedium text-ft1 text-black-50"
          >
            <div className="w-35 truncate">
              {utility.capitalizeFirstLetter(item.role)}
            </div>
          </td>
          <td
            onClick={() => handleRedirect(item)}
            className="font-ManropeMedium text-ft1 text-black-50"
          >
            <div className="w-35 truncate">
              {item?.lastLogin
                ? moment(item.lastLogin).format("DD MMM[,] YYYY")
                : "-"}
            </div>
          </td>
          {/* <td onClick={() => handleRedirect(item)}>
            <div className="w-35 truncate">
              {" "}
              {item?.phone ? (
                <div className="flex gap-1 text-black-50 font-ManropeMedium text-ft2 w-35 truncate">
                  <span>{getCountryCode(item)}</span>
                  <span>{item?.phone || "-"}</span>
                </div>
              ) : (
                <div className=" w-35 ">-</div>
              )}
            </div>
          </td> */}
          {/* <td
            onClick={() => handleRedirect(item)}
            className="font-ManropeMedium text-ft1 text-black-50"
          >
            <div className="w-35 xl:w-auto truncate">{item.email}</div>
          </td> */}
          <td>
            <div
              onClick={() => handleMoreIconClick(item?._id)}
              className="w-6.25 relative"
            >
              {userPermissions?.indexOf("staff:write:action") > -1 && (
                <img src={more} alt="More" className="cursor-pointer" />
              )}
              {selectedUserId === item?._id && (
                <OutsideClickRef
                  state={optionRef}
                  setState={() => setSelectedUserId(null)}
                >
                  <div
                    onClick={() =>
                      setBlockStaff((prev) => ({
                        ...prev,
                        popup: !blockStaff?.popup,
                        id: item?._id,
                        name: item?.name,
                      }))
                    }
                    className="flex absolute top-10 right-5 rounded-20 bg-white shadow-inner p-5 z-20 w-75 gap-3.75"
                  >
                    <img src={Error} alt="Error" />
                    <p className="text-black-100 font-ManropeRegular text-ft3">
                      Block
                    </p>
                  </div>
                </OutsideClickRef>
              )}
            </div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={7}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery || selectedFilters?.length > 0 ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setSort={setSort}
        loading={loading}
        sort={sort}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalPages={totalPages - 1}
        totalDocs={totalDocs}
        setCurrentPage={setCurrentPage}
        renderFilter={renderFilter()}
        clickFilter={clickFilter}
        setClickFilter={setClickFilter}
      />
      {blockStaff?.popup && (
        <WarningPopup
          title={`Block ${blockStaff?.name}?`}
          description={`Are you sure? You want to block this staff.`}
          setToggle={() =>
            setBlockStaff((prev) => ({
              ...prev,
              popup: false,
              id: "",
              name: "",
            }))
          }
          handleActivateUser={() =>
            handleBlockStaff(blockStaff?.id, blockStaff?.name)
          }
          loading={updating}
        />
      )}
    </>
  );
};

export default Active;
