/**
 * @module CreateEntity
 * @desc Component for creating a new entity account.
 */

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import left from "../../../assets/Icons/Left.svg";
import more from "../../../assets/Icons/More.svg";
import ShowToast from "../../../common/showToast";
import WarningPopup from "../../../common/warningPopup";
import { toastType } from "../../../constant/commonArray";
import { FileUploadService, UserService } from "../../../services";
import UserEntityService from "../../../services/userEntityService";
import utility from "../../../utility";
import ContactInfo from "./contactInfo";
import EntityInfo from "./entityInfo";
import GeneralInfo from "./generalInfo";

/**
 * CreateEntity Component
 * @returns {JSX.Element} JSX Element representing the CreateEntity component.
 */

const CreateEntity = () => {
  const [steps, setSteps] = useState(0);
  const [discard, setDiscard] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadProfile, setUploadProfile] = useState(null);
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);
  const [valueEntity, setValueEntity] = useState(null);
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const history = useHistory();
  const [state, setState] = useState({
    byRequest: false,
    type: "",
    profilePhoto: "",
    fullName: "",
    userName: "",
    bio: "",
    cityName: "",
    countryName: "",
    location: {
      name: "",
      latitude: 0,
      longitude: 0,
    },
    delegatedContactPerson: {
      name: "",
      email: "",
      countryCode: "+49",
      phone: "",
    },
    registrationAddress: {
      location: {
        name: "",
        latitude: 0,
        longitude: 0,
      },
    },
    organisationDocuments: {
      licence: "",
      companyWebsite: "",
      taxIdName: "",
      taxId: "",
      isValidWebsite: true,
    },
    otherDocuments: [],
  });

  /**
   * Handles the creation of the entity account.
   * @async
   * @name handleCreateEntity
   */
  const handleCreateEntity = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("files", uploadProfile);

      const [err, res] = await utility.parseResponse(
        new FileUploadService().fileUpload(formData)
      );

      if (err || !res) return;
      const formDataDocument = new FormData();
      files.forEach((file) => {
        formDataDocument.append(`files`, file);
      });
      const [errDocument, responseDocument] = await utility.parseResponse(
        new FileUploadService().fileUpload(formDataDocument)
      );

      if (errDocument || !responseDocument) return;
      if (responseDocument?.responseCode !== 200) {
        ShowToast({
          message: responseDocument?.message,
          type: toastType.ERROR,
        });
        return;
      }
      const newDocuments = responseDocument?.responseData.map((fileInfo) => ({
        type: extractFileExtension(fileInfo.fileName),
        url: fileInfo.cdnUrl,
      }));
      const updatedState = {
        ...state,
        userName: `@${state.userName}`,
        profilePhoto: res?.responseData[0]?.cdnUrl,
        otherDocuments: [...newDocuments],
      };

      const [error, response] = await utility.parseResponse(
        new UserEntityService().createEntity(updatedState)
      );

      const employeeInfoData = employeeInfo.map((employee) => {
        const { department, entityId, ...rest } = employee;
        const filteredEmployee = {
          ...rest,
          entityId: response?.responseData?.data?._id,
        };
        return filteredEmployee;
      });
      if (employeeInfo.length > 0) {
        await new UserService().addEmployee(employeeInfoData);
      }
      if (error || !response.responseData) return;
      if (res?.failedEmployees?.length > 0) {
        ShowToast({
          message: res?.failureMessage,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: res?.successMessage,
          type: toastType.SUCCESS,
        });
        history.push("/entity-account");
      }
    } catch (e) {
      console.error(e);
      ShowToast({
        message: "Something went wrong.",
        type: toastType.SUCCESS,
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Extracts the file extension from the given file name.
   * @param {string} fileName - The name of the file.
   * @name extractFileExtension
   * @returns {string} The file extension.
   */

  const extractFileExtension = (fileName) => {
    const parts = fileName.split(".");
    return parts.length > 1 ? parts[parts.length - 1] : "";
  };

  const handleDiscard = () => {
    setState({
      byRequest: false,
      type: "",
      profilePhoto: "",
      fullName: "",
      userName: "",
      bio: "",
      location: {
        name: "",
        latitude: 0,
        longitude: 0,
      },
      delegatedContactPerson: {
        name: "",
        email: "",
        countryCode: "+49",
        phone: "",
      },
      registrationAddress: {
        location: {
          name: "",
          latitude: 0,
          longitude: 0,
        },
      },
      organisationDocuments: {
        licence: "",
        companyWebsite: "",
        taxIdName: "",
        taxId: "",
        isValidWebsite: true,
      },
      otherDocuments: [],
    });
    setDiscard(false);
    setValueEntity("");
    setFiles([]);
    setCroppedImage(null);
    setFile({});
    setEmployeeInfo([]);
    setSteps(0);
  };

  const setToggle = () => {
    setDiscard(false);
  };

  const renderStepComponent = () => {
    switch (steps) {
      case 0:
        return (
          <GeneralInfo
            setSteps={setSteps}
            setState={setState}
            state={state}
            setUploadProfile={setUploadProfile}
            file={file}
            setFile={setFile}
            croppedImage={croppedImage}
            setCroppedImage={setCroppedImage}
            setError={setError}
            error={error}
          />
        );
      case 1:
        return (
          <EntityInfo
            setSteps={setSteps}
            setState={setState}
            state={state}
            setFiles={setFiles}
            valueEntity={valueEntity}
            setValueEntity={setValueEntity}
            files={files}
            setError={setError}
            error={error}
          />
        );
      default:
        return (
          <ContactInfo
            setSteps={setSteps}
            handleCreateEntity={handleCreateEntity}
            setState={setState}
            state={state}
            setEmployeeInfo={setEmployeeInfo}
            employeeInfo={employeeInfo}
            loading={loading}
            setError={setError}
            error={error}
          />
        );
    }
  };

  return (
    <div className="py-5 w-full h-full  overflow-y-auto pr-12.5">
      <div className="flex justify-between bg-white p-5 items-center w-full rounded-20  h-21.25">
        <div className="flex items-center gap-7.5">
          <Link to="/entity-account">
            <img src={left} alt="left" />
          </Link>
          <p className="text-ft5 text-black-100 font-ManropeBold ">
            Creating entity account
          </p>
        </div>
        <div onClick={() => setDiscard(!discard)}>
          <img src={more} alt="More" className="cursor-pointer" />
        </div>
      </div>
      <div className="w-full  bg-white mt-5 rounded-t-20 px-10 py-5">
        <div className="flex gap-10">
          <span
            className={`${
              steps === 0
                ? "text-ft5 font-ManropeBold text-black-100"
                : "text-ft3 font-ManropeMedium text-black-75"
            }`}
          >
            General
          </span>
          <span
            className={`${
              steps === 1
                ? "text-ft5 font-ManropeBold text-black-100"
                : "text-ft3 font-ManropeMedium text-black-75"
            }`}
          >
            Entity info
          </span>
          <span
            className={`${
              steps === 2
                ? "text-ft5 font-ManropeBold text-black-100"
                : "text-ft3 font-ManropeMedium text-black-75"
            }`}
          >
            {" "}
            Contact person
          </span>
        </div>
        {renderStepComponent()}
      </div>
      {discard && (
        <WarningPopup
          title={"Discard changes ?"}
          description={"All entered data will be deleted"}
          handleActivateUser={handleDiscard}
          setToggle={setToggle}
        />
      )}
    </div>
  );
};

export default CreateEntity;
