/**
 * @module EmployeesComponent
 * @example
 * // Usage in a React functional component
 * import EmployeesComponent from "./EmployeesComponent";
 *
 * const MyComponent = () => {
 *   return <EmployeesComponent />;
 * };
 * @returns {React.Component} The EmployeesComponent
 */

import React, { useState, useRef, useEffect } from "react";
import searchIcon from "../../assets/Icons/Search.svg";
import { ReactComponent as AddIcon } from "../../assets/Icons/plus.svg";
import Close from "../../assets/Icons/close.svg";
import add from "../../assets/Icons/Add.svg";
import OutsideClickRef from "../../common/outsideClickRef";
import EmployeeList from "./employeeList";
import utility from "../../utility";
import { UserService } from "../../services";
import { useDebouncedCallback } from "use-debounce";
import CreateDeptPopup from "./createDeptPopup";
import CreatePositionPopUp from "./createPositionPopUp";
import { useSelector } from "react-redux";
import left from "../../assets/Icons/Left.svg";
import { useHistory, useLocation } from "react-router-dom";

/**
 * EmployeesComponent functional component to manage employee data and display employee list.
 *
 * @return {JSX.Element} The JSX element containing the employee list and search functionality.
 */
const EmployeesComponent = () => {
  const [newJob, setNewjob] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState("");
  const createRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");

  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    setDebounceValue(value);
  }, 500);

  /**
   * A function that handles the search input change event.
   *
   * @param {Event} e - the event object
   * @return {void}
   */
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
  };

  /**
   * A function to fetch job lists asynchronously.
   *
   * @param {void} - No parameters
   * @return {void} - No return value
   */
  const getJobLists = async () => {
    setLoading(true);

    try {
      let requestParams = {};
      if (debounceValue) {
        requestParams.searchKey = debounceValue;
      }
      const [error, response] = await utility.parseResponse(
        new UserService().getJobList(requestParams)
      );

      if (error || !response) return;
      setJobList(response);

      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJobLists();
    // eslint-disable-next-line
  }, [debounceValue]);

  return (
    <div className="flex flex-col py-5 w-full h-full overflow-y-auto pr-12.5 gap-5">
      <div className="flex justify-between items-center bg-white p-5 rounded-20 gap-5">
        {haveAudit && (
          <div
            onClick={() => {
              if (haveAudit) {
                history.push("/audit-log");
              }
            }}
          >
            <img src={left} alt="left" className="cursor-pointer" />
          </div>
        )}
        <div className="flex p-5 items-center gap-5 rounded-full bg-grey-50 w-full h-11.25">
          <img
            src={searchIcon}
            alt="searchIcon"
            className="opacity-60 w-5 h-5"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            className="font-ManropeLight text-ft3 w-full bg-grey-50 text-black-300 focus:outline-none"
          />
          {!!searchQuery && (
            <img
              onClick={() => {
                setSearchQuery("");
                debouncedSearch("");
              }}
              src={Close}
              alt=""
              className="flex justify-end cursor-pointer w-5 h-5"
            />
          )}
        </div>
        {userPermissions?.indexOf("position:write:details") > -1 && (
          <div
            className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center relative cursor-pointer"
            onClick={() => {
              setNewjob(!newJob);
            }}
          >
            <AddIcon />
          </div>
        )}
        {newJob && (
          <OutsideClickRef
            state={createRef}
            setState={setNewjob}
            className={"absolute top-0 right-0 "}
          >
            <div className="flex flex-col absolute w-75 top-29 right-15 rounded-20 shadow-inner z-10 bg-white">
              <button
                className="flex p-5 items-center gap-3.75  w-75 rounded-t-20 hover:bg-grey-50"
                onClick={() => setPopup("NEW_POSITION")}
              >
                <img src={add} alt="stop" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  New position
                </p>
              </button>
              <hr className="text-grey-50" />
              <button
                className="flex p-5 items-center gap-3.75  w-75 rounded-b-20 hover:bg-grey-50"
                onClick={() => setPopup("NEW_DEPARTMENT")}
              >
                <img src={add} alt="stop" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  New department
                </p>
              </button>
            </div>
          </OutsideClickRef>
        )}
      </div>
      <EmployeeList
        jobList={jobList}
        setJobList={setJobList}
        loading={loading}
        getJobLists={getJobLists}
        searchQuery={searchQuery}
      />
      {popup === "NEW_POSITION" && (
        <CreatePositionPopUp setPopup={setPopup} getJobLists={getJobLists} />
      )}
      {popup === "NEW_DEPARTMENT" && (
        <CreateDeptPopup setPopup={setPopup} getJobLists={getJobLists} />
      )}
    </div>
  );
};

export default EmployeesComponent;
