import React, { useEffect, useRef, useState } from "react";
import Table from "../../../common/table";
import { useParams } from "react-router-dom";
import placeholder from "../../../assets/Icons/Placeholder.svg";
import more from "../../../assets/Icons/More.svg";
import moment from "moment";
import { UserService } from "../../../services";
import utility from "../../../utility";
import OutsideClickRef from "../../../common/outsideClickRef";
import DisablePopUp from "../../../common/disablePopUp";
import Error from "../../../assets/Icons/Error.svg";
import { useSelector } from "react-redux";
import PlaceholderWnm from "../../../assets/Icons/PlaceholderWnm.png";

/**
 * Component representing a list of comments for a user account.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.debounceValueComment - Debounced search value for comments.
 * @param {string} props.selectedUser - ID of the selected user.
 * @param {Function} props.setDisablePopUp - Function to set disable popup state.
 * @param {Function} props.setSelectedUser - Function to set selected user state.
 * @param {Function} props.setDisableType - Function to set disable type state.
 * @param {boolean} props.isDisablePopUp - Boolean indicating if disable popup is active.
 * @param {string} props.isDisableType - Type of disable action.
 * @param {number} props.currentPageComment - Current page number for comments.
 * @param {Function} props.setCurrentPageComment - Function to set current page for comments.
 * @param {Function} props.getEntityDetailsData - Function to get entity details data.
 * @param {Object} props.entityDetails - Details of the entity related to comments.
 * @param {string} props.searchQuery - Search query for comments.
 * @returns {JSX.Element} CommentList component.
 */
const CommentList = ({
  debounceValueComment,
  selectedUser,
  setDisablePopUp,
  setSelectedUser,
  setDisableType,
  isDisablePopUp,
  isDisableType,
  currentPageComment,
  setCurrentPageComment,
  getEntityDetailsData,
  entityDetails,
  searchQuery,
}) => {
  const [commentList, setCommentList] = useState([]);
  const [pollId, setPollId] = useState("");
  const [sortComment, setSortComment] = useState(-1);
  const [totalDocsComment, setTotalDocsComment] = useState(0);
  const [limitComment, setLimitComment] = useState(25);
  const totalPagesComment = Math.ceil(totalDocsComment / limitComment);
  const [loadingComment, setLoadingComment] = useState(false);
  const { id } = useParams();
  const optionsRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  const CommentHeaders = [
    { name: "Comment" },
    { name: "Created", sort: true },
    { name: "Creater" },
  ];

  /**
   * Handles page change for comments pagination.
   * @param {number} pageNumber - Page number to navigate to.
   */
  const handlePageChangeComment = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPagesComment) {
      setCurrentPageComment(pageNumber);
    }
  };

  const getAccountCommentList = async () => {
    setLoadingComment(true);
    const requestParams = {
      searchKey: debounceValueComment,
      sort: sortComment,
      limit: limitComment,
      skip: currentPageComment,
    };
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().userAccountComment(id, requestParams)
      );

      if (error || !response) return;
      setCommentList(response.comments);
      setTotalDocsComment(response?.totalCount);
      setLoadingComment(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingComment(false);
    }
  };

  const renderCommentTableBody = () => {
    if (commentList.length > 0) {
      return commentList.map((value) => (
        <tr key={value?._id}>
          <td
            className={`text-black-100 font-ManropeMedium text-ft2  py-4 w-3/6 px-5`}
          >
            {value.description || "-"}
          </td>
          <td className={`text-black-100 font-ManropeMedium text-ft2`}>
            {moment(value.addedOn).format("DD MMM[,] YYYY")}
          </td>
          <td>{value.creator}</td>

          <td>
            <div className="relative">
              {userPermissions?.indexOf("business:write:comment") > -1 && (
                <img
                  src={more}
                  alt="More"
                  className="cursor-pointer"
                  onClick={() =>
                    setPollId(pollId === value._id ? null : value._id)
                  }
                />
              )}
              {pollId === value?._id && (
                <OutsideClickRef
                  state={optionsRef}
                  setState={() => setPollId(null)}
                >
                  <div className="absolute right-0 top-7.75 flex flex-col items-start w-75 rounded-20 shadow-inner z-10 bg-white">
                    <button
                      onClick={() => {
                        setDisablePopUp(true);
                        setDisableType("comment");
                        setSelectedUser(value?._id);
                      }}
                      className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
                    >
                      <img src={Error} alt="error" />
                      <p className="text-ft3 font-ManropeLight text-black-100">
                        Disable Comments
                      </p>
                    </button>
                  </div>
                </OutsideClickRef>
              )}
            </div>
          </td>
        </tr>
      ));
    } else if (!loadingComment) {
      return (
        <tr className="h-57vh">
          <td colSpan={6}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  useEffect(() => {
    getAccountCommentList();
    // eslint-disable-next-line
  }, [id, debounceValueComment, sortComment, limitComment, currentPageComment]);

  return (
    <>
      <Table
        headers={CommentHeaders}
        tableBody={renderCommentTableBody()}
        loading={loadingComment}
        pageSize={limitComment}
        sort={sortComment}
        setPageSize={setLimitComment}
        setSort={setSortComment}
        totalPages={totalPagesComment}
        totalDocs={totalDocsComment}
        setCurrentPage={setCurrentPageComment}
        handlePageChange={handlePageChangeComment}
      />
      {isDisablePopUp && isDisableType === "comment" && (
        <DisablePopUp
          setToggle={setDisablePopUp}
          keyName={"comment"}
          selectedId={selectedUser}
          getDetails={getAccountCommentList}
          updateUser={getEntityDetailsData}
          name="Disable comment"
          suspendedState={entityDetails?.status}
          suspendedName={entityDetails?.fullName}
        />
      )}
    </>
  );
};

export default CommentList;
