import React, { useEffect, useState } from "react";
import { UserService } from "../../../services";
import utility from "../../../utility";
import DetailsEmployeeInfo from "./detailsEmployeeInfo";
import { CircularProgress } from "@mui/material";

/**
 * Component representing a list of employees.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.id - ID of the entity to fetch employee list.
 * @param {boolean} props.permissionDelete - Permission to delete employees.
 * @param {boolean} props.permissionEdit - Permission to edit employees.
 * @returns {JSX.Element} EmployeeList component.
 */
const EmployeeList = ({ id, permissionDelete, permissionEdit }) => {
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [loading, setIsLoading] = useState(false);

  /**
   * Fetches employee list for the specified entity ID.
   * @async
   * @function getEntityDetailsData
   */
  const getEntityDetailsData = async () => {
    setIsLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getEmployeeList(id)
      );
      if (error || !response) return;
      setEmployeeInfo(response);
      setIsLoading(false);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEntityDetailsData();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center  items-center w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-5">
      {employeeInfo.map((item, index) => (
        <DetailsEmployeeInfo
          key={index}
          employee={item}
          getEntityDetailsData={getEntityDetailsData}
          index={index}
          permissionDelete={permissionDelete}
          permissionEdit={permissionEdit}
        />
      ))}
    </div>
  );
};

export default EmployeeList;
