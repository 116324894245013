import React, { useState } from "react";
import ShowToast from "../../../common/showToast";
import { keyTypeConstants, toastType } from "../../../constant/commonArray";

/**
 * RejectPopup component for displaying a rejection reason input popup.
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setReject - Function to handle rejection popup visibility
 * @param {Function} props.setState - Function to update component state
 * @param {Object} props.state - Current component state
 * @param {Function} props.pollApproved - Function to handle approval polling
 * @param {boolean} props.upadting - Flag indicating if update is in progress
 * @returns {JSX.Element} - Rendered component
 */
const RejectPopup = ({
  setReject,
  setState,
  state,
  pollApproved,
  upadting,
}) => {
  const [error, setError] = useState("");
  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 items-center fixed flex justify-center right-0 top-0 h-full w-full">
      <div className="bg-white  relative flex flex-col 3xl:h-70per 2xl:h-80per w-2/4 p-7.5 z-50 rounded-20 overflow-y-auto">
        <div className="flex flex-col h-full">
          <span className="text-ft4 font-ManropeBold text-black-100">
            Reject reason
          </span>
          <div className="mt-7">
            <span className="text-ft3 font-ManropeBold text-black-100">
              Please enter reject reason here
            </span>
          </div>
          <div className="mt-3 h-full w-full">
            <textarea
              name=""
              id=""
              cols="50"
              rows="10"
              value={state?.rejectReason}
              onChange={(e) => {
                if (e.target.value.length > 1500) {
                  setError("Reject reason should be less than 1500 characters");
                } else {
                  setError("");
                }
                setState((prev) => ({ ...prev, rejectReason: e.target.value }));
              }}
              className="w-full h-full resize-none bg-grey-50 outline-none rounded-20 text-ft3 font-ManropeMedium p-5 text-black-100"
            />
            {error && (
              <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                {error}
              </div>
            )}
          </div>
          <div className="flex gap-3 justify-end pt-5">
            <div className="w-1/2 flex gap-3">
              <button
                onClick={() => {
                  setReject(false);
                  setState({ rejectReason: "" });
                }}
                className="h-16.25 w-full rounded-full border border-black-100 border-opacity-20 font-ManropeBold text-ft2 text-black-100 bg-transparent"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (!state?.rejectReason || upadting) {
                    ShowToast({
                      type: toastType.ERROR,
                      message: "Please enter reject reason",
                    });
                  } else {
                    pollApproved(keyTypeConstants.REJECTED);
                  }
                }}
                disabled={!state?.rejectReason || upadting || !!error}
                className={`h-16.25 w-full rounded-full font-ManropeBold text-ft2  ${
                  !state?.rejectReason || upadting || !!error
                    ? "text-black-100 bg-grey-50"
                    : "text-black-100 bg-orange-50"
                }`}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectPopup;
