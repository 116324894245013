import { httpConstants } from "../constant/commonArray";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./userService";

const {
  METHOD_TYPE: { GET },
  API_END_POINT: { WALLET },
} = httpConstants;

export default class WalletService {
  baseUrl = process.env.REACT_APP_WALLET_SERVICE;
  getAddress = async (id) => {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `/${WALLET}/${id}`, {}, getHeaders())
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
}
