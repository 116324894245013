import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";

/**
 * AudioFileName component for displaying and editing audio file name and preview.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setOpenMedia - Function to control the visibility of the media popup.
 * @param {Array} props.files - Array of selected files.
 * @param {Function} props.setFiles - Function to set the selected files.
 * @param {number} props.index - Index of the audio file.
 * @param {Function} props.handleEditName - Function to handle editing the audio file name.
 * @param {string} props.audioName - Current name of the audio file.
 * @returns {JSX.Element} AudioFileName component.
 */
const AudioFileName = ({
  setOpenMedia,
  files,
  setFiles,
  index,
  handleEditName,
  audioName,
}) => {
  const [name, setName] = useState(null);

  const handleClose = () => {
    setOpenMedia(null);
    setFiles([]);
  };

  useEffect(() => {
    if (files && files.length > 0) {
      setName(audioName);
    } else {
      setName("");
    }
  }, [audioName, files]);

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center right-0 top-0 h-full w-full">
      <div className="bg-white  relative flex flex-col h-112.5 w-115 z-50  top-30 rounded-20 ">
        <div className="flex w-full justify-between mb-5 px-7.5 pt-7.5">
          <h1 className="font-ManropeBold text-black-100 text-ft4">Preview</h1>
          <Close onClick={handleClose} className="cursor-pointer" />
        </div>
        <div className="px-7.5 pt-5 flex justify-center h-70per">
          <div className="flex flex-col gap-3 w-full">
            <div className="w-full">
              <input
                type="text"
                placeholder="File name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
              />
            </div>
            <audio controls className="w-full">
              <source
                src={
                  files[0] instanceof File
                    ? URL.createObjectURL(files[0])
                    : files[0]?.url
                }
              />
            </audio>
          </div>
        </div>
        <div className="h-full flex w-full px-7.5 pb-7.5 justify-end items-end">
          <button
            onClick={() => {
              setOpenMedia(null);
              handleEditName(index, name || audioName);
              setFiles([]);
            }}
            disabled={false}
            className={`flex items-center justify-center rounded-full  w-full h-16.25  font-ManropeBold text-ft2 ${
              false
                ? "bg-grey-50 text-grey-250"
                : "cursor-pointer bg-orange-50 text-black-100"
            }`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudioFileName;
