import React, { useEffect, useRef, useState } from "react";
import ToggleSwitch from "../../../../../../common/component/toggleSwitch";
import OutsideClickRef from "../../../../../../common/outsideClickRef";
import UpArrow from "../../../../../../assets/Icons/up-arrow.svg";
import DownArrow from "../../../../../../assets/Icons/down-arrow.svg";
import {
  agreementData,
  FormalityData,
  frequencyData,
  QualityData,
  textData,
} from "../../../../../../constant/commonArray";
import TypeImage from "./typeImage";
import TypeText from "./typeText";

/**
 * Poll component for managing different types of poll options.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setState - Function to set component state.
 * @param {Object} props.state - Component state object.
 * @param {number} props.idx - Index of the poll category.
 * @param {Array<Array<string>>} props.errorsPoll - Errors associated with poll options.
 * @param {Function} props.setErrorsPoll - Function to set errors associated with poll options.
 * @returns {JSX.Element} Poll component JSX.
 */
const Poll = ({ setState, state, idx, errorsPoll, setErrorsPoll }) => {
  const optionsValue = [
    {
      name: "Text",
      data: textData,
      enum: "TEXT",
    },
    {
      name: "Images",
      data: [],
      enum: "IMAGE",
    },
    {
      name: "Frequency",
      data: frequencyData,
      enum: "TEXT",
    },
    {
      name: "Quality",
      data: QualityData,
      enum: "TEXT",
    },
    {
      name: "Agreement",
      data: agreementData,
      enum: "TEXT",
    },
    {
      name: "Familarity",
      data: FormalityData,
      enum: "TEXT",
    },
  ];

  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const answersLength = state?.polls[idx]?.options?.answers?.length;
  useEffect(() => {
    if (answersLength && answersLength !== errorsPoll.length) {
      setErrorsPoll([new Array(answersLength).fill("")]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mt-15 flex flex-col">
      <div className="flex flex-col gap-15">
        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Option Type
          </label>
          <div>
            <OutsideClickRef state={dropdownRef} setState={setIsOpen}>
              <div className="relative w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25">
                <div
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  className="w-full h-full flex justify-between items-center cursor-pointer"
                >
                  <span
                    className={`w-full h-full flex items-center font-ManropeRegular ${
                      !!state?.polls[idx]?.options?.name
                        ? "text-black-100"
                        : "text-black-75"
                    } text-ft3`}
                  >
                    {state?.polls[idx]?.options?.name || "Not choosen"}
                  </span>
                  {!isOpen ? (
                    <img src={DownArrow} alt="" className="w-6 h-6" />
                  ) : (
                    <img src={UpArrow} alt="" className="w-6 h-6" />
                  )}
                </div>

                {isOpen && (
                  <div className="absolute right-0 mt-2 w-full z-50 max-h-100 overflow-y-auto  shadow-dropdownShadow bg-white  rounded-20">
                    <div className="py-1 flex flex-col">
                      {optionsValue.map((option, index) => (
                        <span
                          key={index}
                          className={`w-full p-5 text-ft3 font-ManropeRegular hover:bg-grey-350 text-black-150 cursor-pointer  ${
                            idx === optionsValue.length - 1
                              ? ""
                              : "border-b border-grey-50"
                          }`}
                          onClick={() => {
                            setState((prevState) => {
                              const updatedPollCategory = [...prevState.polls];
                              updatedPollCategory[idx].options = {
                                type: option.enum,
                                answers: option.data,
                                name: option.name,
                              };
                              updatedPollCategory[idx].images = [];
                              return {
                                ...prevState,
                                polls: updatedPollCategory,
                              };
                            });
                            setErrorsPoll((prevErrors) => {
                              const updatedErrors = [...prevErrors];
                              updatedErrors[idx] = [];
                              return updatedErrors;
                            });

                            setIsOpen(false);
                          }}
                        >
                          {option.name}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickRef>
          </div>
        </div>

        <div className="flex flex-col gap-1 pt-5">
          {state?.polls[idx]?.options?.type === "TEXT" ? (
            <TypeText
              setState={setState}
              state={state}
              idx={idx}
              setErrorsPoll={setErrorsPoll}
              errorsPoll={errorsPoll}
            />
          ) : (
            state?.polls[idx]?.options?.type === "IMAGE" && (
              <TypeImage setState={setState} state={state} idx={idx} />
            )
          )}
        </div>
        <div>
          <div className="flex justify-between gap-1  ">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Availability multiple options
            </label>
            <div>
              <ToggleSwitch
                setValue={() =>
                  setState((prev) => ({
                    ...prev,
                    polls: prev.polls.map((poll, i) =>
                      i === idx
                        ? { ...poll, multipleOptions: !poll.multipleOptions }
                        : poll
                    ),
                  }))
                }
                value={state?.polls[idx]?.multipleOptions}
              />
            </div>
          </div>
          {state?.polls[idx]?.options?.type !== "IMAGE" && (
            <div className="flex justify-between gap-1  pt-3">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                To set an open answer
              </label>
              <div>
                <ToggleSwitch
                  setValue={() =>
                    setState((prev) => ({
                      ...prev,
                      polls: prev.polls.map((poll, i) =>
                        i === idx
                          ? { ...poll, openAnswer: !poll.openAnswer }
                          : poll
                      ),
                    }))
                  }
                  value={state?.polls[idx]?.openAnswer}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Poll;
