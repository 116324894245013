import React, { useEffect, useState } from "react";
import { UserService } from "../../../services";
import utility from "../../../utility";
import { CircularProgress } from "@mui/material";

/**
 * Component for displaying a preview of a link.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.link - The URL of the link to preview.
 * @param {string} [props.bgColor="bg-grey-50"] - Background color of the preview container.
 * @param {string} [props.rounded="rounded-30"] - Rounded corners style of the preview container.
 * @returns {JSX.Element} LinkPreview component.
 */
const LinkPreview = ({
  link,
  bgColor = "bg-grey-50",
  rounded = "rounded-30",
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    const requestParams = {
      url: link,
    };

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getLinkdata(requestParams)
      );

      if (error || !response) return;

      setData(response);

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [link]);

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress
          className={`${
            bgColor !== "bg-grey-50" ? "text-white" : "text-black-100"
          } w-5 h-5`}
        />
      </div>
    );
  }

  return (
    <div
      onClick={() => window.open(data?.url, "_blank")}
      className={`flex gap-5 p-5 ${rounded} ${bgColor} cursor-pointer`}
    >
      {data?.logo && (
        <div className="h-16 w-16 rounded-lg flex flex-nowrap">
          <img src={data.logo} alt="" className="h-16 w-16 rounded-lg" />
        </div>
      )}
      <div className="flex flex-col gap-1">
        <span
          className={`${
            bgColor !== "bg-grey-50" ? "text-white" : "text-black-100"
          }  font-ManropeBold text-ft3`}
        >
          {data?.domain || data?.url}
        </span>
        <span
          className={`
            ${
              bgColor !== "bg-grey-50"
                ? "text-white text-opacity-60"
                : "text-grey-100"
            } font-ManropeRegular text-ft3`}
        >
          {data?.title}
        </span>
      </div>
    </div>
  );
};

export default LinkPreview;
