import React from "react";
import TableFooter from "./tableFooter";
import defaultImage from "../assets/Icons/signupProfileSmall.png";
import { CircularProgress } from "@mui/material";
import placeholder from "../assets/Icons/Placeholder.svg";

/**
 * PollVoted component renders a list of users who voted in a poll, including pagination.
 * @component
 * @param {Object} props - The properties object.
 * @param {boolean} props.loading - Indicates if the votes are currently loading.
 * @param {Array} props.votes - An array of user objects who voted in the poll.
 * @param {Function} props.setPageSize - Function to set the number of votes per page.
 * @param {number} props.pageSize - The current number of votes per page.
 * @param {number} props.currentPage - The current page number.
 * @param {Function} props.handlePageChange - Function to handle changing the page.
 * @param {number} props.totalPages - The total number of pages.
 * @param {number} props.totalDocs - The total number of votes.
 * @param {Function} props.setCurrentPage - Function to set the current page number.
 * @returns {JSX.Element} The PollVoted component.
 */
const PollVoted = ({
  loading,
  votes,
  setPageSize,
  pageSize,
  currentPage,
  handlePageChange,
  totalPages,
  totalDocs,
  setCurrentPage,
}) => {
  return (
    <div className="flex w-full flex-col mt-5  row-start-2 row-span-1 rounded-20 bg-white h-auto ">
      {!loading ? (
        <div className="flex w-full flex-col mt-5  row-start-2 row-span-1 rounded-20 bg-white h-auto">
          {votes?.length > 0 ? (
            <div>
              <div className="max-h-77vh overflow-y-auto scrollbar-hide">
                {votes.map((value, idx) => (
                  <div key={idx} className="flex gap-2.5 mt-5 ml-5">
                    <img
                      src={value.profilePhoto || defaultImage}
                      alt="userProfile"
                      className="w-11.25 h-11.25 rounded-full"
                    />
                    <div className="flex flex-col">
                      <p className="font-ManropeBold text-ft2 text-black-100">
                        {value.fullName}
                      </p>
                      <p className="font-ManropeRegular text-ft2 text-grey-200">
                        {value.userName}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="px-4">
                <TableFooter
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalPages={totalPages}
                  totalDocs={totalDocs}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
              <img src={placeholder} alt="placeholder" />
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center h-100">
          <CircularProgress className="text-black-100 w-5 h-5" />
        </div>
      )}
    </div>
  );
};

export default PollVoted;
