import React, { useState } from "react";

/**
 * React component representing open-ended questions and their responses.
 *
 * @module OpenQuestions
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.details - An array of open-ended questions and their responses.
 * @returns {React.Component} The OpenQuestions component.
 * @example
 * // Usage in a React functional component
 * import OpenQuestions from "./OpenQuestions";
 *
 * const MyComponent = () => {
 *   const openEndedQuestions = [
 *     { count: 5, vote: "Option A" },
 *     { count: 8, vote: "Option B" },
 *     // ... more open-ended questions
 *   ];
 *
 *   return <OpenQuestions details={openEndedQuestions} />;
 * };
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.details - An array of open-ended questions and their responses.
 * @returns {React.Component} The OpenQuestions component.
 */

const OpenQuestions = ({ details }) => {
  /**
   * State to control whether to display all open-ended questions or a subset.
   *
   * @type {boolean}
   */

  const [showAll, setShowAll] = useState(false);

  /**
   * Filtered array of open-ended questions based on the 'showAll' state.
   *
   * @type {Array}
   */

  const displayedSurveyInfo = showAll ? details : details?.slice(0, 9);
  return (
    <>
      <div className="flex w-full flex-wrap">
        {displayedSurveyInfo?.map((value, index) => (
          <div
            key={index}
            className="flex rounded-20 items-center justify-center m-2 p-2 bg-grey-50 gap-1"
          >
            <p className="text-black-100 font-ManropeBold text-ft2">
              {value.count}
            </p>
            <p className="text-black-75 font-ManropeRegular text-ft2">
              {value.vote}
            </p>
          </div>
        ))}
      </div>
      {details?.length > 9 && (
        <p
          className="text-orange-50 font-ManropeBold text-ft2 cursor-pointer"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "View less answers" : "View all answers"}
        </p>
      )}
    </>
  );
};

export default OpenQuestions;
