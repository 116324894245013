import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../../../common/component/dropdown";
import { ReactComponent as AddImage } from "../../../assets/Icons/paperclip.svg";
import { ReactComponent as Delete } from "../../../assets/Icons/delete.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { CircularProgress } from "@mui/material";
import ShowToast from "../../../common/showToast";
import { failureMessage, toastType } from "../../../constant/commonArray";
import ImagePopUp from "../../../common/imagePopUp";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

/**
 * EntityInfo component for handling entity information.
 * @component
 * @param {Object} props - Component props.
 * @param {function} props.setSteps - Function to set the current step.
 * @param {Object} props.state - Current state of the component.
 * @param {function} props.setState - Function to update the component state.
 * @param {Array<File>} props.files - Array of selected files.
 * @param {function} props.setFiles - Function to update the selected files.
 * @param {string} props.valueEntity - Selected entity type.
 * @param {function} props.setValueEntity - Function to update the selected entity type.
 * @returns {JSX.Element} React component.
 */

const nameRegex = new RegExp(/^[a-zA-Z\s]*$/);
const EntityInfo = ({
  setSteps,
  state,
  setState,
  files,
  setFiles,
  setValueEntity,
  valueEntity,
  setError,
  error,
}) => {
  const optionsEntity = ["Organization", "Business", "Government"];

  const fileInputRef = useRef(null);
  const [toggleImagePopUp, setToggleImagePopUp] = useState(false);
  const [image, setImages] = useState([]);
  const user = useSelector((state) => state.user);

  const { getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/gif": [".gif"],
      "application/pdf": [".pdf"],
      "application/docx": [".docx"],
      "application/doc": [".doc"],
    },
    maxSize: 5 * 1024 * 1024,
    disablePreview: false,
    disabled: false,
    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );

      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR_ENTITY,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED,
          type: toastType.ERROR,
        });
      } else {
        const updatedFiles = [...files, ...acceptedFiles];
        setFiles(updatedFiles);
      }
    },
  });

  const handleSpanClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteClick = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleChange = (address) => {
    setState((prev) => ({
      ...prev,
      registrationAddress: {
        location: {
          ...prev.registrationAddress.location,
          name: address,
        },
      },
    }));
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setState((prev) => ({
          ...prev,
          registrationAddress: {
            location: {
              ...prev.registrationAddress.location,
              name: address,
              latitude: latLng.lat,
              longitude: latLng.lng,
            },
          },
        }));
      })
      .catch((error) => console.error("Error", error));
  };
  const handleCompanyWebsiteChange = (e) => {
    const enteredValue = e.target.value;
    const isValid =
      /^(https?:\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.(com|in|net|org|edu|gov|mil|co|info|biz|name|mobi|us|ca|uk)\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/g.test(
        enteredValue
      );

    if (enteredValue.length <= 75) {
      setError((prev) => ({ ...prev, websiteError: false }));
    } else {
      setError((prev) => ({ ...prev, websiteError: true }));
    }
    if (isValid || enteredValue === "") {
      setError((prev) => ({ ...prev, isValidWebsite: false }));
    } else {
      setError((prev) => ({ ...prev, isValidWebsite: true }));
    }
    setState((prev) => ({
      ...prev,
      organisationDocuments: {
        ...prev.organisationDocuments,
        companyWebsite: enteredValue,
      },
    }));
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, type: valueEntity?.toUpperCase() }));
    // eslint-disable-next-line
  }, [valueEntity]);

  return (
    <div className="mt-15 flex flex-col">
      <div className="flex flex-col gap-15">
        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Entity type
          </label>
          <div className="w-1/2">
            <Dropdown
              options={optionsEntity}
              placeHolder={"Not choosen"}
              value={valueEntity}
              setValue={setValueEntity}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Entity license
            </label>
            <input
              type="text"
              value={state?.organisationDocuments?.licence}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 50) {
                  setError((prev) => ({ ...prev, licence: false }));
                } else {
                  setError((prev) => ({ ...prev, licence: true }));
                }
                setState((prev) => ({
                  ...prev,
                  organisationDocuments: {
                    ...prev.organisationDocuments,
                    licence: e.target.value,
                  },
                }));
              }}
              className="w-1/2 rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
          </div>
          {error?.licence && (
            <span className=" text-red-50">
              Entity license can't be more than 50
            </span>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Company website
          </label>
          <input
            type="text"
            value={state?.organisationDocuments?.companyWebsite}
            onChange={handleCompanyWebsiteChange}
            className="w-1/2 rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
          />
          {error.isValidWebsite && (
            <span className=" text-red-50">
              Invalid website link. Please try a valid link.
            </span>
          )}
          {error.websiteError && (
            <span className=" text-red-50">
              Company website can't be more than 75
            </span>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Name of tax ID
            </label>
            <input
              type="text"
              value={state?.organisationDocuments?.taxIdName}
              onChange={(e) => {
                const inputValue = e.target.value;
                const isValid = nameRegex.test(inputValue);
                if (inputValue.length <= 50) {
                  setError((prev) => ({ ...prev, taxIdName: false }));
                } else {
                  setError((prev) => ({ ...prev, taxIdName: true }));
                }
                if (isValid || inputValue === "") {
                  setState((prev) => ({
                    ...prev,
                    organisationDocuments: {
                      ...prev.organisationDocuments,
                      taxIdName: e.target.value,
                    },
                  }));
                }
              }}
              className="w-1/2 rounded-full bg-grey-50 outline-none px-6 h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
          </div>
          {error?.taxIdName && (
            <span className=" text-red-50">
              Name of tax ID can't be more than 50
            </span>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Tax ID
            </label>
            <input
              type="text"
              value={state?.organisationDocuments?.taxId}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 50) {
                  setError((prev) => ({ ...prev, taxId: false }));
                } else {
                  setError((prev) => ({ ...prev, taxId: true }));
                }
                setState((prev) => ({
                  ...prev,
                  organisationDocuments: {
                    ...prev.organisationDocuments,
                    taxId: e.target.value,
                  },
                }));
              }}
              className="w-1/2 rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
          </div>

          {error?.taxId && (
            <span className=" text-red-50">Tax ID can't be more than 50</span>
          )}
        </div>

        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            State of registration
          </label>
          {user.gmapsLoaded && (
            <PlacesAutocomplete
              value={state?.registrationAddress?.location?.name}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="relative">
                  <input
                    {...getInputProps({
                      placeholder: "Start type",
                      className:
                        "w-1/2 rounded-full bg-grey-50 outline-none px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3",
                    })}
                  />
                  <div className="max-h-56 overflow-y-auto z-50 shadow-xl bg-white w-1/2 mt-2 left-0 rounded-20 absolute">
                    {loading && (
                      <div className="flex justify-center  items-center h-56 w-full">
                        <CircularProgress className="text-black-100 w-5 h-5" />
                      </div>
                    )}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active p-5"
                        : "suggestion-item p-5";
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          )}
        </div>
      </div>
      <div className="mt-15">
        <div className="flex gap-4 pb-7.5 ">
          {files.map((file, idx) => {
            return (
              <div className="relative">
                <div
                  key={idx}
                  onClick={() => {
                    setToggleImagePopUp(!toggleImagePopUp);
                    setImages(files);
                  }}
                  className="w-30 h-30 rounded-20 flex justify-end"
                >
                  {file?.name?.includes(".pdf") ||
                  file?.name?.includes(".docx") ||
                  file?.name?.includes(".doc") ? (
                    <div
                      className={`w-30 h-30 flex-col rounded-20 justify-end items-center gap-2 flex cursor-pointer`}
                    >
                      <AddImage className="w-10 h-10" />
                      <span className="text-ft1 text-center text-black-75 font-ManropeMedium">
                        preview file
                      </span>
                    </div>
                  ) : (
                    <img
                      src={URL.createObjectURL(file)}
                      alt=""
                      className="w-30 h-30 rounded-20"
                    />
                  )}
                </div>
                <div className="absolute flex justify-center rounded-20 items-center right-2 top-2 bg-white bg-opacity-40 w-7.5 h-7.5">
                  <Delete
                    onClick={() => handleDeleteClick(idx)}
                    className="w-4 h-4 cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div
          onClick={handleSpanClick}
          className="flex gap-2.5 items-center cursor-pointer"
        >
          <AddImage />
          <span className="text-orange-50 font-ManropeBold text-ft2">
            Add document
          </span>
          <input
            {...getInputProps()}
            key={files.length}
            ref={fileInputRef}
            type="file"
            className="hidden"
            accept=".png,.jpg,.pdf,.doc,.docx"
          />
        </div>
      </div>
      <div className="flex justify-end mt-15 gap-7.5">
        <button
          onClick={() => setSteps(0)}
          className="border border-black-100 border-opacity-20 rounded-full w-55 h-16.25"
        >
          Previous
        </button>
        <button
          disabled={
            !state.type ||
            !state?.organisationDocuments?.licence ||
            !state?.organisationDocuments?.taxId ||
            !state?.organisationDocuments?.taxIdName ||
            !state?.organisationDocuments?.companyWebsite ||
            files.length <= 0 ||
            error?.licence ||
            error?.taxId ||
            error?.taxIdName ||
            error?.websiteError ||
            error?.isValidWebsite
          }
          onClick={() => {
            setSteps(2);
          }}
          className={`${
            state.type &&
            state?.organisationDocuments?.licence &&
            state?.organisationDocuments?.taxId &&
            state?.organisationDocuments?.taxIdName &&
            state?.organisationDocuments?.companyWebsite &&
            files.length > 0 &&
            !error?.licence &&
            !error?.taxId &&
            !error?.taxIdName &&
            !error?.websiteError &&
            !error?.isValidWebsite
              ? "bg-orange-50"
              : "bg-grey-50"
          } rounded-full w-55 h-16.25`}
        >
          Save
        </button>
      </div>
      {toggleImagePopUp && (
        <ImagePopUp
          toggleImagePopUp={toggleImagePopUp}
          setToggleImagePopUp={setToggleImagePopUp}
          images={image}
        />
      )}
    </div>
  );
};

export default EntityInfo;
