/**
 * Login Component for user authentication.
 * @module LoginComponent
 */

import React, { useEffect, useState } from "react";
import LoginPopUp from "./LoginpopUp";
import EmailPopUp from "./EmailpopUp";
import logo from "../../assets/Icons/logo.svg";
import upperHand from "../../assets/Icons/upperHand.svg";
import lowerHand from "../../assets/Icons/lowerHand.svg";
import handL from "../../assets/Icons/handL.png";
import handU from "../../assets/Icons/handU.png";
import eyeHide from "../../assets/Icons/eyeHide.svg";
import eyeShow from "../../assets/Icons/eyeShow.svg";
import "react-toastify/dist/ReactToastify.css";
import ShowToast from "../../common/showToast";
import {
  cookiesConstants,
  failureMessage,
  toastType,
  keyTypeConstants,
  permissionRoutes,
} from "../../constant/commonArray";
import { sessionManager } from "../../managers/sessionManager";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import utility from "../../utility";
import { UserService } from "../../services";
import CryptoJS from "crypto-js";
const { AUTH0_ID_TOKEN, POLL_DETAILS } = keyTypeConstants;

/**
 * Functional component representing the login page.
 * @function Login
 * @returns {JSX.Element} JSX for the login component.
 */

const Login = () => {
  const [forgotSeen, setForgotSeen] = useState(false);
  const dispatch = useDispatch();
  const [loginSeen, setLoginSeen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoginEnabled, setIsLoginEnabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setDataInCookies } = sessionManager;
  const history = useHistory();
  const screenHeight = window.innerHeight;
  const [disableOtp, setDisableOtp] = useState(
    sessionManager.getDataFromCookies(cookiesConstants.DISABLE_OTP) || false
  );

  /**
   * Displays a Toast notification for password reset.
   * @function notify
   * @param {string} recoveryEmail - The email to which the reset email is sent.
   * @returns {void}
   */

  const notify = (recoveryEmail) => {
    ShowToast({
      message: `A password reset email has been sent to ${recoveryEmail}. If you didn't receive an email, check your spam folder`,
      type: toastType.SUCCESS,
    });
  };

  /**
   * Shows a password recovery message in the UI.
   * @function showPasswordRecoveryMessage
   * @param {string} recoveryEmail - The email for which the recovery message is shown.
   * @returns {void}
   */

  const showPasswordRecoveryMessage = (recoveryEmail) => {
    notify(recoveryEmail);
  };

  /**
   * Handles changes in the email input field.
   * @function handleEmailChange
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event object for the input change.
   * @returns {void}
   */

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError("");
    checkLoginAvailability(emailValue, password);
  };

  /**
   * Handles changes in the password input field.
   * @function handlePasswordChange
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event object for the input change.
   * @returns {void}
   */
  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    checkLoginAvailability(email, passwordValue);
  };

  /**
   * Handles blur event for the email input field.
   * Validates the email and sets an error if invalid.
   * @function handleEmailBlur
   * @returns {void}
   */

  const handleEmailBlur = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
    }
  };

  function validatePassword(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    return regex.test(password);
  }

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  /**
   * Checks the availability of login based on email and password validity.
   * @function checkLoginAvailability
   * @param {string} email - The user's email.
   * @param {string} password - The user's password.
   * @returns {void}
   */

  const checkLoginAvailability = (email, password) => {
    setIsLoginEnabled(validateEmail(email) && validatePassword(password));
  };

  const alternateForgotPopUp = () => {
    setForgotSeen(!forgotSeen);
  };

  /**
   * Handles the login process, communicates with the authentication service, and updates the Redux store.
   * @function handleLogin
   * @returns {void}
   */

  const checkEmailAvailable = async () => {
    const requestData = {
      email: email,
    };
    try {
      let email = await new UserService().checkEmail(requestData);

      return email.email_verified;
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
      return false;
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const emailVerified = await checkEmailAvailable();

      if (!emailVerified) {
        ShowToast({
          message: "Email is not verified.",
          type: toastType.ERROR,
        });
        setLoading(false);
        return;
      }

      let requestData = {
        email: email,
      };

      const encrypted = CryptoJS.AES.encrypt(
        password,
        process.env.REACT_APP_PASSWORD_ENCRYPT_KEY
      );
      requestData.password = encrypted.toString();

      const [error, signInRes] = await utility.parseResponse(
        new UserService().staffLogin(requestData)
      );

      let errorMessage = "";
      if (error === "Wrong email or password.") {
        errorMessage = "Incorrect email or password.";
      } else if (error === "user is blocked") {
        errorMessage = "User is blocked";
      } else {
        errorMessage = error;
      }

      if (error) {
        ShowToast({
          message: errorMessage,
          type: toastType.ERROR,
        });
        setLoading(false);
        return;
      }

      const encryptedToken = CryptoJS.AES.decrypt(
        signInRes?.token,
        process.env.REACT_APP_PASSWORD_ENCRYPT_KEY
      );
      const decryptedToken = JSON.parse(
        encryptedToken.toString(CryptoJS.enc.Utf8)
      );

      let matchingRoute;
      let matchingRoutePollDetails;
      if (!error) {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `${decryptedToken?.access_token}`,
        };

        const [err, res] = await utility.parseResponse(
          new UserService().getCheckStaff(decryptedToken?.authId, headers)
        );
        if (err || res?.responseCode !== 200) {
          history.push("/");
          ShowToast({
            message: failureMessage.YOU_DONT_HAVE_ACCESS,
            type: toastType.ERROR,
          });
          return;
        }

        const userPayload = {
          isLoggedIn: decryptedToken?.access_token ? true : false,
          name: res?.responseData?.name,
          email: res?.responseData?.email,
          profilePicture: res?.responseData?.profilePhoto,
          roleDetails: res?.responseData?.roleDetails,
          phone: res?.responseData?.phone,
          userId: decryptedToken?.authId,
          staffId: res?.responseData?._id,
          accessToken: decryptedToken?.access_token,
          countryCode: res?.responseData?.countryCode,
        };
        dispatch({ type: "UPDATE_USER", payload: userPayload });

        const userPermissions = res?.responseData?.roleDetails?.access.map(
          (permission) => permission.toLowerCase().replace(/\s+/g, "")
        );

        matchingRoute = Object.entries(permissionRoutes)?.find(([permission]) =>
          userPermissions?.includes(
            permission?.toLowerCase()?.replace(/\s+/g, "")
          )
        );
        matchingRoutePollDetails = Object.entries(permissionRoutes)?.find(
          ([permission]) =>
            permission === "poll:read:list" &&
            userPermissions?.includes(
              permission?.toLowerCase()?.replace(/\s+/g, "")
            )
        );

        setDataInCookies(decryptedToken.access_token, AUTH0_ID_TOKEN, 1);
      }
      const pollReviewData = sessionManager.getDataFromCookies(POLL_DETAILS);
      if (pollReviewData) {
        history.push(
          matchingRoutePollDetails
            ? `/poll-list/details/${pollReviewData.pollId}?userId=${pollReviewData.userId}&isReview=${pollReviewData.isReview}`
            : "/profile"
        );
        sessionManager.removeDataFromCookies(POLL_DETAILS);
      } else {
        history.push(matchingRoute ? matchingRoute[1] : "/profile");
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Toggles the visibility of the password.
   * @function handlePasswordVisibilityToggle
   * @returns {void}
   */

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (disableOtp) {
      const timer = setInterval(() => {
        setDisableOtp(
          sessionManager.getDataFromCookies(cookiesConstants.DISABLE_OTP)
        );
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [disableOtp]);

  return (
    <div className="w-full h-screen bg-white grid grid-cols-loginCol">
      <div className="flex flex-col col-start-1 col-span-1">
        <img
          src={logo}
          alt="logo"
          className="flex-none mt-10.75 ml-12.5 w-30.25 h-8.75"
        />
        <div className="flex flex-col flex-grow items-center justify-center">
          <div className="flex flex-col items-start gap-8">
            <p className=" text-black-100 font-ManropeBold text-ft6">Log In</p>
            <div className="flex flex-col items-start gap-5">
              <div className="flex flex-col items-start gap-0.5">
                <input
                  type="email"
                  placeholder="Email"
                  className={`flex w-83.75 h-16.25 p-2.5 px-6.25 items-center gap-5 bg-grey-50 text-black-150 text-ft3 rounded-full focus:outline-none ${
                    emailError
                      ? "  border-2 border-solid border-orange-100"
                      : ""
                  }`}
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                />
                {emailError && (
                  <div className="text-orange-100 font-ManropeLight">
                    {emailError}
                  </div>
                )}
              </div>
              <div className="flex w-83.75 h-16.25 py-2.5 px-6.25 items-center justify-between rounded-full bg-grey-50">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="bg-grey-50 text-black-150 text-ft3 focus:outline-none h-full w-95per pr-2"
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && isLoginEnabled && !loading) {
                      handleLogin();
                    }
                  }}
                />

                <button onClick={handlePasswordVisibilityToggle}>
                  {showPassword ? (
                    <img src={eyeShow} alt="eyeShow" />
                  ) : (
                    <img src={eyeHide} alt="eyeHide" />
                  )}
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center gap-5">
              <button
                className={`flex w-83.75 h-16.25 p-2.5 px-7.5 justify-center items-center rounded-full bg-orange-50 text-ft2 text-black-100 font-ManropeBold ${
                  isLoginEnabled && !loading
                    ? ""
                    : "bg-grey-50 text-black-200 pointer-events-none"
                }`}
                onClick={handleLogin}
                disabled={!isLoginEnabled || loading}
              >
                {loading ? (
                  <CircularProgress className="text-orange-50" />
                ) : (
                  "Log In"
                )}
              </button>
              <button
                className="flex items-start font-ManropeBold text-ft2 text-orange-50 text-center"
                onClick={() => {
                  alternateForgotPopUp();
                }}
              >
                Forgot Password
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-start-2 col-span-1 ml-auto mr-auto hidden lg:block">
        <div className="relative">
          <img
            src={upperHand}
            alt="Bottom"
            className="object-cover opacity-10"
            style={{ height: screenHeight * 0.5 }}
          />
          <img
            src={handU}
            alt="Top"
            className="absolute top-0 left-2per z-10 object-cover"
            style={{ height: screenHeight * 0.48 }}
          />
        </div>
        <div className="relative">
          <img
            src={lowerHand}
            alt="Bottom"
            className="object-cover opacity-10"
            style={{ height: screenHeight * 0.5 }}
          />
          <img
            src={handL}
            alt="Top"
            className="absolute bottom-0 left-15per z-10 object-cover"
            style={{ height: screenHeight * 0.48 }}
          />
        </div>
      </div>
      {loginSeen ? (
        <LoginPopUp
          login={handleLogin}
          setLoginSeen={setLoginSeen}
          email={email}
          disableOtp={disableOtp}
          setDisableOtp={setDisableOtp}
        />
      ) : null}
      {forgotSeen ? (
        <EmailPopUp
          forgot={alternateForgotPopUp}
          onResetPasswordClick={showPasswordRecoveryMessage}
          detail={email}
          notify={true}
          setLoginSeen={setLoginSeen}
          disableOtp={disableOtp}
          setDisableOtp={setDisableOtp}
        />
      ) : null}
    </div>
  );
};

export default Login;
