/**
 * @module StatisticsComponent
 * @description
 * Module for managing and rendering additional fields.
 *
 * @example
 * // Usage in a React functional component
 * import StatisticsComponent from "./StatisticsComponent";
 *
 * const MyComponent = () => {
 *   return <StatisticsComponent />;
 * };
 *
 * @returns {React.Component} The StatisticsComponent component
 */

import React, { useEffect, useRef, useState } from "react";
// import documentDownload from "../../assets/Icons/documentDownload.svg";
import chevronUp from "../../assets/Icons/chevronUp.svg";
import chevronDown from "../../assets/Icons/Chevrondown.svg";
import Location from "./location";
import Account from "./account";
import Users from "./users";
import Polls from "./polls";
import placeholder from "../../assets/Icons/Placeholder.svg";
import RightSideFields from "./righSideFields";
import Calendar from "react-calendar";
import calender from "../../assets/Icons/calender.svg";
import { UserService } from "../../services";
import utility from "../../utility";
import { useDebouncedCallback } from "use-debounce";
import { CircularProgress } from "@mui/material";
import OutsideClickRef from "../../common/outsideClickRef";
import checkMark from "../../assets/Icons/checkMark.svg";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import moment from "moment";
import Close from "../../assets/Icons/close.svg";

const StatisticsComponent = () => {
  const [openCalendar, setCalendar] = useState(false);
  const [openCustomTime, setOpenCustomTime] = useState(false);
  const [data, setData] = useState([]);
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const dropdownRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const [countrySelected, setCountrySelected] = useState("");
  const [toggleCalenderStart, setToggleCalenderStart] = useState(false);
  const [customTIme, setCustomTime] = useState("");
  const [period, setPeriod] = useState("");
  const [toggleCalenderEnd, setToggleCalenderEnd] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const optionRefStart = useRef();
  const optionRefEnd = useRef();
  const timeRef = useRef();
  const [selectStartDate, setSelectStartDate] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
  );
  const [selectEndDate, setSelectEndDate] = useState(new Date());
  const dateToEpoch = (date) => date.getTime();
  const datePeriod = [
    "Last 7 days",
    "Last 14 days",
    "Previous month",
    "All Time",
    "Select period",
  ];

  /**
   * Asynchronously fetches statistics data based on the provided start and end dates
   *
   * @param {type} start - the start date for the statistics data
   * @param {type} end - the end date for the statistics data
   * @return {type} undefined
   */
  const getStatisticsData = async (start, end) => {
    setLoadingData(true);
    try {
      if (start && end && start >= end) {
        ShowToast({
          message: "Start date should be less than end date.",
          type: toastType.ERROR,
        });
        return;
      }
      if (start && end) {
        setStartDate(start);
        setEndDate(end);
      }
      let requestParam = {};
      if (countrySelected) {
        requestParam.location = countrySelected;
      }
      if (customTIme) {
        const { startDate, endDate } = getDateRange(customTIme);
        requestParam.from = startDate;
        requestParam.to = endDate;
      }
      if (start && end) {
        requestParam.from = dateToEpoch(start);
        requestParam.to = dateToEpoch(end);
      }
      const [error, response] = await utility.parseResponse(
        new UserService().getStatistics(requestParam)
      );

      if (error || !response) return;
      setData(response);
      setLoadingData(false);
      setCalendar(false);
      setOpenCustomTime(false);
    } catch (e) {
      console.error(e);
      setLoadingData(false);
    }
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 500);

  /**
   * Asynchronous function to fetch location data and update the country state.
   *
   */
  const getLocation = async () => {
    setLoading(true);

    try {
      let requestParam = {
        locationName: debounceValue,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().getLocation(requestParam)
      );
      if (error || !response) return;
      setCountry(response);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    debouncedSearch(value);
    setDropDownOpen(true);
  };

  /**
   * Function to get the date range based on the given label.
   *
   * @param {string} label - The label for the date range.
   * @return {object} An object containing the start and end dates.
   */
  const getDateRange = (label) => {
    const now = new Date();
    switch (label) {
      case "Last 7 days":
        return {
          startDate: dateToEpoch(new Date(now - 6 * 24 * 60 * 60 * 1000)),
          endDate: dateToEpoch(now),
        };
      case "Last 14 days":
        return {
          startDate: dateToEpoch(new Date(now - 13 * 24 * 60 * 60 * 1000)),
          endDate: dateToEpoch(now),
        };
      case "Previous month":
        const firstDayOfMonth = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          1
        );
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        return {
          startDate: dateToEpoch(firstDayOfMonth),
          endDate: dateToEpoch(lastDayOfMonth),
        };
      case "All Time":
        return {
          startDate: 0,
          endDate: dateToEpoch(now),
        };
      default:
    }
  };

  const formatDate = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date?.getDate();
    const month = months[date?.getMonth()];
    const year = date?.getFullYear();

    return `${day} ${month} ${year}`;
  };

  /**
   * Updates the custom time range and sets the start and end dates based on the label.
   *
   * @param {string} label - the label for the custom time range
   * @return {void}
   */
  const handleDateRangeChange = (label) => {
    setCustomTime(label);
    const { startDate, endDate } = getDateRange(label);
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
  };

  useEffect(() => {
    if (searchQuery) getLocation();
    // eslint-disable-next-line
  }, [debounceValue]);

  useEffect(() => {
    getStatisticsData();
    // eslint-disable-next-line
  }, [countrySelected, customTIme]);

  /**
   * Downloads data as a CSV file.
   *
   * @param {Object} userByLocation - user data grouped by location
   * @param {Array} activeAccount - list of active user accounts
   * @param {Number} avgTimeOnApp - average time spent on the app
   * @param {Array} allData - all the data to be converted to CSV
   * @param {string} fileName - the name of the CSV file to be downloaded
   * @return {void}
   */
  // const downloadCSV = (
  //   userByLocation,
  //   activeAccount,
  //   avgTimeOnApp,
  //   allData,
  //   fileName
  // ) => {
  //   const csvContent = convertToCSV(
  //     userByLocation,
  //     activeAccount,
  //     avgTimeOnApp,
  //     allData
  //   );

  //   const blob = new Blob([csvContent], { type: "text/csv" });

  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = fileName || "data.csv";

  //   document.body.appendChild(a);
  //   a.click();

  //   document.body.removeChild(a);
  //   URL.revokeObjectURL(url);
  // };

  // const convertToCSV = (
  //   userByLocation,
  //   activeAccount,
  //   avgTimeOnApp,
  //   allData
  // ) => {
  //   let csvContent = "";

  //   const customHeaders = [
  //     "Number of open polls",
  //     "Number of close polls",
  //     "Number of total polls",
  //     "Number of votes",
  //     "Number of App download",
  //     "Number of Business Account",
  //     "Number of User Account",
  //   ];
  //   const header1 = customHeaders.join(",") + "\n";
  //   const rowData = `${allData?.polls?.activePolls || 0},${allData?.polls?.closedPolls || 0
  //     },${allData?.polls?.createdPolls || 0},${allData?.votes?.total || 0},${allData?.application?.appStoreDownloads +
  //     allData?.application?.playStoreDownloads || 0
  //     },${allData?.businessAccounts?.total || 0},${allData?.userAccounts?.total || 0
  //     }\n`;
  //   csvContent += header1 + rowData;
  //   csvContent += "\n";

  //   if (userByLocation?.length > 0) {
  //     csvContent += `    Number of Total Users\n`;
  //     const filteredUserByLocation = userByLocation.map(
  //       ({ latitude, longitude, ...rest }) => rest
  //     );
  //     const header2 = Object.keys(filteredUserByLocation[0]).join(",") + "\n";
  //     const rows2 = filteredUserByLocation.map(
  //       (row) => Object.values(row).join(",") + "\n"
  //     );
  //     csvContent += header2 + rows2.join("") + "\n";

  //     csvContent += "\n";
  //   }

  //   if (activeAccount?.length > 0) {
  //     csvContent += `     Number of Active users\n`;
  //     const header3 = Object.keys(activeAccount[0]).join(",") + "\n";
  //     const rows3 = activeAccount.map(
  //       (row) => Object.values(row).join(",") + "\n"
  //     );
  //     csvContent += header3 + rows3.join("") + "\n";

  //     csvContent += "\n";
  //   }
  //   if (avgTimeOnApp?.length > 0) {
  //     csvContent += `      Average time spend on the App\n`;
  //     const header4 = Object.keys(avgTimeOnApp[0]).join(",") + "\n";
  //     const rows4 = avgTimeOnApp.map(
  //       (row) => Object.values(row).join(",") + "\n"
  //     );
  //     csvContent += header4 + rows4.join("") + "\n";
  //   }

  //   return csvContent;
  // };

  if (loadingData) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div className="w-full h-full overflow-y-auto pr-12.5">
      <div className="flex py-5 w-full gap-5">
        <div className="flex flex-col w-75per gap-5">
          <div className="flex justify-between gap-2.5">
            <OutsideClickRef
              setState={() => {
                setCalendar(false);
              }}
              className="w-full"
              state={timeRef}
            >
              <div className="flex flex-grow justify-center items-center rounded-20 bg-orange-50 h-16.25 gap-2.5 relative cursor-pointer">
                <div
                  onClick={() => {
                    if (!openCalendar && openCustomTime) {
                      setCalendar(false);
                      setOpenCustomTime(false);
                    } else {
                      setCalendar(!openCalendar);
                    }
                  }}
                  className="flex flex-grow justify-center w-full items-center h-16.25 gap-2.5"
                >
                  <img src={calender} alt="calendar" />
                  <p className="text-black-100 font-ManropeBold text-ft2">
                    {(!!customTIme && customTIme !== "All Time") ||
                    period === "Selected period"
                      ? formatDate(startDate)
                      : moment(
                          data?.votes?.votesByDate[
                            data?.votes?.votesByDate?.length - 1
                          ]?.addedOn
                        ).format("DD MMM YYYY")}
                    {" - "}{" "}
                    {(!!customTIme && customTIme !== "All Time") ||
                    period === "Selected period"
                      ? formatDate(endDate)
                      : moment(data?.votes?.votesByDate[0]?.addedOn).format(
                          "DD MMM YYYY"
                        )}
                  </p>
                  <img
                    src={
                      openCalendar || openCustomTime ? chevronUp : chevronDown
                    }
                    alt="chevronUp"
                    className="cursor-pointer"
                  />
                </div>
                {openCalendar && (
                  <div className="absolute bg-white rounded-20 top-18 z-50 left-0 w-full cursor-pointer shadow-dropdownShadow">
                    {datePeriod.map((item, index) => (
                      <div
                        onClick={() => {
                          if (item === "Select period") {
                            setOpenCustomTime("CALENDER");
                            setCalendar(false);
                          } else {
                            handleDateRangeChange(item);
                            setPeriod("");
                          }
                        }}
                        className={`flex flex-col hover:bg-grey-50 gap-5 pt-5 ${
                          index === datePeriod.length - 1
                            ? "pb-5 rounded-b-20"
                            : ""
                        } ${index === 0 ? "rounded-t-20" : ""}`}
                        key={index}
                      >
                        <p className="text-black-100 font-ManropeMedium text-ft3 px-5">
                          {item}
                        </p>
                        <hr
                          className={`${
                            index === datePeriod.length - 1
                              ? "hidden"
                              : "text-grey-50"
                          }`}
                        />
                      </div>
                    ))}
                  </div>
                )}

                {openCustomTime && (
                  <div className="absolute bg-white rounded-20 top-18 z-50 left-0 w-full py-2.5 cursor-pointer shadow-dropdownShadow">
                    <div className="flex w-full justify-between p-5 ">
                      <div className="flex gap-10 flex-wrap">
                        <div className="flex flex-col relative">
                          <p className="text-black-100 font-ManropeMedium text-ft3">
                            Start Date
                          </p>
                          <OutsideClickRef
                            setState={() => {
                              setToggleCalenderStart(false);
                            }}
                            state={optionRefStart}
                          >
                            <div>
                              <div
                                className="flex justify-between px-6.25 py-5 rounded-full bg-grey-50 w-40"
                                onClick={() =>
                                  setToggleCalenderStart(!toggleCalenderStart)
                                }
                              >
                                <p>
                                  {selectStartDate.getDate() +
                                    "." +
                                    (selectStartDate.getMonth() + 1) +
                                    "." +
                                    selectStartDate.getFullYear()}
                                </p>
                                <img
                                  src={calender}
                                  alt={"calender"}
                                  className="cursor-pointer"
                                />
                              </div>
                              {toggleCalenderStart && (
                                <Calendar
                                  onChange={(value) => {
                                    setSelectStartDate(value);
                                    setToggleCalenderStart(false);
                                  }}
                                  value={selectStartDate}
                                  className="absolute top-25 left-0 bg-white w-99.5 rounded-20 shadow-inner border-0.5 border-black-10 font-ManropeRegular text-ft3 text-black-100 z-10"
                                />
                              )}
                            </div>
                          </OutsideClickRef>
                        </div>
                        <div className="flex flex-col relative">
                          <p className="text-black-100 font-ManropeMedium text-ft3">
                            End Date
                          </p>
                          <OutsideClickRef
                            setState={() => setToggleCalenderEnd(false)}
                            state={optionRefEnd}
                          >
                            <div>
                              <div
                                onClick={() =>
                                  setToggleCalenderEnd(!toggleCalenderEnd)
                                }
                                className="flex justify-between px-6.25 py-5 rounded-full bg-grey-50 w-40"
                              >
                                <p>
                                  {selectEndDate.getDate() +
                                    "." +
                                    (selectEndDate.getMonth() + 1) +
                                    "." +
                                    selectEndDate.getFullYear()}
                                </p>
                                <img
                                  src={calender}
                                  alt={"calender"}
                                  className="cursor-pointer"
                                />
                              </div>
                              {toggleCalenderEnd && (
                                <Calendar
                                  onChange={(value) => {
                                    setSelectEndDate(value);
                                    setToggleCalenderEnd(false);
                                  }}
                                  value={selectEndDate}
                                  className="absolute top-25 left-0 bg-white w-99.5 rounded-20 shadow-inner border-0.5 border-black-10 font-ManropeRegular text-ft3 text-black-100 z-10"
                                />
                              )}
                            </div>
                          </OutsideClickRef>
                        </div>
                      </div>
                      <div className="flex gap-10 items-center pt-4">
                        <button
                          onClick={() => {
                            if (selectStartDate < selectEndDate) {
                              setCalendar(false);
                              getStatisticsData(selectStartDate, selectEndDate);
                              setPeriod("Selected period");
                            } else {
                              ShowToast({
                                message:
                                  "Start date should be less than end date.",
                                type: toastType.ERROR,
                              });
                            }
                          }}
                          className="w-32.5 h-11 rounded-20 bg-orange-50 font-ManropeBold text-ft2 text-black-100"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickRef>

            {/* {userPermissions?.indexOf("statistics:read:download") > -1 && (
              <div
                onClick={() =>
                  downloadCSV(
                    data?.totalAccounts?.accountsByLocation,
                    data?.totalAccounts?.activeAccountsByLocation,
                    data?.application?.averageTimeByLocation,
                    data,
                    "statistics.csv"
                  )
                }
                className="flex justify-center items-center h-16.25 rounded-20 bg-orange-50 cursor-pointer w-16.25 !important"
              >
                <img src={documentDownload} alt="documentDownload" />
              </div>
            )} */}
          </div>

          <div className="flex flex-col p-5 bg-white rounded-20 h-32.5">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Country
            </p>
            <div className="relative">
              <div className="h-16.25 w-full rounded-full flex items-center justify-between bg-grey-50 px-5">
                <input
                  type="text"
                  value={countrySelected || searchQuery}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Start typing"
                  className="w-full focus:outline-none bg-grey-50"
                />
                {countrySelected && (
                  <img
                    src={Close}
                    alt=""
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => {
                      setCountrySelected("");
                      setSearchQuery("");
                    }}
                  />
                )}
              </div>

              <OutsideClickRef state={dropdownRef} setState={setDropDownOpen}>
                {searchQuery && !loading && dropDownOpen && (
                  <div className="absolute top-18 max-h-65 overflow-y-auto z-10 left-0 right-0 shadow-inner bg-white rounded-20 w-full">
                    {country?.length > 0 ? (
                      country.map((item) => (
                        <div
                          key={item.country}
                          className="flex gap-2.5 px-5 py-2.5 cursor-pointer justify-between border-b-0.5 border-grey-50"
                        >
                          <div className="flex justify-between">
                            <div className="flex gap-3.75">
                              <div className="flex flex-col">
                                <p className="text-black-100 font-ManropeBold text-ft3">
                                  {item.country}
                                </p>
                                <p className="text-black-600 font-ManropeRegular text-ft2">
                                  {utility.formatNumber(item.countOfUsers)}{" "}
                                  users
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="relative flex items-center">
                            <input
                              type="checkbox"
                              checked={countrySelected === item.country}
                              onChange={() => {
                                setCountrySelected((prevCountry) =>
                                  prevCountry === item.country
                                    ? ""
                                    : item.country
                                );
                              }}
                              className={`appearance-none w-6 h-6 rounded-lg bg-white border-2 border-grey-100 cursor-pointer ${
                                countrySelected === item.country
                                  ? "bg-orange-50 border-none"
                                  : "bg-white border-2 border-grey-100"
                              }`}
                            />
                            {countrySelected === item.country && (
                              <img
                                onClick={() => {
                                  setCountrySelected((prevCountry) =>
                                    prevCountry === item.country
                                      ? ""
                                      : item.country
                                  );
                                }}
                                className="absolute cursor-pointer"
                                src={checkMark}
                                alt="checkMark"
                              />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex justify-center items-center">
                        <img
                          src={placeholder}
                          alt="placeholder"
                          className="h-50"
                        />
                      </div>
                    )}
                  </div>
                )}
              </OutsideClickRef>

              {loading && dropDownOpen && (
                <div className="absolute top-18 h-40 overflow-y-auto z-10 shadow-inner bg-white rounded-20 w-full">
                  <div className="flex justify-center items-center h-full w-full">
                    <CircularProgress className="text-black-100 w-5 h-5" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <Location data={data} countrySelected={countrySelected} />
            <Users data={data} />
            <Account data={data} />
            <Polls data={data} />
            {/* <CashRevenue /> */}
          </div>
        </div>
        <RightSideFields data={data} endDate={endDate} startDate={startDate} />
      </div>
    </div>
  );
};

export default StatisticsComponent;
