import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../../../assets/Icons/close.svg";
import searchIcon from "../../../../assets/Icons/Search.svg";
import Right from "../../../../assets/Icons/Right.svg";
import utility from "../../../../utility";
import { useDebouncedCallback } from "use-debounce";
import { UserService } from "../../../../services";
import { CircularProgress } from "@mui/material";
import placeholder from "../../../../assets/Icons/Placeholder.svg";

/**
 * ChannelPopup Component renders a popup for selecting channels with search functionality.
 * @component
 * @param {object} props - Component props
 * @param {Function} props.setOpenChannel - Function to set the open state of the channel
 * @param {object} props.openChannel - Object containing information about the open channel
 * @returns {JSX.Element} ChannelPopup component
 */

const ChannelPopup = ({ setOpenChannel, openChannel }) => {
  const [channelList, setChannelList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const [loading, setLoading] = useState(false);

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 500);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
  };

  const getChannelList = async () => {
    setLoading(true);

    try {
      let requestParams = {};
      if (debounceValue) {
        requestParams.searchKey = debounceValue;
      }
      const [error, response] = await utility.parseResponse(
        new UserService().getChannelList(requestParams)
      );

      if (error || !response) return;
      const filterArray = response.filter((item) => item?.channels?.length > 0);
      setChannelList(filterArray);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChannelList();
    // eslint-disable-next-line
  }, [debounceValue]);

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white  relative flex flex-col h-2/3 w-115 z-50 p-7.5 rounded-20 ">
        <div className="flex justify-between">
          <span className="text-black-100 font-ManropeBold text-ft4">
            Channels
          </span>
          <Close
            onClick={() =>
              setOpenChannel((prev) => ({
                ...prev,
                typeOpen: "",
              }))
            }
            className="cursor-pointer"
          />
        </div>

        <div className="flex w-full mt-5 h-11.25 p-5 items-center gap-3.75 flex-shrink-0 rounded-full bg-grey-50">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            className="font-ManropeLight text-ft3 w-95per bg-grey-50 text-black-300 focus:outline-none"
          />
          <img
            src={searchIcon}
            alt="searchIcon"
            className="opacity-60 w-5per h-5"
          />
        </div>
        {!loading ? (
          <div className="mt-10 flex flex-col gap-10 h-80per overflow-y-auto scrollbar-hide">
            {channelList?.length > 0 ? (
              channelList.map((item) => (
                <div
                  key={item?.groupId}
                  onClick={() => {
                    setOpenChannel((prev) => ({
                      ...prev,
                      typeOpen: "ChannelsDetails",
                      name: item.groupName,
                      data: item.channels,
                    }));
                  }}
                  className="flex justify-between items-center cursor-pointer"
                >
                  <div className="flex gap-3 items-center">
                    <img
                      src={item.icon}
                      alt=""
                      className="w-10.5 h-10.5 rounded-full"
                    />
                    <span className="font-ManropeBold text-black-100 text-ft3">
                      {item.groupName}
                    </span>
                  </div>
                  <div>
                    <img src={Right} alt="" />
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center  bg-white rounded-20">
                <img src={placeholder} alt="placeholder" />
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-center  items-center h-80per w-full">
            <CircularProgress className="text-black-100 w-5 h-5" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChannelPopup;
