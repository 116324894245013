/**
 * Create an image object from the provided URL.
 * @param {string} url - Image URL.
 * @returns {Promise<HTMLImageElement>} Promise that resolves to the loaded image.
 */

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

/**
 * Convert degrees to radians.
 * @param {number} degreeValue - Degree value to be converted.
 * @returns {number} Radian equivalent of the degree value.
 */

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Calculate the size of the bounding box after rotation.
 * @param {number} width - Original width.
 * @param {number} height - Original height.
 * @param {number} rotation - Rotation angle in degrees.
 * @returns {{ width: number, height: number }} Rotated width and height.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * Crop and rotate an image based on the provided parameters.
 * @param {string} imageSrc - Source URL of the image.
 * @param {{ x: number, y: number, width: number, height: number }} pixelCrop - Crop coordinates.
 * @param {number} [rotation=0] - Rotation angle in degrees.
 * @param {{ horizontal: boolean, vertical: boolean }} [flip={ horizontal: false, vertical: false }] - Flip settings.
 * @returns {Promise<string>} Promise that resolves to the base64 data of the cropped and rotated image.
 */

export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error("Failed to create blob."));
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    }, "image/jpeg");
  });
}
