import React, { useEffect, useState } from "react";
import chart from "../../assets/Icons/Chart.svg";
import graph from "../../assets/Icons/activeGraph.svg";
import ChartWithPie from "./components/pieChart";
import ChartWithBar from "./components/barChart";
import chartActive from "../../assets/Icons/chart-active.svg";
import pieInactive from "../../assets/Icons/pie-inactive.svg";
import { getRandomColor } from "../../utility";

/**
 * Account component displays a chart or graph based on the provided data.
 *
 * @component
 * @example
 * // Example usage of Account component
 * <Account
 *   data={{ unverifiedAccounts: { total: 10 }, BlueCheckVerifiedAccounts: { total: 5 }, businessAccounts: { total: 3 } }}
 *   token={false}
 *   heading="Account"
 * />
 *
 * @param {Object} data - Data object containing account information.
 * @param {boolean} [token=false] - Boolean indicating whether to display a token in the chart.
 * @param {string} [heading="Account"] - Heading or title for the Account component.
 *
 * @returns {JSX.Element} The rendered Account component.
 */

const Account = ({ data, token = false, heading = "Account" }) => {
  const generatedColor = getRandomColor();
  const [colors, setColors] = useState([]);
  const [chartType, setChartType] = useState(false);
  const pieChartData = [
    { name: "Unverified", value: data?.unverifiedAccounts?.total || 0 },
    {
      name: "Blue check verified",
      value: data?.BlueCheckVerifiedAccounts?.total || 0,
    },
    // { name: "Business", value: data?.businessAccounts?.total || 0 },
  ];

  const filteredPieChartData = pieChartData.filter((item) => item.value);

  useEffect(() => {
    const newColors = pieChartData?.map(() => generatedColor());
    setColors([...newColors]);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="flex flex-col p-5 gap-10 w-full h-155 rounded-20 bg-white">
      <div className="flex justify-between">
        <p className="text-black-100 font-ManropeBold text-ft5">{heading}</p>
        <div className="flex gap-2.5">
          <img
            src={!chartType ? chart : chartActive}
            alt="chart"
            onClick={() => setChartType(true)}
            className="cursor-pointer"
          />
          <img
            src={chartType ? pieInactive : graph}
            alt="graph"
            onClick={() => setChartType(false)}
            className="cursor-pointer"
          />
        </div>
      </div>
      {!chartType ? (
        <ChartWithPie
          colors={colors}
          data={filteredPieChartData}
          dataKey={"value"}
          token={token}
        />
      ) : (
        <ChartWithBar
          data={filteredPieChartData}
          colors={colors}
          YAxisKey={"name"}
          BarKey={"value"}
        />
      )}
    </div>
  );
};

export default Account;
