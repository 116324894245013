import { httpConstants } from "../constant/commonArray";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./userService";

const {
  METHOD_TYPE: { GET },
  API_END_POINT: { HASHTAG },
} = httpConstants;

export default class SocialInteractionService {
  baseUrl = process.env.REACT_APP_SOCIAL_INTRACTION_SERVICE;

  getHashTag = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${HASHTAG}?${query}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
}
