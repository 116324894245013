/**
 * @module CreateTokens
 * @description
 * Module for managing and rendering additional fields.
 *
 * @example
 * // Usage in a React functional component
 * import CreateTokens from "./CreateTokens";
 *
 * const MyComponent = () => {
 *   return <CreateTokens />;
 * };
 *
 * @returns {React.Component} The CreateTokens component
 */

import React, { useState, useEffect } from "react";
import left from "../../../assets/Icons/Left.svg";
import GeneralToken from "./generalToken";
import Content from "../../../common/content";
import AudienceReach from "../../../common/audienceReach";
import PreviewPopUp from "../../../common/previewPopUp";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import utility from "../../../utility";
import { FileUploadService, UserService } from "../../../services";
import ShowToast from "../../../common/showToast";
import { toastType } from "../../../constant/commonArray";
import UserEntityService from "../../../services/userEntityService";
import { useSelector } from "react-redux";
const CreateTokens = () => {
  const [steps, setSteps] = useState(0);
  const history = useHistory();
  const [previewPopUp, setPreviewPopUp] = useState(false);
  const [creating, setCreating] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploadProfile, setUploadProfile] = useState(null);
  const [startEditTime, setStartEditTime] = useState(false);
  const [endEditTime, setEndEditTime] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const user = useSelector((state) => state.user);
  const [byDefaultStartTime, setByDefaultStartTime] = useState(false);
  const [byDefaultEndTime, setByDefaultEndTime] = useState(false);
  const [file, setFile] = useState({});
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const duplicateId = queryParams.get("duplicate");
  const [dateStart, setDateStart] = useState(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    setByDefaultStartTime(true);
    return tomorrow;
  });

  const [untilDate, setUntilDate] = useState(() => {
    const today = new Date();
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 2);
    setByDefaultEndTime(true);
    return dayAfterTomorrow;
  });
  const [selectTime, setSelectTime] = useState(utility.getDefaultTime());
  const [untilSelectTime, setUntilSelectTime] = useState(
    utility.getDefaultTime()
  );
  const [state, setState] = useState({
    name: "",
    amount: "",
    startDate: Date.now(),
    endDate: Date.now(),
    language: "English",
    heading: "",
    buttonText: "",
    description: "",
    audienceReach: {},
  });

  /**
   * Asynchronously handles the creation of a promotion, including setting up the
   * necessary date and time values, uploading media files, and making a request to
   * the user service in order to create the promotion. Shows a success or error
   * message toast accordingly.
   *
   * @return {Promise<void>}
   */
  const handleCreatePromotion = async () => {
    setCreating(true);
    try {
      const combinedDateTimeStart = `${
        dateStart.toISOString().split("T")[0]
      }T${selectTime}`;

      let dateTimeStart = new Date(combinedDateTimeStart);
      if (startEditTime || byDefaultStartTime) {
        dateTimeStart.setDate(dateTimeStart.getDate());
      } else {
        dateTimeStart.setDate(dateTimeStart.getDate() + 1);
      }
      const epochTimeStart = await dateTimeStart.getTime();

      const combinedDateTimeEnd = `${
        untilDate.toISOString().split("T")[0]
      }T${untilSelectTime}`;

      let dateTimeEnd = new Date(combinedDateTimeEnd);
      if (endEditTime || byDefaultEndTime) {
        dateTimeEnd.setDate(dateTimeEnd.getDate());
      } else {
        dateTimeEnd.setDate(dateTimeEnd.getDate() + 1);
      }
      const epochTimeEnd = await dateTimeEnd.getTime();

      let updatedState = {};

      if (state?.media === undefined) {
        const formData = new FormData();
        formData.append("files", uploadProfile);

        const [err, res] = await utility.parseResponse(
          new FileUploadService().fileUpload(formData)
        );

        if (err || !res) return;
        const newDocuments = res?.responseData.map((fileInfo) => ({
          type: "image",
          url: fileInfo.cdnUrl,
        }));

        updatedState = {
          ...state,
          rewardFormat: "FREE_TOKENS",
          media: [...newDocuments],
          startDate: epochTimeStart || state?.startDate,
          endDate: epochTimeEnd || state?.endDate,
          creator: user?.staffId,
        };
      } else {
        updatedState = {
          ...state,
          rewardFormat: "FREE_TOKENS",
          startDate: epochTimeStart || state?.startDate,
          endDate: epochTimeEnd || state?.endDate,
          creator: user?.staffId,
        };
      }

      const [error, response] = await utility.parseResponse(
        new UserService().createPromotion(updatedState)
      );

      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        history.push("/promo-and-rewards");
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    } finally {
      setCreating(false);
    }
  };

  /**
   * Asynchronously retrieves and sets details from the response.
   *
   */
  const getDetails = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().promotionDetails(duplicateId)
      );
      if (error || !response) return;
      setState({
        language: utility.capitalizeFirstLetter(response?.[0]?.language),
        name: response?.[0]?.name,
        account: response?.[0]?.account?.accountId,
        amount: response?.[0]?.amount,
        heading: response?.[0]?.heading,
        description: response?.[0]?.description,
        audienceReach: response?.[0]?.audienceReach,
        buttonText: response?.[0]?.buttonText,
        media: response?.[0]?.media,
      });

      const epochStartDate = response?.[0]?.startDate;
      const startDate = new Date(epochStartDate);
      if (startDate.getHours() >= 12) {
        setStartEditTime(true);
      }
      const hoursStart = startDate.getHours();
      const minutesStart = startDate.getMinutes();
      const formattedTimeStart = `${String(hoursStart).padStart(
        2,
        "0"
      )}:${String(minutesStart).padStart(2, "0")}`;
      setDateStart(startDate);

      setSelectTime(formattedTimeStart);
      const epochEndDate = response?.[0]?.endDate;
      const endDate = new Date(epochEndDate);
      if (endDate.getHours() >= 12) {
        setEndEditTime(true);
      }
      const hoursEnd = endDate.getHours();
      const minutesEnd = endDate.getMinutes();
      const formattedTimeEnd = `${String(hoursEnd).padStart(2, "0")}:${String(
        minutesEnd
      ).padStart(2, "0")}`;
      setUntilDate(endDate);
      setUntilSelectTime(formattedTimeEnd);
      setCroppedImage(response?.[0]?.media[0]?.url);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Asynchronous function to get user count and update the user count state.
   */
  const getUserCount = async () => {
    if (
      (!state?.audienceReach ||
        Object.keys(state.audienceReach).length === 0) &&
      duplicateId
    ) {
      return;
    }
    try {
      let requestData = {
        raceOrEthnicity:
          state?.audienceReach?.race?.length > 0
            ? state?.audienceReach?.race
            : undefined,
        audience:
          state?.audienceReach?.audience?.length > 0
            ? state?.audienceReach?.audience
            : undefined,
        gender:
          state?.audienceReach?.gender?.length > 0
            ? state?.audienceReach?.gender
            : undefined,
        verifiedUsers:
          state?.audienceReach?.verifiedUsers?.length > 0
            ? state?.audienceReach?.verifiedUsers
            : undefined,
        location:
          state?.audienceReach?.location?.length > 0
            ? state?.audienceReach?.location
            : undefined,
        age:
          state?.audienceReach?.age?.length > 0
            ? state?.audienceReach?.age?.map((range) => {
                const [min, max] = range.split(/-|and/);
                if (max === " older") return { min: parseInt(min), max: 400 };
                return { min: parseInt(min), max: parseInt(max) };
              })
            : undefined,
      };

      const [error, response] = await utility.parseResponse(
        new UserEntityService().getUserCount(requestData)
      );

      if (error || !response) return;
      setUserCount(response?.totalRespondents);
    } catch (e) {
      console.error(e);
    }
  };

  function renderStepContent() {
    switch (steps) {
      case 0:
        return (
          <GeneralToken
            setSteps={setSteps}
            setState={setState}
            state={state}
            setUntilSelectTime={setUntilSelectTime}
            untilSelectTime={untilSelectTime}
            setSelectTime={setSelectTime}
            selectTime={selectTime}
            setUntilDate={setUntilDate}
            untilDate={untilDate}
            setDateStart={setDateStart}
            dateStart={dateStart}
            setEndEditTime={setEndEditTime}
            setStartEditTime={setStartEditTime}
            setByDefaultStartTime={setByDefaultStartTime}
            setByDefaultEndTime={setByDefaultEndTime}
          />
        );
      case 1:
        return (
          <Content
            setSteps={setSteps}
            setState={setState}
            state={state}
            croppedImage={croppedImage}
            file={file}
            setFile={setFile}
            setCroppedImage={setCroppedImage}
            setUploadProfile={setUploadProfile}
            keyName="promotion"
          />
        );
      default:
        return (
          <AudienceReach
            setSteps={setSteps}
            setState={setState}
            state={state}
            handleCreateAds={handleCreatePromotion}
            creating={creating}
          />
        );
    }
  }

  useEffect(() => {
    getUserCount();
    // eslint-disable-next-line
  }, [state.audienceReach]);

  useEffect(() => {
    if (duplicateId) {
      getDetails();
      setByDefaultEndTime(false);
      setByDefaultStartTime(false);
    }

    // eslint-disable-next-line
  }, [duplicateId]);

  return (
    <div className="py-5 w-full h-full  overflow-y-auto pr-12.5">
      <div className="flex flex-col gap-5">
        <div className="w-full h-21.25 px-5 flex items-center rounded-20 bg-white">
          <div className="flex items-center gap-7.5">
            <Link to="/promo-and-rewards">
              <img src={left} alt="left" />
            </Link>
            <p className="text-ft5 text-black-50 font-ManropeBold ">
              Create new promotion 'Free tokens'
            </p>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="flex flex-col w-75per rounded-20 bg-white py-5 gap-7.5">
            <div className="w-full bg-white px-10">
              <div className="flex gap-10">
                <span
                  className={`${
                    steps === 0
                      ? "text-ft5 font-ManropeBold text-black-100"
                      : "text-ft3 font-ManropeMedium text-black-75"
                  } cursor-pointer `}
                >
                  General
                </span>
                <span
                  className={`${
                    steps === 1
                      ? "text-ft5 font-ManropeBold text-black-100"
                      : "text-ft3 font-ManropeMedium text-black-75"
                  } cursor-pointer `}
                >
                  Content
                </span>
                <span
                  className={`${
                    steps === 2
                      ? "text-ft5 font-ManropeBold text-black-100"
                      : "text-ft3 font-ManropeMedium text-black-75"
                  } cursor-pointer `}
                >
                  {" "}
                  Audience reach
                </span>
              </div>
            </div>
            {renderStepContent()}
          </div>
          <div className="flex flex-col w-25per gap-10">
            <div className="flex flex-col rounded-20 bg-purple-100 pt-5 pb-3.75 gap-2.5 px-5">
              <p className="text-black-100 font-ManropeBold text-ft2">
                Potential number of respondents:
              </p>
              <p className="text-black-100 font-ManropeBold text-ft7 py-2.5">
                {utility.formatNumber(userCount) || 0}
              </p>
            </div>
            <p
              onClick={() => {
                croppedImage &&
                  state?.heading &&
                  setPreviewPopUp(!previewPopUp);
              }}
              className={`flex justify-center items-center text-black-100 font-ManropeBold text-ft2 ${
                croppedImage && state?.heading
                  ? "cursor-pointer"
                  : "cursor-default"
              } `}
            >
              See preview
            </p>
          </div>
        </div>
      </div>
      {previewPopUp && (
        <PreviewPopUp
          previewPopUp={previewPopUp}
          setPreviewPopUp={setPreviewPopUp}
          state={state}
          croppedImage={croppedImage}
          account={""}
        />
      )}
    </div>
  );
};

export default CreateTokens;
