import React, { useState } from "react";
import close from "../../assets/Icons/close.svg";
import { CircularProgress } from "@mui/material";
import { UserService } from "../../services";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";

const activityRegex = new RegExp(/^\d*\.?\d{0,1}$/);

/**
 * EditActivity component allows users to edit the activity points.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setEditActivity - Function to set the state of edit activity popup
 * @param {Object} props.editActivity - Data of the activity to be edited
 * @param {Function} props.getActivityData - Function to refresh the activity data
 * @returns {JSX.Element}
 */
const EditActivity = ({ setEditActivity, editActivity, getActivityData }) => {
  const [state, setState] = useState({
    min: editActivity?.data?.min || 0,
    max: editActivity?.data?.max || 0,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  /**
   * Handles the edit activity action.
   *
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const handleEditActivity = async () => {
    let response;
    setLoading(true);
    try {
      response = await new UserService().editActivity(
        state,
        editActivity?.data?._id
      );
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        getActivityData();
        setEditActivity((prev) => ({ ...prev, popup: false }));
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="flex flex-col bg-white rounded-20 shadow-md w-112.5 p-7.5">
        <div className="flex w-full justify-between items-center mb-5">
          <span className="font-ManropeBold text-ft4 text-black-100">
            Update Activity Points
          </span>
          <img
            onClick={() =>
              setEditActivity((prev) => ({ ...prev, popup: false }))
            }
            src={close}
            alt="close"
            className="cursor-pointer"
          />
        </div>
        <div className="pb-7.5">
          <span className="font-ManropeBold text-ft3 text-black-100">
            {editActivity?.data?.activityName}
          </span>
        </div>
        <div className="flex flex-col gap-7.5 overflow-y-auto scrollbar-hide max-h-43per">
          <div className="flex flex-col">
            <label className="font-ManropeMedium text-ft3 text-black-100">
              Minimum Points
            </label>
            <div className="h-16.25 px-6.25 rounded-full flex justify-between items-center bg-grey-50 focus:outline-none">
              <input
                type="text"
                value={state?.min}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (activityRegex.test(inputValue)) {
                    const numericValue = inputValue;
                    if (numericValue >= 0.1 && numericValue <= 99) {
                      setState((prev) => ({
                        ...prev,
                        min: inputValue === "" ? "" : numericValue,
                      }));
                      setError((prev) => ({ ...prev, min: false }));
                    } else {
                      setError((prev) => ({ ...prev, min: true }));
                      setState((prev) => ({
                        ...prev,
                        min: inputValue === "" ? "" : inputValue,
                      }));
                    }
                  }
                }}
                className="focus:outline-none w-95per bg-transparent"
              />
              <img
                src={close}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    min: 0,
                  }))
                }
                alt="close"
                className="cursor-pointer w-5per"
              />
            </div>
            {error?.min && (
              <div className="text-red-50 text-ft2 font-ManropeLight mt-1">
                Input value must be between 0.1 and 99
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label className="font-ManropeMedium text-ft3 text-black-100">
              Maximum Points
            </label>
            <div className="h-16.25 px-6.25 rounded-full flex justify-between items-center bg-grey-50 focus:outline-none">
              <input
                type="text"
                value={state?.max}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (activityRegex.test(inputValue)) {
                    const numericValue = inputValue;
                    if (numericValue >= 0.1 && numericValue <= 99) {
                      setState((prev) => ({
                        ...prev,
                        max: inputValue === "" ? "" : numericValue,
                      }));
                      setError((prev) => ({ ...prev, max: false }));
                    } else {
                      setError((prev) => ({ ...prev, max: true }));
                      setState((prev) => ({
                        ...prev,
                        max: inputValue === "" ? "" : inputValue,
                      }));
                    }
                  }
                }}
                className="focus:outline-none w-95per bg-transparent"
              />
              <img
                src={close}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    max: 0,
                  }))
                }
                alt="close"
                className="cursor-pointer w-5per"
              />
            </div>
            {error?.max && (
              <div className="text-red-50 text-ft2 font-ManropeLight mt-1">
                Input value must be between 0.1 and 99
              </div>
            )}
            {parseFloat(state?.max) < parseFloat(state?.min) &&
              !error?.max &&
              !error?.min && (
                <div className="text-red-50 text-ft2 font-ManropeLight mt-1">
                  Max value should greater than equal to min value.
                </div>
              )}
          </div>
        </div>
        <button
          onClick={handleEditActivity}
          disabled={
            parseFloat(state?.max) < parseFloat(state?.min) ||
            Object.values(error).some((value) => value !== false) ||
            loading ||
            (state?.min === editActivity?.data?.min &&
              state?.max === editActivity?.data?.max)
          }
          className={`h-16.25 px-6.25 rounded-full ${
            loading ||
            parseFloat(state?.max) < parseFloat(state?.min) ||
            Object.values(error).some((value) => value !== false) ||
            (state?.min === editActivity?.data?.min &&
              state?.max === editActivity?.data?.max)
              ? "bg-grey-50"
              : "bg-orange-50"
          }  mt-7.5 text-black-100 font-ManropeBold text-ft2`}
        >
          {loading ? (
            <div className="flex justify-center  items-center h-full w-full">
              <CircularProgress className="text-orange-50" />
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditActivity;
