import React, { useEffect, useState } from "react";
import DefaultProfile from "../../../assets/Icons/default-profile.svg";
import Edit from "../../../assets/Icons/edit-white.svg";
import AddImage from "../../../assets/Icons/add-image.svg";
import DeleteImage from "../../../assets/Icons/delete-image.svg";
import SelectImagePopup from "./selectImagePopup";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { CircularProgress } from "@mui/material";
import UserEntityService from "../../../services/userEntityService";
import { useDispatch, useSelector } from "react-redux";
import { eventConstants, toastType } from "../../../constant/commonArray";
import utility from "../../../utility";
import ShowToast from "../../../common/showToast";
import WarningPopup from "../../../common/warningPopup";
import { useDebouncedCallback } from "use-debounce";

/**
 * GeneralInfo component for handling general entity information.
 * @component
 * @param {Object} props - Component props.
 * @param {function} props.setSteps - Function to set the current step.
 * @param {Object} props.state - Current state of the component.
 * @param {function} props.setState - Function to update the component state.
 * @param {function} props.setUploadProfile - Function to update the upload profile state.
 * @param {File} props.file - Selected file for profile photo.
 * @param {function} props.setFile - Function to update the selected file.
 * @param {string} props.croppedImage - Cropped image URL.
 * @param {function} props.setCroppedImage - Function to update the cropped image URL.
 * @param {Object} props.setError - Object containing error states.
 * @param {Object} props.error - Error state.
 * @returns {JSX.Element} React component.
 */

const GeneralInfo = ({
  setSteps,
  state,
  setState,
  setUploadProfile,
  file,
  setFile,
  croppedImage,
  setCroppedImage,
  setError,
  error,
}) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const [replaceImage, setReplaceImage] = useState(null);
  const [isUserNameAvailable, setIsUserNameAvailable] = useState(true);
  const [debounceValue, setDebounceValue] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.user);

  /**
   * Updates the location name in the state based on the given address.
   *
   * @param {string} address - the new address to set
   * @return {void}
   */
  const handleChange = (address) => {
    setState((prev) => ({
      ...prev,
      location: {
        ...prev.location,
        name: address,
      },
    }));
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 300);

  /**
   * Handles the selection of an address, updates the state with the city name,
   * country name, and geocoding location, and catches any errors that occur.
   *
   * @param {string} address - The address to be handled
   * @return {void}
   */
  const handleSelect = (address) => {
    const parts = address.split(",").map((part) => part.trim());
    setState((prev) => ({
      ...prev,
      cityName: parts[0],
      countryName: parts[parts.length - 1],
    }));

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setState((prev) => ({
          ...prev,
          location: {
            ...prev.location,
            name: address,
            latitude: latLng.lat,
            longitude: latLng.lng,
          },
        }));
      })
      .catch((error) => console.error("Error", error));
  };

  /**
   * Asynchronously checks the availability of a username and updates the state
   * accordingly. Shows a loader while the request is being made and hides it
   * after the request is completed, regardless of the outcome. Displays a toast
   * message if an error occurs during the process.
   *
   * @param {string} username - The username to be checked for availability
   * @return {void}
   */
  const checkUserName = async (username) => {
    try {
      const requestData = `@${username}`;
      dispatch({ type: eventConstants.SHOW_LOADER });
      let [error, userNameRes] = await utility.parseResponse(
        new UserEntityService().checkUserNameExists(requestData)
      );
      if (error || !userNameRes) {
        setIsUserNameAvailable(false);
      } else {
        if (userNameRes?.available) {
          setIsUserNameAvailable(true);
        } else {
          setIsUserNameAvailable(false);
        }
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };
  /**
   * An asynchronous function to handle changes in the username input field.
   *
   * @param {Object} e - The event object representing the input change
   * @return {void}
   */
  const handleUserNameChange = async (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 30) {
      setError((prev) => ({ ...prev, userNameError: false }));
    } else {
      setError((prev) => ({ ...prev, userNameError: true }));
    }
    setState((prev) => ({ ...prev, userName: inputValue }));

    debouncedSearch(inputValue);
  };

  const handleDiscardChanges = () => {
    setShowWarning(false);
    history.push("/entity-account");
  };

  useEffect(() => {
    if (!!debounceValue) checkUserName(debounceValue);
    // eslint-disable-next-line
  }, [debounceValue]);

  return (
    <div className="mt-15 flex flex-col">
      <div>
        {!state?.profilePhoto && !croppedImage ? (
          <div
            onClick={() => setImagePopup(true)}
            onMouseEnter={() => setEdit(true)}
            onMouseLeave={() => setEdit(false)}
            className="bg-black-100 rounded-full h-42.5 w-42.5 relative flex justify-center items-center"
          >
            <img src={DefaultProfile} alt="" className="absolute" />
            {edit && (
              <img src={Edit} alt="" className="absolute z-10 cursor-pointer" />
            )}
          </div>
        ) : (
          <div
            onMouseEnter={() => setReplaceImage(true)}
            onMouseLeave={() => setReplaceImage(false)}
            className=" rounded-full h-42.5 w-42.5 relative flex justify-center items-center"
          >
            <img
              src={state?.profilePhoto || croppedImage}
              alt=""
              className="absolute w-full h-full rounded-full"
            />
            <div className="absolute z-10 flex gap-7.5">
              {replaceImage && (
                <img
                  src={AddImage}
                  alt=""
                  className="w-12.5 cursor-pointer"
                  onClick={() => {
                    setCroppedImage(null);
                    setFile(null);
                    setImagePopup(true);
                  }}
                />
              )}
              {replaceImage && (
                <img
                  src={DeleteImage}
                  alt=""
                  className="w-12.5 cursor-pointer"
                  onClick={() => {
                    setCroppedImage(null);
                    setFile(null);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-15 mt-15">
        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Company name
          </label>
          <input
            type="text"
            value={state?.fullName}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 60) {
                setError((prev) => ({ ...prev, fullNameError: false }));
              } else {
                setError((prev) => ({ ...prev, fullNameError: true }));
              }
              setState((prev) => ({ ...prev, fullName: inputValue }));
            }}
            className="w-1/2 rounded-full bg-grey-50 outline-none px-6 h-16.25 text-black-100 font-ManropeRegular text-ft3"
          />
          {error?.fullNameError && (
            <span className=" text-red-50">
              Company name can't be more than 60
            </span>
          )}
        </div>

        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Username
          </label>
          <div className="w-1/2 rounded-full bg-grey-50  px-6 h-16.25 flex items-center">
            <span className="bg-none text-black-100 opacity-60 text-ft3 font-ManropeMedium">
              @
            </span>
            <input
              type="text"
              value={state?.userName}
              onChange={handleUserNameChange}
              className="pl-1 w-full outline-none h-full bg-transparent text-black-100 font-ManropeRegular text-ft3"
            />
          </div>
          {!isUserNameAvailable && (
            <span className=" text-red-50">Username is already taken</span>
          )}
          {error?.userNameError && (
            <span className=" text-red-50">Username can't be more than 30</span>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Description
          </label>
          <input
            type="text"
            value={state?.bio}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 150) {
                setError((prev) => ({ ...prev, bioError: false }));
              } else {
                setError((prev) => ({ ...prev, bioError: true }));
              }
              setState((prev) => ({ ...prev, bio: e.target.value }));
            }}
            className="w-1/2 rounded-full bg-grey-50 outline-none px-6 h-16.25 text-black-100 font-ManropeRegular text-ft3"
          />
          {error?.bioError && (
            <span className=" text-red-50">
              Description can't be more than 150
            </span>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Location
          </label>
          {user.gmapsLoaded && (
            <PlacesAutocomplete
              value={state?.location?.name}
              onChange={handleChange}
              onSelect={handleSelect}
              searchOptions={{ types: ["locality"] }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="relative">
                  <input
                    {...getInputProps({
                      placeholder: "Start type",
                      className:
                        "w-1/2 rounded-full bg-grey-50 outline-none px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3",
                    })}
                  />
                  <div className="max-h-56 overflow-y-auto shadow-xl bg-white w-1/2 mt-2 left-0 rounded-20 absolute">
                    {loading && (
                      <div className="flex justify-center  items-center h-56 w-full">
                        <CircularProgress className="text-black-100 w-5 h-5" />
                      </div>
                    )}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? "suggestion-item--active p-5"
                        : "suggestion-item p-5";
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={`${suggestion.placeId}-${index}`}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          )}
        </div>
        {/* <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Amount, Dollars (Optional)
          </label>
          <input
            type="text"
            value={state?.amount}
            onChange={(e) =>
              setState((prev) => ({ ...prev, amount: e.target.value }))
            }
            className="w-1/2 rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
          />
        </div> */}
      </div>
      <div className="flex justify-end mt-15 gap-7.5">
        <button
          onClick={() => setShowWarning(true)}
          className="border border-black-100 border-opacity-20 rounded-full w-55 h-16.25"
        >
          Cancel
        </button>

        <button
          disabled={
            (!croppedImage && !state?.profilePhoto) ||
            !state?.bio ||
            !state?.userName ||
            !state?.location.name ||
            error?.bioError ||
            error?.userNameError ||
            error?.fullNameError ||
            !state?.fullName ||
            state?.fullName?.trim() === "" ||
            state?.bio?.trim() === "" ||
            !isUserNameAvailable
          }
          onClick={() => {
            setSteps(1);
          }}
          className={`${
            (croppedImage || state?.profilePhoto) &&
            state?.bio &&
            state?.userName &&
            state?.location.name &&
            !error?.bioError &&
            !error?.userNameError &&
            !error?.fullNameError &&
            state?.fullName?.trim() !== "" &&
            state?.bio?.trim() !== "" &&
            state?.fullName &&
            isUserNameAvailable
              ? "bg-orange-50"
              : "bg-grey-50"
          } rounded-full w-55 h-16.25`}
        >
          Save
        </button>
      </div>
      {imagePopup && (
        <SelectImagePopup
          setImagePopup={setImagePopup}
          setCroppedImage={setCroppedImage}
          croppedImage={croppedImage}
          setState={setState}
          setFile={setFile}
          file={file}
          setUploadProfile={setUploadProfile}
        />
      )}
      {showWarning && (
        <WarningPopup
          title="Discard changes ?"
          description="All entered data will be deleted"
          setToggle={setShowWarning}
          handleActivateUser={handleDiscardChanges}
        />
      )}
    </div>
  );
};

export default GeneralInfo;
