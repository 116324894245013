import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import left from "../../../assets/Icons/Left.svg";
import ZoomIn from "../../../assets/Icons/zoom-in.svg";
import ZoomOut from "../../../assets/Icons/zoom-out.svg";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Cropper from "react-easy-crop";
import ShowToast from "../../../common/showToast";
import { failureMessage, toastType } from "../../../constant/commonArray";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";
import getCroppedImg from "./crop";
import { CircularProgress } from "@mui/material";

/**
 * SelectImagePopup component for handling image selection and cropping.
 * @component
 * @param {Object} props - Component props.
 * @param {function} props.setImagePopup - Function to control the visibility of the image popup.
 * @param {function} props.setCroppedImage - Function to set the cropped image.
 * @param {function} props.setFile - Function to set the selected file.
 * @param {File} props.file - Selected file for image upload.
 * @param {function} props.setUploadProfile - Function to update the upload profile state.
 * @param {string} [props.edit="edit"] - Edit mode ("edit" or "add").
 * @returns {JSX.Element} React component.
 */

const SelectImagePopup = ({
  setImagePopup = () => {},
  setCroppedImage,
  setFile,
  file,
  setUploadProfile = () => {},
  edit = "edit",
  keyName = "default",
  handleSubmission = () => {},
  loading = false,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  /**
   * Callback function for when cropping is complete.
   * @param {Object} croppedArea - The cropped area coordinates.
   * @param {Object} croppedAreaPixels - The cropped area in pixels.
   */

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(file.preview, croppedAreaPixels);
      setCroppedImage(croppedImage);
      const fileName = JSON.stringify(new Date().getTime()) + ".png";
      const image = await DataURLtoFile(croppedImage, fileName);
      setUploadProfile(image);
      handleSubmission(image);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [croppedAreaPixels, File]);

  /**
   * Convert Data URL to File.
   * @param {string} dataUrl - The Data URL to convert.
   * @param {string} fileName - The name of the file.
   * @returns {Promise<File>} A promise with the converted File object.
   */

  const DataURLtoFile = async (dataUrl, fileName) => {
    var arr = dataUrl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 5 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED,
          type: toastType.ERROR,
        });
      } else {
        const imgPreview = URL.createObjectURL(acceptedFiles[0]);
        setFile({ ...acceptedFiles, preview: imgPreview });
      }
    },
  });

  const onZoomChange = useCallback((newZoom) => {
    setZoom(newZoom);
  }, []);

  const onZoomIn = () => {
    const newZoom = Math.min(zoom + 0.1, 3);
    setZoom(newZoom);
  };

  const onZoomOut = () => {
    const newZoom = Math.max(zoom - 0.1, 1);
    setZoom(newZoom);
  };

  const getButtonText = () => {
    if (edit !== "add") {
      return loading ? <CircularProgress className="text-orange-50" /> : "Done";
    } else {
      return "Save";
    }
  };

  return (
    <div className="bg-grey-100 bg-opacity-70  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white overflow-auto flex flex-col h-2/3 w-115 z-50 p-7.5 rounded-20">
        <div className="w-full flex justify-between items-center">
          <div className="flex gap-5">
            <img
              onClick={() => {
                setImagePopup(false);
                setFile([]);
                setCroppedImage(null);
              }}
              src={left}
              alt=""
              className={`${edit !== "add" ? "cursor-pointer" : "hidden"}`}
            />
            <span className="text-ft4 font-ManropeBold text-black-100">
              {edit !== "add" ? "Edit photo" : "Add media"}
            </span>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setImagePopup(false);
              setFile([]);
              setCroppedImage(null);
            }}
          >
            <Close />
          </div>
        </div>

        {!file?.preview ? (
          <div
            {...getRootProps()}
            className={`border-dashed flex flex-col justify-center items-center border-grey-100 ${
              edit === "add" ? "h-full rounded-20 border-2" : "h-3/4 border"
            }  mt-5 p-4 text-center`}
          >
            <input
              {...getInputProps()}
              type="file"
              accept=".png, .jpg, .jpeg"
              className="hidden"
            />
            {edit !== "add" && (
              <span className="text-ft4 font-ManropeBold text-black-100">
                Drag and drop file to upload image
              </span>
            )}
            <span className="text-ft3 font-ManropeRegular text-black-75 mt-1">
              JPG, PNG no more than 5MB. Recommended aspect ratio 1:1
            </span>
            <span className="w-137px h-11.25 bg-orange-50 mt-7.5 rounded-full text-ft2 font-ManropeBold text-black-100 flex justify-center items-center cursor-pointer">
              Choose file
            </span>
          </div>
        ) : (
          <div className="h-3/4  mt-5 p-4 text-center">
            <div className="h-80per">
              <div className="bg-grey-500 p-5 w-full h-full relative">
                <Cropper
                  image={file.preview}
                  crop={crop}
                  zoom={zoom}
                  zoomSpeed={4}
                  maxZoom={3}
                  zoomWithScroll={true}
                  showGrid={false}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  className="z-10 w-full h-full"
                  cropShape="round"
                />
              </div>
            </div>
            <div className="h-auto mt-10 flex gap-3 items-center">
              <img
                src={ZoomOut}
                alt=""
                onClick={onZoomOut}
                className="cursor-pointer"
              />

              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="zoom"
                onChange={onZoomChange}
                className="range"
              />

              <img
                src={ZoomIn}
                alt=""
                onClick={onZoomIn}
                className="cursor-pointer"
              />
            </div>
          </div>
        )}

        {((edit === "add" && file?.preview) || edit === "edit") && (
          <div className="mt-5">
            <button
              disabled={loading}
              onClick={() => {
                showCroppedImage();
                if (keyName !== "profile") {
                  setImagePopup(false);
                }
              }}
              className={`w-full rounded-full ${
                file?.preview && !loading
                  ? "bg-orange-50 text-black-100"
                  : "bg-grey-50 text-black-75"
              } text-ft3 font-ManropeBold  h-16.25`}
            >
              {getButtonText()}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectImagePopup;
