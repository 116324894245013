import React, { useRef, useState } from "react";
import TableFooter from "./tableFooter";
import defaultImage from "../assets/Icons/signupProfileSmall.png";
import { CircularProgress } from "@mui/material";
import placeholder from "../assets/Icons/Placeholder.svg";
import OutsideClickRef from "./outsideClickRef";
import utility from "../utility";
import more from "../assets/Icons/More.svg";
import { useSelector } from "react-redux";
import error from "../assets/Icons/Error.svg";

/**
 * PollComment component renders a list of comments for a poll, including pagination and user permissions for managing comments.
 * @component
 * @param {Object} props - The properties object.
 * @param {boolean} props.loading - Indicates if the comments are currently loading.
 * @param {Array} props.comment - An array of comment objects to be displayed.
 * @param {Function} props.setIsCommentPopUp - Function to show or hide the comment pop-up.
 * @param {string} props.permission - The required permission to show comment options.
 * @param {Function} props.setIsCommentId - Function to set the current comment ID.
 * @param {Function} props.setPageSize - Function to set the number of comments per page.
 * @param {number} props.pageSize - The current number of comments per page.
 * @param {number} props.currentPage - The current page number.
 * @param {Function} props.handlePageChange - Function to handle changing the page.
 * @param {number} props.totalPages - The total number of pages.
 * @param {number} props.totalDocs - The total number of comments.
 * @param {Function} props.setCurrentPage - Function to set the current page number.
 * @returns {JSX.Element} The PollComment component.
 */
const PollComment = ({
  loading,
  comment,
  setIsCommentPopUp,
  permission,
  setIsCommentId,
  setPageSize,
  pageSize,
  currentPage,
  handlePageChange,
  totalPages,
  totalDocs,
  setCurrentPage,
}) => {
  const optionRef = useRef();
  const [selectedUser, setSelectedUser] = useState(null);

  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  const showCommentOption = (value) => {
    if (selectedUser === value) {
      setSelectedUser(null);
    } else {
      setSelectedUser(value);
    }
  };
  return (
    <div className="flex w-full flex-col mt-5  row-start-2 row-span-1 rounded-20 bg-white h-auto ">
      {!loading ? (
        <div className="flex w-full flex-col mt-5  row-start-2 row-span-1 rounded-20 bg-white h-auto">
          {comment?.length > 0 ? (
            <div>
              <div className="max-h-77vh overflow-y-auto scrollbar-hide">
                {comment.map((value, idx) => (
                  <div
                    key={idx}
                    className={`flex justify-between items-start mt-5 mx-2.5 pl-5 pr-2.5 py-3.75 relative `}
                  >
                    <div className="flex gap-3.75">
                      <img
                        src={value?.commentor?.profilePhoto || defaultImage}
                        alt="userProfile"
                        className="w-11.25 h-11.25 rounded-full"
                      />
                      <div className="flex flex-col gap-2.5">
                        <p className="font-ManropeExtraBold text-ft3 text-black-100">
                          {value?.commentor?.fullName}
                        </p>
                        <p className="font-ManropeRegular text-ft3 text-black-100">
                          {value.description}
                        </p>
                        <p className="font-ManropeRegular text-ft3 text-black-75">
                          {utility.getTimeDifference(value.addedOn)}
                        </p>
                      </div>
                    </div>
                    {userPermissions?.indexOf(permission) > -1 && (
                      <img
                        src={more}
                        alt="more"
                        className="flex cursor-pointer"
                        onClick={() => showCommentOption(value._id)}
                      />
                    )}
                    {selectedUser === value?._id && (
                      <div className="absolute right-0 top-21.25 flex flex-col items-start w-75 rounded-20 shadow-inner z-10 bg-white">
                        <OutsideClickRef
                          state={optionRef}
                          setState={() => showCommentOption("")}
                        >
                          <button
                            className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
                            onClick={() => {
                              setIsCommentPopUp != null &&
                                setIsCommentPopUp(true);
                              setIsCommentId != null &&
                                setIsCommentId(value?._id);
                            }}
                          >
                            <img src={error} alt="error" />
                            <p className="text-ft3 font-ManropeLight text-black-100">
                              Disable Comments
                            </p>
                          </button>
                        </OutsideClickRef>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="px-4">
                <TableFooter
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalPages={totalPages}
                  totalDocs={totalDocs}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
              <img src={placeholder} alt="placeholder" />
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center h-100">
          <CircularProgress className="text-black-100 w-5 h-5" />
        </div>
      )}
    </div>
  );
};

export default PollComment;
