import React, { useState } from "react";
import close from "../../assets/Icons/close.svg";
import { useDispatch } from "react-redux";
import {
  cookiesConstants,
  eventConstants,
  failureMessage,
  successMessage,
  toastType,
} from "../../constant/commonArray";
import utility from "../../utility";
import UserEntityService from "../../services/userEntityService";
import ShowToast from "../../common/showToast";
import { sessionManager } from "../../managers/sessionManager";
import UserService from "../../services/userService";

/**
 * React component representing a verification popup for email.
 *
 * @component
 * @example
 * const handleLoginSeen = (isVisible) => {
 *   // handle login popup visibility
 * };
 *
 * <PopUp setLoginSeen={handleLoginSeen} />
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.setLoginSeen - Function to control the visibility of the login popup.
 * @returns {JSX.Element} JSX for the verification popup component.
 */

const PopUp = (props) => {
  const dispatch = useDispatch();
  const [inputFields, setInputFields] = useState(["", "", "", "", "", ""]);
  const inputRefs = inputFields.map(() => React.createRef());
  const email = sessionManager.getDataFromCookies(
    cookiesConstants.VALIDATE_EMAIL
  );
  const [otpTypeNumber, setOptNumber] = useState(
    sessionManager.getDataFromCookies(cookiesConstants.OTP_TYPE_NUMBER) || 0
  );

  /**
   * Handles the click event on the close icon.
   * @function
   * @name handleClick
   * @returns {void}
   */

  const handleClick = () => {
    props.setLoginSeen(false);
  };

  /**
   * Handles the change event in the input fields.
   * @function
   * @name handleInputChange
   * @param {number} index - Index of the input field.
   * @param {string} value - Value entered in the input field.
   * @returns {void}
   */

  const handleInputChange = (index, value) => {
    // Check if the input value is numeric
    const isNumeric = /^[0-9]*$/;

    // If the input value is numeric, update the state
    if (isNumeric.test(value) || value === "") {
      const newInputFields = [...inputFields];
      newInputFields[index] = value;
      setInputFields(newInputFields);
      if (value === "" && index > 0) {
        inputRefs[index - 1].current.focus();
      }
      // If the current input field is not the last one and a value is entered, focus on the next input field
      else if (index < inputFields.length - 1 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  /**
   * Checks if all input fields are filled.
   * @function
   * @name isAllFieldsFilled
   * @returns {boolean} - True if all input fields are filled, otherwise false.
   */

  const isAllFieldsFilled = () => {
    return inputFields.every((field) => field.trim() !== "");
  };

  /**
   * Verifies the entered OTP and triggers the password reset.
   * @function
   * @name verifyOtp
   * @returns {void}
   */

  // FUNCTION TO VERIFY OTP
  const verifyOtp = async () => {
    const OTP = inputFields.join("");

    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      const [error, verifyOtpRes] = await utility.parseResponse(
        new UserEntityService().verificationOtp({
          email: sessionManager.getDataFromCookies(
            cookiesConstants.VALIDATE_EMAIL
          ),
          otp: OTP,
        })
      );
      if (error || !verifyOtpRes) {
        if (otpTypeNumber < 9) {
          ShowToast({
            message:
              error === failureMessage.INVALID_OTP
                ? failureMessage.INCORRECT_OTP
                : failureMessage.INVALID_OTP,
            type: toastType.ERROR,
          });
        }
        setInputFields(["", "", "", "", "", ""]);
        sessionManager.setDataInCookies(
          otpTypeNumber + 1,
          cookiesConstants.OTP_TYPE_NUMBER,
          1
        );
        setOptNumber(otpTypeNumber + 1);
        if (otpTypeNumber >= 9) {
          ShowToast({
            message: "Please try again after 15 minutes",
            type: toastType.ERROR,
          });
          const fifteenMinutesInDays = 15 / (24 * 60);
          sessionManager.setDataInCookies(
            true,
            cookiesConstants.DISABLE_OTP,
            fifteenMinutesInDays
          );
          const existingEmails =
            sessionManager.getDataFromCookies(cookiesConstants.TRIED_EMAILS) ||
            [];

          const newEmail = sessionManager.getDataFromCookies(
            cookiesConstants.VALIDATE_EMAIL
          );

          if (!existingEmails.includes(newEmail)) {
            existingEmails.push(newEmail);
          }
          sessionManager.setDataInCookies(
            existingEmails,
            cookiesConstants.TRIED_EMAILS,
            1
          );

          props.setLoginSeen(false);
          props.setDisableOtp(true);
        }
      } else {
        ShowToast({
          message: successMessage.OTP_SUCCESSFULLY_VERIFIED,
          type: toastType.SUCCESS,
        });
        sessionManager.removeDataFromCookies(cookiesConstants.DISABLE_OTP);
        sessionManager.removeDataFromCookies(cookiesConstants.OTP_TYPE_NUMBER);
        const existingEmails =
          sessionManager.getDataFromCookies(cookiesConstants.TRIED_EMAILS) ||
          [];

        const foundIndex = existingEmails.findIndex(
          (email) =>
            email ===
            sessionManager.getDataFromCookies(cookiesConstants.VALIDATE_EMAIL)
        );

        if (foundIndex !== -1) {
          const updatedEmails = [
            ...existingEmails.slice(0, foundIndex),
            ...existingEmails.slice(foundIndex + 1),
          ];
          sessionManager.setDataInCookies(
            updatedEmails,
            cookiesConstants.TRIED_EMAILS,
            1
          );
        }
        props.setDisableOtp(false);
        await resetPassword();
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
      console.error(error);
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  /**
   * Resets the user's password.
   * @function
   * @name resetPassword
   * @returns {void}
   */

  // FUNCTION TO RESET PASSWORD
  const resetPassword = async () => {
    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      let [error, passwordResetRes] = await utility.parseResponse(
        new UserService().forgotPassword({
          email: sessionManager.getDataFromCookies(
            cookiesConstants.VALIDATE_EMAIL
          ),
        })
      );
      if (error || !passwordResetRes) {
        ShowToast({
          message: error?.message,
          type: toastType.ERROR,
        });
        return;
      } else {
        ShowToast({
          message: `A password reset email has been sent to ${sessionManager.getDataFromCookies(
            cookiesConstants.VALIDATE_EMAIL
          )}. If you didn't receive an email, check your spam folder`,
          type: toastType.SUCCESS,
        });
        props.setLoginSeen(false);
        sessionManager.removeDataFromCookies(cookiesConstants.VALIDATE_EMAIL);
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
      console.error(error);
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  /**
   * Resends the verification code.
   * @function
   * @name resendCode
   * @returns {void}
   */

  // FUNCTION TO RESEND CODE
  const resendCode = async () => {
    setInputFields(["", "", "", "", "", ""]);
    dispatch({ type: eventConstants.SHOW_LOADER });
    try {
      let [error, resendOtpRes] = await utility.parseResponse(
        new UserEntityService().generateOtp({
          email: sessionManager.getDataFromCookies(
            cookiesConstants.VALIDATE_EMAIL
          ),
          isPassword: true,
        })
      );

      if (error || !resendOtpRes) {
        ShowToast({
          message: error?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: resendOtpRes?.message,
          type: toastType.SUCCESS,
        });
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
      console.error(error);
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    let updatedFields = [...inputFields];

    if (!/^\d+$/.test(text)) {
      return;
    }

    if (text.length > inputFields.length - index) {
      updatedFields.splice(
        index,
        inputFields.length - index,
        ...text.slice(0, inputFields.length - index).split("")
      );
    } else {
      updatedFields.splice(index, text.length, ...text.split(""));
    }

    setInputFields(updatedFields);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "ArrowLeft" && index > 0) {
      event.preventDefault();
      inputRefs[index - 1].current.focus();
    } else if (event.key === "ArrowRight" && index < inputFields.length - 1) {
      event.preventDefault();
      inputRefs[index + 1].current.focus();
    }
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="mt-41.25 mb-41.25 ml-123.5 mr-123.5 bg-white inline-flex h-142.5 p-7.5 flex-col justify-between items-start flex-shrink-0 rounded-xl shadow-md">
        <div className="flex flex-col items-start gap-95  mb-31">
          <div className="flex flex-col items-start gap-5 w-full">
            <div className="flex w-full justify-between items-start">
              <p className="text-black-100 text-ft4 font-ManropeBold text-center">
                Email verification
              </p>
              <img
                src={close}
                alt="close"
                className="cursor-pointer text-black-100"
                onClick={handleClick}
              />
            </div>
            <p className="w-full text-ft3 font-ManropeRegular text-black-100 font-normal mb-23.75">
              Enter the code sent to: {email}
            </p>
          </div>
          <div className="flex flex-col items-center gap-5">
            <div className="flex gap-2.5">
              {inputFields.map((field, index) => (
                <input
                  key={index}
                  type="text"
                  value={field}
                  maxLength="1"
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onPaste={(e) => handlePaste(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  className={`flex flex-row w-15.25 h-18 p-4 items-start text-black-100 font-ManropeBold text-ft5 rounded-17 bg-grey-50 text-center focus:outline-none border-transparent border-4 focus:border-white focus:ring-4 focus:ring-blue-100`}
                  ref={inputRefs[index]}
                />
              ))}
            </div>
            <button
              onClick={() => resendCode()}
              className="text-orange-50 text-center font-ManropeLight font-bold text-ft1"
            >
              Resend code
            </button>
          </div>
        </div>
        <div
          onClick={() => verifyOtp()}
          className={`flex h-16.25 py-2.5 px-7.5 justify-center items-center gap-2.5 flex-shrink-0 self-stretch rounded-full text-ft2 font-bold ${
            isAllFieldsFilled() ? "bg-orange-50 cursor-pointer" : "bg-grey-50"
          }`}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default PopUp;
