let initialState = {
  isSideBarOpen: false,
};
export const checkIsSidebarOpen = (data) => {
  return {
    type: "sidebar",
    payload: data,
  };
};

export default function sidebarComponent(state = initialState, action) {
  if (action.type === "sidebar") {
    return {
      ...state,
      isSideBarOpen: action.payload,
    };
  } else {
    return state;
  }
}
