import React, { useState } from "react";
import ImageViewer from "../../../common/component/imageViewer";

/**
 * React component for rendering a type of ranking.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {Array} props.details - The details of the ranking items.
 * @param {string} props.image - The type of ranking items, either "IMAGE" or another value.
 * @returns {React.ReactNode} - The rendered component.
 */
const TypeRanking = ({ details, image }) => {
  const [voteType, setVotetype] = useState("yourVote");
  const [openPopup, setOpenPopup] = useState(false);
  const [openImage, setOpenImage] = useState("");

  /**
   * Gets the ordinal suffix for a given rank.
   *
   * @function
   * @private
   * @param {number} rank - The rank to get the suffix for.
   * @returns {string} - The ordinal suffix for the rank.
   */
  function getOrdinalSuffix(rank) {
    const j = rank % 10,
      k = rank % 100;
    if (!rank) {
      return "not available";
    }
    if (j === 1 && k !== 11) {
      return `${rank}st place`;
    }
    if (j === 2 && k !== 12) {
      return `${rank}nd place`;
    }
    if (j === 3 && k !== 13) {
      return `${rank}rd place`;
    }
    return `${rank}th place`;
  }

  const sortedData = [...details].sort((a, b) => a.rank - b.rank);

  return (
    <div className="flex flex-col gap-7.5 w-full">
      <div className="flex rounded-30 bg-grey-50">
        <button
          className={`flex items-center w-1/2 justify-center whitespace-nowrap font-ManropeBold text-ft2 h-16 px-22.25 text-black-100 ${
            voteType === "yourVote" ? "bg-orange-50 rounded-30" : ""
          }`}
          onClick={() => {
            setVotetype("yourVote");
          }}
        >
          Your vote
        </button>
        <button
          className={`flex items-center w-1/2 justify-center whitespace-nowrap font-ManropeBold text-ft2 h-16 px-22.25 text-black-100 ${
            voteType === "majorityVote" ? "bg-orange-50 rounded-30" : ""
          }`}
          onClick={() => {
            setVotetype("majorityVote");
          }}
        >
          Majority vote
        </button>
      </div>
      {voteType === "yourVote" ? (
        <div className="flex flex-col gap-7.5">
          {details.map((item, index) => (
            <div className="flex justify-between" key={index}>
              {image === "IMAGE" || image === "Images" ? (
                <img
                  onClick={() => {
                    setOpenPopup(!openPopup);
                    setOpenImage(item?.value);
                  }}
                  src={item?.value.replace(/\s/g, "%20")}
                  alt={item?.rank || "-"}
                  className="w-15 h-15 rounded-15px cursor-pointer"
                />
              ) : (
                <p className="font-ManropeBold text-ft3 text-black-100">
                  {item?.value}
                </p>
              )}
              <p className="font-ManropeBold text-ft3 text-black-100">
                {getOrdinalSuffix(index + 1)}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col gap-7.5">
          {sortedData.map((item, index) => (
            <div className="flex justify-between" key={index}>
              {image === "IMAGE" ? (
                <img
                  onClick={() => {
                    setOpenPopup(!openPopup);
                    setOpenImage(item?.value);
                  }}
                  src={item?.value}
                  alt={item?.rank || "-"}
                  className="w-15 h-15 rounded-15px cursor-pointer"
                />
              ) : (
                <p className="font-ManropeBold text-ft3 text-black-100">
                  {item?.value}
                </p>
              )}
              <p className="font-ManropeBold text-ft3 text-black-100">
                {getOrdinalSuffix(item?.rank)}
              </p>
            </div>
          ))}
        </div>
      )}
      {openPopup && (
        <ImageViewer
          toggleImagePopUp={openPopup}
          setToggleImagePopUp={setOpenPopup}
          image={openImage}
        />
      )}
    </div>
  );
};

export default TypeRanking;
