import { eventConstants } from "../constant/commonArray";

let initialState = {
  loading: false,
};
export default function loader(state = initialState, action) {
  switch (action.type) {
    case eventConstants.SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case eventConstants.HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
