import { combineReducers } from "redux";
import user from "./user";
import loading from "./loader";
import sidebarComponent from "./manageSidebar";

export default combineReducers({
  user,
  loading,
  sideBar: sidebarComponent,
});
