import React, { useState } from "react";
import { ReactComponent as Delete } from "../../../assets/Icons/delete.svg";
import { ReactComponent as UpArrow } from "../../../assets/Icons/up-arrow.svg";
import { ReactComponent as DownArrow } from "../../../assets/Icons/down-arrow.svg";
import Dropdown from "../../../common/component/dropdown";
import utility from "../../../utility";

/**
 * EmployeeDetails component displays detailed information about an employee.
 * It includes the employee's name, type of staff, and additional details that can be expanded.
 *
 * @component
 * @example
 * const handleDeleteClick = (index) => {
 *   // handle delete logic
 * };
 *
 * const employeeInfo = {
 *   name: "John Doe",
 *   typeOfStaff: "Full-time",
 *   // ... other employee information
 * };
 *
 * <EmployeeDetails
 *   employeeInfo={employeeInfo}
 *   idx={0}
 *   handleDeleteClick={handleDeleteClick}
 * />
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.employeeInfo - Information about the employee.
 * @param {string} props.employeeInfo.name - The name of the employee.
 * @param {string} props.employeeInfo.typeOfStaff - The type of staff (e.g., Full-time, Part-time).
 * @param {number} props.idx - The index of the employee in the list.
 * @param {Function} props.handleDeleteClick - Function to handle the delete button click.
 * @returns {JSX.Element} The rendered EmployeeDetails component.
 */

const EmployeeDetails = ({ employeeInfo, idx, handleDeleteClick }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="w-1/2 pt-15">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <span className="font-ManropeBold text-ft4 text-black-100">
            {employeeInfo?.name}
          </span>
          <span className="font-ManropeRegular text-ft3 text-grey-250">
            {employeeInfo?.typeOfStaff}
          </span>
        </div>
        <div className="flex gap-5">
          <Delete
            className="w-6 h-6 cursor-pointer"
            onClick={() => handleDeleteClick(idx)}
          />
          {open ? (
            <UpArrow
              onClick={() => setOpen(!open)}
              className="cursor-pointer"
            />
          ) : (
            <DownArrow
              onClick={() => setOpen(!open)}
              className="cursor-pointer"
            />
          )}
        </div>
      </div>
      {open && (
        <div className="w-full flex flex-col gap-7.5 mt-7.5">
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Name
            </label>
            <input
              type="text"
              disabled
              value={employeeInfo?.name}
              className="rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Email
            </label>
            <input
              type="text"
              disabled
              value={employeeInfo?.email}
              className=" rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Phone number
            </label>
            <div className="rounded-full bg-grey-50  px-6 h-16.25 flex items-center">
              <div className="bg-none flex gap-1 pr-4 border-r border-black-125">
                <span className="text-black-100 text-ft3 font-ManropeBold ">
                  {employeeInfo?.countryCode}
                </span>
              </div>
              <input
                type="text"
                disabled
                value={employeeInfo?.phone}
                className="pl-1 w-full outline-none h-full bg-transparent text-black-100 font-ManropeRegular text-ft3"
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Type of staff
            </label>
            <Dropdown
              options={[]}
              placeHolder={"Not choosen"}
              disabled
              value={utility.formatText(employeeInfo?.staffType)}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Role
            </label>
            <Dropdown
              options={[]}
              disabled
              placeHolder={"Not choosen"}
              value={utility.formatText(employeeInfo?.role)}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Department
            </label>
            <Dropdown
              options={[]}
              placeHolder={"Not choosen"}
              disabled
              value={employeeInfo?.department}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Position
            </label>
            <Dropdown
              options={[]}
              placeHolder={"Not choosen"}
              disabled
              value={employeeInfo?.jobPosition}
              keyName={"position"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeDetails;
