/**
 * React component representing a CreatePoll page wrapper.
 * @function
 * @module CreatePoll
 * @returns {JSX.Element} JSX for the CreatePoll.
 */

import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import left from "../../../assets/Icons/Left.svg";
import ShowToast from "../../../common/showToast";
import { EnumValues, textData, toastType } from "../../../constant/commonArray";
import { FileUploadService, UserService } from "../../../services";
import { useSelector } from "react-redux";
import utility from "../../../utility";
import Poll from "./options/poll/poll";
import RankingPoll from "./options/ranking/rankingPoll";
import TwoSidedPoll from "./options/twoSided/twoSidedPoll";
import PollDetails from "./pollDetails";
import Setting from "./setting/setting";
import Survey from "./survey/survey";
import { CircularProgress } from "@mui/material";
import PreviewPollPopup from "../previewPoll";
import ContentCreationService from "../../../services/contentCreationService";

let ffmpeg;
const CreatePoll = () => {
  const [steps, setSteps] = useState(0);
  const [preview, setPreview] = useState(false);
  const [creating, setCreating] = useState(false);
  const user = useSelector((state) => state.user);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [images, setImages] = useState([]);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const pollId = queryParams.get("pollId");
  const [state, setState] = useState({
    questionType: "Poll",
    pollType: "PUBLIC",
    question: "",
    description: "",
    media: [],
    audioName: [],
    answer: [
      {
        title: "",
        description: "",
        cover: [],
      },
      {
        title: "",
        description: "",
        cover: [],
      },
    ],
    options: {
      name: "Text",
      answers: textData,
      type: "TEXT",
    },
    channels: [],
    reward: {
      rewardPerVote: "",
      budgetLimit: "",
    },
    isSensitive: false,
    isAnonymous: false,
    isFromAdmin: true,
    byPassReview: false,
    isForPollpapa: false,
    isRewardAdded: false,
    multipleOptions: false,
    openAnswer: false,
    polls: [
      {
        questionType: "Poll",
        question: "",
        description: "",
        media: [],
        images: [],
        audioName: [],
        answers: [
          {
            title: "",
            description: "",
            cover: [],
          },
          {
            title: "",
            description: "",
            cover: [],
          },
        ],
        options: {
          name: "Text",
          answers: textData,
          type: "TEXT",
        },
        multipleOptions: false,
        openAnswer: false,
      },
      {
        questionType: "Poll",
        question: "",
        description: "",
        media: [],
        images: [],
        audioName: [],
        answers: [
          {
            title: "",
            description: "",
            cover: [],
          },
          {
            title: "",
            description: "",
            cover: [],
          },
        ],
        options: {
          name: "Text",
          answers: textData,
          type: "TEXT",
        },
        multipleOptions: false,
        openAnswer: false,
      },
    ],
  });

  const [dateStart, setDateStart] = useState(null);

  const [dateEnd, setDateEnd] = useState(null);
  const [selectTime, setSelectTime] = useState(null);
  const [selectEndTime, setSelectEndTime] = useState(null);

  /**
   * Handles the creation of a poll.
   *
   * @return {Promise<void>} Returns a promise that resolves when the poll is created.
   */
  const handleCreatePoll = async () => {
    setCreating(true);
    try {
      const epochTimeStart = utility.getEpochTime(dateStart, selectTime);
      const epochTimeEnd = utility.getEpochTime(dateEnd, selectEndTime);
      let updatedState = {};
      const { addReward, polls, answer, audioName, ...rest } = state;
      let modifiedOptions;

      if (state?.questionType !== EnumValues.TWO_SIDED) {
        let uploadedFiles;
        if (state?.media?.length > 0) {
          const formDataDocument = new FormData();
          const videoFiles = new FormData();
          const filesToUpload = state.media.filter(
            (file) => file?.type !== "link" && file?.type !== "video/mp4"
          );

          const allMedia = state.media.filter((file) => file?.type !== "link");

          const videoToUpload = state.media.filter(
            (file) => file?.type === "video/mp4"
          );
          videoToUpload.forEach((file) => {
            videoFiles.append(`files`, file);
          });

          filesToUpload.forEach((file) => {
            formDataDocument.append(`files`, file);
          });
          let mediaFilesUrls = [];
          let videoFilesUrls = [];
          if (filesToUpload.length > 0) {
            const [errDocument, responseDocument] = await utility.parseResponse(
              new FileUploadService().fileUpload(formDataDocument)
            );

            if (errDocument || !responseDocument) return;
            if (responseDocument?.responseCode !== 200) {
              ShowToast({
                message: responseDocument?.message,
                type: toastType.ERROR,
              });
              return;
            }
            mediaFilesUrls = responseDocument?.responseData || [];
          }
          if (videoToUpload.length > 0) {
            const [errVideo, responseVideo] = await utility.parseResponse(
              new FileUploadService().fileCompressNUpload(videoFiles)
            );

            if (errVideo || !responseVideo) return;
            if (responseVideo?.responseCode !== 200) {
              ShowToast({
                message: responseVideo?.message,
                type: toastType.ERROR,
              });
              return;
            }
            videoFilesUrls = responseVideo?.responseData || [];
          }
          let updatedFiles = [];
          let videoIndex = 0;
          let mediaIndex = 0;
          allMedia.forEach((file) => {
            if (file?.type === "video/mp4") {
              updatedFiles.push({
                ...videoFilesUrls[videoIndex],
              });
              videoIndex++;
            } else {
              updatedFiles.push({
                ...mediaFilesUrls[mediaIndex],
              });
              mediaIndex++;
            }
          });
          uploadedFiles = updatedFiles?.map((fileInfo) => ({
            type: utility.extractFileExtension(fileInfo.fileName),
            url: fileInfo.cdnUrl,
          }));
        }

        const newDocuments = [];
        let linkIndex = 0;
        state.media.forEach((file, idx) => {
          if (file.type === "link") {
            newDocuments.push(file);
          } else {
            newDocuments.push({
              ...uploadedFiles[linkIndex],
              fileName: state.audioName[idx]?.fileName || "",
            });
            linkIndex++;
          }
        });

        if (images?.length > 0 && state?.options?.type === "IMAGE") {
          const formDataImages = new FormData();

          images.forEach((file) => {
            formDataImages.append(`files`, file);
          });

          const [errImages, responseImages] = await utility.parseResponse(
            new FileUploadService().fileUpload(formDataImages)
          );

          if (errImages || !responseImages) return;
          if (responseImages?.responseCode !== 200) {
            ShowToast({
              message: responseImages?.message,
              type: toastType.ERROR,
            });
            return;
          }
          modifiedOptions = responseImages?.responseData.map((fileInfo, i) => ({
            index: String.fromCharCode(i + 65),
            value: fileInfo.cdnUrl,
          }));
        } else {
          modifiedOptions = state?.options?.answers?.map((item, i) => ({
            index: String.fromCharCode(i + 65),
            value: item?.value,
          }));
        }

        updatedState = {
          ...rest,
          activePeriod: {
            startDate: epochTimeStart || state?.startDate,
            endDate: epochTimeEnd || state?.endDate,
          },
          creator: !state?.isForPollpapa ? user?.staffId : account?._id,
          staffId: user?.staffId,
          questionType:
            state?.questionType === EnumValues.TWO_SIDED
              ? "TWO_SIDED"
              : state?.questionType?.replace(/\s+/g, "_").toUpperCase(),
          options:
            state?.questionType !== EnumValues.OPEN_QUESTION
              ? {
                  answers: modifiedOptions,
                  type: state?.options?.type,
                }
              : undefined,
          isForPollpapa: !state?.isForPollpapa,
          description: state?.description
            .split("@[")
            .join("")
            .split("]")
            .join(""),
          media: [...newDocuments],
        };
      } else {
        if (state.questionType === EnumValues.TWO_SIDED) {
          if (
            state?.answer[0]?.cover.length > 0 &&
            state?.answer[1]?.cover?.length > 0
          ) {
            const formDataImages = new FormData();

            const formatImages = [
              ...state?.answer[0]?.cover,
              ...state?.answer[1]?.cover,
            ];

            formatImages.forEach((file) => {
              formDataImages.append(`files`, file);
            });

            const [errImages, responseImages] = await utility.parseResponse(
              new FileUploadService().fileUpload(formDataImages)
            );

            if (errImages || !responseImages) return;
            if (responseImages?.responseCode !== 200) {
              ShowToast({
                message: responseImages?.message,
                type: toastType.ERROR,
              });
              return;
            }
            modifiedOptions = responseImages?.responseData.map(
              (fileInfo, i) => ({
                ...state?.answer[i],
                cover: fileInfo.cdnUrl,
                index: String.fromCharCode(i + 65),
              })
            );
          } else {
            modifiedOptions = state.answer.map((answer, i) => ({
              ...answer,
              index: String.fromCharCode(i + 65),
            }));
          }
        }
        updatedState = {
          ...rest,
          activePeriod: {
            startDate: epochTimeStart || state?.startDate,
            endDate: epochTimeEnd || state?.endDate,
          },
          creator: !state?.isForPollpapa ? user?.staffId : account?._id,
          options: {
            answers: modifiedOptions,
            type: state?.options?.type,
          },
          questionType:
            state?.questionType === EnumValues.TWO_SIDED
              ? "TWO_SIDED"
              : state?.questionType?.replace(/\s+/g, "_").toUpperCase(),
          staffId: user?.staffId,
          isForPollpapa: !state?.isForPollpapa,
          description: state?.description
            .split("@[")
            .join("")
            .split("]")
            .join(""),
        };
      }

      const [error, response] = await utility.parseResponse(
        new ContentCreationService().createPoll(updatedState)
      );

      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        history.push("/poll-list");
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    } finally {
      setCreating(false);
    }
  };

  /**
   * Handles the creation of a survey.
   *
   * @return {Promise<void>} Promise that resolves when the survey is created.
   */
  const handleCreateSurvey = async () => {
    setCreating(true);
    try {
      const epochTimeStart = utility.getEpochTime(dateStart, selectTime);
      const epochTimeEnd = utility.getEpochTime(dateEnd, selectEndTime);
      let uploadedFiles = [];
      if (state?.media?.length > 0) {
        const formDataDocument = new FormData();
        const videoFiles = new FormData();
        const filesToUpload = state.media.filter(
          (file) => file?.type !== "link" && file?.type !== "video/mp4"
        );
        const allMedia = state.media.filter((file) => file?.type !== "link");
        const videoToUpload = state.media.filter(
          (file) => file?.type === "video/mp4"
        );
        videoToUpload.forEach((file) => {
          videoFiles.append(`files`, file);
        });

        filesToUpload.forEach((file) => {
          formDataDocument.append(`files`, file);
        });
        let mediaFilesUrls = [];
        let videoFilesUrls = [];
        if (filesToUpload.length > 0) {
          const [errDocument, responseDocument] = await utility.parseResponse(
            new FileUploadService().fileUpload(formDataDocument)
          );

          if (errDocument || !responseDocument) return;
          if (responseDocument?.responseCode !== 200) {
            ShowToast({
              message: responseDocument?.message,
              type: toastType.ERROR,
            });
            return;
          }
          mediaFilesUrls = responseDocument?.responseData || [];
        }
        if (videoToUpload.length > 0) {
          const [errVideo, responseVideo] = await utility.parseResponse(
            new FileUploadService().fileCompressNUpload(videoFiles)
          );

          if (errVideo || !responseVideo) return;
          if (responseVideo?.responseCode !== 200) {
            ShowToast({
              message: responseVideo?.message,
              type: toastType.ERROR,
            });
            return;
          }
          videoFilesUrls = responseVideo?.responseData;
        }
        let updatedFiles = [];
        let videoIndex = 0;
        let mediaIndex = 0;
        allMedia.forEach((file) => {
          if (file?.type === "video/mp4") {
            updatedFiles.push({
              ...videoFilesUrls[videoIndex],
            });
            videoIndex++;
          } else {
            updatedFiles.push({
              ...mediaFilesUrls[mediaIndex],
            });
            mediaIndex++;
          }
        });
        uploadedFiles = updatedFiles?.map((fileInfo) => ({
          type: utility.extractFileExtension(fileInfo.fileName),
          url: fileInfo.cdnUrl,
        }));
      }

      const pollsData = await uploadPollMedia();
      const {
        addReward,
        polls,
        pollType,
        questionType,
        question,
        openAnswer,
        media,
        multipleOptions,
        options,
        answer,
        audioName,
        ...rest
      } = state;

      const updatedState = {
        ...rest,
        activePeriod: {
          startDate: epochTimeStart || state?.startDate,
          endDate: epochTimeEnd || state?.endDate,
        },
        surveyName: state?.question,
        surveyType: "PUBLIC",
        coverImage: {
          type: uploadedFiles[0]?.type,
          url: uploadedFiles[0]?.url,
        },
        creator: !state?.isForPollpapa ? user?.staffId : account?._id,
        staffId: user?.staffId,
        isForPollpapa: !state?.isForPollpapa,
        polls: pollsData,
        description: state?.description
          .split("@[")
          .join("")
          .split("]")
          .join(""),
      };

      const [error, response] = await utility.parseResponse(
        new ContentCreationService().createSurvey(updatedState)
      );

      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        history.push("/poll-list");
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setCreating(false);
    }
  };

  const uploadPollMedia = async () => {
    try {
      const updatedPolls = await Promise.all(
        state.polls.map(async (poll) => {
          let modifiedOptions;
          const newDocuments = [];
          if (poll.questionType !== EnumValues.TWO_SIDED) {
            const filesToUpload = poll.media.filter(
              (file) => file?.type !== "link"
            );
            let uploadedMedia;

            if (filesToUpload.length > 0) {
              const formDataPoll = new FormData();

              const videoFiles = new FormData();
              const filesUpload = poll.media.filter(
                (file) => file?.type !== "link" && file?.type !== "video/mp4"
              );
              const allMedia = poll.media.filter(
                (file) => file?.type !== "link"
              );
              const videoToUpload = poll.media.filter(
                (file) => file?.type === "video/mp4"
              );
              videoToUpload.forEach((file) => {
                videoFiles.append(`files`, file);
              });

              filesUpload.forEach((file) => {
                formDataPoll.append(`files`, file);
              });

              let mediaFilesUrls = [];
              let videoFilesUrls = [];
              if (filesToUpload.length > 0) {
                const [errDocument, responseDocument] =
                  await utility.parseResponse(
                    new FileUploadService().fileUpload(formDataPoll)
                  );

                if (errDocument || !responseDocument) return;
                if (responseDocument?.responseCode !== 200) {
                  ShowToast({
                    message: responseDocument?.message,
                    type: toastType.ERROR,
                  });
                  return;
                }
                mediaFilesUrls = responseDocument?.responseData || [];
              }
              if (videoToUpload.length > 0) {
                const [errVideo, responseVideo] = await utility.parseResponse(
                  new FileUploadService().fileCompressNUpload(videoFiles)
                );

                if (errVideo || !responseVideo) return;
                if (responseVideo?.responseCode !== 200) {
                  ShowToast({
                    message: responseVideo?.message,
                    type: toastType.ERROR,
                  });
                  return;
                }
                videoFilesUrls = responseVideo?.responseData;
              }

              let updatedFiles = [];
              let videoIndex = 0;
              let mediaIndex = 0;
              allMedia.forEach((file) => {
                if (file?.type === "video/mp4") {
                  updatedFiles.push({
                    ...videoFilesUrls[videoIndex],
                  });
                  videoIndex++;
                } else {
                  updatedFiles.push({
                    ...mediaFilesUrls[mediaIndex],
                  });
                  mediaIndex++;
                }
              });

              uploadedMedia = updatedFiles?.map((fileInfo) => ({
                type: utility.extractFileExtension(fileInfo.fileName),
                url: fileInfo.cdnUrl,
              }));
            }

            let linkIndex = 0;
            poll.media.forEach((file, index) => {
              if (file?.type === "link") {
                newDocuments.push(file);
              } else {
                newDocuments.push({
                  ...uploadedMedia[linkIndex],
                  fileName: poll.audioName[index]?.fileName || "",
                });
                linkIndex++;
              }
            });
            if (poll?.images?.length > 0 && poll?.options?.type === "IMAGE") {
              const formDataImages = new FormData();

              poll.images.forEach((file) => {
                formDataImages.append(`files`, file);
              });

              const [errImages, responseImages] = await utility.parseResponse(
                new FileUploadService().fileUpload(formDataImages)
              );

              if (errImages || !responseImages) return;
              if (responseImages?.responseCode !== 200) {
                ShowToast({
                  message: responseImages?.message,
                  type: toastType.ERROR,
                });
                return;
              }
              modifiedOptions = responseImages?.responseData.map(
                (fileInfo, i) => ({
                  index: String.fromCharCode(i + 65),
                  value: fileInfo.cdnUrl,
                })
              );
            } else {
              modifiedOptions = poll?.options?.answers?.map((item, i) => ({
                index: String.fromCharCode(i + 65),
                value: item?.value,
              }));
            }
          } else if (poll.questionType === EnumValues.TWO_SIDED) {
            if (
              poll?.answers[0]?.cover?.length > 0 &&
              poll?.answers[1].cover?.length > 0
            ) {
              const formDataImages = new FormData();

              const formatImages = [
                ...poll.answers[0]?.cover,
                ...poll.answers[1]?.cover,
              ];

              formatImages.forEach((file) => {
                formDataImages.append(`files`, file);
              });

              const [errImages, responseImages] = await utility.parseResponse(
                new FileUploadService().fileUpload(formDataImages)
              );

              if (errImages || !responseImages) return;
              if (responseImages?.responseCode !== 200) {
                ShowToast({
                  message: responseImages?.message,
                  type: toastType.ERROR,
                });
                return;
              }
              modifiedOptions = responseImages?.responseData.map(
                (fileInfo, i) => ({
                  ...poll.answers[i],
                  cover: fileInfo.cdnUrl,
                  index: String.fromCharCode(i + 65),
                })
              );
            } else {
              modifiedOptions = poll.answers.map((answer, i) => ({
                ...answer,
                index: String.fromCharCode(i + 65),
              }));
            }
          }

          const { images, answers, audioName, ...rest } = poll;

          return {
            ...rest,
            media: [...newDocuments],
            options:
              poll?.questionType !== EnumValues.OPEN_QUESTION
                ? { type: poll?.options?.type, answers: modifiedOptions }
                : undefined,
            questionType:
              poll?.questionType === EnumValues.TWO_SIDED
                ? "TWO_SIDED"
                : poll?.questionType?.replace(/\s+/g, "_").toUpperCase(),
            description: poll?.description
              .split("@[")
              .join("")
              .split("]")
              .join(""),
          };
        })
      );

      return updatedPolls.filter((poll) => poll !== null);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getDetails = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().pollDetailsManagement(pollId)
      );
      if (error || !response) return;
      setState((prev) => ({
        ...prev,
        channels: response[0]?.channels,
        questionType:
          response[0]?.post?.questionType === "TWO_SIDED"
            ? EnumValues.TWO_SIDED
            : utility.formatText(
                response[0]?.post?.questionType || response[0]?.type
              ),
        pollType: utility.capitalizeFirstLetter(response[0]?.post?.pollType),
        question: response[0]?.post?.question || response[0]?.post?.surveyName,
        description: response[0]?.post?.description
          .replace(/(@\w+)/g, "@[$1]")
          .replace(/(#\w+)/g, "@[$1]"),
        media: getMedia(response),
        audioName:
          response[0]?.post?.media?.map((item) => ({
            fileName: item?.fileName || "",
          })) || [],

        options: {
          name: utility.capitalizeFirstLetter(response[0]?.post?.options?.type),
          type: response[0]?.post?.options?.type,
          answers: response[0]?.post?.options?.answers,
        },
        multipleOptions: response[0]?.post?.multipleOptions,
        openAnswer: response[0]?.post?.openAnswer,
        isSensitive: response[0]?.isSensitive,
        isForPollpapa: response[0]?.isForPollpapa || true,
        reason: response[0]?.rejectReason,
        isRewardAdded: response[0]?.isRewardAdded,
        reward: response[0]?.reward,
        answer: response[0]?.post?.options?.answers ?? [],
        polls: (response[0]?.post?.polls ?? [])?.map((poll) => ({
          ...poll,
          questionType:
            poll?.questionType === "TWO_SIDED"
              ? EnumValues.TWO_SIDED
              : utility.formatText(poll.questionType) || "",
          images:
            poll?.options?.type === "IMAGE" ? poll?.options?.answers || [] : [],
          question: poll?.question,
          description: poll?.description
            .replace(/(@\w+)/g, "@[$1]")
            .replace(/(#\w+)/g, "@[$1]"),
          options: {
            name: utility.capitalizeFirstLetter(poll?.options?.type),
            type: poll?.options?.type,
            answers: poll?.options?.answers,
          },
          audioName:
            poll?.media?.map((item) => ({
              fileName: item?.fileName || "",
            })) || [],
          media: poll?.media,
          answers: poll?.options?.answers,
        })),
      }));
      if (response[0]?.post?.options?.type === "IMAGE") {
        setImages(response[0]?.post?.options?.answers);
      }
      setAccount(response[0]?.creator);
      const epochStartDate = response[0]?.info?.activePeriod?.startDate;
      const startDate = new Date(epochStartDate);

      const hoursStart = startDate.getHours();
      const minutesStart = startDate.getMinutes();
      const formattedTimeStart = `${String(hoursStart).padStart(
        2,
        "0"
      )}:${String(minutesStart).padStart(2, "0")}`;
      setDateStart(startDate);

      setSelectTime(formattedTimeStart);
      const epochEndDate = response[0]?.info?.activePeriod?.endDate;
      const endDate = new Date(epochEndDate);

      const hoursEnd = endDate.getHours();
      const minutesEnd = endDate.getMinutes();
      const formattedTimeEnd = `${String(hoursEnd).padStart(2, "0")}:${String(
        minutesEnd
      ).padStart(2, "0")}`;
      setDateEnd(endDate);
      setSelectEndTime(formattedTimeEnd);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  function getMedia(response) {
    const post = response[0]?.post;

    if (post?.media?.length > 0) {
      return post.media;
    }

    const coverImage = post?.coverImage;

    if (
      coverImage &&
      Object.keys(coverImage).length !== 0 &&
      coverImage.url !== ""
    ) {
      return [coverImage];
    }

    return [];
  }

  const handleEditPoll = async () => {
    setCreating(true);
    try {
      const epochTimeStart = utility.getEpochTime(dateStart, selectTime);
      const epochTimeEnd = utility.getEpochTime(dateEnd, selectEndTime);
      const requestData = {
        question: state.question,
        description: state.description,
        channels: state?.channels,
        multipleOptions: state?.multipleOptions,
        openAnswer: state?.openAnswer,
        isSensitive: state?.isSensitive,
        isRewardAdded: state?.isRewardAdded,
        activePeriod: {
          startDate: epochTimeStart,
          endDate: epochTimeEnd,
        },
        reward: state?.reward,
      };

      let modifiedOptions;
      if (state?.media?.length > 0) {
        let uploadedFiles;
        const filesToUpload = state.media.filter(
          (file) => file instanceof File
        );

        if (filesToUpload.length > 0) {
          const formDataDocument = new FormData();

          const videoFiles = new FormData();

          const videoToUpload = state.media.filter(
            (file) => file?.type === "video/mp4"
          );

          const uploadFiles = state.media.filter(
            (file) => file?.type !== "video/mp4" && file instanceof File
          );

          videoToUpload.forEach((file) => {
            videoFiles.append(`files`, file);
          });

          uploadFiles.forEach((file) => {
            formDataDocument.append(`files`, file);
          });
          let mediaFilesUrls = [];
          let videoFilesUrls = [];
          if (uploadFiles.length > 0) {
            const [errDocument, responseDocument] = await utility.parseResponse(
              new FileUploadService().fileUpload(formDataDocument)
            );

            if (errDocument || !responseDocument) return;
            if (responseDocument?.responseCode !== 200) {
              ShowToast({
                message: responseDocument?.message,
                type: toastType.ERROR,
              });
              return;
            }
            mediaFilesUrls = responseDocument?.responseData || [];
          }
          if (videoToUpload.length > 0) {
            const [errVideo, responseVideo] = await utility.parseResponse(
              new FileUploadService().fileCompressNUpload(videoFiles)
            );

            if (errVideo || !responseVideo) return;
            if (responseVideo?.responseCode !== 200) {
              ShowToast({
                message: responseVideo?.message,
                type: toastType.ERROR,
              });
              return;
            }
            videoFilesUrls = responseVideo?.responseData;
          }

          let updatedFiles = [];
          let videoIndex = 0;
          let mediaIndex = 0;
          filesToUpload.forEach((file) => {
            if (file?.type === "video/mp4") {
              updatedFiles.push({
                ...videoFilesUrls[videoIndex],
              });
              videoIndex++;
            } else {
              updatedFiles.push({
                ...mediaFilesUrls[mediaIndex],
              });
              mediaIndex++;
            }
          });
          uploadedFiles = updatedFiles?.map((fileInfo) => ({
            type: utility.extractFileExtension(fileInfo.fileName),
            url: fileInfo.cdnUrl,
          }));
        }
        const newMedia = [];
        let fileIndex = 0;
        state.media.forEach((file, idx) => {
          if (file instanceof File) {
            newMedia.push({
              ...uploadedFiles[fileIndex],
              fileName: state.audioName[idx]?.fileName || "",
            });
            fileIndex++;
          } else {
            newMedia.push({
              ...file,
              fileName: state.audioName[idx]?.fileName || "",
            });
          }
        });
        requestData.media = newMedia;
      }
      const filesToUpload = images.filter((file) => file instanceof File);
      if (filesToUpload?.length > 0 && state?.options?.type === "Images") {
        const formDataImages = new FormData();

        filesToUpload.forEach((file) => {
          formDataImages.append(`files`, file);
        });

        const [errImages, responseImages] = await utility.parseResponse(
          new FileUploadService().fileUpload(formDataImages)
        );

        if (errImages || !responseImages) return;
        if (responseImages?.responseCode !== 200) {
          ShowToast({
            message: responseImages?.message,
            type: toastType.ERROR,
          });
          return;
        }
        modifiedOptions = responseImages?.responseData.map((fileInfo, i) => ({
          value: fileInfo.cdnUrl,
        }));

        const newMedia = [];
        let fileIndex = 0;
        images.forEach((file, i) => {
          if (file instanceof File) {
            modifiedOptions[fileIndex] = {
              ...modifiedOptions[fileIndex],
              index: String.fromCharCode(i + 65),
            };
            newMedia.push(modifiedOptions[fileIndex]);
            fileIndex++;
          } else {
            newMedia.push(file);
          }
        });
        requestData.options = { type: state?.options?.type, answers: newMedia };
      } else if (
        state?.options?.type === "Images" &&
        state?.questionType === "Ranking"
      ) {
        requestData.options = { type: state?.options?.type, answers: images };
      } else if (state?.questionType === EnumValues.TWO_SIDED) {
        let modifiedOptions = [];
        if (state?.answer[0]?.cover[0] instanceof File) {
          const formDataImages = new FormData();
          state.answer[0].cover.forEach((file) => {
            formDataImages.append(`files`, file);
          });

          const [errImages, responseImages] = await utility.parseResponse(
            new FileUploadService().fileUpload(formDataImages)
          );

          if (errImages || !responseImages) return;
          if (responseImages?.responseCode !== 200) {
            ShowToast({
              message: responseImages?.message,
              type: toastType.ERROR,
            });
            return;
          }

          modifiedOptions.push({
            ...state?.answer[0],
            cover: responseImages?.responseData[0]?.cdnUrl,
          });
        } else {
          modifiedOptions.push({ ...state?.answer[0] });
        }

        if (state?.answer[1].cover[0] instanceof File) {
          const formDataImages = new FormData();
          state.answer[1].cover.forEach((file) => {
            formDataImages.append(`files`, file);
          });

          const [errImages, responseImages] = await utility.parseResponse(
            new FileUploadService().fileUpload(formDataImages)
          );

          if (errImages || !responseImages) return;
          if (responseImages?.responseCode !== 200) {
            ShowToast({
              message: responseImages?.message,
              type: toastType.ERROR,
            });
            return;
          }

          modifiedOptions.push({
            ...state?.answer[1],
            cover: responseImages?.responseData[0]?.cdnUrl,
          });
        } else {
          modifiedOptions.push({ ...state?.answer[1] });
        }

        requestData.options = { answers: modifiedOptions };
      } else if (state.questionType !== EnumValues.OPEN_QUESTION) {
        requestData.options = state?.options;
      }
      const [error, response] = await utility.parseResponse(
        new ContentCreationService().editPoll(pollId, requestData)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        history.push("/poll-list");
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setCreating(false);
    }
  };

  const handleEditSurvey = async () => {
    setCreating(true);
    try {
      const epochTimeStart = utility.getEpochTime(dateStart, selectTime);
      const epochTimeEnd = utility.getEpochTime(dateEnd, selectEndTime);
      const newMedia = [];

      if (state?.media?.length > 0) {
        let uploadedFiles;
        const filesToUpload = state.media.filter(
          (file) => file instanceof File
        );
        if (filesToUpload.length > 0) {
          const formDataDocument = new FormData();
          const videoFiles = new FormData();
          const filesUpload = state.media.filter(
            (file) => file?.type !== "link" && file?.type !== "video/mp4"
          );
          const allMedia = state.media.filter((file) => file?.type !== "link");
          const videoToUpload = state.media.filter(
            (file) => file?.type === "video/mp4"
          );
          videoToUpload.forEach((file) => {
            videoFiles.append(`files`, file);
          });

          filesUpload.forEach((file) => {
            formDataDocument.append(`files`, file);
          });
          let mediaFilesUrls = [];
          let videoFilesUrls = [];
          if (filesToUpload.length > 0) {
            const [errDocument, responseDocument] = await utility.parseResponse(
              new FileUploadService().fileUpload(formDataDocument)
            );

            if (errDocument || !responseDocument) return;
            if (responseDocument?.responseCode !== 200) {
              ShowToast({
                message: responseDocument?.message,
                type: toastType.ERROR,
              });
              return;
            }
            mediaFilesUrls = responseDocument?.responseData || [];
          }
          if (videoToUpload.length > 0) {
            const [errVideo, responseVideo] = await utility.parseResponse(
              new FileUploadService().fileCompressNUpload(videoFiles)
            );

            if (errVideo || !responseVideo) return;
            if (responseVideo?.responseCode !== 200) {
              ShowToast({
                message: responseVideo?.message,
                type: toastType.ERROR,
              });
              return;
            }
            videoFilesUrls = responseVideo?.responseData;
          }
          let updatedFiles = [];
          let videoIndex = 0;
          let mediaIndex = 0;
          allMedia.forEach((file) => {
            if (file?.type === "video/mp4") {
              updatedFiles.push({
                ...videoFilesUrls[videoIndex],
              });
              videoIndex++;
            } else {
              updatedFiles.push({
                ...mediaFilesUrls[mediaIndex],
              });
              mediaIndex++;
            }
          });
          uploadedFiles = updatedFiles?.map((fileInfo) => ({
            type: utility.extractFileExtension(fileInfo.fileName),
            url: fileInfo.cdnUrl,
          }));
        }

        let fileIndex = 0;
        state.media.forEach((file) => {
          if (file instanceof File) {
            newMedia.push(uploadedFiles[fileIndex]);
            fileIndex++;
          } else {
            newMedia.push(file);
          }
        });
      }

      const pollsData = await uploadEditPollMedia();
      const {
        addReward,
        polls,
        pollType,
        questionType,
        question,
        openAnswer,
        media,
        multipleOptions,
        options,
        answer,
        isForPollpapa,
        isFromAdmin,
        reason,
        audioName,
        ...rest
      } = state;

      const updatedState = {
        ...rest,
        activePeriod: {
          startDate: epochTimeStart || state?.startDate,
          endDate: epochTimeEnd || state?.endDate,
        },
        surveyName: state?.question,
        coverImage: {
          type: newMedia[0]?.type,
          url: newMedia[0]?.url,
        },
        polls: pollsData,
      };

      const [error, response] = await utility.parseResponse(
        new ContentCreationService().editSurvey(pollId, updatedState)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        history.push("/poll-list");
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setCreating(false);
    }
  };

  const uploadEditPollMedia = async () => {
    try {
      const updatedPolls = await Promise.all(
        state.polls.map(async (poll) => {
          const filesToUpload = poll.media.filter(
            (file) => file instanceof File
          );
          let modifiedOptions = [];
          const newDocuments = [];
          let uploadedMedia = [];

          if (filesToUpload.length > 0) {
            const formDataPoll = new FormData();

            const videoFiles = new FormData();
            const filesUpload = poll.media.filter(
              (file) => file?.type !== "video/mp4" && file instanceof File
            );
            const videoToUpload = poll.media.filter(
              (file) => file?.type === "video/mp4"
            );
            videoToUpload.forEach((file) => {
              videoFiles.append(`files`, file);
            });

            filesUpload.forEach((file) => {
              formDataPoll.append(`files`, file);
            });

            let mediaFilesUrls = [];
            let videoFilesUrls = [];
            if (filesUpload.length > 0) {
              const [errDocument, responseDocument] =
                await utility.parseResponse(
                  new FileUploadService().fileUpload(formDataPoll)
                );

              if (errDocument || !responseDocument) return;
              if (responseDocument?.responseCode !== 200) {
                ShowToast({
                  message: responseDocument?.message,
                  type: toastType.ERROR,
                });
                return;
              }
              mediaFilesUrls = responseDocument?.responseData || [];
            }
            if (videoToUpload.length > 0) {
              const [errVideo, responseVideo] = await utility.parseResponse(
                new FileUploadService().fileCompressNUpload(videoFiles)
              );

              if (errVideo || !responseVideo) return;
              if (responseVideo?.responseCode !== 200) {
                ShowToast({
                  message: responseVideo?.message,
                  type: toastType.ERROR,
                });
                return;
              }
              videoFilesUrls = responseVideo?.responseData;
            }

            let updatedFiles = [];
            let videoIndex = 0;
            let mediaIndex = 0;
            filesToUpload.forEach((file) => {
              if (file?.type === "video/mp4") {
                updatedFiles.push({
                  ...videoFilesUrls[videoIndex],
                });
                videoIndex++;
              } else {
                updatedFiles.push({
                  ...mediaFilesUrls[mediaIndex],
                });
                mediaIndex++;
              }
            });

            uploadedMedia = updatedFiles?.map((fileInfo) => ({
              type: utility.extractFileExtension(fileInfo.fileName),
              url: fileInfo.cdnUrl,
            }));
          }

          let linkIndex = 0;
          poll.media.forEach((file, index) => {
            if (file instanceof File) {
              newDocuments.push({
                ...uploadedMedia[linkIndex],
                fileName: poll.audioName[index]?.fileName || "",
              });
              linkIndex++;
            } else {
              newDocuments.push({
                ...file,
                fileName: poll.audioName[index]?.fileName || "",
              });
            }
          });
          if (
            poll?.images?.length > 0 &&
            poll?.questionType !== EnumValues.TWO_SIDED &&
            poll?.questionType !== EnumValues.OPEN_QUESTION
          ) {
            const filesToUpload = poll.images.filter(
              (file) => file instanceof File
            );
            if (filesToUpload.length > 0) {
              const formDataImages = new FormData();

              filesToUpload.forEach((file) => {
                formDataImages.append(`files`, file);
              });

              const [errImages, responseImages] = await utility.parseResponse(
                new FileUploadService().fileUpload(formDataImages)
              );

              if (errImages || !responseImages) return;
              if (responseImages?.responseCode !== 200) {
                ShowToast({
                  message: responseImages?.message,
                  type: toastType.ERROR,
                });
                return;
              }
              modifiedOptions = responseImages?.responseData.map(
                (fileInfo, i) => ({
                  value: fileInfo.cdnUrl,
                })
              );
            }
            let fileIndex = 0;
            poll.images.forEach((file, i) => {
              const newIndex = String.fromCharCode(i + 65);
              if (file instanceof File) {
                modifiedOptions[fileIndex] = {
                  ...modifiedOptions[fileIndex],
                  index: newIndex,
                };

                fileIndex++;
              } else {
                modifiedOptions.push({
                  ...file,
                  index: newIndex,
                });
              }
            });
          } else if (poll?.questionType === EnumValues.TWO_SIDED) {
            if (poll?.answers[0]?.cover[0] instanceof File) {
              const formDataImages = new FormData();
              poll.answers[0].cover.forEach((file) => {
                formDataImages.append(`files`, file);
              });

              const [errImages, responseImages] = await utility.parseResponse(
                new FileUploadService().fileUpload(formDataImages)
              );

              if (errImages || !responseImages) return;
              if (responseImages?.responseCode !== 200) {
                ShowToast({
                  message: responseImages?.message,
                  type: toastType.ERROR,
                });
                return;
              }

              modifiedOptions.push({
                ...poll?.answers[0],
                cover: responseImages?.responseData[0]?.cdnUrl,
              });
            } else {
              modifiedOptions.push({ ...poll?.answers[0] });
            }

            if (poll?.answers[1].cover[0] instanceof File) {
              const formDataImages = new FormData();
              poll.answers[1].cover.forEach((file) => {
                formDataImages.append(`files`, file);
              });

              const [errImages, responseImages] = await utility.parseResponse(
                new FileUploadService().fileUpload(formDataImages)
              );

              if (errImages || !responseImages) return;
              if (responseImages?.responseCode !== 200) {
                ShowToast({
                  message: responseImages?.message,
                  type: toastType.ERROR,
                });
                return;
              }

              modifiedOptions.push({
                ...poll?.answers[1],
                cover: responseImages?.responseData[0]?.cdnUrl,
              });
            } else {
              modifiedOptions.push({ ...poll?.answers[1] });
            }
          } else {
            modifiedOptions = poll?.options?.answers?.map((item, i) => ({
              index: String.fromCharCode(i + 65),
              value: item?.value,
            }));
          }

          const { images, answers, audioName, ...rest } = poll;
          return {
            ...rest,
            media: [...newDocuments],
            options:
              poll?.questionType !== EnumValues.OPEN_QUESTION
                ? {
                    answers: modifiedOptions,
                    type: poll?.options?.type,
                  }
                : undefined,
            questionType:
              poll?.questionType === EnumValues.TWO_SIDED
                ? "TWO_SIDED"
                : poll?.questionType?.replace(/\s+/g, "_").toUpperCase(),
          };
        })
      );

      return updatedPolls.filter((poll) => poll !== null);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const loadScript = (src) => {
    return new Promise((onFulfilled, _) => {
      const script = document.createElement("script");
      let loaded;
      script.async = "async";
      script.defer = "defer";
      script.setAttribute("src", src);
      script.onreadystatechange = script.onload = () => {
        if (!loaded) {
          onFulfilled(script);
        }
        loaded = true;
      };
      script.onerror = function () {
        console.log("Script failed to load");
      };
      document.getElementsByTagName("head")[0].appendChild(script);
    });
  };

  useEffect(() => {
    loadScript(
      "https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.11.2/dist/ffmpeg.min.js"
    ).then(() => {
      if (typeof window !== "undefined") {
        ffmpeg = window.FFmpeg.createFFmpeg({ log: false });
        ffmpeg.load();
        setIsScriptLoaded(true);
      }
    });
  }, []);

  const renderStepComponent = () => {
    switch (steps) {
      case 0:
        return (
          <PollDetails
            setState={setState}
            state={state}
            setSteps={setSteps}
            account={account}
            setAccount={setAccount}
            pollId={pollId}
            setImages={setImages}
            ffmpeg={ffmpeg}
            isScriptLoaded={isScriptLoaded}
          />
        );
      case 1:
        switch (state?.questionType) {
          case EnumValues.POLL:
            return (
              <Poll
                setState={setState}
                state={state}
                setSteps={setSteps}
                images={images}
                setImages={setImages}
              />
            );
          case EnumValues.TWO_SIDED:
            return (
              <TwoSidedPoll
                setSteps={setSteps}
                state={state}
                setState={setState}
              />
            );
          case EnumValues.RANKING:
            return (
              <RankingPoll
                setState={setState}
                state={state}
                setSteps={setSteps}
                images={images}
                setImages={setImages}
              />
            );
          case EnumValues.SURVEY:
            return (
              <Survey
                setState={setState}
                setSteps={setSteps}
                state={state}
                images={images}
                setImages={setImages}
                ffmpeg={ffmpeg}
                isScriptLoaded={isScriptLoaded}
              />
            );
          default:
            return null;
        }
      default:
        return (
          <Setting
            setState={setState}
            state={state}
            setEndTime={setSelectEndTime}
            endTime={selectEndTime}
            startTime={selectTime}
            setStartTime={setSelectTime}
            setEndDate={setDateEnd}
            endDate={dateEnd}
            startDate={dateStart}
            setStartDate={setDateStart}
            setSteps={setSteps}
            handleCreatePoll={handleCreatePoll}
            handleCreateSurvey={handleCreateSurvey}
            creating={creating}
            handleEditPoll={handleEditPoll}
            handleEditSurvey={handleEditSurvey}
          />
        );
    }
  };

  useEffect(() => {
    if (pollId) {
      getDetails();
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div className="py-5 w-full h-full  overflow-y-auto pr-12.5">
      <div className="flex justify-between bg-white p-5 items-center w-full rounded-20  h-21.25">
        <div className="flex items-center gap-7.5">
          <Link to="/poll-list">
            <img src={left} alt="left" />
          </Link>
          <p className="text-ft5 text-black-100 font-ManropeBold ">Create</p>
        </div>
      </div>
      <div className="w-full  bg-white mt-5 rounded-20 px-10 py-5">
        <div className="w-full flex justify-between">
          <div className="flex gap-10">
            <span
              className={`${
                steps === 0
                  ? "text-ft5 font-ManropeBold text-black-100"
                  : "text-ft3 font-ManropeMedium text-black-75"
              }`}
            >
              Details
            </span>
            {state?.questionType !== "Open Question" && (
              <span
                className={`${
                  steps === 1
                    ? "text-ft5 font-ManropeBold text-black-100"
                    : "text-ft3 font-ManropeMedium text-black-75"
                }`}
              >
                {state?.questionType !== "Survey" ? "Options" : "Polls"}
              </span>
            )}
            <span
              className={`${
                steps === 2
                  ? "text-ft5 font-ManropeBold text-black-100"
                  : "text-ft3 font-ManropeMedium text-black-75"
              }`}
            >
              {" "}
              Settings
            </span>
          </div>
          {steps === 2 && (
            <span
              onClick={() => {
                if (state?.channels?.length > 0 && !!dateEnd && !!dateStart) {
                  setPreview(true);
                } else {
                  ShowToast({
                    message: "Please select channels and Active period",
                    type: toastType.ERROR,
                  });
                }
              }}
              className="text-orange-50 font-ManropeBold text-ft3 cursor-pointer"
            >
              Preview
            </span>
          )}
        </div>
        {renderStepComponent()}
      </div>
      {preview && (
        <PreviewPollPopup
          setPreview={setPreview}
          data={state}
          account={account}
          images={images}
        />
      )}
    </div>
  );
};

export default CreatePoll;
