import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as AddImage } from "../../../../../assets/Icons/paperclip.svg";
import ShowToast from "../../../../../common/showToast";
import { failureMessage, toastType } from "../../../../../constant/commonArray";
import Cross from "../../../../../assets/Icons/close-white.svg";

/**
 * Two-sided poll component for creating a poll with two sides, each side containing a title, description, and optional cover image.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setSteps - Function to set the current step of the poll creation process
 * @param {Object} props.state - Current state of the poll data
 * @param {Function} props.setState - Function to update the state of the poll data
 */
const TwoSidedPoll = ({ setSteps, state, setState }) => {
  const fileInputRefFirst = useRef(null);
  const fileInputRefSecond = useRef(null);
  const [errors, setErrors] = useState([
    { title: "", description: "" },
    { title: "", description: "" },
  ]);

  const { getInputProps: firstImage } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 50 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    /**
     * Handle file drop event.
     *
     * @param {Array<File>} acceptedFiles - Array of accepted files
     * @param {Array<Object>} fileRejections - Array of file rejections
     */

    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED_50MB,
          type: toastType.ERROR,
        });
      } else {
        setState((prev) => ({
          ...prev,
          answer: prev.answer.map((a, i) =>
            i === 0 ? { ...a, cover: acceptedFiles } : a
          ),
        }));
      }
    },
  });

  const { getInputProps: secondImage } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 5 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED,
          type: toastType.ERROR,
        });
      } else {
        setState((prev) => ({
          ...prev,
          answer: prev.answer.map((a, i) =>
            i === 1 ? { ...a, cover: acceptedFiles } : a
          ),
        }));
      }
    },
  });

  const handleSpanClickFirst = () => {
    fileInputRefFirst.current.click();
  };

  const handleDeleteClick = (index) => {
    setState((prev) => ({
      ...prev,
      answer: prev.answer.map((a, i) =>
        i === index ? { ...a, cover: [] } : a
      ),
    }));
  };

  const handleSpanClickSecond = () => {
    fileInputRefSecond.current.click();
  };

  return (
    <div className="mt-15 flex flex-col">
      <div className="flex gap-4 w-full">
        <div className="flex flex-col gap-1 pt-5 w-1/2">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            First Side
          </label>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-0.5">
              <input
                type="text"
                value={state?.answer?.[0]?.title || ""}
                onChange={(e) => {
                  if (e.target.value.length > 150) {
                    setErrors((prevErrors) => [
                      {
                        ...prevErrors[0],
                        title: "Title should be less than 150 characters",
                      },
                      prevErrors[1],
                    ]);
                  } else {
                    setErrors((prevErrors) => [
                      { ...prevErrors[0], title: "" },
                      prevErrors[1],
                    ]);
                  }
                  const updatedAnswer = [...state?.answer];
                  updatedAnswer[0] = {
                    ...updatedAnswer[0],
                    title: e.target.value,
                  };
                  setState((prev) => ({ ...prev, answer: updatedAnswer }));
                }}
                placeholder="First side title"
                className="rounded-full bg-grey-50 outline-none px-6 h-16.25 text-black-100 font-ManropeRegular text-ft3"
              />
              {errors[0]?.title && (
                <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                  {errors[0]?.title}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-0.5">
              <input
                type="text"
                placeholder="First side description"
                value={state?.answer?.[0]?.description || ""}
                onChange={(e) => {
                  if (e.target.value.length > 150) {
                    setErrors((prevErrors) => [
                      {
                        ...prevErrors[0],
                        description:
                          "Description should be less than 150 characters",
                      },
                      prevErrors[1],
                    ]);
                  } else {
                    setErrors((prevErrors) => [
                      { ...prevErrors[0], description: "" },
                      prevErrors[1],
                    ]);
                  }
                  const updatedAnswer = [...state?.answer];
                  updatedAnswer[0] = {
                    ...updatedAnswer[0],
                    description: e.target.value,
                  };
                  setState((prev) => ({ ...prev, answer: updatedAnswer }));
                }}
                className="rounded-full bg-grey-50 outline-none px-6 h-16.25 text-black-100 font-ManropeRegular text-ft3"
              />
              {errors[0]?.description && (
                <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                  {errors[0]?.description}
                </div>
              )}
            </div>
          </div>
          {state?.answer[0]?.cover?.length > 0 && (
            <div className="pt-5 pb-5 flex flex-col gap-5 ">
              <div className="flex rounded-20 gap-5 items-center py-3 w-41.25 h-41.25 relative">
                <img
                  src={
                    state?.answer[0]?.cover[0] instanceof File
                      ? URL.createObjectURL(state?.answer[0]?.cover[0])
                      : state?.answer[0]?.cover
                  }
                  alt="Preview"
                  className="w-41.25 h-41.25 rounded-20"
                />
                <div
                  onClick={() => handleDeleteClick(0)}
                  className="w-6 h-6 cursor-pointer rounded-full bg-black-50 p-0.5 absolute top-2 right-2"
                >
                  <img src={Cross} alt="check" />
                </div>
              </div>
            </div>
          )}
          {state?.answer[0]?.cover?.length <= 0 && (
            <div
              onClick={handleSpanClickFirst}
              className="flex gap-2.5 items-center cursor-pointer pt-5"
            >
              <AddImage />
              <span className="text-orange-50 font-ManropeBold text-ft2">
                Add Cover
              </span>
              <input
                {...firstImage()}
                key={state?.answer[0]?.cover.length}
                ref={fileInputRefFirst}
                type="file"
                className="hidden"
                accept=".svg, .png, .jpg"
              />
            </div>
          )}
        </div>
        {state?.reason?.length > 0 && (
          <div className="bg-red-75 rounded-xl p-5 h-max-content w-1/2 flex flex-col gap-1 text-ft1">
            <span className="text-ft2 font-ManropeBold text-black-100">
              Reason:
            </span>
            {state?.reason?.map((item, idx) => (
              <span
                key={idx}
                className="text-ft2 font-ManropeRegular text-black-100"
              >
                {item?.description}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-1 pt-5">
        <label
          htmlFor=""
          className="text-black-100 text-ft3 font-ManropeMedium"
        >
          Second Side
        </label>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-0.5">
            <input
              type="text"
              placeholder="Second side title"
              value={state?.answer?.[1]?.title || ""}
              onChange={(e) => {
                if (e.target.value.length > 150) {
                  setErrors((prevErrors) => [
                    prevErrors[0],
                    {
                      ...prevErrors[1],
                      title: "Title should be less than 150 characters",
                    },
                  ]);
                } else {
                  setErrors((prevErrors) => [
                    prevErrors[0],
                    { ...prevErrors[1], title: "" },
                  ]);
                }
                const updatedAnswer = [...state?.answer];
                updatedAnswer[1] = {
                  ...updatedAnswer[1],
                  title: e.target.value,
                };
                setState((prev) => ({
                  ...prev,
                  answer: updatedAnswer,
                }));
              }}
              className="w-1/2 rounded-full bg-grey-50 outline-none px-6 h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
            {errors[1]?.title && (
              <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                {errors[1]?.title}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-0.5">
            <input
              type="text"
              placeholder="Second side description"
              value={state?.answer?.[1]?.description || ""}
              onChange={(e) => {
                if (e.target.value.length > 150) {
                  setErrors((prevErrors) => [
                    prevErrors[0],
                    {
                      ...prevErrors[1],
                      description:
                        "Description should be less than 150 characters",
                    },
                  ]);
                } else {
                  setErrors((prevErrors) => [
                    prevErrors[0],
                    { ...prevErrors[1], description: "" },
                  ]);
                }
                const updatedAnswer = [...state?.answer];
                updatedAnswer[1] = {
                  ...updatedAnswer[1],
                  description: e.target.value,
                };
                setState((prev) => ({
                  ...prev,
                  answer: updatedAnswer,
                }));
              }}
              className="w-1/2 rounded-full bg-grey-50 outline-none px-6 h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
            {errors[1]?.description && (
              <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                {errors[1]?.description}
              </div>
            )}
          </div>
        </div>
        {state?.answer[1]?.cover.length > 0 && (
          <div className="pt-5 pb-5 flex flex-col gap-5 ">
            <div className="flex gap-5 items-center py-3 rounded-20 w-41.25 h-41.25 relative">
              <img
                src={
                  state?.answer[1]?.cover[0] instanceof File
                    ? URL.createObjectURL(state?.answer[1]?.cover[0])
                    : state?.answer[1]?.cover
                }
                alt="Preview"
                className="w-41.25 h-41.25 rounded-20"
              />
              <div
                onClick={() => handleDeleteClick(1)}
                className="w-6 h-6 cursor-pointer rounded-full bg-black-50 p-0.5 absolute top-2 right-2"
              >
                <img src={Cross} alt="check" />
              </div>
            </div>
          </div>
        )}
        {state?.answer[1]?.cover?.length <= 0 && (
          <div
            onClick={handleSpanClickSecond}
            className="flex gap-2.5 items-center cursor-pointer pt-5"
          >
            <AddImage />
            <span className="text-orange-50 font-ManropeBold text-ft2">
              Add Cover
            </span>
            <input
              {...secondImage()}
              key={state?.answer[1]?.cover?.length}
              ref={fileInputRefSecond}
              type="file"
              className="hidden"
              accept=".svg, .png, .jpg"
            />
          </div>
        )}
      </div>

      <div className="flex justify-end mt-15 gap-7.5">
        <button
          onClick={() => setSteps(0)}
          className="border border-black-100 border-opacity-20 font-ManropeBold rounded-full w-55 h-16.25 text-ft2"
        >
          Previous
        </button>
        <button
          disabled={
            errors?.some((item) => item?.title) ||
            errors?.some((item) => item?.description) ||
            state?.answer?.some((item) => !item?.title) ||
            state?.answer?.some((item) => !item?.description)
          }
          onClick={() => {
            setSteps(2);
          }}
          className={`${
            errors?.some((item) => item?.title) ||
            errors?.some((item) => item?.description) ||
            state?.answer?.some((item) => !item?.title) ||
            state?.answer?.some((item) => !item?.description)
              ? "bg-grey-50"
              : " bg-orange-50"
          } rounded-full w-55 h-16.25 font-ManropeBold text-ft2`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TwoSidedPoll;
