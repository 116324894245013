/**
 * @module DetailAds
 * @description
 * Module for managing and rendering DetailAds.
 *
 * @example
 * // Usage in a React functional component
 * import DetailAds from "./DetailAds";
 *
 * const MyComponent = () => {
 *   return <DetailAds />;
 * };
 *
 * @returns {React.Component} The DetailAds component
 */

import React, { useEffect, useRef, useState } from "react";
import pause from "../../assets/Icons/pause.svg";
import error from "../../assets/Icons/Error.svg";
import WarningPopup from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import { useParams, useHistory } from "react-router-dom";
import { UserService } from "../../services";
import { toastType } from "../../constant/commonArray";
import { CircularProgress } from "@mui/material";
import tick from "../../assets/Icons/Tick.svg";
import time from "../../assets/Icons/Time.svg";
import utility from "../../utility";
import SuspendedUserPopUp from "../DashboardPage/details/SuspendedUserPopUp";
import PreviewPopUp from "../../common/previewPopUp";
import OutsideClickRef from "../../common/outsideClickRef";
import DetailsAds from "../../common/detailsAds";
import UserEntityService from "../../services/userEntityService";
import { useSelector } from "react-redux";
/**
 * DetailAds component function that handles various user interactions and data fetching.
 *
 * @return {JSX.Element} The rendered JSX element of the DetailAds component
 */
const DetailAds = () => {
  const [isStopPopUp, setStopPopUp] = useState(false);
  const [isDeletePopUp, setDeletePopUp] = useState(false);
  const [isUserExpanded, setUserExpanded] = useState(false);
  const [details, setDetails] = useState([]);
  const [previewPopUp, setPreviewPopUp] = useState(false);
  const history = useHistory();
  const [userCount, setUserCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const optionsRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [selectedItem, setSelectedItem] = useState({
    status: "",
    popUp: false,
    name: "",
  });
  const [popUpVisible, setPopUpVisible] = useState({
    popUp: false,
    keyName: "",
  });

  const [seenActivatePopUp, setSeenActivatePopUp] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [suspendType, setSuspendType] = useState("");
  const { id } = useParams();

  /**
   * Toggles the visibility of a pop-up and sets a key name.
   *
   * @param {type} value - the value to set as the key name
   * @return {type} undefined
   */
  const togglePopUpVisble = (value) => {
    setPopUpVisible((prev) => ({
      ...prev,
      popUp: !popUpVisible.popUp,
      keyName: value,
    }));
    setUserExpanded(false);
  };
  /**
   * Renders profile dots based on the status of the user.
   *
   * @param {string} status - the status of the user
   * @return {JSX.Element} the profile dots component
   */
  const renderProfileDots = (status) => {
    return status === "ACTIVE" && isUserExpanded ? (
      <OutsideClickRef
        state={optionsRef}
        setState={() => setUserExpanded(false)}
      >
        {userPermissions?.indexOf("business:write:suspend") > -1 && (
          <div
            onClick={() => togglePopUpVisble("suspend")}
            className="absolute right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-10 bg-white cursor-pointer"
          >
            <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
              <img src={pause} alt="Pause" />
              <p className="text-ft3 font-ManropeLight text-black-100">
                Suspend
              </p>
            </button>
          </div>
        )}
      </OutsideClickRef>
    ) : (
      isUserExpanded && status === "SUSPENDED" && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setUserExpanded(false)}
        >
          <div className="absolute flex flex-col right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-10 bg-white">
            {userPermissions?.indexOf("business:write:activate") > -1 && (
              <button
                onClick={() => setSeenActivatePopUp(!seenActivatePopUp)}
                className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
              >
                <img src={tick} alt="tick" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  Activate
                </p>
              </button>
            )}
            {userPermissions?.indexOf("business:write:suspend") > -1 && (
              <button
                className="flex h-16.25 px-5 items-center gap-3.75 shadow-custom"
                onClick={() => togglePopUpVisble("edit")}
              >
                <img src={time} alt="time" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  Edit Suspended time
                </p>
              </button>
            )}
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Asynchronously retrieves details and updates state.
   *
   */
  const getDetails = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().adsDetails(id)
      );
      if (error || !response) return;
      setDetails(response[0]);
      getUserCount(response[0]);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  /**
   * Handles the click event for displaying more information about the item.
   *
   * @param {Object} item - The item to display more information for
   * @return {void}
   */
  const handleMoreClick = (item) => {
    if (selectedItem?.popUp) {
      setSelectedItem({ popUp: false });
    } else {
      setSelectedItem({
        status: item?.status,
        popUp: true,
        name: item?.name,
      });
    }
  };

  /**
   * Asynchronously handles stopping an ad.
   *
   * @param {type} id - the id of the ad to be stopped
   * @return {type} description of return value
   */
  const handleStopAd = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().stopAd(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setSelectedItem({
        popUp: false,
        status: "",
        name: "",
      });
      setStopPopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Asynchronously handles the deletion of an ad.
   *
   * @param {type} id - The unique identifier of the ad to be deleted
   * @return {type} undefined
   */
  const handleDeleteAd = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deleteAd(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        history.push("/advertising");
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setSelectedItem({
        popUp: false,
        status: "",
        name: "",
      });
      setDeletePopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Asynchronously handles duplicate ads by parsing the response and showing a toast message.
   *
   * @param {type} id - description of parameter
   * @return {type} undefined
   */
  const handleDuplicatAds = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().duplicateAd(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setSelectedItem({
        popUp: false,
        status: "",
        name: "",
      });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Asynchronously handles user activation.
   *
   * @param {type} id - The user ID to activate
   * @return {type} undefined
   */
  const handleActivateUser = async (id) => {
    let response;
    setUpdatingUser(true);
    try {
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getDetails();
      setSeenActivatePopUp(false);
      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };

  /**
   * Asynchronously retrieves user count based on provided audience reach data.
   *
   * @param {Object} data - The audience reach data object
   * @return {undefined} No return value
   */
  const getUserCount = async (data) => {
    try {
      let requestData = {
        raceOrEthnicity:
          data?.audienceReach?.race?.length > 0
            ? data?.audienceReach?.race
            : undefined,
        audience:
          data?.audienceReach?.audience?.length > 0
            ? data?.audienceReach?.audience
            : undefined,
        gender:
          data?.audienceReach?.gender?.length > 0
            ? data?.audienceReach?.gender
            : undefined,
        verifiedUsers:
          data?.audienceReach?.verifiedUsers?.length > 0
            ? data?.audienceReach?.verifiedUsers
            : undefined,
        location:
          data?.audienceReach?.location?.length > 0
            ? data?.audienceReach?.location
            : undefined,
        age:
          data?.audienceReach?.age?.length > 0
            ? data?.audienceReach?.age?.map((range) => {
                const [min, max] = range.split(/-|and/);
                if (max === " older") return { min: parseInt(min), max: 400 };
                return { min: parseInt(min), max: parseInt(max) };
              })
            : undefined,
      };

      const [error, response] = await utility.parseResponse(
        new UserEntityService().getUserCount(requestData)
      );

      if (error || !response) return;
      setUserCount(response?.totalRespondents);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const suspend = utility.getSuspensionType(
      details?.account?.suspension?.type,
      details?.account?.suspension?.period?.startDate,
      details?.account?.suspension?.period?.endDate
    );
    setSuspendType(suspend);
  }, [details]);

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div className="flex w-full overflow-y-auto mt-5 mb-5 pr-12.5 gap-5 bg-grey-50">
      <DetailsAds
        details={details}
        error={error}
        id={id}
        isUserExpanded={isUserExpanded}
        renderProfileDots={renderProfileDots}
        selectedItem={selectedItem}
        setDeletePopUp={setDeletePopUp}
        setStopPopUp={setStopPopUp}
        handleDuplicate={handleDuplicatAds}
        setPreviewPopUp={setPreviewPopUp}
        setSelectedItem={setSelectedItem}
        handleMoreClick={handleMoreClick}
        setUserExpanded={setUserExpanded}
        userCount={userCount}
        permissionDuplicate={"advertising:write:duplicate"}
        permissionStop={"advertising:write:stop"}
        permissionDelete={"advertising:write:delete"}
      />
      {isDeletePopUp && (
        <WarningPopup
          title={`Delete ${selectedItem?.name}`}
          description={"Are you sure? You want to delete Ad"}
          handleActivateUser={() => handleDeleteAd(id)}
          setToggle={setDeletePopUp}
        />
      )}
      {isStopPopUp && (
        <WarningPopup
          title={`Stop ${selectedItem?.name}`}
          description={"Are you sure? You want to stop Ad"}
          handleActivateUser={() => handleStopAd(id)}
          setToggle={setStopPopUp}
        />
      )}
      {seenActivatePopUp && (
        <WarningPopup
          handleActivateUser={() =>
            handleActivateUser(details?.account?.accountId)
          }
          setToggle={() => setSeenActivatePopUp(false)}
          title={`Activate ${details?.account?.fullName} ?`}
          description={
            "User will be activated. All created content will be available for other users"
          }
          text={`${details?.account?.fullName} activated`}
          loading={updatingUser}
        />
      )}

      {popUpVisible.popUp && popUpVisible.keyName === "suspend" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={details?.account?.userName}
          id={details?.account?.accountId}
          getdetails={getDetails}
        />
      )}
      {popUpVisible.popUp && popUpVisible.keyName === "edit" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={details?.account?.userName}
          id={details?.account?.accountId}
          type={suspendType}
          keyName={popUpVisible.keyName}
          endDate={details?.account?.suspension?.period?.endDate}
          getdetails={getDetails}
          reasonType={details?.account?.suspension?.reason}
          name={details?.fullName}
          setActivatePopUp={setSeenActivatePopUp}
        />
      )}
      {previewPopUp && (
        <PreviewPopUp
          previewPopUp={previewPopUp}
          setPreviewPopUp={setPreviewPopUp}
          state={details}
          croppedImage={details?.media[0]?.url}
          account={details?.account}
        />
      )}
    </div>
  );
};

export default DetailAds;
