import React, { useEffect, useState } from "react";
import close from "../../assets/Icons/close.svg";
import chevrondown from "../../assets/Icons/Chevrondown.svg";
import chevronUp from "../../assets/Icons/chevronUp.svg";
import ShowToast from "../../common/showToast";
import SelectImagePopup from "../entityAccount/createEntity/selectImagePopup";
import { FileUploadService, UserService } from "../../services";
import utility from "../../utility";
import { eventConstants, toastType } from "../../constant/commonArray";
import contactProfile from "../../assets/Icons/contactProfile.svg";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch } from "react-redux";
import { CircularProgress, Popover } from "@mui/material";
import PhoneNumberDropdown from "../../common/component/phoneNumberDropdown";
import parsePhoneNumberFromString from "libphonenumber-js";

/**
 * Component for creating a new staff member.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setIsPopupType - Function to set the type of popup
 * @param {Function} props.getStaffList - Function to fetch the updated staff list
 * @returns {JSX.Element} - CreateNewStaffComponent
 */
const numberLengthRegex = new RegExp(/^\d{0,15}$/);
const numberRegex = new RegExp(
  /^(?!.*(\d)\1{6})(?!.*1234567890)(?!.*9876543210).*$/
);
const CreateNewStaffComponent = ({ setIsPopupType, getStaffList }) => {
  const [timeSuspendDropDown, setTimeSuspendDropDown] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploadProfile, setUploadProfile] = useState(null);
  const [creatingStaff, setCreatingStaff] = useState(false);
  const [file, setFile] = useState({});
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [debounceValue, setDebounceValue] = useState("");
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    name: "",
    roleId: "",
    countryCode: "+1",
    phone: "",
  });

  const [roleList, setRoleList] = useState([]);
  const [error, setError] = useState({});
  const [selectedRole, setSelectedRole] = useState({
    name: "",
    id: "",
  });

  /**
   * Handles the creation of a new staff member.
   *
   * @async
   * @function
   * @param {string} name - The name of the new staff member
   * @returns {void}
   */
  const handleStaffCreate = async (name) => {
    setCreatingStaff(true);
    try {
      let cdnUrl;
      if (uploadProfile) {
        const formData = new FormData();
        formData.append("files", uploadProfile);

        const [err, res] = await utility.parseResponse(
          new FileUploadService().fileUpload(formData)
        );

        if (err || !res) return;
        cdnUrl = res?.responseData[0]?.cdnUrl;
      }
      const { isValidEmail, isValidName, ...rest } = state;
      const requestData = {
        ...rest,
        profilePhoto: cdnUrl,
        roleId: selectedRole?.id,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().createStaff(requestData)
      );

      if (error || !response?.responseData) return;
      if (response?.success) {
        ShowToast({
          message: name + " added to staff",
          type: toastType.SUCCESS,
        });
        setIsPopupType("");
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreatingStaff(false);
      getStaffList();
    } catch (e) {
      console.error(e);
      setCreatingStaff(false);
    } finally {
      setCreatingStaff(false);
    }
  };

  /**
   * Fetches the list of roles.
   *
   * @async
   * @function
   * @returns {void}
   */
  const getRoleList = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getRoleList()
      );

      if (error || !response) return;
      const newRole = {
        role: "Add new role",
      };
      const updatedRoleList = [...(response || []), newRole];
      setRoleList(updatedRoleList);
      setSelectedRole({
        name: updatedRoleList[0]?.role,
        id: updatedRoleList[0]?._id,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 800);

  /**
   * Checks the availability of the entered email.
   *
   * @async
   * @function
   * @returns {void}
   */
  const checkEmailAvailable = async () => {
    const requestData = {
      email: debounceValue,
    };
    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      let [error, email] = await utility.parseResponse(
        new UserService().checkEmail(requestData)
      );
      if (error || !email) {
        setIsEmailAvailable(false);
      } else {
        if (email?.available) {
          setIsEmailAvailable(true);
        } else {
          setIsEmailAvailable(false);
        }
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  /**
   * Handles changes in the email input.
   *
   * @param {Object} e - The event object
   * @returns {void}
   */
  const handleEmail = (e) => {
    const emailValue = e.target.value;
    const isValidEmail = utility.validateEmail(emailValue);
    if (isValidEmail) {
      setError((prev) => ({ ...prev, emailError: false }));
    } else {
      setError((prev) => ({ ...prev, emailError: true }));
    }
    setState((prev) => ({ ...prev, email: emailValue }));
    debouncedSearch(emailValue);
  };

  const [rolePopoverAnchorEl, setRolePopoverAnchorEl] = useState(null);

  /**
   * Opens the role selection popover.
   *
   * @param {Object} event - The event object
   * @returns {void}
   */
  const handleRolePopoverOpen = (event) => {
    setRolePopoverAnchorEl(event.currentTarget);
    setTimeSuspendDropDown(!timeSuspendDropDown);
  };

  const handleRolePopoverClose = () => {
    setRolePopoverAnchorEl(null);
  };

  const rolePopoverOpen = Boolean(rolePopoverAnchorEl);
  const rolePopoverId = rolePopoverOpen ? "role-popover" : undefined;

  const handleCountryCode = (countryCode) => {
    setState((prev) => ({
      ...prev,
      countryCode: countryCode,
    }));
  };

  const isValidPhoneNumber = (phoneNumber, countryCode) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      countryCode
    );
    return parsedPhoneNumber && parsedPhoneNumber.isValid();
  };
  function validatePhoneNumber(phoneNumber) {
    return numberRegex.test(phoneNumber);
  }

  useEffect(() => {
    if (debounceValue && utility.validateEmail(debounceValue))
      checkEmailAvailable();
    // eslint-disable-next-line
  }, [debounceValue]);

  useEffect(() => {
    getRoleList();
  }, []);

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center">
      <div className="flex flex-col bg-white rounded-20 shadow-md w-112.5 h-155 p-7.5">
        <div className="flex w-full justify-between items-center mb-5">
          <span className="font-ManropeBold text-ft4 text-black-100">
            Create new staff
          </span>
          <img
            src={close}
            alt="close"
            className="cursor-pointer"
            onClick={() => setIsPopupType("")}
          />
        </div>
        <div>
          {!croppedImage ? (
            <div>
              <img
                src={contactProfile}
                onClick={() => setImagePopup(true)}
                alt="contactProfile"
                className="flex mx-auto rounded-full bg-black-100 w-35per h-25per mb-7.5 cursor-pointer"
              />
            </div>
          ) : (
            <div>
              <img
                src={croppedImage}
                alt=""
                className="flex mx-auto rounded-full bg-black-100 w-35per h-25per mb-7.5"
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-7.5 overflow-y-auto scrollbar-hide max-h-43per">
          <div className="flex flex-col">
            <p className="font-ManropeMedium text-ft3 text-black-100">Role</p>
            <div
              className="flex h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50 justify-between items-center relative cursor-pointer"
              onClick={(event) => {
                handleRolePopoverOpen(event);
              }}
            >
              <div className="flex w-full justify-between items-center relative cursor-pointer">
                <p className="font-ManropeRegular text-ft3 text-black-100">
                  {utility.capitalizeFirstLetter(selectedRole.name)}
                </p>
                <img
                  src={timeSuspendDropDown ? chevronUp : chevrondown}
                  alt="chevrondown"
                  className="cursor-pointer"
                />
              </div>
              <Popover
                id={rolePopoverId}
                open={timeSuspendDropDown}
                anchorEl={rolePopoverAnchorEl}
                onClose={handleRolePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ height: "30%" }}
              >
                {roleList?.map((roleOption) => (
                  <div key={roleOption._id || 1}>
                    <div
                      className={`w-97.5 py-4 px-4 cursor-pointer font-ManropeRegular text-ft3 ${
                        roleOption.role === "Add new role"
                          ? "text-orange-50"
                          : "text-black-100"
                      }`}
                      onClick={() => {
                        if (roleOption.role !== "Add new role") {
                          setSelectedRole({
                            name: roleOption?.role,
                            id: roleOption?._id,
                          });
                          setTimeSuspendDropDown(false);
                        } else {
                          setIsPopupType("ROLE");
                        }
                      }}
                    >
                      {utility.capitalizeFirstLetter(roleOption.role)}
                    </div>
                    <hr
                      className={`${
                        roleOption.role !== "Add new role"
                          ? "text-grey-100"
                          : "opacity-0"
                      }`}
                    />
                  </div>
                ))}
              </Popover>
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="Name"
              className="font-ManropeMedium text-ft3 text-black-100"
            >
              Name
            </label>
            <input
              type="text"
              id="Name"
              value={state?.name}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 50) {
                  setError((prev) => ({ ...prev, nameError: false }));
                } else {
                  setError((prev) => ({ ...prev, nameError: true }));
                }
                setState((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              className="h-16.25 px-6.25 rounded-full bg-grey-50 focus:outline-none"
            />
            {error?.nameError && (
              <span className=" text-red-50">Name can't be more than 50</span>
            )}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="Email"
              className="font-ManropeMedium text-ft3 text-black-100"
            >
              Email
            </label>
            <input
              type="email"
              id="Email"
              value={state?.email}
              onChange={(e) => handleEmail(e)}
              className="h-16.25 px-6.25 rounded-full bg-grey-50 focus:outline-none"
            />
            {!isEmailAvailable && (
              <span className=" text-red-50">Email is already taken</span>
            )}
            {error?.emailError && (
              <span className=" text-red-50">Please enter a valid email</span>
            )}
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Phone number
              </label>
              <div className="rounded-full bg-grey-50  px-6 h-16.25 flex items-center">
                <div className="flex gap-4 items-center">
                  <PhoneNumberDropdown
                    setState={handleCountryCode}
                    state={state?.countryCode}
                  />
                  <input
                    type="text"
                    value={state?.phone}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (numberLengthRegex.test(inputValue)) {
                        setState((prev) => ({
                          ...prev,
                          phone: inputValue,
                        }));
                      }
                    }}
                    className="focus:outline-none bg-grey-50"
                  />
                </div>
              </div>
            </div>

            {(!isValidPhoneNumber(
              `${state?.countryCode}${parseInt(state?.phone)}`,
              state?.countryCode
            ) ||
              !validatePhoneNumber(state?.phone)) &&
              state?.phone !== "" && (
                <span className=" text-red-50">
                  Please enter the valid phone number
                </span>
              )}
          </div>
        </div>
        <button
          onClick={() => handleStaffCreate(state.name)}
          disabled={
            !state?.email ||
            !state.name ||
            !state?.phone ||
            !selectedRole?.id ||
            creatingStaff ||
            state?.name?.trim() === "" ||
            error?.nameError ||
            !isValidPhoneNumber(
              `${state?.countryCode}${parseInt(state?.phone)}`,
              state?.countryCode
            ) ||
            !validatePhoneNumber(state?.phone) ||
            error?.emailError ||
            !isEmailAvailable
          }
          className={`h-16.25 px-6.25 rounded-full ${
            state?.email &&
            state.name &&
            state?.phone &&
            selectedRole?.id &&
            !creatingStaff &&
            isValidPhoneNumber(
              `${state?.countryCode}${parseInt(state?.phone)}`,
              state?.countryCode
            ) &&
            validatePhoneNumber(state?.phone) &&
            !error?.nameError &&
            state?.name?.trim() !== "" &&
            !error?.emailError &&
            isEmailAvailable
              ? "bg-orange-50"
              : "bg-grey-50"
          }  mt-7.5 text-black-100 font-ManropeBold text-ft2`}
        >
          {creatingStaff ? (
            <CircularProgress className="text-orange-50" />
          ) : (
            "Create"
          )}
        </button>
      </div>
      {imagePopup && (
        <SelectImagePopup
          setImagePopup={setImagePopup}
          setCroppedImage={setCroppedImage}
          croppedImage={croppedImage}
          setFile={setFile}
          file={file}
          edit={"add"}
          setUploadProfile={setUploadProfile}
        />
      )}
    </div>
  );
};

export default CreateNewStaffComponent;
