import React, { useEffect } from "react";

/**
 * OutsideClickRef component handles clicks outside of a specified element.
 * @component
 * @param {Object} props - The properties object.
 * @param {React.RefObject} props.state - A reference to the element to detect outside clicks for.
 * @param {Function} props.setState - The function to update the state when an outside click is detected.
 * @param {React.ReactNode} props.children - The children elements to be rendered inside the component.
 * @param {string} [props.className] - The class name to be applied to the component.
 * @param {string} [props.classContain=null] - A class name prefix to exclude from outside click detection.
 * @returns {JSX.Element} The OutsideClickRef component.
 */

const OutsideClickRef = ({
  state,
  setState,
  children,
  className,
  classContain = null,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        state.current &&
        !state.current.contains(event.target) &&
        !event.target.classList.value.startsWith(classContain)
      ) {
        setState(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div className={className} ref={state}>
      {children}
    </div>
  );
};

export default OutsideClickRef;
