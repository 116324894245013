export const optionsStaff = ["Company staff"];

export const optionsRole = ["Account Owner", "Manager", "Other"];
export const optionsPosition = [
  {
    department: "Executive Leadership",
    position: ["CMO (Chief Marketing Officer)"],
  },
  {
    department: "Management & Supervision",
    position: ["Marketing Manager", "Project Manager"],
  },
  {
    department: "Administrative & Support",
    position: ["Customer Support Representative"],
  },
  {
    department: "Sales & Marketing",
    position: [
      "Marketing Coordinator",
      "Digital Marketing Specialist",
      "Content Writer",
      "Social Media Manager",
    ],
  },
  {
    department: "Information Technology (IT)",
    position: ["Systems Analyst", "IT Support Specialist", "Web Developer"],
  },
];

export const toastType = {
  SUCCESS: "success",
  ERROR: "error",
};

export const httpConstants = {
  METHOD_TYPE: {
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
    PATCH: "PATCH",
  },
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png",
  },
  DEVICE_TYPE: {
    WEB: "web",
  },
  API_END_POINT: {
    GET_CLAIM_DETAILS_BY_ID: "/claimById",
    GET_ALL_CLAIMS: "/claims",
    USER_LIST: "user/list",
    USER_DETAILS: "user/details",
    POST_LIST: "account/post/list",
    POLL_DETAILS: "account/post/details",
    USER_EDIT: "user/edit",
    ACOOUNT_DISABLE_POST: "account/post/disable",
    ACTIVATE_ACCOUNT: "account/activate",
    SUSPEND_USER: "account/suspend",
    EDIT_SUSPEND_TIME: "account/suspendtime/edit",
    ACCOUNT_COMMENT_LIST: "account/comment/list",
    ACCOUNT_LIKES: "account/post/likes",
    ACCOUNT_VOTES: "account/post/votes",
    ACCOUNT_REPOST: "account/post/reposts",
    ACCOUNT_COMMENT: "account/post/comments",
    DISABLE_COMMENT: "account/comment/disable",
    ENTITY_LIST: "entity/list",
    ENTITY_DETAILS: "entity/detalis",
    EMPLOYEE_LIST: "entity/employee/list",
    ADD_EMPLOYEE: "entity/employee/add",
    ENTITY_EDIT: "entity/edit",
    DELETE_EMPLOYEE: "entity/employee/delete",
    EDIT_EMPLOYEE: "entity/employee/edit",
    UPLOAD_FILES: "file-upload",
    GET_REQUEST: "request/list",
    GET_BLUETICK_REQUEST: "request/bluetick/details",
    GET_ENTITY_REQUEST: "request/entity/details",
    VOILATION_LIST: "violations/list",
    CREATE_ENTITY: "entity/create",
    VIOLATION_DETAILS: "violation/details",
    REJECT_VIOLATION: "violation/reject",
    REJECT_REQUEST: "request/reject",
    APPROVE_BLUETICK: "request/bluetick/approve",
    GET_JOB_LIST: "dept/job/list",
    GENERATE_OTP: "generate-otp",
    GET_AD: "advt/list",
    VERIFY_OTP: "verify-otp",
    RESET_PASSWORD: "staff/forgot-password",
    DUPLICATE_AD: "advt/duplicate",
    STOP_AD: "advt/stop",
    DELETE_AD: "advt/delete",
    ADS_DETAILS: "advt/details",
    CREATE_AD: "advt/create",
    GET_LOCATION: "account/location/list",
    CHECK_USERNAME: "user/check/username",
    EMAIL_CHECK: "entity/email/check",
    PROMOTION_LIST: "promotion/list",
    DUPLICATE_PROMOTION: "promotion/duplicate",
    STOP_PROMOTION: "promotion/stop",
    DELETE_PROMOTION: "promotion/delete",
    PROMOTION_DETAILS: "promotion/details",
    STAFF_LIST: "staff/list",
    BLOCK_STAFF: "staff/block",
    ACTIVATE_STAFF: "staff/activate",
    ROLE_LIST: "role/list",
    ADD_STAFF: "staff/add",
    DELETE_ROLE: "role/delete",
    GET_ADDITIONAL_LIST: "field/list",
    DELETE_FIELD: "field/delete",
    FIELD_CREATE: "field/create",
    PROMOTION_CREATE: "promotion/create",
    CHANNEL_LIST: "channel/list",
    DELETE_CHANNEL: "channel/delete",
    DELETE_CHANNEL_GROUP: "channel/group/delete",
    CHECK_CHANNEL_NAME: "channel/name/check",
    GROUP_LIST: "channel/group/list",
    CHANNEL_CREATE: "channel/create",
    EDIT_CHANNEL: "channel/update",
    CHECK_GROUP_NAME: "channel/group/name/check",
    CREATE_GROUP: "channel/group/create",
    EDIT_GROUP: "channel/group/update",
    DELETE_JOB: "dept/job/delete",
    DELETE_DEPT: "dept/delete",
    DEPT_LIST: "dept/list",
    CHECK_DEPT_NAME: "dept/name/check",
    DEPT_CREATE: "dept/create",
    CHECK_POSITION: "dept/job/name/check",
    CREATE_JOB: "dept/job/create",
    EDIT_DEPT: "dept/update",
    EDIT_POSITION: "dept/job/update",
    STAFF_DETAILS: "staff/details",
    UPDATE_STAFF: "staff/update",
    CHECK_STAFF: "staff/check",
    ADD_ROLE: "role/add",
    UPDATE_ROLE: "role/update",
    CHANNEL_ICON_LIST: "channel/group/icon",
    CREATE_NEW_ICON: "channel/group/icon",
    STATISTICS: "platform/statistics",
    INCOME_SPLIT_LIST: "price-distribution/list",
    EDIT_INCOME_PRICE: "price-distribution/update",
    USER_COUNT: "user/count",
    UPDATE_POSITION: "position",
    POLL_DETAIL_MANAGEMENT: "post/details/admin",
    POLL_APPROVED: "post/approval",
    POLL_LIST: "admins/post/list",
    CREATE_POLL: "post/poll",
    LINK_PREVIEW: "link/preview",
    ACTIVITY_LIST: "points/activity/list",
    EDIT_ACTIVITY_POINT: "points/activity/update",
    INTEREST_LIST: "points/interest/list",
    EDIT_INTEREST_POINT: "points/interest/update",
    STAFF_LOGIN: "staff/login",
    POST_SURVEY: "post/survey",
    EDIT_POLL: "post/poll/edit",
    EDIT_SURVEY: "post/survey/edit",
    USER_MENTIONS: "user/mention",
    VIDEO_COMPRESS: "compress-video",
    HASHTAG: "hashtags",
    TOKEN_MANAGMENT: "token-management",
    UPDATE_B_VALUE: "update-beta",
    TOKEN_LIST: "tokens/list",
    R_HISTORY: "r-history",
    AUDIT_LOGS: "admin/logs",
    AUDIT_FILTERS: "admin/logs/filters",
    WALLET: "wallet",
    GET_BALANCE: "balance",
    COMPRESS_AND_UPLOAD: "upload-and-compress-video",
    R_CALCULATE: "calculate-r",
    MINT_TRANSFER: "mint-transfer",
    TOKEN_TRANFER: "transfer-tokens",
    APPLE_PRODUCT: "admin/apple/product",
    GOOGLE_PRODUCT: "admin/google/product",
  },
};

export const keyTypeConstants = {
  AUTH0_ID_TOKEN: "AUTH0_ID_TOKEN",
  USER: "user",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  USER_INFO: "USER_INFO",
  SHOW_POLL: "SHOW_POLL",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  ADMIN_PENDING: "ADMIN_PENDING",
  ADMIN_REJECT: "ADMIN_REJECT",
  POLL_DETAILS: "POLL_DETAILS",
};

export const requestType = {
  USER: "USER",
  BUSINESS: "BUSINESS",
  ORGANIZATION: "ORGANIZATION",
  GOVERNMENT: "GOVERNMENT",
};

export const AuditEnum = {
  USER_DETAILS: "USER_DETAILS",
  ENTITY_DETAILS: "ENTITY_DETAILS",
  MODERATION_POLL: "MODERATION_POLL",
  MODERATION_COMMENT: "MODERATION_COMMENT",
  MODERATION_USER: "MODERATION_USER",
  REQUEST_USER: "REQUEST_USER",
  REQUEST_ENTITY: "REQUEST_ENTITY",
  ADDITIONAL_LIST: "ADDITIONAL_LIST",
  CHANNEL_LIST: "CHANNEL_LIST",
  ROLE_LIST: "ROLE_LIST",
  INCOME_LIST: "INCOME_LIST",
  EMPLOYEE_LIST: "EMPLOYEE_LIST",
  ACTIVITY_LIST: "ACTIVITY_LIST",
  INTEREST_LIST: "INTEREST_LIST",
  POLL_DETAILS: "POLL_DETAILS",
  STAFF_LIST: "STAFF_LIST",
  PROFILE: "PROFILE",
};

export const reason = [
  "Spam",
  "Nudity",
  "Minor safety",
  "Bullying and harassment",
  "Violence and graphic content",
  "Illegal activities",
  "Hate speech",
  "False information",
  "Suicide, eating disorder, self-harm",
  "Impersonation",
  "Intellectual Property Violation",
];

const currentDate = new Date();

export const timeSuspend = [
  {
    label: "1 day",
    startDate: currentDate.getTime(),
    endDate: currentDate.getTime() + 24 * 60 * 60 * 1000,
  },
  {
    label: "2 day",
    startDate: currentDate.getTime(),
    endDate: currentDate.getTime() + 24 * 2 * 60 * 60 * 1000,
  },
  {
    label: "1 week",
    startDate: currentDate.getTime(),
    endDate: currentDate.getTime() + 7 * 24 * 60 * 60 * 1000,
  },
  {
    label: "Permanent",
    startDate: currentDate.getTime(),
    endDate: 0,
  },
];

export const reasonDisablePost = [
  "Spam",
  "Nudity",
  "Minor safety",
  "Bullying and harassment",
  "Violence and graphic content",
  "Illegal activities",
  "Hate speech",
  "False information",
  "Suicide, eating disorder, self-harm",
  "Impersonation",
  "Intellectual Property Violation",
];

export const rewards = [
  {
    Name: "Name of Ad 1",
    Status: "Pending",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 2",
    Status: "Active",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 3",
    Status: "Ended",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 4",
    Status: "Pending",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 5",
    Status: "Ended",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 6",
    Status: "Apple",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 7",
    Status: "Active",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 8",
    Status: "Ended",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 9",
    Status: "Pending",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 10",
    Status: "Active",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 11",
    Status: "Ended",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
  {
    Name: "Name of Ad 12",
    Status: "Pending",
    Created: 1700551381,
    Start: 1700551381,
    End: 1700551381,
  },
];

export const roles = [
  {
    Role: "Super Admin",
    Access:
      "User management, Business management , Report management,  Request management,  Platform parameters, Staff management, Statistics",
    Staff: 10,
  },
  {
    Role: "Admin",
    Access:
      "User management, Business management , Report management,  Request management,  Platform parameters, Staff management, Statistics",
    Staff: 32,
  },
  {
    Role: "Moderator",
    Access:
      "User management, Business management , Report management,  Request management,  Platform parameters, Staff management, Statistics",
    Staff: 32,
  },
  {
    Role: "Poll Creator",
    Access: "Business management (Create Polls, Delete Polls)",
    Staff: 134,
  },
  {
    Role: "Name for new role",
    Access: "Business management (Create Polls, Delete Polls)",
    Staff: 0,
  },
];

export const Languages = [
  {
    id: 1,
    name: "English",
  },
  {
    id: 2,
    name: "Spanish",
  },
  {
    id: 3,
    name: "Bulgarian",
  },
  {
    id: 4,
    name: "German",
  },
  {
    id: 5,
    name: "Mandarin",
  },
  {
    id: 6,
    name: "Hindi",
  },
  {
    id: 7,
    name: "French",
  },
  {
    id: 8,
    name: "Arabic",
  },
  {
    id: 9,
    name: "Russian",
  },
];

export const race = [
  "Asian",
  "Black or African American",
  "Native American or Alaska Native",
  "Native Hawaiian or Pacific Islander",
  "Hispanic or Latino",
  "Middle Eastern or Arab",
  "White",
  "Other",
];
export const failureMessage = {
  ENTER_VALID_EMAIL: "Please enter valid email",
  INCORRECT_OTP: "The code you’ve entered is incorrect",
  INVALID_OTP: "Invalid OTP",
  FILE_TYPE_ERROR: "Unsupported file format. Supported formats: JPG, PNG, JPEG",
  FILE_TYPE_ERROR_ENTITY:
    "Unsupported file format. Supported formats: JPG, PNG, JPEG, DOCX, PDF",
  FILE_TYPE_ERROR_AUDIO: "Unsupported file format. Supported formats: MP3",
  FILE_TYPE_ERROR_MEDIA:
    "Unsupported file format. Supported formats: JPG, PNG, JPEG and MP4 ",
  FILE_SIZE_EXCEEDED: "Maximum image size exceeded",
  FILE_MEDIA_SIZE_EXCEEDED: "Maximum media size exceeded",
  FILE_SIZE_EXCEEDED_50MB: "Maximum file size exceeded",
  CANT_DELETE_ROLE: "You can't delete 'Super Admin' role",
  CANT_DELETE_STAFF: "You can't delete a role with staff",
  YOU_DONT_HAVE_ACCESS: "You don't have access",
  CURRENT_PASS_CANT_SAME: "New password cannot be same as current password",
};
export const successMessage = {
  OTP_SUCCESSFULLY_VERIFIED: "OTP verified successfully",
  PASSWORD_RESET_MAIL_SEND: "Password reset email sent on your email",
  USER_UPDATED: "User update successfully",
  COMMENT_DISABLED: "Comment disabled",
  POST_DISABLED: "Post disabled",
};
export const eventConstants = {
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_LOADER: "SHOW_LOADER",
};
export const cookiesConstants = {
  VALIDATE_EMAIL: "isValidEmail",
  OTP_TYPE_NUMBER: "optCount",
  DISABLE_OTP: "disableOtp",
  TRIED_EMAILS: "triedEmails",
};

export const subOptions = {
  settings: [
    {
      label: "Additional fields",
      image: require("../assets/Icons/Add.svg"),
      path: "/platform-parameters",
      permission: "field:read:list",
    },
    {
      label: "Channels",
      image: require("../assets/Icons/Category.svg"),
      path: "/platform-parameters/channels",
      permission: "channels:read:list",
    },
    {
      label: "Roles",
      image: require("../assets/Icons/Groupb.svg"),
      path: "/platform-parameters/roles",
      permission: "roles:read:list",
    },
    {
      label: "Income split",
      image: require("../assets/Icons/Familiar.svg"),
      path: "/platform-parameters/income-split",
      permission: "income:read:list",
    },
    // {
    //   label: "Employees positions",
    //   image: require("../assets/Icons/Work.svg"),
    //   path: "/platform-parameters/employees",
    //   permission: "position:read:list",
    // },
    {
      label: "Activity points",
      image: require("../assets/Icons/activity-point.svg"),
      path: "/platform-parameters/activity",
      permission: "activity:read",
    },
    {
      label: "Interest points",
      image: require("../assets/Icons/interest-point.svg"),
      path: "/platform-parameters/interest",
      permission: "interest:read",
    },
    {
      label: "In app products",
      image: require("../assets/Icons/in-app-product.svg"),
      path: "/platform-parameters/products-list",
      permission: "product:read:list",
    },
  ],
  rewards: [
    {
      label: "Promotions",
      image: require("../assets/Icons/Star.svg"),
      path: "/promo-and-rewards",
    },
    {
      label: "Rewards",
      image: require("../assets/Icons/Reward.svg"),
      path: "/promo-and-rewards/reward",
    },
  ],
};

export const icons = [
  {
    id: "1",
    icon: require("../assets/Icons/Add.svg"),
    color: "green-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Additional fields",
  },
  {
    id: "2",
    icon: require("../assets/Icons/Category.svg"),
    color: "purple-50",
    channels: ["XYZ", "ABC", "123"],
    channelName: "Category",
  },
  {
    id: "3",
    icon: require("../assets/Icons/Groupb.svg"),
    color: "blue-100",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Groupb",
  },
  {
    id: "4",
    icon: require("../assets/Icons/Location.svg"),
    color: "purple-100",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Location",
  },
  {
    id: "5",
    icon: require("../assets/Icons/Familiar.svg"),
    color: "yellow-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Familiar",
  },
  {
    id: "6",
    icon: require("../assets/Icons/Work.svg"),
    color: "red-75",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Work",
  },
  {
    id: "7",
    icon: require("../assets/Icons/Star.svg"),
    color: "purple-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Star",
  },
  {
    id: "8",
    icon: require("../assets/Icons/Reward.svg"),
    color: "blue-100",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Reward",
  },
  {
    id: "9",
    icon: require("../assets/Icons/Work.svg"),
    color: "green-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Work",
  },
  {
    id: "10",
    icon: require("../assets/Icons/Category.svg"),
    color: "yellow-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
  {
    id: "11",
    icon: require("../assets/Icons/Groupb.svg"),
    color: "green-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Groupb",
  },
  {
    id: "12",
    icon: require("../assets/Icons/Location.svg"),
    color: "purple-100",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Location",
  },
  {
    id: "13",
    icon: require("../assets/Icons/Familiar.svg"),
    color: "red-75",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
  {
    id: "14",
    icon: require("../assets/Icons/Work.svg"),
    color: "yellow-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
  {
    id: "15",
    icon: require("../assets/Icons/Star.svg"),
    color: "green-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
  {
    id: "16",
    icon: require("../assets/Icons/Reward.svg"),
    color: "blue-100",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
  {
    id: "17",
    icon: require("../assets/Icons/Work.svg"),
    color: "purple-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
  {
    id: "18",
    icon: require("../assets/Icons/Category.svg"),
    color: "red-75",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
  {
    id: "19",
    icon: require("../assets/Icons/Groupb.svg"),
    color: "purple-100",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
  {
    id: "20",
    icon: require("../assets/Icons/Location.svg"),
    color: "yellow-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
  {
    id: "21",
    icon: require("../assets/Icons/Familiar.svg"),
    color: "purple-50",
    channels: ["Music", "Movies", "Web Series"],
    channelName: "Category",
  },
];

export const award = [
  {
    rewardIcon: require("../assets/Icons/Chart.svg"),
    rewardStatement: "Creating certain amount of Polls",
    rewardThreshold: "10 polls",
    rewardAmount: "10 USD",
    rewardColor: "blue-100",
    rewardStatus: "ACTIVE",
  },
  {
    rewardIcon: require("../assets/Icons/ProfileAdd-2.svg"),
    rewardStatement: "Creating certain amount of Polls",
    rewardThreshold: "10 polls",
    rewardAmount: "10 USD",
    rewardColor: "blue-100",
    rewardStatus: "ACTIVE",
  },
  {
    rewardIcon: require("../assets/Icons/ProfileAdd-2.svg"),
    rewardStatement: "Reward for referral registration",
    rewardThreshold: "10 polls",
    rewardAmount: "10 USD",
    rewardColor: "purple-100",
    rewardStatus: "ACTIVE",
  },
  {
    rewardIcon: require("../assets/Icons/ProfileAdd-2.svg"),
    rewardStatement: "Reward for referral registration",
    rewardThreshold: "10 polls",
    rewardAmount: "10 USD",
    rewardColor: "purple-100",
    rewardStatus: "ACTIVE",
  },
  {
    rewardIcon: require("../assets/Icons/Chart.svg"),
    rewardStatement: "Creating certain amount of Polls",
    rewardThreshold: "10 polls",
    rewardAmount: "10 USD",
    rewardColor: "blue-100",
    rewardStatus: "DISABLED",
  },
  {
    rewardIcon: require("../assets/Icons/ProfileAdd-2.svg"),
    rewardStatement: "Reward for referral registration",
    rewardThreshold: "10 polls",
    rewardAmount: "10 USD",
    rewardColor: "purple-100",
    rewardStatus: "ACTIVE",
  },
  {
    rewardIcon: require("../assets/Icons/Chart.svg"),
    rewardStatement: "Reward for referral registration",
    rewardThreshold: "10 polls",
    rewardAmount: "10 USD",
    rewardColor: "purple-100",
    rewardStatus: "DISABLED",
  },
  {
    rewardIcon: require("../assets/Icons/ProfileAdd-2.svg"),
    rewardStatement: "Creating certain amount of Polls",
    rewardThreshold: "10 polls",
    rewardAmount: "10 USD",
    rewardColor: "blue-100",
    rewardStatus: "ACTIVE",
  },
];

export const staffActivity = [
  {
    heading: "DECEMBER 2022",
    subArray: [
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Netflix",
        date: "2023-01-01",
        title: "Сreate poll Name poll",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Netflix",
        date: "2022-01-01",
        title: "What's Your Favorite Movie?",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Hulu",
        date: "2023-01-02",
        title: "Top 10 Shows to Watch This Month",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Amazon Prime",
        date: "2023-01-03",
        title: "Exclusive Interview with the Cast of...",
      },
    ],
  },
  {
    heading: "DECEMBER 2022",
    subArray: [
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Disney+",
        date: "2023-01-04",
        title: "Educational Programs for Children",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "HBO",
        date: "2023-01-05",
        title: "Making of a Blockbuster",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Apple TV+",
        date: "2023-01-06",
        title: "Latest Innovations in Entertainment",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Paramount+",
        date: "2023-01-07",
        title: "Must-Watch Films",
      },
    ],
  },
  {
    heading: "DECEMBER 2022",
    subArray: [
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "YouTube Originals",
        date: "2023-01-08",
        title: "Exploring the Unknown",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Vimeo",
        date: "2023-01-09",
        title: "Emerging Filmmakers",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Crunchyroll",
        date: "2023-01-10",
        title: "Latest Releases",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "HBO Max",
        date: "2023-01-11",
        title: "New Original Series",
      },
    ],
  },
  {
    heading: "DECEMBER 2022",
    subArray: [
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Showtime",
        date: "2023-01-12",
        title: "Stand-Up Extravaganza",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Discovery+",
        date: "2023-01-13",
        title: "Exploring the Wilderness",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "ESPN+",
        date: "2023-01-14",
        title: "Best Moments of the Year",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "BBC iPlayer",
        date: "2023-01-15",
        title: "Timeless TV Shows",
      },
    ],
  },
  {
    heading: "DECEMBER 2022",
    subArray: [
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Twitch",
        date: "2023-01-16",
        title: "Join the Adventure",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "National Geographic",
        date: "2023-01-17",
        title: "Journey to the Unknown",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "PBS",
        date: "2023-01-18",
        title: "Insights into History",
      },
      {
        Profile: require("../assets/Icons/userimage.png"),
        channelName: "Vudu",
        date: "2023-01-19",
        title: "Blockbuster Hits and Hidden Gems",
      },
    ],
  },
];

export const permissionRoutes = {
  "user:read:list": "/user",
  "business:read:list": "/entity-account",
  "report:read:list": "/moderations",
  "request:read:list": "/requests",
  "field:read:list": "/platform-parameters",
  "channels:read:list": "/platform-parameters/channels",
  "roles:read:list": "/platform-parameters/roles",
  "income:read:list": "/platform-parameters/income-split",
  "position:read:list": "/platform-parameters/employees",
  "interest:read": "/platform-parameters/interest",
  "activity:read": "/platform-parameters/activity",
  "product:read:list": "/platform-parameters/products-list",
  "token:read:balance": "/token",
  "token:read:bFactor": "/token",
  "token:read:token": "/token",
  "token:read:rValue": "/token",
  // "advertising:read:list": "/advertising",
  // "Promo & rewards": "/promo-and-rewards",
  // "Token Management": "/token",
  "poll:read:list": "/poll-list",
  "staff:read:list": "/staff",
  "statistics:read:data": "/statistics",
  "audit:read:list": "/audit-log",
};

export const linkType = [
  { name: "facebook", icons: require("../assets/Icons/facebook.svg") },
  { name: "youtube", icons: require("../assets/Icons/youTube.svg") },
  { name: "linkedIn", icons: require("../assets/Icons/linkedIn.svg") },
  { name: "twitter", icons: require("../assets/Icons/twitter.svg") },
  { name: "instagram", icons: require("../assets/Icons/instagram.svg") },
  { name: "tikTok", icons: require("../assets/Icons/tikTok.svg") },
];

export const permissionSet = [
  {
    name: "User management",
    permissions: [
      {
        name: "View the Active/Suspended Users list",
        permission: "user:read:list",
        needPermission: [],
        requiredPermissions: [
          "user:write:suspend",
          "user:write:activate",
          "user:write:poll",
          "user:write:comment",
          "user:write:details",
          "user:read:details",
        ],
      },
      {
        name: "View User Details",
        permission: "user:read:details",
        needPermission: ["user:read:list"],
        requiredPermissions: [
          "user:write:poll",
          "user:write:comment",
          "user:write:details",
        ],
      },
      {
        name: "Suspend User",
        permission: "user:write:suspend",
        needPermission: ["user:read:list"],
      },
      {
        name: "Activate User",
        permission: "user:write:activate",
        needPermission: ["user:read:list"],
      },
      {
        name: "Disable Poll",
        permission: "user:write:poll",
        needPermission: ["user:read:list", "user:read:details"],
      },
      {
        name: "Disable Comment",
        permission: "user:write:comment",
        needPermission: ["user:read:list", "user:read:details"],
      },
      {
        name: "Edit User Details",
        permission: "user:write:details",
        needPermission: ["user:read:list", "user:read:details"],
      },
    ],
  },
  // {
  //   name: "Business management",
  //   permissions: [
  //     {
  //       name: "View the Active/Suspended Entity list",
  //       permission: "business:read:list",
  //       needPermission: [],
  //       requiredPermissions: [
  //         "business:read:details",
  //         "business:write:entity",
  //         "business:write:suspend",
  //         "business:write:activate",
  //         "business:write:poll",
  //         "business:write:comment",
  //         "business:write:employee:add",
  //         "business:write:employee:edit",
  //         "business:write:employee:remove",
  //         "business:write:details",
  //       ],
  //     },
  //     {
  //       name: "View Entity Details",
  //       permission: "business:read:details",
  //       needPermission: ["business:read:list"],
  //       requiredPermissions: [
  //         "business:write:poll",
  //         "business:write:comment",
  //         "business:write:employee:add",
  //         "business:write:employee:edit",
  //         "business:write:employee:remove",
  //         "business:write:details",
  //       ],
  //     },
  //     {
  //       name: "Create Entity Account",
  //       permission: "business:write:entity",
  //       needPermission: ["business:read:list"],
  //     },
  //     {
  //       name: "Suspend Entity",
  //       permission: "business:write:suspend",
  //       needPermission: ["business:read:list"],
  //     },
  //     {
  //       name: "Activate Entity",
  //       permission: "business:write:activate",
  //       needPermission: ["business:read:list"],
  //     },
  //     {
  //       name: "Disable Poll",
  //       permission: "business:write:poll",
  //       needPermission: ["business:read:list", "business:read:details"],
  //     },
  //     {
  //       name: "Disable Comment",
  //       permission: "business:write:comment",
  //       needPermission: ["business:read:list", "business:read:details"],
  //     },
  //     {
  //       name: "Add Employee",
  //       permission: "business:write:employee:add",
  //       needPermission: ["business:read:list", "business:read:details"],
  //     },
  //     {
  //       name: "Edit Employee",
  //       permission: "business:write:employee:edit",
  //       needPermission: ["business:read:list", "business:read:details"],
  //     },
  //     {
  //       name: "Remove Employee",
  //       permission: "business:write:employee:remove",
  //       needPermission: ["business:read:list", "business:read:details"],
  //     },
  //     {
  //       name: "Edit Entity Details",
  //       permission: "business:write:details",
  //       needPermission: ["business:read:list", "business:read:details"],
  //     },
  //   ],
  // },
  {
    name: "Report management",
    permissions: [
      {
        name: "View Reports",
        permission: "report:read:list",
        needPermission: [],
        requiredPermissions: [
          "report:write:post",
          "report:write:comment",
          "report:write:user",
        ],
      },
      {
        name: "Approve/Disable Post",
        permission: "report:write:post",
        needPermission: ["report:read:list"],
      },
      {
        name: "Approve/Disable Comment",
        permission: "report:write:comment",
        needPermission: ["report:read:list"],
      },
      {
        name: "Leave Active or, Suspend User",
        permission: "report:write:user",
        needPermission: ["report:read:list"],
      },
    ],
  },
  {
    name: "Request management",
    permissions: [
      {
        name: "View Requests",
        permission: "request:read:list",
        needPermission: [],
        requiredPermissions: [
          "request:write:bluecheck",
          "request:write:entity",
        ],
      },
      {
        name: "Blue Check Request",
        permission: "request:write:bluecheck",
        needPermission: ["request:read:list"],
      },
      {
        name: "Entity Request",
        permission: "request:write:entity",
        needPermission: ["request:read:list"],
      },
    ],
  },
  // {
  //   name: "Advertising management",
  //   permissions: [
  //     {
  //       name: "View Ad",
  //       permission: "advertising:read:list",
  //     },
  //     {
  //       name: "Create Ad",
  //       permission: "advertising:write:create",
  //     },
  //     {
  //       name: "Duplicate Ad",
  //       permission: "advertising:write:duplicate",
  //     },
  //     {
  //       name: "Stop Ad",
  //       permission: "advertising:write:stop",
  //     },
  //     {
  //       name: "Delete Ad",
  //       permission: "advertising:write:delete",
  //     },
  //   ],
  // },

  // {
  //   name: "Promo & Reward management",
  //   permissions: [
  //     {
  //       name: "View Promo",
  //       permission: "promo:read:list",
  //     },
  //     {
  //       name: "Create Promo",
  //       permission: "promo:write:create",
  //     },
  //     {
  //       name: "Duplicate Promo",
  //       permission: "promo:write:duplicate",
  //     },
  //     {
  //       name: "Stop Promo",
  //       permission: "promo:write:stop",
  //     },
  //     {
  //       name: "Delete Promo",
  //       permission: "promo:write:delete",
  //     },
  //     {
  //       name: "View Reward",
  //       permission: "reward:write:details",
  //     },
  //   ],
  // },
  {
    name: "Platform Parameter management",
    permissions: [
      {
        name: "View Additional fields",
        permission: "field:read:list",
        needPermission: [],
        requiredPermissions: ["field:write:details"],
      },
      {
        name: "Create/Delete Additional field",
        permission: "field:write:details",
        needPermission: ["field:read:list"],
      },
      {
        name: "View Channels",
        permission: "channels:read:list",
        needPermission: [],
        requiredPermissions: ["channels:write:details"],
      },
      {
        name: "Edit Channels - (Create, Edit, Delete)",
        permission: "channels:write:details",
        needPermission: ["channels:read:list"],
      },
      {
        name: "View Roles",
        permission: "roles:read:list",
        needPermission: [],
        requiredPermissions: ["roles:write:details"],
      },
      {
        name: "Edit Roles - (Create, Edit, Delete)",
        permission: "roles:write:details",
        needPermission: ["roles:read:list"],
      },
      {
        name: "View Income Split",
        permission: "income:read:list",
        needPermission: [],
        requiredPermissions: ["income:write:details"],
      },
      {
        name: "Edit Income Split",
        permission: "income:write:details",
        needPermission: ["income:read:list"],
      },
      {
        name: "View Employee Positions",
        permission: "position:read:list",
        needPermission: [],
        requiredPermissions: ["position:write:details"],
      },
      {
        name: "Edit Positions- (Create, Edit, Delete)",
        permission: "position:write:details",
        needPermission: ["position:read:list"],
      },
      {
        name: "View Activity Points",
        permission: "activity:read",
        needPermission: [],
        requiredPermissions: ["activity:write"],
      },
      {
        name: "Edit Activity Points",
        permission: "activity:write",
        needPermission: ["activity:read"],
      },
      {
        name: "View Interest Points",
        permission: "interest:read",
        needPermission: [],
        requiredPermissions: ["interest:write"],
      },
      {
        name: "Edit Interest Points",
        permission: "interest:write",
        needPermission: ["interest:read"],
      },
      {
        name: "View In app products",
        permission: "product:read:list",
        needPermission: [],
      },
    ],
  },
  {
    name: "Token management",
    permissions: [
      {
        name: "View Admin Wallet Balance",
        permission: "token:read:balance",
        needPermission: [],
        requiredPermissions: [],
      },
      {
        name: "View b Factor",
        permission: "token:read:bFactor",
        needPermission: [],
        requiredPermissions: ["token:write:bFactor"],
      },
      {
        name: "View Token Created",
        permission: "token:read:token",
        needPermission: [],
        requiredPermissions: ["token:read:history"],
      },
      {
        name: "View Current R-Value",
        permission: "token:read:rValue",
        needPermission: [],
        requiredPermissions: ["token:read:rHistory"],
      },
      {
        name: "View Transaction history",
        permission: "token:read:history",
        needPermission: ["token:read:token"],
      },
      {
        name: "View R history",
        permission: "token:read:rHistory",
        needPermission: ["token:read:rValue"],
      },
      {
        name: "Edit b Factor",
        permission: "token:write:bFactor",
        needPermission: ["token:read:bFactor"],
        requiredPermissions: [],
      },
    ],
  },
  {
    name: "Poll management",
    permissions: [
      {
        name: "View Active List of Polls",
        permission: "poll:read:list:active",
        needPermission: [],
        requiredPermissions: [
          "poll:write:create",
          "poll:write:edit",
          "poll:write:approve",
        ],
      },
      {
        name: "View Rejected List of Polls",
        permission: "poll:read:list:rejected",
        needPermission: [],
        requiredPermissions: [
          "poll:write:create",
          "poll:write:edit",
          "poll:write:approve",
        ],
      },
      {
        name: "View Finished List of Polls",
        permission: "poll:read:list:finished",
        needPermission: [],
        requiredPermissions: [
          "poll:write:create",
          "poll:write:edit",
          "poll:write:approve",
        ],
      },
      {
        name: "Create Polls",
        permission: "poll:write:create",
        needPermission: [
          "poll:read:list:active",
          "poll:read:list:rejected",
          "poll:read:list:finished",
          "poll:write:edit",
        ],
      },
      {
        name: "Edit Polls",
        permission: "poll:write:edit",
        needPermission: [
          "poll:read:list:active",
          "poll:read:list:rejected",
          "poll:read:list:finished",
          "poll:write:create",
        ],
      },
      {
        name: "Approve / Reject Polls",
        permission: "poll:write:approve",
        needPermission: [
          "poll:read:list:active",
          "poll:read:list:rejected",
          "poll:read:list:finished",
        ],
      },
    ],
  },
  {
    name: "Staff management",
    permissions: [
      {
        name: "View Staff list",
        permission: "staff:read:list",
        needPermission: [],
        requiredPermissions: [
          "staff:write:details",
          "staff:read:details",
          "staff:write:create",
          "staff:write:action",
        ],
      },
      {
        name: "View Staff Details",
        permission: "staff:read:details",
        needPermission: ["staff:read:list"],
        requiredPermissions: ["staff:write:details"],
      },
      {
        name: "Edit Staff Details",
        permission: "staff:write:details",
        needPermission: ["staff:read:list", "staff:read:details"],
      },
      {
        name: "Create Staff",
        permission: "staff:write:create",
        needPermission: ["staff:read:list"],
      },
      {
        name: "Block/Activate Staff",
        permission: "staff:write:action",
        needPermission: ["staff:read:list"],
      },
    ],
  },
  {
    name: "Statistics management",
    permissions: [
      {
        name: "View Statistics",
        permission: "statistics:read:data",
        needPermission: [],
        requiredPermissions: ["statistics:read:download"],
      },
      {
        name: "Download Reports",
        permission: "statistics:read:download",
        needPermission: ["statistics:read:data"],
      },
    ],
  },
  {
    name: "Audit log",
    permissions: [
      {
        name: "View Audit List",
        permission: "audit:read:list",
        needPermission: [],
        requiredPermissions: ["audit:read:details"],
      },
      {
        name: "View Audit Details",
        permission: "audit:read:details",
        needPermission: ["audit:read:list"],
      },
    ],
  },
];

export const categoryOption = [
  "Poll",
  "Ranking",
  "Two-Sided Question",
  "Open Question",
  "Survey",
];
export const categoryOptionSurvey = [
  "Poll",
  "Ranking",
  "Two-Sided Question",
  "Open Question",
  "Description",
];

export const EnumValues = {
  POLL: "Poll",
  RANKING: "Ranking",
  TWO_SIDED: "Two-Sided Question",
  OPEN_QUESTION: "Open Question",
  SURVEY: "Survey",
};

export const pollDescription = {
  POLL: "Offer respondents the ability to choose between one or several answers.",
  RANKING: "Responses will consist of listing your results from best to worst.",
  TWO_SIDED: "Offer respondents to choose one point of view.",
  OPEN_QUESTION: "Respondents can offer their answers in an open form.",
  SURVEY: " Create any type of polls united by one topic.",
};

export const pollTypeOption = ["Public", "Public Targeted"];

export const frequencyData = [
  {
    value: "Never",
  },
  {
    value: "Rarely",
  },
  {
    value: "Sometimes",
  },
  {
    value: "Often",
  },
  {
    value: "Always",
  },
];

export const QualityData = [
  {
    value: "Very poor",
  },
  {
    value: "Poor",
  },
  {
    value: "Fair",
  },
  {
    value: "Good",
  },
  {
    value: "Excellent",
  },
];
export const FormalityData = [
  {
    value: "Not familiar",
  },
  {
    value: "Slightly familiar",
  },
  {
    value: "Moderately familiar",
  },
  {
    value: "Very familiar",
  },
  {
    value: "Extremely familiar",
  },
];
export const agreementData = [
  {
    value: "Strongly disagree",
  },
  {
    value: "Disagree",
  },
  {
    value: "Neutral",
  },
  {
    value: "Agree",
  },
  {
    value: "Strongly agree",
  },
];

export const textData = [
  {
    value: "",
  },
  {
    value: "",
  },
];
