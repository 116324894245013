/**
 * @module InterestComponent
 * @example
 * // Usage in a React functional component
 * import InterestComponent from "./InterestComponent";
 *
 * const MyComponent = () => {
 *   return <InterestComponent />;
 * };
 * @returns {React.Component} The InterestComponent
 */

import React from "react";
import InterestList from "./interestList";
import left from "../../assets/Icons/Left.svg";
import { useHistory, useLocation } from "react-router-dom";

const InterestComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const history = useHistory();
  return (
    <div className="w-full h-full pr-12.5 py-8.75 overflow-y-auto">
      {haveAudit && (
        <div
          onClick={() => {
            if (haveAudit) {
              history.push("/audit-log");
            }
          }}
          className="pb-3"
        >
          <img src={left} alt="left" className="cursor-pointer" />
        </div>
      )}
      <InterestList />
    </div>
  );
};

export default InterestComponent;
