import React, { useEffect, useState } from "react";
import Table from "../../common/table";
import placeholder from "../../assets/Icons/Placeholder.svg";
import left from "../../assets/Icons/Left.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import UpArrow from "../../assets/Icons/up-arrow.svg";
import DownArrow from "../../assets/Icons/down-arrow.svg";
import utility from "../../utility";
import BlockChainService from "../../services/blockChainService";

/**
 * Component for displaying R-Value history with sorting and pagination.
 *
 * @component
 * @example
 * <RValueHistory />
 */
const RValueHistory = () => {
  const headers = [
    { name: " R-Value", startDate: true },
    { name: "Date and time", endDate: true },
    { name: "" },
  ];
  const [open, setOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sortValue, setSortValue] = useState("");
  const [sortDate, setSortDate] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * Handles page change events.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getData = async () => {
    setLoading(true);

    const requestData = {
      limit: pageSize,
      skip: currentPage * pageSize,
      sort: -1,
    };

    if (sortValue || sortDate) {
      requestData.sortingKey = sortValue || sortDate;
    }

    try {
      const [error, response] = await utility.parseResponse(
        new BlockChainService().getRHistory(requestData)
      );

      if (error || !response) return;
      setData(response?.rDocumentsWithData);
      setTotalDocs(response?.totalCount);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [currentPage, sortDate, sortValue]);

  /**
   * Handles click event to toggle view of calculations for a specific item.
   * @param {string} idx - Index of the clicked item.
   */
  const handleClick = (idx) => {
    if (open === idx?.toString()) {
      setOpen(null);
    } else {
      setOpen(idx?.toString());
    }
  };

  /**
   * Renders the arrow icon based on whether the calculations are expanded or not.
   * @param {string} idx - Index of the item.
   * @returns {JSX.Element} - UpArrow or DownArrow icon.
   */
  const renderShowArrow = (idx) => {
    return idx?.toString() === open ? (
      <img src={UpArrow} alt="" />
    ) : (
      <img src={DownArrow} alt="" />
    );
  };

  /**
   * Renders the calculation details for a specific item if it is expanded.
   * @param {Object} item - The item object containing calculations.
   * @param {string} idx - Index of the item.
   * @returns {JSX.Element} - Rendered calculation details in table rows.
   */
  const renderCalculation = (item, idx) => {
    return (
      <>
        {open === idx?.toString() &&
          Object.entries(item?.calculations)?.map(([key, value], idx) => {
            let name;
            if (key === "beta") {
              name = "B factor: ";
            } else if (key === "pollClosedCount") {
              name = "Total poll closed:";
            } else if (key === "totalVotesLast30Days") {
              name = "Total number votes polled in last 30 days:";
            } else if (key === "totalActiveUsersLast30Days") {
              name = "Total Active users in last 30 days:";
            } else {
              name = key;
            }
            return (
              <tr key={idx} className="">
                <td className="pb-1 pt-3 px-5 w-35per font-ManropeMedium text-ft2 text-grey-550">
                  <div className="w-72 truncate">{name}</div>
                </td>
                <td className="font-ManropeMedium w-35per text-ft2 text-black-300">
                  <div className="w-30 truncate">{value}</div>
                </td>
                <td className="font-ManropeMedium w-30per text-ft2 text-black-50">
                  <div className="flex gap-1"></div>
                </td>
              </tr>
            );
          })}
      </>
    );
  };

  const renderTableBody = () => {
    if (data?.length > 0) {
      return data?.map((item, idx) => (
        <React.Fragment key={idx}>
          <tr
            key={idx}
            className={`hover:bg-grey-350 relative cursor-pointer ${
              open === idx?.toString() &&
              "border-b border-dashed border-grey-600"
            } `}
          >
            <td className="py-5 w-35per px-5 font-ManropeMedium text-ft2 text-black-50">
              <div className="w-72 truncate">{item?.rCount}</div>
            </td>
            <td className="font-ManropeMedium w-35per text-ft2 text-black-50">
              <div className="w-40 truncate">
                {" "}
                {moment(item.addedOn).format("hh:mm A DD MMM[,] YYYY")}
              </div>
            </td>
            <td className="font-ManropeMedium w-30per text-ft2 text-black-50">
              <div onClick={() => handleClick(idx)} className="flex gap-2">
                <span>View calculation</span>
                {renderShowArrow()}
              </div>
            </td>
          </tr>
          {renderCalculation(item, idx)}
        </React.Fragment>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={3}>
            <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
              <img src={placeholder} alt="placeholder" className="mt-10per" />
            </div>{" "}
          </td>
        </tr>
      );
    }
  };
  return (
    <div className="flex py-5 w-full h-full overflow-y-auto pr-12.5 gap-5">
      <div className="w-full">
        <div className="bg-white p-5 rounded-20 h-21.25 gap-5 flex items-center">
          <Link to="/token">
            <img src={left} alt="left" className="cursor-pointer" />
          </Link>
          <p className="text-black-100 font-ManropeBold text-ft5">
            R Value History
          </p>
        </div>
        <Table
          headers={headers}
          tableBody={renderTableBody()}
          setPageSize={setPageSize}
          pageSize={pageSize}
          loading={loading}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages - 1}
          totalDocs={totalDocs}
          setCurrentPage={setCurrentPage}
          endDate={sortDate}
          setStartDate={setSortValue}
          tagStart={"rCount"}
          startDate={sortValue}
          setEndDate={setSortDate}
          tagEnd={"addedOn"}
        />
      </div>
    </div>
  );
};

export default RValueHistory;
