import React from "react";
import { ReactComponent as Delete } from "../../../../../../assets/Icons/delete.svg";
import ShowToast from "../../../../../../common/showToast";
import { toastType } from "../../../../../../constant/commonArray";
import { ReactComponent as AddImage } from "../../../../../../assets/Icons/plus-orange.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import menu from "../../../../../../assets/Icons/menu.svg";
import utility from "../../../../../../utility";

/**
 * TypeText component for managing text-based options within a poll category.
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.state - Component state object.
 * @param {Function} props.setState - Function to set component state.
 * @param {number} props.idx - Index of the poll category.
 * @param {Array<Array<string>>} props.errorsRanking - Errors associated with ranking options.
 * @param {Function} props.setErrorRanking - Function to set errors associated with ranking options.
 * @returns {JSX.Element} TypeText component JSX.
 */

const TypeText = ({ state, setState, idx, errorsRanking, setErrorRanking }) => {
  /**
   * Handles drag end event for reordering answers.
   * @param {Object} result - Drag result object.
   */ const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...state.polls[idx].options.answers];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    const newErrors = [...errorsRanking];
    const [movedError] = newErrors[idx].splice(result.source.index, 1);
    newErrors[idx].splice(result.destination.index, 0, movedError);
    setState((prevState) => ({
      ...prevState,
      polls: prevState.polls.map((poll, i) =>
        i === idx
          ? {
              ...poll,
              options: {
                ...poll.options,
                answers: newItems,
              },
            }
          : poll
      ),
    }));
    setErrorRanking(newErrors);
  };

  /**
   * Handles change in answer text.
   * @param {Object} e - Event object.
   * @param {number} index - Index of the answer.
   */
  const handleAnswersChange = (e, index) => {
    const inputValue = e.target.value;
    setErrorRanking((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[idx] = newErrors[idx] || [];
      newErrors[idx][index] =
        inputValue.length > 150
          ? "Answer should be less than 150 characters"
          : "";
      return newErrors;
    });
    setState((prev) => ({
      ...prev,
      polls: prev.polls.map((poll, i) =>
        i === idx
          ? {
              ...poll,
              options: {
                ...poll.options,
                answers: poll.options.answers.map((answer, j) =>
                  j === index
                    ? {
                        ...answer,
                        value: e.target.value,
                      }
                    : answer
                ),
              },
            }
          : poll
      ),
    }));
  };

  /**
   * Handles deletion of an answer.
   * @param {number} index - Index of the answer to delete.
   */

  const handleDelete = (index) => {
    setState((prev) => ({
      ...prev,
      polls: prev.polls.map((poll, i) =>
        i === idx
          ? {
              ...poll,
              options: {
                ...poll.options,
                answers: poll.options.answers.filter((_, j) => j !== index),
              },
            }
          : poll
      ),
    }));
    setErrorRanking((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[idx].splice(index, 1);
      return newErrors;
    });
  };

  return (
    <div className="flex flex-col gap-5">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <div
              className="flex flex-col gap-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {state?.polls[idx]?.options?.answers?.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="flex gap-5 items-center"
                    >
                      <div className="flex flex-col gap-0.5 w-full">
                        <div className="gap-3  w-full rounded-full bg-grey-50 outline-none px-6 flex justify-between items-center h-16.25 text-black-100 font-ManropeRegular text-ft3">
                          <input
                            type="text"
                            value={item.value}
                            placeholder={`${utility.ordinalText(
                              index + 1
                            )} answer`}
                            onChange={(e) => handleAnswersChange(e, index)}
                            className="focus:outline-none bg-transparent w-95per"
                          />
                          <img
                            src={menu}
                            {...provided.dragHandleProps}
                            alt="menu"
                            className="w-3.5 h-1.5 cursor-pointer"
                          />
                        </div>
                        {errorsRanking[idx]?.[index] && (
                          <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                            {errorsRanking[idx]?.[index]}
                          </div>
                        )}
                      </div>
                      {state?.polls[idx]?.options?.answers?.length > 2 && (
                        <Delete
                          onClick={() => {
                            handleDelete(index);
                          }}
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div
        onClick={() => {
          if (state?.polls[idx]?.options?.answers?.length >= 6) {
            ShowToast({
              type: toastType.ERROR,
              message: "Can't add more than 6 answers",
            });
          } else {
            setState((prev) => ({
              ...prev,
              polls: prev.polls.map((poll, i) =>
                i === idx
                  ? {
                      ...poll,
                      options: {
                        ...poll.options,
                        answers: [
                          ...(poll.options.answers || []),
                          { value: "" },
                        ],
                      },
                    }
                  : poll
              ),
            }));
            setErrorRanking((prevErrors) => {
              const newErrors = [...prevErrors];
              newErrors[idx] = [...(newErrors[idx] || []), ""];
              return newErrors;
            });
          }
        }}
        className="flex gap-2.5 items-center cursor-pointer pt-5"
      >
        <AddImage />
        <span className="text-orange-50 font-ManropeBold text-ft2">
          Add answer
        </span>
      </div>
    </div>
  );
};

export default TypeText;
