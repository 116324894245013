import React, { useState } from "react";
import Contacts from "../assets/Icons/Contacts.svg";
import Chevrondown from "../assets/Icons/Chevrondown.svg";
import Edit from "../assets/Icons/Edit.svg";
import CountryList from "country-list-with-dial-code-and-flag";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
import EditPopup from "./editPopup";
import utility from "../utility";
import { UserService } from "../services";
import ShowToast from "./showToast";
import { toastType } from "../constant/commonArray";

/**
 * DetailsContact component displays contact details including email and phone number.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Object} props.details - The details object containing contact information.
 * @param {Function} props.getDetails - The function to fetch updated details.
 * @param {string} props.id - The ID associated with the contact details.
 * @param {string} props.keyName - The key name for conditional rendering (default or violation).
 * @returns {JSX.Element} DetailsContact component.
 */

const DetailsContact = ({ details, getDetails, id, keyName = "default" }) => {
  const [isContactExpanded, setContactExpanded] = useState(false);
  const [edit, setEditPopup] = useState(null);
  const [state, setState] = useState({
    phone: details?.phone,
    countryCode:
      typeof details?.countryCode === "number"
        ? `+${details?.countryCode}`
        : details?.countryCode || "+49",
    email: details?.email,
  });

  let arr = CountryList.getAll();

  arr.sort(
    (a, b) => parseInt(a.data.dial_code, 10) - parseInt(b.data.dial_code, 10)
  );

  const country = CountryList.findOneByDialCode(state.countryCode);
  const flagSvg = CountryFlagSvg[country?.code];

  const renderEditPopup = () => {
    switch (edit) {
      case "phone":
        return (
          <EditPopup
            name={"Phone number"}
            setEditPopup={setEditPopup}
            value={details?.phone}
            countryCode={
              typeof details?.countryCode === "number"
                ? `+${details?.countryCode}`
                : details?.countryCode
            }
            setState={setState}
            keyName="phone"
            state={state}
            handleSubmission={handleSubmission}
            arr={arr}
            flagSvg={flagSvg}
          />
        );
      default:
        return null;
    }
  };

  const handleSubmission = async () => {
    setEditPopup(null);
    let requestData = {};
    if (state.phone) {
      requestData.countryCode = state?.countryCode;
      requestData.phone = state?.phone;
    }
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().userEdit(id || details?._id, requestData)
      );
      if (err || !res) return;

      if (res?.statusCode === 400) {
        ShowToast({
          message: res?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "User update successfully",
          type: toastType.SUCCESS,
        });
      }
      getDetails();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      className={`flex flex-col p-5 rounded-20 bg-white ${
        isContactExpanded ? "gap-7.5" : ""
      }`}
    >
      <div
        onClick={() => setContactExpanded(!isContactExpanded)}
        className="flex items-center gap-3.75 cursor-pointer justify-between"
      >
        <div className="w-auto">
          <div className="flex items-center justify-center w-12.5 h-12.5 rounded-full bg-orange-50">
            <img src={Contacts} alt="Contacts" />
          </div>
        </div>

        <div className="flex w-full justify-between">
          <p className="text-ft3 font-ManropeBold text-black-50">Contacts</p>
          <img src={Chevrondown} alt="Chevrondown" className="cursor-pointer" />
        </div>
      </div>
      {isContactExpanded && (
        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">Email</p>
              <p className="font-ManropeRegular text-ft0 2xl:text-ft3 text-grey-250">
                {state?.email}
              </p>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">
                Phone number
              </p>
              <p className="font-ManropeRegular text-ft3 text-grey-250">
                {state?.countryCode}
                {state?.phone}
              </p>
            </div>
            {keyName !== "violation" && (
              <img
                src={Edit}
                alt="Edit"
                onClick={() => setEditPopup("phone")}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
      )}
      {renderEditPopup()}
    </div>
  );
};

export default DetailsContact;
