import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../assets/Icons/close.svg";
import { eventConstants, toastType } from "../../constant/commonArray";
import chevronUp from "../../assets/Icons/chevronUp.svg";
import chevronDown from "../../assets/Icons/Chevrondown.svg";
import ShowToast from "../../common/showToast";
import { UserService } from "../../services";
import utility from "../../utility";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

/**
 * ChannelPopUp component allows users to create or edit a channel.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.handleChannelPopUp - Function to close the channel popup.
 * @param {string} props.title - The title of the popup (Create new channel/Edit channel).
 * @param {Function} props.getChannelList - Function to fetch the channel list.
 * @param {Object} props.groupData - Data of the group to which the channel belongs.
 * @param {Object} props.channelData - Data of the channel (if editing an existing channel).
 * @param {string} props.keyName - Key indicating if the action is to "create" or "edit" a channel.
 * @returns {JSX.Element} - The JSX element representing the ChannelPopUp component.
 */

const ChannelPopUp = ({
  handleChannelPopUp = () => {},
  title,
  getChannelList = () => {},
  groupData,
  channelData,
  keyName = "create",
}) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isChannelAvailable, setIsChannelAvailable] = useState(false);
  const [debounceValue, setDebounceValue] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [creating, setCreating] = useState(false);
  const [state, setState] = useState({
    groupId: groupData?.groupId || "",
    groupName: groupData?.groupName || "Not choosen",
    channelName: channelData?.name || "",
    language: channelData?.language || "English",
    onlyKycusers: false,
  });
  const [error, setError] = useState({});

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 300);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredGroupList = groupList.filter((item) =>
    item.groupName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  /**
   * Asynchronously checks the channel name and updates the channel availability state
   *
   * @param {string} name - the name of the channel to be checked
   * @return {void}
   */
  const checkChannelName = async (name) => {
    try {
      const requestData = {
        channelName: name,
      };
      dispatch({ type: eventConstants.SHOW_LOADER });
      const [error, res] = await utility.parseResponse(
        new UserService().checkChannel(requestData)
      );
      if (error || !res) {
        setIsChannelAvailable(false);
      } else {
        if (res?.status) {
          setIsChannelAvailable(false);
        } else {
          setIsChannelAvailable(true);
        }
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  /**
   * Retrieves the group list asynchronously and sets the group list in the state.
   *
   */
  const getGroupList = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().groupList()
      );

      if (error || !response) return;
      setGroupList(response);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Asynchronously handles the channel name input change event.
   *
   * @param {Event} e - the input change event
   * @return {void}
   */
  const handleChannelName = async (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 50) {
      setError((prev) => ({ ...prev, nameError: false }));
    } else {
      setError((prev) => ({ ...prev, nameError: true }));
    }
    setState((prev) => ({ ...prev, channelName: e.target.value }));
    debouncedSearch(e.target.value);
    setIsChannelAvailable(false);
  };

  /**
   * Asynchronously handles the creation of a channel.
   *
   * @param {string} groupName - the name of the group
   * @param {string} channelName - the name of the channel
   */
  const handleCreateChannel = async (groupName, channelName) => {
    setCreating(true);

    try {
      const { groupName, onlyKycusers, ...rest } = state;

      const [error, response] = await utility.parseResponse(
        new UserService().createChannel(rest)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: "channel '" + channelName + "' added to '" + groupName,
          type: toastType.SUCCESS,
        });
        getChannelList();
        handleChannelPopUp();
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    }
  };

  /**
   * Handles the process of editing a channel.
   *
   * @return {Promise} a Promise that resolves after the editing process is complete
   */
  const handleEditChannel = async () => {
    setCreating(true);

    try {
      const { groupName, onlyKycusers, ...rest } = state;

      const [error, response] = await utility.parseResponse(
        new UserService().editChannel(channelData?.channelId, rest)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getChannelList();
        handleChannelPopUp();
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    }
  };

  useEffect(() => {
    getGroupList();
  }, []);

  useEffect(() => {
    if (!!debounceValue) checkChannelName(debounceValue);
    // eslint-disable-next-line
  }, [debounceValue]);

  /**
   * Scrolls the container in the specified direction by a fixed amount.
   *
   * @param {string} direction - The direction to scroll the container ("left" or "right").
   * @return {void}
   */

  const isGroupNameNotChosen = state?.groupName === "Not choosen";
  const isChannelNameEmpty = state?.channelName?.trim() === "";
  const isEditingSameChannel =
    keyName === "edit" &&
    state?.channelName?.trim() === channelData?.name?.trim() &&
    state?.groupName === groupData?.groupName &&
    state?.language === channelData?.language;
  const isChannelAvailableMismatch =
    isChannelAvailable &&
    state?.channelName?.toLowerCase() !== channelData?.name?.toLowerCase();

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 flex justify-center items-center fixed w-full h-full right-0 top-0">
      <div className="flex flex-col justify-between bg-white w-112.5 h-155 shadow-md rounded-20 p-7.5 ">
        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <p className="text-black-100 font-ManropeBold text-ft4">{title}</p>
            <Close className="cursor-pointer" onClick={handleChannelPopUp} />
          </div>
          <div className="flex flex-col">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Group of channels
            </p>
            <div
              className="flex py-5 px-5 rounded-full bg-grey-50 justify-between items-center relative cursor-pointer"
              onClick={() => setDropdownOpen(!isDropdownOpen)}
            >
              <p
                className={`font-ManropeRegular text-ft3 ${
                  state?.groupName === "Not choosen"
                    ? "text-grey-250"
                    : "text-black-100"
                } `}
              >
                {state?.groupName}
              </p>
              <img
                src={isDropdownOpen ? chevronUp : chevronDown}
                alt=""
                className="cursor-pointer"
              />
              {isDropdownOpen && (
                <div className="flex flex-col absolute top-20 left-0 z-20 bg-white w-full shadow-md rounded-20 gap-5 py-5 overflow-y-auto max-h-50vh">
                  <input
                    type="text"
                    className="px-5 py-2 rounded-full outline-none"
                    placeholder="Search group"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  />

                  {filteredGroupList.length > 0 ? (
                    filteredGroupList.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col gap-5 cursor-pointer"
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            groupName: item?.groupName,
                            groupId: item?._id,
                          }))
                        }
                      >
                        <div className="font-ManropeRegular text-ft3 text-black-100 px-5">
                          {item.groupName}
                        </div>
                        <hr
                          className={`${
                            index === filteredGroupList.length - 1
                              ? "hidden"
                              : "text-grey-50"
                          }`}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="font-ManropeRegular text-ft3 text-black-100 px-5">
                      No results found
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* <div className="flex flex-col gap-2 mt-1">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Languages
            </p>
            <div className="flex gap-2.5">
              <img
                src={ArrowLeft}
                alt="left"
                onClick={() => scrollContainer("left")}
                className="cursor-pointer"
              />
              <div
                ref={containerRef}
                className="flex gap-2.5 overflow-x-scroll scrollbar-hide"
              >
                {Languages.map((value) => (
                  <div
                    onClick={() =>
                      setState((prev) => ({ ...prev, language: value?.name }))
                    }
                    key={value.name}
                    className={`flex items-center rounded-20 px-2 py-0.75 text-ft2 text-black-100 cursor-pointer ${
                      state.language === value.name
                        ? "bg-orange-50 font-ManropeBold"
                        : "bg-grey-50 font-ManropeRegular"
                    }`}
                  >
                    {value.name}
                  </div>
                ))}
              </div>
              <img
                src={ArrowRight}
                alt="right"
                onClick={() => scrollContainer("right")}
                className="cursor-pointer"
              />
            </div>
          </div> */}
          <div className="flex flex-col mt-1">
            <p>Name of channel</p>
            <input
              value={state?.channelName}
              type="text"
              onChange={handleChannelName}
              className={`w-full h-16.25 px-6.25 rounded-full bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none ${
                isChannelAvailable &&
                state?.channelName?.toLowerCase() !==
                  channelData?.name?.toLowerCase()
                  ? "border-1 border-orange-100"
                  : ""
              }`}
            />
            {isChannelAvailable &&
              state?.channelName?.toLowerCase() !==
                channelData?.name?.toLowerCase() && (
                <p className="text-orange-100 font-ManropeRegular text-ft2">
                  This channel already exists
                </p>
              )}
            {error?.nameError && !isChannelAvailable && (
              <span className=" text-red-50">
                Channel name can't be more than 50
              </span>
            )}
          </div>
          {/* <div className="flex gap-3 items-center">
            <input
              type="checkbox"
              className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                state?.onlyKycusers
                  ? "bg-orange-50 border-none"
                  : "bg-white border-2 border-grey-100"
              }`}
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  onlyKycusers: !state?.onlyKycusers,
                }))
              }
              checked={state?.onlyKycusers}
            />
            {state?.onlyKycusers ? (
              <img
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    onlyKycusers: !state?.onlyKycusers,
                  }))
                }
                className="absolute cursor-pointer"
                src={checkMark}
                alt="checkMark"
              ></img>
            ) : (
              ""
            )}
            <p className="text-black-100 font-ManropeRegular text-ft3">
              Сan view only passed KYC users
            </p>
            <div className="flex items-center justify-center w-11 h-7.5 bg-grey-50 rounded-20">
              <p className="text-black-100 font-ManropeMedium text-ft0">18+</p>
            </div>
          </div> */}
        </div>
        <button
          disabled={
            isGroupNameNotChosen ||
            isChannelNameEmpty ||
            isEditingSameChannel ||
            isChannelAvailableMismatch ||
            creating ||
            Object.values(error).some((value) => value !== false)
          }
          className={`flex items-center justify-center rounded-full  h-16.25  font-ManropeBold text-ft3 ${
            isGroupNameNotChosen ||
            isChannelNameEmpty ||
            isEditingSameChannel ||
            isChannelAvailableMismatch ||
            creating ||
            Object.values(error).some((value) => value !== false)
              ? "bg-grey-50 text-grey-250"
              : "cursor-pointer bg-orange-50 text-black-100"
          }`}
          onClick={
            keyName === "edit"
              ? handleEditChannel
              : () => {
                  handleCreateChannel(state?.groupName, state?.channelName);
                }
          }
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ChannelPopUp;
