import React, { useEffect, useState } from "react";
import utility from "../utility";
import defaultImage from "../assets/Icons/signupProfileSmall.png";
import OpenQuestions from "../modules/DashboardPage/pollDetails/openQuestions";
import moment from "moment";
import TypePoll from "../modules/DashboardPage/pollDetails/typePoll";
import TypeTwoSided from "../modules/DashboardPage/pollDetails/typeTwoSided";
import TypeRanking from "../modules/DashboardPage/pollDetails/typeRanking";
import { CircularProgress } from "@mui/material";
import AudioPreview from "../modules/pollManagement/createPoll/audioPreview";
import LinkPreview from "../modules/pollManagement/createPoll/linkPreview";
import CoverMedia from "../modules/pollManagement/previewPoll/coverMedia";
import OtherLink from "../assets/Icons/other-link.svg";
import AudioWhite from "../assets/Icons/audio.svg";

/**
 * Poll component displays information about a poll, including details, likes, comments, reposts, and votes.
 *
 * @component
 * @example
 * // Example usage of Poll component
 * <Poll
 *   pollState="polls"
 *   likes={likesArray}
 *   votes={votesArray}
 *   comment={commentsArray}
 *   repost={repostsArray}
 *   details={pollDetailsObject}
 *   setIsCommentPopUp={setIsCommentPopUpFunction}
 *   setIsCommentId={setIsCommentIdFunction}
 * />
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.pollState - The state of the poll ("polls", "likes", "comments", "reposts", "voted").
 * @param {Array} props.likes - An array of user objects who liked the poll.
 * @param {Array} props.votes - An array of user objects who voted in the poll.
 * @param {Array} props.comment - An array of comment objects for the poll.
 * @param {Array} props.repost - An array of user objects who reposted the poll.
 * @param {Object} props.details - The details of the poll.
 * @param {function} props.setIsCommentPopUp - A function to set the state of comment pop-up.
 * @param {function} props.setIsCommentId - A function to set the state of comment ID.
 *
 * @returns {JSX.Element} The rendered Poll component.
 */

const Poll = ({ pollState, details, loading = false }) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [viewSurveyDetails, setViewSurveyDetails] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const [showDescSurvey, setShowDescSurvey] = useState(null);

  const getRandomColor = (index) => {
    const colors = ["#EBC9F1", "#C3E9FC", "#BDD6AB", "#F6C0B8", "#FFE8AE"];
    return colors[index % colors.length];
  };

  const filterVideoNImages = details?.post?.media?.filter((item) => {
    return (
      item?.type !== "link" && item?.type !== "Link" && item?.type !== "mp3"
    );
  });

  const audioFiles = details?.post?.media?.filter((item) => {
    return item?.type === "mp3";
  });
  const linkFiles = details?.post?.media?.filter((item) => {
    return item?.type === "link" || item?.type === "Link";
  });

  const getAllAudioNLink = [...(audioFiles || []), ...(linkFiles || [])];

  const toggleDesc = (idx) => {
    if (showDescSurvey === idx) {
      setShowDescSurvey(null);
    } else {
      setShowDescSurvey(idx);
    }
  };

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = Date.now();
      const timeDifference = details?.activePeriod?.endDate - currentTime;
      const remainingMilliseconds = Math.max(0, timeDifference);

      const remainingDays = Math.floor(
        remainingMilliseconds / (1000 * 60 * 60 * 24)
      );
      const remainingHours = Math.floor(
        (remainingMilliseconds / (1000 * 60 * 60)) % 24
      );
      const remainingMinutes = Math.floor(
        (remainingMilliseconds / (1000 * 60)) % 60
      );

      setTimeLeft({
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
      });
    };

    calculateTimeLeft();

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 60000);

    return () => clearInterval(timer);
  }, [details]);

  const date = `${timeLeft.days ? timeLeft.days : ""}${
    timeLeft.days ? "d" : ""
  } ${timeLeft.hours}hr ${timeLeft?.days ? "" : timeLeft.minutes}${
    timeLeft?.days ? "" : "m"
  }`;

  const renderQuestionType = (item) => {
    switch (item?.questionType) {
      case "POLL":
        return (
          <TypePoll
            details={item?.options?.answers}
            image={item?.options?.type}
          />
        );
      case "TWO_SIDED":
        return (
          <div className="flex justify-center flex-col items-center gap-5 w-full pt-5">
            <TypeTwoSided details={item?.options?.answers} />
          </div>
        );
      case "RANKING":
        return (
          <div className="pt-5">
            <TypeRanking
              details={item?.options?.answers}
              image={item?.options?.type}
            />
          </div>
        );
      case "OPEN_QUESTION":
        return (
          <div className="pt-5">
            <OpenQuestions details={item?.options?.votesWithCount} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex w-full flex-col mt-5 mb-5 row-start-2 row-span-1 rounded-20 bg-white h-auto pb-5">
      {!loading ? (
        <div className="flex w-full flex-col mt-5 mb-5 row-start-2 row-span-1 rounded-20 bg-white h-auto pb-5">
          {pollState === "polls" && (
            <div className="flex flex-col">
              <div className="flex justify-between px-5 pt-5 mb-5">
                <div className="flex flex-col gap-3">
                  <div className="flex gap-3">
                    <p className="w-17.25 text-ft2 font-ManropeRegular text-grey-250">
                      Type
                    </p>
                    <p className="w-25 text-ft2 font-ManropeBold text-black-100">
                      {utility.capitalizeFirstLetter(
                        details?.post?.pollType ||
                          details?.post?.surveyType ||
                          ""
                      )}{" "}
                      {utility.capitalizeFirstLetter(details?.type || "")}
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <p className="w-17.25  text-ft2 font-ManropeRegular text-grey-250">
                      Amount
                    </p>
                    <p className="w-25 text-ft2 font-ManropeBold text-black-100">
                      -
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex gap-3">
                    <p className="w-17.25  text-ft2 font-ManropeRegular text-grey-250">
                      Created
                    </p>
                    <p className="text-ft2 font-ManropeBold text-black-100">
                      {moment(details?.addedOn).format("MMM DD, YYYY HH:mm")}
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <p className="w-17.25  text-ft2 font-ManropeRegular text-grey-250">
                      {utility.getStatus(
                        details?.activePeriod?.startDate,
                        details?.activePeriod?.endDate
                      )}
                    </p>
                    <p className="text-ft2 font-ManropeBold text-black-100">
                      {moment(details?.activePeriod?.startDate).format(
                        "MMM DD, YYYY HH:mm"
                      )}
                      {" - "}{" "}
                      {moment(details?.activePeriod?.endDate).format(
                        "MMM DD, YYYY HH:mm"
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {details?.disable?.disablerName && (
                <div className="px-5 mb-10">
                  <div className="bg-red-75 rounded-xl p-5 w-full">
                    <p className="flex flex-col gap-1 text-black-50 font-ManropeMedium text-ft1">
                      <div className="flex gap-1.25 flex-wrap">
                        Disabled by:
                        <b>
                          <div className="break-all">
                            {" "}
                            {details?.disable?.disablerName || "-"}{" "}
                          </div>
                        </b>
                      </div>
                      <span className="break-all">
                        {details?.disable?.reason || "-"}
                      </span>
                    </p>
                  </div>
                </div>
              )}

              <div className="flex items-center justify-center pb-5">
                <div className="inline-flex flex-col gap-5 w-80per xl:w-2/3">
                  {details?.post?.media?.length > 0 &&
                  details?.type !== "SURVEY" ? (
                    <div className="flex justify-center flex-col gap-2 w-full items-center">
                      {filterVideoNImages?.length > 0 && (
                        <div
                          // style={{
                          //   backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 42.01%, rgba(0, 0, 0, 0.4) 69.22%, rgba(0, 0, 0, 0.5) 84.1%, rgba(0, 0, 0, 0.4) 100%), url(${details?.post?.media[0]?.url})`,
                          //   backgroundPosition: "center center",
                          //   backgroundRepeat: "no-repeat",
                          // }}
                          className="flex items-end rounded-20 p-5 w-full h-115 bg-cover justify-between"
                        >
                          <CoverMedia
                            data={filterVideoNImages}
                            name={details?.creator?.fullName}
                            profilePhoto={
                              details?.creator?.profilePhoto || defaultImage
                            }
                            endDate={`${date} left` ?? "-"}
                            rounded={"rounded-20"}
                          />
                        </div>
                      )}

                      <div className="w-full">
                        {details?.post?.description && (
                          <div
                            onClick={() => setShowDesc(!showDesc)}
                            className="px-5"
                          >
                            <span className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer">
                              Learn more
                            </span>
                          </div>
                        )}
                        {showDesc && (
                          <div className="px-5 font-ManropeMedium text-ft3 text-black-100  pt-1 pb-3">
                            <span>{details?.post?.description}</span>
                          </div>
                        )}
                        {(audioFiles?.length > 0 || linkFiles?.length > 0) && (
                          <div className="flex gap-3 items-center px-5">
                            <span
                              onClick={() => setViewDetails(!viewDetails)}
                              className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer"
                            >
                              {!viewDetails ? "View details" : "Hide details"}
                            </span>
                            {audioFiles?.length > 0 && (
                              <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-grey-50 items-center">
                                <img
                                  src={AudioWhite}
                                  alt=""
                                  className="h-4 w-4"
                                />
                                <span className="text-black-50 font-ManropeBold text-ft0">
                                  {audioFiles?.length || 0}
                                </span>
                              </div>
                            )}
                            {linkFiles?.length > 0 && (
                              <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-grey-50 items-center">
                                <img
                                  src={OtherLink}
                                  alt=""
                                  className="h-4 w-4"
                                />
                                <span className="text-black-50 font-ManropeBold text-ft0">
                                  {linkFiles?.length || 0}
                                </span>
                              </div>
                            )}
                          </div>
                        )}

                        {viewDetails && (
                          <div>
                            {getAllAudioNLink?.map((item, idx) => {
                              return (
                                <div className="mt-2" key={idx}>
                                  {" "}
                                  {item?.type === "mp3" ? (
                                    <AudioPreview
                                      fileName={item?.fileName}
                                      item={item}
                                      bgColor={"bg-grey-50"}
                                    />
                                  ) : (
                                    <LinkPreview
                                      link={item?.url}
                                      bgColor={"bg-grey-50"}
                                      rounded={"rounded-20"}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    details?.type !== "SURVEY" && (
                      <div className="flex flex-col">
                        <div className="flex justify-center items-center">
                          <div className="flex items-end rounded-20 p-5 justify-between w-full">
                            <div className="flex items-center gap-2.5">
                              <div className="p-0.25 border-0.5 border-white rounded-full">
                                <img
                                  src={
                                    details?.creator?.profilePhoto ||
                                    defaultImage
                                  }
                                  alt="user"
                                  className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
                                />
                              </div>
                              <p className="font-ManropeBold text-ft2 text-center text-black-50">
                                {details?.creator?.fullName}
                              </p>
                            </div>
                            <div className="flex items-center justify-center w-auto p-2 rounded-20  bg-black-100 backdrop-blur-xl bg-opacity-10">
                              <p className="font-ManropeBold text-ft2 w-full text-black-100">
                                {`${date}` || "-"} left
                              </p>
                            </div>
                          </div>
                        </div>
                        {details?.post?.description && (
                          <div
                            onClick={() => setShowDesc(!showDesc)}
                            className="px-5"
                          >
                            <span className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer">
                              Learn more
                            </span>
                          </div>
                        )}
                        {showDesc && (
                          <div className="px-5 font-ManropeMedium text-ft3 text-black-100  pt-1 pb-3">
                            <span>{details?.post?.description}</span>
                          </div>
                        )}
                      </div>
                    )
                  )}
                  <div className="flex flex-col px-5 gap-5 justify-center items-center">
                    {details?.type !== "SURVEY" && (
                      <div className="flex flex-col gap-3.75 w-full">
                        <div className="flex gap-1">
                          {details?.channels?.map((item, index) => (
                            <p
                              key={index}
                              className="flex items-center justify-center  rounded-20 text-black-100 font-ManropeMedium text-ft2 px-1.5"
                              style={{ backgroundColor: getRandomColor(index) }}
                            >
                              {item}
                            </p>
                          ))}
                        </div>
                        <p className="text-ft5 font-ManropeBold text-black-100">
                          {details?.post?.question}
                        </p>
                      </div>
                    )}
                    {details?.type === "SURVEY" ? (
                      <div className="w-full">
                        <div className="flex justify-center items-center">
                          {details?.post?.coverImage?.url ? (
                            <div
                              style={{
                                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 42.01%, rgba(0, 0, 0, 0.4) 69.22%, rgba(0, 0, 0, 0.5) 84.1%, rgba(0, 0, 0, 0.4) 100%), url(${details?.post?.coverImage?.url})`,
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                              }}
                              className="flex items-end rounded-20 p-5 w-full h-115 bg-cover justify-between"
                            >
                              <div className="flex items-center gap-2.5">
                                <div className="p-0.25 border-0.5 border-white rounded-full">
                                  <img
                                    src={
                                      details?.creator?.profilePhoto ||
                                      defaultImage
                                    }
                                    alt="user"
                                    className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
                                  />
                                </div>
                                <p className="font-ManropeBold text-ft2 text-center text-white">
                                  {details?.creator?.fullName}
                                </p>
                              </div>
                              <div className="flex items-center justify-center w-auto p-2 rounded-20  bg-white backdrop-blur-xl bg-opacity-10">
                                <p className="font-ManropeBold text-ft2 w-full text-white">
                                  {`${date}` || "-"} left
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-end rounded-20 py-5 justify-between w-full">
                              <div className="flex items-center gap-2.5">
                                <div className="p-0.25 border-0.5 border-white rounded-full">
                                  <img
                                    src={
                                      details?.creator?.profilePhoto ||
                                      defaultImage
                                    }
                                    alt="user"
                                    className="flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full"
                                  />
                                </div>
                                <p className="font-ManropeBold text-ft2 text-center text-black-50">
                                  {details?.creator?.fullName}
                                </p>
                              </div>
                              <div className="flex items-center justify-center w-auto p-2 rounded-20  bg-black-100 backdrop-blur-xl bg-opacity-10">
                                <p className="font-ManropeBold text-ft2 w-full text-black-100">
                                  {`${date}` || "-"} left
                                </p>
                              </div>
                            </div>
                          )}
                        </div>

                        {details?.post?.description && (
                          <div
                            onClick={() => setShowDesc(!showDesc)}
                            className="mt-3"
                          >
                            <span className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer">
                              Learn more
                            </span>
                          </div>
                        )}
                        {showDesc && (
                          <div className="font-ManropeMedium text-ft3 text-black-100  pt-1 pb-3">
                            <span>{details?.post?.description}</span>
                          </div>
                        )}
                        <div className="flex flex-col gap-3.75 w-full pt-5">
                          <div className="flex gap-1">
                            {details?.channels?.map((item, index) => (
                              <p
                                key={index}
                                className="flex items-center justify-center  rounded-20 text-black-100 font-ManropeMedium text-ft2 p-1.5"
                                style={{
                                  backgroundColor: getRandomColor(index),
                                }}
                              >
                                {item}
                              </p>
                            ))}
                          </div>
                          <p className="text-ft5 font-ManropeBold text-black-100">
                            {details?.post?.surveyName}
                          </p>
                        </div>
                        <div className="pt-5">
                          <span className="text-ft0 font-ManropeMedium text-black-50">
                            {details?.post?.polls?.length} questions
                          </span>
                        </div>
                        {details?.post?.polls?.map((item, idx) => {
                          const filterVideoNImages = item?.media?.filter(
                            (item) => {
                              return (
                                item?.type !== "link" &&
                                item?.type !== "Link" &&
                                item?.type !== "mp3"
                              );
                            }
                          );

                          const audioFiles = item?.media?.filter((item) => {
                            return item?.type === "mp3";
                          });
                          const linkFiles = item?.media?.filter((item) => {
                            return (
                              item?.type === "link" || item?.type === "Link"
                            );
                          });

                          const getAllAudioNLink = [
                            ...(audioFiles || []),
                            ...(linkFiles || []),
                          ];
                          return (
                            <div className="pt-12.5" key={idx}>
                              <div className="flex justify-center flex-col gap-2 w-full items-center">
                                {filterVideoNImages?.length > 0 && (
                                  <div className="flex items-end rounded-20 py-5 w-full h-115 bg-cover justify-between">
                                    <CoverMedia
                                      data={filterVideoNImages}
                                      name={details?.creator?.fullName}
                                      profilePhoto={
                                        details?.creator?.profilePhoto ||
                                        defaultImage
                                      }
                                      endDate={`${date} left` ?? "-"}
                                      rounded={"rounded-20"}
                                    />
                                  </div>
                                )}

                                <div className="w-full">
                                  {item?.description && (
                                    <div onClick={() => toggleDesc(idx)}>
                                      <span className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer">
                                        Learn more
                                      </span>
                                    </div>
                                  )}
                                  {showDescSurvey === idx && (
                                    <div className="font-ManropeMedium text-ft3 text-black-100  pt-1 pb-3">
                                      <span>{item?.description}</span>
                                    </div>
                                  )}
                                  {(audioFiles?.length > 0 ||
                                    linkFiles?.length > 0) && (
                                    <div className="flex gap-3 items-center">
                                      <span
                                        onClick={() => {
                                          if (
                                            viewSurveyDetails !==
                                            idx?.toString()
                                          ) {
                                            setViewSurveyDetails(
                                              idx?.toString()
                                            );
                                          } else {
                                            setViewSurveyDetails(null);
                                          }
                                        }}
                                        className="font-ManropeBold text-ft2 text-orange-50 cursor-pointer"
                                      >
                                        {viewSurveyDetails !== idx?.toString()
                                          ? "View details"
                                          : "Hide details"}
                                      </span>
                                      {audioFiles?.length > 0 && (
                                        <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-grey-50 items-center">
                                          <img
                                            src={AudioWhite}
                                            alt=""
                                            className="h-4 w-4"
                                          />
                                          <span className="text-black-50 font-ManropeBold text-ft0">
                                            {audioFiles?.length || 0}
                                          </span>
                                        </div>
                                      )}
                                      {linkFiles?.length > 0 && (
                                        <div className="w-max px-2 py-1 rounded-20 flex gap-1 bg-grey-50 items-center">
                                          <img
                                            src={OtherLink}
                                            alt=""
                                            className="h-4 w-4"
                                          />
                                          <span className="text-black-50 font-ManropeBold text-ft0">
                                            {linkFiles?.length || 0}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {viewSurveyDetails === idx?.toString() && (
                                    <div>
                                      {getAllAudioNLink?.map((item, idx) => {
                                        return (
                                          <div className="mt-2" key={idx}>
                                            {" "}
                                            {item?.type === "mp3" ? (
                                              <AudioPreview
                                                fileName={item?.fileName}
                                                item={item}
                                                bgColor={"bg-grey-50"}
                                              />
                                            ) : (
                                              <LinkPreview
                                                link={item?.url}
                                                bgColor={"bg-grey-50"}
                                                rounded={"rounded-20"}
                                              />
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="py-5">
                                <div className="p-2 rounded-20 bg-grey-50 flex w-max">
                                  <span className="text-ft0 font-ManropeMedium text-black-100 w-max">
                                    {item?.questionType === "POLL"
                                      ? "Single choice question"
                                      : utility.formatText(item?.questionType)}
                                  </span>
                                </div>
                                <p className="text-ft5 mt-5 font-ManropeBold text-black-100">
                                  {item?.question}
                                </p>
                              </div>
                              {renderQuestionType(item)}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      renderQuestionType(details?.post)
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center h-100">
          <CircularProgress className="text-black-100 w-5 h-5" />
        </div>
      )}
    </div>
  );
};

export default Poll;
