import React, { useState } from "react";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import { UserService } from "../../services";
import { CircularProgress } from "@mui/material";
import close from "../../assets/Icons/close.svg";

const interestPointRegex = new RegExp(/^\d*\.?\d{0,1}$/);

/**
 * Component for editing interest points associated with an activity.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.getInterestData - Function to fetch updated interest data.
 * @param {Function} props.setEditInterest - Function to control the edit interest popup state.
 * @param {Object} props.editInterest - Object containing data related to the interest being edited.
 * @returns {JSX.Element}
 */
const EditInterest = ({ getInterestData, setEditInterest, editInterest }) => {
  const [state, setState] = useState({
    value: editInterest?.data?.value || 0,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const handleEditInterest = async () => {
    let response;
    setLoading(true);
    try {
      response = await new UserService().editInterest(
        state,
        editInterest?.data?._id
      );
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        getInterestData();
        setEditInterest((prev) => ({ ...prev, popup: false }));
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="flex flex-col bg-white rounded-20 shadow-md w-112.5 p-7.5">
        <div className="flex w-full justify-between items-center mb-5">
          <span className="font-ManropeBold text-ft4 text-black-100">
            Update Interest Points
          </span>
          <img
            onClick={() =>
              setEditInterest((prev) => ({ ...prev, popup: false }))
            }
            src={close}
            alt="close"
            className="cursor-pointer"
          />
        </div>
        <div className="pb-7.5">
          <span className="font-ManropeBold text-ft3 text-black-100">
            {editInterest?.data?.activityName}
          </span>
        </div>
        <div className="flex flex-col gap-7.5 overflow-y-auto scrollbar-hide max-h-43per">
          <div className="flex flex-col">
            <label className="font-ManropeMedium text-ft3 text-black-100">
              Interest Points
            </label>
            <div className="h-16.25 px-6.25 rounded-full flex justify-between items-center bg-grey-50 focus:outline-none">
              <input
                type="text"
                value={state?.value}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (interestPointRegex.test(inputValue)) {
                    const numericValue = inputValue;
                    if (numericValue >= 0.1 && numericValue <= 99) {
                      setState((prev) => ({
                        ...prev,
                        value: inputValue === "" ? "" : numericValue,
                      }));
                      setError((prev) => ({ ...prev, value: false }));
                    } else {
                      setError((prev) => ({ ...prev, value: true }));
                      setState((prev) => ({
                        ...prev,
                        value: inputValue === "" ? "" : inputValue,
                      }));
                    }
                  }
                }}
                className="focus:outline-none w-95per bg-transparent"
              />
              <img
                src={close}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    value: 0,
                  }))
                }
                alt="close"
                className="cursor-pointer w-5per"
              />
            </div>
            {error?.value && (
              <div className="text-red-50 text-ft2 font-ManropeLight mt-1">
                Input value must be between 0.1 and 99
              </div>
            )}
          </div>
        </div>
        <button
          onClick={handleEditInterest}
          disabled={
            loading ||
            state?.value === editInterest?.data?.value ||
            Object.values(error).some((value) => value !== false)
          }
          className={`h-16.25 px-6.25 rounded-full ${
            loading ||
            state?.value === editInterest?.data?.value ||
            Object.values(error).some((value) => value !== false)
              ? "bg-grey-50"
              : "bg-orange-50"
          }  mt-7.5 text-black-100 font-ManropeBold text-ft2`}
        >
          {loading ? (
            <div className="flex justify-center  items-center h-full w-full">
              <CircularProgress className="text-orange-50" />
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditInterest;
