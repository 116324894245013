import React, { useRef, useState, useMemo } from "react";
import OutsideClickRef from "../../common/outsideClickRef";
import checkMark from "../../assets/Icons/checkMark.svg";
import PlaceholderWnm from "../../assets/Icons/PlaceholderWnm.png";
import utility from "../../utility";
import moment from "moment";
import placeholder from "../../assets/Icons/Placeholder.svg";
import Table from "../../common/table";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import calender from "../../assets/Icons/calender.svg";
import { AuditEnum } from "../../constant/commonArray";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

/**
 * LogList component renders a table of log entries with filtering and pagination features.
 *
 * @component
 * @param {object} props - Component props
 * @param {function} props.setPageSize - Function to set page size
 * @param {string} props.searchQuery - Search query string
 * @param {number} props.pageSize - Number of items per page
 * @param {function} props.setSort - Function to set sorting criteria
 * @param {boolean} props.loading - Loading state indicator
 * @param {object} props.sort - Sorting criteria object
 * @param {number} props.currentPage - Current page number
 * @param {function} props.handlePageChange - Function to handle page change
 * @param {number} props.totalPages - Total number of pages
 * @param {number} props.totalDocs - Total number of documents
 * @param {function} props.setCurrentPage - Function to set current page
 * @param {array} props.data - Array of log data
 * @param {function} props.setSelectedFiltersName - Function to set selected name filters
 * @param {array} props.selectedFilterName - Array of selected name filters
 * @param {array} props.selectedFilterPage - Array of selected page filters
 * @param {array} props.selectedFilterAction - Array of selected action filters
 * @param {array} props.selectedFilterField - Array of selected field filters
 * @param {object} props.filterList - Object containing filter options
 * @param {Date} props.startDate - Start date for time filtering
 * @param {function} props.setStartDate - Function to set start date
 * @param {Date} props.endDate - End date for time filtering
 * @param {function} props.setEndDate - Function to set end date
 * @returns {JSX.Element} Rendered LogList component
 */
const LogList = ({
  setPageSize,
  searchQuery,
  pageSize,
  setSort,
  loading,
  sort,
  currentPage,
  handlePageChange,
  totalPages,
  totalDocs,
  setCurrentPage,
  data,
  setSelectedFiltersName,
  selectedFilterName,
  selectedFilterPage,
  setSelectedFiltersPage,
  selectedFilterAction,
  setSelectedFiltersAction,
  selectedFilterField,
  setSelectedFiltersField,
  filterList,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const headers = [
    {
      name: "UserName",
      filter: true,
      filterLength: selectedFilterName?.length,
    },
    {
      name: "Page",
      typeFilter: true,
      typeFilterLength: selectedFilterPage?.length,
    },
    {
      name: "Action",
      categoryFilter: true,
      categoryFilterLength: selectedFilterAction?.length,
    },
    {
      name: "Field",
      createdFor: true,
      createdForLength: selectedFilterField?.length,
    },
    { name: "Previous Value" },
    { name: "Current Value" },
    {
      name: "Timestamp",
      timeFilter: true,
      timeFilterLength: startDate || endDate,
    },
  ];
  const history = useHistory();
  const [clickFilter, setClickFilter] = useState(false);
  const [clickFilterPage, setClickFilterPage] = useState(false);
  const [clickFilterField, setClickFilterField] = useState(false);
  const [clickFilterAction, setClickFilterAction] = useState(false);
  const [clickTime, setClickTime] = useState(false);
  const [toggleStartCalender, setToggleStartCalender] = useState(false);
  const [toggleEndCalender, setToggleEndCalender] = useState(false);
  const [oneTImeStartDate, setOneTImeStartDate] = useState(null || startDate);
  const [oneTimeEndDate, setOneTImeEndDate] = useState(null || endDate);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const optionRefName = useRef();
  const optionRefPage = useRef();
  const optionRefAcion = useRef();
  const optionRefField = useRef();
  const timeRef = useRef();

  const [error, setError] = useState({});
  const useStyles = makeStyles((theme) => ({
    datePickerDay: {
      color: "#191714",
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 400,
    },
    datePickerTypography: {
      color: "#28272799",
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 400,
    },
  }));

  const classes = useStyles();

  useMemo(() => {
    switch (error?.errorType) {
      case "invalidDate": {
        return setError((prev) => ({
          ...prev,
          dateError: "Date is not valid",
        }));
      }
      case "disablePast": {
        return setError((prev) => ({
          ...prev,
          dateError:
            "The selected time must be in the future for today's date.",
        }));
      }
      case "maxDate": {
        return setError((prev) => ({
          ...prev,
          dateError: "The selected time must can't be beyond the max date.",
        }));
      }
      default: {
        return "";
      }
    }
    // eslint-disable-next-line
  }, [error?.errorType]);
  const toggleSelectedFilterName = (filter) => {
    setCurrentPage(0);
    setSelectedFiltersName((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const toggleSelectedFilterPage = (filter) => {
    setCurrentPage(0);
    setSelectedFiltersPage((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const toggleSelectedFilterAction = (filter) => {
    setCurrentPage(0);
    setSelectedFiltersAction((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };
  const toggleSelectedFilterField = (filter) => {
    setCurrentPage(0);
    setSelectedFiltersField((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };
  /**
   * Renders the filter names with checkboxes and labels based on the selected filter names.
   *
   * @return {JSX.Element} The rendered JSX for filter names.
   */

  const renderFilterName = () => {
    return (
      clickFilter && (
        <OutsideClickRef
          state={optionRefName}
          setState={() => setClickFilter(false)}
        >
          <div
            className={`flex max-w-md max-h-57.5 overflow-y-auto p-5 flex-col items-start gap-5 absolute top-5 -right-30 z-50 rounded-20 bg-white shadow-inner`}
          >
            {filterList?.names?.map((item) => (
              <div className="flex h-7.5 items-center gap-3.75 self-stretch">
                <input
                  type="checkbox"
                  className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                    selectedFilterName.includes(item)
                      ? "bg-orange-50 border-none"
                      : "bg-white border-2 border-grey-100"
                  }`}
                  onChange={() => toggleSelectedFilterName(item)}
                  checked={selectedFilterName.includes(item)}
                />
                {selectedFilterName.includes(item) ? (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectedFilterName(item);
                    }}
                    className="absolute cursor-pointer"
                    src={checkMark}
                    alt="checkMark"
                  ></img>
                ) : (
                  ""
                )}
                <label className="font-ManropeLight text-ft3 text-black-100 font-normal whitespace-nowrap">
                  {item}
                </label>
              </div>
            ))}
          </div>
        </OutsideClickRef>
      )
    );
  };

  const renderFilterPage = () => {
    return (
      clickFilterPage && (
        <OutsideClickRef
          state={optionRefPage}
          setState={() => setClickFilterPage(false)}
        >
          <div
            className={`flex max-w-md max-h-57.5 overflow-y-auto p-5 flex-col items-start gap-5 absolute top-5 -right-30 z-50 rounded-20 bg-white shadow-inner`}
          >
            {filterList?.pages?.map((item) => (
              <div className="flex h-7.5 items-center gap-3.75 self-stretch">
                <input
                  type="checkbox"
                  className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                    selectedFilterPage.includes(item)
                      ? "bg-orange-50 border-none"
                      : "bg-white border-2 border-grey-100"
                  }`}
                  onChange={() => toggleSelectedFilterPage(item)}
                  checked={selectedFilterPage.includes(item)}
                />
                {selectedFilterPage.includes(item) ? (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectedFilterPage(item);
                    }}
                    className="absolute cursor-pointer"
                    src={checkMark}
                    alt="checkMark"
                  ></img>
                ) : (
                  ""
                )}
                <label className="font-ManropeLight text-ft3 text-black-100 font-normal whitespace-nowrap">
                  {item}
                </label>
              </div>
            ))}
          </div>
        </OutsideClickRef>
      )
    );
  };
  const renderFilterAction = () => {
    return (
      clickFilterAction && (
        <OutsideClickRef
          state={optionRefAcion}
          setState={() => setClickFilterAction(false)}
        >
          <div
            className={`flex max-w-md max-h-57.5 overflow-y-auto p-5 flex-col items-start gap-5 absolute top-5 -right-30 z-50 rounded-20 bg-white shadow-inner`}
          >
            {filterList?.actions?.map((item) => (
              <div className="flex h-7.5 items-center gap-3.75 self-stretch">
                <input
                  type="checkbox"
                  className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                    selectedFilterAction.includes(item)
                      ? "bg-orange-50 border-none"
                      : "bg-white border-2 border-grey-100"
                  }`}
                  onChange={() => toggleSelectedFilterAction(item)}
                  checked={selectedFilterAction.includes(item)}
                />
                {selectedFilterAction.includes(item) ? (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectedFilterAction(item);
                    }}
                    className="absolute cursor-pointer"
                    src={checkMark}
                    alt="checkMark"
                  ></img>
                ) : (
                  ""
                )}
                <label className="font-ManropeLight text-ft3 text-black-100 font-normal whitespace-nowrap">
                  {item}
                </label>
              </div>
            ))}
          </div>
        </OutsideClickRef>
      )
    );
  };

  const fieldValues = filterList?.fields?.filter((item) => item !== "");
  const renderFilterField = () => {
    return (
      clickFilterField && (
        <OutsideClickRef
          state={optionRefField}
          setState={() => setClickFilterField(false)}
        >
          <div
            className={`flex max-w-md max-h-57.5 overflow-y-auto p-5 flex-col items-start gap-5 absolute top-5 -right-30 z-50 rounded-20 bg-white shadow-inner`}
          >
            {fieldValues?.map((item) => (
              <div className="flex h-7.5 items-center gap-3.75 self-stretch">
                <input
                  type="checkbox"
                  className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                    selectedFilterField.includes(item)
                      ? "bg-orange-50 border-none"
                      : "bg-white border-2 border-grey-100"
                  }`}
                  onChange={() => toggleSelectedFilterField(item)}
                  checked={selectedFilterField.includes(item)}
                />
                {selectedFilterField.includes(item) ? (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectedFilterField(item);
                    }}
                    className="absolute cursor-pointer"
                    src={checkMark}
                    alt="checkMark"
                  ></img>
                ) : (
                  ""
                )}
                <label className="font-ManropeLight text-ft3 text-black-100 font-normal whitespace-nowrap">
                  {item}
                </label>
              </div>
            ))}
          </div>
        </OutsideClickRef>
      )
    );
  };

  const handleUpadteDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setClickTime(false);
  };

  const handleRedirect = (enumValue, id) => {
    switch (enumValue) {
      case AuditEnum.USER_DETAILS:
        return `/user/userDetails/${id}?from=audit-log`;
      case AuditEnum.ENTITY_DETAILS:
        return `/entity-account/details/${id}?from=audit-log`;
      case AuditEnum.MODERATION_POLL:
        return `/moderations/post/${id}?from=audit-log`;
      case AuditEnum.MODERATION_COMMENT:
        return `/moderations/comment/${id}?from=audit-log`;
      case AuditEnum.MODERATION_USER:
        return `/moderations/violationDetails/${id}?from=audit-log`;
      case AuditEnum.REQUEST_USER:
        return `/requests/blue-check/${id}?from=audit-log`;
      case AuditEnum.REQUEST_ENTITY:
        return `/requests/account/${id}?from=audit-log`;
      case AuditEnum.ADDITIONAL_LIST:
        return `/platform-parameters?from=audit-log`;
      case AuditEnum.CHANNEL_LIST:
        return `/platform-parameters/channels?from=audit-log`;
      case AuditEnum.ROLE_LIST:
        return `/platform-parameters/roles?from=audit-log`;
      case AuditEnum.INCOME_LIST:
        return `/platform-parameters/income-split?from=audit-log`;
      case AuditEnum.EMPLOYEE_LIST:
        return `/platform-parameters/employees?from=audit-log`;
      case AuditEnum.ACTIVITY_LIST:
        return `/platform-parameters/activity?from=audit-log`;
      case AuditEnum.INTEREST_LIST:
        return `/platform-parameters/interest?from=audit-log`;
      case AuditEnum.POLL_DETAILS:
        return `/poll-list/details/${id}?from=audit-log`;
      case AuditEnum.STAFF_LIST:
        return `/staff?from=audit-log`;
      case AuditEnum.PROFILE:
        return `/profile?from=audit-log`;
      default:
        return "";
    }
  };

  const renderFilterTime = () => {
    return (
      clickTime && (
        <OutsideClickRef
          state={timeRef}
          setState={setClickTime}
          classContain="Mui"
        >
          <div
            className={`flex w-106.25 max-h-57.5 overflow-y-auto flex-col p-5 items-start gap-5 absolute top-5 -right-30 z-50 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex flex-col gap-1 w-full">
              <div className="flex justify-end w-full">
                <span
                  onClick={() => {
                    handleUpadteDate(null, null);
                    setOneTImeStartDate(null);
                    setOneTImeEndDate(null);
                  }}
                  className="text-ft2 font-ManropeMedium text-orange-50 cursor-pointer"
                >
                  Clear
                </span>
              </div>
              <div className="w-full flex gap-5">
                <div className="flex flex-col relative w-1/2">
                  <p className="font-ManropeRegular text-ft3 text-black-100">
                    Start Date
                  </p>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label=""
                        onError={(newError) =>
                          setError((prev) => ({ ...prev, errorType: newError }))
                        }
                        open={toggleStartCalender}
                        maxDate={oneTimeEndDate}
                        disableHighlightToday
                        onClose={() => setToggleStartCalender(false)}
                        onChange={(value) => {
                          const currentDate = new Date();
                          currentDate.setHours(0, 0, 0, 0);
                          const selectedDate = new Date(value);
                          selectedDate.setHours(0, 0, 0, 0);
                          setOneTImeStartDate(selectedDate);
                        }}
                        value={oneTImeStartDate}
                        classes={{ typography: classes.datePickerTypography }}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { border: "none" },
                            height: "48px",
                            padding: "25px 10px 25px 10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "20px",
                            backgroundColor: "#f3f3f3",
                            border: "none",
                          },
                        }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              endAdornment: (
                                <InputAdornment
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                    cursor: "pointer",
                                    marginRight: "12px",
                                  }}
                                  position="start"
                                >
                                  <img
                                    src={calender}
                                    alt=""
                                    onClick={() => {
                                      setToggleStartCalender(
                                        !toggleStartCalender
                                      );
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            },
                          },
                          day: {
                            className: classes.datePickerDay,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="flex flex-col relative w-1/2">
                  <p className="font-ManropeRegular text-ft3 text-black-100">
                    End Date
                  </p>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label=""
                        onError={(newError) =>
                          setError((prev) => ({ ...prev, errorType: newError }))
                        }
                        open={toggleEndCalender}
                        minDate={oneTImeStartDate}
                        disableHighlightToday
                        onClose={() => setToggleEndCalender(false)}
                        onChange={(value) => {
                          const currentDate = new Date();
                          currentDate.setHours(0, 0, 0, 0);
                          const selectedDate = new Date(value);
                          selectedDate.setHours(0, 0, 0, 0);
                          setOneTImeEndDate(selectedDate);
                        }}
                        value={oneTimeEndDate}
                        classes={{ typography: classes.datePickerTypography }}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { border: "none" },
                            height: "48px",
                            padding: "25px 10px 25px 10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "20px",
                            backgroundColor: "#f3f3f3",
                            border: "none",
                          },
                        }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              endAdornment: (
                                <InputAdornment
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                    cursor: "pointer",
                                    marginRight: "12px",
                                  }}
                                  position="start"
                                >
                                  <img
                                    src={calender}
                                    alt=""
                                    onClick={() => {
                                      setToggleEndCalender(!toggleEndCalender);
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            },
                          },
                          day: {
                            className: classes.datePickerDay,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            {error?.errorType && (
              <span className=" text-red-50">{error?.dateError}</span>
            )}
            <div className="w-1/2">
              <button
                onClick={() => {
                  handleUpadteDate(oneTImeStartDate, oneTimeEndDate);
                }}
                disabled={
                  error?.errorType || !oneTImeStartDate || !oneTimeEndDate
                }
                className={`${
                  error?.errorType || !oneTImeStartDate || !oneTimeEndDate
                    ? "bg-grey-50"
                    : "bg-orange-50"
                } font-ManropeBold w-70per rounded-20 p-5 h-4 flex justify-center items-center text-black-50 text-ft2 `}
              >
                Update
              </button>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };

  const renderCurrentValue = (item) => {
    if (Array.isArray(item.currentValue)) {
      return item.currentValue.map((value, index) => (
        <span key={index}>{value.name}</span>
      ));
    } else if (
      item.currentValue &&
      typeof item.currentValue === "object" &&
      !Array.isArray(item.currentValue)
    ) {
      return Object.keys(item.currentValue).map((key, index) => (
        <span key={index}>{item.currentValue[key]}</span>
      ));
    } else if (item.field === "modifiedOn") {
      return moment(item.currentValue).format("hh:mm A[, ] DD-MM-YYYY");
    } else {
      return item.currentValue;
    }
  };

  const renderPrevValue = (item) => {
    if (Array.isArray(item.prevValue)) {
      return item.prevValue.map((value, index) => (
        <span key={index}>{value.name}</span>
      ));
    } else if (item.field === "modifiedOn") {
      return moment(item.prevValue).format("hh:mm A[, ] DD-MM-YYYY");
    } else if (
      item.prevValue &&
      typeof item.prevValue === "object" &&
      !Array.isArray(item.prevValue)
    ) {
      return Object.keys(item.prevValue).map((key, index) => (
        <span key={index}>{item.prevValue[key]}</span>
      ));
    } else {
      return item.prevValue;
    }
  };

  const handleClickRedirect = (item) => {
    if (item?.redirect && userPermissions?.indexOf("audit:read:details") > -1) {
      const url = handleRedirect(item?.redirect, item?.contentId);
      history.push(url);
    }
  };
  const renderTableBody = () => {
    if (data?.length > 0) {
      return data?.map((item, index) => (
        <tr
          onClick={() => handleClickRedirect(item)}
          key={index}
          className="hover:bg-grey-350 cursor-pointer relative"
        >
          <td className="py-5 px-5">
            <div className="flex gap-2.5 items-center w-35">
              <span className="font-ManropeMedium text-black-100  text-ft1 w-35 truncate">
                {item?.staffName || "-"}
              </span>
            </div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-35 truncate">
              {utility.capitalizeFirstLetter(item.page)}
            </div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-35 truncate">{item.action}</div>
          </td>
          <td>
            <div className="w-35 truncate">{item.field || "-"}</div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-50  truncate">{renderPrevValue(item)}</div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-50  truncate"> {renderCurrentValue(item)}</div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-50  truncate">
              {moment(item.addedOn).format("hh:mm A[, ] DD-MM-YYYY")}
            </div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={7}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery || selectedFilterName?.length > 0 ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setSort={setSort}
        loading={loading}
        sort={sort}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalPages={totalPages - 1}
        totalDocs={totalDocs}
        setCurrentPage={setCurrentPage}
        renderFilter={renderFilterName()}
        clickFilter={clickFilter}
        setClickFilter={setClickFilter}
        renderFilterTime={renderFilterTime()}
        clickTime={clickTime}
        setClickTime={setClickTime}
        setClickFilterType={setClickFilterPage}
        clickFilterType={clickFilterPage}
        setCategoryFilter={setClickFilterAction}
        categoryFilter={clickFilterAction}
        setCreatedFor={setClickFilterField}
        createdFor={clickFilterField}
        renderFilterType={renderFilterPage()}
        renderFilterCategory={renderFilterAction()}
        renderFilterCreatedFor={renderFilterField()}
      />
    </>
  );
};

export default LogList;
