import React, { useState } from "react";
import logo from "../assets/Icons/logo.svg";
import userIcon from "../assets/Icons/user.svg";
import userUnactive from "../assets/Icons/user-unactive.svg";
// import EnityActive from "../assets/Icons/entity-active.svg";
// import work from "../assets/Icons/Work.svg";
import error from "../assets/Icons/Error.svg";
import ErrorActive from "../assets/Icons/error-active.svg";
import message from "../assets/Icons/MessageBlack.svg";
import MessageActive from "../assets/Icons/message-active.svg";
import settings from "../assets/Icons/settings.svg";
import SettingsActive from "../assets/Icons/settings-active.svg";
import money from "../assets/Icons/token-management.svg";
import moneyActive from "../assets/Icons/moneyActive.svg";
import graph from "../assets/Icons/Graph.svg";
import activeGraph from "../assets/Icons/activeGraph.svg";
import PollActive from "../assets/Icons/poll-active.svg";
import PollInactive from "../assets/Icons/poll-inactive.svg";
import menuFries from "../assets/Icons/MenuFries.svg";
import { subOptions } from "../constant/commonArray.js";
import profile from "../assets/Icons/profile-octagon.svg";
import profileActive from "../assets/Icons/ProfileActive.svg";
import UpArrow from "../assets/Icons/up-arrow.svg";
import DownArrow from "../assets/Icons/down-arrow.svg";
import AuditActive from "../assets/Icons/audit-inactive.svg";
import AuditInactive from "../assets/Icons/audit-active.svg";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkIsSidebarOpen } from "../reducers/manageSidebar";
import defaultImage from "../assets/Icons/signupProfileSmall.png";

/**
 * SideBar component represents the sidebar navigation in the application.
 *
 * @component
 * @example
 * // Example usage of SideBar component
 * <SideBar />
 *
 * @returns {JSX.Element} The rendered SideBar component.
 */

const menuItems = [
  {
    id: 1,
    label: "Users",
    activeIcon: userIcon,
    icon: userUnactive,
    path: "/user",
    permissions: ["user:read:list"],
  },
  // {
  //   id: 2,
  //   label: "Entity Accounts",
  //   activeIcon: EnityActive,
  //   icon: work,
  //   path: "/entity-account",
  //   permissions: ["business:read:list"],
  // },
  {
    id: 3,
    label: "Moderations",
    activeIcon: ErrorActive,
    icon: error,
    path: "/moderations",
    permissions: ["report:read:list"],
  },
  {
    id: 4,
    label: "Requests",
    activeIcon: MessageActive,
    icon: message,
    path: "/requests",
    permissions: ["request:read:list"],
  },
  {
    id: 5,
    label: "Platform Parameters",
    activeIcon: SettingsActive,
    icon: settings,
    path: "/platform-parameters",
    subOptions: subOptions.settings,
    hoverType: "settings",
    dropDownType: "parameters",
    permissions: [
      "field:read:list",
      "channels:read:list",
      "roles:read:list",
      "income:read:list",
      "position:read:list",
      "product:read:list",
      "activity:read",
      "interest:read",
    ],
  },
  {
    id: 6,
    label: "Token Management",
    activeIcon: moneyActive,
    icon: money,
    path: "/token",
    permissions: [
      "token:read:balance",
      "token:read:bFactor",
      "token:read:token",
      "token:read:rValue",
    ],
  },
  {
    id: 7,
    label: "Poll Management",
    activeIcon: PollActive,
    icon: PollInactive,
    path: "/poll-list",
    permissions: [
      "poll:read:list:active",
      "poll:read:list:rejected",
      "poll:read:list:finished",
    ],
    opacity: true,
  },
  {
    id: 8,
    label: "Staff",
    activeIcon: profileActive,
    icon: profile,
    path: "/staff",
    permissions: ["staff:read:list"],
  },
  {
    id: 10,
    label: "Statistics",
    activeIcon: activeGraph,
    icon: graph,
    path: "/statistics",
    permissions: ["statistics:read:data"],
  },
  {
    id: 11,
    label: "Audit Log",
    activeIcon: AuditInactive,
    icon: AuditActive,
    path: "/audit-log",
    auditLog: "audit-log",
    permissions: ["audit:read:list"],
  },
];

const SideBar = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const isSidebarActive = useSelector((state) => state?.sideBar?.isSideBarOpen);
  const [selectedItem, setSelectedItem] = useState(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [dropDown, setDropDown] = useState(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const history = useHistory();
  const handleRewardClick = () => {
    if (!isSidebarActive) {
      history.push("/promo-and-rewards");
    }
  };

  return (
    <aside
      className={`flex flex-col justify-between items-start pl-12.5 flex-shrink-0 pr-10.75 pt-10.75 row-start-1 col-start-1 row-span-2 col-span-1 ${
        isSidebarActive && "w-31"
      }`}
      onMouseEnter={() => setMenuVisible(true)}
      onMouseLeave={() => setMenuVisible(isSidebarActive ? true : false)}
    >
      <div className="flex flex-col items-start gap-16 h-87per">
        <div className="flex items-center gap-10">
          <img
            src={logo}
            alt="logo"
            className={`${isSidebarActive ? "hidden" : ""}`}
          />
          <button
            onClick={() => dispatch(checkIsSidebarOpen(!isSidebarActive))}
          >
            {menuVisible && <img src={menuFries} alt="menuFries" />}
          </button>
        </div>
        <div
          className={`flex flex-col items-start gap-6 ${
            !isSidebarActive ? "overflow-y-auto h-full scrollbar-hide" : ""
          }`}
        >
          {/* {userPermissions?.indexOf("advertising:read:list") > -1 &&
            renderMenuItem(
              "Advertising",
              MegaphoneActive,
              megaphone,
              "/advertising"
            )} */}
          {/* {userPermissions?.indexOf("promo:read:list") &&
            renderSubMenuItem(
              "rewards",
              "rewards",
              "/promo-and-rewards",
              subOptions.rewards,
              "Promo & Rewards",
              RewardActive,
              reward
            )} */}

          {menuItems.map((item) => {
            if (!!item.hoverType) {
              return userPermissions?.some((perm) =>
                item.permissions.includes(perm)
              ) ? (
                <RenderSubMenuItem
                  key={item.id}
                  dropDownType={item.dropDownType}
                  hoverType={item.hoverType}
                  path={item.path}
                  data={item.subOptions}
                  label={item.label}
                  activeIcon={item.activeIcon}
                  icon={item.icon}
                  isSidebarActive={isSidebarActive}
                  haveAudit={haveAudit}
                  setDropDown={setDropDown}
                  handleRewardClick={handleRewardClick}
                  currentPath={currentPath}
                  dropDown={dropDown}
                  setSelectedItem={setSelectedItem}
                  selectedItem={selectedItem}
                  userPermissions={userPermissions}
                  history={history}
                />
              ) : null;
            }
            return userPermissions?.some((perm) =>
              item.permissions.includes(perm)
            ) ? (
              <RenderMenuItem
                key={item.id}
                label={item.label}
                activeIcon={item.activeIcon}
                icon={item.icon}
                path={item.path}
                opacity={item.opacity}
                auditLog={item.auditLog}
                haveAudit={haveAudit}
                isSidebarActive={isSidebarActive}
                currentPath={currentPath}
              />
            ) : null;
          })}
        </div>
      </div>
      <Link to="/profile">
        <div className="flex items-center mb-6 w-39.75 gap-2.5">
          <img
            src={user?.profilePicture || defaultImage}
            alt="user"
            className={`flex w-7.5 h-7.5 justify-center items-center gap-2.5 flex-shrink-0 rounded-full ${
              currentPath.includes("/profile") && "border-2 border-orange-50"
            }`}
          />
          <div
            className={`flex flex-col justify-center items-start ${
              isSidebarActive ? "hidden" : ""
            }`}
          >
            <p className="font-ManropeBold text-ft2 max-w-40 truncate text-center text-black-50">
              {user?.name}
            </p>
            <p className="font-ManropeBold text-ft2 text-grey-200">
              {user?.roleDetails?.role}
            </p>
          </div>
        </div>
      </Link>
    </aside>
  );
};

export default SideBar;

const RenderMenuItem = ({
  label,
  activeIcon,
  icon,
  path,
  opacity = false,
  auditLog = "",
  haveAudit,
  isSidebarActive = false,
  currentPath,
}) => {
  return (
    <Link to={path}>
      <div className={`flex items-center gap-3.75 `}>
        <img
          src={
            (currentPath.includes(path) && haveAudit !== "audit-log") ||
            auditLog === haveAudit
              ? activeIcon
              : icon
          }
          alt={label}
          className={`${
            opacity ||
            (currentPath.includes(path) && haveAudit !== "audit-log") ||
            auditLog === haveAudit
              ? ""
              : "opacity-40"
          }`}
        ></img>
        <p
          className={`text-center text-ft2 ${
            (currentPath.includes(path) && haveAudit !== "audit-log") ||
            auditLog === haveAudit
              ? ``
              : "text-opacity-40"
          } font-ManropeBold text-black-100  ${
            isSidebarActive ? "hidden" : ""
          }`}
        >
          {label}
        </p>
      </div>
    </Link>
  );
};

const RenderSubMenuItem = ({
  dropDownType,
  hoverType,
  path,
  data,
  label,
  activeIcon,
  icon,
  isSidebarActive = false,
  haveAudit,
  setDropDown,
  handleRewardClick,
  currentPath,
  dropDown,
  setSelectedItem,
  selectedItem,
  userPermissions,
  history,
}) => {
  return (
    <div className="flex flex-col">
      <div
        onClick={() => {
          if (!isSidebarActive && dropDown === dropDownType) {
            setDropDown("");
          } else if (!isSidebarActive) {
            setDropDown(dropDownType);
          }
        }}
        className={`flex items-center gap-3.75 relative cursor-pointer`}
        onMouseEnter={() => {
          isSidebarActive && setSelectedItem(hoverType);
        }}
        onMouseLeave={() => {
          isSidebarActive && setSelectedItem("");
        }}
      >
        <img
          src={
            currentPath.includes(path) && haveAudit !== "audit-log"
              ? activeIcon
              : icon
          }
          alt={label}
          onClick={handleRewardClick}
          className={`cursor-pointer ${
            currentPath.includes(path) && haveAudit !== "audit-log"
              ? ""
              : "opacity-40"
          }`}
        ></img>

        <p
          className={`text-center text-ft2 font-ManropeBold text-black-100 ${
            isSidebarActive ? "hidden" : ""
          } ${
            currentPath.includes(path) && haveAudit !== "audit-log"
              ? ""
              : "opacity-40"
          }`}
        >
          {label}
        </p>
        {!isSidebarActive && (
          <div>
            <img src={dropDown === dropDownType ? UpArrow : DownArrow} alt="" />
          </div>
        )}

        {selectedItem === hoverType && (
          <ul
            className={`absolute top-0 z-10 flex flex-col w-75 rounded-20 bg-white shadow-inner cursor-pointer ${
              isSidebarActive ? "left-6" : "left-40"
            }`}
          >
            {data?.map((option, index) => (
              <div key={index}>
                {userPermissions?.indexOf(option?.permission) > -1 && (
                  <div
                    onClick={() => {
                      option?.path && history.push(option?.path);
                    }}
                    className={`flex h-16.25 px-5 items-center gap-3.75 self-stretch ${
                      index !== data.length - 1
                        ? "border-b-0.5 border-gray-light"
                        : ""
                    }`}
                    key={index}
                  >
                    <img
                      src={option.image}
                      alt="suboption"
                      className="w-8 h-8 flex-shrink-0"
                    />
                    <li className="font-ManropeBold text-ft3 text-black-100">
                      {option.label}
                    </li>
                  </div>
                )}
              </div>
            ))}
          </ul>
        )}
      </div>
      {dropDown === dropDownType && !isSidebarActive && (
        <div className="pt-3">
          {data.map((option, index) => (
            <div key={index}>
              {userPermissions?.indexOf(option?.permission) > -1 && (
                <div
                  onClick={() => {
                    option?.path && history.push(option.path);
                  }}
                  className={`flex h-8 px-3 items-center gap-3.75 self-stretch cursor-pointer`}
                  key={index}
                >
                  <img
                    src={option.image}
                    alt="suboption"
                    className={`w-4 h-4 flex-shrink-0 ${
                      currentPath === option?.path && haveAudit !== "audit-log"
                        ? ""
                        : "opacity-40"
                    }`}
                  />
                  <li
                    className={`font-ManropeBold text-ft1 text-black-100 ${
                      currentPath === option?.path && haveAudit !== "audit-log"
                        ? ""
                        : "opacity-40"
                    }`}
                  >
                    {option.label}
                  </li>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
