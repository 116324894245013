import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import placeholder from "../../../assets/Icons/Placeholder.svg";

/**
 * ChartWithPie displays a pie chart using Recharts library.
 *
 * @component
 * @example
 * // Example usage of ChartWithPie
 * <ChartWithPie
 *   colors={["#EBC9F1", "#C3E9FC", "#BDD6AB", "#F6C0B8", "#FFE8AE"]}
 *   data={[
 *     { name: "Category1", value: 1500 },
 *     { name: "Category2", value: 800 },
 *     // ...more data
 *   ]}
 *   dataKey="value"
 *   token={false}
 * />
 *
 * @param {string[]} colors - An array of colors used for pie chart segments.
 * @param {Object[]} data - An array of objects containing data for each segment.
 * @param {string} dataKey - The key to extract values for each segment.
 * @param {boolean} [token=false] - If true, a token layout will be used for the pie chart.
 *
 * @returns {JSX.Element} The rendered ChartWithPie component.
 */

const ChartWithPie = ({
  colors,
  data,
  dataKey,
  token = false,
  height = 500,
}) => {
  return (
    <div className="h-full">
      {data?.length > 0 ? (
        <div>
          <ResponsiveContainer height={height}>
            <PieChart>
              <Pie
                data={data}
                dataKey={dataKey}
                cx="50%"
                cy="50%"
                innerRadius={token ? 120 : 180}
                outerRadius={token ? 130 : 190}
                fill="#82ca9d"
                paddingAngle={2}
                cornerRadius={10}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    style={{ outline: "none" }}
                    fill={colors[index % colors.length]}
                    className="cursor-pointer"
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip dataKey={dataKey} />} />
              {dataKey !== "count" && (
                <Legend
                  verticalAlign="bottom"
                  align="left"
                  iconType="circle"
                  content={() => (
                    <div className="flex gap-5 flex-wrap pb-5">
                      {data.map(({ name, value, location, count }, index) => {
                        const totalPopulation = data.reduce(
                          (acc, marker) =>
                            acc + (marker.value || marker?.count),
                          0
                        );
                        const valueInK =
                          value > 1000 || count > 1000
                            ? (value || count) / 1000
                            : value || count;
                        const percentage =
                          ((value || count) / totalPopulation) * 100;

                        return (
                          <div
                            key={name || location}
                            className="flex items-center gap-2"
                          >
                            <div
                              className="w-3 h-3 rounded-full"
                              style={{ backgroundColor: colors[index] }}
                            ></div>
                            <div className="flex gap-2">
                              <p className="text-black-100 font-ManropeBold text-ft3">
                                {name || location}
                              </p>
                              <p className="text-black-75 text-opacity-60 font-ManropeRegular text-ft3">
                                {valueInK > 1000
                                  ? `${valueInK.toFixed(1)}k`
                                  : valueInK}
                                ({percentage.toFixed(2)}%)
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  wrapperStyle={{
                    paddingLeft: "20px",
                    paddingBottom: "-30px",
                    paddingTop: "0px",
                  }}
                />
              )}
            </PieChart>
          </ResponsiveContainer>
          {dataKey === "count" && (
            <div className="pt-5 flex gap-5 px-5 flex-wrap">
              {data?.map(({ name, value, location, count }, index) => (
                <RenderChartComponent
                  key={index}
                  colors={colors[index]}
                  count={count}
                  data={data}
                  location={location}
                  name={name}
                  value={value}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center gap-7.5 h-full rounded-20">
          <img src={placeholder} alt="placeholder" />
        </div>
      )}
    </div>
  );
};

export default ChartWithPie;

const CustomTooltip = ({ active, payload, dataKey }) => {
  if (active && payload && payload.length) {
    const color = payload[0].payload.fill;

    return (
      <div className="flex flex-col bg-white rounded-20 p-5 shadow-md w-61 gap-2">
        <div className="flex items-center gap-2">
          <div
            style={{ backgroundColor: color }}
            className="w-3 h-3 rounded-full"
          ></div>
          <p className="text-black-100 font-ManropeBold text-ft3">
            {dataKey === "value"
              ? payload[0].name
              : payload[0].payload?.location}
          </p>
        </div>
        <p className="text-black-75 font-ManropeRegular text-ft3 pl-5">
          {payload[0].value || payload[0].count}
        </p>
      </div>
    );
  }
  return null;
};

const RenderChartComponent = ({
  name,
  value,
  location,
  count,
  colors,
  data,
}) => {
  const totalPopulation = data.reduce(
    (acc, marker) => acc + (marker.value || marker?.count),
    0
  );
  const valueInK =
    value > 1000 || count > 1000 ? (value || count) / 1000 : value || count;
  const percentage = ((value || count) / totalPopulation) * 100;

  return (
    <div key={name || location} className="flex items-center gap-2">
      <div
        className="w-3 h-3 rounded-full"
        style={{ backgroundColor: colors }}
      ></div>
      <div className="flex gap-2">
        <p className="text-black-100 font-ManropeBold text-ft3">
          {name || location}
        </p>
        <p className="text-black-75 text-opacity-60 font-ManropeRegular text-ft3">
          {valueInK > 1000 ? `${valueInK.toFixed(1)}k` : valueInK} (
          {percentage.toFixed(2)}%)
        </p>
      </div>
    </div>
  );
};
