import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import index from "./modules/LoginPage/index";
import Dashboard from "./modules/DashboardPage/Dashboard";
import UserDetails from "./modules/DashboardPage/details/UserDetails";
import EntityComponent from "./modules/entityAccount";
import ViolationsComponent from "./modules/violations";
import RequestsComponent from "./modules/requests";
import BlueCheckComponent from "./modules/requests/blueCheck";
import EntityCheck from "./modules/requests/entityCheck";
import CreateEntity from "./modules/entityAccount/createEntity/createEntity";
import { ToastContainer } from "react-toastify";
import EntityDetails from "./modules/entityAccount/details/entityDetails";
import PostReport from "./modules/violations/postdetails";
import PollDetailsComponent from "./modules/DashboardPage/pollDetails";
import CommentReport from "./modules/violations/commentdetails";
import UserReport from "./modules/violations/userdetails";
import AdvertisingComponent from "./modules/advertising";
import CreateAdvertisingComponent from "./modules/advertising/adsCreate/create";
import DetailsAdvertisingComponent from "./modules/advertising/detailAds";
import EntityPollDetailsComponent from "./modules/entityAccount/details/pollDetails";
import { keyTypeConstants, toastType } from "./constant/commonArray";
import PromotionsComponent from "./modules/promotions";
import PromotionDetails from "./modules/promotions/detailPromos";
import OnlyRewardsComponent from "./modules/rewards";
import CreateTokens from "./modules/promotions/create/createToken";
import CreateVotes from "./modules/promotions/create/createVotes";
import CreateContent from "./modules/promotions/create/createContent";
import RolesComponent from "./modules/roles";
import AdditionalComponent from "./modules/additionalField";
import EmployeesComponent from "./modules/employees";
import ChannelsComponent from "./modules/channels";
import CreateComponent from "./modules/additionalField/create";
import ProfileComponent from "./modules/profile";
import StatisticsComponent from "./modules/statistics";
import Loader from "./common/component/loader";
import { connect, useDispatch } from "react-redux";
import StaffComponent from "./modules/staff";
import Activity from "./modules/staff/activity";
import { UserService } from "./services";
import utility from "./utility";
import PrivateRoute from "./privateRoute";
import IncomeSplitComponent from "./modules/incomeSplit";
import TokenComponent from "./modules/tokenManagement";
import ShowToast from "./common/showToast";
import { sessionManager } from "./managers/sessionManager";
import PollListComponent from "./modules/pollManagement/pollList";
import CreatePoll from "./modules/pollManagement/createPoll/createPoll";
import PollDetailsCompoenent from "./modules/pollManagement/pollDetails.js";
import ActivityPointComponent from "./modules/activityPoint";
import PageNotFound from "./common/pageNotFound";
import InterestComponent from "./modules/userInterest";
import ViewTransactions from "./modules/tokenManagement/viewTransactions";
import RValueHistory from "./modules/tokenManagement/rValueHistory";
import AuditLogComponent from "./modules/auditLog";
import FailedViewTransactions from "./modules/tokenManagement/failedTransaction";
import InAppProductCompoenent from "./modules/inAppProducts";

const { USER, AUTH0_ID_TOKEN } = keyTypeConstants;

const App = (props) => {
  let loader = props?.loader?.loading ? <Loader /> : null;
  const dispatch = useDispatch();
  const history = useHistory();
  const { removeDataFromCookies } = sessionManager;
  const userId = utility.getDataFromLocalStorage(USER);
  const getStaffDetails = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getCheckStaff(userId?.userId)
      );

      if (error || !response?.responseData) return;
      if (response?.responseCode === 401) {
        dispatch({
          type: "REMOVE_USER",
          payload: {},
        });
        removeDataFromCookies(AUTH0_ID_TOKEN);
        ShowToast({ type: toastType.ERROR, message: "Session expired" });
        history.replace("/");
      }

      const userPayload = {
        name: response?.responseData?.name,
        email: response?.responseData?.email,
        profilePicture: response?.responseData?.profilePhoto,
        roleDetails: response?.responseData?.roleDetails,
        phone: response?.responseData?.phone,
        staffId: response?.responseData?._id,
        countryCode: response?.responseData?.countryCode,
      };

      dispatch({ type: "UPDATE_USER", payload: userPayload });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (userId) getStaffDetails();
    // eslint-disable-next-line
  }, []);

  const initMap = () => {
    const updateData = {
      gmapsLoaded: true,
    };
    dispatch({ type: "UPDATE_USER", payload: updateData });
  };

  useEffect(() => {
    window.initMap = initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&loading=async&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      {loader}
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/" component={index} />
          <PrivateRoute
            exact
            path="/user"
            component={Dashboard}
            permissions={["user:read:list"]}
          />

          <PrivateRoute
            exact
            path="/user/userDetails/:id"
            component={UserDetails}
            permissions={["user:read:details", "audit:read:details"]}
          />
          <PrivateRoute
            exact
            path="/user/pollDetails/:id"
            component={PollDetailsComponent}
            permissions={["user:read:details", "audit:read:details"]}
          />
          <PrivateRoute
            exact
            path="/entity-account"
            component={EntityComponent}
            permissions={["business:read:list"]}
          />

          <PrivateRoute
            exact
            path="/entity-account/create"
            component={CreateEntity}
            permissions={["business:write:entity"]}
          />
          <PrivateRoute
            exact
            path="/entity-account/details/:id"
            component={EntityDetails}
            permissions={["business:read:details", "audit:read:details"]}
          />
          <PrivateRoute
            exact
            path="/entity-account/poll/details/:id"
            component={EntityPollDetailsComponent}
            permissions={["business:read:details", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/moderations"
            component={ViolationsComponent}
            permissions={["report:read:list"]}
          />

          <PrivateRoute
            exact
            path="/moderations/post/:id"
            component={PostReport}
            permissions={["report:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/moderations/comment/:id"
            component={CommentReport}
            permissions={["report:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/moderations/violationDetails/:id"
            component={UserReport}
            permissions={["report:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/requests"
            component={RequestsComponent}
            permissions={["request:read:list"]}
          />

          <PrivateRoute
            exact
            path="/requests/blue-check/:id"
            component={BlueCheckComponent}
            permissions={["request:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/requests/account/:id"
            component={EntityCheck}
            permissions={["request:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/advertising"
            component={AdvertisingComponent}
            permissions={["advertising:read:list"]}
          />
          <PrivateRoute
            exact
            path="/advertising/account-create"
            component={CreateAdvertisingComponent}
            permissions={["advertising:write:create"]}
          />

          <PrivateRoute
            exact
            path="/advertising/details/:id"
            component={DetailsAdvertisingComponent}
            permissions={["advertising:read:list"]}
          />

          <PrivateRoute
            exact
            path="/promo-and-rewards"
            component={PromotionsComponent}
            permissions={["promo:read:list"]}
          />

          <PrivateRoute
            exact
            path="/promo-and-rewards/details/:id"
            component={PromotionDetails}
            permissions={["promo:read:list"]}
          />

          <PrivateRoute
            exact
            path="/promo-and-rewards/reward"
            component={OnlyRewardsComponent}
            permissions={["promo:read:list"]}
          />

          <PrivateRoute
            exact
            path="/promo-and-rewards/create-token"
            component={CreateTokens}
            permissions={["promo:write:create"]}
          />

          <PrivateRoute
            exact
            path="/promo-and-rewards/create-content"
            component={CreateContent}
            permissions={["promo:write:create"]}
          />

          <PrivateRoute
            exact
            path="/promo-and-rewards/create-votes"
            component={CreateVotes}
            permissions={"promo:write:create"}
          />

          <PrivateRoute
            exact
            path="/platform-parameters"
            component={AdditionalComponent}
            permissions={["field:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/platform-parameters/create"
            component={CreateComponent}
            permissions={["field:write:details"]}
          />

          <PrivateRoute
            exact
            path="/platform-parameters/channels"
            component={ChannelsComponent}
            permissions={["channels:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/platform-parameters/roles"
            component={RolesComponent}
            permissions={["roles:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/platform-parameters/income-split"
            component={IncomeSplitComponent}
            permissions={["income:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/platform-parameters/employees"
            component={EmployeesComponent}
            permissions={["position:read:list", "audit:read:details"]}
          />
          <PrivateRoute
            exact
            path="/platform-parameters/products-list"
            component={InAppProductCompoenent}
            permissions={["product:read:list"]}
          />

          <PrivateRoute
            exact
            path="/poll-list"
            component={PollListComponent}
            permissions={[
              "poll:read:list:active",
              "poll:read:list:rejected",
              "poll:read:list:finished",
            ]}
          />
          <PrivateRoute
            exact
            path="/poll-list/create"
            component={CreatePoll}
            permissions={["poll:write:create"]}
          />
          <PrivateRoute
            exact
            path="/poll-list/details/:id"
            component={PollDetailsCompoenent}
            permissions={[
              "poll:read:list:active",
              "poll:read:list:rejected",
              "poll:read:list:finished",
              "audit:read:details",
            ]}
          />

          <PrivateRoute
            exact
            path="/token"
            component={TokenComponent}
            permissions={[
              "token:read:balance",
              "token:read:bFactor",
              "token:read:token",
              "token:read:rValue",
              "audit:read:details",
            ]}
          />
          <PrivateRoute
            exact
            path="/token/view-transaction"
            component={ViewTransactions}
            permissions={["token:read:history"]}
          />
          <PrivateRoute
            exact
            path="/token/failed-transaction"
            component={FailedViewTransactions}
            permissions={["token:read:token"]}
          />
          <PrivateRoute
            exact
            path="/token/rValue-transaction"
            component={RValueHistory}
            permissions={["token:read:rHistory"]}
          />
          <PrivateRoute
            exact
            path="/platform-parameters/activity"
            component={ActivityPointComponent}
            permissions={["activity:read", "audit:read:details"]}
          />
          <PrivateRoute
            exact
            path="/platform-parameters/interest"
            component={InterestComponent}
            permissions={["interest:read", "audit:read:details"]}
          />
          <PrivateRoute
            exact
            path="/staff"
            component={StaffComponent}
            permissions={["staff:read:list", "audit:read:details"]}
          />

          <PrivateRoute
            exact
            path="/staff/activity/:id"
            component={Activity}
            permissions={["staff:read:details"]}
          />

          <PrivateRoute
            exact
            path="/statistics"
            component={StatisticsComponent}
            permissions={["statistics:read:data"]}
          />

          <PrivateRoute
            exact
            path="/audit-log"
            component={AuditLogComponent}
            permissions={["audit:read:list"]}
          />

          <PrivateRoute
            path="/profile"
            component={ProfileComponent}
            permissions={[
              "user:read:list",
              "business:read:list",
              "report:read:list",
              "request:read:list",
              "advertising:read:list",
              "promo:read:list",
              "position:read:list",
              "income:read:list",
              "roles:read:list",
              "channels:read:list",
              "poll:read:list:finished",
              "poll:read:list:rejected",
              "poll:read:list:active",
              "field:read:list",
              "activity:read",
              "interest:read",
              "staff:read:list",
              "statistics:read:data",
              "audit:read:list",
              "token:read:balance",
              "token:read:bFactor",
              "token:read:token",
              "token:read:rValue",
            ]}
          />
          <Route exact path="*" component={PageNotFound} />
        </Switch>
      </Suspense>
      <ToastContainer />
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return { loader: state.loading };
};
export default connect(mapStateToProps)(App);
