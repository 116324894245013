import React, { useEffect, useState } from "react";
import left from "../assets/Icons/Left.svg";
import right from "../assets/Icons/Right.svg";

/**
 * TableFooter component renders the pagination and page size selection controls for a table.
 *
 * @component
 * @example
 * // Example usage of TableFooter component
 * <TableFooter
 *   handlePageChange={/* function to handle page change * / () => {}}
 *   currentPage={/* current page number * / 0}
 *   totalPages={/* total number of pages * / 25}
 *   setPageSize={/* function to set the page size * / () => {}}
 *   pageSize={/* current page size * / 25}
 *   totalDocs={/* total number of documents/rows * /}
 *   setCurrentPage={/* function to set the current page * / () => {}}
 * />
 *
 * @param {Function} [handlePageChange=() => {}] - Function to handle page change.
 * @param {number} [currentPage=0] - Current page number.
 * @param {number} [totalPages=25] - Total number of pages.
 * @param {Function} [setPageSize=() => {}] - Function to set the page size.
 * @param {number} [pageSize=25] - Current page size.
 * @param {number} totalDocs - Total number of documents/rows.
 * @param {Function} [setCurrentPage=() => {}] - Function to set the current page.
 *
 * @returns {JSX.Element} The rendered TableFooter component.
 */

const TableFooter = ({
  handlePageChange = () => {},
  currentPage = 0,
  totalPages = 25,
  setPageSize = () => {},
  pageSize = 25,
  totalDocs,
  setCurrentPage = () => {},
}) => {
  const [skipDoc, setSkipDoc] = useState(0);

  useEffect(() => {
    if (currentPage !== 0 && totalDocs >= currentPage * pageSize) {
      setSkipDoc(currentPage * pageSize);
    }
    // eslint-disable-next-line
  }, [currentPage]);

  let displayedRange = "";

  if (currentPage === 0 && totalDocs > pageSize) {
    displayedRange = `1-${pageSize}`;
  } else if (currentPage === 0) {
    displayedRange = `1-${totalDocs}`;
  } else if (totalDocs >= currentPage * pageSize + pageSize) {
    displayedRange = `${skipDoc + 1}-${skipDoc + pageSize}`;
  } else {
    displayedRange = `${skipDoc + 1}-${totalDocs}`;
  }

  return (
    <div className="flex justify-end mt-auto w-full h-16.25 flex-shrink-0 rounded-b-20 bg-white">
      <div className="inline-flex items-center gap-10">
        <div className="flex items-start gap-1">
          <p className="text-black-50 font-ManropeLight text-ft2 opacity-50">
            Show per page:
          </p>
          <select
            id="itemsPerPage"
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setCurrentPage(0);
              setSkipDoc(0);
            }}
            value={pageSize}
            className="flex items-end gap-1.25 text-black-100 font-ManropeLight text-ft2"
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <p className="text-black-50 font-ManropeLight text-ft2 opacity-50">
          {displayedRange} of {totalDocs}
        </p>
        <div className="flex items-start gap-2.5">
          <img
            onClick={() => handlePageChange(currentPage - 1)}
            src={left}
            alt="left"
            className={`flex w-6.25 h-6.25 p-1.25 0 1.25 0 justify-center items-center ${
              currentPage > 0 ? "cursor-pointer" : "opacity-50"
            }`}
          />
          <img
            onClick={() => handlePageChange(currentPage + 1)}
            src={right}
            alt="right"
            className={`w-6.25 h-6.25 ${
              currentPage * pageSize + pageSize < totalDocs
                ? "cursor-pointer"
                : "opacity-50"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
