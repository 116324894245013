import moment from "moment";
import React from "react";
import hand from "../../assets/Icons/poll.png";
import utility from "../../utility";

/**
 * RightSideFields component displays various information related to polling period,
 * user accounts, business accounts, polls, surveys, and revenue.
 *
 * @component
 * @example
 * // Example usage of RightSideFields component
 * <RightSideFields
 *   data={{
 *     votes: {
 *       votesByDate: [
 *         { addedOn: "2022-01-01T12:00:00", date: "2022-01-01", value: 10 },
 *         // ...more vote data
 *       ],
 *     },
 *     userAccounts: { total: 100 },
 *     businessAccounts: { total: 50 },
 *     polls: { createdPolls: 20, activePolls: 15, closedPolls: 5 },
 *     surveys: { createdSurveys: 10, activeSurveys: 7, closedSurveys: 3 },
 *   }}
 *   startDate={new Date("2022-01-01")}
 *   endDate={new Date("2022-02-01")}
 * />
 *
 * @param {Object} data - Data object containing information about votes, user accounts, business accounts, polls, and surveys.
 * @param {Date} startDate - Start date of the polling period.
 * @param {Date} endDate - End date of the polling period.
 *
 * @returns {JSX.Element} The rendered RightSideFields component.
 */
const RightSideFields = ({ data, startDate, endDate }) => {
  const lastAddedOn = moment(
    data?.votes?.votesByDate[data?.votes?.votesByDate?.length - 1]?.addedOn
  );
  const firstAddedOn = moment(data?.votes?.votesByDate[0]?.addedOn);

  const duration = moment.duration(firstAddedOn.diff(lastAddedOn));

  const years = duration.years() || 0;
  const months = duration.months() || 0;
  const days = duration.days() || 0;
  const hours = duration.hours() || 0;

  return (
    <div className="flex flex-col w-25per gap-2.5">
      <div className="flex justify-between bg-brown-50 bg-opacity-90 w-full h-31.25 rounded-20 px-5 pt-5 pb-3.75">
        <div className="flex flex-col justify-between w-full">
          <p className="text-white font-ManropeBold text-ft32 whitespace-nowrap">
            Reporting period
          </p>
          <div className="flex gap-2 w-full justify-between flex-wrap">
            <div className="flex flex-col whitespace-nowrap">
              <p className="text-white text-opacity-50 font-ManropeMedium text-ft0">
                {data?.votes?.votesByDate[data?.votes?.votesByDate?.length - 1]
                  ?.date ||
                  `${startDate?.getFullYear()}-${
                    startDate?.getMonth() + 1
                  }-${startDate?.getDate()}`}

                {"-"}
              </p>
              <p className="text-white text-opacity-50 font-ManropeMedium text-ft0">
                {data?.votes?.votesByDate[0]?.date ||
                  `${endDate?.getFullYear()}-${
                    endDate?.getMonth() + 1
                  }-${endDate?.getDate()}`}
              </p>
            </div>
            <div className="flex items-end whitespace-nowrap">
              <p className="text-white font-ManropeBold text-ft2 xl:text-ft32 2xl:text-ft5 3xl:text-ft7 mb-3.75">
                {`${years !== 0 ? years + "y " : ""}${
                  months !== 0 ? months + "m " : ""
                }${days}d ${hours}h`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between rounded-20 bg-brown-50 bg-opacity-90 h-30.25">
        <div className="flex flex-col justify-between ml-5 my-5">
          <div className="flex justify-center items-center font-ManropeSemiBold py-1 px-2 text-ft0 text-black-300 rounded-20 bg-gold-50">
            User
          </div>
          <p className="text-white font-ManropeBold text-ft52">
            {utility.formatNumber(data?.userAccounts?.total)}
          </p>
        </div>
        <div className="flex">
          <img src={hand} alt="hand" />
        </div>
      </div>
      <div className="flex gap-2.5 w-full">
        {/* <div className="flex flex-col justify-between w-50per h-30.25 bg-brown-50 bg-opacity-90 rounded-20 py-5 px-auto 2xl:px-2 3xl:px-5">
          <p className="flex items-center justify-center px-1 2xl:px-2 py-1 w-max bg-gold-50 rounded-20 text-black-300 font-ManropeSemiBold text-ft0">
            Business accounts
          </p>
          <p className="text-white font-ManropeBold text-ft52 mx-2 2xl:mx-0">
            {" "}
            {utility.formatNumber(data?.businessAccounts?.total)}
          </p>
        </div> */}
        <div className="flex flex-col justify-between w-50per h-30.25 bg-brown-50 bg-opacity-90 rounded-20 p-5">
          <p className="flex items-center justify-center px-2 py-1 w-max bg-gold-50 rounded-20 text-black-300 font-ManropeSemiBold text-ft0">
            Created polls
          </p>
          <p className="text-white font-ManropeBold text-ft52">
            {utility.formatNumber(data?.polls?.createdPolls)}
          </p>
        </div>
        <div className="flex flex-col justify-between w-50per h-30.25 bg-brown-50 bg-opacity-90 rounded-20 p-5">
          <p className="flex items-center justify-center px-2 py-1 w-max bg-gold-50 rounded-20 text-black-300 font-ManropeSemiBold text-ft0">
            Active polls
          </p>
          <p className="text-white font-ManropeBold text-ft52">
            {" "}
            {utility.formatNumber(data?.polls?.activePolls)}
          </p>
        </div>
      </div>
      <div className="flex gap-2.5 w-full">
        <div className="flex flex-col justify-between w-50per h-30.25 bg-brown-50 bg-opacity-90 rounded-20 p-5">
          <p className="flex items-center justify-center px-2 py-1 bg-gold-50  w-max rounded-20 text-black-300 font-ManropeSemiBold text-ft0">
            Closed polls
          </p>
          <p className="text-white font-ManropeBold text-ft52">
            {" "}
            {utility.formatNumber(data?.polls?.closedPolls)}
          </p>
        </div>
        <div className="flex flex-col justify-between w-50per h-30.25 bg-brown-50 bg-opacity-90 rounded-20 py-5 px-auto xl:px-2 3xl:px-5">
          <p className="flex items-center justify-center px-2 py-1 w-max bg-gold-50 rounded-20 text-black-300 font-ManropeSemiBold text-ft0">
            Created surveys
          </p>
          <p className="text-white font-ManropeBold text-ft52 mx-2 2xl:mx-0">
            {" "}
            {utility.formatNumber(data?.surveys?.createdSurveys)}
          </p>
        </div>
      </div>

      <div className="flex gap-2.5 w-full">
        <div className="flex flex-col justify-between w-50per h-30.25 bg-brown-50 bg-opacity-90 rounded-20 py-5 px-auto xl:px-2 3xl:px-5">
          <p className="flex items-center justify-center px-2 py-1 w-max bg-gold-50  rounded-20 text-black-300 font-ManropeSemiBold text-ft0">
            Active surveys
          </p>
          <p className="text-white font-ManropeBold text-ft52 mx-2 2xl:mx-0">
            {" "}
            {utility.formatNumber(data?.surveys?.activeSurveys)}
          </p>
        </div>
        <div className="flex flex-col justify-between w-50per h-30.25 bg-brown-50 bg-opacity-90 rounded-20 py-5 px-auto xl:px-2 3xl:px-5">
          <p className="flex items-center justify-center px-2 py-1 w-max bg-gold-50  rounded-20 text-black-300 font-ManropeSemiBold text-ft0">
            Closed surveys
          </p>
          <p className="text-white font-ManropeBold text-ft52 mx-2 2xl:mx-0">
            {" "}
            {utility.formatNumber(data?.surveys?.closedSurveys)}
          </p>
        </div>
      </div>
      <div className="flex gap-2.5 w-full">
        {/* <div className="flex flex-col justify-between w-50per h-30.25 bg-white rounded-20 p-5">
          <p className="flex items-center justify-center px-2 py-1 w-max bg-yellow-50 rounded-20 text-black-150 font-ManropeMedium text-ft0">
            Cash revenue
          </p>
          <p className="text-black-100 font-ManropeBold text-ft52">$1500</p>
        </div> */}
      </div>

      {/* <div className="flex justify-between w-full h-33.5 rounded-20 bg-white px-5 pb-5">
        <div className="flex flex-col justify-between pt-5">
          <p className="flex items-center justify-center px-2 py-1 w-max bg-red-75 rounded-20 text-black-150 font-ManropeMedium text-ft0">
            Token revenue
          </p>
          <p className="text-black-100 font-ManropeBold text-ft4 3xl:text-ft52">
            35 415 421 PPT
          </p>
        </div>
        <img src={pollPapaCoin} alt="token" />
      </div> */}
    </div>
  );
};

export default RightSideFields;
