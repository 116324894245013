import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { ReactComponent as Close } from "../assets/Icons/close.svg";
import {
  eventConstants,
  optionsRole,
  toastType,
} from "../constant/commonArray";
import UserEntityService from "../services/userEntityService";
import { UserService } from "../services";
import Dropdown from "./component/dropdown";
import PhoneNumberDropdown from "./component/phoneNumberDropdown";
import ShowToast from "./showToast";
import utility from "../utility";
import parsePhoneNumberFromString from "libphonenumber-js";

/**
 * EditPopup component for editing user information.
 * @component
 * @param {Object} props - The properties of the component.
 * @returns {JSX.Element} EditPopup component.
 */

const EditPopup = ({
  name,
  value,
  setEditPopup,
  setState,
  keyName,
  state,
  handleSubmission = () => {},
  flagSvg,
  arr,
  countryCode,
  loading = false,
}) => {
  const dispatch = useDispatch();
  const [debounceValue, setDebounceValue] = useState("");
  const [jobList, setJobList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [loadingPosition, setLoadingPosition] = useState(false);
  const [error, setError] = useState({});
  const [userNameLength, setUserNameLength] = useState(false);

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 300);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      countryCode: countryCode,
      [keyName]: value,
    }));

    // eslint-disable-next-line
  }, [value, countryCode, keyName, setState]);

  const checkUserName = async (username) => {
    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      const [error, userNameRes] = await utility.parseResponse(
        new UserEntityService().checkUserNameExists(username)
      );
      if (error || !userNameRes?.available) {
        setError((prev) => ({ ...prev, usernameError: true }));
      } else {
        setError({});
      }
    } catch (error) {
      ShowToast({ message: error?.message, type: toastType.ERROR });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  const handleUserNameChange = (e) => {
    const newValue = e.target.value;

    if (newValue.length <= 30) {
      setUserNameLength(false);
      setState((prev) => ({
        ...prev,
        userName: newValue.includes("@") ? newValue : `@${newValue}`,
      }));
      debouncedSearch(newValue);
    } else {
      setUserNameLength(true);
    }
  };

  const getJobsList = async () => {
    setLoadingPosition(true);
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().getJobList()
      );
      if (!err) setJobList(transformResponse(res));
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingPosition(false);
    }
  };

  const getRoleList = async () => {
    setLoadingPosition(true);
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().getRoleList()
      );
      if (!err) setRoleList(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingPosition(false);
    }
  };

  const transformResponse = (originalRes) =>
    originalRes.map(({ departmentName, jobs }) => ({
      department: departmentName,
      position: jobs.map((position) => position.name),
    }));

  const handleCountryCode = (countryCode) => {
    setState((prev) => ({ ...prev, countryCode }));
  };

  const isValidPhoneNumber = (phoneNumber, countryCode) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      countryCode
    );
    return parsedPhoneNumber && parsedPhoneNumber.isValid();
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^(?!.*(\d)\1{6})(?!.*1234567890)(?!.*9876543210).*$/;
    return regex.test(phoneNumber);
  };

  useEffect(() => {
    if (debounceValue) checkUserName(debounceValue);
    if (keyName === "jobPosition") getJobsList();
    if (keyName === "role" || keyName === "roleId") getRoleList();
    // eslint-disable-next-line
  }, [debounceValue, keyName]);

  const getButtonClass = () => {
    const commonConditions =
      (keyName !== "phone" && !state?.[keyName]?.trim()) ||
      Object.keys(error).length !== 0 ||
      userNameLength ||
      loading;

    if (keyName === "phone") {
      const phoneConditions =
        (value === state?.[keyName] && countryCode === state?.countryCode) ||
        !isValidPhoneNumber(
          `${state?.countryCode}${parseInt(state?.phone)}`,
          state?.countryCode
        ) ||
        !validatePhoneNumber(state?.phone);
      return phoneConditions || commonConditions
        ? "bg-grey-50"
        : "bg-orange-50";
    }

    return value?.trim() === state?.[keyName]?.trim() || commonConditions
      ? "bg-grey-50"
      : "bg-orange-50";
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const isUserName = keyName === "userName";
    const isFullName = keyName === "fullName" && name !== "Company name";
    const isCompanyName = name === "Company name";

    if (isUserName) {
      handleUserNameChange(e);
    } else if (isFullName || keyName === "name") {
      if (inputValue.length <= 50 && /^[a-zA-Z\s]*$/.test(inputValue)) {
        setError({});
        setState((prev) => ({ ...prev, [keyName]: inputValue }));
      } else {
        setError((prev) => ({ ...prev, fullNameError: true }));
      }
    } else if (isCompanyName) {
      if (inputValue.length <= 60) {
        setError({});
        setState((prev) => ({ ...prev, [keyName]: inputValue }));
      } else {
        setError((prev) => ({ ...prev, companyNameError: true }));
      }
    } else {
      setState((prev) => ({ ...prev, [keyName]: inputValue }));
    }
  };

  const renderInput = () => {
    switch (keyName) {
      case "phone":
        return (
          <div className="flex flex-col">
            <div className="flex h-16.25 py-2.5 px-7.5 justify-start items-center flex-shrink-0 self-stretch bg-grey-50 rounded-full text-black-150 text-ft2 font-ManropeRegular">
              <div className="flex gap-4 items-center w-full h-full">
                <PhoneNumberDropdown
                  state={state?.countryCode}
                  setState={handleCountryCode}
                />
                <input
                  type="text"
                  value={state?.phone || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d{0,15}$/.test(inputValue)) {
                      setState((prev) => ({
                        ...prev,
                        phone: inputValue,
                      }));
                    }
                  }}
                  className="focus:outline-none bg-grey-50 h-full w-full text-ft2 font-ManropeRegular text-black-100 pl-4"
                />
              </div>
            </div>
            {(!isValidPhoneNumber(
              `${state?.countryCode}${parseInt(state?.phone)}`,
              state?.countryCode
            ) ||
              !validatePhoneNumber(state?.phone)) &&
              state?.phone !== "" && (
                <span className="text-red-50">
                  Please enter the valid phone number
                </span>
              )}
          </div>
        );
      case "role":
      case "jobPosition":
        return (
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              {name}
            </label>
            <Dropdown
              options={keyName === "role" ? optionsRole : jobList}
              placeHolder={"Not chosen"}
              value={state?.[keyName] || ""}
              setValue={(selectedValue) =>
                setState((prev) => ({ ...prev, [keyName]: selectedValue }))
              }
              keyName={keyName}
            />
          </div>
        );
      case "roleId":
        return (
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              {name}
            </label>
            <Dropdown
              options={roleList}
              placeHolder={"Not chosen"}
              value={state?.role || ""}
              setValue={setState}
              keyName={keyName}
            />
          </div>
        );
      default:
        return (
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              {name}
            </label>
            <input
              type="text"
              value={state?.[keyName] || ""}
              onChange={(e) => handleChange(e)}
              className="rounded-full bg-grey-50 outline-none px-6 h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
            {error?.usernameError && (
              <span className="text-red-50">Username is already taken</span>
            )}
            {userNameLength && (
              <span className="text-red-50">
                Username can't be more than 30
              </span>
            )}
            {error?.fullNameError && (
              <span className="text-red-50">Name can't be more than 50</span>
            )}
            {error?.companyNameError && (
              <span className="text-red-50">
                Company Name can't be more than 60
              </span>
            )}
          </div>
        );
    }
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 fixed flex justify-center items-center right-0 top-0 h-full w-full">
      {!loadingPosition ? (
        <div className="bg-white relative flex flex-col h-2/3 w-115 z-50 p-7.5 rounded-20">
          <div className="flex justify-between">
            <span className="text-black-100 font-ManropeBold text-ft4">{`Edit ${name}`}</span>
            <Close
              onClick={() => setEditPopup(null)}
              className="cursor-pointer"
            />
          </div>
          <div className="mt-7.5 w-full flex flex-col justify-between h-full">
            {renderInput()}
          </div>
          <div>
            <button
              disabled={getButtonClass() === "bg-grey-50"}
              onClick={handleSubmission}
              className={`rounded-full w-full mt-auto h-16.25 text-black-100 font-ManropeBold text-ft2 ${getButtonClass()}`}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-white relative flex flex-col h-2/3 w-115 z-50 p-7.5 rounded-20">
          <div className="flex justify-center items-center h-full w-full">
            <CircularProgress className="text-black-100 w-5 h-5" />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditPopup;
