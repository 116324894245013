/**
 * @module AdditionalField
 * @description
 * Module for managing and rendering additional fields.
 *
 * @example
 * // Usage in a React functional component
 * import AdditionalField from "./AdditionalField";
 *
 * const MyComponent = () => {
 *   return <AdditionalField />;
 * };
 *
 * @returns {React.Component} The AdditionalField component
 */

import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/Icons/plus.svg";
import Table from "../../common/table";
import placeholder from "../../assets/Icons/Placeholder.svg";
import deletes from "../../assets/Icons/delete.svg";
import more from "../../assets/Icons/More.svg";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import WarningPopup from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import { UserService } from "../../services";
import utility from "../../utility";
import OutsideClickRef from "../../common/outsideClickRef";
import { toastType } from "../../constant/commonArray";
import { useSelector } from "react-redux";
import left from "../../assets/Icons/Left.svg";

/**
 * Functional component for rendering and managing additional fields.
 * @returns {JSX.Element} AdditionalField component
 */
const AdditionalField = () => {
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState({
    name: "",
    id: "",
  });
  const [fieldId, setFieldId] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addtionaList, setAdditionalList] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [sort, setSort] = useState(-1);
  const optionsRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const totalPages = Math.ceil(totalDocs / pageSize);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const headers = [
    { name: "Created", sort: true },
    { name: "Name" },
    { name: "Question" },
    { name: "Answers" },
  ];

  /**
   * Asynchronously retrieves additional fields and updates the state with the response.
   *
   */
  const getAddtionalFields = async () => {
    setLoading(true);
    let requestParams = {
      sort: sort,
      limit: pageSize,
      skip: currentPage,
    };

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getAdditionalList(requestParams)
      );
      if (error || !response) return;
      setAdditionalList(response?.additionalFields);
      setTotalDocs(response?.totalCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  /**
   * An asynchronous function to handle the deletion of a field.
   *
   * @param {string} id - The ID of the field to be deleted
   * @param {string} name - The name of the field to be deleted
   * @return {void}
   */
  const handleDeleteField = async (id, name) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deleteField(id)
      );
      if (error || !response) return;
      if (response?.success) {
        ShowToast({
          message: name + " deleted successfully",
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      getAddtionalFields();
      setShowWarning(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAddtionalFields();
    // eslint-disable-next-line
  }, [sort, currentPage, pageSize]);

  /**
   * Renders the table body based on the additional list.
   *
   * @return {JSX.Element} The table body JSX element
   */
  const renderTableBody = () => {
    if (addtionaList?.length > 0) {
      return addtionaList?.map((item) => (
        <tr
          key={item?._id}
          className="hover:bg-grey-350 relative cursor-pointer"
        >
          <td className="py-5 px-5 font-ManropeMedium text-ft2 text-black-50 cursor-pointer w-30per">
            {moment(item?.addedOn).format("DD MMM[,] YYYY")}
          </td>
          <td className="font-ManropeMedium text-ft2 text-black-50 w-30per">
            {item?.name}
          </td>
          <td className="font-ManropeMedium text-ft2 text-black-50 w-1/4 pr-1/3">
            {item?.question}
          </td>
          <td className="font-ManropeMedium text-ft2 text-black-50">
            {item?.optionsCount}
          </td>
          <td>
            {userPermissions?.indexOf("field:write:details") > -1 && (
              <img
                src={more}
                alt="More"
                onClick={() => {
                  setFieldId(item?._id);
                }}
                className="cursor-pointer"
              />
            )}
            {fieldId === item?._id && (
              <OutsideClickRef
                setState={() => setFieldId(null)}
                state={optionsRef}
              >
                <button
                  className="flex items-center absolute w-75 p-5 gap-3.75 top-18 right-5 rounded-20 shadow-inner z-10 bg-white"
                  onClick={() => {
                    setShowWarning(true);
                    setSelectedItem((prev) => ({
                      ...prev,
                      name: item?.name,
                      id: item?._id,
                    }));
                  }}
                >
                  <img src={deletes} alt="deletes" />
                  <p className="text-black-100 font-ManropeRegular text-ft3">
                    Delete
                  </p>
                </button>
              </OutsideClickRef>
            )}
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={7}>
            <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
              <img src={placeholder} alt="placeholder" />
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="py-5 w-full h-full overflow-y-auto pr-12.5">
      {(userPermissions?.indexOf("field:write:details") > -1 || haveAudit) && (
        <div
          className={`flex ${
            haveAudit ? "justify-between" : "justify-end"
          } items-center bg-white p-5 rounded-20 gap-5`}
        >
          {haveAudit && (
            <div
              onClick={() => {
                if (haveAudit) {
                  history.push("/audit-log");
                }
              }}
            >
              <img src={left} alt="left" className="cursor-pointer" />
            </div>
          )}
          {userPermissions?.indexOf("field:write:details") > -1 && (
            <div
              className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center relative cursor-pointer"
              onClick={() => {
                history.push("/platform-parameters/create");
              }}
            >
              <AddIcon />
            </div>
          )}
        </div>
      )}
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        sort={sort}
        setSort={setSort}
        loading={loading}
        setPageSize={setPageSize}
        pageSize={pageSize}
        totalDocs={totalDocs}
        handlePageChange={handlePageChange}
      />
      {showWarning && (
        <WarningPopup
          title={`Delete “${selectedItem?.name}”?`}
          description={"Are you sure? You want to delete field"}
          setToggle={setShowWarning}
          handleActivateUser={() =>
            handleDeleteField(selectedItem?.id, selectedItem?.name)
          }
        />
      )}
    </div>
  );
};

export default AdditionalField;
