/**
 * @module PromotionDetails
 * @description
 * Module for managing and rendering additional fields.
 *
 * @example
 * // Usage in a React functional component
 * import PromotionDetails from "./PromotionDetails";
 *
 * const MyComponent = () => {
 *   return <PromotionDetails />;
 * };
 *
 * @returns {React.Component} The PromotionDetails component
 */

import React, { useEffect, useState } from "react";
import error from "../../assets/Icons/Error.svg";
import WarningPopup from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import { useParams, useHistory } from "react-router-dom";
import { UserService } from "../../services";
import { toastType } from "../../constant/commonArray";
import { CircularProgress } from "@mui/material";
import utility from "../../utility";
import PreviewPopUp from "../../common/previewPopUp";
import DetailsAds from "../../common/detailsAds";
import UserEntityService from "../../services/userEntityService";

/**
 * Functional component for displaying promotion details and handling related actions.
 *
 * @return {JSX.Element} The JSX element to display promotion details and handle related actions.
 */
const PromotionDetails = () => {
  const [isStopPopUp, setStopPopUp] = useState(false);
  const [isDeletePopUp, setDeletePopUp] = useState(false);
  const [isUserExpanded, setUserExpanded] = useState(false);
  const [details, setDetails] = useState([]);
  const [previewPopUp, setPreviewPopUp] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    status: "",
    popUp: false,
    name: "",
  });
  const { id } = useParams();

  /**
   * A function that retrieves details and sets loading state.
   *
   * @param {} -
   * @return {}
   */
  const getDetails = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().promotionDetails(id)
      );
      if (error || !response) return;
      setDetails(response[0]);
      getUserCount(response[0]);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  /**
   * Handles the click on the "more" button for a specific item.
   *
   * @param {object} item - The item for which the "more" button was clicked
   * @return {void}
   */
  const handleMoreClick = (item) => {
    if (selectedItem?.popUp) {
      setSelectedItem({ popUp: false });
    } else {
      setSelectedItem({
        status: item?.status,
        popUp: true,
        name: item?.name,
      });
    }
  };

  /**
   * Asynchronously handles stopping a promotion.
   *
   * @param {type} id - The ID of the promotion to stop
   * @return {type} undefined
   */
  const handleStopPromotion = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().stopPromotion(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setSelectedItem({
        popUp: false,
        status: "",
        name: "",
      });
      setStopPopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Asynchronously handles the deletion of a promotion.
   *
   * @param {type} id - The ID of the promotion to be deleted
   * @return {type} undefined
   */
  const handleDeletePromotion = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deletePromotion(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        history.push("/promo-and-rewards");
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setSelectedItem({
        popUp: false,
        status: "",
        name: "",
      });
      setDeletePopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Redirects to a specific URL based on the provided item and id.
   *
   * @param {string} item - The item to determine the redirection URL.
   * @param {string} id - The id to be included in the redirection URL.
   * @return {void}
   */
  const handleDuplicateRedirect = (item, id) => {
    switch (item) {
      case "FREE_TOKENS":
        history.push(`/promo-and-rewards/create-token?duplicate=${id}`);
        break;
      case "VOTES":
        history.push(`/promo-and-rewards/create-votes?duplicate=${id}`);
        break;
      case "CONTENT":
        history.push(`/promo-and-rewards/create-content?duplicate=${id}`);
        break;
      default:
    }
  };
  /**
   * Asynchronously retrieves the user count based on the provided data.
   *
   * @param {Object} data - the data object containing audience reach information
   * @return {undefined} - no explicit return value
   */
  const getUserCount = async (data) => {
    try {
      let requestData = {
        raceOrEthnicity:
          data?.audienceReach?.race?.length > 0
            ? data?.audienceReach?.race
            : undefined,
        audience:
          data?.audienceReach?.audience?.length > 0
            ? data?.audienceReach?.audience
            : undefined,
        gender:
          data?.audienceReach?.gender?.length > 0
            ? data?.audienceReach?.gender
            : undefined,
        verifiedUsers:
          data?.audienceReach?.verifiedUsers?.length > 0
            ? data?.audienceReach?.verifiedUsers
            : undefined,
        location:
          data?.audienceReach?.location?.length > 0
            ? data?.audienceReach?.location
            : undefined,
        age:
          data?.audienceReach?.age?.length > 0
            ? data?.audienceReach?.age?.map((range) => {
                const [min, max] = range.split(/-|and/);
                if (max === " older") return { min: parseInt(min), max: 400 };
                return { min: parseInt(min), max: parseInt(max) };
              })
            : undefined,
      };

      const [error, response] = await utility.parseResponse(
        new UserEntityService().getUserCount(requestData)
      );

      if (error || !response) return;
      setUserCount(response?.totalRespondents);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex w-full overflow-y-auto mt-5 mb-5 pr-12.5 gap-5 bg-grey-50">
      <DetailsAds
        details={details}
        error={error}
        id={id}
        isUserExpanded={isUserExpanded}
        selectedItem={selectedItem}
        setDeletePopUp={setDeletePopUp}
        setStopPopUp={setStopPopUp}
        handleDuplicate={handleDuplicateRedirect}
        setPreviewPopUp={setPreviewPopUp}
        setSelectedItem={setSelectedItem}
        handleMoreClick={handleMoreClick}
        setUserExpanded={setUserExpanded}
        keyName="promotion"
        userCount={userCount}
        permissionDuplicate={"advertising:write:duplicate"}
        permissionStop={"advertising:write:stop"}
        permissionDelete={"advertising:write:delete"}
      />
      {isDeletePopUp && (
        <WarningPopup
          title={`Delete ${selectedItem?.name}`}
          description={"Are you sure? You want to delete Promotion"}
          handleActivateUser={() => handleDeletePromotion(id)}
          setToggle={setDeletePopUp}
        />
      )}
      {isStopPopUp && (
        <WarningPopup
          title={`Stop ${selectedItem?.name}`}
          description={"Are you sure? You want to stop Promotion"}
          handleActivateUser={() => handleStopPromotion(id)}
          setToggle={setStopPopUp}
        />
      )}

      {previewPopUp && (
        <PreviewPopUp
          previewPopUp={previewPopUp}
          setPreviewPopUp={setPreviewPopUp}
          state={details}
          croppedImage={details?.media[0]?.url}
          account={details?.account}
        />
      )}
    </div>
  );
};

export default PromotionDetails;
