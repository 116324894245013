import React, { useEffect, useRef, useState } from "react";
import left from "../../assets/Icons/Left.svg";
import DetailsProfile from "../../common/detailsProfile";
import pause from "../../assets/Icons/pause.svg";
import ViolationsInfo from "./violationsInfo";
import DetailsBalance from "../../common/detailsBalance";
import DetailsGeneralInfo from "../entityAccount/details/detailsGeneralInfo";
import DetailsContact from "../../common/detailsContact";
import DisablePopUp from "../../common/disablePopUp";
import ParticularPoll from "../../common/particularPoll";
import utility from "../../utility";
import { UserService } from "../../services";
import placeholder from "../../assets/Icons/Placeholder.svg";
import defaultImage from "../../assets/Icons/signupProfileSmall.png";
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import WarningPopup from "../../common/warningPopup";
import SuspendedUserPopUp from "../DashboardPage/details/SuspendedUserPopUp";
import tick from "../../assets/Icons/Tick.svg";
import time from "../../assets/Icons/Time.svg";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import { CircularProgress } from "@mui/material";
import OutsideClickRef from "../../common/outsideClickRef";
import { useSelector } from "react-redux";
import WalletService from "../../services/walletService";
import BlockChainService from "../../services/blockChainService";
import TableFooter from "../../common/tableFooter";

/**
 * Component for displaying details of a post.
 *
 * @component
 * @example
 * // Usage of the component
 * <PostDetails />
 */

const PostDetails = () => {
  const [isUserExpanded, setUserExpanded] = useState(false);
  const [isViolations, setViolations] = useState("violations");
  const [isInfo, setInfo] = useState("");
  const [isGeneralExpanded, setGeneralExpanded] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisablePopUp, setDisablePopUp] = useState(false);
  const [violationDetails, setViolationDetails] = useState([]);
  const [details, setDetails] = useState({});
  const [pollDetails, setPollDetails] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const [comment, setComment] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [commentDetails, setCommentDetails] = useState([]);
  const [seenActivatePopUp, setSeenActivatePopUp] = useState(false);
  const [suspendType, setSuspendType] = useState("");
  const [profileType, setProfileType] = useState("");
  const optionsRef = useRef(null);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const tabType = queryParams.get("tab");
  const haveAudit = queryParams.get("from");
  const [balance, setBalance] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [commentId, setCommentId] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  const totalPages = Math.ceil(totalDocs / pageSize);

  const [popUpVisible, setPopUpVisible] = useState({
    popUp: false,
    keyName: "",
  });

  /**
   * Fetch post details from the server.
   *
   * @async
   * @function
   * @private
   */

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getDetails = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getViolationDetails(id)
      );

      if (error || !response) return;
      getCommentDetails(response?.postDetails?._id);
      setDetails(response);
      setPollDetails(response?.postDetails);
      setCommentId(response?.postDetails?._id);
      setViolationDetails(response?.violationsWithCount);
      setProfileType(
        response?.userDetails?.role === "USER"
          ? response?.userDetails?.isBlueTickVerified
          : response?.userDetails?.entityType
      );

      setUserExpanded(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getCommentDetails = async (id) => {
    setPageLoading(true);
    try {
      const requestData = {
        limit: pageSize,
        skip: currentPage,
      };
      const [errComment, resComment] = await utility.parseResponse(
        new UserService().getUserComment(id, requestData)
      );
      if (errComment || !resComment) return;
      setCommentDetails(resComment[0]?.comments);
      setTotalDocs(resComment[0]?.totalCount);
    } catch (error) {
      console.error(error);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (commentId) getCommentDetails(commentId);
    // eslint-disable-next-line
  }, [pageSize, currentPage]);
  /**
   * Handles the activation of a suspended user.
   *
   * @async
   * @function
   * @param {string} id - The user ID.
   * @private
   */

  const handleActivateUser = async (id) => {
    let response;
    setUpdatingUser(true);
    try {
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getDetails();
      setSeenActivatePopUp(false);
      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };
  const togglePopUpVisble = (value) => {
    setPopUpVisible((prev) => ({
      ...prev,
      popUp: !popUpVisible.popUp,
      keyName: value,
    }));
    setUserExpanded(false);
  };

  /**
   * Renders the profile options based on the user status.
   *
   * @function
   * @private
   * @param {string} status - The user status.
   * @returns {JSX.Element|null} - JSX for profile options.
   */

  const renderProfileDots = (status) => {
    return status === "ACTIVE" && isUserExpanded ? (
      <OutsideClickRef
        state={optionsRef}
        setState={() => setUserExpanded(false)}
      >
        <div
          onClick={() => togglePopUpVisble("suspend")}
          className="absolute right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-10 bg-white"
        >
          <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
            <img src={pause} alt="Pause" />
            <p className="text-ft3 font-ManropeLight text-black-100">Suspend</p>
          </button>
        </div>
      </OutsideClickRef>
    ) : (
      isUserExpanded && status === "SUSPENDED" && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setUserExpanded(false)}
        >
          <div className="absolute flex flex-col right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-10 bg-white">
            <button
              onClick={() => setSeenActivatePopUp(!seenActivatePopUp)}
              className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
            >
              <img src={tick} alt="tick" />
              <p className="text-ft3 font-ManropeRegular text-black-100">
                Activate
              </p>
            </button>
            <button
              className="flex h-16.25 px-5 items-center gap-3.75 shadow-custom"
              onClick={() => togglePopUpVisble("edit")}
            >
              <img src={time} alt="time" />
              <p className="text-ft3 font-ManropeRegular text-black-100">
                Edit Suspended time
              </p>
            </button>
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Handles the rejection of a post violation.
   *
   * @async
   * @function
   * @private
   */
  const handleRejectViolation = async () => {
    let response;
    try {
      response = await new UserService().rejectViolation(id);
      if (response?.statusCode === 400) {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Report rejected",
          type: toastType.SUCCESS,
        });
        history.push("/moderations");
      }
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
    }
  };

  const getAddress = async () => {
    try {
      const [err, res] = await utility.parseResponse(
        new WalletService().getAddress(details?.userDetails?._id)
      );
      if (err || !res) return;

      const [error, response] = await utility.parseResponse(
        new BlockChainService().getBalance(res?.responseData?.publicKey)
      );
      if (error && !response) return;

      setBalance(response?.balance);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };
  useEffect(() => {
    if (Object.keys(details)?.length > 0) getAddress();
    // eslint-disable-next-line
  }, [details]);

  useEffect(() => {
    const suspend = utility.getSuspensionType(
      details?.userDetails?.suspension?.type,
      details?.userDetails?.suspension?.period?.startDate,
      details?.userDetails?.suspension?.period?.endDate
    );
    setSuspendType(suspend);
  }, [details]);

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }
  return (
    <div className="w-full pr-12.5 overflow-y-auto mt-5 pb-5">
      <div className="flex gap-5">
        <div className="w-75per ">
          <div className=" h-21.25 px-5 flex justify-between items-center rounded-20 bg-white row-start-1 row-span-1">
            <div className="flex gap-7.5">
              <img
                onClick={() => {
                  if (haveAudit) {
                    history.push("/audit-log");
                  } else {
                    history.push(`/moderations?tab=${tabType}`);
                  }
                }}
                src={left}
                alt="left"
                className="cursor-pointer"
              />
              <div className="flex gap-10">
                <div onClick={() => setComment(false)}>
                  <p
                    className={` ${
                      !comment
                        ? "font-ManropeBold text-ft5 text-black-100"
                        : "font-ManropeMedium text-ft3 text-black-200"
                    } cursor-pointer`}
                  >
                    {`${
                      !details?.violationDetails?.isDisabled
                        ? "Report"
                        : "Reported"
                    }`}{" "}
                    on Post
                  </p>
                </div>
                <div onClick={() => setComment(true)} className="flex gap-1">
                  <p
                    className={` ${
                      comment
                        ? "font-ManropeBold text-ft5 text-black-100"
                        : "font-ManropeMedium text-ft3 text-black-200"
                    } cursor-pointer`}
                  >
                    Comments
                  </p>
                  <p className="font-ManropeBold text-ft3 text-black-200">
                    {commentDetails?.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {!comment ? (
            <div className="flex flex-col w-full mb-5 mt-5 gap-5 flex-shrink-0 rounded-20 bg-white">
              <ParticularPoll details={pollDetails} />
              {!!pollDetails &&
                !details?.violationDetails?.isDisabled &&
                userPermissions?.indexOf("report:write:post") > -1 && (
                  <footer className="flex justify-end items-center pr-5 py-5 h-107px gap-5">
                    <button
                      onClick={handleRejectViolation}
                      className="flex justify-center items-center rounded-full border border-solid border-black-20 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
                    >
                      Approved to post
                    </button>
                    <button
                      className="flex justify-center items-center rounded-full border border-solid border-transparent bg-orange-50 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
                      onClick={() => setDisablePopUp(!isDisablePopUp)}
                    >
                      Disable post
                    </button>
                  </footer>
                )}
            </div>
          ) : (
            <div className="flex flex-col w-full mb-5 mt-5 gap-5 flex-shrink-0 rounded-20 bg-white">
              {commentDetails.length > 0 ? (
                <div className="flex flex-col overflow-y-scroll scrollbar-hide max-h-80vh">
                  {!pageLoading ? (
                    commentDetails.map((item) => (
                      <div
                        className={`flex justify-between mt-5 mx-2.5 pl-5 pr-2.5 py-3.75 relative`}
                        key={item?._id}
                      >
                        <div className="flex gap-3.75">
                          <img
                            src={item?.commentor?.profilePhoto || defaultImage}
                            alt="userProfile"
                            className="w-11.25 h-11.25 rounded-20"
                          />
                          <div className="flex flex-col gap-2.5">
                            <p className="font-ManropeExtraBold text-ft3 text-black-100">
                              {item?.commentor?.fullName}
                            </p>
                            <p className="font-ManropeRegular text-ft3 text-black-100">
                              {item?.description}
                            </p>
                            <p className="font-ManropeRegular text-ft3 text-grey-200">
                              {utility.getTimeDifference(item?.addedOn)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center  items-center h-100 w-full">
                      <CircularProgress className="text-black-100 w-5 h-5" />
                    </div>
                  )}
                  <div className="px-4">
                    <TableFooter
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      totalPages={totalPages}
                      totalDocs={totalDocs}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col overflow-y-scroll scrollbar-hide max-h-80vh">
                  <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
                    <img src={placeholder} alt="placeholder" />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-5 relative w-25per">
          <DetailsProfile
            name={details?.userDetails?.fullName || "-"}
            username={details?.userDetails?.userName || "-"}
            renderPopUp={renderProfileDots(details?.userDetails?.status)}
            setUserExpanded={setUserExpanded}
            profile={details?.userDetails?.profilePhoto}
            isUserExpanded={isUserExpanded}
            isDisabled={details?.violationDetails?.isDisabled}
            profileType={profileType}
            permissionSuspend={
              details?.userDetails?.role === "USER"
                ? "user:write:suspend"
                : "business:write:suspend"
            }
            permissionActive={
              details?.userDetails?.role === "USER"
                ? "user:write:activate"
                : "business:write:activate"
            }
            status={details?.userDetails?.status}
          />
          <ViolationsInfo
            details={details?.userDetails}
            violations={isViolations}
            setViolations={setViolations}
            info={isInfo}
            setInfo={setInfo}
            violationDetails={violationDetails}
            disabledDetails={pollDetails?.disable}
            isDisabled={details?.violationDetails?.isDisabled}
          />

          {isInfo === "info" && (
            <>
              <DetailsBalance
                price={balance || 0}
                fontSize={"text-ft6"}
                rewardAmount={details?.userDetails?.rewardAmount || 0}
              />
              <DetailsContact
                details={details?.userDetails}
                getDetails={getDetails}
                keyName="violation"
              />
              <DetailsGeneralInfo
                isDropDownOpen={isDropDownOpen}
                setIsDropDownOpen={setIsDropDownOpen}
                setGeneralExpanded={setGeneralExpanded}
                isGeneralExpanded={isGeneralExpanded}
                details={details?.userDetails}
                stateId={details?.userDetails?._id}
                getEntityDetailsData={getDetails}
                keyName="violation"
              />
            </>
          )}
        </div>
      </div>
      {popUpVisible.popUp && popUpVisible.keyName === "suspend" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={details?.userDetails?.userName}
          id={details?.userDetails?._id}
          getdetails={getDetails}
        />
      )}
      {popUpVisible.popUp && popUpVisible.keyName === "edit" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={details?.userDetails?.userName}
          id={details?.userDetails?._id}
          keyName={popUpVisible.keyName}
          type={suspendType}
          getdetails={getDetails}
          endDate={details?.userDetails?.suspension?.period?.endDate}
          reasonType={details?.userDetails?.suspension?.reason}
          name={details?.fullName}
          setActivatePopUp={setSeenActivatePopUp}
        />
      )}
      {seenActivatePopUp && (
        <WarningPopup
          handleActivateUser={() =>
            handleActivateUser(details?.userDetails?._id)
          }
          title={`Activate ${details?.userDetails?.userName} ?`}
          description={
            "User will be activated. All created content will be available for other users"
          }
          text={`${details?.userDetails?.fullName} activated`}
          setToggle={() => setSeenActivatePopUp(false)}
          loading={updatingUser}
        />
      )}

      {isDisablePopUp && (
        <DisablePopUp
          setToggle={setDisablePopUp}
          keyName={"post"}
          selectedId={pollDetails?._id}
          getDetails={getDetails}
          suspendedState={details?.userDetails?.status}
          suspendedName={details?.userDetails?.fullName}
        />
      )}
    </div>
  );
};

export default PostDetails;
