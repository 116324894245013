import React, { useEffect, useRef, useState } from "react";
import Contacts from "../../../assets/Icons/Contacts.svg";
import Chevrondown from "../../../assets/Icons/Chevrondown.svg";
import Information from "../../../assets/Icons/Information.svg";
import pause from "../../../assets/Icons/pause.svg";
import tick from "../../../assets/Icons/Tick.svg";
import time from "../../../assets/Icons/Time.svg";
import SuspendedUserPopUp from "./SuspendedUserPopUp";
import WarningPopup from "../../../common/warningPopup";
import UpArrow from "../../../assets/Icons/up-arrow.svg";
import moment from "moment";
import DetailsProfile from "../../../common/detailsProfile";
import utility from "../../../utility";
import OutsideClickRef from "../../../common/outsideClickRef";
import DetailsBalance from "../../../common/detailsBalance";
import { useSelector } from "react-redux";
import Edit from "../../../assets/Icons/Edit.svg";

/**
 * React component representing the aside section for user details.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isContactExpanded - Whether the contact section is expanded.
 * @param {Function} props.setContactExpanded - Function to set the contact section's expansion state.
 * @param {boolean} props.isGeneralExpanded - Whether the general information section is expanded.
 * @param {Function} props.setGeneralExpanded - Function to set the general information section's expansion state.
 * @param {Object} props.details - User details object.
 * @param {Function} props.setEditPopup - Function to set the edit popup state (not specified in the code, added for completeness).
 * @param {Function} props.handleActivateUser - Function to handle user activation.
 * @param {Function} props.getUserDetails - Function to get user details.
 * @param {Function} props.setSeenActivatePopUp - Function to set the activation popup visibility state.
 * @param {boolean} props.seenActivatePopUp - Whether the activation popup has been seen.
 * @returns {React.Component} The rendered UserDetailAsideSection component.
 */

const UserDetailAsideSection = ({
  isContactExpanded,
  setContactExpanded,
  isGeneralExpanded,
  setGeneralExpanded,
  details,
  handleActivateUser = () => {},
  getUserDetails = () => {},
  setSeenActivatePopUp = () => {},
  seenActivatePopUp,
  setEditPopup,
  balance,
}) => {
  const [isUserExpanded, setUserExpanded] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState({
    popUp: false,
    keyName: "",
  });
  const optionsRef = useRef(null);
  const [suspendType, setSuspendType] = useState("");
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  const suspendedOption = () => {
    setUserExpanded(!isUserExpanded);
  };

  const togglePopUpVisble = (value) => {
    setPopUpVisible((prev) => ({
      ...prev,
      popUp: !popUpVisible.popUp,
      keyName: value,
    }));
    setUserExpanded(false);
  };

  const suspendedOnDate = details?.suspension?.period?.startDate
    ? new Date(details.suspension.period.startDate)
    : null;

  const suspendedTillDate = details?.suspension?.period?.endDate
    ? new Date(details.suspension.period.endDate)
    : null;

  useEffect(() => {
    if (details) {
      const suspend = utility.getSuspensionType(
        details?.suspension?.type,
        details?.suspension?.period?.startDate,
        details?.suspension?.period?.endDate
      );
      setSuspendType(suspend);
    }
  }, [details]);

  const renderProfileDots = () => {
    return details?.status === "ACTIVE" && isUserExpanded ? (
      <OutsideClickRef
        state={optionsRef}
        setState={() => setUserExpanded(false)}
      >
        {userPermissions?.indexOf("user:write:suspend") > -1 && (
          <div className="absolute right-5 top-25 w-75 rounded-20 shadow-inner z-20 bg-white">
            <button
              className="flex h-16.25 px-5 items-center gap-3.75 self-stretch w-full"
              onClick={() => togglePopUpVisble("suspend")}
            >
              <img src={pause} alt="Pause" />
              <p className="text-ft3 font-ManropeLight text-black-100">
                Suspend
              </p>
            </button>
          </div>
        )}
      </OutsideClickRef>
    ) : (
      isUserExpanded && details?.status === "SUSPENDED" && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setUserExpanded(false)}
        >
          <div className="absolute flex flex-col  right-5 top-25 w-75 rounded-20 shadow-inner z-20 bg-white">
            {userPermissions?.indexOf("user:write:activate") > -1 && (
              <button
                onClick={() => setSeenActivatePopUp(!seenActivatePopUp)}
                className="flex h-16.25 px-5 items-center gap-3.75 self-stretch w-full rounded-t-20 hover:bg-grey-350"
              >
                <img src={tick} alt="tick" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  Activate
                </p>
              </button>
            )}
            <hr className="text-grey-50 w-full" />
            {userPermissions?.indexOf("user:write:suspend") > -1 && (
              <button
                className="flex h-16.25 px-5 items-center gap-3.75 w-full  rounded-b-20 hover:bg-grey-350"
                onClick={() => {
                  togglePopUpVisble("edit");
                }}
              >
                <img src={time} alt="time" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  Edit Suspended time
                </p>
              </button>
            )}
          </div>
        </OutsideClickRef>
      )
    );
  };

  const isSuspended = details?.suspension;
  const suspendedFromDate = isSuspended
    ? utility.formatDate(suspendedOnDate)
    : "";
  const suspendedTillDateText =
    isSuspended && details?.suspension?.period?.endDate !== 0
      ? ` - ${utility.formatDate(suspendedTillDate)}`
      : "";

  const suspensionStatus = isSuspended
    ? `Suspended ${suspendedFromDate}${suspendedTillDateText}`
    : "-";

  return (
    <div className="flex flex-col gap-5 mt-5 pb-5 w-25per">
      <div className="w-full">
        <DetailsProfile
          name={details?.fullName || "-"}
          username={details?.userName || "-"}
          setUserExpanded={suspendedOption}
          isUserExpanded={isUserExpanded}
          renderPopUp={renderProfileDots()}
          profile={details?.profilePhoto}
          profileType={details?.isBlueTickVerified}
          keyName="user"
          permissionActive={"user:write:activate"}
          permissionSuspend={"user:write:suspend"}
          status={details?.status}
        />
      </div>
      <div className="flex flex-col items-start w-full p-5 gap-5 rounded-20 bg-white">
        <div className="flex w-full gap-2 justify-between">
          <div className="flex flex-col w-full">
            <p className="font-ManropeRegular text-ft2 text-grey-250">Status</p>
            <div
              className={`flex items-center justify-center rounded-20 w-min px-2 py-1.25 ${
                details?.status === "ACTIVE" ? "bg-green-50" : "bg-red-75"
              }`}
            >
              <p
                className={`font-ManropeRegular text-ft2 text-black-100 ${
                  details?.status === "SUSPENDED"
                    ? "w-15 xl:w-auto truncate"
                    : "w-auto"
                } `}
              >
                {details?.status &&
                  details?.status.charAt(0).toUpperCase() +
                    details?.status.slice(1).toLowerCase()}
              </p>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <p className="font-ManropeRegular text-ft2 text-grey-250">KYC</p>
            <div className="flex items-center justify-center rounded-20 bg-yellow-50 w-max py-1.25 px-2 ">
              <p className="font-ManropeRegular text-ft2 text-black-100">
                {details?.isKYCVerifiedNOT || "Not verified"}
              </p>
            </div>
          </div>
        </div>
        {details?.status === "SUSPENDED" && (
          <div className="bg-red-75 rounded-xl p-5 w-full">
            <p className="flex flex-col gap-1 text-black-50 font-ManropeMedium text-ft1">
              <span>{suspensionStatus}</span>
              <div className="flex gap-1.25 flex-wrap">
                Suspended by:
                <b>
                  <div className="break-all">
                    {" "}
                    {details?.suspension?.suspenderName || "-"}{" "}
                  </div>
                </b>
              </div>
              <span className="break-all">
                {details?.suspension?.reason || "-"}
              </span>
            </p>
          </div>
        )}
        <div className="flex w-full gap-2 justify-between">
          <div className="flex flex-col w-full">
            <p className="font-ManropeRegular text-ft2 text-grey-250">
              Violations
            </p>
            <p className="font-ManropeBold text-ft2 text-black-100">
              {details?.approvedViolations} approved
            </p>
          </div>
          <div className="flex flex-col w-full">
            <p className="font-ManropeRegular text-ft2 text-grey-250">Badge</p>
            <p className="font-ManropeBold text-ft2 text-black-100">
              {details?.isBlueTickVerified === "COMPLETED" ? "Blue" : "-"}
            </p>
          </div>
        </div>
        <div className="flex w-full gap-2 justify-between">
          <div className="flex flex-col w-full">
            <p className="font-ManropeRegular text-ft2 text-grey-250">
              Following
            </p>
            <p className="font-ManropeBold text-ft2 text-black-100">
              {details?.following || 0}
            </p>
          </div>
          <div className="flex flex-col w-full">
            <p className="font-ManropeRegular text-ft2 text-grey-250">
              Followers
            </p>
            <p className="font-ManropeBold text-ft2 text-black-100">
              {details?.followers || 0}
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-ManropeRegular text-ft2 text-grey-250">Circles</p>
          <p className="font-ManropeBold text-ft2 text-black-100">
            {details?.circles || 0}
          </p>
        </div>
      </div>
      <div className="w-full h-45">
        <DetailsBalance
          price={balance || 0}
          fontSize={"text-ft6"}
          rewardAmount={details?.rewardAmount || 0}
        />
      </div>
      <div
        className={`flex flex-col p-5 rounded-20 bg-white w-full ${
          isContactExpanded ? "h-full gap-7.5" : ""
        }`}
      >
        <div
          onClick={() => setContactExpanded(!isContactExpanded)}
          className="flex items-center gap-2 xl:gap-3.75 cursor-pointer"
        >
          <div className="w-auto">
            <div className="flex items-center justify-center w-12.5 h-12.5 rounded-full bg-yellow-50">
              <img src={Contacts} alt="Contacts" />
            </div>
          </div>
          <div className="flex w-full justify-between">
            <p className="text-ft32 font-ManropeBold text-black-100">
              Contacts
            </p>
            <img
              src={isContactExpanded ? UpArrow : Chevrondown}
              alt="Chevrondown"
              className="cursor-pointer"
            />
          </div>
        </div>
        {isContactExpanded && (
          <div className="flex flex-col gap-5">
            <div className="flex justify-between">
              <div className="flex flex-col w-full">
                <p className="font-ManropeBold text-ft3 text-black-100">
                  Email
                </p>
                <p className="font-ManropeRegular text-ft3 text-grey-250 w-full truncate">
                  {details?.email}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center gap-1">
              <div className="flex flex-col">
                <p className="font-ManropeBold text-ft3 text-black-100">
                  Phone number
                </p>
                <p className="font-ManropeRegular flex gap-1 text-ft3 text-grey-250 w-full truncate">
                  <span>
                    {" "}
                    {typeof details?.countryCode === "number"
                      ? `+${details?.countryCode}`
                      : details?.countryCode}{" "}
                  </span>
                  <span>{details?.phone || "-"}</span>
                </p>
              </div>
              {userPermissions?.indexOf("user:write:details") > -1 && (
                <div>
                  <img
                    src={Edit}
                    alt="Edit"
                    onClick={() => setEditPopup("phone")}
                    className="cursor-pointer"
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={`flex flex-col p-5 rounded-20 bg-white w-full ${
          isGeneralExpanded ? "h-auto gap-7.5" : ""
        }`}
      >
        <div
          onClick={() => setGeneralExpanded(!isGeneralExpanded)}
          className="flex items-center gap-2 xl:gap-3.75 cursor-pointer"
        >
          <div className="w-auto">
            <div className="flex items-center justify-center w-12.5 h-12.5 rounded-full bg-purple-50">
              <img src={Information} alt="Information" />
            </div>
          </div>
          <div className="flex w-full justify-between">
            <p className="text-ft3 2xl:text-ft32 font-ManropeBold text-black-100">
              General Info
            </p>
            <img
              src={isGeneralExpanded ? UpArrow : Chevrondown}
              alt="Chevrondown"
              className="cursor-pointer"
            />
          </div>
        </div>
        {isGeneralExpanded && (
          <div className="flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <div className="flex flex-col w-90per">
                <p className="font-ManropeBold text-ft3 text-black-100">
                  Display name
                </p>
                <p className="font-ManropeRegular text-ft3 w-full truncate text-grey-250">
                  {details?.fullName || "-"}
                </p>
              </div>
              {userPermissions?.indexOf("user:write:details") > -1 && (
                <div>
                  <img
                    src={Edit}
                    alt="Edit"
                    onClick={() => setEditPopup("name")}
                    className="cursor-pointer"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col w-90per">
                <p className="font-ManropeBold text-ft3 text-black-100">
                  Username
                </p>
                <p className="font-ManropeRegular text-ft3 w-full truncate text-grey-250">
                  {details?.userName || "-"}
                </p>
              </div>
              {userPermissions?.indexOf("user:write:details") > -1 &&
                details?.isBlueTickVerified !== "COMPLETED" && (
                  <div className="flex items-center">
                    <img
                      src={Edit}
                      alt="Edit"
                      onClick={() => setEditPopup("username")}
                      className="cursor-pointer"
                    />
                  </div>
                )}
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col w-80per">
                <p className="font-ManropeBold text-ft3 text-black-100">Bio</p>
                <p className="font-ManropeRegular text-ft3 text-grey-250 break-words ">
                  {details?.bio || "-"}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">
                Registered
              </p>
              <p className="font-ManropeRegular text-ft3 text-grey-250">
                {moment(details?.addedOn).format("DD MMMM[,] YYYY")}
              </p>
            </div>
          </div>
        )}
      </div>
      {popUpVisible.popUp && popUpVisible.keyName === "suspend" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={details?.userName}
          id={details?._id}
          getdetails={getUserDetails}
        />
      )}
      {popUpVisible.popUp && popUpVisible.keyName === "edit" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={details?.userName}
          type={suspendType}
          id={details?._id}
          keyName={popUpVisible.keyName}
          getdetails={getUserDetails}
          endDate={details?.suspension?.period?.endDate}
          name={details?.fullName}
          setActivatePopUp={setSeenActivatePopUp}
          reasonType={details?.suspension?.reason}
        />
      )}
      {seenActivatePopUp ? (
        <WarningPopup
          handleActivateUser={() => handleActivateUser(details?._id)}
          title={`Activate ${details?.userName} ?`}
          description={
            "User will be activated. All created content will be available for other users"
          }
          text={`${details?.fullName} activated`}
          setToggle={() => setSeenActivatePopUp(!seenActivatePopUp)}
        />
      ) : null}
    </div>
  );
};

export default UserDetailAsideSection;
