/**
 * @module InAppProductCompoenent
 */

import React from "react";
import ProductList from "./list";

/**
 * InAppProductCompoenent - A React component for managing app products.
 * @component
 * @example
 * import { InAppProductCompoenent } from "./index.js";
 * // ...
 * <InAppProductCompoenent />
 *
 * @returns {JSX.Element} React component
 */
const InAppProductCompoenent = () => {
  return (
    <div className="py-5 w-full h-full overflow-y-auto pr-12.5">
      <ProductList />
    </div>
  );
};

export default InAppProductCompoenent;
