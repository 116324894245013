import React, { useCallback, useRef } from "react";
import { MentionsInput, Mention } from "react-mentions";
import styleMentionTag from "./styleMentionTag";
import styleTag from "./styleTag";
import UserEntityService from "../../../services/userEntityService";
import utility from "../../../utility";
import { useDebouncedCallback } from "use-debounce";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ReactComponent as Info } from "../../../assets/Icons/info-icon.svg";
import defaultImage from "../../../assets/Icons/signupProfileSmall.png";
import { useSelector } from "react-redux";
import SocialInteractionService from "../../../services/socialInteractionService";

/**
 * PreviewTagsMentions component for handling mentions and hashtags in a text input.
 * @component
 * @param {Object} props - Component props
 * @param {string} props.value - Current value of the input
 * @param {Function} props.onChange - Function to handle input change
 * @param {string} props.error - Error message to display, if any
 * @param {number} props.idx - Index of the component instance
 * @returns {JSX.Element} Rendered component
 */
const PreviewTagsMentions = ({ value, onChange = () => {}, error, idx }) => {
  const myInput = useRef();
  const user = useSelector((state) => state.user);

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    zIndex: theme.zIndex.tooltip + 1,
    [`& .MuiTooltip-tooltip`]: {
      maxWidth: "none !important",
      width: 500,
      height: 100,
      backgroundColor: "#FFF",
      borderRadius: "25px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      whiteSpace: "no-wrap !important",
    },
    [`& .MuiTooltip-arrow`]: {
      color: "white",
    },
  }));

  /**
   * Retrieves mentions of users based on query.
   * @param {string} query - Search query for user mentions
   * @param {Function} callback - Callback function to handle suggestions
   */
  const getMentionsUser = async (query, callback) => {
    try {
      const requestParams = {
        id: user?.staffId,
        limit: 20,
        skip: 0,
        searchQuery: `@${query}`,
      };
      const [error, response] = await utility.parseResponse(
        new UserEntityService().getUserMentions(requestParams)
      );

      if (error || !response) return;
      let suggestions = [];

      if (response.length > 0) {
        const userArray = response.map((user) => ({
          id: user._id,
          display: user.userName,
          fullName: user.fullName,
          profile: user.profilePhoto,
        }));
        suggestions = [...userArray];
      } else {
        suggestions = [{ id: query, display: `@${query}` }];
      }
      callback(suggestions);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  /**
   * Retrieves hashtags based on query.
   * @param {string} query - Search query for hashtags
   * @param {Function} callback - Callback function to handle suggestions
   */
  const getHashTag = async (query, callback) => {
    let suggestions = [];
    if (query.length < 2) {
      suggestions = [{ id: query, display: `#${query}` }];
      callback(suggestions);
      return;
    }
    try {
      const requestParams = {
        searchQuery: `#${query}`,
        limit: 20,
        skip: 0,
      };
      const [error, response] = await utility.parseResponse(
        new SocialInteractionService().getHashTag(requestParams)
      );

      if (error || !response) return;

      if (response[0]?.hashTags?.length > 0) {
        const hashArray = response[0]?.hashTags?.map((tag) => ({
          id: tag._id,
          display: tag.name,
          count: tag.count,
        }));
        suggestions = [...hashArray];
      } else {
        suggestions = [{ id: query, display: `#${query}` }];
      }
      callback(suggestions);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const debouncedGetMentionsUser = useCallback(
    useDebouncedCallback(getMentionsUser, 200),
    []
  );

  const debouncedGetHashTag = useCallback(
    useDebouncedCallback(getHashTag, 200),
    []
  );

  return (
    <div className="flex flex-col gap-0.5">
      <div className="relative rounded-full bg-grey-50 outline-none  px-6 flex justify-between items-center  h-16.25 text-black-100 font-ManropeRegular text-ft3">
        <MentionsInput
          inputRef={myInput}
          singleLine
          spellCheck="false"
          placeholder="Learn more (optional)"
          value={value}
          onChange={(e) => onChange(e, idx)}
          className="focus:outline-none bg-transparent w-95per h-full"
          style={styleMentionTag}
        >
          <Mention
            trigger="@"
            markup="@[__display__]"
            appendSpaceOnAdd={true}
            data={debouncedGetMentionsUser}
            style={styleTag}
            renderSuggestion={(suggestion) => (
              <div className="h-full flex gap-5 flex-col overflow-y-auto ">
                <div className="flex gap-5 items-center cursor-pointer py-2">
                  <img
                    src={suggestion.profile || defaultImage}
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="flex flex-col gap-1">
                    <span className="font-ManropeBold text-ft3 text-black-100">
                      {suggestion.fullName}
                    </span>
                    <span className="font-ManropeRegular text-ft3 text-black-75">
                      {suggestion.display}
                    </span>
                  </div>
                </div>
              </div>
            )}
          />
          <Mention
            trigger="#"
            markup="@[__display__]"
            appendSpaceOnAdd={true}
            data={debouncedGetHashTag}
            style={styleTag}
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index
            ) => {
              return (
                <div className="h-full flex gap-5 flex-col overflow-y-auto ">
                  <div className="flex gap-5 items-center cursor-pointer py-2">
                    <div
                      style={{
                        backgroundColor: utility.getRandomColors(index),
                      }}
                      className="w-12.5 h-12.5 flex-shrink-0 rounded-full flex justify-center items-center"
                    >
                      <span className="font-ManropeBold text-ft3 text-black-100">
                        #
                      </span>
                    </div>

                    <div className="flex flex-col gap-1 w-95per">
                      <span className="font-ManropeBold text-ft3 text-black-100">
                        {suggestion.display}
                      </span>
                      <span className="font-ManropeRegular text-ft3 text-black-75">
                        {utility.formatNumber(suggestion.count)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </MentionsInput>
        <Stack sx={{ alignItems: "flex-start" }}>
          <StyledTooltip
            title={
              <div className="flex gap-2 flex-col p-3 w-full h-full">
                <span className="text-ft2 font-ManropeMedium text-black-50">
                  Support your voters in making well-informed decisions by
                  providing them with precise details, relevant information,
                  reliable sources, or insights from personal experiences.
                </span>
              </div>
            }
            placement="bottom-end"
          >
            <Info className="w-5 h-5" />
          </StyledTooltip>
        </Stack>{" "}
      </div>

      {error && (
        <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
          {error}
        </div>
      )}
    </div>
  );
};

export default PreviewTagsMentions;
