import React, { useEffect, useState } from "react";
import { ReactComponent as AddImage } from "../../../assets/Icons/add-contact.svg";
import AddEmployee from "../../../common/addEmployee";
import EmployeeDetails from "../details/employeeDetails";
import { UserService } from "../../../services";
import { useDispatch } from "react-redux";
import utility from "../../../utility";
import { eventConstants, toastType } from "../../../constant/commonArray";
import ShowToast from "../../../common/showToast";
import { useDebouncedCallback } from "use-debounce";
import { CircularProgress } from "@mui/material";
import PhoneNumberDropdown from "../../../common/component/phoneNumberDropdown";
import parsePhoneNumberFromString from "libphonenumber-js";

/**
 * ContactInfo component for managing contact information.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setSteps - Function to set current step.
 * @param {Function} props.handleCreateEntity - Function to handle entity creation.
 * @param {Object} props.state - Component state.
 * @param {Function} props.setState - Function to set component state.
 * @param {Function} props.setEmployeeInfo - Function to set employee information.
 * @param {Array} props.employeeInfo - Array containing employee information.
 * @param {boolean} props.loading - Flag indicating loading state.
 * @param {Object} props.error - Object containing error information.
 * @param {Function} props.setError - Function to set error state.
 * @returns {JSX.Element} React component.
 */

const numberRegex = new RegExp(
  /^(?!.*(\d)\1{6})(?!.*1234567890)(?!.*9876543210).*$/
);

const nameRegex = new RegExp(/^[a-zA-Z\s]*$/);
const numberLengthRegex = new RegExp(/^\d{0,15}$/);

const ContactInfo = ({
  setSteps,
  handleCreateEntity,
  state,
  setState,
  setEmployeeInfo,
  employeeInfo,
  loading,
  error,
  setError,
}) => {
  const [addEmployeePopup, setAddEployeePopup] = useState(false);
  const [valueStaff, setValueStaff] = useState("Company staff");
  const [valueRole, setValueRole] = useState(null);
  const [valuePosition, setValuePosition] = useState(null);
  const [valueDepartment, setValueDepartment] = useState(null);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [debounceValue, setDebounceValue] = useState("");
  const [creatingEmployee, setCreatingEmployee] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteClick = (index) => {
    const updatedInfo = [...employeeInfo];
    updatedInfo.splice(index, 1);
    setEmployeeInfo(updatedInfo);
  };

  const handleSaveEmployee = async (data) => {
    setCreatingEmployee(true);
    const newEmployee = {
      entityId: "",
      name: data?.name,
      email: data?.email,
      countryCode: data?.countryCode,
      phone: data?.phone,
      staffType: valueStaff.toUpperCase().replace(/\s/g, "_"),
      role: valueRole.toUpperCase().replace(/\s/g, "_"),
      jobPosition: valuePosition,
      department: valueDepartment?.department,
    };

    setEmployeeInfo((prevEmployeeInfo) => [...prevEmployeeInfo, newEmployee]);

    setValueRole(null);
    setValuePosition(null);
    setValueDepartment(null);
    setAddEployeePopup(false);
    setCreatingEmployee(false);
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 800);

  const checkEmailAvailable = async () => {
    const requestData = {
      email: debounceValue,
    };
    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      let [error, email] = await utility.parseResponse(
        new UserService().checkEmail(requestData)
      );
      if (error || !email) {
        setIsEmailAvailable(false);
      } else {
        if (email?.available) {
          setIsEmailAvailable(true);
        } else {
          setIsEmailAvailable(false);
        }
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  const handleEmail = (e) => {
    const emailValue = e.target.value;
    const isValidEmail = utility.validateEmail(emailValue);
    if (emailValue.length <= 50) {
      setError((prev) => ({ ...prev, emailLength: false }));
    } else {
      setError((prev) => ({ ...prev, emailLength: true }));
    }
    if (isValidEmail) {
      setError((prev) => ({ ...prev, emailError: false }));
    } else {
      setError((prev) => ({ ...prev, emailError: true }));
    }
    setState((prev) => ({
      ...prev,
      delegatedContactPerson: {
        ...prev.delegatedContactPerson,
        email: e.target.value,
      },
    }));
    debouncedSearch(e.target.value);
  };

  const handleCountryCode = (countryCode) => {
    setState((prev) => ({
      ...prev,
      delegatedContactPerson: {
        ...prev.delegatedContactPerson,
        countryCode: countryCode,
      },
    }));
  };

  useEffect(() => {
    if (debounceValue && utility.validateEmail(debounceValue))
      checkEmailAvailable();
    // eslint-disable-next-line
  }, [debounceValue]);

  const isValidPhoneNumber = (phoneNumber, countryCode) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      countryCode
    );
    return parsedPhoneNumber && parsedPhoneNumber.isValid();
  };
  function validatePhoneNumber(phoneNumber) {
    return numberRegex.test(phoneNumber);
  }

  return (
    <div className="mt-15 flex flex-col">
      <div className="flex flex-col gap-15">
        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Name
          </label>
          <input
            type="text"
            value={state?.delegatedContactPerson?.name}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 50) {
                setError((prev) => ({ ...prev, nameError: false }));
              } else {
                setError((prev) => ({ ...prev, nameError: true }));
              }
              if (nameRegex.test(inputValue)) {
                setState((prev) => ({
                  ...prev,
                  delegatedContactPerson: {
                    ...prev.delegatedContactPerson,
                    name: e.target.value,
                  },
                }));
              }
            }}
            className="w-1/2 rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
          />
          {error?.nameError && (
            <span className=" text-red-50">Name can't be more than 50</span>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Email
          </label>
          <input
            type="text"
            value={state?.delegatedContactPerson?.email}
            onChange={handleEmail}
            className="w-1/2 rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
          />
          {(!isEmailAvailable ||
            (employeeInfo?.length > 0 &&
              employeeInfo?.find(
                (item) => item?.email === state?.delegatedContactPerson?.email
              ))) &&
            !error?.emailError &&
            !error?.emailLength && (
              <span className=" text-red-50">Email is already taken</span>
            )}
          {error?.emailError && !error?.emailLength && (
            <span className=" text-red-50">Please enter the valid email</span>
          )}
          {error?.emailLength && (
            <span className=" text-red-50">Email can't be more than 64</span>
          )}
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Phone number
          </label>
          <div className="w-1/2 rounded-full bg-grey-50  px-6 h-16.25 flex items-center">
            <PhoneNumberDropdown
              setState={handleCountryCode}
              state={state?.delegatedContactPerson?.countryCode}
            />

            <input
              type="text"
              value={state?.delegatedContactPerson?.phone}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (numberLengthRegex.test(inputValue)) {
                  setState((prev) => ({
                    ...prev,
                    delegatedContactPerson: {
                      ...prev.delegatedContactPerson,
                      phone: inputValue,
                    },
                  }));
                }
              }}
              className="focus:outline-none w-full bg-grey-50 pl-8  text-ft2 font-ManropeRegular"
            />
          </div>
          {(!isValidPhoneNumber(
            `${state?.delegatedContactPerson?.countryCode}${parseInt(
              state?.delegatedContactPerson?.phone
            )}`,
            state?.delegatedContactPerson?.countryCode
          ) ||
            !validatePhoneNumber(state?.delegatedContactPerson?.phone)) &&
            state?.delegatedContactPerson?.phone !== "" && (
              <span className=" text-red-50">
                Please enter the valid phone number
              </span>
            )}
        </div>
      </div>
      {employeeInfo.map((item, idx) => (
        <EmployeeDetails
          employeeInfo={item}
          idx={idx}
          handleDeleteClick={handleDeleteClick}
        />
      ))}
      <div
        onClick={() => setAddEployeePopup(true)}
        className="flex gap-2.5 items-center cursor-pointer mt-15"
      >
        <AddImage />
        <span className="text-orange-50 font-ManropeBold text-ft2">
          Add employee
        </span>
      </div>
      <div className="flex justify-end mt-15 gap-7.5">
        <button
          onClick={() => setSteps(1)}
          className="border border-black-100 border-opacity-20 rounded-full w-55 h-16.25"
        >
          Previous
        </button>
        <button
          disabled={
            !state?.delegatedContactPerson?.email ||
            !state?.delegatedContactPerson?.name ||
            !state?.delegatedContactPerson?.phone ||
            loading ||
            !isValidPhoneNumber(
              `${state?.delegatedContactPerson?.countryCode}${parseInt(
                state?.delegatedContactPerson?.phone
              )}`,
              state?.delegatedContactPerson?.countryCode
            ) ||
            !validatePhoneNumber(state?.delegatedContactPerson?.phone) ||
            error?.nameError ||
            error?.emailError ||
            error?.emailLength ||
            state?.delegatedContactPerson?.name?.trim() === "" ||
            !isEmailAvailable ||
            (employeeInfo?.length > 0 &&
              employeeInfo?.find(
                (item) => item?.email === state?.delegatedContactPerson?.email
              ))
          }
          onClick={() => {
            handleCreateEntity();
          }}
          className={`${
            !state?.delegatedContactPerson?.email ||
            !state?.delegatedContactPerson?.name ||
            !state?.delegatedContactPerson?.phone ||
            loading ||
            !isValidPhoneNumber(
              `${state?.delegatedContactPerson?.countryCode}${parseInt(
                state?.delegatedContactPerson?.phone
              )}`,
              state?.delegatedContactPerson?.countryCode
            ) ||
            !validatePhoneNumber(state?.delegatedContactPerson?.phone) ||
            error?.nameError ||
            error?.emailError ||
            error?.emailLength ||
            state?.delegatedContactPerson?.name?.trim() === "" ||
            !isEmailAvailable ||
            (employeeInfo?.length > 0 &&
              employeeInfo?.find(
                (item) => item?.email === state?.delegatedContactPerson?.email
              ))
              ? "bg-grey-50"
              : "bg-orange-50"
          } rounded-full w-55 h-16.25`}
        >
          {loading ? <CircularProgress className="text-orange-50" /> : "Save"}
        </button>
      </div>

      {addEmployeePopup && (
        <AddEmployee
          setAddEployeePopup={setAddEployeePopup}
          setValueRole={setValueRole}
          setValueStaff={setValueStaff}
          setValuePosition={setValuePosition}
          setValueDepartment={setValueDepartment}
          valueRole={valueRole}
          valueStaff={valueStaff}
          valueDepartment={valueDepartment}
          valuePosition={valuePosition}
          handleSave={handleSaveEmployee}
          loadingState={creatingEmployee}
          entityEmail={state?.delegatedContactPerson?.email}
          employeeInfo={employeeInfo}
        />
      )}
    </div>
  );
};

export default ContactInfo;
