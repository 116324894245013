import React, { useState } from "react";
import close from "../../assets/Icons/close.svg";
import checkMark from "../../assets/Icons/checkMark.svg";
import { permissionSet, toastType } from "../../constant/commonArray";
import ShowToast from "../../common/showToast";
import utility from "../../utility";
import { UserService } from "../../services";
import down from "../../assets/Icons/Chevrondown.svg";
import UpArrow from "../../assets/Icons/up-arrow.svg";

/**
 * RolesPopup is a component that displays a popup for creating or editing roles.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setToggle - Function to toggle the visibility of the popup.
 * @param {string} props.heading - The heading of the popup, indicating whether it's for creating or editing a role.
 * @param {Function} [props.getRoleList] - Function to fetch the updated list of roles (optional).
 * @param {Object} props.data - Data object containing role information for pre-populating the fields.
 * @param {string} props.data.role - The role name.
 * @param {Array} props.data.access - The array of access rights.
 * @param {string} props.data.roleId - The ID of the role.
 * @returns {JSX.Element} - The JSX element representing the RolesPopup.
 */

const RolesPopup = ({ setToggle, heading, getRoleList = () => {}, data }) => {
  /**
   * State to manage the form fields.
   * @type {Object}
   * @property {string} role - The role name.
   * @property {Array} access - The array of access rights.
   * @property {string} roleId - The ID of the role.
   */
  const filteredAccess = data?.access?.filter(
    (item) => item?.permissions && item?.permissions.length > 0
  );

  const [state, setState] = useState({
    role: data?.role || "",
    access: filteredAccess || [],
    roleId: data?.roleId,
  });
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState("");
  const [lengthError, setLengthError] = useState({});
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(null);

  const handleCreateRole = async () => {
    setUpdating(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().createRole(state)
      );
      if (error || !response) return;
      if (response?.success) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        setToggle();
        getRoleList();
      } else if (response?.responseCode === 400) {
        setError(response?.responseData?.message);
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }

      setUpdating(false);
    } catch (e) {
      console.error(e);
      setUpdating(false);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Handles the editing of an existing role.
   * @async
   * @returns {void}
   */

  const handleEditRole = async () => {
    setUpdating(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().editRole(state)
      );
      if (error || !response) return;
      if (response?.success) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        setToggle();
        getRoleList();
      } else if (response?.responseCode === 400) {
        setError(response?.responseData?.message);
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setUpdating(false);
    } catch (e) {
      console.error(e);
      setUpdating(false);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Handles the selection of a role access field.
   * @param {string} field - The selected access field.
   * @returns {void}
   */
  const handleRoleSelect = (name, permission) => {
    setState((prevState) => {
      const isNameSelected = prevState.access.some(
        (access) => access.name === name
      );
      const permissionSet = permission?.map((item) => item?.permission);
      const updatedAccess = isNameSelected
        ? prevState.access.filter((access) => access.name !== name)
        : [...prevState.access, { name, permissions: permissionSet }];

      return {
        ...prevState,
        access: updatedAccess,
      };
    });
  };

  const handleOptionToggle = (name, optionName, needPermission) => {
    setState((prevState) => {
      const existingAccess = prevState.access.find(
        (access) => access.name === name
      );

      let updatedAccess;
      if (existingAccess) {
        const permissionsSelected = needPermission?.filter(
          (permission) => !existingAccess?.permissions?.includes(permission)
        );
        const permissions = existingAccess?.permissions.includes(optionName)
          ? existingAccess.permissions.filter(
              (permission) => permission !== optionName
            )
          : [...existingAccess.permissions, optionName, ...permissionsSelected];

        const updatedAccessList = prevState.access.map((access) =>
          access.name === name ? { ...access, permissions } : access
        );

        updatedAccess = updatedAccessList.filter(
          (access) => access.permissions.length > 0
        );
      } else {
        updatedAccess = [
          ...prevState.access,
          { name, permissions: [optionName, ...needPermission] },
        ];
      }

      return {
        ...prevState,
        access: updatedAccess,
      };
    });
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center">
      <div className="flex flex-col bg-white rounded-20 shadow-md w-112.5 h-155 p-7.5">
        <div className="flex w-full justify-between items-center mb-5">
          <span className="font-ManropeBold text-ft4 text-black-100">
            {heading}
          </span>
          <img
            src={close}
            alt="close"
            className="cursor-pointer"
            onClick={setToggle}
          />
        </div>
        <div className="flex flex-col">
          <p className="text-black-100 font-ManropeMedium text-ft3">Role</p>
          <input
            type="text"
            value={state?.role}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 50) {
                setLengthError((prev) => ({ ...prev, nameError: false }));
              } else {
                setLengthError((prev) => ({ ...prev, nameError: true }));
              }
              setState({ ...state, role: e.target.value });
              setError("");
            }}
            className="px-6.25 h-16.25 w-full rounded-full bg-grey-50 text-black-100 font-ManropeRegular text-ft3 focus:outline-none"
          />
          {lengthError?.nameError && (
            <span className=" text-red-50">
              Role name can't be more than 50
            </span>
          )}
        </div>
        {!!error && <span className=" text-red-50">{error}</span>}
        <div className="flex flex-col gap-5 my-6 overflow-y-auto justify-between">
          {permissionSet?.map((field, fieldIndex) => (
            <div key={fieldIndex} className="flex flex-col gap-3.75">
              <div className={`flex justify-between items-center `}>
                <div className="relative flex items-center">
                  <input
                    type="checkbox"
                    onClick={() =>
                      handleRoleSelect(field.name, field.permissions)
                    }
                    className={`appearance-none w-6 h-6 rounded-lg bg-white border-2 border-grey-100 cursor-pointer  ${
                      state?.access?.some(
                        (access) => access?.name === field?.name
                      )
                        ? "bg-orange-50 border-none"
                        : "bg-white border-2 border-grey-100"
                    }`}
                  />
                  <p className="text-black-100 font-ManropeRegular text-ft3 ml-2.5">
                    {field.name}
                  </p>
                  {state?.access?.some(
                    (access) => access?.name === field?.name
                  ) && (
                    <img
                      onClick={() => handleRoleSelect(field.name)}
                      className="absolute cursor-pointer"
                      src={checkMark}
                      alt="checkMark"
                    />
                  )}
                </div>

                <img
                  src={selectedFieldIndex === fieldIndex ? UpArrow : down}
                  alt="down"
                  className="cursor-pointer"
                  onClick={() =>
                    setSelectedFieldIndex(
                      selectedFieldIndex === fieldIndex ? null : fieldIndex
                    )
                  }
                />
              </div>

              {selectedFieldIndex === fieldIndex && (
                <div className={`flex flex-col gap-2.5 ml-8`}>
                  {field?.permissions?.map((option, optionIndex) => {
                    return (
                      <div key={optionIndex} className="flex flex-col gap-3.75">
                        <div className="flex  justify-between items-center">
                          <div className="relative flex items-center">
                            <input
                              type="checkbox"
                              onChange={() =>
                                handleOptionToggle(
                                  field?.name,
                                  option?.permission,
                                  option.needPermission
                                )
                              }
                              disabled={
                                /read/g.test(option.permission) &&
                                !!state?.access?.find(
                                  (access) =>
                                    access?.name === field?.name &&
                                    access?.permissions?.some((item) =>
                                      option?.requiredPermissions?.includes(
                                        item
                                      )
                                    )
                                )
                              }
                              className={`appearance-none w-6 h-6 rounded-lg bg-white border-2 border-grey-100 cursor-pointer ${
                                state?.access?.find(
                                  (access) =>
                                    access?.name === field?.name &&
                                    access?.permissions?.includes(
                                      option?.permission
                                    )
                                )
                                  ? "bg-orange-50 border-none"
                                  : "bg-white border-2 border-grey-100"
                              }`}
                            />
                            <p className="text-black-100 font-ManropeRegular text-ft3 ml-2.5">
                              {option.name}
                            </p>
                            {state?.access?.find(
                              (access) =>
                                access?.name === field?.name &&
                                access?.permissions?.includes(
                                  option?.permission
                                )
                            ) && (
                              <img
                                onClick={() => {
                                  if (
                                    !(
                                      /read/g.test(option.permission) &&
                                      !!state?.access?.find(
                                        (access) =>
                                          access?.name === field?.name &&
                                          access?.permissions?.some((item) =>
                                            option?.requiredPermissions?.includes(
                                              item
                                            )
                                          )
                                      )
                                    )
                                  ) {
                                    handleOptionToggle(
                                      field?.name,
                                      option.permission,
                                      option.needPermission
                                    );
                                  }
                                }}
                                className="absolute cursor-pointer"
                                src={checkMark}
                                alt="checkMark"
                              ></img>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>

        <button
          className={`px-7.5 py-2.5 flex flex-none justify-center items-center h-16.25 w-full rounded-full text-black-100 ${
            state.role?.trim() === "" ||
            state.access.length === 0 ||
            updating ||
            Object.values(lengthError).some((value) => value !== false) ||
            !!error ||
            (state?.role === data?.role &&
              state?.access?.every((value) => data?.access?.includes(value)) &&
              data?.access?.every((value) => state?.access?.includes(value)))
              ? "bg-grey-50 opacity-60"
              : "bg-orange-50"
          } font-ManropeBold text-ft2 mt-auto`}
          disabled={
            state.role?.trim() === "" ||
            state.access.length === 0 ||
            updating ||
            Object.values(lengthError).some((value) => value !== false) ||
            !!error ||
            (state?.role === data?.role &&
              state?.access?.every((value) => data?.access?.includes(value)) &&
              data?.access?.every((value) => state?.access?.includes(value)))
          }
          onClick={heading === "Role" ? handleEditRole : handleCreateRole}
        >
          {heading === "Role" ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
};

export default RolesPopup;
