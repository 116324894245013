import React, { useRef, useState } from "react";
import ToggleSwitch from "../../../../../common/component/toggleSwitch";
import OutsideClickRef from "../../../../../common/outsideClickRef";
import TypeImage from "./typeImage";
import TypeText from "./typeText";
import UpArrow from "../../../../../assets/Icons/up-arrow.svg";
import DownArrow from "../../../../../assets/Icons/down-arrow.svg";
import {
  agreementData,
  FormalityData,
  frequencyData,
  QualityData,
  textData,
} from "../../../../../constant/commonArray";

/**
 * Renders the component for configuring poll options and settings.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setState - Function to update component state.
 * @param {Object} props.state - Current state of the component.
 * @param {Function} props.setSteps - Function to update current step in the parent component.
 * @param {Array} props.images - Array of images for the poll.
 * @param {Function} props.setImages - Function to update the images array.
 * @returns {JSX.Element} Rendered component.
 */

const Poll = ({ setState, state, setSteps, images, setImages }) => {
  const optionsValue = [
    {
      name: "Text",
      data: textData,
      enum: "TEXT",
    },
    {
      name: "Images",
      data: [],
      enum: "IMAGE",
    },
    {
      name: "Frequency",
      data: frequencyData,
      enum: "TEXT",
    },
    {
      name: "Quality",
      data: QualityData,
      enum: "TEXT",
    },
    {
      name: "Agreement",
      data: agreementData,
      enum: "TEXT",
    },
    {
      name: "Familarity",
      data: FormalityData,
      enum: "TEXT",
    },
  ];

  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  return (
    <div className="mt-15 flex flex-col">
      <div className="flex gap-4 w-full">
        <div className="flex flex-col gap-15 w-1/2">
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Option Type
            </label>
            <div>
              <OutsideClickRef state={dropdownRef} setState={setIsOpen}>
                <div className="relative w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25">
                  <div
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    className="w-full h-full flex justify-between items-center cursor-pointer"
                  >
                    <span
                      className={`w-full h-full flex items-center font-ManropeRegular ${
                        !!state?.options?.name
                          ? "text-black-100"
                          : "text-black-75"
                      } text-ft3`}
                    >
                      {state?.options?.name || "Not choosen"}
                    </span>
                    {!isOpen ? (
                      <img src={DownArrow} alt="" className="w-6 h-6" />
                    ) : (
                      <img src={UpArrow} alt="" className="w-6 h-6" />
                    )}
                  </div>

                  {isOpen && (
                    <div className="absolute right-0 mt-2 w-full z-50 max-h-100 overflow-y-auto  shadow-dropdownShadow bg-white  rounded-20">
                      <div className="py-1 flex flex-col">
                        {optionsValue.map((option, idx) => (
                          <span
                            key={idx}
                            className={`w-full p-5 text-ft3 font-ManropeRegular hover:bg-grey-350 text-black-150 cursor-pointer  ${
                              idx === optionsValue.length - 1
                                ? ""
                                : "border-b border-grey-50"
                            }`}
                            onClick={() => {
                              setState((prev) => ({
                                ...prev,
                                options: {
                                  ...prev.options,
                                  type: option.enum,
                                  answers: option.data,
                                  name: option.name,
                                },
                              }));
                              setErrors([]);
                              setIsOpen(false);
                            }}
                          >
                            {option.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </OutsideClickRef>
            </div>
          </div>

          <div className="flex flex-col gap-1 pt-5">
            {state?.options?.type === "TEXT" ? (
              <TypeText
                setState={setState}
                state={state}
                setErrors={setErrors}
                errors={errors}
              />
            ) : (
              state?.options?.type === "IMAGE" && (
                <TypeImage images={images} setImages={setImages} />
              )
            )}
          </div>
          <div>
            <div className="flex justify-between gap-1  ">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Availability multiple options
              </label>
              <div>
                <ToggleSwitch
                  setValue={() =>
                    setState((prev) => ({
                      ...prev,
                      multipleOptions: !state?.multipleOptions,
                    }))
                  }
                  value={state?.multipleOptions}
                />
              </div>
            </div>
            {state?.options?.type !== "IMAGE" && (
              <div className="flex justify-between gap-1  pt-3">
                <label
                  htmlFor=""
                  className="text-black-100 text-ft3 font-ManropeMedium"
                >
                  To set an open answer
                </label>
                <div>
                  <ToggleSwitch
                    setValue={() =>
                      setState((prev) => ({
                        ...prev,
                        openAnswer: !state?.openAnswer,
                      }))
                    }
                    value={state?.openAnswer}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {state?.reason?.length > 0 && (
          <div className="bg-red-75 rounded-xl p-5 h-max-content w-1/2 flex flex-col gap-1 text-ft1">
            <span className="text-ft2 font-ManropeBold text-black-100">
              Reason:
            </span>
            {state?.reason?.map((item, idx) => (
              <span
                key={idx}
                className="text-ft2 font-ManropeRegular text-black-100"
              >
                {item?.description}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="flex justify-end mt-15 gap-7.5">
        <button
          onClick={() => setSteps(0)}
          className="border border-black-100 border-opacity-20 font-ManropeBold rounded-full w-55 h-16.25 text-ft2"
        >
          Previous
        </button>
        <button
          disabled={
            errors.some((item) => !!item) ||
            (state?.options?.type !== "IMAGE" &&
              state?.options?.answers?.some((item) => !item?.value)) ||
            (state?.options?.type === "IMAGE" &&
              (images.length < 2 ||
                images.some((item) => item.hasOwnProperty("dummyImages"))))
          }
          onClick={() => {
            setSteps(2);
          }}
          className={`${
            errors.some((item) => !!item) ||
            (state?.options?.type !== "IMAGE" &&
              state?.options?.answers?.some((item) => !item?.value)) ||
            (state?.options?.type === "IMAGE" &&
              (images.length < 2 ||
                images.some((item) => item.hasOwnProperty("dummyImages"))))
              ? "bg-grey-50"
              : "bg-orange-50"
          } rounded-full w-55 h-16.25 font-ManropeBold text-ft2`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Poll;
