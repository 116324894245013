/**
 * @module IncomeSplitComponent
 * @example
 * // Usage in a React functional component
 * import IncomeSplitComponent from "./IncomeSplitComponent";
 *
 * const MyComponent = () => {
 *   return <IncomeSplitComponent />;
 * };
 * @returns {React.Component} The IncomeSplitComponent
 */

import React from "react";
import IncomeSplitList from "./incomeSplitList";
import left from "../../assets/Icons/Left.svg";
import { useHistory, useLocation } from "react-router-dom";

const IncomeSplitComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const history = useHistory();
  return (
    <div className="w-full pr-12.5 pt-8.75 overflow-y-auto">
      {haveAudit && (
        <div
          onClick={() => {
            if (haveAudit) {
              history.push("/audit-log");
            }
          }}
          className="pb-3"
        >
          <img src={left} alt="left" className="cursor-pointer" />
        </div>
      )}
      <IncomeSplitList />
    </div>
  );
};

export default IncomeSplitComponent;
