import { CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import MenuBurger from "../../assets/Icons/MenuBurger.svg";
import More from "../../assets/Icons/More.svg";
import { UserService } from "../../services";
import utility from "../../utility";
import Edit from "../../assets/Icons/Edit.svg";
import Add from "../../assets/Icons/Add.svg";
import deletes from "../../assets/Icons/delete.svg";
import OutsideClickRef from "../../common/outsideClickRef";
import WarningPopup from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import ChannelPopUp from "./createChannelPopUp";
import CreateGroupComponent from "./createGroupPopUp";
import placeholder from "../../assets/Icons/Placeholder.svg";
import PlaceholderWnm from "../../assets/Icons/PlaceholderWnm.png";
import { useSelector } from "react-redux";

/**
 * ChannelList component displays a list of channels and their respective groups.
 * It allows for dragging and dropping to change the order of groups and channels.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Array} props.channelList - The list of channels and their groups.
 * @param {Function} props.setChannelList - Function to set the channel list.
 * @param {boolean} props.loading - Flag indicating if data is still loading.
 * @param {Function} props.getChannelList - Function to fetch the channel list.
 * @returns {JSX.Element} - The JSX element representing the ChannelList component.
 */

const ChannelList = ({
  channelList,
  setChannelList,
  loading,
  getChannelList,
  searchQuery,
}) => {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [isChannelOpen, setIsChannelOpen] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [channelId, setChannelId] = useState("");
  const optionRef = useRef();
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [groupPopup, setGroupPopup] = useState({
    popup: false,
    data: "",
  });
  const [deleteChannelPopup, setDeleteChannelPopUp] = useState({
    name: "",
    id: "",
    popup: false,
  });
  const [deleteGroupPopup, setDeleteGroupPopUp] = useState({
    name: "",
    id: "",
    popup: false,
  });

  const [showPopup, setShowPopup] = useState({
    popup: false,
    type: "",
    groupData: "",
    channelData: "",
  });

  /**
   * Handle the drag end event and update the channel list accordingly.
   *
   * @param {object} result - The result object from the drag end event
   * @return {void} No return value
   */

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = [...channelList];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setChannelList(items);
    const { data, keyName } = findPreviousDataGroup(items, reorderedItem);
    if (data && keyName !== "first") {
      handleUpdatePosition("GROUP", result.draggableId, data.position + 1);
    } else {
      handleUpdatePosition("GROUP", result.draggableId, data.position - 1);
    }
  }
  /**
   * Finds the previous data group based on the dragged item.
   *
   * @param {Array} items - The array of items to search through.
   * @param {Object} draggedItem - The item that was dragged and used for comparison.
   * @return {Object} An object containing the previous data group and a key name.
   */
  function findPreviousDataGroup(items, draggedItem) {
    const groupIndex = items.findIndex(
      (group) => group.groupId === draggedItem.groupId
    );
    if (groupIndex !== -1 && groupIndex > 0) {
      const previousGroup = items[groupIndex - 1];
      return { data: previousGroup, keyName: "default" };
    }

    return { data: items[1], keyName: "first" };
  }

  /**
   * Handles the click event for a channel.
   *
   * @param {type} channelId - The ID of the channel being clicked
   * @return {type} description of return value
   */
  const handleChannelClick = (channelId) => {
    if (selectedChannel === channelId) {
      setIsChannelOpen((prev) => !prev);
    } else {
      setSelectedChannel(channelId);
      setIsChannelOpen(true);
    }
  };

  /**
   * Handles the drag end event for the subchannel.
   *
   * @param {object} result - The result of the drag end event
   * @param {string} channelId - The ID of the channel
   * @return {void}
   */
  function handleSubChannelOnDragEnd(result, channelId) {
    if (!result.destination) return;

    const updatedChannelList = channelList.map((channel) => {
      if (channel.groupId === channelId) {
        const subChannels = [...channel.channels];
        const [reorderedItem] = subChannels.splice(result.source.index, 1);
        subChannels.splice(result.destination.index, 0, reorderedItem);

        const { data, keyName } = findPreviousDataChannel(
          subChannels,
          reorderedItem
        );

        if (data && keyName !== "first") {
          handleUpdatePosition(
            "CHANNEL",
            reorderedItem.channelId,
            data.position + 1
          );
        } else {
          handleUpdatePosition(
            "CHANNEL",
            reorderedItem.channelId,
            data.position - 1
          );
        }

        return { ...channel, channels: subChannels };
      }
      return channel;
    });

    setChannelList(updatedChannelList);
  }

  /**
   * Finds the previous data channel in the items array based on the dragged item.
   *
   * @param {Array} items - The array of items to search through.
   * @param {Object} draggedItem - The item that was dragged and used for comparison.
   * @return {Object} An object containing the previous data channel and a key name.
   */
  function findPreviousDataChannel(items, draggedItem) {
    const channelIndex = items.findIndex(
      (channel) => channel.channelId === draggedItem.channelId
    );

    if (channelIndex !== -1 && channelIndex > 0) {
      return { data: items[channelIndex - 1], keyName: "default" };
    }

    return { data: items[1], keyName: "first" };
  }

  /**
   * Asynchronously handles the deletion of a channel.
   *
   * @param {type} id - the ID of the channel to be deleted
   * @param {type} name - the name of the channel to be deleted
   */
  const handleDeleteChannel = async (id, name) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deleteChannel(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: name + " deleted successfully",
          type: toastType.SUCCESS,
        });
        getChannelList();
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setDeleteChannelPopUp({
        id: "",
        name: "",
        popup: false,
      });
      setChannelId("");
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Async function to handle the deletion of a group.
   *
   * @param {type} id - description of parameter
   * @param {type} groupName - description of parameter
   * @return {type} undefined if there's an error or no response, otherwise nothing
   */
  const handleDeleteGroup = async (id, groupName) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deleteChannelGroup(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: groupName + " deleted successfully",
          type: toastType.SUCCESS,
        });
        getChannelList();
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setDeleteGroupPopUp({
        id: "",
        name: "",
        popup: false,
      });
      setChannelId("");
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Opens a pop-up for editing group and channel data.
   *
   * @param {type} groupData - The data for the group
   * @param {type} channelData - The data for the channel
   * @return {type} undefined
   */
  const handleEditPopUp = (groupData, channelData) => {
    setShowPopup({
      popup: true,
      type: "EDIT",
      groupData: groupData,
      channelData: channelData,
    });
  };

  const handleAddPopUp = (groupData) => {
    setShowPopup({
      popup: true,
      type: "ADD",
      groupData: groupData,
    });
  };

  /**
   * Asynchronously handles the update of the position for a given target and id.
   *
   * @param {Object} target - the target object
   * @param {string} id - the id of the target
   * @param {number} position - the new position to update
   */
  const handleUpdatePosition = async (target, id, position) => {
    try {
      const requestData = {
        target: target,
        targetId: id,
        position: position,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().updatePosition(requestData)
      );
      if (error || !response?.responseData) return;
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {channelList?.length > 0 ? (
        <DragDropContext
          onDragEnd={(e) => {
            handleOnDragEnd(e);
          }}
        >
          <Droppable droppableId="vertical" isDropDisabled={isChannelOpen}>
            {(provided) => (
              <div
                className="flex flex-col gap-2.5"
                // {...provided.droppableProps}
                // ref={provided.innerRef}
              >
                {channelList.map((item, index) => (
                  <Draggable
                    key={item.groupId}
                    draggableId={item.groupId}
                    index={index}
                    isDragDisabled={isChannelOpen}
                  >
                    {(provided) => (
                      <div
                      // ref={provided.innerRef}
                      //  {...provided.draggableProps}
                      >
                        <div
                          className={`flex flex-col rounded-20 bg-white py-5 gap-5`}
                        >
                          <div
                            className={`flex justify-between cursor-pointer px-5`}
                          >
                            <div
                              onClick={() => handleChannelClick(item.groupId)}
                              className="flex justify-between w-90per"
                            >
                              <div className="flex items-center">
                                {/* <img
                                  {...provided.dragHandleProps}
                                  src={MenuBurger}
                                  alt="MenuBurger"
                                  className="mr-10 w-5 h-5 opacity-20"
                                /> */}
                                <img
                                  src={item.icon}
                                  alt="userImage"
                                  className="mr-5 w-11.25 h-11.25 rounded-full"
                                />
                                <p className="text-black-100 font-ManropeBold text-ft3">
                                  {item.groupName}
                                </p>
                              </div>
                              <div className="flex items-center">
                                <div className="flex flex-col mr-25">
                                  <p className="text-grey-250 font-ManropeMedium text-ft2">
                                    Channels
                                  </p>
                                  <p className="text-black-50 font-ManropeBold text-ft2">
                                    {item.totalChannels}
                                  </p>
                                </div>
                                <div className="flex flex-col">
                                  <p className="text-grey-250 font-ManropeMedium text-ft2">
                                    Created
                                  </p>
                                  <p className="text-black-50 font-ManropeBold text-ft2">
                                    {moment(item.addedOn).format(
                                      "DD MMM[,] YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="relative flex items-center">
                              {userPermissions?.indexOf(
                                "channels:write:details"
                              ) > -1 && (
                                <img
                                  src={More}
                                  alt="More"
                                  onClick={() => setGroupId(item?.groupId)}
                                  className="cursor-pointer"
                                />
                              )}
                              {groupId === item?.groupId && (
                                <div>
                                  <OutsideClickRef
                                    state={optionRef}
                                    setState={() => setGroupId(null)}
                                  >
                                    <div className="flex flex-col absolute w-75 top-10 right-0 rounded-20 shadow-inner z-10 bg-white">
                                      <button
                                        onClick={() => handleAddPopUp(item)}
                                        className="flex p-5 items-center gap-3.75 w-75 rounded-t-20 hover:bg-grey-350"
                                      >
                                        <img src={Add} alt="stop" />
                                        <p className="text-ft3 font-ManropeRegular text-black-100">
                                          Add channel
                                        </p>
                                      </button>
                                      <hr className="text-grey-50 w-full" />
                                      <button
                                        onClick={() =>
                                          setGroupPopup((prev) => ({
                                            ...prev,
                                            data: item,
                                            popup: true,
                                          }))
                                        }
                                        className="flex p-5 items-center gap-3.75 w-75 hover:bg-grey-350"
                                      >
                                        <img src={Edit} alt="stop" />
                                        <p className="text-ft3 font-ManropeRegular text-black-100">
                                          Edit
                                        </p>
                                      </button>
                                      <hr className="text-grey-50 w-full" />
                                      <button
                                        onClick={() =>
                                          setDeleteGroupPopUp((prev) => ({
                                            ...prev,
                                            id: item?.groupId,
                                            name: item?.groupName,
                                            popup: true,
                                          }))
                                        }
                                        className="flex p-5 items-center gap-3.75 w-75 rounded-b-20 hover:bg-grey-350"
                                      >
                                        <img src={deletes} alt="deletes" />

                                        <p className="text-ft3 font-ManropeRegular text-black-100">
                                          Delete
                                        </p>
                                      </button>
                                    </div>
                                  </OutsideClickRef>
                                </div>
                              )}
                            </div>
                          </div>
                          {isChannelOpen &&
                            selectedChannel === item.groupId &&
                            item?.channels?.length > 0 && (
                              <>
                                <hr className="text-grey-50" />
                                <DragDropContext
                                  onDragEnd={(e) => {
                                    handleSubChannelOnDragEnd(e, item?.groupId);
                                  }}
                                >
                                  <Droppable droppableId="subChan">
                                    {(provided) => (
                                      <div
                                        className="flex flex-col gap-5"
                                        // {...provided.droppableProps}
                                        // ref={provided.innerRef}
                                      >
                                        {item?.channels?.map(
                                          (subChannel, index) => {
                                            return (
                                              <Draggable
                                                key={subChannel.channelId}
                                                draggableId={
                                                  subChannel.channelId
                                                }
                                                index={index}
                                              >
                                                {(provided) => (
                                                  <div
                                                    className="flex flex-col gap-5"
                                                    // ref={provided.innerRef}
                                                    // {...provided.draggableProps}
                                                  >
                                                    <div className="flex justify-between px-5">
                                                      <div className="flex justify-between w-90per">
                                                        <div className="flex items-center">
                                                          {/* <img
                                                            {...provided.dragHandleProps}
                                                            src={MenuBurger}
                                                            alt="MenuBurger"
                                                            className="mr-10 w-5 h-5 opacity-20"
                                                          /> */}
                                                          <div className="flex items-center justify-center mr-5 w-11.25 h-11.25">
                                                            <p className="w-5 h-5 rounded-full bg-purple-50"></p>
                                                          </div>
                                                          <p className="text-black-100 font-ManropeBold text-ft3">
                                                            {subChannel.name}
                                                          </p>
                                                        </div>
                                                        <div className="flex flex-col">
                                                          <p className="text-grey-250 font-ManropeMedium text-ft2">
                                                            Created
                                                          </p>
                                                          <p className="text-black-50 font-ManropeBold text-ft2">
                                                            {moment(
                                                              subChannel.addedOn
                                                            ).format(
                                                              "DD MMM[,] YYYY"
                                                            )}
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="relative flex items-center">
                                                        {userPermissions?.indexOf(
                                                          "channels:write:details"
                                                        ) > -1 && (
                                                          <img
                                                            src={More}
                                                            alt="More"
                                                            onClick={() =>
                                                              setChannelId(
                                                                subChannel?.channelId
                                                              )
                                                            }
                                                            className="cursor-pointer"
                                                          />
                                                        )}
                                                        {channelId ===
                                                          subChannel?.channelId && (
                                                          <div>
                                                            <OutsideClickRef
                                                              state={optionRef}
                                                              setState={() =>
                                                                setChannelId(
                                                                  null
                                                                )
                                                              }
                                                            >
                                                              <div className="flex flex-col absolute w-75 top-10 right-0 rounded-20 shadow-inner z-10 bg-white">
                                                                <hr className="text-grey-50 w-full" />
                                                                <button
                                                                  onClick={() =>
                                                                    handleEditPopUp(
                                                                      item,
                                                                      subChannel
                                                                    )
                                                                  }
                                                                  className="flex p-5 items-center gap-3.75 w-75 rounded-t-20 hover:bg-grey-350"
                                                                >
                                                                  <img
                                                                    src={Edit}
                                                                    alt="stop"
                                                                  />
                                                                  <p className="text-ft3 font-ManropeRegular text-black-100">
                                                                    Edit
                                                                  </p>
                                                                </button>
                                                                <hr className="text-grey-50 w-full" />
                                                                <button
                                                                  onClick={() =>
                                                                    setDeleteChannelPopUp(
                                                                      (
                                                                        prev
                                                                      ) => ({
                                                                        ...prev,
                                                                        id: subChannel?.channelId,
                                                                        name: subChannel?.name,
                                                                        popup: true,
                                                                      })
                                                                    )
                                                                  }
                                                                  className="flex p-5 items-center gap-3.75 w-75 rounded-b-20 hover:bg-grey-350"
                                                                >
                                                                  <img
                                                                    src={
                                                                      deletes
                                                                    }
                                                                    alt="deletes"
                                                                  />

                                                                  <p className="text-ft3 font-ManropeRegular text-black-100">
                                                                    Delete
                                                                  </p>
                                                                </button>
                                                              </div>
                                                            </OutsideClickRef>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <hr
                                                      className={`${
                                                        index ===
                                                        item?.channels?.length -
                                                          1
                                                          ? "hidden"
                                                          : "text-grey-50"
                                                      }`}
                                                    />
                                                  </div>
                                                )}
                                              </Draggable>
                                            );
                                          }
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </>
                            )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
          {searchQuery ? (
            <>
              <img src={PlaceholderWnm} alt="placeholder" className="mt-36.5" />
              <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                No results were found for your search
              </p>
              <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                Try to change your request
              </p>
            </>
          ) : (
            <img src={placeholder} alt="placeholder" className="mt-5per" />
          )}
        </div>
      )}
      {deleteChannelPopup?.popup && (
        <WarningPopup
          description={"Are you sure? You want to delete channel"}
          handleActivateUser={() =>
            handleDeleteChannel(
              deleteChannelPopup?.id,
              deleteChannelPopup?.name
            )
          }
          title={`Delete “${deleteChannelPopup?.name}”?`}
          setToggle={() => setDeleteChannelPopUp(null)}
        />
      )}
      {deleteGroupPopup?.popup && (
        <WarningPopup
          description={"Are you sure? You want to delete channel"}
          handleActivateUser={() =>
            handleDeleteGroup(deleteGroupPopup?.id, deleteGroupPopup?.name)
          }
          title={`Delete “${deleteGroupPopup?.name}”?`}
          setToggle={() => setDeleteGroupPopUp(null)}
        />
      )}
      {showPopup?.popup && showPopup?.type === "EDIT" && (
        <ChannelPopUp
          handleChannelPopUp={() => {
            setShowPopup({
              popup: false,
              type: "",
              id: "",
              data: "",
              channelData: "",
            });
          }}
          title="Edit channel"
          getChannelList={getChannelList}
          channelData={showPopup?.channelData}
          groupData={showPopup?.groupData}
          keyName="edit"
        />
      )}
      {showPopup?.popup && showPopup?.type === "ADD" && (
        <ChannelPopUp
          handleChannelPopUp={() => {
            setShowPopup({
              popup: false,
              type: "",
              id: "",
              data: "",
            });
          }}
          title="Create new channel"
          getChannelList={getChannelList}
          groupData={showPopup?.groupData}
        />
      )}
      {groupPopup?.popup && (
        <CreateGroupComponent
          data={groupPopup?.data}
          getChannelList={getChannelList}
          closePopup={() =>
            setGroupPopup((prev) => ({ ...prev, popup: false, data: "" }))
          }
          keyName="edit"
        />
      )}
    </div>
  );
};

export default ChannelList;
