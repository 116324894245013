import React, { useEffect, useState } from "react";
import searchLogo from "../../assets/Icons/Search.svg";
import { staffActivity, toastType } from "../../constant/commonArray";
import moment from "moment";
import DetailsProfile from "../../common/detailsProfile";
import GeneralInfo from "./generalInfo";
import Error from "../../assets/Icons/Error.svg";
import WarningPopUp from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import { UserService } from "../../services";
import utility from "../../utility";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CircularProgress } from "@mui/material";
import placeholder from "../../assets/Icons/Placeholder.svg";
import Contact from "./contact";
import left from "../../assets/Icons/Left.svg";
import { useHistory, useLocation } from "react-router-dom";

/**
 * Component for displaying staff activity details.
 *
 * @component
 * @returns {JSX.Element} - Activity component
 */

const Activity = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const { id } = useParams();
  const [isInfo, setInfo] = useState("info");
  const [isUserExpanded, setUserExpanded] = useState(false);
  const [isUserBlock, setUserBlock] = useState({
    popup: false,
    type: "",
  });

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const history = useHistory();
  /**
   * Handles the search input change.
   *
   * @function
   * @param {Object} e - Event object
   * @returns {void}
   */
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  /**
   * Fetches staff details.
   *
   * @async
   * @function
   * @returns {void}
   */
  const getDetails = async () => {
    setLoading(true);

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getStaffDetails(id)
      );

      if (error || !response) return;
      setStaffData(response);

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  /**
   * Handles the activation of a staff member.
   *
   * @async
   * @function
   * @returns {void}
   */
  const handleActivateStaff = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().activateStaff(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      getDetails();
      setUserExpanded(false);
      setUserBlock(null);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Handles the blocking of a staff member.
   *
   * @async
   * @function
   * @returns {void}
   */
  const handleBlockStaff = async () => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().blockStaff(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      getDetails();
      setUserExpanded(false);
      setUserBlock(null);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Renders the profile dots based on user expansion status and staff status.
   *
   * @function
   * @returns {JSX.Element|null} - Profile dots component or null if not needed
   */

  const renderProfileDots = () => {
    return (
      isUserExpanded && (
        <div>
          {staffData?.status === "ACTIVE" ? (
            <div
              onClick={() => {
                setUserBlock({
                  popup: !isUserBlock?.popup,
                  type: "BLOCK",
                });
                setUserExpanded(false);
              }}
              className="absolute right-2 top-23.75 w-75 rounded-20 shadow-inner z-10 bg-white cursor-pointer"
            >
              <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
                <img src={Error} alt="Error" />
                <p className="text-ft3 font-ManropeLight text-black-100">
                  Block
                </p>
              </button>
            </div>
          ) : (
            <div
              onClick={() => {
                setUserBlock({
                  popup: !isUserBlock?.popup,
                  type: "ACTIVE",
                });
                setUserExpanded(false);
              }}
              className="absolute right-2 top-23.75 w-75 rounded-20 shadow-inner z-10 bg-white cursor-pointer"
            >
              <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
                <img src={Error} alt="Error" />
                <p className="text-ft3 font-ManropeLight text-black-100">
                  Activate
                </p>
              </button>
            </div>
          )}
        </div>
      )
    );
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex py-5 w-full overflow-y-auto pr-12.5 mb-5 rounded-b-20 gap-5">
      <div className="flex flex-col w-75per gap-5">
        <div className="bg-white p-5 rounded-20 flex items-center">
          {haveAudit && (
            <div
              onClick={() => {
                if (haveAudit) {
                  history.push(`/staff?from=${haveAudit}`);
                }
              }}
              className="w-5per"
            >
              <img src={left} alt="left" className="cursor-pointer" />
            </div>
          )}
          <div
            className={`flex ${
              haveAudit ? "w-95per" : "w-full"
            } p-5 items-center flex-shrink-0 gap-5 rounded-full bg-grey-50 h-11.25`}
          >
            <img
              src={searchLogo}
              alt="searchIcon"
              className="opacity-60 w-5 h-5"
            />
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => handleSearch(e)}
              className="font-ManropeLight text-ft3 bg-grey-50 text-black-300 focus:outline-none w-full"
            />
          </div>
        </div>
        {staffActivity.length <= 0 ? (
          <div className="flex flex-col bg-white p-7.5 rounded-20 gap-7.5">
            {staffActivity.map((item) => (
              <div className="flex flex-col gap-5">
                <p className="text-black-100 font-ManropeBold text-ft3">
                  {item.heading}
                </p>
                <div className="flex flex-col gap-5">
                  {item.subArray.map((subItem, subIndex) => (
                    <div className="flex justify-between">
                      <div className="flex gap-3.75">
                        <img
                          className="w-11.25 h-11.25"
                          src={subItem.Profile}
                          alt="Profile"
                        />
                        <div className="flex flex-col">
                          <p className="text-black-100 font-ManropeBold text-ft3">
                            {subItem.channelName}
                          </p>
                          <p className="text-black-75 font-ManropeRegular text-ft2">
                            {subItem.title}
                          </p>
                        </div>
                      </div>
                      <p className="text-black-75 font-ManropeRegular text-ft2">
                        {moment(subItem.date).format("MMM DD, YYYY")}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col bg-white p-7.5 rounded-20 h-70vh justify-center items-center">
            <img src={placeholder} alt="placeholder" />
          </div>
        )}
      </div>
      <div className="flex flex-col w-25per gap-5">
        <DetailsProfile
          renderPopUp={renderProfileDots()}
          isUserExpanded={isUserExpanded}
          setUserExpanded={setUserExpanded}
          name={staffData?.name || ""}
          profile={staffData?.profilePhoto}
          username={staffData?.roleDetails?.role}
          isStaff={true}
          permissionSuspend={"staff:write:action"}
          permissionActive={"staff:write:action"}
          status={"ACTIVE"}
        />
        <div className="flex flex-col rounded-20 bg-white w-full gap-5 p-5">
          <div className="flex justify-between">
            <p
              className={`cursor-pointer ${
                isInfo === "info"
                  ? "text-ft5 text-black-100 font-ManropeBold"
                  : "text-black-75 text-ft3 font-ManropeMedium"
              }`}
              onClick={() => setInfo("info")}
            >
              Info
            </p>
            {/* <p
              className={`cursor-pointer ${
                isInfo === "info"
                  ? "text-black-75 text-ft3 font-ManropeMedium"
                  : "text-ft5 text-black-100 font-ManropeBold"
              }`}
              onClick={() => setInfo("accounts")}
            >
              Accounts
            </p> */}
          </div>
          {isInfo === "info" && (
            <div className="flex justify-between">
              <div className="flex flex-col">
                <p className="text-black-75 font-ManropeMedium text-ft2">
                  Status
                </p>
                <p
                  className={`text-black-100 font-ManropeMedium text-ft0 rounded-full py-0.75 px-2 ${
                    staffData?.status === "ACTIVE" ? "bg-green-50" : "bg-red-75"
                  }`}
                >
                  {utility.capitalizeFirstLetter(staffData?.status)}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-black-75 font-ManropeMedium text-ft2">
                  Last Login
                </p>
                <p className="text-black-100 font-ManropeBold text-ft0 xl:text-ft2 whitespace-nowrap">
                  {staffData?.lastLogin
                    ? moment(staffData?.lastLogin).format("MMMM DD, yy h:mm")
                    : "-"}
                </p>
              </div>
            </div>
          )}
        </div>
        {isInfo === "info" && (
          <>
            <Contact details={staffData} getDetails={getDetails} />
            <GeneralInfo details={staffData} getDetails={getDetails} />
          </>
        )}
        {isUserBlock?.popup && (
          <WarningPopUp
            handleActivateUser={
              isUserBlock?.type === "BLOCK"
                ? handleBlockStaff
                : handleActivateStaff
            }
            setToggle={() => setUserBlock(null)}
            title={`${isUserBlock?.type === "BLOCK" ? "Block" : "Active"}  ${
              staffData?.name
            }?`}
            description={`Are you sure? You want to  ${
              isUserBlock?.type === "BLOCK" ? "block" : "active"
            } this staff`}
          />
        )}
      </div>
    </div>
  );
};

export default Activity;
