import { httpConstants, keyTypeConstants } from "../constant/commonArray";
import { sessionManager } from "../managers/sessionManager";

const {
  METHOD_TYPE: { POST },
  API_END_POINT: { UPLOAD_FILES, COMPRESS_AND_UPLOAD },
} = httpConstants;

const { AUTH0_ID_TOKEN } = keyTypeConstants;

export default class FileUploadService {
  fileUpload = async (requestData) => {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_FILE_UPLOAD_SERVICE + `/${UPLOAD_FILES}`, {
        method: POST,
        headers: {
          Authorization: `${sessionManager.getDataFromCookies(AUTH0_ID_TOKEN)}`,
        },
        body: requestData,
      })
        .then(async (data) => {
          try {
            const res = await data.json();
            if (
              !res.success &&
              res.responseCode !== 400 &&
              res.responseCode !== 401
            ) {
              return reject(res?.message);
            }
            return resolve(res);
          } catch (err) {
            reject(err);
          }
        })
        .catch(reject);
    });
  };
  fileCompressNUpload = async (requestData) => {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_FILE_UPLOAD_SERVICE + `/${COMPRESS_AND_UPLOAD}`,
        {
          method: POST,
          headers: {
            Authorization: `${sessionManager.getDataFromCookies(
              AUTH0_ID_TOKEN
            )}`,
          },
          body: requestData,
        }
      )
        .then(async (data) => {
          try {
            const res = await data.json();
            if (
              !res.success &&
              res.responseCode !== 400 &&
              res.responseCode !== 401
            ) {
              return reject(res?.message);
            }
            return resolve(res);
          } catch (err) {
            reject(err);
          }
        })
        .catch(reject);
    });
  };
}
