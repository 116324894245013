import React from "react";
import moment from "moment";
import placeholder from "../../../assets/Icons/Placeholder.svg";
import utility from "../../../utility";
import PlaceholderWnm from "../../../assets/Icons/PlaceholderWnm.png";
import Table from "../../../common/table";
import { useHistory } from "react-router-dom";

/**
 * FinishedList component for displaying a list of finished items.
 * @component
 * @param {Object} props - Component props
 * @param {string} props.searchQuery - Current search query string
 * @param {Function} props.setPageSize - Function to set page size
 * @param {number} props.pageSize - Current page size
 * @param {Function} props.setCurrentPage - Function to set current page
 * @param {number} props.currentPage - Current page number
 * @param {Function} props.setTotalDocs - Function to set total documents count
 * @param {number} props.totalDocs - Total number of documents
 * @param {Function} props.setSort - Function to set sorting criteria
 * @param {Array<Object>} props.data - Array of data items to display
 * @param {Object} props.sort - Current sorting criteria
 * @param {Function} props.handlePageChange - Function to handle page change
 * @param {Function} props.setClickFilterType - Function to set clicked filter type
 * @param {boolean} props.clickFilterType - Clicked filter type flag
 * @param {Function} props.renderFilterType - Function to render filter types
 * @param {boolean} props.loading - Flag indicating loading state
 * @param {string} props.sortByType - Sorting type
 * @param {Function} props.setSortByType - Function to set sorting type
 * @param {Function} props.renderFilter - Function to render filters
 * @param {boolean} props.clickFilter - Clicked filter flag
 * @param {Function} props.setClickFilter - Function to set clicked filter
 * @param {Array<string>} props.selectedFilter - Selected filters
 * @param {Array<string>} props.selectedFilterType - Selected filter types
 * @returns {JSX.Element} - Rendered component
 */

const FinishedList = ({
  searchQuery,
  setPageSize,
  pageSize,
  setCurrentPage,
  currentPage,
  setTotalDocs,
  totalDocs,
  setSort,
  data,
  sort,
  handlePageChange,
  setClickFilterType,
  clickFilterType,
  renderFilterType,
  loading,
  sortByType,
  setSortByType,
  renderFilter,
  clickFilter,
  setClickFilter,
  selectedFilter,
  selectedFilterType,
}) => {
  const headers = [
    { name: "Title", sortByType: true },
    {
      name: "Category",
      typeFilter: true,
      typeFilterLength: selectedFilterType?.length,
    },
    { name: "Type" },
    { name: "Created For" },
    { name: "Total votes" },
    { name: "Start Date", sortByType: true },
    { name: "End Date", sortByType: true },
    { name: "Status", filter: true, filterLength: selectedFilter?.length },
  ];
  const history = useHistory();

  const renderTableBody = () => {
    if (data.length > 0 && !loading) {
      return data?.map((item) => (
        <tr
          key={item?._id}
          onClick={() =>
            // userPermissions?.indexOf("business:read:details") > -1 &&
            history.push(`/poll-list/details/${item?._id}`)
          }
          className="hover:bg-grey-350 cursor-pointer"
        >
          <td className="py-5 px-5 max-w-155px font-ManropeMedium text-ft1 text-black-50">
            <div className=" max-w-155px  truncate">{item?.post?.name}</div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-25 truncate">
              {utility.capitalizeFirstLetter(item?.type)}
            </div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-25 truncate">
              {utility.capitalizeFirstLetter(item?.post?.type)}{" "}
              {utility.capitalizeFirstLetter(item?.type)}
            </div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-25 truncate">
              {utility.capitalizeFirstLetter(item?.creator?.fullName)}
            </div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-25 truncate">{item.voteCount}</div>
          </td>

          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-40 truncate">
              {moment(item?.activePeriod?.startDate).format(
                "DD MMM[,] YYYY HH:mm"
              )}
            </div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-40 truncate">
              {moment(item?.activePeriod?.endDate).format(
                "DD MMM[,] YYYY HH:mm"
              )}
            </div>
          </td>
          <td className="font-ManropeMedium text-ft1 text-black-50">
            <div className="w-25 truncate">
              {utility.formatText(item?.pollStatus)}
            </div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={8}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };
  return (
    <div>
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setTotalDocs={setTotalDocs}
        totalDocs={totalDocs}
        setSort={setSort}
        sort={sort}
        handlePageChange={handlePageChange}
        clickFilterType={clickFilterType}
        renderFilterType={renderFilterType()}
        setClickFilterType={setClickFilterType}
        loading={loading}
        sortByType={sortByType}
        setSortByType={setSortByType}
        renderFilter={renderFilter()}
        clickFilter={clickFilter}
        setClickFilter={setClickFilter}
      />
    </div>
  );
};

export default FinishedList;
