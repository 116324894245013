/**
 * @module AdvertisingComponent
 * @description
 * Module for managing and rendering AdvertisingComponent.
 *
 * @example
 * // Usage in a React functional component
 * import AdvertisingComponent from "./AdvertisingComponent";
 *
 * const MyComponent = () => {
 *   return <AdvertisingComponent />;
 * };
 *
 * @returns {React.Component} The AdvertisingComponent component
 */

import React, { useEffect, useRef, useState } from "react";
import search from "../../assets/Icons/Search.svg";
import { ReactComponent as AddIcon } from "../../assets/Icons/plus.svg";
import Table from "../../common/table";
import { toastType } from "../../constant/commonArray";
import moment from "moment";
import more from "../../assets/Icons/More.svg";
import deletes from "../../assets/Icons/delete.svg";
import stop from "../../assets/Icons/Stop.svg";
import error from "../../assets/Icons/Error.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import WarningPopup from "../../common/warningPopup";
import ShowToast from "../../common/showToast";
import { UserService } from "../../services";
import utility from "../../utility";
import Close from "../../assets/Icons/close.svg";
import { useDebouncedCallback } from "use-debounce";
import placeholder from "../../assets/Icons/Placeholder.svg";
import PlaceholderWnm from "../../assets/Icons/PlaceholderWnm.png";
import checkMark from "../../assets/Icons/checkMark.svg";
import OutsideClickRef from "../../common/outsideClickRef";
import { useSelector } from "react-redux";

/**
 * Functional component for displaying and managing advertising data.
 *
 * @return {JSX.Element} The advertising component UI.
 */
const AdvertisingComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState({
    id: "",
    name: "",
  });
  const [adId, setAdId] = useState("");
  const [isStopPopUp, setStopPopUp] = useState(false);
  const [isDeletePopUp, setDeletePopUp] = useState(false);
  const [ads, setAds] = useState([]);
  const [debounceValue, setDebounceValue] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [clickFilter, setClickFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [sort, setSort] = useState(-1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const optionsRef = useRef(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * Handles page change based on the given page number.
   *
   * @param {number} pageNumber - The page number to navigate to.
   */

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const history = useHistory();
  const headers = [
    { name: " Name of Ad" },
    { name: "Status", filter: true },
    { name: "Account" },
    { name: "Created", sort: true },
    { name: "Start date", startDate: true },
    { name: "End date", endDate: true },
  ];

  /**
   * Fetches ads based on the provided parameters and updates the state accordingly.
   *
   * @param {void} none
   * @return {void} nothing
   */

  const getAds = async () => {
    setLoading(true);
    let requestParams = {
      sort: sort,
      limit: pageSize,
      skip: currentPage,
    };

    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    if (startDate) {
      requestParams.sortingKey = startDate;
    }
    if (endDate) {
      requestParams.sortingKey = endDate;
    }
    if (selectedFilters) {
      requestParams.status = selectedFilters;
    }
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getAd(requestParams)
      );

      if (error || !response) return;
      setAds(response?.advertisementList);
      setTotalDocs(response?.totalCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  /**
   * Asynchronously handles stopping ad by id.
   *
   * @param {type} id - the id of the ad to stop
   * @return {type} undefined
   */
  const handleStopAd = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().stopAd(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getAds();
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setSelectedItem({
        id: "",
        name: "",
      });
      setAdId("");
      setStopPopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Handles the deletion of an ad.
   *
   * @param {type} id - the identifier of the ad to delete
   * @return {type} undefined
   */
  const handleDeleteAd = async (id) => {
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().deleteAd(id)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getAds();
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setSelectedItem({
        id: "",
        name: "",
      });
      setAdId("");
      setDeletePopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setCurrentPage(0);
    setDebounceValue(value);
  }, 500);

  /**
   * Handle search event.
   *
   * @param {Event} e - the event object
   * @return {void}
   */
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPage(0);
  };

  function getStatus(startDate, endDate) {
    const now = Date.now();

    if (startDate > now) {
      return "Pending";
    } else if (endDate < now) {
      return "Ended";
    } else {
      return "Active";
    }
  }

  /**
   * Handles the click event for displaying more information on the item.
   *
   * @param {Object} item - the item to display more information for
   * @return {void}
   */
  const handleMoreClick = (item) => {
    if (adId === item?._id) {
      setAdId(null);
    } else {
      setAdId(item?._id);
    }
  };

  useEffect(() => {
    getAds();
    // eslint-disable-next-line
  }, [
    debounceValue,
    pageSize,
    sort,
    currentPage,
    startDate,
    endDate,
    selectedFilters,
  ]);

  /**
   * Toggles the selected filter in the list of selected filters.
   *
   * @param {type} filter - the filter to be toggled
   * @return {type} undefined
   */

  const toggleSelectedFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  /**
   * Renders the filter component based on the selected filters.
   *
   * @return {JSX.Element} The rendered filter component
   */
  const renderFilter = () => {
    return (
      clickFilter && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilter(false)}
        >
          <div
            className={`flex w-45 p-5 flex-col items-start gap-5 absolute top-5 right-5 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("ENDED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("ENDED")}
                checked={selectedFilters.includes("ENDED")}
              />
              {selectedFilters.includes("ENDED") ? (
                <img
                  onClick={() => toggleSelectedFilter("ENDED")}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Ended
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("PENDING")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("PENDING")}
                checked={selectedFilters.includes("PENDING")}
              />
              {selectedFilters.includes("PENDING") ? (
                <img
                  onClick={() => toggleSelectedFilter("PENDING")}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Pending
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("ACTIVE")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("ACTIVE")}
                checked={selectedFilters.includes("ACTIVE")}
              />
              {selectedFilters.includes("ACTIVE") ? (
                <img
                  onClick={() => toggleSelectedFilter("ACTIVE")}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Active
              </label>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Renders the table body based on the ads data.
   *
   * @return {JSX.Element} The table body JSX element
   */
  const renderTableBody = () => {
    if (ads?.length > 0) {
      return ads?.map((item) => (
        <tr key={item?._id} className="hover:bg-grey-350 relative">
          <td
            onClick={() =>
              history.push({ pathname: `/advertising/details/${item?._id}` })
            }
            className="py-2.5 px-5 font-ManropeRegular text-ft2 text-black-50 cursor-pointer"
          >
            <div className="w-35 truncate">{item.name}</div>
          </td>
          <td
            onClick={() =>
              history.push({ pathname: `/advertising/details/${item?._id}` })
            }
            className="font-ManropeRegular text-ft2 text-black-50 cursor-pointer"
          >
            <div className="w-35 truncate">
              {getStatus(item?.startDate, item?.endDate)}
            </div>
          </td>
          <td
            onClick={() =>
              history.push({ pathname: `/advertising/details/${item?._id}` })
            }
            className="font-ManropeRegular text-ft2 text-black-50 cursor-pointer"
          >
            <div className="w-35 truncate">{item.account || "-"}</div>
          </td>
          <td
            onClick={() =>
              history.push({ pathname: `/advertising/details/${item?._id}` })
            }
            className="font-ManropeRegular text-ft2 text-black-50 cursor-pointer"
          >
            <div className="w-35 truncate">
              {moment(item.addedOn).format("DD MMM[,] YYYY")}
            </div>
          </td>
          <td
            onClick={() =>
              history.push({ pathname: `/advertising/details/${item?._id}` })
            }
            className="font-ManropeRegular text-ft2 text-black-50 cursor-pointer"
          >
            <div className="w-35 truncate">
              {moment(item.startDate).format("DD MMM[,] YYYY HH:mm")}
            </div>
          </td>
          <td
            onClick={() =>
              history.push({ pathname: `/advertising/details/${item?._id}` })
            }
            className="font-ManropeRegular text-ft2 text-black-50 cursor-pointer"
          >
            <div className="w-35 truncate">
              {moment(item.endDate).format("DD MMM[,] YYYY HH:mm")}
            </div>
          </td>
          <td>
            <div className="w-6.25">
              {(userPermissions?.indexOf("advertising:write:duplicate") > -1 ||
                userPermissions?.indexOf("advertising:write:stop") > -1 ||
                userPermissions?.indexOf("advertising:write:delete") > -1) && (
                <img
                  src={more}
                  alt="More"
                  onClick={() => {
                    handleMoreClick(item);
                  }}
                  className="cursor-pointer"
                />
              )}
            </div>
          </td>
          {adId === item?._id &&
            getStatus(item?.startDate, item?.endDate) === "Active" && (
              <OutsideClickRef
                state={optionsRef}
                setState={() => setAdId(null)}
              >
                <div className="flex flex-col absolute w-75 top-13 right-5 rounded-20 shadow-inner z-10 bg-white">
                  {userPermissions?.indexOf("advertising:write:duplicate") >
                    -1 && (
                    <button
                      onClick={() =>
                        history.push(
                          `/advertising/account-create?duplicate=${item?._id}`
                        )
                      }
                      className="flex p-5 items-center gap-3.75 w-75 rounded-t-20 hover:bg-grey-350"
                    >
                      <img src={stop} alt="stop" />
                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Create duplicate
                      </p>
                    </button>
                  )}
                  <hr className="text-grey-50 w-full" />
                  {userPermissions?.indexOf("advertising:write:stop") > -1 && (
                    <button
                      onClick={() => {
                        setStopPopUp(true);
                        setSelectedItem({
                          id: item?._id,
                          name: item?.name,
                        });
                      }}
                      className="flex p-5 items-center gap-3.75 w-75 rounded-b-20 hover:bg-grey-350"
                    >
                      <img src={error} alt="error" />

                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Stop
                      </p>
                    </button>
                  )}
                </div>
              </OutsideClickRef>
            )}
          {adId === item?._id &&
            getStatus(item?.startDate, item?.endDate) === "Pending" && (
              <OutsideClickRef
                state={optionsRef}
                setState={() => setAdId(null)}
              >
                <div className="flex flex-col absolute w-75 top-13 right-5 rounded-20 shadow-inner z-10 bg-white">
                  {userPermissions?.indexOf("advertising:write:duplicate") >
                    -1 && (
                    <button
                      onClick={() =>
                        history.push(
                          `/advertising/account-create?duplicate=${item?._id}`
                        )
                      }
                      className="flex p-5 items-center gap-3.75 w-75 rounded-t-20 hover:bg-grey-350"
                    >
                      <img src={stop} alt="stop" />
                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Create duplicate
                      </p>
                    </button>
                  )}
                  <hr className="text-grey-50 w-full" />
                  {userPermissions?.indexOf("advertising:write:delete") >
                    -1 && (
                    <button
                      onClick={() => {
                        setDeletePopUp(true);
                        setSelectedItem({
                          id: item?._id,
                          name: item?.name,
                        });
                      }}
                      className="flex p-5 items-center gap-3.75 w-75 rounded-b-20 hover:bg-grey-350"
                    >
                      <img src={deletes} alt="deletes" />

                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Delete
                      </p>
                    </button>
                  )}
                </div>
              </OutsideClickRef>
            )}
          {adId === item?._id &&
            getStatus(item?.startDate, item?.endDate) === "Ended" && (
              <OutsideClickRef
                state={optionsRef}
                setState={() => setAdId(null)}
              >
                <div className="flex flex-col absolute w-75 top-13 right-5 rounded-20 shadow-inner z-10 bg-white">
                  {userPermissions?.indexOf("advertising:write:duplicate") >
                    -1 && (
                    <button
                      onClick={() =>
                        history.push(
                          `/advertising/account-create?duplicate=${item?._id}`
                        )
                      }
                      className="flex p-5 items-center gap-3.75"
                    >
                      <img src={stop} alt="stop" />
                      <p className="text-ft3 font-ManropeRegular text-black-100">
                        Create duplicate
                      </p>
                    </button>
                  )}
                </div>
              </OutsideClickRef>
            )}
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={6}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };
  return (
    <div className="py-5 w-full h-full overflow-y-auto pr-5 xl:pr-12.5">
      <div className="flex justify-between items-center bg-white p-5 rounded-20 gap-5">
        <div className="flex p-5 items-center gap-5 rounded-full bg-grey-50 w-full h-11.25">
          <img src={search} alt="searchIcon" className="opacity-60 w-5 h-5" />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            className="font-ManropeLight text-ft3 w-full bg-grey-50 text-black-300 focus:outline-none"
          />
          {!!searchQuery && (
            <img
              onClick={() => {
                setSearchQuery("");
                debouncedSearch("");
              }}
              src={Close}
              alt=""
              className="flex justify-end cursor-pointer  w-5 h-5"
            />
          )}
        </div>
        {userPermissions?.indexOf("advertising:write:create") > -1 && (
          <Link to="/advertising/account-create">
            <div className="w-11 h-11 bg-orange-50 rounded-15px flex justify-center items-center relative cursor-pointer">
              <AddIcon />
            </div>
          </Link>
        )}
      </div>
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        currentPage={currentPage}
        loading={loading}
        handlePageChange={handlePageChange}
        pageSize={pageSize}
        totalDocs={totalDocs}
        setCurrentPage={setCurrentPage}
        setSort={setSort}
        setPageSize={setPageSize}
        sort={sort}
        totalPages={totalPages}
        setStartDate={setStartDate}
        tagStart={"startDate"}
        startDate={startDate}
        setEndDate={setEndDate}
        clickFilter={clickFilter}
        setClickFilter={setClickFilter}
        renderFilter={renderFilter()}
        endDate={endDate}
      />
      {isDeletePopUp && (
        <WarningPopup
          title={`Delete ${selectedItem?.name}`}
          description={"Are you sure? You want to delete Ad"}
          handleActivateUser={() => handleDeleteAd(selectedItem?.id)}
          setToggle={setDeletePopUp}
        />
      )}
      {isStopPopUp && (
        <WarningPopup
          title={`Stop ${selectedItem?.name}`}
          description={"Are you sure? You want to stop Ad"}
          handleActivateUser={() => handleStopAd(selectedItem?.id)}
          setToggle={setStopPopUp}
        />
      )}
    </div>
  );
};

export default AdvertisingComponent;
