import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Success } from "../assets/Icons/toast-success.svg";
import { ReactComponent as Error } from "../assets/Icons/toast-error.svg";

/**
 * ShowToast component displays a customizable toast notification.
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.message - The message to display in the toast.
 * @param {string} props.type - The type of toast, can be "success" or "error".
 * @param {number} [props.duration=3000] - The duration for which the toast is visible, in milliseconds.
 * @returns {void}
 */
const ShowToast = ({ message, type, duration = 3000 }) => {
  const toastStyles = {
    success: {
      backgroundColor: "#1917141A",
      borderRadius: "30px",
      backdropFilter: "blur(15px)",
      paddingLeft: "10px",
      paddingRight: "10px",
      minHeight: "66px",
      minWidth: "380px",
      border: "1px solid rgba(255, 255, 255, 0.20)",
    },
    error: {
      backgroundColor: "#1917141A",
      borderRadius: "30px",
      backdropFilter: "blur(15px)",
      paddingLeft: "10px",
      paddingRight: "10px",
      minHeight: "66px",
      minWidth: "380px",
      border: "1px solid rgba(255, 255, 255, 0.20)",
    },
  };

  const style = toastStyles[type] || {};
  let toastId;
  const showToast = () => {
    if (type === "success") {
      toast.dismiss(toastId);
      toastId = toast.success(message, {
        position: "bottom-left",
        style: { ...style },
        autoClose: duration,
        hideProgressBar: true,
        icon: <Success />,
        closeButton: false,
      });
    } else if (type === "error") {
      toast.dismiss(toastId);
      toastId = toast.error(message, {
        position: "bottom-left",
        style: { ...style },
        autoClose: duration,
        hideProgressBar: true,
        icon: <Error />,
        closeButton: false,
      });
    }
  };
  showToast();
};

export default ShowToast;
