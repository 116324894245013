import React, { useState } from "react";
import { ReactComponent as Close } from "../../assets/Icons/close.svg";
import eyeShow from "../../assets/Icons/eyeShow.svg";
import eyeHide from "../../assets/Icons/eyeHide.svg";
import Info from "../../assets/Icons/info-profile.svg";
import utility from "../../utility";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import { UserService } from "../../services";
import ShowToast from "../../common/showToast";
import {
  failureMessage,
  toastType,
  keyTypeConstants,
} from "../../constant/commonArray";
import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CheckMark from "../../assets/Icons/checkMark-white.svg";
import Cross from "../../assets/Icons/close-white.svg";
import CryptoJS from "crypto-js";
import { sessionManager } from "../../managers/sessionManager";
import { useHistory } from "react-router-dom";

const { AUTH0_ID_TOKEN } = keyTypeConstants;

/**
 * PasswordPopUp component for editing the user's password.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.setState - Function to set the state of the parent component.
 * @returns {JSX.Element} PasswordPopUp component.
 */

const upperCaseRegex = new RegExp(/[A-Z]/);
const loweCaseRegex = new RegExp(/[a-z]/);
const numberRegex = new RegExp(/\d/);
const specialCharScoreRegex = new RegExp(/[!@#$%^&*(),.?":{}|<>]/);

const PasswordPopUp = ({ setState }) => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    current: false,
    new: false,
    repeat: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const { removeDataFromCookies } = sessionManager;

  const [isCurrentPassExist, setIsCurrentPassExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPassMatch, setIsPassMatch] = useState(false);
  const user = useSelector((state) => state.user);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [values, setValues] = useState({
    currentPassword: "",
    newPassword: "",
    repeatPassword: "",
  });
  const [passwordCheckMark, setPasswordCheckMark] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    specialChar: false,
  });

  const evaluatePasswordStrength = (password) => {
    const lengthScore = password.length >= 8 ? 1 : 0;
    const uppercaseScore = upperCaseRegex.test(password) ? 1 : 0;
    const lowercaseScore = loweCaseRegex.test(password) ? 1 : 0;
    const numberScore = numberRegex.test(password) ? 1 : 0;
    const specialCharScore = specialCharScoreRegex.test(password) ? 1 : 0;
    setPasswordCheckMark({
      length: lengthScore,
      upperCase: uppercaseScore,
      lowerCase: lowercaseScore,
      number: numberScore,
      specialChar: specialCharScore,
    });
    const totalScore =
      lengthScore +
      uppercaseScore +
      lowercaseScore +
      numberScore +
      specialCharScore;
    return (totalScore / 5) * 100;
  };

  /**
   * Handles the change of password input fields.
   * @param {string} passwordType - The type of password field (currentPassword, newPassword, repeatPassword).
   * @param {string} value - The value entered in the password field.
   * @returns {void}
   */
  const handlePasswordChange = (passwordType, value) => {
    setValues((prev) => ({
      ...prev,
      [passwordType]: value,
    }));
    if (passwordType === "currentPassword") {
      setIsCurrentPassExist(false);
    }

    if (passwordType === "repeatPassword") {
      setIsPassMatch(values.newPassword !== value);
    }
    if (passwordType === "newPassword") {
      const strength = evaluatePasswordStrength(value);
      setPasswordStrength(strength);
    }
  };

  /**
   * Toggles the visibility of the password.
   * @param {string} passwordType - The type of password field (current, new, repeat).
   * @returns {void}
   */
  const handlePasswordVisibilityToggle = (passwordType) => {
    setPasswordVisibility((prevVisibility) => ({
      ...prevVisibility,
      [passwordType]: !prevVisibility[passwordType],
    }));
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    zIndex: theme.zIndex.tooltip + 1,
    [`& .MuiTooltip-tooltip`]: {
      maxWidth: "none !important",
      width: 350,
      height: 190,
      backgroundColor: "#FFF",
      borderRadius: "25px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      whiteSpace: "no-wrap !important",
    },
    [`& .MuiTooltip-arrow`]: {
      color: "white",
    },
  }));

  const logOut = () => {
    dispatch({
      type: "REMOVE_USER",
      payload: {},
    });
    removeDataFromCookies(AUTH0_ID_TOKEN);
    history.replace("/");
  };

  const handlePassWordChange = async () => {
    if (values?.newPassword !== values?.repeatPassword) {
      setIsPassMatch(true);
      return;
    }
    try {
      setLoading(true);
      let requestData = {
        email: user?.email,
      };
      const encrypted = CryptoJS.AES.encrypt(
        values?.currentPassword,
        process.env.REACT_APP_PASSWORD_ENCRYPT_KEY
      );
      requestData.password = encrypted.toString();

      const [error, signInRes] = await utility.parseResponse(
        new UserService().staffLogin(requestData)
      );
      if (error) {
        setIsCurrentPassExist(true);
        setLoading(false);
        return;
      }
      if (signInRes) {
        if (values?.newPassword === values?.currentPassword) {
          ShowToast({
            message: failureMessage.CURRENT_PASS_CANT_SAME,
            type: toastType.ERROR,
          });
          return;
        }
        const requestParams = {};
        const encryptedNewPassword = CryptoJS.AES.encrypt(
          values?.newPassword,
          process.env.REACT_APP_PASSWORD_ENCRYPT_KEY
        );

        requestParams.password = encryptedNewPassword.toString();
        const [err, res] = await utility.parseResponse(
          new UserService().editStaff(user?.staffId, requestParams)
        );
        if (err || !res) return;

        if (res?.statusCode === 400) {
          ShowToast({
            message: res?.responseData?.message,
            type: toastType.ERROR,
          });
        } else {
          ShowToast({
            message: "User update successfully",
            type: toastType.SUCCESS,
          });
          setState("");
          logOut();
        }
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const ActiveColor = (progress) => {
    if (passwordStrength >= 66.66) return "#BDD6AB";
    else if (passwordStrength >= 33.33) {
      return "#FFE8AE";
    } else {
      return "#F6C0B8";
    }
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 flex justify-center items-center fixed w-full h-full right-0 top-0">
      <div className="flex flex-col justify-between bg-white w-112.5 min-h-155 shadow-md rounded-20 p-7.5">
        <div className="flex flex-col gap-7.5 ">
          <div className="flex justify-between">
            <p className="text-black-100 font-ManropeBold text-ft4">
              Edit password
            </p>
            <Close className="cursor-pointer" onClick={() => setState("")} />
          </div>
          <div className="flex flex-col gap-7.5">
            <div className="flex flex-col">
              <p className="text-black-100 font-ManropeMedium text-ft3">
                Current Password
              </p>
              <div className="flex h-16.25 py-2.5 px-6.25 items-center rounded-full bg-grey-50  justify-between">
                <input
                  type={passwordVisibility["current"] ? "text" : "password"}
                  placeholder="Password"
                  value={values?.currentPassword}
                  className="bg-grey-50 text-black-150 text-ft3 focus:outline-none w-95per h-full"
                  onChange={(e) =>
                    handlePasswordChange("currentPassword", e.target.value)
                  }
                />
                <button
                  onClick={() => handlePasswordVisibilityToggle("current")}
                >
                  {passwordVisibility["current"] ? (
                    <img src={eyeShow} alt="eyeShow" />
                  ) : (
                    <img src={eyeHide} alt="eyeHide" />
                  )}
                </button>
              </div>
              {isCurrentPassExist && (
                <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                  Incorrect current password
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <p className="text-black-100 font-ManropeMedium text-ft3">
                New Password
              </p>
              <div className="flex h-16.25 py-2.5 px-6.25 items-center rounded-full bg-grey-50  justify-between">
                <input
                  type={passwordVisibility["new"] ? "text" : "password"}
                  placeholder="Password"
                  value={values?.newPassword}
                  className="bg-grey-50 text-black-150 text-ft3 focus:outline-none w-95per h-full"
                  onChange={(e) =>
                    handlePasswordChange("newPassword", e.target.value)
                  }
                />
                <button onClick={() => handlePasswordVisibilityToggle("new")}>
                  {passwordVisibility["new"] ? (
                    <img src={eyeShow} alt="eyeShow" />
                  ) : (
                    <img src={eyeHide} alt="eyeHide" />
                  )}
                </button>
              </div>
              {values?.newPassword && (
                <div className="flex items-center gap-2 mt-4 mb-1">
                  <div
                    style={{
                      backgroundColor: ActiveColor(passwordStrength),
                      width: `${passwordStrength}%`,
                    }}
                    className={`h-1.25 rounded-20 `}
                  />
                </div>
              )}

              <div
                className={`flex gap-2 items-center ${
                  !values?.newPassword ? "mt-4" : ""
                }`}
              >
                <span>Password requirements</span>

                <Stack sx={{ alignItems: "flex-start" }}>
                  <StyledTooltip
                    title={
                      <div className="flex gap-2 flex-col p-3 w-full h-full">
                        <span className="text-ft2 font-ManropeMedium text-black-50">
                          The password must meet the requirements:
                        </span>
                        <div className="flex flex-col gap-2 text-ft2 font-ManropeMedium text-black-50">
                          <div className="flex gap-1 items-center">
                            {passwordCheckMark.length ? (
                              <div className="w-4.5 h-4.5 rounded-full bg-green-50 p-0.5">
                                <img src={CheckMark} alt="check" />
                              </div>
                            ) : (
                              <div className="w-4.5 h-4.5 rounded-full bg-red-50 p-0.5">
                                <img src={Cross} alt="check" />
                              </div>
                            )}
                            <span>Minimum 8 characters</span>
                          </div>
                          <div className="flex gap-1 items-center">
                            {passwordCheckMark.upperCase ? (
                              <div className="w-4.5 h-4.5 rounded-full bg-green-50 p-0.5">
                                <img src={CheckMark} alt="check" />
                              </div>
                            ) : (
                              <div className="w-4.5 h-4.5 rounded-full bg-red-50 p-0.5">
                                <img src={Cross} alt="check" />
                              </div>
                            )}
                            <span>At least 1 uppercase letter (A-Z)</span>
                          </div>
                          <div className="flex gap-1 items-center">
                            {passwordCheckMark.number ? (
                              <div className="w-4.5 h-4.5 rounded-full bg-green-50 p-0.5">
                                <img src={CheckMark} alt="check" />
                              </div>
                            ) : (
                              <div className="w-4.5 h-4.5 rounded-full bg-red-50 p-0.5">
                                <img src={Cross} alt="check" />
                              </div>
                            )}
                            <span>At least 1 number (0-9)</span>
                          </div>
                          <div className="flex gap-1 items-center">
                            {passwordCheckMark.specialChar ? (
                              <div className="w-4.5 h-4.5 rounded-full bg-green-50 p-0.5">
                                <img src={CheckMark} alt="check" />
                              </div>
                            ) : (
                              <div className="w-4.5 h-4.5 rounded-full bg-red-50 p-0.5">
                                <img src={Cross} alt="check" />
                              </div>
                            )}
                            <span> At least 1 special character</span>
                          </div>
                        </div>
                      </div>
                    }
                    arrow
                    placement="bottom"
                  >
                    <img src={Info} alt="info" className="w-4 h-4" />
                  </StyledTooltip>
                </Stack>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="text-black-100 font-ManropeMedium text-ft3">
                Repeat Password
              </p>
              <div className="flex h-16.25 py-2.5 px-6.25 items-center rounded-full bg-grey-50  justify-between">
                <input
                  type={passwordVisibility["repeat"] ? "text" : "password"}
                  placeholder="Password"
                  value={values?.repeatPassword}
                  className="bg-grey-50 text-black-150 text-ft3 focus:outline-none w-95per h-full"
                  onChange={(e) =>
                    handlePasswordChange("repeatPassword", e.target.value)
                  }
                />
                <button
                  onClick={() => handlePasswordVisibilityToggle("repeat")}
                >
                  {passwordVisibility["repeat"] ? (
                    <img src={eyeShow} alt="eyeShow" />
                  ) : (
                    <img src={eyeHide} alt="eyeHide" />
                  )}
                </button>
              </div>
              {isPassMatch && (
                <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                  Passwords do not match
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-7 pt-7.5">
          <button
            disabled={
              isCurrentPassExist ||
              isPassMatch ||
              !values?.currentPassword ||
              !values?.newPassword ||
              !values?.repeatPassword ||
              loading ||
              passwordStrength < 100
            }
            className={`${
              isCurrentPassExist ||
              isPassMatch ||
              !values?.currentPassword ||
              !values?.newPassword ||
              !values?.repeatPassword ||
              loading ||
              passwordStrength < 100
                ? "bg-grey-50"
                : "bg-orange-50"
            } text-black-100 font-ManropeBold text-ft3 w-full h-16.25 rounded-full`}
            onClick={() => {
              handlePassWordChange();
            }}
          >
            {loading ? <CircularProgress className="text-orange-50" /> : "Save"}
          </button>
          <span
            className="flex justify-center text-orange-50 font-ManropeBold text-ft3 cursor-pointer"
            onClick={() => {
              setState("FORGOT_PASSWORD");
            }}
          >
            Forgot Password
          </span>
        </div>
      </div>
    </div>
  );
};

export default PasswordPopUp;
