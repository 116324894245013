import React, { useEffect, useRef, useState } from "react";
import left from "../../assets/Icons/Left.svg";
import DetailsProfile from "../../common/detailsProfile";
import pause from "../../assets/Icons/pause.svg";
import ViolationsInfo from "./violationsInfo";
import DetailsBalance from "../../common/detailsBalance";
import DetailsGeneralInfo from "../entityAccount/details/detailsGeneralInfo";
import DetailsContact from "../../common/detailsContact";
import DisablePopUp from "../../common/disablePopUp";
import Post from "../DashboardPage/details/Post";
import { UserService } from "../../services";
import defaultImage from "../../assets/Icons/signupProfileSmall.png";
import utility from "../../utility";
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import WarningPopup from "../../common/warningPopup";
import SuspendedUserPopUp from "../DashboardPage/details/SuspendedUserPopUp";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import tick from "../../assets/Icons/Tick.svg";
import time from "../../assets/Icons/Time.svg";
import placeholder from "../../assets/Icons/Placeholder.svg";
import { CircularProgress } from "@mui/material";
import OutsideClickRef from "../../common/outsideClickRef";
import { useSelector } from "react-redux";
import WalletService from "../../services/walletService";
import BlockChainService from "../../services/blockChainService";
import TableFooter from "../../common/tableFooter";

/**
 * React component for displaying user details, violations, and comments.
 *
 * @component
 * @returns {JSX.Element} - UserDetails component
 */

const UserDetails = () => {
  const [isUserExpanded, setUserExpanded] = useState(false);
  const [isViolations, setViolations] = useState("violations");
  const [isInfo, setInfo] = useState("");
  const [isGeneralExpanded, setGeneralExpanded] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [pollDetails, setPollDetails] = useState([]);
  const [isDisablePopUp, setDisablePopUp] = useState(false);
  const [violationDetails, setViolationDetails] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const history = useHistory();
  const [comment, setComment] = useState(false);
  const optionsRef = useRef(null);
  const [commentDetails, setCommentDetails] = useState([]);
  const [suspendType, setSuspendType] = useState("");
  const [profileType, setProfileType] = useState("");
  const [seenActivatePopUp, setSeenActivatePopUp] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tabType = queryParams.get("tab");
  const haveAudit = queryParams.get("from");
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [balance, setBalance] = useState("");
  const [popUpVisible, setPopUpVisible] = useState({
    popUp: false,
    keyName: "",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [limit, setLimit] = useState(25);
  const [loadingUser, setLoadingUser] = useState(false);
  const totalPages = Math.ceil(totalDocs / limit);
  const [userID, setUserID] = useState("");
  const [totalDocsComment, setTotalDocsComment] = useState(0);
  const [limitComment, setLimitComment] = useState(25);
  const [currentPageComment, setCurrentPageComment] = useState(0);
  const totalPagesComment = Math.ceil(totalDocsComment / limitComment);
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePageChangeComment = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPagesComment) {
      setCurrentPageComment(pageNumber);
    }
  };
  /**
   * Fetches user details, violations, and comments from the server.
   * Updates component state accordingly.
   *
   * @async
   * @function
   * @returns {Promise<void>} - A Promise that resolves after fetching details.
   */
  const getDetails = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getViolationDetails(id)
      );
      if (error || !response) return;

      setDetails(response);
      setUserID(response?.userDetails?._id);
      setViolationDetails(response?.violationsWithCount);

      setProfileType(
        response?.userDetails?.role === "USER"
          ? response?.userDetails?.isBlueTickVerified
          : response?.userDetails?.entityType
      );

      await getPollDetailsComment(response?.userDetails?._id);
      if (response?.userDetails?.role === "USER") {
        await getPollDetailsUser(response?.userDetails?._id);
      } else {
        await getPollDetailsEntity(response?.userDetails?._id);
      }

      setLoading(false);
      setUserExpanded(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const getPollDetailsUser = async (userId) => {
    setLoadingUser(true);
    try {
      const requestParams = {
        limit: limit,
        skip: currentPage,
      };

      const [err, res] = await utility.parseResponse(
        new UserService().getPostDetails(userId || userID, requestParams)
      );
      if (err || !res) return;
      setPollDetails(res?.postsList);
      setTotalDocs(res?.totalCount);
      setLoadingUser(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingUser(false);
    }
  };
  const getPollDetailsComment = async (userId) => {
    setLoadingUser(true);
    try {
      const requestParams = {
        limit: limitComment,
        skip: currentPageComment,
      };

      const [errComment, resComment] = await utility.parseResponse(
        new UserService().userAccountComment(userId || userID, requestParams)
      );
      if (errComment || !resComment) return;
      setCommentDetails(resComment?.comments);
      setTotalDocsComment(resComment?.totalCount);
      setLoadingUser(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingUser(false);
    }
  };

  const getPollDetailsEntity = async (userId) => {
    setLoadingUser(true);
    try {
      const requestParams = {
        limit: limit,
        skip: currentPage,
      };

      const [err, res] = await utility.parseResponse(
        new UserService().getPostDetails(userId || userID, requestParams)
      );
      if (err || !res) return;
      setPollDetails(res?.postsList);
      setTotalDocs(res?.totalCount);
      setLoadingUser(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingUser(false);
    }
  };

  /**
   * Handles the activation of a suspended user.
   * Sends a request to the server to activate the user.
   *
   * @async
   * @function
   * @param {string} id - The user ID to activate.
   * @returns {Promise<void>} - A Promise that resolves after handling the activation.
   */
  const handleActivateUser = async (id) => {
    let response;
    setUpdatingUser(true);
    try {
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getDetails();
      setSeenActivatePopUp(false);
      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };

  /**
   * Toggles the visibility of a pop-up based on the keyName provided.
   * It also sets the userExpanded state to false.
   *
   * @function
   * @param {string} value - The keyName for the pop-up.
   * @returns {void}
   */
  const togglePopUpVisble = (value) => {
    setPopUpVisible((prev) => ({
      ...prev,
      popUp: !popUpVisible.popUp,
      keyName: value,
    }));
    setUserExpanded(false);
  };

  /**
   * Renders the profile dots based on the user's status.
   * It displays different options for active and suspended users.
   *
   * @function
   * @param {string} status - The status of the user.
   * @returns {JSX.Element | null} - Rendered profile dots or null.
   */
  const renderProfileDots = (status) => {
    return status === "ACTIVE" && isUserExpanded ? (
      <OutsideClickRef
        state={optionsRef}
        setState={() => setUserExpanded(false)}
      >
        <div
          onClick={() => togglePopUpVisble("suspend")}
          className="absolute right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-10 bg-white cursor-pointer"
        >
          <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
            <img src={pause} alt="Pause" />
            <p className="text-ft3 font-ManropeLight text-black-100">Suspend</p>
          </button>
        </div>
      </OutsideClickRef>
    ) : (
      isUserExpanded && status === "SUSPENDED" && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setUserExpanded(false)}
        >
          <div className="absolute flex flex-col right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-10 bg-white">
            <button
              onClick={() => setSeenActivatePopUp(!seenActivatePopUp)}
              className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
            >
              <img src={tick} alt="tick" />
              <p className="text-ft3 font-ManropeRegular text-black-100">
                Activate
              </p>
            </button>
            <button
              className="flex h-16.25 px-5 items-center gap-3.75 shadow-custom"
              onClick={() => togglePopUpVisble("edit")}
            >
              <img src={time} alt="time" />
              <p className="text-ft3 font-ManropeRegular text-black-100">
                Edit Suspended time
              </p>
            </button>
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Handles the rejection of a user report.
   * Sends a request to the server to reject the violation report.
   *
   * @async
   * @function
   * @returns {Promise<void>} - A Promise that resolves after handling the rejection.
   */
  const handleRejectViolation = async () => {
    let response;
    try {
      response = await new UserService().rejectViolation(id);
      if (response?.statusCode === 400) {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Report rejected",
          type: toastType.SUCCESS,
        });
        history.push("/moderations");
      }
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
    }
  };

  const getAddress = async () => {
    try {
      const [err, res] = await utility.parseResponse(
        new WalletService().getAddress(details?.userDetails?._id)
      );
      if (err || !res) return;

      const [error, response] = await utility.parseResponse(
        new BlockChainService().getBalance(res?.responseData?.publicKey)
      );
      if (error && !response) return;

      setBalance(response?.balance);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };
  useEffect(() => {
    if (Object.keys(details)?.length > 0) getAddress();
    // eslint-disable-next-line
  }, [details]);

  useEffect(() => {
    const suspend = utility.getSuspensionType(
      details?.userDetails?.suspension?.type,
      details?.userDetails?.suspension?.period?.startDate,
      details?.userDetails?.suspension?.period?.endDate
    );
    setSuspendType(suspend);
  }, [details]);

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!userID && details?.userDetails?.role === "USER") {
      getPollDetailsUser(userID);
    } else if (!!userID) {
      getPollDetailsEntity(userID);
    }
    // eslint-disable-next-line
  }, [currentPage, limit]);

  useEffect(() => {
    if (!!userID) getPollDetailsComment(userID);

    // eslint-disable-next-line
  }, [currentPageComment, limitComment]);

  /**
   * Renders a loading spinner while data is being fetched.
   *
   * @returns {JSX.Element | null} - Loading spinner or null.
   */
  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  /**
   * Main rendering of the UserDetails component.
   *
   * @returns {JSX.Element} - UserDetails component.
   */

  return (
    <div className="w-full pr-12.5 overflow-y-auto mt-5 pb-5">
      <div className="flex gap-5">
        <div className="w-75per ">
          <div className=" h-21.25 px-5 flex justify-between items-center rounded-20 bg-white row-start-1 row-span-1">
            <div className="flex gap-7.5">
              <img
                onClick={() => {
                  if (haveAudit) {
                    history.push("/audit-log");
                  } else {
                    history.push(`/moderations?tab=${tabType}`);
                  }
                }}
                src={left}
                alt="left"
                className="cursor-pointer"
              />
              <div className="flex gap-10">
                <div onClick={() => setComment(false)}>
                  <p
                    className={` ${
                      !comment
                        ? "font-ManropeBold text-ft5 text-black-100"
                        : "font-ManropeMedium text-ft3 text-black-200"
                    } cursor-pointer`}
                  >
                    {`${
                      !details?.violationDetails?.isDisabled
                        ? "Report"
                        : "Reported"
                    }`}{" "}
                    on User
                  </p>
                </div>
                <div onClick={() => setComment(true)} className="flex gap-1">
                  <p
                    className={` ${
                      comment
                        ? "font-ManropeBold text-ft5 text-black-100"
                        : "font-ManropeMedium text-ft3 text-black-200"
                    } cursor-pointer`}
                  >
                    Comments
                  </p>
                  <p className="font-ManropeBold text-ft3 text-black-200">
                    {totalDocsComment}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {!comment ? (
            <div className="flex flex-col w-full mb-5 mt-5 gap-5 flex-shrink-0 rounded-20">
              <div className="w-full rounded-20 bg-white">
                <div className="w-full rounded-20 bg-white">
                  <div className="h-70vh">
                    {!loadingUser ? (
                      <div className="overflow-y-scroll flex  items-center flex-col scrollbar-hide h-full">
                        {pollDetails.length > 0 ? (
                          pollDetails.map((poll) => (
                            <Post
                              key={poll?._id}
                              poll={poll}
                              id={id}
                              url={""}
                              setDisablePopUp={setDisablePopUp}
                              setSelectedUser={() =>
                                setSelectedUser(
                                  selectedUser === poll?._id ? null : poll?._id
                                )
                              }
                              selectedUser={selectedUser}
                              keyName="violation"
                            />
                          ))
                        ) : (
                          <div className="flex w-full h-full justify-center items-center gap-7.5 flex-shrink-0 self-center">
                            <img src={placeholder} alt="placeholder" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="flex justify-center  items-center h-full w-full">
                        <CircularProgress className="text-black-100 w-5 h-5" />
                      </div>
                    )}
                  </div>
                  <TableFooter
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    pageSize={limit}
                    setPageSize={setLimit}
                    totalPages={totalPages - 1}
                    totalDocs={totalDocs}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>

              {!details?.violationDetails?.isDisabled &&
                userPermissions?.indexOf("report:write:user") > -1 && (
                  <footer className="flex justify-end items-center pr-5 py-5  rounded-20 h-107px gap-5 bg-white">
                    <button
                      onClick={handleRejectViolation}
                      className="flex justify-center items-center rounded-full border border-solid border-black-20 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
                    >
                      Leave active
                    </button>
                    <button
                      className="flex justify-center items-center rounded-full border border-solid border-transparent bg-orange-50 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
                      onClick={() => togglePopUpVisble("suspend")}
                    >
                      Suspend User
                    </button>
                  </footer>
                )}
            </div>
          ) : (
            <div className="flex flex-col w-full mb-5 mt-5 gap-5 flex-shrink-0 rounded-20 bg-white">
              {commentDetails.length > 0 ? (
                <div className="flex flex-col h-77vh  overflow-y-scroll scrollbar-hide">
                  {!loadingUser ? (
                    commentDetails.map((item) => (
                      <div
                        className={`flex justify-between mt-5 mx-2.5 pl-5 pr-2.5 py-3.75 relative`}
                        key={item?._id}
                      >
                        <div className="flex gap-3.75">
                          <img
                            src={
                              details?.userDetails?.profilePhoto || defaultImage
                            }
                            alt="userProfile"
                            className="w-11.25 h-11.25 rounded-20"
                          />
                          <div className="flex flex-col gap-2.5">
                            <p className="font-ManropeExtraBold text-ft3 text-black-100">
                              {details?.userDetails?.fullName}
                            </p>
                            <p className="font-ManropeRegular text-ft3 text-black-100">
                              {item?.description}
                            </p>
                            <p className="font-ManropeRegular text-ft3 text-grey-200">
                              {utility.getTimeDifference(item?.addedOn)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center  items-center h-full w-full">
                      <CircularProgress className="text-black-100 w-5 h-5" />
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col overflow-y-scroll scrollbar-hide max-h-80vh">
                  <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
                    <img src={placeholder} alt="placeholder" />
                  </div>
                </div>
              )}
              <TableFooter
                currentPage={currentPageComment}
                handlePageChange={handlePageChangeComment}
                pageSize={limitComment}
                setPageSize={setLimitComment}
                totalPages={totalPagesComment - 1}
                totalDocs={totalDocsComment}
                setCurrentPage={setCurrentPageComment}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-5 relative w-25per">
          <DetailsProfile
            name={details?.userDetails?.fullName || "-"}
            username={details?.userDetails?.userName || "-"}
            renderPopUp={renderProfileDots(details?.userDetails?.status)}
            setUserExpanded={setUserExpanded}
            profile={details?.userDetails?.profilePhoto}
            isUserExpanded={isUserExpanded}
            isDisabled={details?.violationDetails?.isDisabled}
            profileType={profileType}
            permissionSuspend={
              details?.userDetails?.role === "USER"
                ? "user:write:suspend"
                : "business:write:suspend"
            }
            permissionActive={
              details?.userDetails?.role === "USER"
                ? "user:write:activate"
                : "business:write:activate"
            }
            status={details?.userDetails?.status}
          />
          <ViolationsInfo
            details={details?.userDetails}
            violations={isViolations}
            setViolations={setViolations}
            info={isInfo}
            setInfo={setInfo}
            violationDetails={violationDetails}
            disabledDetails={details?.userDetails?.suspension}
            isDisabled={details?.violationDetails?.isDisabled}
          />

          {isInfo === "info" && (
            <>
              <DetailsBalance
                price={balance || 0}
                fontSize={"text-ft6"}
                rewardAmount={details?.userDetails?.rewardAmount || 0}
              />
              <DetailsContact
                details={details?.userDetails}
                getDetails={getDetails}
                keyName="violation"
              />
              <DetailsGeneralInfo
                isDropDownOpen={isDropDownOpen}
                setIsDropDownOpen={setIsDropDownOpen}
                setGeneralExpanded={setGeneralExpanded}
                isGeneralExpanded={isGeneralExpanded}
                details={details?.userDetails}
                stateId={details?.userDetails?._id}
                getEntityDetailsData={getDetails}
                keyName="violation"
              />
            </>
          )}
        </div>
      </div>
      {popUpVisible.popUp && popUpVisible.keyName === "suspend" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={details?.userDetails?.userName}
          id={details?.userDetails?._id}
          getdetails={getDetails}
        />
      )}
      {popUpVisible.popUp && popUpVisible.keyName === "edit" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={details?.userDetails?.userName}
          id={details?.userDetails?._id}
          keyName={popUpVisible.keyName}
          type={suspendType}
          getdetails={getDetails}
          endDate={details?.userDetails?.suspension?.period?.endDate}
          reasonType={details?.userDetails?.suspension?.reason}
          name={details?.fullName}
          setActivatePopUp={setSeenActivatePopUp}
        />
      )}
      {seenActivatePopUp && (
        <WarningPopup
          handleActivateUser={() =>
            handleActivateUser(details?.userDetails?._id)
          }
          title={`Activate ${details?.userDetails?.userName} ?`}
          description={
            "User will be activated. All created content will be available for other users"
          }
          text={`${details?.userDetails?.fullName} activated`}
          setToggle={() => setSeenActivatePopUp(false)}
          loading={updatingUser}
        />
      )}

      {isDisablePopUp && (
        <DisablePopUp
          setToggle={setDisablePopUp}
          keyName={"post"}
          selectedId={selectedUser}
          getDetails={getDetails}
          suspendedState={details?.userDetails?.status}
          suspendedName={details?.userDetails?.fullName}
        />
      )}
    </div>
  );
};

export default UserDetails;
