import React from "react";

/**
 * MistakePopup component for displaying a popup with information about found mistakes.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.downloadErrorExcel - Function to download an Excel file with error details.
 * @param {Object} props.popUp - Data object containing information about mistakes.
 * @param {number} props.popUp.count - Number of mistakes found.
 * @param {Array} props.popUp.data - Array of data related to mistakes.
 * @returns {JSX.Element} MistakePopup component.
 */

const MistakePopup = ({ downloadErrorExcel, popUp }) => {
  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white h-55 w-115 rounded-20 p-7.5">
        <div className="text-center w-full text-ft4 font-ManropeBold  text-black-50">
          <span>Found mistakes</span>
        </div>
        <div className="text-center w-full text-ft3 font-ManropeRegular  text-black-75 mt-4">
          <span>{`There are errors when loading users (${popUp?.count})`}</span>
        </div>
        <div>
          <button
            onClick={() => downloadErrorExcel(popUp?.data)}
            className="w-full bg-orange-50 rounded-20 mt-7.5 h-16.25 flex justify-center items-center font-ManropeBold text-ft1 text-black-50"
          >
            Download Documents
          </button>
        </div>
      </div>
    </div>
  );
};

export default MistakePopup;
