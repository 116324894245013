import React, { useEffect, useRef, useState } from "react";
import {
  sortableContainer,
  sortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import AddImage from "../../../../../../assets/Icons/plus-orange.svg";
import ShowToast from "../../../../../../common/showToast";
import {
  failureMessage,
  toastType,
} from "../../../../../../constant/commonArray";
import Cross from "../../../../../../assets/Icons/close-white.svg";
import Menu from "../../../../../../assets/Icons/menu-white.svg";
import { useDropzone } from "react-dropzone";
import ImageLogo from "../../../../../../assets/Icons/image-logo.svg";

/**
 * TypeImage component for managing image-related functionalities within a poll.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setState - Function to set component state.
 * @param {Object} props.state - Component state object.
 * @param {number} props.idx - Index of the poll category.
 * @returns {JSX.Element} TypeImage component JSX.
 */

const TypeImage = ({ setState, state, idx }) => {
  const fileInputRef = useRef(null);
  const [index, setIndex] = useState(null);

  /**
   * Handles sorting of images.
   * @param {Object} param - Sort event parameters.
   * @param {number} param.oldIndex - Old index of the sorted item.
   * @param {number} param.newIndex - New index of the sorted item.
   */ const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedImages = arrayMoveImmutable(
      state?.polls[idx]?.images,
      oldIndex,
      newIndex
    );

    setState((prevState) => {
      const updatedPollCategory = [...prevState.polls];
      updatedPollCategory[idx].images = updatedImages;
      return { ...prevState, polls: updatedPollCategory };
    });
  };

  /**
   * Handles deletion of an image.
   * @param {number} index - Index of the image to delete.
   */
  const handleDeleteClick = (index) => {
    if (state?.polls[idx]?.images?.length === 1) {
      setState((prevState) => {
        const updatedPollCategory = [...prevState.polls];
        updatedPollCategory[idx].images = [
          { dummyImages: "" },
          {
            dummyImages: "",
          },
        ];
        return { ...prevState, polls: updatedPollCategory };
      });
    } else {
      setState((prevState) => {
        const updatedPollCategory = [...prevState.polls];
        updatedPollCategory[idx].images = updatedPollCategory[
          idx
        ].images.filter((_, i) => i !== index);
        return { ...prevState, polls: updatedPollCategory };
      });
    }
  };

  const { getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 50 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    /**
     * Handles dropping of files into the dropzone.
     * @param {Array<File>} acceptedFiles - Array of accepted files.
     * @param {Array<Object>} fileRejections - Array of rejected files.
     */
    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED_50MB,
          type: toastType.ERROR,
        });
      } else {
        setState((prevState) => {
          const updatedPolls = [...prevState.polls];
          if (idx >= 0 && idx < updatedPolls.length) {
            const pollToUpdate = { ...updatedPolls[idx] };
            const updatedImages = [...pollToUpdate.images];
            updatedImages[index] = acceptedFiles[0];
            updatedPolls[idx] = {
              ...pollToUpdate,
              images: updatedImages,
            };
          }
          return { ...prevState, polls: updatedPolls };
        });
      }
    },
  });

  /**
   * Handles click event on a span element to trigger file input.
   * @param {number} index - Index of the image.
   */
  const handleSpanClick = (index) => {
    fileInputRef.current.click();
    setIndex(index);
  };

  const handleMoreImage = () => {
    if (state?.polls[idx]?.images?.length <= 5) {
      setState((prevState) => {
        const updatedPollCategory = [...prevState.polls];
        updatedPollCategory[idx].images = [
          ...updatedPollCategory[idx].images,
          { dummyImages: "" },
        ];
        return { ...prevState, polls: updatedPollCategory };
      });
    } else {
      ShowToast({
        message: "You can add only 6 images",
        type: toastType.ERROR,
      });
    }
  };

  useEffect(() => {
    if (state?.polls[idx]?.images?.length === 0) {
      setState((prevState) => {
        const updatedPollCategory = [...prevState.polls];
        updatedPollCategory[idx].images = [
          ...updatedPollCategory[idx].images,
          { dummyImages: "" },
          { dummyImages: "" },
        ];
        return { ...prevState, polls: updatedPollCategory };
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {" "}
      {state?.polls[idx]?.images?.length > 0 ? (
        <SortableContainer onSortEnd={onSortEnd} axis="xy" useDragHandle={true}>
          {state?.polls[idx]?.images?.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              idx={index}
              index={index}
              value={value}
              deleteFunc={handleDeleteClick}
              getInputProps={getInputProps}
              fileInputRef={fileInputRef}
              handleSpanClick={handleSpanClick}
              imageLength={state?.polls[idx]?.images}
            />
          ))}
        </SortableContainer>
      ) : (
        <div className="flex flex-wrap gap-10">
          <div
            onClick={() => handleSpanClick(0)}
            className="flex flex-col items-center justify-center w-40 h-40 bg-grey-50 rounded-20 cursor-pointer"
          >
            <img src={ImageLogo} alt="" />
            <span className="text-black-100 text-ft3 font-ManropeMedium">
              Add image
            </span>
            <input
              {...getInputProps()}
              key={state?.polls[idx]?.images?.length}
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept=".svg, .png, .jpg"
              multiple
            />
          </div>
          <div
            onClick={() => handleSpanClick(1)}
            className="flex flex-col items-center justify-center w-40 h-40 bg-grey-50 rounded-20 cursor-pointer"
          >
            <img src={ImageLogo} alt="" />
            <span className="text-black-100 text-ft3 font-ManropeMedium">
              Add image
            </span>
            <input
              {...getInputProps()}
              key={state?.polls[idx]?.images?.length}
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept=".svg, .png, .jpg"
              multiple
            />
          </div>
        </div>
      )}
      <div
        onClick={handleMoreImage}
        className="flex gap-2.5 items-center cursor-pointer pt-5"
      >
        <img src={AddImage} alt="Add" />
        <span className="text-orange-50 font-ManropeBold text-ft2">
          Add more
        </span>
      </div>
    </div>
  );
};

export default TypeImage;

const SortableItem = sortableElement(
  ({
    value,
    deleteFunc,
    idx,
    getInputProps,
    fileInputRef,
    handleSpanClick,
    imageLength,
  }) => {
    return (
      <div>
        {value?.dummyImages !== "" && value ? (
          <div className="flex flex-col relative items-center justify-center w-40 h-40  rounded-20 cursor-pointer">
            {value && (
              <img
                src={
                  value instanceof File
                    ? URL.createObjectURL(value)
                    : value?.value
                }
                alt=""
                className="rounded-20 w-40 h-40 object-fill"
              />
            )}
            <div
              onClick={() => {
                deleteFunc(idx);
              }}
              className="w-6 h-6 z-50 cursor-pointer rounded-full bg-black-100 bg-opacity-70 p-0.5 absolute top-2 right-2"
            >
              <img src={Cross} alt="check" />
            </div>
            <div className="cursor-pointer flex gap-1 absolute left-2 top-2 rounded-20 bg-black-100 bg-opacity-70  px-3 py-0.5 w-13">
              <span className="text-white text-ft2 font-ManropeBold">
                {idx + 1}
              </span>
              <DragHandle />
            </div>
          </div>
        ) : (
          <div className="flex relative flex-col w-40 h-40 bg-grey-50 rounded-20 cursor-pointer">
            <div
              onClick={() => {
                handleSpanClick(idx);
              }}
              className="flex justify-center items-center flex-col gap-1 h-40"
            >
              <img src={ImageLogo} alt="" />
              <span className="text-black-100 text-ft3 font-ManropeMedium">
                Add image
              </span>
            </div>
            <input
              {...getInputProps()}
              key={imageLength.length}
              ref={(el) => (fileInputRef.current = el)}
              type="file"
              className="hidden"
              accept=".svg, .png, .jpg"
              multiple
            />
            {imageLength?.length > 2 && (
              <div
                onClick={() => {
                  deleteFunc(idx);
                }}
                className="w-6 h-6 cursor-pointer rounded-full bg-black-50 p-0.5 absolute top-2 right-2"
              >
                <img src={Cross} alt="check" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <div className="bg-transparent flex flex-wrap gap-10 w-full">
      {children}
    </div>
  );
});

const DragHandle = SortableHandle(() => {
  return <img src={Menu} alt="menu" className="cursor-pointer" />;
});
