import React, { useState } from "react";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";

/**
 * Component for displaying a popup to add a link.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setOpenMedia - Function to set the open state of the media popup.
 * @param {Function} props.handleAddMedia - Function to handle adding media (link in this case).
 * @returns {JSX.Element} LinkPopup component.
 */
const LinkPopup = ({ setOpenMedia, handleAddMedia }) => {
  const [link, setLink] = useState({
    type: "",
    url: "",
  });
  const [error, setError] = useState("");

  /**
   * Checks if a given URL is valid.
   *
   * @param {string} url - URL to validate.
   * @returns {boolean} Whether the URL is valid or not.
   */
  const isValidUrl = (url) => {
    const regex =
      /^(https?:\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.(com|in|net|org|edu|gov|mil|co|info|biz|name|mobi|us|ca|uk)\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/g;
    return regex.test(url);
  };

  const handleSave = () => {
    if (isValidUrl(link.url)) {
      setOpenMedia(null);
      handleAddMedia(link);
    }

    setError("Please enter a valid URL");
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center right-0 top-0 h-full w-full">
      <div className="bg-white  relative flex flex-col h-112.5 w-115 z-50  top-30 rounded-20 ">
        <div className="flex w-full justify-between mb-5 px-7.5 pt-7.5">
          <h1 className="font-ManropeBold text-black-100 text-ft4">Add Link</h1>
          <Close
            onClick={() => setOpenMedia(null)}
            className="cursor-pointer"
          />
        </div>
        <div className="px-7.5 pt-5">
          <input
            type="text"
            placeholder="Link"
            value={link.url}
            onChange={(e) => {
              setLink((prev) => ({
                ...prev,
                url: e.target.value,
                type: "link",
              }));
              setError("");
            }}
            className="w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
          />
          {error && <p className="text-red-50 text-ft3">{error}</p>}
        </div>
        <div className="h-full flex w-full px-7.5 pb-7.5 justify-end items-end">
          <button
            disabled={!!error || link.url === ""}
            onClick={handleSave}
            className={`flex items-center justify-center rounded-full  w-full h-16.25  font-ManropeBold text-ft2 ${
              !!error || link.url === ""
                ? "bg-grey-50 text-grey-250"
                : "cursor-pointer bg-orange-50 text-black-100"
            }`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinkPopup;
