import React, { useMemo, useState } from "react";
import { ReactComponent as Close } from "../../../../assets/Icons/close.svg";
import calender from "../../../../assets/Icons/calender.svg";
import Time from "../../../../assets/Icons/Time.svg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";

/**
 * ActivePeriod component for managing start and end dates with optional times.
 * @component
 * @param {Object} props - Component props.
 * @param {Date} props.startDate - Start date.
 * @param {Function} props.setStartDate - Function to set start date.
 * @param {Date} props.endDate - End date.
 * @param {Function} props.setEndDate - Function to set end date.
 * @param {String} props.startTime - Start time.
 * @param {Function} props.setStartTime - Function to set start time.
 * @param {String} props.endTime - End time.
 * @param {Function} props.setEndTime - Function to set end time.
 * @param {Function} props.setActivePeriodOpen - Function to toggle visibility of the component.
 * @returns {JSX.Element} ActivePeriod component JSX.
 */

const ActivePeriod = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setStartTime,
  startTime,
  setEndTime,
  endTime,
  setActivePeriodOpen,
}) => {
  const [toggleCalender, setToggleCalender] = useState(false);
  const [toggleUntilCalender, setToggleUntilCalender] = useState(false);
  const [isUserSelectTime, setIsUserSelectTime] = useState({
    startTime: false,
    endTime: false,
  });

  const [dateStart, setDateStart] = useState(() => {
    if (startDate) return new Date(startDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  });

  const [dateEnd, setDateEnd] = useState(() => {
    if (endDate) return new Date(endDate);
    const today = new Date();
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 1);
    dayAfterTomorrow.setHours(0, 0, 0, 0);
    return dayAfterTomorrow;
  });

  const [selectTime, setSelectTime] = useState(() => {
    if (startTime) return startTime;
    const defaultTime = new Date();
    defaultTime.setMinutes(defaultTime.getMinutes() + 1);
    return defaultTime.toTimeString().slice(0, 5);
  });
  const [selectEndTime, setSelectEndTime] = useState(() => {
    if (endTime) return endTime;
    const defaultTime = new Date();
    defaultTime.setMinutes(defaultTime.getMinutes() + 1);
    return defaultTime.toTimeString().slice(0, 5);
  });
  const [error, setError] = useState({});
  const useStyles = makeStyles((theme) => ({
    datePickerDay: {
      color: "#191714",
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 400,
    },
    datePickerTypography: {
      color: "#28272799",
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 400,
    },
  }));

  const classes = useStyles();

  useMemo(() => {
    switch (error?.errorType) {
      case "invalidDate": {
        return setError((prev) => ({
          ...prev,
          dateError: "Date is not valid",
        }));
      }
      case "disablePast": {
        return setError((prev) => ({
          ...prev,
          dateError:
            "The selected time must be in the future for today's date.",
        }));
      }
      case "maxDate": {
        return setError((prev) => ({
          ...prev,
          dateError: "The selected time must can't be beyond the max date.",
        }));
      }
      default: {
        return "";
      }
    }
    // eslint-disable-next-line
  }, [error?.errorType]);

  useMemo(() => {
    switch (error?.errorTypeEnd) {
      case "invalidDate": {
        return setError((prev) => ({
          ...prev,
          endDateError: "Date is not valid",
        }));
      }
      case "disablePast": {
        return setError((prev) => ({
          ...prev,
          endDateError:
            "The selected time must be in the future for today's date.",
        }));
      }
      case "maxDate": {
        return setError((prev) => ({
          ...prev,
          endDateError: "The selected time must can't be beyond the max date.",
        }));
      }
      case "minDate": {
        return setError((prev) => ({
          ...prev,
          dateError: "The selected time must can't be beyond the max date.",
        }));
      }
      default: {
        return "";
      }
    }
    // eslint-disable-next-line
  }, [error?.errorTypeEnd]);

  /**
   * Retrieves the current timezone of the user's browser.
   * @returns {string} Current timezone.
   */
  function getTimezone() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  }

  /**
   * Adds specified number of days to a given date.
   * @param {Date} date - Starting date.
   * @param {number} days - Number of days to add.
   * @returns {Date} Resulting date after addition of days.
   */
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const minSelectableDate = addDays(dateStart, 1);
  const maxSelectableDate = addDays(dateStart, 45);

  /**
   * Handles the validation and changes of time selection.
   * @param {string} timeStart - Selected start time.
   * @param {string} timeEnd - Selected end time.
   * @param {Date} startDate - Start date.
   * @param {Date} endDate - End date.
   */

  const handleTimeChange = (timeStart, timeEnd, startDate, endDate) => {
    const differenceInDays = Math.floor(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const [hours, minutes] = timeStart.split(":").map(Number);
    const currentDate = new Date(startDate.toDateString());
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    const startTimeEpoch = currentDate.getTime();
    const curr = new Date();

    if (curr.getTime() <= startTimeEpoch) {
      if (differenceInDays === 1) {
        const startTime = new Date(`${endDate.toDateString()} ${timeStart}`);
        const endTime = new Date(`${endDate.toDateString()} ${timeEnd}`);

        if (startTime.getTime() <= endTime.getTime()) {
          setError((prev) => ({ ...prev, timeError: null }));
        } else {
          setError((prev) => ({
            ...prev,
            timeError: "Please select a poll duration of at least 1 day.",
          }));
        }
      } else if (differenceInDays === 45) {
        const startTime = new Date(`${endDate.toDateString()} ${timeStart}`);
        const endTime = new Date(`${endDate.toDateString()} ${timeEnd}`);

        if (startTime.getTime() >= endTime.getTime()) {
          setError((prev) => ({ ...prev, timeError: null }));
        } else {
          setError((prev) => ({
            ...prev,
            timeError: "Please select a poll duration max 45 days.",
          }));
        }
      } else {
        setError((prev) => ({ ...prev, timeError: null }));
      }
    } else {
      setError((prev) => ({
        ...prev,
        timeError: "Please select a current or future time.",
      }));
    }
  };

  const handleSaveDate = () => {
    const date = new Date();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const nowTime = `${hours}:${minutes}`;
    setStartDate(dateStart);
    setEndDate(dateEnd);
    if (isUserSelectTime.startTime) {
      setStartTime(selectTime);
    } else {
      setStartTime(nowTime);
    }
    if (isUserSelectTime.endTime) {
      setEndTime(selectEndTime);
    } else {
      setEndTime(nowTime);
    }
    setActivePeriodOpen(false);
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white  relative flex flex-col h-3/5 w-115 z-50 p-7.5 rounded-20 overflow-y-auto">
        <div className="flex justify-between">
          <span className="text-black-100 font-ManropeBold text-ft4">
            Active period
          </span>
          <Close
            onClick={() => setActivePeriodOpen(false)}
            className="cursor-pointer"
          />
        </div>
        <div className="mt-10">
          <div className="flex flex-col gap-10">
            <div className="flex gap-3.75">
              <div className="flex flex-col relative cursor-pointer">
                <p className="text-black-100 font-ManropeMedium text-ft3">
                  Start Date
                </p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label=""
                    disablePast
                    onError={(newError) =>
                      setError((prev) => ({ ...prev, errorType: newError }))
                    }
                    open={toggleCalender}
                    disableHighlightToday
                    onClose={() => setToggleCalender(false)}
                    onChange={(value) => {
                      setDateStart(value);
                      setToggleUntilCalender(false);
                      const selectedDate = new Date(value);
                      selectedDate.setHours(0, 0, 0, 0);
                      const endDate = new Date(dateEnd);
                      endDate.setHours(0, 0, 0, 0);
                      handleTimeChange(
                        selectTime,
                        selectEndTime,
                        selectedDate,
                        endDate
                      );
                    }}
                    value={dateStart}
                    classes={{ typography: classes.datePickerTypography }}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        height: "62px",
                        padding: "25px 10px 25px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "30px",
                        backgroundColor: "#f3f3f3",
                        border: "none",
                      },
                    }}
                    slotProps={{
                      textField: {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment
                              sx={{
                                width: "40px",
                                height: "40px",
                                cursor: "pointer",
                                marginRight: "12px",
                              }}
                              position="start"
                            >
                              <img
                                src={calender}
                                alt=""
                                onClick={() => {
                                  setToggleCalender(!toggleCalender);
                                }}
                              />
                            </InputAdornment>
                          ),
                        },
                      },
                      day: {
                        className: classes.datePickerDay,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="flex flex-col">
                <p className="text-black-100 font-ManropeMedium text-ft3">
                  Time
                </p>
                <div className="flex relative items-center justify-between px-6.25 py-4 rounded-full bg-grey-50 w-50">
                  <input
                    type="time"
                    value={selectTime}
                    onChange={(e) => {
                      setSelectTime(e.target.value);
                      const selectedDate = new Date(dateEnd);
                      selectedDate.setHours(0, 0, 0, 0);
                      const startDate = new Date(dateStart);
                      startDate.setHours(0, 0, 0, 0);
                      handleTimeChange(
                        e.target.value,
                        selectEndTime,
                        startDate,
                        selectedDate
                      );
                      setIsUserSelectTime((prev) => ({
                        ...prev,
                        startTime: true,
                      }));
                    }}
                    className="rounded-full px-2 py-1 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 w-full"
                  />
                  <img
                    src={Time}
                    alt={"time"}
                    className=" absolute right-8 pointer-events-none"
                  />
                </div>
              </div>
            </div>
          </div>
          {error?.errorType && (
            <span className=" text-red-50">{error?.dateError}</span>
          )}
        </div>

        <div className="mt-5">
          <div className="flex gap-3.75">
            <div className="flex flex-col relative cursor-pointer">
              <p className="text-black-100 font-ManropeMedium text-ft3">
                End Date
              </p>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label=""
                  disablePast
                  onError={(newError) =>
                    setError((prev) => ({ ...prev, errorTypeEnd: newError }))
                  }
                  open={toggleUntilCalender}
                  disableHighlightToday
                  onClose={() => setToggleUntilCalender(!toggleUntilCalender)}
                  onChange={(value) => {
                    setDateEnd(value);
                    setToggleUntilCalender(false);
                    const selectedDate = new Date(value);
                    selectedDate.setHours(0, 0, 0, 0);
                    const startDate = new Date(dateStart);
                    startDate.setHours(0, 0, 0, 0);
                    handleTimeChange(
                      selectTime,
                      selectEndTime,
                      startDate,
                      selectedDate
                    );
                  }}
                  value={dateEnd}
                  maxDate={maxSelectableDate}
                  minDate={minSelectableDate}
                  classes={{ typography: classes.datePickerTypography }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { border: "none" },
                      height: "62px",
                      padding: "25px 10px 25px 10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "30px",
                      backgroundColor: "#f3f3f3",
                      border: "none",
                    },
                  }}
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment
                            sx={{
                              width: "40px",
                              height: "40px",
                              cursor: "pointer",
                              marginRight: "12px",
                            }}
                            position="start"
                          >
                            <img
                              src={calender}
                              alt=""
                              onClick={() => {
                                setToggleUntilCalender(!toggleUntilCalender);
                              }}
                            />
                          </InputAdornment>
                        ),
                      },
                    },
                    day: {
                      className: classes.datePickerDay,
                    },
                  }}
                />
              </LocalizationProvider>
            </div>

            <div className="flex flex-col">
              <p className="text-black-100 font-ManropeMedium text-ft3">Time</p>
              <div className="flex relative items-center justify-between px-6.25 py-4 rounded-full bg-grey-50 w-50">
                <input
                  type="time"
                  value={selectEndTime}
                  onChange={(e) => {
                    setSelectEndTime(e.target.value);
                    const selectedDate = new Date(dateEnd);
                    selectedDate.setHours(0, 0, 0, 0);
                    const startDate = new Date(dateStart);
                    startDate.setHours(0, 0, 0, 0);
                    handleTimeChange(
                      selectTime,
                      e.target.value,
                      startDate,
                      selectedDate
                    );
                    setIsUserSelectTime((prev) => ({
                      ...prev,
                      endTime: true,
                    }));
                  }}
                  className="rounded-full px-2 py-1 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 w-full"
                />
                <img
                  src={Time}
                  alt={"time"}
                  className=" absolute right-8 pointer-events-none"
                />
              </div>
            </div>
          </div>
          {error.errorTypeEnd && (
            <span className=" text-red-50">
              {error.endDateError || error?.dateError}
            </span>
          )}
          {error.timeError && !error.errorTypeEnd && (
            <span className=" text-red-50">{error?.timeError}</span>
          )}
          {error.pastTime && !error.errorTypeEnd && !error.timeError && (
            <span className=" text-red-50">
              Please select a current or future time.
            </span>
          )}
        </div>

        <div className="mt-3">
          <span className="text-ft3 font-ManropeMedium text-black-50">
            Timezone: {getTimezone()}
          </span>
        </div>

        <div className="mt-auto">
          <button
            disabled={
              error.errorType ||
              error.errorTypeEnd ||
              error.timeError ||
              error.pastTime
            }
            onClick={() => handleSaveDate()}
            className={`w-full ${
              error.errorType ||
              error.errorTypeEnd ||
              error.timeError ||
              error.pastTime
                ? "bg-grey-50"
                : "bg-orange-50"
            }  rounded-full mt-7.5 h-16.25 flex justify-center items-center font-ManropeBold text-ft1 text-black-50 `}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivePeriod;
