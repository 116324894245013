import React, { useEffect, useState } from "react";
import arrowdown from "../assets/Icons/Arrow.svg";
import arrowUp from "../assets/Icons/Up.svg";
import filters from "../assets/Icons/filterIcon.svg";
import filtersDot from "../assets/Icons/filter-dot.svg";
import left from "../assets/Icons/Left.svg";
import right from "../assets/Icons/Right.svg";
import { CircularProgress } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
LoadingOverlay.propTypes = undefined;

/**
 * Table component renders a table with headers, body, and optional pagination.
 *
 * @component
 * @example
 * // Example usage of Table component
 * <Table
 *   headers={[
 *     { name: "Name", filter: true, sort: true },
 *     { name: "Date", startDate: true, endDate: true },
 *     // ... other header configurations
 *   ]}
 *   tableBody={ JSX representing table body }
 *   setPageSize={ function to set the page size }
 *   pageSize={25}
 *   renderFilter={JSX representing filter component }
 *   setClickFilter={function to set filter visibility }
 *   clickFilter={ state indicating filter visibility }
 *   loading={boolean indicating loading state }
 *   setSort={ function to set the sort order }
 *   sort={current sort order }
 *   handlePageChange={function to handle page change }
 *   currentPage={ current page number }
 *   totalDocs={ total number of documents/rows }
 *   setCurrentPage={ function to set the current page }
 *   setStartDate={ function to set start date filter }
 *   startDate={ start date filter value }
 *   setEndDate={ function to set end date filter }
 *   endDate={ end date filter value }
 *   clickFilterType={ state indicating type filter visibility }
 *   setClickFilterType={ function to set type filter visibility }
 *   renderFilterType={ JSX representing type filter component }
 *   tagStart={tag for start date filter }
 *   showPagination={true}
 *   isCountry={/* boolean indicating if the table is for country data }
 * />
 *
 * @param {Object[]} headers - Array of objects representing table headers.
 * @param {JSX} tableBody - JSX representing the table body.
 * @param {Function} [setPageSize=() => {}] - Function to set the page size.
 * @param {number} [pageSize=25] - Current page size.
 * @param {JSX} renderFilter - JSX representing the filter component.
 * @param {Function} [setClickFilter=() => {}] - Function to set filter visibility.
 * @param {boolean} clickFilter - State indicating filter visibility.
 * @param {boolean} loading - Boolean indicating loading state.
 * @param {Function} [setSort=() => {}] - Function to set the sort order.
 * @param {number} sort - Current sort order.
 * @param {Function} [handlePageChange=() => {}] - Function to handle page change.
 * @param {number} currentPage - Current page number.
 * @param {number} totalDocs - Total number of documents/rows.
 * @param {Function} [setCurrentPage=() => {}] - Function to set the current page.
 * @param {Function} [setStartDate=() => {}] - Function to set start date filter.
 * @param {string} startDate - Start date filter value.
 * @param {Function} [setEndDate=() => {}] - Function to set end date filter.
 * @param {string} endDate - End date filter value.
 * @param {boolean} clickFilterType - State indicating type filter visibility.
 * @param {Function} [setClickFilterType=() => {}] - Function to set type filter visibility.
 * @param {JSX} renderFilterType - JSX representing the type filter component.
 * @param {string} tagStart - Tag for start date filter.
 * @param {boolean} [showPagination=true] - Boolean to determine whether to show pagination.
 * @param {boolean} [isCountry=false] - Boolean indicating if the table is for country data.
 *
 * @returns {JSX.Element} The rendered Table component.
 */

const Table = ({
  headers,
  tableBody,
  setPageSize = () => {},
  pageSize = 25,
  renderFilter,
  setClickFilter = () => {},
  clickFilter,
  loading,
  setSort = () => {},
  sort = -1,
  handlePageChange = () => {},
  currentPage = 0,
  totalDocs,
  setCurrentPage = () => {},
  setStartDate = () => {},
  startDate,
  setEndDate = () => {},
  endDate,
  clickFilterType,
  setClickFilterType = () => {},
  renderFilterType,
  categoryFilter,
  setCategoryFilter = () => {},
  createdFor,
  setCreatedFor = () => {},
  tagStart,
  tagEnd,
  renderFilterCreatedFor,
  renderFilterCategory,
  showPagination = true,
  isCountry = false,
  setSortByType = () => {},
  sortByType,
  renderFilterTime,
  clickTime,
  setClickTime = () => {},
}) => {
  const [skipDoc, setSkipDoc] = useState(0);
  useEffect(() => {
    if (currentPage !== 0 && totalDocs >= currentPage * pageSize) {
      setSkipDoc(currentPage * pageSize);
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const modifyName = (item) => {
    const name = item
      ?.toLowerCase()
      .split(" ")
      .map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join("");
    return name;
  };

  let displayedRange = "";

  if (currentPage === 0 && totalDocs > pageSize) {
    displayedRange = `1-${pageSize}`;
  } else if (currentPage === 0) {
    displayedRange = `1-${totalDocs}`;
  } else if (totalDocs >= currentPage * pageSize + pageSize) {
    displayedRange = `${skipDoc + 1}-${skipDoc + pageSize}`;
  } else {
    displayedRange = `${skipDoc + 1}-${totalDocs}`;
  }

  return (
    <div className="flex flex-col w-full h-87per mt-5 flex-shrink-0 rounded-20 bg-white row-start-2 col-start-2 row-span-1 col-span-1 ">
      <LoadingOverlay
        active={loading}
        spinner={<CircularProgress className="text-black-100 w-5 h-5" />}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.7)",
            borderRadius: "20px",
          }),
        }}
      >
        <div className="h-77vh overflow-scroll scrollbar-hide rounded-t-20">
          <table className="w-full table-auto">
            <thead className="sticky top-0 bg-white z-20">
              <tr>
                {headers.map((item, idx) => {
                  let arrowImage = null;
                  if (item.sortByType) {
                    if (sort === -1 && sortByType !== modifyName(item?.name)) {
                      arrowImage = (
                        <img
                          onClick={() => {
                            setSortByType(modifyName(item?.name));
                            setSort(1);
                          }}
                          src={arrowdown}
                          alt="arrowdown"
                          className="w-4 h-4 flex-shrink-0 cursor-pointer"
                        />
                      );
                    } else if (
                      sort === 1 &&
                      sortByType === modifyName(item?.name)
                    ) {
                      arrowImage = (
                        <img
                          onClick={() => {
                            setSortByType(modifyName(item?.name));
                            setSort(-1);
                          }}
                          src={arrowUp}
                          alt="arrowdown"
                          className="w-4 h-4 flex-shrink-0 cursor-pointer"
                        />
                      );
                    } else {
                      arrowImage = (
                        <img
                          onClick={() => {
                            setSortByType(modifyName(item?.name));
                            setSort(1);
                          }}
                          src={arrowdown}
                          alt="arrowdown"
                          className="w-4 h-4 flex-shrink-0 cursor-pointer"
                        />
                      );
                    }
                  }
                  return (
                    <td key={idx} className={` ${idx === 0 && "p-5"}`}>
                      <div
                        className={`flex gap-1 items-center ${
                          idx === 1 && isCountry && "w-39.75"
                        }`}
                      >
                        <span className="text-black-100 font-ManropeBold text-ft2">
                          {item.name}
                        </span>
                        {item.filter && (
                          <div className="relative">
                            <img
                              onClick={() => setClickFilter(!clickFilter)}
                              src={item?.filterLength ? filtersDot : filters}
                              alt="filter"
                              className="w-4 h-4 flex-shrink-0 cursor-pointer"
                            />
                            {renderFilter}
                          </div>
                        )}
                        {item.typeFilter && (
                          <div className="relative">
                            <img
                              onClick={() =>
                                setClickFilterType(!clickFilterType)
                              }
                              src={
                                item?.typeFilterLength ? filtersDot : filters
                              }
                              alt="filter"
                              className="w-4 h-4 flex-shrink-0 cursor-pointer"
                            />
                            {renderFilterType}
                          </div>
                        )}
                        {item.categoryFilter && (
                          <div className="relative">
                            <img
                              onClick={() => setCategoryFilter(!categoryFilter)}
                              src={
                                item?.categoryFilterLength
                                  ? filtersDot
                                  : filters
                              }
                              alt="filter"
                              className="w-4 h-4 flex-shrink-0 cursor-pointer"
                            />
                            {renderFilterCategory}
                          </div>
                        )}
                        {item.createdFor && (
                          <div className="relative">
                            <img
                              onClick={() => setCreatedFor(!createdFor)}
                              src={
                                item?.createdForLength ? filtersDot : filters
                              }
                              alt="filter"
                              className="w-4 h-4 flex-shrink-0 cursor-pointer"
                            />
                            {renderFilterCreatedFor}
                          </div>
                        )}
                        {item.timeFilter && (
                          <div className="relative">
                            <img
                              onClick={() => setClickTime(!clickTime)}
                              src={
                                item?.timeFilterLength ? filtersDot : filters
                              }
                              alt="filter"
                              className="w-4 h-4 flex-shrink-0 cursor-pointer"
                            />
                            {renderFilterTime}
                          </div>
                        )}
                        {item.sort && sort === -1 ? (
                          <img
                            onClick={() => setSort(1)}
                            src={arrowdown}
                            alt="arrowdown"
                            className="w-4 h-4 flex-shrink-0 cursor-pointer"
                          />
                        ) : (
                          item.sort && (
                            <img
                              onClick={() => setSort(-1)}
                              src={arrowUp}
                              alt="arrowdown"
                              className="w-4 h-4 flex-shrink-0 cursor-pointer"
                            />
                          )
                        )}
                        {item.startDate && !startDate ? (
                          <img
                            onClick={() => setStartDate(tagStart)}
                            src={arrowdown}
                            alt="arrowdown"
                            className="w-4 h-4 flex-shrink-0 cursor-pointer"
                          />
                        ) : (
                          item.startDate &&
                          startDate === tagStart && (
                            <img
                              onClick={() => setStartDate("")}
                              src={arrowUp}
                              alt="arrowdown"
                              className="w-4 h-4 flex-shrink-0 cursor-pointer"
                            />
                          )
                        )}
                        {item.endDate && !endDate ? (
                          <img
                            onClick={() => setEndDate(tagEnd)}
                            src={arrowdown}
                            alt="arrowdown"
                            className="w-4 h-4 flex-shrink-0 cursor-pointer"
                          />
                        ) : (
                          item.endDate &&
                          endDate === tagEnd && (
                            <img
                              onClick={() => setEndDate("")}
                              src={arrowUp}
                              alt="arrowdown"
                              className="w-4 h-4 flex-shrink-0 cursor-pointer"
                            />
                          )
                        )}
                        {arrowImage}
                      </div>
                    </td>
                  );
                })}
                <td></td>
              </tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
        </div>
      </LoadingOverlay>
      {showPagination && (
        <div className="flex justify-end w-full h-16.25 flex-shrink-0 rounded-b-20 bg-white px-3 border-t border-grey-50">
          <div className="inline-flex items-center gap-10">
            <div className="flex items-start gap-1">
              <p className="text-black-50 font-ManropeLight text-ft2 opacity-50">
                Show per page:
              </p>
              <select
                id="itemsPerPage"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  setCurrentPage(0);
                  setSkipDoc(0);
                }}
                className="flex items-end gap-1.25 text-black-100 font-ManropeLight text-ft2 cursor-pointer"
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <p className="text-black-50 font-ManropeLight text-ft2 opacity-50">
              {displayedRange} of {totalDocs}
            </p>
            <div className="flex items-start gap-2.5">
              <img
                onClick={() => handlePageChange(currentPage - 1)}
                src={left}
                alt="left"
                className={`flex w-6.25 h-6.25 p-1.25 0 1.25 0 justify-center items-center ${
                  currentPage > 0 ? "cursor-pointer" : "opacity-50"
                }`}
              />
              <img
                onClick={() => handlePageChange(currentPage + 1)}
                src={right}
                alt="right"
                className={`w-6.25 h-6.25 ${
                  currentPage * pageSize + pageSize < totalDocs
                    ? "cursor-pointer"
                    : "opacity-50"
                }`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
