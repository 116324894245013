import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Close } from "../../assets/Icons/close.svg";
import ShowToast from "../../common/showToast";
import { eventConstants, toastType } from "../../constant/commonArray";
import { UserService } from "../../services";
import utility from "../../utility";

/**
 * ResetPopUp component for initiating a password reset.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Function} props.setState - Function to set the state of the parent component.
 * @returns {JSX.Element} ResetPopUp component.
 */

const ResetPopUp = ({ setState }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  /**
   * Initiates the password reset process.
   * @async
   * @returns {void}
   */
  const resetPassword = async () => {
    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      let [error, passwordResetRes] = await utility.parseResponse(
        new UserService().forgotPassword({
          email: user?.email,
        })
      );
      if (error || !passwordResetRes) {
        ShowToast({
          message: error?.message,
          type: toastType.ERROR,
        });
        return;
      } else {
        ShowToast({
          message: `A password reset email has been sent to ${user?.email}. If you didn't receive an email, check your spam folder`,
          type: toastType.SUCCESS,
        });
        setState("");
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };
  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 flex justify-center items-center fixed w-full h-full right-0 top-0">
      <div className="flex flex-col justify-between bg-white w-112.5 h-155 shadow-md rounded-20 p-7.5">
        <div className="flex flex-col gap-7.5">
          <div className="flex justify-between">
            <p className="text-black-100 font-ManropeBold text-ft4">
              Reset password
            </p>
            <Close className="cursor-pointer" onClick={() => setState("")} />
          </div>
          <p className="text-black-100 font-ManropeRegular text-ft3">
            We will send you a password reset email
          </p>
          <div className="flex flex-col">
            <p className="text-black-100 font-ManropeMedium text-ft3">Email</p>
            <input
              type="email"
              value={user?.email}
              disabled
              className="w-full h-16.25 px-6.25 py-2.5 rounded-full bg-grey-50 text-black-100 font-ManropeRegular text-ft3 focus:outline-none"
            />
          </div>
        </div>
        <button
          onClick={resetPassword}
          className="w-full bg-orange-50 rounded-full h-16.25 flex justify-center items-center font-ManropeBold text-ft2 text-black-100"
        >
          Reset password
        </button>
      </div>
    </div>
  );
};

export default ResetPopUp;
