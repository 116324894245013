import React, { useState, useEffect, useCallback } from "react";
import close from "../../assets/Icons/close.svg";
import utility from "../../utility";
import UserEntityService from "../../services/userEntityService";
import ShowToast from "../../common/showToast";
import {
  cookiesConstants,
  eventConstants,
  failureMessage,
  toastType,
} from "../../constant/commonArray";
import { useDispatch } from "react-redux";
import { sessionManager } from "../../managers/sessionManager";

/**
 * React component representing a popup for email-related actions (e.g., verification, password reset).
 *
 * @component
 * @example
 * const handleForgot = () => {
 *   // handle "Forgot" action
 * };
 *
 * <EmailPopUp
 *   detail="user@example.com"
 *   notify={true}
 *   heading="Email Verification"
 *   subHeading="Enter the code sent to:"
 *   forgot={handleForgot}
 *   setLoginSeen={(isVisible) => setLoginPopupVisibility(isVisible)}
 * />
 *
 * @param {Object} props - Component properties.
 * @param {string} props.detail - Email or detail value.
 * @param {boolean} props.notify - Indicates whether it's for email verification (true) or other actions (false).
 * @param {string} props.heading - Heading for the popup.
 * @param {string} props.subHeading - Subheading for the popup.
 * @param {Function} props.forgot - Callback function for handling the "Forgot" action.
 * @param {Function} props.setLoginSeen - Callback function to control the visibility of the login popup.
 * @returns {JSX.Element} JSX for the email popup component.
 */

const EmailPopUp = (props) => {
  const dispatch = useDispatch();
  const [detail, setDetail] = useState(props.detail);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  /**
   * Checks if the entered email is valid and updates the state accordingly.
   *
   * @function
   * @name checkEmailFilled
   * @param {string} detailValue - Email or detail value.
   * @returns {void}
   */

  const checkEmailFilled = useCallback((detailValue) => {
    let emailValue = utility.validateEmail(detailValue);
    setIsValidEmail(emailValue);
  }, []);

  /**
   * Handles the change event in the input field.
   *
   * @function
   * @name handleDetailChange
   * @param {Object} e - Event object.
   * @returns {void}
   */

  const handleDetailChange = useCallback(
    (e) => {
      const detailValue = e.target.value;
      setDetail(detailValue);
      checkEmailFilled(detailValue);
    },
    [checkEmailFilled]
  );

  useEffect(() => {
    // Call handleDetailChange only on the initial render
    if (initialRender) {
      handleDetailChange({ target: { value: props.detail } });
      setInitialRender(false);
    }
  }, [props.detail, initialRender, handleDetailChange]); // Re-run the effect if props.detail, initialRender, or handleDetailChange changes

  /**
   * Handles the click event on the "Forgot" button.
   *
   * @function
   * @name handleClick
   * @returns {void}
   */
  const handleClick = () => {
    props.forgot();
  };

  /**
   * Sends an OTP for email verification and triggers the login popup.
   *
   * @function
   * @name sendOtp
   * @returns {void}
   */
  // FUNCTION TO SEND OTP
  const sendOtp = async () => {
    sessionManager.setDataInCookies(detail, cookiesConstants.VALIDATE_EMAIL);
    try {
      if (!isValidEmail) {
        ShowToast({
          message: failureMessage.ENTER_VALID_EMAIL,
          type: toastType.ERROR,
        });
        return;
      }
      dispatch({ type: eventConstants.SHOW_LOADER });
      let [error, otpRes] = await utility.parseResponse(
        new UserEntityService().generateOtp({
          email: detail,
          isPassword: true,
        })
      );

      if (error || !otpRes) {
        ShowToast({
          message: error?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: otpRes?.message,
          type: toastType.SUCCESS,
        });
        props.forgot();
        props.setLoginSeen(true);
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white  relative flex flex-col justify-between items-start h-2/3 w-115 z-50 p-7.5 rounded-20">
        <div className="flex flex-col items-start ">
          <div
            className={`flex flex-col items-start ${
              props.notify ? "gap-5" : ""
            }`}
          >
            <div className="flex w-97.5 justify-between items-start">
              <p className="text-black-100 text-ft4 font-ManropeBold text-center">
                {props.notify ? "Email verification" : props.heading}
              </p>
              <img
                src={close}
                alt="close"
                className="cursor-pointer text-black-100"
                onClick={handleClick}
              />
            </div>
            <p
              className={`text-ft3 font-ManropeRegular text-black-100 font-normal mb-2.5 ${
                props.notify ? "" : "mt-5"
              }`}
            >
              {props.notify
                ? `Enter the code sent to: ${props.detail}`
                : props.subHeading}
            </p>

            <input
              type="email"
              value={detail}
              onChange={handleDetailChange}
              className="flex h-16.25 py-2.5 px-7.5 justify-center items-center flex-shrink-0 self-stretch bg-grey-50 rounded-full text-black-150 text-ft2 font-ManropeRegular focus:outline-none"
            />
          </div>
        </div>
        {props.notify && (
          <button
            className={`flex h-16.25 py-2.5 px-7.5 justify-center items-center flex-shrink-0 self-stretch cursor-pointer  rounded-full text-black-50 text-ft2 font-ManropeBold ${
              isValidEmail ? "bg-orange-50" : "bg-grey-50 text-black-200"
            }`}
            onClick={() => {
              const existingEmails =
                sessionManager.getDataFromCookies(
                  cookiesConstants.TRIED_EMAILS
                ) || [];
              const findMyEmail = existingEmails.find(
                (email) => email === detail
              );
              if (findMyEmail === detail && props.disableOtp) {
                ShowToast({
                  message: "Please try again after some time.",
                  type: toastType.ERROR,
                });
              } else {
                sendOtp();
              }
            }}
          >
            Next
          </button>
        )}
        {!props.notify && (
          <button
            className={`flex h-16.25 py-2.5 px-7.5 justify-center items-center flex-shrink-0 self-stretch rounded-full text-black-50 text-ft2 font-ManropeBold ${
              isValidEmail
                ? "bg-orange-50"
                : "bg-grey-50 text-black-200 pointer-events-none"
            }`}
            // onClick={() => handleSubmission(detail)}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default EmailPopUp;
