import React from "react";

/**
 * LearnMore component for displaying additional information with a 'Done' button.
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setIsLearnMore - Function to toggle LearnMore visibility
 * @param {string} props.description - Description or background information to display
 * @returns {JSX.Element} - Rendered component
 */
const LearnMore = ({ setIsLearnMore, description }) => {
  return (
    <div className="bg-black-50 flex flex-col p-4 justify-between overflow-y-auto overflow-x-hidden scrollbar-hide w-full h-full">
      <div className="flex flex-col gap-10 pt-5">
        <div className="flex flex-col gap-3">
          <span className="text-ft52 font-ManropeBold text-white">
            Learn more
          </span>
          <span className="text-ft2 font-ManropeRegular text-white text-opacity-60">
            Background information
          </span>
        </div>
        <div>
          <span className="text-ft3 font-InterRegular text-white">
            {description}
          </span>
        </div>
      </div>
      <div className="w-full h-16.25 rounded-full bg-orange-50">
        <button
          onClick={() => setIsLearnMore(false)}
          className="text-ft2 w-full h-full font-ManropeBold text-black-50 flex justify-center items-center"
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default LearnMore;
