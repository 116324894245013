import React from "react";
import PollPreviewText from "./pollPreviewText";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";
import RankingPreviewText from "./rankingPreviewText";
import TwoSidedPreview from "./twoSidedPreview";
import OpenQuestion from "./openQuestion";
import Survey from "./survey";

/**
 * PreviewPollPopup component for displaying different types of preview components based on the question type.
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setPreview - Function to set preview state
 * @param {Object} props.data - Data object containing information for preview
 * @param {Object} props.account - Account information
 * @param {Object} props.images - Images related to the preview
 * @returns {JSX.Element} - Rendered component
 */
const PreviewPollPopup = ({ setPreview, data, account, images }) => {
  /**
   * Determines the preview component based on the question type.
   * @param {Object} data - Data object containing information for preview
   * @param {Object} account - Account information
   * @param {Object} images - Images related to the preview
   * @returns {JSX.Element} - Preview component based on question type
   */
  function getPreviewComponent(data, account, images) {
    const questionType = data?.post?.questionType || data?.questionType;

    switch (questionType) {
      case "POLL":
      case "Poll":
        return (
          <PollPreviewText data={data} account={account} images={images} />
        );
      case "RANKING":
      case "Ranking":
        return (
          <RankingPreviewText data={data} account={account} images={images} />
        );
      case "TWO_SIDED":
      case "Two-Sided Question":
        return <TwoSidedPreview data={data} account={account} />;
      case "OPEN_QUESTION":
      case "Open Question":
        return <OpenQuestion data={data} account={account} />;
      default:
        return <Survey setPreview={setPreview} data={data} account={account} />;
    }
  }

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex items-center justify-center right-0 top-0 h-full w-full">
      <div className="h-80per w-142.5 flex gap-10">
        {getPreviewComponent(data, account, images)}
        <div onClick={() => setPreview(false)}>
          <Close className="cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default PreviewPollPopup;
