import React, { useState } from "react";
import Chevrondown from "../../assets/Icons/Chevrondown.svg";
import Information from "../../assets/Icons/Information.svg";
import Edit from "../../assets/Icons/Edit.svg";
import EditPopup from "../../common/editPopup";
import ShowToast from "../../common/showToast";
import { successMessage, toastType } from "../../constant/commonArray";
import { UserService } from "../../services";
import utility from "../../utility";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

/**
 * Component for displaying and editing general information about a staff member.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.details - Staff member details
 * @param {Function} props.getDetails - Function to fetch updated details
 * @returns {JSX.Element} - GeneralInfo component
 */

const GeneralInfo = ({ details, getDetails }) => {
  const [isGeneralExpanded, setGeneralExpanded] = useState(false);
  const [edit, setEditPopup] = useState(null);
  const { id } = useParams();
  const [state, setState] = useState({
    role: details?.roleDetails?.role,
    name: details?.name,
    roleId: details?.roleDetails?.roleId || "",
  });
  const [updating, setUpdating] = useState(false);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  /**
   * Handles the submission of edited information.
   *
   * @async
   * @function
   * @returns {void}
   */

  const handleSubmission = async () => {
    setUpdating(true);
    let requestData = {};
    if (state.role !== details?.roleDetails?.roleId) {
      requestData.roleId = state?.roleId;
    }
    if (state.name !== details?.name) {
      requestData.name = state?.name;
    }
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().editStaff(id, requestData)
      );
      if (err || !res) return;

      if (res?.responseCode === 200) {
        ShowToast({
          message: successMessage.USER_UPDATED,
          type: toastType.SUCCESS,
        });
        getDetails();
        setEditPopup(null);
      } else {
        ShowToast({
          message: res?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setUpdating(false);
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Renders the appropriate EditPopup component based on the type of information being edited.
   *
   * @function
   * @returns {JSX.Element|null} - EditPopup component or null if not needed
   */
  const renderEditPopup = () => {
    switch (edit) {
      case "Name":
        return (
          <EditPopup
            name={"Name"}
            setEditPopup={setEditPopup}
            setState={setState}
            value={details?.name}
            state={state}
            keyName="name"
            handleSubmission={handleSubmission}
            loading={updating}
          />
        );
      case "Role":
        return (
          <EditPopup
            name={"Role"}
            setEditPopup={setEditPopup}
            setState={setState}
            value={details?.roleDetails?.role}
            state={state}
            keyName="roleId"
            handleSubmission={handleSubmission}
            loading={updating}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`flex flex-col p-5 cursor-pointer rounded-20 bg-white w-full`}
    >
      <div
        onClick={() => setGeneralExpanded(!isGeneralExpanded)}
        className="flex items-center gap-2 xl:gap-3.75"
      >
        <div className="w-auto">
          <div className="flex items-center justify-center w-12.5 h-12.5 rounded-full bg-purple-50">
            <img src={Information} alt="Information" />
          </div>
        </div>
        <div className="flex w-full justify-between">
          <p className="text-ft3 2xl:text-ft32 font-ManropeBold text-black-100">
            General Info
          </p>
          <img src={Chevrondown} alt="Chevrondown" className="cursor-pointer" />
        </div>
      </div>
      {isGeneralExpanded && (
        <div className="flex flex-col gap-5 pt-7.5">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">Name</p>
              <p className="font-ManropeRegular text-ft3 text-grey-250">
                {details?.name}
              </p>
            </div>
            {userPermissions?.indexOf("staff:write:details") > -1 && (
              <img
                src={Edit}
                alt="Edit"
                onClick={() => setEditPopup("Name")}
                className="cursor-pointer w-5 h-5"
              />
            )}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">Role</p>
              <p className="font-ManropeRegular text-ft3 text-grey-250">
                {details?.roleDetails?.role || "-"}
              </p>
            </div>
            {userPermissions?.indexOf("staff:write:details") > -1 && (
              <img
                src={Edit}
                alt="Edit"
                onClick={() => setEditPopup("Role")}
                className="cursor-pointer w-5 h-5"
              />
            )}
          </div>
        </div>
      )}
      {renderEditPopup()}
    </div>
  );
};

export default GeneralInfo;
