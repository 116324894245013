import { httpConstants } from "../constant/commonArray";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./userService";

const {
  METHOD_TYPE: { POST, GET },
  API_END_POINT: {
    CREATE_ENTITY,
    GENERATE_OTP,
    VERIFY_OTP,
    CHECK_USERNAME,
    USER_COUNT,
    USER_MENTIONS,
  },
} = httpConstants;

export default class UserEntityService {
  baseUrl = process.env.REACT_APP_USER_MICROSERVICE;
  createEntity = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${CREATE_ENTITY}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res);
        })
        .catch(reject);
    });
  };
  generateOtp = async (requestQuery) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl +
          `/${GENERATE_OTP}?email=${requestQuery?.email}&isPassword=${requestQuery?.isPassword}`
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  verificationOtp = async (requestQuery) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl +
          `/${VERIFY_OTP}?email=${requestQuery?.email}&otp=${requestQuery?.otp}`
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  checkUserNameExists = async (requestQuery) => {
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${CHECK_USERNAME}/${requestQuery}`,
        {},
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getUserCount = async (requestData, id) => {
    return new Promise((resolve, reject) => {
      httpService(
        POST,
        this.baseUrl + `/${USER_COUNT}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getUserMentions = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `/${USER_MENTIONS}?${query}`,
        requestData,
        getHeaders()
      )
        .then((res) => {
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
}
