import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../assets/Icons/close.svg";
import { eventConstants, toastType } from "../../constant/commonArray";
import utility from "../../utility";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { UserService } from "../../services";
import ShowToast from "../../common/showToast";

/**
 * Component for creating or editing departments.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setPopup - Function to control the visibility of the popup
 * @param {Function} props.getJobLists - Function to fetch the list of jobs
 * @param {Object} props.data - Data of the department for which the position is being created or edited
 * @param {string} props.keyName - Key name indicating whether it's for creating or editing a department
 * @returns {JSX.Element} - CreateDeptPopup component
 */

const CreateDeptPopup = ({
  setPopup,
  getJobLists,
  data,
  keyName = "create",
}) => {
  const dispatch = useDispatch();
  const [isDeptAvailable, setIsDeptAvailable] = useState(false);
  const [debounceValue, setDebounceValue] = useState("");
  const [creating, setCreating] = useState(false);
  const [state, setState] = useState({
    departmentName: data?.departmentName || "",
    language: data?.language || "English",
  });
  const [error, setError] = useState({});

  /**
   * Handles the creation of a new department.
   *
   * @async
   * @function
   * @returns {void}
   */

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 300);

  /**
   * Handles the editing of an existing department.
   *
   * @async
   * @function
   * @returns {void}
   */

  const checkDeptName = async (name) => {
    try {
      const requestData = {
        departmentName: name,
      };
      dispatch({ type: eventConstants.SHOW_LOADER });
      const [error, res] = await utility.parseResponse(
        new UserService().checkDept(requestData)
      );
      if (error || !res) {
        setIsDeptAvailable(false);
      } else {
        if (res?.status) {
          setIsDeptAvailable(false);
        } else {
          setIsDeptAvailable(true);
        }
      }
    } catch (error) {
      ShowToast({
        message: error?.message,
        type: toastType.ERROR,
      });
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  const handleDeptName = async (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 50) {
      setError((prev) => ({ ...prev, nameError: false }));
    } else {
      setError((prev) => ({ ...prev, nameError: true }));
    }
    setState((prev) => ({ ...prev, departmentName: e.target.value }));
    debouncedSearch(e.target.value);
  };

  const handleCreateDept = async () => {
    setCreating(true);

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().createDept(state)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });

        setPopup("");
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    }
  };

  const handleEditDept = async () => {
    setCreating(true);

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().editDept(data?.departmentId, state)
      );
      if (error || !response?.responseData) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        getJobLists();
        setPopup("");
      } else if (response?.responseCode === 400) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: "Something went wrong",
          type: toastType.ERROR,
        });
      }
      setCreating(false);
    } catch (e) {
      console.error(e);
      setCreating(false);
    }
  };

  useEffect(() => {
    if (!!debounceValue) checkDeptName(debounceValue);
    // eslint-disable-next-line
  }, [debounceValue]);

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 flex justify-center items-center fixed w-full h-full right-0 top-0">
      <div className="flex flex-col justify-between bg-white w-112.5 h-155 shadow-md rounded-20 p-7.5 ">
        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <p className="text-black-100 font-ManropeBold text-ft4">
              {keyName === "edit" ? "Edit" : "Create new"} department
            </p>
            <Close className="cursor-pointer" onClick={() => setPopup("")} />
          </div>

          {/* <div className="flex flex-col gap-2 mt-1">
            <p className="text-black-100 font-ManropeMedium text-ft3">
              Languages
            </p>
            <div className="flex gap-2.5">
              <img
                src={ArrowLeft}
                alt="left"
                className="cursor-pointer"
                onClick={() => scrollContainer("left")}
              />
              <div
                ref={containerRef}
                className="flex gap-2.5 overflow-x-scroll scrollbar-hide"
              >
                {Languages.map((value) => (
                  <div
                    key={value.name}
                    onClick={() =>
                      setState((prev) => ({ ...prev, language: value?.name }))
                    }
                    className={`flex items-center rounded-20 px-2 py-0.75 text-ft2 text-black-100 cursor-pointer ${
                      value.name?.toLowerCase() ===
                      state?.language?.toLowerCase()
                        ? "bg-orange-50 font-ManropeBold"
                        : "bg-grey-50 font-ManropeRegular"
                    }`}
                  >
                    {value.name}
                  </div>
                ))}
              </div>
              <img
                src={ArrowRight}
                alt="right"
                className="cursor-pointer"
                onClick={() => scrollContainer("right")}
              />
            </div>
          </div> */}
          <div className="flex flex-col mt-1">
            <p>Name of department</p>
            <input
              value={state?.departmentName}
              type="text"
              onChange={handleDeptName}
              className={`w-full h-16.25 px-6.25 rounded-full bg-grey-50 font-ManropeRegular text-ft3 text-black-100 focus:outline-none ${
                isDeptAvailable &&
                state?.departmentName !== data?.departmentName
                  ? "border-1 border-orange-100"
                  : ""
              }`}
            />
            {isDeptAvailable &&
              state?.departmentName !== data?.departmentName && (
                <p className="text-orange-100 font-ManropeRegular text-ft2">
                  This department already exists
                </p>
              )}
            {error?.nameError && !isDeptAvailable && (
              <span className=" text-red-50">
                Department name can't be more than 50
              </span>
            )}
          </div>
        </div>
        <button
          disabled={
            !state?.departmentName ||
            creating ||
            isDeptAvailable ||
            Object.values(error).some((value) => value !== false) ||
            (keyName === "edit" &&
              state?.departmentName === data?.departmentName &&
              data?.language?.toLowerCase() === state?.language?.toLowerCase())
          }
          className={`flex items-center justify-center rounded-full  h-16.25  font-ManropeBold text-ft3 ${
            !state?.departmentName ||
            creating ||
            isDeptAvailable ||
            Object.values(error).some((value) => value !== false) ||
            (keyName === "edit" &&
              state?.departmentName === data?.departmentName &&
              data?.language?.toLowerCase() === state?.language?.toLowerCase())
              ? "bg-grey-50 text-grey-250"
              : "cursor-pointer bg-orange-50 text-black-100 "
          }`}
          onClick={keyName === "edit" ? handleEditDept : handleCreateDept}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CreateDeptPopup;
