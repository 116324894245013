import React, { useRef } from "react";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";
import { ReactComponent as Audio } from "../../../assets/Icons/audio.svg";
import { ReactComponent as Browse } from "../../../assets/Icons/browse.svg";
import { ReactComponent as Link } from "../../../assets/Icons/Link.svg";
import ShowToast from "../../../common/showToast";
import { failureMessage, toastType } from "../../../constant/commonArray";
import { useDropzone } from "react-dropzone";

/**
 * AddMedia component for uploading audio, media files, or adding links.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setOpenMedia - Function to control the visibility of the media upload popup.
 * @param {Function} props.setFiles - Function to set the selected files.
 * @param {Array} props.files - Array of selected files.
 * @param {string} props.type - Type of media upload.
 * @param {Function} props.setImagePopup - Function to control the visibility of the image popup.
 * @param {Function} props.setSelectedFile - Function to set the selected file.
 * @param {Function} props.handleAddMedia - Function to handle adding media.
 * @returns {JSX.Element} AddMedia component.
 */

const AdddMedia = ({
  setOpenMedia,
  setFiles,
  files,
  type,
  setImagePopup,
  setSelectedFile,
  handleAddMedia,
}) => {
  const fileInputRefAudio = useRef(null);
  const fileInputRefMedia = useRef(null);

  const handleAudioDrop = (acceptedFiles, fileRejections) => {
    handleDrop(
      acceptedFiles,
      fileRejections,
      failureMessage.FILE_TYPE_ERROR_AUDIO
    );
  };

  const handleMediaDrop = (acceptedFiles, fileRejections) => {
    handleDrop(
      acceptedFiles,
      fileRejections,
      failureMessage.FILE_TYPE_ERROR_MEDIA
    );
  };

  const handleDrop = (acceptedFiles, fileRejections, fileTypeErrorMsg) => {
    const fileTypeError = fileRejections.find(
      (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
    );
    const fileSizeError = fileRejections.find(
      (fileRejection) => fileRejection.errors[0].code === "file-too-large"
    );

    if (fileTypeError) {
      ShowToast({
        message: fileTypeErrorMsg,
        type: toastType.ERROR,
      });
    } else if (fileSizeError) {
      ShowToast({
        message: failureMessage.FILE_MEDIA_SIZE_EXCEEDED,
        type: toastType.ERROR,
      });
    } else {
      const updatedFiles = [...files, ...acceptedFiles];
      if (
        updatedFiles[0]?.name?.includes(".mp3") ||
        updatedFiles[0]?.name?.includes(".mp4")
      ) {
        setOpenMedia("PREVIEW");
        setFiles(updatedFiles);
      } else if (updatedFiles[0]?.name?.includes(".gif")) {
        handleAddMedia(updatedFiles[0], updatedFiles[0]?.name);
        setOpenMedia(null);
      } else {
        setSelectedFile(updatedFiles[0]);
        setImagePopup({
          popup: true,
        });
        setOpenMedia(null);
      }
    }
  };

  const { getInputProps: getAudioInputProps } = useDropzone({
    accept: { "audio/mp3": [".mp3"] },
    maxSize: 50 * 1024 * 1024,
    disablePreview: false,
    disabled: false,
    onDrop: handleAudioDrop,
  });

  const { getInputProps: getMediaInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "video/mp4": [".mp4"],
      "image/gif": [".gif"],
    },
    maxSize: 50 * 1024 * 1024,
    disablePreview: false,
    disabled: false,
    onDrop: handleMediaDrop,
  });

  const handleSpanClickAudio = () => {
    fileInputRefAudio.current.click();
  };

  const handleSpanClickMedia = () => {
    fileInputRefMedia.current.click();
  };

  return (
    <div className="bg-grey-100 bg-opacity-70 z-50  fixed flex justify-center right-0 top-0 h-full w-full">
      <div className="bg-white  relative flex flex-col h-112.5 w-115 z-50  top-30 rounded-20 ">
        <div className="flex w-full justify-between mb-5 px-7.5 pt-7.5">
          <h1 className="font-ManropeBold text-black-100 text-ft4">
            Add Media
          </h1>
          <Close
            onClick={() => {
              setOpenMedia(null);
              setFiles([]);
            }}
            className="cursor-pointer"
          />
        </div>
        <hr className="text-grey-50" />
        <div>
          {type !== "survey" && (
            <>
              <div
                onClick={handleSpanClickAudio}
                className="flex gap-5 mb-5 px-7.5 pt-5 cursor-pointer"
              >
                <Audio className="w-6 h-6" />
                <span className="text-ft3 font-ManropeRegular text-black-300">
                  Audio file
                </span>
                <input
                  {...getAudioInputProps()}
                  ref={fileInputRefAudio}
                  type="file"
                  className="hidden"
                  accept=".mp3"
                />
              </div>
              <hr className="text-grey-50" />
            </>
          )}
          {type !== "survey" && (
            <>
              {" "}
              <div
                onClick={() => setOpenMedia("LINK")}
                className="flex gap-5 mb-5 px-7.5 pt-5 cursor-pointer"
              >
                <Link className="w-6 h-6" />
                <span className="text-ft3 font-ManropeRegular text-black-300">
                  Link
                </span>
              </div>
              <hr className="text-grey-50" />
            </>
          )}
          <div
            onClick={handleSpanClickMedia}
            className="flex gap-5 mb-5 px-7.5 pt-5 cursor-pointer"
          >
            <Browse className="w-6 h-6" />
            <span className="text-ft3 font-ManropeRegular text-black-300">
              Browse
            </span>
            <input
              {...getMediaInputProps()}
              ref={fileInputRefMedia}
              type="file"
              className="hidden"
              accept=".mp4, .png, .jpg, .jpeg, .gif"
            />
          </div>
          <hr className="text-grey-50" />
        </div>
        <div className="h-full flex w-full px-7.5 pb-7.5 justify-end items-end">
          <button
            disabled={false}
            className={`flex items-center justify-center rounded-full  w-full h-16.25  font-ManropeBold text-ft2 ${
              false
                ? "bg-grey-50 text-grey-250"
                : "cursor-pointer bg-orange-50 text-black-100"
            }`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdddMedia;
