import React from "react";
import utility from "../../utility";

/**
 * React component for displaying user violations and information.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object[]} props.details - Information details to be displayed.
 * @param {string} props.violations - Current state indicating violations.
 * @param {Object[]} props.violationDetails - Details of each violation type.
 * @param {Function} props.setViolations - Function to set the violations state.
 * @param {string} props.info - Current state indicating information.
 * @param {Function} props.setInfo - Function to set the information state.
 * @returns {JSX.Element} - ViolationsInfo component.
 */

const ViolationsInfo = ({
  details,
  violations,
  violationDetails,
  setViolations = () => {},
  info,
  setInfo = () => {},
  disabledDetails,
  isDisabled,
}) => {
  const totalViolationCount = violationDetails?.reduce(
    (total, value) => total + value.count,
    0
  );

  const getKYCVerificationStatusColor = (isKYCVerified) => {
    switch (isKYCVerified) {
      case "NOT_STARTED":
        return "bg-yellow-50";
      case "DECLINED":
        return "bg-red-75";
      default:
        return "bg-green-50";
    }
  };

  const getSuspensionText = (details) => {
    if (details?.suspension) {
      const suspendedOnDate = details?.suspension?.period?.startDate
        ? new Date(details.suspension.period.startDate)
        : null;

      const suspendedTillDate = details?.suspension?.period?.endDate
        ? new Date(details.suspension.period.endDate)
        : null;
      const startDate = utility.formatDate(suspendedOnDate);
      const endDate = utility.formatDate(suspendedTillDate);

      if (details?.suspension?.period?.endDate !== 0) {
        return `Suspended ${startDate} - ${endDate}`;
      }
      return `Suspended ${startDate}`;
    }
    return "-";
  };

  const getRoleColor = (details) => {
    if (details?.role === "USER") {
      return details?.isBlueTickVerified === "COMPLETED" ? "Blue" : "-";
    } else {
      switch (details?.entityType) {
        case "BUSINESS":
          return "Gray";
        case "GOVERNMENT":
          return "Green";
        default:
          return "Blue";
      }
    }
  };

  return (
    <div className="flex flex-col rounded-20 bg-white p-5 gap-5">
      <div className="flex gap-2 xl:gap-8.75">
        <div
          className="flex gap-1 cursor-pointer"
          onClick={() => {
            setViolations("violations");
            setInfo("");
          }}
        >
          <p
            className={`${
              violations
                ? "text-ft4 xl:text-ft5 text-black-100 font-ManropeBold"
                : "text-black-400 font-ManropeRegular text-ft2 xl:text-ft3"
            }`}
          >
            Violations
          </p>
          <p
            className={`${
              violations
                ? "text-ft2 text-black-100 font-ManropeBold"
                : "text-black-400 font-ManropeRegular text-ft2"
            }`}
          >
            {totalViolationCount || 0}
          </p>
        </div>
        <p
          className={`cursor-pointer ${
            info
              ? "text-ft4 xl:text-ft5 text-black-100 font-ManropeBold"
              : "text-black-400 font-ManropeRegular text-ft2 xl:text-ft3"
          }`}
          onClick={() => {
            setInfo("info");
            setViolations("");
          }}
        >
          Info
        </p>
      </div>
      {violations === "violations" && (
        <div>
          {disabledDetails && isDisabled && (
            <div className="bg-red-75 rounded-xl p-5 w-full mb-5">
              <div className="flex flex-col gap-1 text-black-50 font-ManropeMedium text-ft1">
                <span>
                  {" "}
                  <b>
                    {disabledDetails?.disablerName ||
                      disabledDetails?.suspenderName ||
                      "-"}{" "}
                  </b>
                </span>
                <span className="break-words">
                  {disabledDetails?.reason || "-"}
                </span>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-2.5">
            {violationDetails?.map((value) => (
              <div key={value?._id} className="flex justify-between gap-1">
                <p className="text-black-400 font-ManropeRegular text-ft2">
                  {value._id}
                </p>
                <p className="text-black-100 font-ManropeBold text-ft2">
                  {value.count}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {info === "info" && (
        <div className="flex flex-col items-start w-full gap-5 rounded-20 bg-white">
          <div className="flex w-full gap-2 justify-between">
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Status
              </p>
              <div
                className={`flex items-center justify-center rounded-20 w-min px-2 py-1.25 ${
                  details?.status === "ACTIVE" ? "bg-green-50" : "bg-red-75"
                }`}
              >
                <p
                  className={`font-ManropeRegular text-ft2 text-black-100 ${
                    details?.status === "SUSPENDED"
                      ? "w-15 xl:w-auto truncate"
                      : "w-auto"
                  } `}
                >
                  {utility.capitalizeFirstLetter(details?.status)}
                </p>
              </div>
            </div>

            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">KYC</p>
              <div
                className={`flex items-center  justify-center px-2 py-1.5 rounded-20 w-max 
                  ${getKYCVerificationStatusColor(details.isKYCVerified)}
                 `}
              >
                <p className="font-ManropeRegular text-ft2 text-black-100">
                  {details.isKYCVerified === "NOT_STARTED"
                    ? "Not verified"
                    : details.isKYCVerified}
                </p>
              </div>
            </div>
          </div>
          {details?.status === "SUSPENDED" && (
            <div className="bg-red-75 rounded-xl p-5 w-full">
              <div className="flex flex-col gap-1 text-black-50 font-ManropeMedium text-ft1">
                <span>{getSuspensionText(details)}</span>
                <div className="flex gap-1.25 flex-wrap">
                  {" "}
                  Suspended by:
                  <b>
                    <div className="break-all">
                      {" "}
                      {details?.suspension?.suspenderName || "-"}{" "}
                    </div>
                  </b>
                </div>
                <span className="break-all">
                  {details?.suspension?.reason || "-"}
                </span>
              </div>
            </div>
          )}
          <div className="flex w-full gap-2 justify-between">
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Violations
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {details?.approvedViolations || 0} approved
              </p>
            </div>
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Badge
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {getRoleColor(details)}
              </p>
            </div>
          </div>
          <div className="flex w-full gap-2 justify-between">
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Followers
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {details?.followers || 0}
              </p>
            </div>
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Following
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {details?.following || 0}
              </p>
            </div>
          </div>
          <div className="flex w-full gap-2 justify-between">
            <div className="flex flex-col w-full">
              <p className="font-ManropeRegular text-ft2 text-grey-250">
                Circles
              </p>
              <p className="font-ManropeBold text-ft2 text-black-100">
                {details?.circles || 0}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViolationsInfo;
