/**
 * React component for displaying entity details.
 * @module EntityDetails
 */

import React, { useEffect, useRef, useState } from "react";
import searchIcon from "../../../assets/Icons/Search.svg";
import vector from "../../../assets/Icons/vector.svg";
import VectorOrange from "../../../assets/Icons/VectorOrange.svg";
import left from "../../../assets/Icons/Left.svg";
import Error from "../../../assets/Icons/Error.svg";
import table from "../../../assets/Icons/table.svg";
import tableOrange from "../../../assets/Icons/tableOrange.svg";
import more from "../../../assets/Icons/More.svg";
import Table from "../../../common/table";
import { toastType } from "../../../constant/commonArray";
import copy from "../../../assets/Icons/copy.svg";
import moment from "moment";
import pause from "../../../assets/Icons/pause.svg";
import placeholder from "../../../assets/Icons/Placeholder.svg";
import DetailsProfile from "../../../common/detailsProfile";
import DetailsBalance from "../../../common/detailsBalance";
import Close from "../../../assets/Icons/close.svg";
import DetailsGeneralInfo from "./detailsGeneralInfo";
import AddEmployee from "../../../common/addEmployee";
import Post from "../../DashboardPage/details/Post";
import TableFooter from "../../../common/tableFooter";
import { UserService } from "../../../services";
import utility from "../../../utility";
import {
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import ShowToast from "../../../common/showToast";
import { CircularProgress } from "@mui/material";
import tick from "../../../assets/Icons/Tick.svg";
import time from "../../../assets/Icons/Time.svg";
import { useDebouncedCallback } from "use-debounce";
import WarningPopup from "../../../common/warningPopup";
import SuspendedUserPopUp from "../../DashboardPage/details/SuspendedUserPopUp";
import DisablePopUp from "../../../common/disablePopUp";
import checkMark from "../../../assets/Icons/checkMark.svg";
import OutsideClickRef from "../../../common/outsideClickRef";
import CommentList from "./commentList";
import EmployeeList from "./employeeList";
import EntityUserInfo from "./entityUserInfo";
import { useSelector } from "react-redux";
import PlaceholderWnm from "../../../assets/Icons/PlaceholderWnm.png";
import WalletService from "../../../services/walletService";
import BlockChainService from "../../../services/blockChainService";

/**
 * EntityDetails component.
 * @returns {JSX.Element} JSX representation of the EntityDetails component.
 *  @example
 *  import { EntityDetails } from "./entityDetails.js";
 *   // ...
 * <EntityDetails />
 */
const EntityDetails = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [showComment, setShowComment] = useState(
    queryParams.get("isComment") === "true" || false
  );
  const showPoll = queryParams.get("showPoll");
  const haveAudit = queryParams.get("from");
  const tabType = queryParams.get("tab");
  const history = useHistory();
  const [showPost, setShowPost] = useState(showPoll || false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserExpanded, setUserExpanded] = useState(false);
  const [isGeneralExpanded, setGeneralExpanded] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLoadingPoll, setLoadingPoll] = useState(false);
  const [addEmployee, setAddEmployee] = useState(false);
  const [isInfo, setIsInfo] = useState("info");
  const [addEmployeePopup, setAddEployeePopup] = useState(false);
  const [debounceValueComment, setDebounceValueComment] = useState("");
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [entityDetails, setEntityDetails] = useState([]);
  const [currentPageComment, setCurrentPageComment] = useState(0);
  const [isDisablePopUp, setDisablePopUp] = useState(false);
  const [isDisableType, setDisableType] = useState(null);
  const [debounceValuePoll, setDebounceValuePoll] = useState("");
  const [pollDetails, setPollDetails] = useState([]);
  const [valueStaff, setValueStaff] = useState("Company staff");
  const [searchQuery, setSearchQuery] = useState("");
  const [valueRole, setValueRole] = useState(null);
  const [valuePosition, setValuePosition] = useState(null);
  const [valueDepartment, setValueDepartment] = useState(null);
  const [currentPagePoll, setCurrentPagePoll] = useState(0);
  const [totalDocsPoll, setTotalDocsPoll] = useState(0);
  const [sortPoll, setSortPoll] = useState(-1);
  const [limitPoll, setLimitPoll] = useState(25);
  const [popUpVisible, setPopUpVisible] = useState({
    popUp: false,
    keyName: "",
  });
  const [seenActivatePopUp, setSeenActivatePopUp] = useState(false);
  const totalPagesPoll = Math.ceil(totalDocsPoll / limitPoll);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const filters = queryParams.get("type");
    return filters ? JSON.parse(filters) : [];
  });
  const [selectedFilterStatus, setSelectedFiltersStatus] = useState(() => {
    const filters = queryParams.get("status");
    return filters ? JSON.parse(filters) : [];
  });
  const [clickFilterStatus, setClickFilterStatus] = useState(false);
  const [clickFilter, setClickFilter] = useState(false);
  const [suspendType, setSuspendType] = useState("");
  const [pollId, setPollId] = useState("");
  const [creatingEmployee, setCreatingEmployee] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [balance, setBalance] = useState(null);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const optionsRef = useRef(null);
  const headers = [
    { name: "Name" },
    { name: "Created", sort: true },
    { name: "Creator" },
    {
      name: "Status",
      typeFilter: true,
      typeFilterLength: selectedFilterStatus?.length,
    },
    { name: "Type", filter: true, filterLength: selectedFilters?.length },
    { name: "Amount" },
  ];

  /**
   * Handle page change for the poll table.
   * @param {number} pageNumber - The new page number.
   */
  const handlePageChangePoll = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPagesPoll) {
      setCurrentPagePoll(pageNumber);
    }
  };

  /**
   * Toggles the visibility of the pop-up and updates the key name.
   *
   * @param {type} value - the value to update the key name
   * @return {type} undefined
   */
  const togglePopUpVisble = (value) => {
    setPopUpVisible((prev) => ({
      ...prev,
      popUp: !popUpVisible.popUp,
      keyName: value,
    }));
    setUserExpanded(false);
  };

  /**
   * Asynchronously fetches entity details data and sets state accordingly.
   */
  const getEntityDetailsData = async () => {
    setIsLoading(true);
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().getEntityDetails(id)
      );
      if (err || !res) return;
      setEntityDetails(res);
      setIsLoading(false);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  const toggleSelectedFilter = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };
  const toggleSelectedFilterStatus = (filter) => {
    setSelectedFiltersStatus((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const renderFilterStatus = () => {
    return (
      clickFilterStatus && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilterStatus(false)}
        >
          <div
            className={`flex w-45 p-5 flex-col items-start gap-5 absolute top-5 right-1 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilterStatus.includes("ACTIVE")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilterStatus("ACTIVE")}
                checked={selectedFilterStatus.includes("ACTIVE")}
              />
              {selectedFilterStatus.includes("ACTIVE") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilterStatus("ACTIVE");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Active
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilterStatus.includes("SCHEDULED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilterStatus("SCHEDULED")}
                checked={selectedFilterStatus.includes("SCHEDULED")}
              />
              {selectedFilterStatus.includes("SCHEDULED") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilterStatus("SCHEDULED");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Scheduled
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilterStatus.includes("CLOSED")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilterStatus("CLOSED")}
                checked={selectedFilterStatus.includes("CLOSED")}
              />
              {selectedFilterStatus.includes("CLOSED") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilterStatus("CLOSED");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Closed
              </label>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };

  /**
   * Renders the filter component based on the selected filters.
   *
   * @return {JSX.Element} The rendered filter component
   */
  const renderFilter = () => {
    return (
      clickFilter && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setClickFilter(false)}
        >
          <div
            className={`flex w-45 p-5 flex-col items-start gap-5 absolute top-5 right-1 z-10 rounded-20 bg-white shadow-inner`}
          >
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("PUBLIC")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("PUBLIC")}
                checked={selectedFilters.includes("PUBLIC")}
              />
              {selectedFilters.includes("PUBLIC") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("PUBLIC");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100 font-normal">
                Public
              </label>
            </div>
            <div className="flex h-7.5 items-center gap-3.75 self-stretch">
              <input
                type="checkbox"
                className={`relative appearance-none w-6 h-6 rounded-lg cursor-pointer ${
                  selectedFilters.includes("PRIVATE")
                    ? "bg-orange-50 border-none"
                    : "bg-white border-2 border-grey-100"
                }`}
                onChange={() => toggleSelectedFilter("PRIVATE")}
                checked={selectedFilters.includes("PRIVATE")}
              />
              {selectedFilters.includes("PRIVATE") ? (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedFilter("PRIVATE");
                  }}
                  className="absolute cursor-pointer"
                  src={checkMark}
                  alt="checkMark"
                ></img>
              ) : (
                ""
              )}
              <label className="font-ManropeLight text-ft3 text-black-100">
                Private
              </label>
            </div>
          </div>
        </OutsideClickRef>
      )
    );
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
    setCurrentPagePoll(0);
    setCurrentPageComment(0);
  };

  /**
   * Asynchronously handles the activation of a user.
   *
   * @param {type} id - The ID of the user to be activated
   * @return {type} undefined
   */
  const handleActivateUser = async (id) => {
    let response;
    setUpdatingUser(true);
    try {
      response = await new UserService().userActivateUser(id);
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
      getEntityDetailsData();
      setSeenActivatePopUp(false);
      setUpdatingUser(false);
    } catch (e) {
      ShowToast({ message: "Something went wrong", type: toastType.ERROR });
      console.error(e);
      setUpdatingUser(false);
    } finally {
      setUpdatingUser(false);
    }
  };

  /**
   * Function to fetch poll details asynchronously.
   *
   * @return {Promise<void>}
   */
  const getPollDetails = async () => {
    setLoadingPoll(true);
    try {
      const requestParams = {
        searchKey: debounceValuePoll,
        sort: sortPoll,
        limit: limitPoll,
        skip: currentPagePoll,
      };
      if (selectedFilters?.length > 0) {
        requestParams.type = selectedFilters;
      }
      if (selectedFilterStatus?.length > 0) {
        requestParams.status = selectedFilterStatus;
      }
      const [err, res] = await utility.parseResponse(
        new UserService().getPostDetails(id, requestParams)
      );
      if (err || !res) return;
      setPollDetails(res?.postsList);
      setTotalDocsPoll(res?.totalCount);
      setLoadingPoll(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingPoll(false);
    }
  };

  /**
   * Asynchronously handles the saving of employee data.
   *
   * @param {object} data - The employee data to be saved
   * @return {Promise<void>} A promise that resolves when the save operation is complete
   */
  const handleSave = async (data) => {
    setCreatingEmployee(true);
    const newEmployee = {
      entityId: id,
      name: data?.name,
      email: data?.email,
      countryCode: data?.countryCode,
      phone: data?.phone,
      staffType: valueStaff.toUpperCase().replace(/\s/g, "_"),
      role: valueRole.toUpperCase().replace(/\s/g, "_"),
      jobPosition: valuePosition,
    };
    try {
      const res = await new UserService().addEmployee([newEmployee]);

      if (res?.failedEmployees?.length > 0) {
        ShowToast({
          message: res?.failureMessage,
          type: toastType.ERROR,
        });
      } else {
        ShowToast({
          message: res?.successMessage,
          type: toastType.SUCCESS,
        });
      }
      setValueRole(null);
      setValuePosition(null);
      setValueDepartment(null);
      setAddEployeePopup(false);
      setCreatingEmployee(false);
      getEntityDetailsData();
    } catch (e) {
      console.error(e);
      setCreatingEmployee(false);
    } finally {
      setCreatingEmployee(false);
    }
  };

  /**
   * Renders the table body based on the details of the poll.
   *
   * @return {JSX.Element} The table body JSX element
   */
  const renderTableBody = () => {
    if (pollDetails.length > 0) {
      return pollDetails.map((userData) => (
        <tr key={userData?._id}>
          <td
            className={`text-black-100 font-ManropeMedium text-ft2 py-4  px-5`}
          >
            <div className="w-40 truncate">{userData?.post?.name || "-"}</div>
          </td>
          <td className={`text-black-100 font-ManropeMedium text-ft2`}>
            <div className="w-32.5 truncate ">
              {moment(userData.addedOn).format("DD MMM[,] YYYY")}
            </div>
          </td>
          <td>
            {" "}
            <div className="w-32.5 truncate ">
              {userData?.creator?.fullName || "-"}
            </div>
          </td>
          <td>
            <div className="flex flex-col w-30 truncate ">
              <span className="text-black-100 font-ManropeMedium text-ft2">
                {utility.getStatus(
                  userData?.activePeriod?.startDate,
                  userData?.activePeriod?.endDate
                )}
              </span>
              <span className="text-ft0 font-ManropeRegular text-grey-400">
                {moment(userData?.activePeriod?.startDate).format("D")}-
                {moment(userData?.activePeriod?.endDate).format("D MMM YYYY")}
              </span>
            </div>
          </td>
          <td className={`text-black-50 font-ManropeMedium text-ft1`}>
            {" "}
            <div className="w-32.5 truncate text-black-50 font-ManropeMedium text-ft1">
              {utility.capitalizeFirstLetter(userData?.post?.type)}{" "}
              {utility.capitalizeFirstLetter(userData?.type)}
            </div>
          </td>
          <td>
            {" "}
            <div className="w-20 truncate ">{userData?.amount || "-"}</div>
          </td>
          <td>
            <div className="relative">
              <div className="w-6.25">
                <img
                  src={more}
                  alt="More"
                  className="cursor-pointer"
                  onClick={() =>
                    setPollId(pollId === userData?._id ? null : userData?._id)
                  }
                />
              </div>

              {pollId === userData?._id && (
                <OutsideClickRef
                  state={optionsRef}
                  setState={() => setPollId(null)}
                >
                  <div className="absolute right-0 flex flex-col items-start w-75 rounded-20 shadow-inner z-10 bg-white">
                    <button
                      onClick={() => copyToClipboard(userData?._id)}
                      className="flex h-16.25 px-5 items-center gap-3.75 self-stretch rounded-t-20 hover:bg-grey-350"
                    >
                      <img src={copy} alt="copy" />
                      <p className="text-ft3 font-ManropeLight text-black-100">
                        Copy Link
                      </p>
                    </button>
                    <hr className="text-grey-50 w-full" />
                    {userPermissions?.indexOf("business:write:poll") > -1 && (
                      <div
                        onClick={() => {
                          setDisablePopUp(true);
                          setSelectedUser(userData?._id);
                          setDisableType("poll");
                        }}
                        className="flex h-16.25 px-5 items-center gap-3.75 self-stretch rounded-b-20 hover:bg-grey-350"
                      >
                        <img src={Error} alt="error" />
                        <button className="text-ft3 font-ManropeLight text-black-100">
                          Disable Poll
                        </button>
                      </div>
                    )}
                  </div>
                </OutsideClickRef>
              )}
            </div>
          </td>
        </tr>
      ));
    } else if (!isLoading) {
      return (
        <tr className="h-57vh">
          <td colSpan={6}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery ||
              selectedFilters?.length > 0 ||
              selectedFilterStatus.length > 0 ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>{" "}
          </td>
        </tr>
      );
    }
  };

  /**
   * Render profile dots based on status and user expansion.
   *
   * @param {string} status - The status of the profile
   * @return {JSX.Element} The rendered profile dots
   */
  const renderProfileDots = (status) => {
    return status === "ACTIVE" && isUserExpanded ? (
      <OutsideClickRef
        state={optionsRef}
        setState={() => setUserExpanded(false)}
      >
        {userPermissions?.indexOf("business:write:suspend") > -1 && (
          <div
            onClick={() => togglePopUpVisble("suspend")}
            className="absolute right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-20 bg-white cursor-pointer"
          >
            <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
              <img src={pause} alt="Pause" />
              <p className="text-ft3 font-ManropeLight text-black-100">
                Suspend
              </p>
            </button>
          </div>
        )}
      </OutsideClickRef>
    ) : (
      isUserExpanded && status === "SUSPENDED" && (
        <OutsideClickRef
          state={optionsRef}
          setState={() => setUserExpanded(false)}
        >
          <div className="absolute flex flex-col right-2 left-2 top-23.75 w-full rounded-20 shadow-inner z-20 bg-white">
            {userPermissions?.indexOf("business:write:activate") > -1 && (
              <button
                onClick={() => setSeenActivatePopUp(!seenActivatePopUp)}
                className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
              >
                <img src={tick} alt="tick" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  Activate
                </p>
              </button>
            )}
            {userPermissions?.indexOf("business:write:suspend") > -1 && (
              <button
                className="flex h-16.25 px-5 items-center gap-3.75 shadow-custom"
                onClick={() => togglePopUpVisble("edit")}
              >
                <img src={time} alt="time" />
                <p className="text-ft3 font-ManropeRegular text-black-100">
                  Edit Suspended time
                </p>
              </button>
            )}
          </div>
        </OutsideClickRef>
      )
    );
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    if (value?.length < 3 && !!value) return;
    if (showComment) {
      setDebounceValueComment(value);
    } else {
      setDebounceValuePoll(value);
    }
  }, 500);

  function copyToClipboard(url) {
    navigator.clipboard.writeText(
      `${window.location.origin}/entity-account/poll/details/${url}?userId=${id}`
    );
    ShowToast({ message: "Link copied", type: toastType.SUCCESS });
  }

  const getAddress = async () => {
    try {
      const [err, res] = await utility.parseResponse(
        new WalletService().getAddress(id)
      );
      if (err || !res) return;

      const [error, response] = await utility.parseResponse(
        new BlockChainService().getBalance(res?.responseData?.publicKey)
      );
      if (error && !response) return;

      setBalance(response?.balance);
    } catch (e) {
      console.error(e);
    }
  };

  const renderContent = () => {
    if (showPost && !showComment) {
      return (
        <div className="w-full mb-5 mt-5 rounded-20 bg-white">
          {!isLoadingPoll ? (
            <div className="overflow-y-scroll flex items-center flex-col scrollbar-hide max-h-70vh">
              {pollDetails.length > 0 ? (
                pollDetails.map((poll) => (
                  <div key={poll._id}>
                    <Post
                      poll={poll}
                      id={id}
                      url={"/entity-account/poll/details"}
                      setDisablePopUp={setDisablePopUp}
                      setDisableType={setDisableType}
                      setSelectedUser={() =>
                        setPollId(pollId === poll._id ? null : poll._id)
                      }
                      postId={selectedUser}
                      setPostId={setSelectedUser}
                      selectedUser={pollId}
                      permission={"business:write:poll"}
                    />
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
                  <img src={placeholder} alt="placeholder" />
                </div>
              )}
            </div>
          ) : (
            <div className="flex justify-center items-center h-full w-full">
              <CircularProgress className="text-black-100 w-5 h-5" />
            </div>
          )}
          <TableFooter
            currentPage={currentPagePoll}
            handlePageChange={handlePageChangePoll}
            pageSize={limitPoll}
            setPageSize={setLimitPoll}
            totalPages={totalPagesPoll - 1}
            totalDocs={totalDocsPoll}
            setCurrentPage={setCurrentPagePoll}
          />
        </div>
      );
    } else if (showComment) {
      return (
        <CommentList
          debounceValueComment={debounceValueComment}
          isDisablePopUp={isDisablePopUp}
          isDisableType={isDisableType}
          selectedUser={selectedUser}
          setDisablePopUp={setDisablePopUp}
          setDisableType={setDisableType}
          setSelectedUser={setSelectedUser}
          currentPageComment={currentPageComment}
          setCurrentPageComment={setCurrentPageComment}
          getEntityDetailsData={getEntityDetailsData}
          entityDetails={entityDetails}
          searchQuery={searchQuery}
        />
      );
    } else {
      return (
        <Table
          headers={headers}
          tableBody={renderTableBody()}
          loading={isLoadingPoll}
          pageSize={limitPoll}
          setPageSize={setLimitPoll}
          setSort={setSortPoll}
          sort={sortPoll}
          currentPage={currentPagePoll}
          handlePageChange={handlePageChangePoll}
          totalPages={totalPagesPoll - 1}
          totalDocs={totalDocsPoll}
          setCurrentPage={setCurrentPagePoll}
          clickFilter={clickFilter}
          renderFilter={renderFilter()}
          setClickFilter={setClickFilter}
          clickFilterType={clickFilterStatus}
          renderFilterType={renderFilterStatus()}
          setClickFilterType={setClickFilterStatus}
        />
      );
    }
  };

  const setAllQuery = () => {
    if (!showComment && selectedFilters?.length > 0) {
      queryParams.set("type", JSON.stringify(selectedFilters));
    } else {
      queryParams.delete("type");
    }
    if (!showComment && selectedFilterStatus?.length > 0) {
      queryParams.set("status", JSON.stringify(selectedFilterStatus));
    } else {
      queryParams.delete("status");
    }
    queryParams.set("sort", sortPoll);
    history.replace({
      search: queryParams.toString(),
    });
  };

  const discardQueryComment = (type) => {
    queryParams.delete("status");
    queryParams.delete("type");
    queryParams.delete("sort");
    queryParams.set("isComment", String(type));
    history.replace({
      search: queryParams.toString(),
    });
    setCurrentPageComment(0);
    setSelectedFiltersStatus([]);
    setSelectedFilters([]);
  };
  const discardQueryPoll = (type) => {
    queryParams.set("sort", sortPoll);
    queryParams.set("isComment", String(type));
    history.replace({
      search: queryParams.toString(),
    });
    setCurrentPagePoll(0);
    setSelectedFiltersStatus([]);
    setSelectedFilters([]);
  };

  const handleShowPoll = (value) => {
    setShowPost(value);
    queryParams.set("showPoll", String(value));
    history.replace({
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    getAddress();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPollDetails();
    setAllQuery();
    // eslint-disable-next-line
  }, [
    id,
    debounceValuePoll,
    sortPoll,
    limitPoll,
    currentPagePoll,
    selectedFilters,
    selectedFilterStatus,
  ]);
  useEffect(() => {
    if (entityDetails) {
      const suspend = utility.getSuspensionType(
        entityDetails?.suspension?.type,
        entityDetails?.suspension?.period?.startDate,
        entityDetails?.suspension?.period?.endDate
      );
      setSuspendType(suspend);
    }
  }, [entityDetails]);

  useEffect(() => {
    getEntityDetailsData();
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div
      className={`w-full h-full flex gap-5 overflow-y-auto overflow-x-hidden pr-12.5 py-5`}
    >
      <div className="flex flex-col w-75per">
        <div className=" h-21.25 px-5 flex justify-between items-center rounded-20 bg-white">
          <div className="flex gap-7.5 items-center">
            <img
              src={left}
              alt="left"
              className="cursor-pointer"
              onClick={() => {
                if (haveAudit) {
                  history.push("/audit-log");
                } else {
                  const dynamicUrl = `/entity-account${
                    tabType ? `?tab=${tabType}` : ""
                  }`;
                  history.push(dynamicUrl);
                }
              }}
            />
            <div className="flex gap-10">
              <p
                className={`${
                  showComment
                    ? "font-ManropeMedium text-ft3 text-grey-250 cursor-pointer"
                    : "font-ManropeBold text-ft5 text-black-100"
                }`}
                onClick={() => {
                  setShowComment(false);
                  setSearchQuery("");
                  setDebounceValueComment("");
                  discardQueryPoll(false);
                }}
              >
                Polls
              </p>
              <p
                className={`${
                  showComment
                    ? "font-ManropeBold text-ft5 text-black-100"
                    : "font-ManropeMedium text-ft3 text-grey-250 cursor-pointer"
                }`}
                onClick={() => {
                  setShowComment(true);
                  setSearchQuery("");
                  setDebounceValuePoll("");
                  discardQueryComment(true);
                }}
              >
                Comments
              </p>
            </div>
          </div>
          <div className="flex gap-2 2xl:gap-5">
            {!showComment && (
              <div className="flex gap-2 2xl:gap-5 items-center">
                {showPost ? (
                  <img
                    src={table}
                    alt="table"
                    className="cursor-pointer"
                    onClick={() => handleShowPoll(false)}
                  />
                ) : (
                  <img src={tableOrange} alt="tableOrange" />
                )}
                {showPost ? (
                  <img
                    src={VectorOrange}
                    alt="vectorOrange"
                    className="w-5 h-5"
                  />
                ) : (
                  <img
                    src={vector}
                    alt="vector"
                    className="cursor-pointer w-5 h-5"
                    onClick={() => handleShowPoll(true)}
                  />
                )}
              </div>
            )}
            <div className="flex w-83.75 h-11.25 p-5 items-center gap-3.75 flex-shrink-0 rounded-full bg-grey-50">
              <img
                src={searchIcon}
                alt="searchIcon"
                className="opacity-60 w-5 h-5"
              />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => handleSearch(e)}
                className="font-ManropeLight text-ft3 w-80per bg-grey-50 text-black-300 focus:outline-none"
              />
              {!!searchQuery && (
                <img
                  onClick={() => {
                    setSearchQuery("");
                    debouncedSearch("");
                  }}
                  src={Close}
                  alt=""
                  className="flex justify-end cursor-pointer w-5 h-5"
                />
              )}
            </div>
          </div>
        </div>

        {renderContent()}
      </div>

      <div className="flex flex-col gap-5 relative w-25per">
        <DetailsProfile
          name={entityDetails?.fullName}
          username={entityDetails?.userName}
          setUserExpanded={setUserExpanded}
          isUserExpanded={isUserExpanded}
          renderPopUp={renderProfileDots(entityDetails?.status)}
          profile={entityDetails?.profilePhoto}
          profileType={entityDetails?.type}
          keyName="entity"
          permissionActive={"business:write:activate"}
          permissionSuspend={"business:write:suspend"}
          status={entityDetails?.status}
        />
        <EntityUserInfo
          setIsInfo={setIsInfo}
          info={isInfo}
          setAddEmployee={setAddEmployee}
          addEmployee={addEmployee}
          addEmployeePopup={addEmployeePopup}
          setAddEployeePopup={setAddEployeePopup}
          getEntityDetailsData={getEntityDetailsData}
          entityDetails={entityDetails}
          permission={"business:write:employee:add"}
        />
        {isInfo === "info" && (
          <DetailsBalance
            price={balance || 0}
            fontSize={"text-ft6"}
            rewardAmount={entityDetails?.rewardAmount || 0}
          />
        )}

        {isInfo === "info" ? (
          <div>
            <DetailsGeneralInfo
              isDropDownOpen={isDropDownOpen}
              setIsDropDownOpen={setIsDropDownOpen}
              setGeneralExpanded={setGeneralExpanded}
              isGeneralExpanded={isGeneralExpanded}
              details={entityDetails}
              getEntityDetailsData={getEntityDetailsData}
            />
          </div>
        ) : (
          <EmployeeList
            id={id}
            permissionDelete={"business:write:employee:remove"}
            permissionEdit={"business:write:employee:edit"}
          />
        )}

        {addEmployeePopup && (
          <AddEmployee
            setAddEployeePopup={setAddEployeePopup}
            setValueRole={setValueRole}
            setValueStaff={setValueStaff}
            setValuePosition={setValuePosition}
            setValueDepartment={setValueDepartment}
            valueRole={valueRole}
            valueStaff={valueStaff}
            valueDepartment={valueDepartment}
            valuePosition={valuePosition}
            handleSave={handleSave}
            loadingState={creatingEmployee}
          />
        )}
      </div>

      {popUpVisible.popUp && popUpVisible.keyName === "suspend" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={entityDetails?.userName}
          id={entityDetails?._id}
          getdetails={getEntityDetailsData}
        />
      )}
      {popUpVisible.popUp && popUpVisible.keyName === "edit" && (
        <SuspendedUserPopUp
          togglePopUpVisble={() => setPopUpVisible(false)}
          username={entityDetails?.userName}
          id={entityDetails?._id}
          keyName={popUpVisible.keyName}
          type={suspendType}
          getdetails={getEntityDetailsData}
          endDate={entityDetails?.suspension?.period?.endDate}
          reasonType={entityDetails?.suspension?.reason}
          name={entityDetails?.fullName}
          setActivatePopUp={setSeenActivatePopUp}
        />
      )}
      {seenActivatePopUp && (
        <WarningPopup
          handleActivateUser={() => handleActivateUser(entityDetails?._id)}
          title={`Activate ${entityDetails?.userName} ?`}
          description={
            "User will be activated. All created content will be available for other users"
          }
          setToggle={() => setSeenActivatePopUp(false)}
          text={`${entityDetails?.fullName} activated`}
          loading={updatingUser}
        />
      )}

      {isDisablePopUp && isDisableType === "poll" && (
        <DisablePopUp
          setToggle={setDisablePopUp}
          keyName={"post"}
          selectedId={selectedUser}
          getDetails={getPollDetails}
          updateUser={getEntityDetailsData}
          suspendedState={entityDetails?.status}
          suspendedName={entityDetails?.fullName}
        />
      )}
    </div>
  );
};

export default EntityDetails;
