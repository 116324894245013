import React, { useEffect, useState } from "react";
import Table from "../../common/table";
import placeholder from "../../assets/Icons/Placeholder.svg";
import left from "../../assets/Icons/Left.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import BlockChainService from "../../services/blockChainService";
import utility from "../../utility";
import ShowToast from "../../common/showToast";
import { eventConstants, toastType } from "../../constant/commonArray";
import { useDispatch } from "react-redux";

/**
 * FailedViewTransactions component displays a table of failed blockchain transactions.
 *
 * @component
 * @returns {JSX.Element} JSX element representing the FailedViewTransactions component.
 */
const FailedViewTransactions = () => {
  const headers = [
    { name: " Tnx hash", endDate: true },
    { name: "Date and time", startDate: true },
    { name: "" },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [sortDate, setSortDate] = useState("");
  const [sortTxn, setSortTxn] = useState("");
  const dispatch = useDispatch();

  const totalPages = Math.ceil(totalDocs / pageSize);

  /**
   * Handles pagination change for the table.
   *
   * @param {number} pageNumber - The page number to navigate to.
   * @returns {void}
   */
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  /**
   * Fetches data for the table of failed transactions from the blockchain service.
   *
   * @async
   * @returns {void}
   */
  const getData = async () => {
    setLoading(true);

    const requestData = {
      limit: pageSize,
      skip: currentPage * pageSize,
      sort: -1,
      type: "FAILED",
    };

    if (sortDate || sortTxn) {
      requestData.sortingKey = sortDate || sortTxn;
    }

    try {
      const [error, response] = await utility.parseResponse(
        new BlockChainService().getTokenList(requestData)
      );

      if (error || !response) return;
      setData(response?.tokens);
      setTotalDocs(response.totalCount);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  /**
   * Handles retrying calculation of 'R' for a failed transaction.
   *
   * @async
   * @param {string} transactionHash - The transaction hash of the failed transaction.
   * @param {any} data - Additional data related to the transaction.
   * @returns {void}
   */
  const handleRetryCalculateR = async (transactionHash, data) => {
    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      const requestData = {
        transactionHash: transactionHash,
        data: data,
      };
      const [error, response] = await utility.parseResponse(
        new BlockChainService().calulateR(requestData)
      );

      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        getData();
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  /**
   * Handles retrying minting of tokens for a failed transaction.
   *
   * @async
   * @param {string} transactionHash - The transaction hash of the failed transaction.
   * @param {any} data - Additional data related to the transaction.
   * @returns {void}
   */
  const handleRetryMint = async (transactionHash, data) => {
    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      const requestData = {
        transactionHash: transactionHash,
        data: data,
      };
      const [error, response] = await utility.parseResponse(
        new BlockChainService().mintTransfer(requestData)
      );

      if (error || !response) return;

      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        getData();
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  /**
   * Handles retrying token transfer for a failed transaction.
   *
   * @param {string} transactionHash - The transaction hash of the failed transaction.
   * @param {any} data - Additional data related to the transaction.
   * @returns {void}
   */
  const handleRetryTokenTransfer = async (transactionHash, data) => {
    try {
      dispatch({ type: eventConstants.SHOW_LOADER });
      const requestData = {
        transactionHash: transactionHash,
        data: data,
        retry: true,
      };
      const [error, response] = await utility.parseResponse(
        new BlockChainService().TokenTransfer(requestData)
      );

      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.message,
          type: toastType.SUCCESS,
        });
        getData();
      } else {
        ShowToast({
          message: response?.message,
          type: toastType.ERROR,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: eventConstants.HIDE_LOADER });
    }
  };

  /**
   * Determines the retry button text based on the given item.
   *
   * @param {Object} item - The item to determine the retry button text for.
   * @return {string} The text for the retry button based on the item.
   */
  const getRetryButtonText = (item) => {
    if (item?.rCalculationFailed) {
      return "Retry R Calculation";
    }
    if (item?.mintToken) {
      return "Retry Transfer";
    }
    return "Retry Mint Transfer";
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [currentPage, sortDate, sortTxn]);

  const renderBtn = (item) => {
    return (
      <button
        onClick={() => {
          if (item.rCalculationFailed) {
            handleRetryCalculateR(item?.txnHash, item?.data);
          } else if (item?.mintToken) {
            handleRetryTokenTransfer(item?.txnHash, item?.data);
          } else {
            handleRetryMint(item?.txnHash, item?.data);
          }
        }}
        className="text-orange-50 font-ManropeMedium text-ft2"
      >
        {getRetryButtonText(item)}
      </button>
    );
  };

  const renderTableBody = () => {
    if (data.length > 0) {
      return data.map((item) => (
        <tr
          key={item._id}
          className="hover:bg-grey-350 relative cursor-pointer"
        >
          <td className="py-5 px-5 w-35per font-ManropeMedium text-ft2 text-black-50">
            <div className="w-85 truncate">{item?.txnHash || "-"}</div>
          </td>
          <td className="font-ManropeMedium py-5 text-ft2 text-black-50">
            <div className="w-50 truncate">
              {moment(item.addedOn).format("hh:mm A DD MMM[,] YYYY")}
            </div>
          </td>
          <td className="text-black-50">
            <div className="w-40 truncate">{renderBtn(item)}</div>
          </td>
        </tr>
      ));
    } else if (!loading) {
      return (
        <tr>
          <td colSpan={3}>
            <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
              <img src={placeholder} alt="placeholder" className="mt-10per" />
            </div>{" "}
          </td>
        </tr>
      );
    }
  };
  return (
    <div className="flex py-5 w-full h-full overflow-y-auto pr-12.5 gap-5">
      <div className="w-full">
        <div className="bg-white p-5 rounded-20 h-21.25 gap-5 flex items-center">
          <Link to="/token">
            <img src={left} alt="left" className="cursor-pointer" />
          </Link>
          <p className="text-black-100 font-ManropeBold text-ft5">
            Failed Transactions
          </p>
        </div>
        <Table
          headers={headers}
          tableBody={renderTableBody()}
          setPageSize={setPageSize}
          pageSize={pageSize}
          loading={loading}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages - 1}
          totalDocs={totalDocs}
          setCurrentPage={setCurrentPage}
          setStartDate={setSortDate}
          tagStart={"addedOn"}
          startDate={sortDate}
          endDate={sortTxn}
          setEndDate={setSortTxn}
          tagEnd={"transactionHash"}
        />
      </div>
    </div>
  );
};

export default FailedViewTransactions;
