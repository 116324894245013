import React, { useEffect, useRef, useState } from "react";
import OutsideClickRef from "../../../../../../common/outsideClickRef";
import UpArrow from "../../../../../../assets/Icons/up-arrow.svg";
import DownArrow from "../../../../../../assets/Icons/down-arrow.svg";
import { textData } from "../../../../../../constant/commonArray";
import TypeImage from "./typeImage";
import TypeText from "./typeText";

/**
 * RankingPoll component for managing ranking poll options.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setState - Function to set component state.
 * @param {Object} props.state - Component state object.
 * @param {number} props.idx - Index of the poll category.
 * @param {Array} props.errorsRanking - Array of errors for ranking options.
 * @param {Function} props.setErrorRanking - Function to set errors for ranking options.
 * @returns {JSX.Element} RankingPoll component JSX.
 */

const RankingPoll = ({
  setState,
  state,
  idx,
  errorsRanking,
  setErrorRanking,
}) => {
  const optionsValue = [
    {
      name: "Text",
      data: textData,
      enum: "TEXT",
    },
    {
      name: "Images",
      data: [],
      enum: "IMAGE",
    },
  ];

  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const answersLength = state?.polls[idx]?.options?.answers?.length;
  useEffect(() => {
    if (answersLength && answersLength !== errorsRanking.length) {
      setErrorRanking([new Array(answersLength).fill("")]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mt-15 flex flex-col">
      <div className="flex flex-col gap-15">
        <div className="flex flex-col gap-1">
          <label
            htmlFor=""
            className="text-black-100 text-ft3 font-ManropeMedium"
          >
            Option Type
          </label>
          <div>
            <OutsideClickRef state={dropdownRef} setState={setIsOpen}>
              <div className="relative w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25">
                <div
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  className="w-full h-full flex justify-between items-center cursor-pointer"
                >
                  <span
                    className={`w-full h-full flex items-center font-ManropeRegular ${
                      !!state?.polls[idx]?.options?.name
                        ? "text-black-100"
                        : "text-black-75"
                    } text-ft3`}
                  >
                    {state?.polls[idx]?.options?.name || "Not choosen"}
                  </span>
                  {!isOpen ? (
                    <img src={DownArrow} alt="" className="w-6 h-6" />
                  ) : (
                    <img src={UpArrow} alt="" className="w-6 h-6" />
                  )}
                </div>

                {isOpen && (
                  <div className="absolute right-0 mt-2 w-full z-50 max-h-100 overflow-y-auto  shadow-dropdownShadow bg-white  rounded-20">
                    <div className="py-1 flex flex-col">
                      {optionsValue.map((option, index) => (
                        <span
                          key={index}
                          className={`w-full p-5 text-ft3 font-ManropeRegular hover:bg-grey-350 text-black-150 cursor-pointer  ${
                            idx === optionsValue.length - 1
                              ? ""
                              : "border-b border-grey-50"
                          }`}
                          onClick={() => {
                            setState((prevState) => {
                              const updatedPollCategory = [...prevState.polls];
                              updatedPollCategory[idx].options = {
                                type: option.enum,
                                answers: option.data,
                                name: option.name,
                              };
                              updatedPollCategory[idx].images = [];
                              return {
                                ...prevState,
                                polls: updatedPollCategory,
                              };
                            });
                            setErrorRanking((prevErrors) => {
                              const updatedErrors = [...prevErrors];
                              updatedErrors[idx] = [];
                              return updatedErrors;
                            });
                            setIsOpen(false);
                          }}
                        >
                          {option.name}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickRef>
          </div>
        </div>

        <div className="flex flex-col gap-1 pt-5">
          {state?.polls[idx]?.options?.type === "TEXT" ? (
            <TypeText
              setState={setState}
              state={state}
              idx={idx}
              errorsRanking={errorsRanking}
              setErrorRanking={setErrorRanking}
            />
          ) : (
            state?.polls[idx]?.options?.type === "IMAGE" && (
              <TypeImage state={state} setState={setState} idx={idx} />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default RankingPoll;
