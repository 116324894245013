import React, { useEffect, useRef, useState } from "react";
import more from "../../assets/Icons/More.svg";
import OutsideClickRef from "../../common/outsideClickRef";
import Edit from "../../assets/Icons/Edit.svg";
import { useSelector } from "react-redux";
import EditActivity from "./editActivity";
import { UserService } from "../../services";
import utility from "../../utility";
import { CircularProgress } from "@mui/material";

/**
 * ActivityList component displays a list of activities with options to edit each activity.
 *
 * @component
 * @returns {JSX.Element}
 */
const ActivityList = () => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const [editActivity, setEditActivity] = useState({
    popup: false,
    data: [],
  });
  const optionRef = useRef();

  /**
   * Fetches activity data from the server.
   *
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const getActivityData = async () => {
    setLoading(true);

    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getActivityList()
      );

      if (error || !response) return;
      setData(response);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getActivityData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-3">
      {data?.map((item) => (
        <div key={item?._id} className="px-5 py-10 bg-white flex  rounded-20">
          <span className="text-ft3 font-ManropeBold text-black-150  w-70per">
            {item?.activityName}
          </span>

          <div className="flex gap-5 w-10per justify-end">
            <span className="text-ft2 font-ManropeRegular text-grey-250">
              Min
            </span>
            <span className="font-ManropeBold text-ft2 text-black-150">
              {item?.min || "-"}
            </span>
          </div>
          <div className="flex gap-5 w-10per justify-end">
            <span className="text-ft2 font-ManropeRegular text-grey-250">
              Max
            </span>
            <span className="font-ManropeBold text-ft2 text-black-150">
              {item?.max || "-"}
            </span>
          </div>
          <div className="relative w-10per flex justify-end">
            {userPermissions?.indexOf("activity:write") > -1 && (
              <img
                onClick={() => setSelectedUserId(item?._id)}
                src={more}
                alt="More"
                className="cursor-pointer"
              />
            )}
            {selectedUserId === item?._id && (
              <OutsideClickRef
                state={optionRef}
                setState={() => setSelectedUserId(null)}
              >
                <div
                  onClick={() =>
                    setEditActivity((prev) => ({
                      ...prev,
                      popup: true,
                      data: item,
                    }))
                  }
                  className="flex absolute top-10 right-0 rounded-20 bg-white shadow-inner p-5 z-20 w-75 gap-3.75 cursor-pointer"
                >
                  <img src={Edit} alt="Edit" />
                  <p className="text-black-100 font-ManropeRegular text-ft3">
                    Edit
                  </p>
                </div>
              </OutsideClickRef>
            )}
          </div>
        </div>
      ))}
      {editActivity?.popup && (
        <EditActivity
          editActivity={editActivity}
          setEditActivity={setEditActivity}
          getActivityData={getActivityData}
        />
      )}
    </div>
  );
};

export default ActivityList;
