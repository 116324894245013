import React from "react";

/**
 * WarningPopup component displays a warning popup with options to confirm or cancel an action.
 *
 * @component
 * @example
 * // Example usage of WarningPopup component
 * <WarningPopup
 *   handleActivateUser={/* function to handle user activation * / () => {}}
 *   setToggle={/* function to toggle the popup visibility * / () => {}}
 *   title="Activate User"
 *   description="Are you sure you want to activate this user?"
 *   loading={/* loading state for asynchronous operations * / false}
 * />
 *
 * @param {Function} handleActivateUser - Function to handle the activation of the user.
 * @param {Function} [setToggle=() => {}] - Function to toggle the visibility of the popup.
 * @param {string} title - Title of the warning popup.
 * @param {string} description - Description or message displayed in the popup.
 * @param {boolean} [loading=false] - Loading state for asynchronous operations.
 *
 * @returns {JSX.Element} The rendered WarningPopup component.
 */
const WarningPopup = ({
  handleActivateUser,
  setToggle = () => {},
  title,
  description,
  loading = false,
}) => {
  return (
    <div className="bg-grey-100 bg-opacity-70 z-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center">
      <div className="flex flex-col justify-evenly bg-white rounded-xl shadow-md w-112.5 h-81.25 px-7.5">
        <div className="flex flex-col items-center justify-center gap-3.75">
          <p className="font-ManropeBold break-all text-ft4 text-black-100">
            {title}
          </p>
          <p className="px-5 font-ManropeRegular text-ft3 text-center text-black-400">
            {description}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-3.75">
          <button
            disabled={loading}
            className={`flex w-97.5 h-16.25 px-6.25 py-2.5 justify-center items-center rounded-full text-ft2 font-ManropeBold ${
              loading ? "bg-grey-50" : "bg-orange-50"
            }`}
            onClick={() => {
              handleActivateUser();
            }}
          >
            Yes
          </button>
          <button
            disabled={loading}
            className={`flex w-97.5 h-16.25 px-6.25 py-2.5 justify-center items-center rounded-full text-ft2 font-ManropeBold`}
            onClick={() => setToggle(false)}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarningPopup;
