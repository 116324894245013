import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import ImageLogo from "../../../../../assets/Icons/image-logo.svg";
import { ReactComponent as AddImage } from "../../../../../assets/Icons/plus-orange.svg";
import ShowToast from "../../../../../common/showToast";
import { failureMessage, toastType } from "../../../../../constant/commonArray";
import Cross from "../../../../../assets/Icons/close-white.svg";

/**
 * Component for handling image uploads and display for a poll.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.images - Array of images for the poll.
 * @param {Function} props.setImages - Function to update the images array.
 * @returns {JSX.Element} Rendered component.
 */
const TypeImage = ({ images, setImages }) => {
  const fileInputRef = useRef(null);
  const [idx, setIdx] = useState(null);

  const { getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 50 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED_50MB,
          type: toastType.ERROR,
        });
      } else {
        const updatedFiles = [...images];
        updatedFiles[idx] = acceptedFiles[0];
        setImages(updatedFiles);
      }
    },
  });
  /**
   * Handles the click event to delete an image at a specific index.
   *
   * @param {number} index - The index of the image to delete.
   */
  const handleSpanClick = (idx) => {
    fileInputRef.current.click();
    setIdx(idx);
  };

  /**
   * Handles the click event to delete an image at a specific index.
   *
   * @param {number} index - The index of the image to delete.
   */

  const handleDeleteClick = (index) => {
    const updatedFiles = [...images];
    updatedFiles.splice(index, 1);
    if (images.length === 1) {
      setImages([{ dummyImages: "" }, { dummyImages: "" }]);
    } else {
      setImages(updatedFiles);
    }
  };

  /**
   * Handles the click event to add more images.
   *
   * @return {void}
   */
  const handleMoreImage = () => {
    if (images.length <= 5) {
      const updatedFiles = [...images, { dummyImages: "" }];
      setImages(updatedFiles);
    } else {
      ShowToast({
        message: "You can add only 6 images",
        type: toastType.ERROR,
      });
    }
  };

  useEffect(() => {
    if (images.length === 0) {
      setImages([{ dummyImages: "" }, { dummyImages: "" }]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="flex flex-wrap gap-10">
        {images?.length > 0 ? (
          images?.map((image, index) => (
            <div key={index}>
              {image?.dummyImages !== "" ? (
                <div className="flex flex-col relative items-center justify-center w-40 h-40  rounded-20 cursor-pointer">
                  <img
                    src={
                      image instanceof File
                        ? URL.createObjectURL(image)
                        : image?.value
                    }
                    alt=""
                    className="rounded-20  w-40 h-40 object-fill"
                  />

                  <div
                    onClick={() => handleDeleteClick(index)}
                    className="w-6 h-6 cursor-pointer rounded-full bg-black-50 p-0.5 absolute top-2 right-2"
                  >
                    <img src={Cross} alt="check" />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col relative w-40 h-40 bg-grey-50 rounded-20 cursor-pointer">
                  <div
                    onClick={() => {
                      handleSpanClick(index);
                    }}
                    className="flex justify-center items-center flex-col gap-1 h-40"
                  >
                    <img src={ImageLogo} alt="" />
                    <span className="text-black-100 text-ft3 font-ManropeMedium">
                      Add image
                    </span>
                  </div>
                  <input
                    {...getInputProps()}
                    key={images.length}
                    ref={(el) => (fileInputRef.current = el)}
                    type="file"
                    className="hidden"
                    accept=".svg, .png, .jpg"
                    multiple
                  />
                  {images?.length > 2 && (
                    <div
                      onClick={() => handleDeleteClick(index)}
                      className="w-6 h-6 cursor-pointer rounded-full bg-black-50 p-0.5 absolute top-2 right-2"
                    >
                      <img src={Cross} alt="check" />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flex flex-wrap gap-10">
            <div
              onClick={() => handleSpanClick(0)}
              className="flex flex-col items-center justify-center w-40 h-40 bg-grey-50 rounded-20 cursor-pointer"
            >
              <img src={ImageLogo} alt="" />
              <span className="text-black-100 text-ft3 font-ManropeMedium">
                Add image
              </span>
              <input
                {...getInputProps()}
                key={images.length}
                ref={fileInputRef}
                type="file"
                className="hidden"
                accept=".svg, .png, .jpg"
                multiple
              />
            </div>
            <div
              onClick={() => handleSpanClick(1)}
              className="flex flex-col items-center justify-center w-40 h-40 bg-grey-50 rounded-20 cursor-pointer"
            >
              <img src={ImageLogo} alt="" />
              <span className="text-black-100 text-ft3 font-ManropeMedium">
                Add image
              </span>
              <input
                {...getInputProps()}
                key={images.length}
                ref={fileInputRef}
                type="file"
                className="hidden"
                accept=".svg, .png, .jpg"
                multiple
              />
            </div>
          </div>
        )}
      </div>
      <div
        onClick={handleMoreImage}
        className="flex gap-2.5 items-center cursor-pointer pt-5"
      >
        <label>
          <AddImage />
        </label>
        <span className="text-orange-50 font-ManropeBold text-ft2">
          Add more
        </span>
      </div>
    </div>
  );
};

export default TypeImage;
