import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import SideBar from "./common/sideBar";
import { keyTypeConstants } from "./constant/commonArray";
import { sessionManager } from "./managers/sessionManager";
const { AUTH0_ID_TOKEN, POLL_DETAILS } = keyTypeConstants;

const PrivateRoute = ({ component: Component, permissions, ...rest }) => {
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const pollId = queryParams.get("pollId");
  const userId = queryParams.get("userId");
  const isReview = queryParams.get("isReview");

  const pollData = {
    pollId,
    userId,
    isReview,
  };

  sessionManager.setDataInCookies(pollData, POLL_DETAILS, 1);

  const isAuthenticated = () => {
    const getToken = sessionManager.getDataFromCookies(AUTH0_ID_TOKEN);
    const tokenPresent = getToken ? true : false;
    return tokenPresent;
  };

  const hasPermission = () => {
    if (!permissions || !userPermissions) return false;
    return permissions?.some((permission) =>
      userPermissions?.includes(permission)
    );
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <div
            style={{
              display: "flex",
              height: "100vh",
              width: "100%",
              backgroundColor: "#F3F3F3",
            }}
          >
            <SideBar />
            {hasPermission() ? (
              <Component {...props} />
            ) : (
              <span>You doesn't have access</span>
            )}
          </div>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
