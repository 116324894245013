import React, { useState } from "react";
import Contacts from "../../assets/Icons/Contacts.svg";
import Chevrondown from "../../assets/Icons/Chevrondown.svg";
import Edit from "../../assets/Icons/Edit.svg";
import CountryList from "country-list-with-dial-code-and-flag";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
import EditPopup from "../../common/editPopup";
import utility from "../../utility";
import { UserService } from "../../services";
import ShowToast from "../../common/showToast";
import { successMessage, toastType } from "../../constant/commonArray";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

/**
 * Component for rendering contact details of a staff member.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.details - Contact details of the staff member
 * @param {Function} props.getDetails - Function to fetch contact details
 * @returns {JSX.Element} - Contact component
 */

const Contact = ({ details, getDetails }) => {
  const [isContactExpanded, setContactExpanded] = useState(false);
  const [edit, setEditPopup] = useState(null);
  const { id } = useParams();
  const [state, setState] = useState({
    phone: details?.phone,
    countryCode:
      typeof details?.countryCode === "number"
        ? `+${details?.countryCode}`
        : details?.countryCode || "+49",
    email: details?.email,
  });
  const [updating, setUpdating] = useState(false);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  let arr = CountryList.getAll();

  arr.sort(
    (a, b) => parseInt(a.data.dial_code, 10) - parseInt(b.data.dial_code, 10)
  );

  const country = CountryList.findOneByDialCode(state.countryCode);
  const flagSvg = CountryFlagSvg[country?.code];

  /**
   * Renders the edit popup based on the selected edit type.
   *
   * @function
   * @returns {JSX.Element|null} - Edit popup component or null if not needed
   */
  const renderEditPopup = () => {
    switch (edit) {
      case "phone":
        return (
          <EditPopup
            name={"Phone number"}
            setEditPopup={setEditPopup}
            value={details?.phone}
            countryCode={
              typeof details?.countryCode === "number"
                ? `+${details?.countryCode}`
                : details?.countryCode
            }
            setState={setState}
            keyName="phone"
            state={state}
            handleSubmission={handleSubmission}
            arr={arr}
            flagSvg={flagSvg}
            loading={updating}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Handles the submission of edited contact details.
   *
   * @async
   * @function
   * @returns {void}
   */

  const handleSubmission = async () => {
    setUpdating(true);
    let requestData = {};

    if (state.phone !== details.phone) {
      requestData.phone = state.phone;
    }
    const countryCodeWithoutPlus = state?.countryCode?.replace(/^\+/, "");
    if (
      state?.countryCode &&
      Number(countryCodeWithoutPlus) !== details?.countryCode
    ) {
      const countryCodeNumber = Number(countryCodeWithoutPlus);
      requestData.countryCode = countryCodeNumber;
    }
    try {
      const [err, res] = await utility.parseResponse(
        new UserService().editStaff(id, requestData)
      );
      if (err || !res) return;

      if (res?.responseCode === 200) {
        ShowToast({
          message: successMessage.USER_UPDATED,
          type: toastType.SUCCESS,
        });
        setEditPopup(null);
        getDetails();
      } else {
        ShowToast({
          message: res?.responseData?.message,
          type: toastType.ERROR,
        });
      }
      setUpdating(false);
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div
      className={`flex flex-col p-5 rounded-20 bg-white ${
        isContactExpanded ? "gap-7.5" : ""
      }`}
    >
      <div
        onClick={() => setContactExpanded(!isContactExpanded)}
        className="flex items-center gap-3.75 cursor-pointer justify-between"
      >
        <div className="w-auto">
          <div className="flex items-center justify-center w-12.5 h-12.5 rounded-full bg-yellow-50">
            <img src={Contacts} alt="Contacts" />
          </div>
        </div>
        <div className="flex w-full justify-between">
          <p className="text-ft32 font-ManropeBold text-black-100">Contacts</p>
          <img src={Chevrondown} alt="Chevrondown" className="cursor-pointer" />
        </div>
      </div>
      {isContactExpanded && (
        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">Email</p>
              <p className="font-ManropeRegular text-ft3 text-grey-250">
                {state?.email}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <p className="font-ManropeBold text-ft3 text-black-100">
                Phone number
              </p>
              <p className="font-ManropeRegular text-ft3 text-grey-250">
                {typeof details?.countryCode === "number"
                  ? `+${details?.countryCode}`
                  : details?.countryCode}
                {details?.phone}
              </p>
            </div>
            {userPermissions?.indexOf("staff:write:details") > -1 && (
              <img
                src={Edit}
                alt="Edit"
                onClick={() => setEditPopup("phone")}
                className="cursor-pointer w-5 h-5"
              />
            )}
          </div>
        </div>
      )}
      {renderEditPopup()}
    </div>
  );
};

export default Contact;
