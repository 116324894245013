import React, { useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as AddImage } from "../../../../../../assets/Icons/paperclip.svg";
import ShowToast from "../../../../../../common/showToast";
import {
  failureMessage,
  toastType,
} from "../../../../../../constant/commonArray";
import Cross from "../../../../../../assets/Icons/close-white.svg";
import utility from "../../../../../../utility";

/**
 * TwoSidedPoll component for displaying and managing a two-sided poll input.
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.state - Component state object.
 * @param {Function} props.setState - Function to set component state.
 * @param {number} props.idx - Index of the poll category.
 * @param {Array} props.errorsTwoSided - Array of errors for the two-sided poll.
 * @param {Function} props.setErrorTwoSided - Function to set errors for the two-sided poll.
 * @returns {JSX.Element} TwoSidedPoll component JSX.
 */

const TwoSidedPoll = ({
  state,
  setState,
  idx,
  errorsTwoSided,
  setErrorTwoSided,
}) => {
  const fileInputRefFirst = useRef(null);
  const fileInputRefSecond = useRef(null);

  const { getInputProps: firstImage } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 5 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED,
          type: toastType.ERROR,
        });
      } else {
        setState((prevState) => {
          const updatedPollCategory = [...prevState.polls];
          updatedPollCategory[idx].answers[0].cover = [
            ...updatedPollCategory[idx].answers[0]?.cover,
            ...acceptedFiles,
          ];
          return { ...prevState, polls: updatedPollCategory };
        });
      }
    },
  });

  const { getInputProps: SecondImage } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 50 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED_50MB,
          type: toastType.ERROR,
        });
      } else {
        setState((prevState) => {
          const updatedPollCategory = [...prevState.polls];
          updatedPollCategory[idx].answers[1].cover = [
            ...updatedPollCategory[idx].answers[1]?.cover,
            ...acceptedFiles,
          ];
          return { ...prevState, polls: updatedPollCategory };
        });
      }
    },
  });
  const handleSpanClickFirst = () => {
    fileInputRefFirst.current.click();
  };

  const handleDeleteClickFirst = (index) => {
    setState((prevState) => {
      const updatedPollCategory = [...prevState.polls];
      const coverArray = updatedPollCategory[idx]?.answers[0]?.cover;
      if (Array.isArray(coverArray)) {
        coverArray.splice(index, 1);
      } else {
        updatedPollCategory[idx].answers[0].cover = [];
      }
      return { ...prevState, polls: updatedPollCategory };
    });
  };

  const handleSpanClickSecond = () => {
    fileInputRefSecond.current.click();
  };

  const handleDeleteClickSecond = (index) => {
    setState((prevState) => {
      const updatedPollCategory = [...prevState.polls];
      const coverArray = updatedPollCategory[idx]?.answers[1]?.cover;
      if (Array.isArray(coverArray)) {
        coverArray.splice(index, 1);
      } else {
        updatedPollCategory[idx].answers[1].cover = [];
      }
      return { ...prevState, polls: updatedPollCategory };
    });
  };

  const validateInputLength = (value) => {
    return value.length > 150;
  };

  const handleInputChange = (e, sideIdx, field) => {
    const { value } = e.target;
    const errorArray = [...errorsTwoSided];
    if (validateInputLength(value)) {
      errorArray[idx][sideIdx][field] = `${utility.capitalizeFirstLetter(
        field
      )} should be less than 150 characters`;
    } else {
      errorArray[idx][sideIdx][field] = "";
    }
    setErrorTwoSided(errorArray);
    setState((prevState) => {
      const updatedPolls = [...prevState.polls];
      updatedPolls[idx].answers[sideIdx][field] = value;
      return { ...prevState, polls: updatedPolls };
    });
  };

  useEffect(() => {
    setErrorTwoSided((prev) => {
      const updatedErrors = [...prev];
      updatedErrors[idx] = [
        { title: "", description: "" },
        { title: "", description: "" },
      ];
      return updatedErrors;
    });
    // eslint-disable-next-line
  }, [idx]);

  return (
    <div className="mt-15 flex flex-col">
      <div className="flex flex-col gap-1 pt-5">
        <label
          htmlFor=""
          className="text-black-100 text-ft3 font-ManropeMedium"
        >
          First Side
        </label>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-0.5">
            <input
              type="text"
              placeholder="First side title"
              value={state?.polls[idx]?.answers[0]?.title || ""}
              onChange={(e) => {
                handleInputChange(e, 0, "title");
              }}
              className="w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
            {errorsTwoSided[idx]?.[0]?.title && (
              <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                {errorsTwoSided[idx]?.[0]?.title}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-0.5">
            <input
              type="text"
              placeholder="First side description"
              value={state?.polls[idx]?.answers[0]?.description || ""}
              onChange={(e) => {
                handleInputChange(e, 0, "description");
              }}
              className="w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
            {errorsTwoSided[idx]?.[0]?.description && (
              <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                {errorsTwoSided[idx]?.[0]?.description}
              </div>
            )}
          </div>
        </div>
        {state?.polls[idx]?.answers[0]?.cover?.length > 0 && (
          <div className="pt-5 pb-5 flex flex-col gap-5 ">
            <div className="flex gap-5 items-center py-3  w-41.25 h-41.25 relative">
              <img
                src={
                  state?.polls[idx]?.answers[0]?.cover[0] instanceof File
                    ? URL.createObjectURL(
                        state?.polls[idx]?.answers[0]?.cover[0]
                      )
                    : state?.polls[idx]?.answers[0]?.cover
                }
                alt="Preview"
                className="w-41.25 h-41.25"
              />
              <div
                onClick={() => handleDeleteClickFirst(0)}
                className="w-6 h-6 cursor-pointer rounded-full bg-black-50 p-0.5 absolute top-2 right-2"
              >
                <img src={Cross} alt="check" />
              </div>
            </div>
          </div>
        )}
        {state?.polls[idx]?.answers[0]?.cover?.length <= 0 && (
          <div
            onClick={handleSpanClickFirst}
            className="flex gap-2.5 items-center cursor-pointer pt-5"
          >
            <AddImage />
            <span className="text-orange-50 font-ManropeBold text-ft2">
              Add Cover
            </span>
            <input
              {...firstImage()}
              key={state?.polls[idx]?.answers[0]?.cover?.length}
              ref={fileInputRefFirst}
              type="file"
              className="hidden"
              accept=".svg, .png, .jpg"
            />
          </div>
        )}
      </div>

      <div className="flex flex-col gap-1 pt-5">
        <label
          htmlFor=""
          className="text-black-100 text-ft3 font-ManropeMedium"
        >
          Second Side
        </label>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-0.5">
            <input
              type="text"
              placeholder="Second side title"
              value={state?.polls[idx]?.answers[1]?.title || ""}
              onChange={(e) => {
                handleInputChange(e, 1, "title");
              }}
              className="w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
            {errorsTwoSided[idx]?.[1]?.title && (
              <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                {errorsTwoSided[idx]?.[1]?.title}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-0.5">
            <input
              type="text"
              placeholder="Second side description"
              value={state?.polls[idx]?.answers[1]?.description || ""}
              onChange={(e) => {
                handleInputChange(e, 1, "description");
              }}
              className="w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
            />
            {errorsTwoSided[idx]?.[1]?.description && (
              <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                {errorsTwoSided[idx]?.[1]?.description}
              </div>
            )}
          </div>
        </div>
        {state?.polls[idx]?.answers[1]?.cover?.length > 0 && (
          <div className="pt-5 pb-5 flex flex-col gap-5 ">
            <div className="flex gap-5 items-center py-3 rounded-20 w-41.25 h-41.25 relative">
              <img
                src={
                  state?.polls[idx]?.answers[1]?.cover[0] instanceof File
                    ? URL.createObjectURL(
                        state?.polls[idx]?.answers[1]?.cover[0]
                      )
                    : state?.polls[idx]?.answers[1]?.cover
                }
                alt="Preview"
                className="w-41.25 h-41.25 rounded-20"
              />
              <div
                onClick={() => handleDeleteClickSecond(0)}
                className="w-6 h-6 cursor-pointer rounded-full bg-black-50 p-0.5 absolute top-2 right-2"
              >
                <img src={Cross} alt="check" />
              </div>
            </div>
          </div>
        )}
        {state?.polls[idx]?.answers[1]?.cover?.length <= 0 && (
          <div
            onClick={handleSpanClickSecond}
            className="flex gap-2.5 items-center cursor-pointer pt-5"
          >
            <AddImage />
            <span className="text-orange-50 font-ManropeBold text-ft2">
              Add Cover
            </span>
            <input
              {...SecondImage()}
              key={state?.polls[idx]?.answers[1]?.cover.length}
              ref={fileInputRefSecond}
              type="file"
              className="hidden"
              accept=".svg, .png, .jpg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TwoSidedPoll;
