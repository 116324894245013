import React, { useEffect, useState } from "react";
import calender from "../../../assets/Icons/calender.svg";
import Calendar from "react-calendar";
import Time from "../../../assets/Icons/Time.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CircularProgress } from "@mui/material";
import utility from "../../../utility";
import { UserService } from "../../../services";
import { useDebouncedCallback } from "use-debounce";
import placeholder from "../../../assets/Icons/Placeholder.svg";
import WarningPopup from "../../../common/warningPopup";

/**
 * React component for managing general information related to creating a votes promotion.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Function} props.setSteps - Function to set the current step.
 * @param {Function} props.setState - Function to set the state of the component.
 * @param {Object} props.state - State object containing votes promotion details.
 * @param {Function} props.setUntilSelectTime - Function to set the "Until" select time.
 * @param {string} props.untilSelectTime - "Until" select time state.
 * @param {Function} props.setSelectTime - Function to set the select time.
 * @param {string} props.selectTime - Select time state.
 * @param {Function} props.setUntilDate - Function to set the "Until" date.
 * @param {Date} props.untilDate - "Until" date state.
 * @param {Function} props.setDateStart - Function to set the start date.
 * @param {Date} props.dateStart - Start date state.
 * @param {Function} props.setAccount - Function to set the selected account.
 * @param {Object} props.account - Selected account state.
 * @param {Function} props.setEndEditTime - Function to set the "End Edit Time" state.
 * @param {Function} props.setStartEditTime - Function to set the "Start Edit Time" state.
 * @param {Function} props.setByDefaultEndTime - Function to set the "By Default End Time" state.
 * @param {Function} props.setByDefaultStartTime - Function to set the "By Default Start Time" state.
 * @returns {JSX.Element} JSX representation of the GeneralVotes component.
 *
 * @example
 * // Usage of the GeneralVotes component
 * <GeneralVotes
 *   setSteps={setSteps}
 *   setState={setState}
 *   state={state}
 *   setUntilSelectTime={setUntilSelectTime}
 *   untilSelectTime={untilSelectTime}
 *   setSelectTime={setSelectTime}
 *   selectTime={selectTime}
 *   setUntilDate={setUntilDate}
 *   untilDate={untilDate}
 *   setDateStart={setDateStart}
 *   dateStart={dateStart}
 *   setAccount={setAccount}
 *   account={account}
 *   setEndEditTime={setEndEditTime}
 *   setStartEditTime={setStartEditTime}
 *   setByDefaultStartTime={setByDefaultStartTime}
 *   setByDefaultEndTime={setByDefaultEndTime}
 * />
 */

const GeneralVotes = ({
  setSteps,
  setState,
  state,
  setUntilSelectTime,
  untilSelectTime,
  setSelectTime,
  selectTime,
  setUntilDate,
  untilDate,
  setDateStart,
  dateStart,
  setAccount,
  account,
  setEndEditTime,
  setStartEditTime,
  setByDefaultEndTime,
  setByDefaultStartTime,
}) => {
  const [toggleCalender, setToggleCalender] = useState(false);
  const [toggleUntilCalender, setToggleUntilCalender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [entityList, setEntityList] = useState([]);
  const [debounceValue, setDebounceValue] = useState("");
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isValidLink, setIsvalidLink] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const history = useHistory();
  const [error, setError] = useState({});

  const handleDiscardChanges = () => {
    setShowWarning(false);
    history.push("/promo-and-rewards");
  };

  const debouncedSearch = useDebouncedCallback((value) => {
    setDebounceValue(value);
  }, 500);

  const getEntityData = async () => {
    setLoading(true);
    let requestParams = {
      limit: 30,
      skip: 0,
      status: "ACTIVE",
    };

    if (debounceValue) {
      requestParams.searchKey = debounceValue;
    }
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getEntityList(requestParams)
      );
      if (error || !response) return;
      setEntityList(response?.entitiesList);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    debouncedSearch(value);
    setDropDownOpen(true);
  };

  useEffect(() => {
    if (searchQuery) getEntityData();
    // eslint-disable-next-line
  }, [debounceValue]);

  const handleLinkChange = (e) => {
    const enteredValue = e.target.value;
    const isValidWebsite = enteredValue.includes("https://");

    setState((prev) => ({ ...prev, reward: e.target.value }));
    setIsvalidLink(isValidWebsite);
  };

  useEffect(() => {
    if (account?.fullName) setSearchQuery(account?.fullName);
    // eslint-disable-next-line
  }, [account?.fullName]);

  return (
    <div className="flex flex-col gap-7.5">
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10">
        <p className="text-black-100 font-ManropeMedium text-ft3">
          Name of promotion
        </p>
        <input
          type="text"
          value={state?.name}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue.length <= 50) {
              setError((prev) => ({ ...prev, nameError: false }));
            } else {
              setError((prev) => ({ ...prev, nameError: true }));
            }
            setState((prev) => ({
              ...prev,
              name: e.target.value,
            }));
          }}
          className="h-16.25 w-1/2 px-5 rounded-full bg-grey-50 focus:outline-none text-black-100 font-ManropeRegular text-ft3"
        />
        {error?.nameError && (
          <span className=" text-red-50">Name can't be more than 50</span>
        )}
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col gap-2.5 px-10 justify-center">
        <p className="text-black-100 font-ManropeMedium text-ft3">Audience</p>
        <div className="flex justify-between">
          <div className="flex gap-2.5">
            <p
              onClick={() =>
                setState((prev) => {
                  const currentAudience = prev?.contentType || [];
                  const updatedAudience = currentAudience.includes("POLLS")
                    ? currentAudience.filter((value) => value !== "POLLS")
                    : [...currentAudience, "POLLS"];

                  return {
                    ...prev,
                    contentType: updatedAudience,
                  };
                })
              }
              className={`flex rounded-20 ${
                state?.contentType?.includes("POLLS")
                  ? "bg-orange-50"
                  : "bg-grey-50"
              }  px-2.5 py-1.25 text-black-100 font-ManropeBold text-ft2 cursor-pointer`}
            >
              Polls
            </p>
            <p
              onClick={() =>
                setState((prev) => {
                  const currentAudience = prev?.contentType || [];
                  const updatedAudience = currentAudience.includes("SURVEY")
                    ? currentAudience.filter((value) => value !== "SURVEY")
                    : [...currentAudience, "SURVEY"];

                  return {
                    ...prev,
                    contentType: updatedAudience,
                  };
                })
              }
              className={`flex rounded-20 ${
                state?.contentType?.includes("SURVEY")
                  ? "bg-orange-50"
                  : "bg-grey-50"
              }  px-2.5 py-1.25 text-black-100 font-ManropeBold text-ft2 cursor-pointer`}
            >
              Survey
            </p>
          </div>
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10">
        <p className="text-black-100 font-ManropeMedium text-ft3">
          Count of creating content
        </p>
        <input
          type="text"
          value={state?.contentCount}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue === "" || /^[0-9]*$/.test(inputValue)) {
              setState((prev) => ({
                ...prev,
                contentCount: inputValue === "" ? "" : parseInt(inputValue),
              }));
            }
          }}
          className="px-5 h-16.25 w-1/2 rounded-full focus:outline-none bg-grey-50  text-black-100 font-ManropeRegular text-ft3"
        />
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10">
        <p className="text-black-100 font-ManropeMedium text-ft3">
          Number of voters
        </p>
        <input
          type="text"
          value={state?.numberOfVoters}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue === "" || /^[0-9]*$/.test(inputValue)) {
              setState((prev) => ({
                ...prev,
                numberOfVoters: inputValue === "" ? "" : parseInt(inputValue),
              }));
            }
          }}
          className="px-5 h-16.25 w-1/2 rounded-full focus:outline-none bg-grey-50  text-black-100 font-ManropeRegular text-ft3"
        />
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col px-10">
        <p className="text-black-100 font-ManropeMedium text-ft3">
          Account (Optional)
        </p>
        <div className="relative">
          <input
            type="text"
            placeholder="Type Name of company or username"
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
            className="px-5 h-16.25 w-1/2 rounded-full focus:outline-none bg-grey-50  text-black-100 font-ManropeRegular text-ft3"
          />
          {!!searchQuery && !loading && dropDownOpen ? (
            <div className="absolute top-18 max-h-55 overflow-y-auto z-10 shadow-inner bg-white rounded-20 w-1/2">
              {entityList.length > 0 ? (
                entityList.map((item) => (
                  <div
                    onClick={() => {
                      setSearchQuery(item?.fullName);
                      setState((prev) => ({
                        ...prev,
                        account: item?._id,
                      }));
                      setAccount(item);
                      setDropDownOpen(false);
                    }}
                    className="flex gap-2.5 px-5 py-2.5 cursor-pointer"
                  >
                    <div className="w-12.5 h-12.5 rounded-20">
                      <img
                        src={item?.profilePhoto}
                        alt=""
                        className="w-12.5 h-12.5 rounded-20"
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="font-ManropeBold text-ft1 text-black-50">
                        {item?.fullName}
                      </span>
                      <span className="font-ManropeRegular text-ft1 text-black-75">
                        {item?.userName}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center gap-7.5 flex-shrink-0 self-center">
                  <img src={placeholder} alt="placeholder" className="h-50" />
                </div>
              )}
            </div>
          ) : (
            loading &&
            dropDownOpen && (
              <div className="absolute top-18 h-40 overflow-y-auto z-10 shadow-inner bg-white rounded-20 w-1/2">
                <div className="flex justify-center  items-center h-full w-full">
                  <CircularProgress className="text-black-100 w-5 h-5" />
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex flex-col gap-5">
        <div className="flex flex-col px-10">
          <label className="radio-label flex items-center gap-3.75 text-black-100 font-ManropeMedium text-ft3">
            <input
              type="radio"
              value="reward"
              className="w-6.25 h-6.25"
              checked={!state?.rewardViaLink}
              onChange={() => {
                setState((prev) => ({
                  ...prev,
                  rewardViaLink: false,
                  reward: "",
                }));
                setIsvalidLink(true);
              }}
            />
            <span className="radio-custom"></span>
            Reward, Dollars
          </label>
          {!state?.rewardViaLink && (
            <>
              <input
                type="text"
                value={state?.reward}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue === "" || /^[0-9]*$/.test(inputValue)) {
                    setState((prev) => ({
                      ...prev,
                      reward: inputValue === "" ? "" : parseInt(inputValue),
                    }));
                  }
                }}
                className="px-5 ml-10 h-16.25 w-1/2 rounded-full bg-grey-50 text-black-100 font-ManropeRegular text-ft3 focus:outline-none"
              />
            </>
          )}
        </div>
        <div className="flex flex-col px-10">
          <label className="radio-label flex items-center gap-3.75 text-black-100 font-ManropeMedium text-ft3">
            <input
              type="radio"
              value="link"
              className="w-6.25 h-6.25"
              checked={state?.rewardViaLink}
              onChange={() => {
                setState((prev) => ({
                  ...prev,
                  rewardViaLink: true,
                  reward: "",
                }));
                setIsvalidLink(true);
              }}
            />
            <span className="radio-custom"></span>
            Reward via Link
          </label>
          {state?.rewardViaLink && (
            <>
              <input
                type="text"
                value={state?.reward}
                onChange={handleLinkChange}
                className="h-16.25 px-5 ml-10 w-1/2 rounded-full bg-grey-50 focus:outline-none"
              />
              {!isValidLink && (
                <span className=" text-red-50">
                  Invalid link. Please include 'https://'
                </span>
              )}
            </>
          )}
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex pl-10 gap-3.75">
        <div className="flex flex-col relative">
          <p className="text-black-100 font-ManropeMedium text-ft3">
            Start Date
          </p>
          <div>
            <div
              onClick={() => setToggleCalender(!toggleCalender)}
              className="flex justify-between px-6.25 py-5 rounded-full bg-grey-50 w-50 cursor-pointer"
            >
              <p>
                {dateStart.getDate() +
                  "." +
                  (dateStart.getMonth() + 1) +
                  "." +
                  dateStart.getFullYear()}
              </p>
              <img src={calender} alt={calender} />
            </div>
            {toggleCalender && (
              <Calendar
                onChange={(value) => {
                  setDateStart(value);
                  setToggleCalender(false);
                  setStartEditTime(false);
                  setByDefaultStartTime(false);
                }}
                value={dateStart}
                className="absolute top-25 left-0 bg-white w-99.5 rounded-20 shadow-inner border-0.5 border-black-10 font-ManropeRegular text-ft3 text-black-100 z-10"
              />
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-black-100 font-ManropeMedium text-ft3">Time</p>
          <div className="flex relative items-center justify-between px-6.25 py-4 rounded-full bg-grey-50 w-50">
            <input
              type="time"
              value={selectTime}
              onChange={(e) => setSelectTime(e.target.value)}
              className="rounded-full px-2 py-1 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 w-full"
            />
            <img
              src={Time}
              alt={"time"}
              className=" absolute right-8 pointer-events-none"
            />
          </div>
        </div>
      </div>
      <hr className="text-grey-50" />
      <div className="flex px-10 gap-3.75">
        <div className="flex flex-col relative">
          <p className="text-black-100 font-ManropeMedium text-ft3">
            Until Date (optional)
          </p>
          <div>
            <div
              onClick={() => setToggleUntilCalender(!toggleUntilCalender)}
              className="flex justify-between px-6.25 py-5 rounded-full bg-grey-50 w-50 cursor-pointer"
            >
              <p>
                {untilDate.getDate() +
                  "." +
                  (untilDate.getMonth() + 1) +
                  "." +
                  untilDate.getFullYear()}
              </p>
              <img src={calender} alt={calender} />
            </div>
            {toggleUntilCalender && (
              <Calendar
                onChange={(value) => {
                  setUntilDate(value);
                  setToggleUntilCalender(false);
                  setEndEditTime(false);
                  setByDefaultEndTime(false);
                }}
                value={untilDate}
                className="absolute top-25 left-0 bg-white w-99.5 rounded-20 shadow-inner border-0.5 border-black-10 font-ManropeRegular text-ft3 text-black-100 z-10"
              />
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-black-100 font-ManropeMedium text-ft3">Time</p>
          <div className="flex relative items-center justify-between px-6.25 py-4 rounded-full bg-grey-50 w-50">
            <input
              type="time"
              value={untilSelectTime}
              onChange={(e) => setUntilSelectTime(e.target.value)}
              className="rounded-full px-2 py-1 bg-grey-50 font-ManropeRegular text-ft3 text-black-100 w-full"
            />
            <img
              src={Time}
              loading="lazy"
              alt={"time"}
              className=" absolute right-8 pointer-events-none"
            />
          </div>
        </div>
      </div>
      <hr className="text-grey-50" />
      <footer className="flex justify-end items-end pr-7.5 gap-5 rounded-20 bg-white">
        <button
          onClick={() => setShowWarning(true)}
          className="flex justify-center items-center rounded-full border border-solid border-black-20 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
        >
          Cancel
        </button>

        <button
          onClick={() => setSteps(1)}
          disabled={
            !state?.name ||
            !state?.reward ||
            !state?.numberOfVoters ||
            !state?.contentCount ||
            state?.contentType?.length <= 0 ||
            state?.name?.trim() === "" ||
            error?.nameError ||
            !isValidLink
          }
          className={`flex justify-center items-center rounded-full border border-solid border-transparent ${
            !state?.name ||
            !state?.reward ||
            !state?.numberOfVoters ||
            !state?.contentCount ||
            state?.contentType?.length <= 0 ||
            state?.name?.trim() === "" ||
            error?.nameError ||
            !isValidLink
              ? "bg-grey-50"
              : "bg-orange-50"
          }  py-5 w-55 text-black-100 font-ManropeBold text-ft2`}
        >
          Create
        </button>
      </footer>
      {showWarning && (
        <WarningPopup
          title="Discard changes ?"
          description="All entered data will be deleted"
          setToggle={setShowWarning}
          handleActivateUser={handleDiscardChanges}
        />
      )}
    </div>
  );
};

export default GeneralVotes;
