/**
 * React component for handling entity check and approval/rejection.
 * @component
 * @example
 * // Usage of EntityCheck in another component or file
 * import EntityCheck from './EntityCheck';
 * // ...
 * <EntityCheck />
 */

import React, { useEffect, useState } from "react";
import left from "../../assets/Icons/Left.svg";
import ImagePopUp from "../../common/imagePopUp";
import RejectRequestPopUp from "./rejectRequestPopUp";
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import utility from "../../utility";
import { UserService } from "../../services";
import { CircularProgress } from "@mui/material";
import UserEntityService from "../../services/userEntityService";
import ShowToast from "../../common/showToast";
import { toastType } from "../../constant/commonArray";
import { useSelector } from "react-redux";
import FileImage from "../../assets/Icons/paperclip.svg";

/**
 * Functional component for handling entity check and approval/rejection.
 * @returns {JSX.Element} JSX element representing the EntityCheck component.
 */

const EntityCheck = () => {
  const [toggleImagePopUp, setToggleImagePopUp] = useState(false);
  const [isRejectPopUp, setRejectPopUp] = useState(false);
  const [requestDetails, setRequestDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const [image, setImages] = useState([]);
  const [reason, setReason] = useState("Not Choosen");
  const [customReasonInput, setCustomReasonInput] = useState("");
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  /**
   * Function to retrieve entity request details asynchronously.
   *
   * @param {void}
   * @return {void}
   */

  const getEntityRequestDetails = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().getEntityRequestDetails(id)
      );

      if (error || !response) return;
      setRequestDetails(response);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEntityRequestDetails();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  /**
   * Function to handle the creation of an entity.
   *
   * @return {Promise<void>} This function does not return anything.
   */

  const handleCreateEntity = async () => {
    let requestData = {
      byRequest: true,
      requestId: id,
    };
    try {
      const [error, response] = await utility.parseResponse(
        new UserEntityService().createEntity(requestData)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        history.push("/requests");
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  /**
   * Asynchronously handles the rejection of a blue tick.
   */
  const handleRejectBlueTick = async () => {
    try {
      let requestData = {
        requestType: "ENTITY",
        requestReason: reason === "Other" ? customReasonInput : reason,
      };
      const [error, response] = await utility.parseResponse(
        new UserService().rejectRequest(id, requestData)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.SUCCESS,
        });
        history.push("/requests");
      } else {
        ShowToast({
          message: response?.responseData?.message,
          type: toastType.ERROR,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleType = (item) => {
    if (item === "USER") {
      return "User (Blue Check Verification)";
    } else if (item === "BUSINESS") {
      return "Business (Grey Check)";
    } else if (item === "ORGANIZATION") {
      return "Organization (Blue Check)";
    } else if (item === "GOVERNMENT") {
      return "Government (Green Check)";
    }
  };

  return (
    <div className="w-full overflow-y-auto mt-5 pb-5 pr-12.5">
      <div className="flex flex-col w-full">
        <div className=" h-21.25 px-5 flex justify-between items-center rounded-20 bg-white">
          <div className="flex gap-7.5 items-center">
            <div
              onClick={() => {
                if (haveAudit) {
                  history.push("/audit-log");
                } else {
                  history.push("/requests");
                }
              }}
            >
              <img src={left} alt="left" className="cursor-pointer" />
            </div>
            <p className="text-black-100 font-ManropeBold text-ft5">
              Request for creating entity account -{" "}
              {handleType(requestDetails?.type)}
            </p>
          </div>
        </div>
        <div className="flex flex-col rounded-t-20 mt-5 gap-5 max-h-70vh overflow-y-auto scrollbar-hide">
          <div className=" flex flex-col rounded-20 bg-white">
            <div className="flex px-10 py-5">
              <p className="text-black-100 font-ManropeBold text-ft42">
                General
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-start py-6 px-10">
              <img
                src={requestDetails?.profilePhoto}
                alt=""
                className="w-42.5 h-42.5 rounded-full"
              />
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                Company name
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {requestDetails?.fullName}
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                Username
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {requestDetails?.userName}
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                Location
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {requestDetails?.location?.name}
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-5 px-10 items-center">
              <p className="text-black-150 font-ManropeBold text-ft3">
                Description
              </p>
              <div className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3 w-92.25 flex justify-end">
                {requestDetails?.bio}
              </div>
            </div>
          </div>
          <div className=" flex flex-col rounded-20 bg-white">
            <div className="flex px-10 py-5">
              <p className="text-black-100 font-ManropeBold text-ft42">
                Entity info
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                Entity type
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {utility.capitalizeFirstLetter(requestDetails?.type)}
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                Entity license
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {requestDetails?.organisationDocuments?.licence}
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                DUN number
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                NA
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                State of registration
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {requestDetails?.registrationAddress?.location?.name}
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                Address
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {requestDetails?.registrationAddress?.zipCode},{" "}
                {requestDetails?.registrationAddress?.address}
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                ITIN number
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {requestDetails?.organisationDocuments?.taxId}
              </p>
            </div>
            <hr className="text-grey-50" />
            {requestDetails?.otherDocuments?.length > 0 && (
              <div className="flex justify-between py-5 px-10 items-center">
                <p className="text-black-150 font-ManropeBold text-ft3">
                  Documents
                </p>
                <div className="flex gap-3.75">
                  {requestDetails?.otherDocuments?.map((item) => {
                    return (
                      <div
                        key={item?._id}
                        className={` w-30 h-30 rounded-20 cursor-pointer`}
                        onClick={() => {
                          setToggleImagePopUp(!toggleImagePopUp);
                          setImages(requestDetails?.otherDocuments);
                        }}
                      >
                        {item?.type?.includes("pdf") ||
                        item?.type?.includes("docx") ||
                        item?.type?.includes("doc") ? (
                          <div
                            className={`w-30 h-30 flex-col rounded-20 justify-end items-center gap-2 flex cursor-pointer`}
                          >
                            <img src={FileImage} alt="" className="w-10 h-10" />
                            <span className="text-ft1 text-center text-black-75 font-ManropeMedium">
                              preview file
                            </span>
                          </div>
                        ) : (
                          <img
                            src={item?.url}
                            alt=""
                            className="w-30 h-30 rounded-20"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className=" flex flex-col mb-5 rounded-20 bg-white">
            <div className="text-black-100 font-ManropeBold text-ft42 px-10 py-5">
              Contact person
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">Name</p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {requestDetails?.delegatedContactPerson?.name}
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">
                Phone number
              </p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                + {requestDetails?.delegatedContactPerson?.countryCode}{" "}
                {requestDetails?.delegatedContactPerson?.phone}
              </p>
            </div>
            <hr className="text-grey-50" />
            <div className="flex justify-between py-6 px-10">
              <p className="text-black-150 font-ManropeBold text-ft3">Email</p>
              <p className="text-black-100 opacity-60 font-normal font-ManropeRegular text-ft3">
                {requestDetails?.delegatedContactPerson?.email}
              </p>
            </div>
          </div>
        </div>
        {userPermissions?.indexOf("request:write:entity") > -1 && (
          <footer className="flex justify-end items-end pr-7.5 py-5 gap-5 rounded-20 bg-white">
            <button
              onClick={() => setRejectPopUp(!isRejectPopUp)}
              className="flex justify-center items-center rounded-full border border-solid border-black-20 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
            >
              Reject
            </button>
            <button
              onClick={handleCreateEntity}
              className="flex justify-center items-center rounded-full border border-solid border-transparent bg-orange-50 py-5 w-55 text-black-100 font-ManropeBold text-ft2"
            >
              Confirm
            </button>
          </footer>
        )}
      </div>

      {toggleImagePopUp && (
        <ImagePopUp
          toggleImagePopUp={toggleImagePopUp}
          setToggleImagePopUp={setToggleImagePopUp}
          images={image}
        />
      )}
      {isRejectPopUp && (
        <RejectRequestPopUp
          isRejectPopUp={isRejectPopUp}
          setRejectPopUp={setRejectPopUp}
          setReason={setReason}
          reason={reason}
          customReasonInput={customReasonInput}
          handleRejectBlueTick={handleRejectBlueTick}
          setCustomReasonInput={setCustomReasonInput}
        />
      )}
    </div>
  );
};

export default EntityCheck;
