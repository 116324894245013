import React, { useEffect, useRef, useState } from "react";
import {
  sortableContainer,
  sortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import AddImage from "../../../../../assets/Icons/plus-orange.svg";
import ShowToast from "../../../../../common/showToast";
import { failureMessage, toastType } from "../../../../../constant/commonArray";
import Cross from "../../../../../assets/Icons/close-white.svg";
import Menu from "../../../../../assets/Icons/menu-white.svg";
import { useDropzone } from "react-dropzone";
import ImageLogo from "../../../../../assets/Icons/image-logo.svg";

/**
 * Component for managing image uploads and sorting images in a sortable container.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.images - Array of images to manage.
 * @param {Function} props.setImages - Function to update the images array.
 * @returns {JSX.Element} Rendered component.
 */
const TypeImage = ({ images, setImages }) => {
  const fileInputRef = useRef(null);
  const [idx, setIdx] = useState(null);

  /**
   * Handles sorting images when dragged within the sortable container.
   * @param {Object} param0 - Object containing oldIndex and newIndex of the sorted item.
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setImages(arrayMoveImmutable(images, oldIndex, newIndex));
  };

  const handleDeleteClick = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    if (images.length === 1) {
      setImages([{ dummyImages: "" }, { dummyImages: "" }]);
    } else {
      setImages(updatedImages);
    }
  };

  const { getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 50 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    /**
     * Processes dropped files and displays appropriate toasts for errors.
     * @param {Array} acceptedFiles - Array of accepted files.
     * @param {Array} fileRejections - Array of rejected files with reasons.
     */
    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED_50MB,
          type: toastType.ERROR,
        });
      } else {
        const updatedFiles = [...images];
        updatedFiles[idx] = acceptedFiles[0];
        setImages(updatedFiles);
      }
    },
  });

  const handleSpanClick = (idx) => {
    fileInputRef.current.click();
    setIdx(idx);
  };

  const handleMoreImage = () => {
    if (images.length <= 5) {
      const updatedFiles = [...images, { dummyImages: "" }];
      setImages(updatedFiles);
    } else {
      ShowToast({
        message: "You can add only 6 images",
        type: toastType.ERROR,
      });
    }
  };

  useEffect(() => {
    if (images.length === 0) {
      setImages([{ dummyImages: "" }, { dummyImages: "" }]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {images.length > 0 ? (
        <SortableContainer onSortEnd={onSortEnd} axis="xy" useDragHandle={true}>
          {images.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              idx={index}
              index={index}
              value={value}
              deleteFunc={handleDeleteClick}
              handleSpanClick={handleSpanClick}
              getInputProps={getInputProps}
              images={images}
              fileInputRef={fileInputRef}
            />
          ))}
        </SortableContainer>
      ) : (
        <div className="flex flex-wrap gap-10">
          <div
            onClick={() => handleSpanClick(0)}
            className="flex flex-col items-center justify-center w-40 h-40 bg-grey-50 rounded-20 cursor-pointer"
          >
            <img src={ImageLogo} alt="" />
            <span className="text-black-100 text-ft3 font-ManropeMedium">
              Add image
            </span>
            <input
              {...getInputProps()}
              key={images.length}
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept=".svg, .png, .jpg"
              multiple
            />
          </div>
          <div
            onClick={() => handleSpanClick(1)}
            className="flex flex-col items-center justify-center w-40 h-40 bg-grey-50 rounded-20 cursor-pointer"
          >
            <img src={ImageLogo} alt="" />
            <span className="text-black-100 text-ft3 font-ManropeMedium">
              Add image
            </span>
            <input
              {...getInputProps()}
              key={images.length}
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept=".svg, .png, .jpg"
              multiple
            />
          </div>
        </div>
      )}
      <div
        onClick={handleMoreImage}
        className="flex gap-2.5 items-center cursor-pointer pt-5"
      >
        <label>
          <img src={AddImage} alt="" />
        </label>
        <span className="text-orange-50 font-ManropeBold text-ft2">
          Add more
        </span>
      </div>
    </div>
  );
};

export default TypeImage;

const SortableItem = sortableElement(
  ({
    value,
    deleteFunc,
    idx,
    handleSpanClick,
    getInputProps,
    images,
    fileInputRef,
  }) => {
    return (
      <div>
        {value?.dummyImages !== "" ? (
          <div className="flex flex-col relative items-center justify-center w-40 h-40  rounded-20 cursor-pointer">
            {value && (
              <img
                src={
                  value instanceof File
                    ? URL.createObjectURL(value)
                    : value.value
                }
                alt=""
                className="rounded-20 w-40 h-40 object-fill"
              />
            )}
            <div
              onClick={() => {
                deleteFunc(idx);
              }}
              className="w-6 h-6 z-50 cursor-pointer rounded-full bg-black-100 bg-opacity-70 p-0.5 absolute top-2 right-2"
            >
              <img src={Cross} alt="check" />
            </div>
            <div className="cursor-pointer flex gap-1 absolute left-2 top-2 rounded-20 bg-black-100 bg-opacity-70  px-3 py-0.5 w-13">
              <span className="text-white text-ft2 font-ManropeBold">
                {idx + 1}
              </span>
              <DragHandle />
            </div>
          </div>
        ) : (
          <div className="flex flex-col relative  w-40 h-40 bg-grey-50 rounded-20 cursor-pointer">
            <div
              onClick={() => {
                handleSpanClick(idx);
              }}
              className="flex justify-center items-center flex-col gap-1 h-40"
            >
              <img src={ImageLogo} alt="" />
              <span className="text-black-100 text-ft3 font-ManropeMedium">
                Add image
              </span>
            </div>
            <input
              {...getInputProps()}
              key={images.length}
              ref={(el) => (fileInputRef.current = el)}
              type="file"
              className="hidden"
              accept=".svg, .png, .jpg"
              multiple
            />
            {images?.length > 2 && (
              <div
                onClick={() => {
                  deleteFunc(idx);
                }}
                className="w-6 h-6 z-50 cursor-pointer rounded-full bg-black-100 bg-opacity-70 p-0.5 absolute top-2 right-2"
              >
                <img src={Cross} alt="check" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <div className="bg-transparent flex flex-wrap gap-10 w-full">
      {children}
    </div>
  );
});

const DragHandle = SortableHandle(() => {
  return <img src={Menu} alt="menu" className="cursor-pointer" />;
});
