import React, { useEffect, useMemo, useState } from "react";
import Dropdown from "../../../common/component/dropdown";
// import ToggleSwitch from "../../../common/component/toggleSwitch";
import { ReactComponent as AddImage } from "../../../assets/Icons/paperclip.svg";
import { ReactComponent as Delete } from "../../../assets/Icons/delete.svg";
import { ReactComponent as Crop } from "../../../assets/Icons/crop.svg";
import AdddMedia from "./addMediaPopup";
import LinkPopup from "./linkPopup";
import PreviewPopup from "./previewPopup";
import {
  categoryOption,
  EnumValues,
  pollDescription,
  pollTypeOption,
  textData,
} from "../../../constant/commonArray";
// import SelectEntityPopup from "./selectEntityPopup";
// import UpArrow from "../../../assets/Icons/up-arrow.svg";
// import DownArrow from "../../../assets/Icons/down-arrow.svg";
// import OutsideClickRef from "../../../common/outsideClickRef";
import LinkPreview from "./linkPreview";
import { useHistory } from "react-router-dom";
import CropImage from "./cropper";
import PreviewTagsMentions from "./previewTagsMentions";
import utility from "../../../utility";
import AudioPreview from "./audioPreview";
import { ReactComponent as Edit } from "../../../assets/Icons/Edit.svg";
import AudioFileName from "./audioFileNameEdit";
import CropVideo from "./videoCropper";

/**
 * Component for creating and editing poll details.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.setState - Function to set the component state.
 * @param {Object} props.state - Current state of the component.
 * @param {Function} props.setSteps - Function to set the current step of the process.
 * @param {Object} props.account - User account information.
 * @param {Function} props.setAccount - Function to set the user account information.
 * @param {string} props.pollId - ID of the poll being edited.
 * @param {Function} props.setImages - Function to set images for the poll.
 * @param {Object} props.ffmpeg - FFmpeg instance for video processing.
 * @param {boolean} props.isScriptLoaded - Flag indicating whether the script is loaded.
 * @returns {JSX.Element} PollDetails component.
 */
const PollDetails = ({
  setState,
  state,
  setSteps,
  account,
  setAccount,
  pollId,
  setImages,
  ffmpeg,
  isScriptLoaded,
}) => {
  const [openMedia, setOpenMedia] = useState(null);
  // const [openEntity, setOpenEntity] = useState(false);
  const [files, setFiles] = useState([]);
  const [audioValues, setAudioValues] = useState({
    name: "",
    index: null,
  });

  const [imagePopup, setImagePopup] = useState({
    popup: false,
    index: "",
  });
  const [videoPopup, setVideoPopup] = useState({
    popup: false,
    index: "",
  });
  const [selectFile, setSelectedFile] = useState([]);
  const [errors, setErrors] = useState({});

  // const dropdownRef = useRef(null);
  const history = useHistory();

  /**
   * Handles selecting a category option for the poll.
   * Clears existing data related to the previous category.
   *
   * @param {string} option - Selected category option.
   */

  const handleCategoryOption = (option) => {
    setState((prev) => ({
      ...prev,
      questionType: option,
      question: "",
      description: "",
      media: [],
      audioName: [],
      answer: [
        {
          title: "",
          description: "",
          cover: [],
        },
        {
          title: "",
          description: "",
          cover: [],
        },
      ],
      options: {
        type: "TEXT",
        answers: textData,
        name: "Text",
      },
      isSensitive: false,
      isAnonymous: false,
      isFromAdmin: true,
      byPassReview: false,
      isForPollpapa: false,
      isRewardAdded: false,
      multipleOptions: false,
      openAnswer: false,
    }));
    setImages([{ dummyImages: "" }, { dummyImages: "" }]);
  };

  /**
   * Handles deleting a media file from the poll.
   *
   * @param {number} index - Index of the media file to delete.
   */
  const handleDeleteClick = (index) => {
    setState((prevState) => {
      const updatedFiles = [...prevState.media];
      updatedFiles.splice(index, 1);
      const updatedAudioName = [...prevState.audioName];
      updatedAudioName.splice(index, 1);
      return { ...prevState, media: updatedFiles, audioName: updatedAudioName };
    });
  };

  /**
   * Handles adding a media file to the poll.
   *
   * @param {File} file - Media file to add.
   * @param {string} name - Optional name of the audio file.
   */
  const handleAddMedia = (file, name) => {
    setState((prev) => ({
      ...prev,
      media: [...prev.media, file],
      audioName: [...prev.audioName, { fileName: !!name ? name : "" }],
    }));
  };

  const handleOnChangeDescription = (e) => {
    if (state?.description.length > 150) {
      setErrors({
        ...errors,
        description: "Description should be less than 150 characters",
      });
    } else {
      setErrors({
        ...errors,
        description: "",
      });
    }
    setState((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  useEffect(() => {
    if (state?.description.length > 150) {
      setErrors({
        ...errors,
        description: "Description should be less than 150 characters",
      });
    } else {
      setErrors({
        ...errors,
        description: "",
      });
    }
    // eslint-disable-next-line
  }, [state.description]);

  const handleEditName = (index, name) => {
    setState((prev) => ({
      ...prev,
      audioName: prev.audioName.map((item, i) => {
        if (i === index) {
          return { ...item, fileName: name };
        }
        return item;
      }),
    }));
  };

  const handleAddBrowseMedia = (image, index) => {
    setState((prev) => ({
      ...prev,
      media: prev.media.map((item, idx) => (idx === index ? image : item)),
    }));
  };
  const handleCroppedImageInitial = (image) => {
    setFiles([image]);
    setOpenMedia("PREVIEW");
  };

  /**
   * Renders the media preview based on its type.
   *
   * @param {File | Object} item - Media file or object containing details.
   * @param {string} fileType - Type of the media file.
   * @param {number} idx - Index of the media file in the list.
   * @returns {JSX.Element} Rendered media preview component.
   */
  const renderMedia = (item, fileType, idx) => {
    const haveImage =
      fileType === "image" ||
      fileType === "gif" ||
      item?.type === "png" ||
      item?.type === "jpg" ||
      item?.type === "jpeg" ||
      item?.type === "gif";
    switch (true) {
      case haveImage:
        return (
          <img
            src={item instanceof File ? URL.createObjectURL(item) : item?.url}
            alt="Preview"
          />
        );
      case fileType === "video" || item?.type === "mp4":
        return (
          <video
            controls
            controlsList="nodownload"
            src={item instanceof File ? URL.createObjectURL(item) : item?.url}
          >
            <source
              src={item instanceof File ? URL.createObjectURL(item) : item?.url}
            />
          </video>
        );
      case fileType === "audio" || item?.type === "mp3":
        return (
          <AudioPreview
            fileName={state?.audioName[idx]?.fileName}
            item={item}
            bgColor={"bg-grey-50"}
          />
        );
      case item?.type === "link":
        return <LinkPreview link={item?.url} />;
      default:
        return null;
    }
  };

  const renderedMedia = useMemo(
    () =>
      state.media.map((item, idx) => {
        let fileType;
        if (item instanceof File) {
          fileType = utility.getFileType(item.name);
        }

        return (
          <div key={idx} className="flex gap-5 items-center py-3 w-50per">
            {renderMedia(item, fileType, idx)}
            <div className="flex gap-5">
              {item?.type !== "link" &&
                fileType === "image" &&
                fileType !== "gif" && (
                  <Crop
                    onClick={() => {
                      setImagePopup({
                        popup: true,
                        index: idx,
                      });
                      setSelectedFile(item);
                    }}
                    className="cursor-pointer"
                  />
                )}

              {item?.type !== "link" && fileType === "video" && (
                <Crop
                  onClick={() => {
                    setVideoPopup({
                      popup: true,
                      index: idx,
                    });
                    setSelectedFile(item);
                  }}
                  className="cursor-pointer"
                />
              )}
              {(fileType === "audio" || item?.type === "mp3") && (
                <Edit
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenMedia("EDIT");
                    setFiles([item]);
                    setAudioValues({
                      name: state?.audioName[idx]?.fileName,
                      index: idx,
                    });
                  }}
                />
              )}
              <Delete
                onClick={() => handleDeleteClick(idx)}
                className="cursor-pointer"
              />
            </div>
          </div>
        );
      }),
    // eslint-disable-next-line
    [state.media, state.audioName]
  );

  /**
   * Renders the description based on the selected poll type.
   *
   * @returns {string} Description text for the selected poll type.
   */
  const renderPollDescription = () => {
    let description = "";
    switch (state?.questionType) {
      case EnumValues.POLL:
        description = pollDescription.POLL;
        break;
      case EnumValues.RANKING:
        description = pollDescription.RANKING;
        break;
      case EnumValues.OPEN_QUESTION:
        description = pollDescription.OPEN_QUESTION;
        break;
      case EnumValues.TWO_SIDED:
        description = pollDescription.TWO_SIDED;
        break;
      case EnumValues.SURVEY:
        description = pollDescription.SURVEY;
        break;
      default:
    }
    return description;
  };

  return (
    <div className="mt-15 flex flex-col">
      <div className="flex gap-4 w-full">
        <div className="flex flex-col gap-15 w-1/2">
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Category
            </label>
            <div>
              <Dropdown
                options={categoryOption}
                placeHolder={"Not choosen"}
                value={state?.questionType}
                disabled={pollId}
                setValue={handleCategoryOption}
              />
            </div>
            <span className="text-ft3 font-ManropeMedium text-grey-550">
              {renderPollDescription()}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              Type
            </label>
            <div>
              <div className="relative w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25">
                <div className="w-full h-full flex justify-between items-center cursor-pointer">
                  <span
                    className={`w-full h-full flex items-center font-ManropeRegular 
                     text-black-100
                     text-ft3`}
                  >
                    {pollTypeOption[0]}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <div className="flex justify-between gap-1">
              <label
                htmlFor=""
                className="text-black-100 text-ft3 font-ManropeMedium"
              >
                Are you creating this poll for some entity?
              </label>
              <div>
                <ToggleSwitch
                  setValue={() =>
                    setState((prev) => ({
                      ...prev,
                      isForPollpapa: !state?.isForPollpapa,
                    }))
                  }
                  disable={pollId}
                  value={state?.isForPollpapa}
                />
              </div>
            </div>
            {state?.isForPollpapa && (
              <div className="flex flex-col gap-1 pt-5">
                <label
                  htmlFor=""
                  className="text-black-100 text-ft3 font-ManropeMedium"
                >
                  Select Entity
                </label>
                <div className="relative">
                  <OutsideClickRef state={dropdownRef} setState={setOpenEntity}>
                    <div
                      onClick={() => {
                        !pollId && setOpenEntity(!openEntity);
                      }}
                    >
                      <div className="relative w-full rounded-full bg-grey-50 outline-none  px-6  h-16.25">
                        <div className="w-full h-full flex justify-between items-center cursor-pointer">
                          {!!account ? (
                            <div
                              className={`w-full h-full flex items-center gap-3 font-ManropeRegular  text-black-100 text-ft3`}
                            >
                              <img
                                src={account?.profilePhoto}
                                alt=""
                                className="w-6 h-6 rounded-full"
                              />
                              <span> {account?.fullName}</span>
                            </div>
                          ) : (
                            <span className="text-ft3 text-black-75 w-full h-full flex items-center font-ManropeRegular">
                              Not choosen
                            </span>
                          )}
                          {!openEntity ? (
                            <img src={DownArrow} alt="" className="w-6 h-6" />
                          ) : (
                            <img src={UpArrow} alt="" className="w-6 h-6" />
                          )}
                        </div>
                      </div>
                    </div>
                    {openEntity && (
                      <SelectEntityPopup
                        setAccount={setAccount}
                        setOpenEntity={setOpenEntity}
                      />
                    )}
                  </OutsideClickRef>
                </div>
              </div>
            )}
          </div> */}

          <div className="flex flex-col gap-1 pt-5">
            <label
              htmlFor=""
              className="text-black-100 text-ft3 font-ManropeMedium"
            >
              {state?.questionType === "Survey" ? "Survey name" : "Question"}
            </label>
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-1">
                <input
                  type="text"
                  placeholder={
                    state?.questionType === "Survey"
                      ? "Survey name"
                      : "Question"
                  }
                  value={state?.question}
                  onChange={(e) => {
                    if (state?.question.length > 150) {
                      setErrors({
                        ...errors,
                        question: "Question should be less than 150 characters",
                      });
                    } else {
                      setErrors({
                        ...errors,
                        question: "",
                      });
                    }
                    setState((prev) => ({ ...prev, question: e.target.value }));
                  }}
                  className=" rounded-full bg-grey-50 outline-none  px-6  h-16.25 text-black-100 font-ManropeRegular text-ft3"
                />
                {errors?.question && (
                  <div className="text-orange-100 text-ft2 font-ManropeLight mt-1">
                    {errors?.question}
                  </div>
                )}
              </div>
              {state?.questionType !== "Two-Sided Question" && (
                <PreviewTagsMentions
                  error={errors?.description}
                  value={state?.description}
                  onChange={handleOnChangeDescription}
                />
              )}
            </div>

            {state?.media?.length > 0 && (
              <div className="pt-5 pb-5 flex flex-col gap-5">
                {renderedMedia}
              </div>
            )}
            {state?.questionType !== "Two-Sided Question" &&
              state?.media?.length < 6 && (
                <div
                  onClick={() => {
                    setOpenMedia("MEDIA");
                  }}
                  className="flex gap-2.5 items-center cursor-pointer pt-5"
                >
                  <AddImage />
                  <span className="text-orange-50 font-ManropeBold text-ft2">
                    {state?.questionType === "Survey"
                      ? "Add Cover"
                      : "Add Media"}
                  </span>
                </div>
              )}
          </div>
        </div>
        {state?.reason?.length > 0 && (
          <div className="bg-red-75 rounded-xl p-5 h-max-content w-1/2 flex flex-col gap-1 text-ft1">
            <span className="text-ft2 font-ManropeBold text-black-100">
              Reason:
            </span>
            {state?.reason?.map((item, idx) => (
              <span
                key={idx}
                className="text-ft2 font-ManropeRegular text-black-100"
              >
                {item?.description}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="flex justify-end mt-15 gap-7.5">
        <button
          onClick={() => history.push("/poll-list")}
          className="border border-black-100 border-opacity-20 font-ManropeBold rounded-full w-55 h-16.25 text-ft2"
        >
          Cancel
        </button>
        <button
          disabled={
            !state?.question ||
            !!errors?.description ||
            !!errors?.question ||
            (state?.isForPollpapa && !account)
          }
          onClick={() => {
            if (state?.questionType !== "Open Question") {
              setSteps(1);
            } else {
              setSteps(2);
            }
          }}
          className={`${
            !state?.question ||
            !!errors?.description ||
            !!errors?.question ||
            (state?.isForPollpapa && !account)
              ? "bg-grey-50"
              : "bg-orange-50"
          } rounded-full w-55 h-16.25 font-ManropeBold text-ft2`}
        >
          Next
        </button>
      </div>
      {openMedia === "MEDIA" && (
        <AdddMedia
          setOpenMedia={setOpenMedia}
          setImagePopup={setImagePopup}
          setSelectedFile={setSelectedFile}
          openMedia={openMedia}
          setFiles={setFiles}
          files={files}
          handleAddMedia={handleAddMedia}
          type={state?.questionType === "Survey" ? "survey" : "poll"}
        />
      )}
      {openMedia === "LINK" && (
        <LinkPopup
          setOpenMedia={setOpenMedia}
          handleAddMedia={handleAddMedia}
          state={state}
        />
      )}
      {openMedia === "PREVIEW" && (
        <PreviewPopup
          state={state}
          files={files.length > 0 ? files : []}
          setOpenMedia={setOpenMedia}
          setFiles={setFiles}
          handleAddMedia={handleAddMedia}
          setState={setState}
          isScriptLoaded={isScriptLoaded}
          ffmpeg={ffmpeg}
        />
      )}
      {openMedia === "EDIT" && (
        <AudioFileName
          state={state}
          files={files.length > 0 ? files : []}
          setOpenMedia={setOpenMedia}
          setFiles={setFiles}
          handleEditName={handleEditName}
          index={audioValues?.index}
          audioName={audioValues?.name}
          setState={setState}
        />
      )}
      {imagePopup.popup && (
        <CropImage
          setState={setState}
          file={selectFile}
          setImagePopup={setImagePopup}
          index={imagePopup.index}
          handleCroppedImage={
            imagePopup.index?.toString()
              ? handleAddBrowseMedia
              : handleCroppedImageInitial
          }
        />
      )}
      {videoPopup.popup && (
        <CropVideo
          setState={setState}
          file={selectFile}
          setVideoPopup={setVideoPopup}
          index={videoPopup.index}
          isScriptLoaded={isScriptLoaded}
          ffmpeg={ffmpeg}
          handleAddMedia={handleAddBrowseMedia}
        />
      )}
    </div>
  );
};

export default PollDetails;
