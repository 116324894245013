import React, { useEffect, useRef, useState } from "react";
import more from "../../../assets/Icons/More.svg";
import error from "../../../assets/Icons/Error.svg";
import Table from "../../../common/table";
import placeholder from "../../../assets/Icons/Placeholder.svg";
import moment from "moment";
import { UserService } from "../../../services";
import DisablePopUp from "../../../common/disablePopUp";
import utility from "../../../utility";
import OutsideClickRef from "../../../common/outsideClickRef";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PlaceholderWnm from "../../../assets/Icons/PlaceholderWnm.png";

/**
 * React component for rendering a list of comments.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {Array} props.commentList - The list of comments to be displayed.
 * @param {boolean} props.loading - Indicates if the comments are still loading.
 * @param {Function} props.setLimitComment - Function to set the limit of comments per page.
 * @param {Function} props.setSortComment - Function to set the sorting order of comments.
 * @param {number} props.limitComment - The limit of comments per page.
 * @param {string} props.sortComment - The sorting order of comments.
 * @param {number} props.totalPages - The total number of pages.
 * @param {Function} props.setCurrentPage - Function to set the current page.
 * @param {number} props.totalDocs - The total number of comments.
 * @param {Function} props.getAccountCommentList - Function to get the account comment list.
 * @returns {React.ReactNode} - The rendered component.
 */
const Comments = ({
  setCurrentPageComment,
  currentPageComment,
  debounceValueComment,
  getUserDetails,
  suspendedState,
  suspendedName,
  searchQuery,
  showComment,
}) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDisablePopUp, setDisablePopUp] = useState(null);
  const [commentId, setCommentId] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [sortComment, setSortComment] = useState(
    Number(queryParams.get("sort")) || -1
  );
  const [loadingComment, setLoadingComment] = useState(false);
  const { id } = useParams();
  const [totalDocsComment, setTotalDocsComment] = useState(0);
  const [limitComment, setLimitComment] = useState(25);
  const totalPagesComment = Math.ceil(totalDocsComment / limitComment);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.roleDetails?.access;

  const optionsRef = useRef(null);

  const headers = [
    { name: "Comment" },
    { name: "Created", sort: true },
    { name: "Creater" },
  ];

  const handlePageChangeComment = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPagesComment) {
      setCurrentPageComment(pageNumber);
    }
  };
  /**
   * Handles the click on the "More" button for a specific comment.
   *
   * @param {string} value - The ID of the comment.
   * @returns {void}
   */
  const handleMoreClick = (value) => {
    if (selectedUser === value) {
      setSelectedUser(null);
    } else {
      setSelectedUser(value);
    }
  };

  /**
   * Renders the body of the comments table.
   *
   * @returns {React.ReactNode} - The rendered table body.
   */
  const renderTableBody = () => {
    if (commentList.length > 0) {
      return commentList.map((value) => (
        <tr key={value?._id} className="hover:bg-grey-350">
          <td
            className={`text-black-100 font-ManropeMedium text-ft2  py-4 w-3/6 px-5`}
          >
            <div className="w-85 truncate">{value.description || "-"}</div>
          </td>
          <td className={`text-black-100 font-ManropeMedium text-ft2`}>
            <div className="w-30 truncate">
              {moment(value.addedOn).format("DD MMM[,] YYYY")}
            </div>
          </td>
          <td>
            <div className="w-37.5 truncate">{value.creator}</div>
          </td>

          <td>
            <div className="relative">
              {userPermissions?.indexOf("user:write:comment") > -1 && (
                <div className="w-6.25">
                  <img
                    src={more}
                    alt="More"
                    className="cursor-pointer"
                    onClick={() => handleMoreClick(value?._id)}
                  />
                </div>
              )}
              {selectedUser === value?._id && (
                <OutsideClickRef
                  state={optionsRef}
                  setState={() => setSelectedUser(null)}
                >
                  <div className="absolute right-0 top-7.75 flex flex-col items-start w-75 rounded-20 shadow-inner z-10 bg-white">
                    <button
                      onClick={() => {
                        setDisablePopUp(!isDisablePopUp);
                        setCommentId(value?._id);
                      }}
                      className="flex h-16.25 px-5 items-center gap-3.75 self-stretch"
                    >
                      <img src={error} alt="error" />
                      <p className="text-ft3 font-ManropeLight text-black-100">
                        Disable Comments
                      </p>
                    </button>
                  </div>
                </OutsideClickRef>
              )}
            </div>
          </td>
        </tr>
      ));
    } else if (!loadingComment) {
      return (
        <tr className="h-57vh">
          <td colSpan={5}>
            <div className="flex flex-col justify-center items-center flex-shrink-0 self-center">
              {searchQuery ? (
                <>
                  <img
                    src={PlaceholderWnm}
                    alt="placeholder"
                    className="mt-36.5"
                  />
                  <p className="text-black-100 font-ManropeBold text-ft4 w-55 text-center pt-7.5">
                    No results were found for your search
                  </p>
                  <p className="text-black-50 font-ManropeMedium text-ft3 w-55 text-center opacity-60 pt-4">
                    Try to change your request
                  </p>
                </>
              ) : (
                <img src={placeholder} alt="placeholder" className="mt-5per" />
              )}
            </div>
          </td>
        </tr>
      );
    }
  };

  const getAccountCommentList = async () => {
    setLoadingComment(true);
    const requestParams = {
      searchKey: debounceValueComment,
      sort: sortComment,
      limit: limitComment,
      skip: currentPageComment,
    };
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().userAccountComment(id, requestParams)
      );

      if (error || !response) return;
      setCommentList(response.comments);

      setTotalDocsComment(response?.totalCount);
      setLoadingComment(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingComment(false);
    }
  };

  const setAllQuery = () => {
    queryParams.set("sort", sortComment);
    history.replace({
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    getAccountCommentList();
    setAllQuery();
    // eslint-disable-next-line
  }, [id, debounceValueComment, sortComment, limitComment, currentPageComment]);

  return (
    <>
      <Table
        headers={headers}
        tableBody={renderTableBody()}
        loading={loadingComment}
        pageSize={limitComment}
        sort={sortComment}
        setPageSize={setLimitComment}
        setSort={setSortComment}
        totalPages={totalPagesComment}
        totalDocs={totalDocsComment}
        setCurrentPage={setCurrentPageComment}
        handlePageChange={handlePageChangeComment}
        currentPage={currentPageComment}
      />
      {isDisablePopUp && (
        <DisablePopUp
          setToggle={setDisablePopUp}
          keyName={"comment"}
          selectedId={commentId}
          getDetails={getAccountCommentList}
          updateUser={getUserDetails}
          name="Disable comment"
          suspendedState={suspendedState}
          suspendedName={suspendedName}
        />
      )}
    </>
  );
};

export default Comments;
