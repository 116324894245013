/**
 * @module PollDetails
 * @description
 * Module for managing and rendering PollDetails.
 *
 * @example
 * // Usage in a React functional component
 * import PollDetails from "./PollDetails";
 *
 * const MyComponent = () => {
 *   return <PollDetails />;
 * };
 *
 * @returns {React.Component} The PollDetails component
 */

import React, { useEffect, useRef, useState } from "react";
import Left from "../../../assets/Icons/Left.svg";
import RejectPopup from "./rejectPopup";
import utility from "../../../utility";
import { CircularProgress } from "@mui/material";
import ShowToast from "../../../common/showToast";
import { keyTypeConstants, toastType } from "../../../constant/commonArray";
import more from "../../../assets/Icons/More.svg";
import moment from "moment";
import { UserService } from "../../../services";
import OutsideClickRef from "../../../common/outsideClickRef";
import Edit from "../../../assets/Icons/Edit.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PreviewPollPopup from "../previewPoll";
import ImageViewer from "../../../common/component/imageViewer";
import ContentCreationService from "../../../services/contentCreationService";

const PollDetails = () => {
  const [preview, setPreview] = useState(false);
  const [reject, setReject] = useState(false);
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const optionRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [state, setState] = useState({
    rejectReason: "",
  });
  const [upadting, setUpdating] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const userPermissions = user?.roleDetails?.access;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const [openPopup, setOpenPopup] = useState(false);
  const [openImage, setOpenImage] = useState("");
  const getDetails = async () => {
    setLoading(true);
    try {
      const [error, response] = await utility.parseResponse(
        new UserService().pollDetailsManagement(id)
      );
      if (error || !response) return;
      setDetails(response[0]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const pollApproved = async (status) => {
    setUpdating(true);
    try {
      let requestData = {};
      if (status === keyTypeConstants.APPROVED) {
        requestData = {
          approvedBySA: status,
          approverAdminId: user?.staffId,
        };
      } else {
        requestData = {
          approvedBySA: status,
          approverAdminId: user?.staffId,
          ...state,
        };
      }
      const [error, response] = await utility.parseResponse(
        new ContentCreationService().approvedPoll(id, requestData)
      );
      if (error || !response) return;
      if (response?.responseCode === 200) {
        ShowToast({
          type: toastType.SUCCESS,
          message: response?.message,
        });
        setReject(false);
        setState({
          rejectReason: "",
        });
        getDetails();
      } else {
        ShowToast({
          type: toastType.ERROR,
          message: response?.message,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  const getStatusBackgroundColor = () => {
    if (details?.reviewStatus?.includes("PENDING")) {
      return "bg-blue-100";
    } else if (details?.reviewStatus?.includes("REJECT")) {
      return "bg-red-75";
    } else {
      return "bg-green-50";
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center  items-center h-full w-full">
        <CircularProgress className="text-black-100 w-5 h-5" />
      </div>
    );
  }

  return (
    <div className="flex gap-5 w-full py-5">
      <div className="flex flex-col w-75per gap-5">
        <div className="flex items-center w-full p-7.5 rounded-20 bg-white justify-between relative">
          <div className="flex gap-7.5 items-center w-full">
            <div className="w-4 h-4 flex flex-shrink-0">
              <div
                onClick={() => {
                  if (haveAudit) {
                    history.push("/audit-log");
                  } else {
                    history.push("/poll-list");
                  }
                }}
              >
                <img src={Left} alt="left" className="cursor-pointer" />
              </div>
            </div>
            <p className="text-black-150 break-words font-ManropeBold text-ft5">
              {details?.post?.surveyName || details?.post?.question}
            </p>
          </div>
          {details?.reviewStatus === keyTypeConstants.ADMIN_REJECT &&
            userPermissions?.indexOf("poll:write:edit") > -1 && (
              <div className="relative">
                <div onClick={() => setIsExpanded(!isExpanded)}>
                  <img src={more} alt="" className="cursor-pointer" />
                </div>
                {isExpanded && (
                  <OutsideClickRef
                    state={optionRef}
                    setState={() => setIsExpanded(false)}
                    className="w-full"
                  >
                    <div
                      onClick={() =>
                        history.push(`/poll-list/create?pollId=${id}`)
                      }
                      className="absolute w-40 right-2.5 rounded-20 shadow-inner z-10 bg-white cursor-pointer"
                    >
                      <button className="flex h-16.25 px-5 items-center gap-3.75 self-stretch">
                        <img src={Edit} alt="Pause" />
                        <p className="text-ft3 font-ManropeMedium text-black-100">
                          Edit
                        </p>
                      </button>
                    </div>
                  </OutsideClickRef>
                )}
              </div>
            )}
        </div>
        <div className="flex flex-col w-full rounded-20 bg-white">
          <p className="text-black-100 font-ManropeBold text-ft42 px-10 py-5">
            General
          </p>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Category</p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {utility.capitalizeFirstLetter(
                  details?.post?.questionType || details?.post?.surveyName
                )}
              </p>
            </div>
          </div>
          <hr className="text-grey-50" />

          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Type</p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {utility.capitalizeFirstLetter(
                  details?.post?.pollType || details?.post?.surveyType
                )}{" "}
                {utility.capitalizeFirstLetter(details?.type)}
              </p>
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Entity</p>
            <div className="flex gap-3 items-center">
              <img
                src={details?.creator?.profilePhoto}
                alt=""
                className="w-6 h-6 rounded-full"
              />
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {details?.creator?.fullName}
              </p>
            </div>
          </div>

          {details?.type !== "SURVEY" && (
            <>
              <hr className="text-grey-50" />
              <div className="flex justify-between px-10 py-6">
                <p className="text-black-100 font-ManropeBold text-ft3">
                  Questions
                </p>
                <div className="flex gap-7.5">
                  <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                    {details?.post?.question}
                  </p>
                </div>
              </div>
            </>
          )}
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">
              Add rewards to poll
            </p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {details?.isRewardAdded ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
          <hr className="text-grey-50" />
        </div>
        {details?.type !== "SURVEY" &&
          details?.post?.questionType !== "OPEN_QUESTION" && (
            <div className="flex flex-col w-full rounded-20 bg-white">
              <p className="text-black-100 font-ManropeBold text-ft42 px-10 py-5">
                Options
              </p>
              <hr className="text-grey-50" />
              {details?.post?.questionType !== "TWO_SIDED" && (
                <div className="flex justify-between px-10 py-6">
                  <p className="text-black-100 font-ManropeBold text-ft3">
                    Option Type
                  </p>
                  <div className="flex gap-7.5">
                    <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                      {utility.capitalizeFirstLetter(
                        details?.post?.options?.type
                      )}
                    </p>
                  </div>
                </div>
              )}

              {details?.post?.questionType !== "TWO_SIDED"
                ? details?.post?.options?.answers?.map((item, idx) => (
                    <div key={idx}>
                      <hr className="text-grey-50" />
                      <div className="flex justify-between px-10 py-6">
                        <p className="text-black-100 whitespace-nowrap font-ManropeBold text-ft3">
                          Option {idx + 1}
                        </p>
                        <div className="flex gap-7.5">
                          {details?.post?.options?.type !== "IMAGE" ? (
                            <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                              {item.value}
                            </p>
                          ) : (
                            <img
                              onClick={() => {
                                setOpenPopup(!openPopup);
                                setOpenImage(item?.value);
                              }}
                              src={item.value}
                              alt=""
                              className="w-20 h-20 rounded-20 cursor-pointer"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                : details?.post?.options?.answers?.map((item, idx) => (
                    <div key={idx} className="flex flex-col">
                      <hr className="text-grey-50" />
                      <div className="flex justify-between px-10 py-6">
                        <p className="text-black-100 font-ManropeBold text-ft3">
                          {idx === 0 ? "First Side" : "Second Side"}
                        </p>
                      </div>
                      <hr className="text-grey-50" />
                      <div className="flex justify-between px-10 py-6">
                        <p className="text-black-100 font-ManropeBold text-ft3">
                          Title
                        </p>
                        <div className="flex gap-7.5">
                          <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                            {item.title}
                          </p>
                        </div>
                      </div>
                      <hr className="text-grey-50" />
                      <div className="flex justify-between px-10 py-6">
                        <p className="text-black-100 font-ManropeBold text-ft3">
                          Description
                        </p>
                        <div className="flex gap-7.5">
                          <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                            {item.description}
                          </p>
                        </div>
                      </div>
                      <hr className="text-grey-50" />
                      <div className="flex justify-between px-10 py-6">
                        <p className="text-black-100 font-ManropeBold text-ft3">
                          Cover
                        </p>
                        <div className="flex gap-7.5">
                          <img
                            onClick={() => {
                              setOpenPopup(!openPopup);
                              setOpenImage(item?.cover);
                            }}
                            src={item.cover}
                            alt=""
                            className="w-20 h-20 rounded-20 cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              <hr className="text-grey-50" />
              <div className="flex justify-between px-10 py-6">
                <p className="text-black-100 font-ManropeBold text-ft3">
                  Availability multiple options
                </p>
                <div className="flex gap-7.5">
                  <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                    {details?.post?.multipleOptions ? "Active" : "Inactive"}
                  </p>
                </div>
              </div>
              <hr className="text-grey-50" />
              <div className="flex justify-between px-10 py-6">
                <p className="text-black-100 font-ManropeBold text-ft3">
                  To set an open answer
                </p>
                <div className="flex gap-7.5">
                  <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                    {details?.post?.openAnswer ? "Active" : "Inactive"}
                  </p>
                </div>
              </div>
              <hr className="text-grey-50" />
            </div>
          )}

        {details?.type === "SURVEY" && (
          <div className="flex flex-col w-full rounded-20 bg-white">
            <p className="text-black-100 font-ManropeBold text-ft42 px-10 py-5">
              Polls
            </p>
            {details?.post?.polls?.map((item, idx) => (
              <div key={idx}>
                <hr className="text-grey-50" />
                <div className="flex justify-between px-10 py-6">
                  <p className="text-black-100 font-ManropeBold text-ft3">
                    Poll {idx + 1}
                  </p>
                </div>
                <hr className="text-grey-50" />
                <div className="flex justify-between px-10 py-6">
                  <p className="text-black-100 font-ManropeBold text-ft3">
                    Question
                  </p>
                  <div className="flex gap-7.5">
                    <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                      {utility.capitalizeFirstLetter(item?.question)}
                    </p>
                  </div>
                </div>
                {item?.questionType !== "TWO_SIDED"
                  ? item?.options?.answers?.map((value, idx) => (
                      <div key={idx}>
                        <hr className="text-grey-50" />
                        <div className="flex justify-between px-10 py-6">
                          <p className="text-black-100 font-ManropeBold text-ft3">
                            Option {idx + 1}
                          </p>
                          <div className="flex gap-7.5">
                            {item?.options?.type !== "IMAGE" ? (
                              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                                {value.value}
                              </p>
                            ) : (
                              <img
                                onClick={() => {
                                  setOpenPopup(!openPopup);
                                  setOpenImage(item?.value);
                                }}
                                src={value.value}
                                alt=""
                                className="w-20 h-20 rounded-20 cursor-pointer"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  : item?.options?.answers?.map((value, idx) => (
                      <div key={idx} className="flex flex-col">
                        <hr className="text-grey-50" />
                        <div className="flex justify-between px-10 py-6">
                          <p className="text-black-100 font-ManropeBold text-ft3">
                            {idx === 0 ? "First Side" : "Second Side"}
                          </p>
                        </div>
                        <hr className="text-grey-50" />
                        <div className="flex justify-between px-10 py-6">
                          <p className="text-black-100 font-ManropeBold text-ft3">
                            Title
                          </p>
                          <div className="flex gap-7.5">
                            <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                              {value.title}
                            </p>
                          </div>
                        </div>
                        <hr className="text-grey-50" />
                        <div className="flex justify-between px-10 py-6">
                          <p className="text-black-100 font-ManropeBold text-ft3">
                            Description
                          </p>
                          <div className="flex gap-7.5">
                            <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                              {value.description}
                            </p>
                          </div>
                        </div>
                        <hr className="text-grey-50" />
                        <div className="flex justify-between px-10 py-6">
                          <p className="text-black-100 font-ManropeBold text-ft3">
                            Cover
                          </p>
                          <div className="flex gap-7.5">
                            <img
                              onClick={() => {
                                setOpenPopup(!openPopup);
                                setOpenImage(item?.cover);
                              }}
                              src={value.cover}
                              alt=""
                              className="w-20 h-20 rounded-20 cursor-pointer"
                            />
                          </div>
                        </div>
                      </div>
                    ))}

                <hr className="text-grey-50" />
                <div className="flex justify-between px-10 py-6">
                  <p className="text-black-100 font-ManropeBold text-ft3">
                    Availability multiple options
                  </p>
                  <div className="flex gap-7.5">
                    <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                      {item?.multipleOptions ? "Active" : "Inactive"}
                    </p>
                  </div>
                </div>
                <hr className="text-grey-50" />
                <div className="flex justify-between px-10 py-6">
                  <p className="text-black-100 font-ManropeBold text-ft3">
                    To set an open answer
                  </p>
                  <div className="flex gap-7.5">
                    <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                      {item?.openAnswer ? "Active" : "Inactive"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-col w-full rounded-20 bg-white">
          <p className="text-black-100 font-ManropeBold text-ft42 px-10 py-5">
            Settings
          </p>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">Channels</p>
            <div className="flex gap-2">
              {details?.channels?.map((item, idx) => (
                <p
                  key={idx}
                  className="font-ManropeRegular text-ft3 ml-2 text-black-600"
                >
                  {item}
                  {details?.channels?.length - 1 === idx ? "" : ","}
                </p>
              ))}
            </div>
          </div>
          <hr className="text-grey-50" />

          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">
              Start date
            </p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {moment(details?.info?.activePeriod?.startDate).format(
                  "DD MMM[,] YYYY HH:mm"
                )}
              </p>
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">End date</p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {moment(details?.info?.activePeriod?.endDate).format(
                  "DD MMM[,] YYYY HH:mm"
                )}
              </p>
            </div>
          </div>

          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">
              Reward amount
            </p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {details?.info?.reward?.budgetLimit ? "$" : ""}
                {details?.info?.reward?.budgetLimit || "-"}
              </p>
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">
              Users to be rewarded
            </p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {details?.info?.reward?.rewardPerVote || "-"}
              </p>
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">
              Sensitive content
            </p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {details?.isSensitive ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
          <hr className="text-grey-50" />
          <div className="flex justify-between px-10 py-6">
            <p className="text-black-100 font-ManropeBold text-ft3">
              Anonymous poll
            </p>
            <div className="flex gap-7.5">
              <p className="font-ManropeRegular text-ft3 ml-2 text-black-600">
                {details?.info?.isAnonymous ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
          <hr className="text-grey-50" />
        </div>
      </div>

      <div className="flex flex-col w-25per gap-5">
        <div className="flex flex-col bg-white rounded-20 p-5 gap-3.75">
          <div className="flex justify-between">
            <p className="text-ft2 font-ManropeRegular text-black-600">
              Status
            </p>
            <p
              className={`text-black-100 ${getStatusBackgroundColor()}  rounded-30 px-3 py-1.5 font-ManropeMedium text-ft2 whitespace-nowrap`}
            >
              {utility.formatText(details?.reviewStatus)}
            </p>
          </div>
          {details?.reviewStatus === keyTypeConstants.ADMIN_REJECT && (
            <div className="bg-red-75 rounded-xl p-5 w-full flex flex-col gap-1 text-ft1">
              <span className="text-ft2 font-ManropeBold text-black-100">
                Reason:
              </span>
              {details?.rejectReason?.map((item, idx) => (
                <span
                  key={idx}
                  className="text-ft2 font-ManropeRegular text-black-100"
                >
                  {item?.description}
                </span>
              ))}
            </div>
          )}
          <div className="flex flex-wrap justify-between gap-1">
            <p className="text-ft2 font-ManropeRegular text-black-600">
              Created On
            </p>
            <p className="text-black-100 font-ManropeBold text-ft2 whitespace-nowrap">
              {moment(details?.addedOn).format("DD MMM[,] YYYY HH:mm")}
            </p>
          </div>
          <div className="flex flex-wrap justify-between">
            <p className="text-ft2 font-ManropeRegular text-black-600">
              Creator
            </p>
            <p className="text-black-100 font-ManropeBold text-ft2">
              {details?.creator?.fullName}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-ft2 font-ManropeRegular text-black-600">
              Reward
            </p>
            <p className="text-black-100 font-ManropeBold text-ft2">
              {" "}
              {details?.info?.reward?.budgetLimit ? "$" : ""}
              {details?.info?.reward?.budgetLimit || "-"}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-ft2 font-ManropeRegular text-black-600">Users</p>
            <p className="text-black-100 font-ManropeBold text-ft2">
              {" "}
              {details?.info?.reward?.rewardPerVote || "-"}
            </p>
          </div>

          <div
            onClick={() => setPreview(!preview)}
            className="flex justify-center cursor-pointer"
          >
            <p className="text-orange-50 font-ManropeBold text-ft3">Preview</p>
          </div>
        </div>
        {details?.reviewStatus === keyTypeConstants.ADMIN_PENDING &&
          userPermissions?.indexOf("poll:write:approve") > -1 && (
            <div className="flex gap-3">
              <button
                onClick={() => setReject(true)}
                className="h-16.25 w-full rounded-full border border-black-100 border-opacity-20 font-ManropeBold text-ft2 text-black-100 bg-transparent"
              >
                Reject
              </button>
              <button
                onClick={() => pollApproved(keyTypeConstants.APPROVED)}
                className="h-16.25 w-full rounded-full font-ManropeBold text-ft2 text-black-100 bg-orange-50"
              >
                Accept
              </button>
            </div>
          )}
      </div>
      {preview && (
        <PreviewPollPopup setPreview={setPreview} data={details} account={{}} />
      )}
      {reject && (
        <RejectPopup
          setReject={setReject}
          setState={setState}
          state={state}
          pollApproved={pollApproved}
          upadting={upadting}
        />
      )}
      {openPopup && (
        <ImageViewer
          toggleImagePopUp={openPopup}
          setToggleImagePopUp={setOpenPopup}
          image={openImage}
        />
      )}
    </div>
  );
};

export default PollDetails;
