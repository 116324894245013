/**
 * @module ActivityPointComponent
 * @description
 * Module for managing and rendering activityPointComponent.
 *
 * @example
 * // Usage in a React functional component
 * import ActivityPointComponent from "./ActivityPointComponent";
 *
 * const MyComponent = () => {
 *   return <ActivityPointComponent />;
 * };
 *
 * @returns {React.Component} The ActivityPointComponent component
 */

import React from "react";
import ActivityList from "./activityList";
import left from "../../assets/Icons/Left.svg";
import { useLocation, useHistory } from "react-router-dom";

const ActivityPointComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const haveAudit = queryParams.get("from");
  const history = useHistory();
  return (
    <div className="w-full h-full pr-12.5 py-8.75 overflow-y-auto">
      {haveAudit && (
        <div
          onClick={() => {
            if (haveAudit) {
              history.push("/audit-log");
            }
          }}
          className="pb-3"
        >
          <img src={left} alt="left" className="cursor-pointer" />
        </div>
      )}
      <ActivityList />
    </div>
  );
};

export default ActivityPointComponent;
