import React from "react";
import { ReactComponent as Close } from "../assets/Icons/close.svg";
import { Languages } from "../constant/commonArray";
import utility from "../utility";

/**
 * PreviewPopUp component displays a preview of the content with language options.
 *
 * @component
 * @example
 * // Example usage of PreviewPopUp component
 * <PreviewPopUp
 *   previewPopUp={isPreviewPopUpOpen}
 *   setPreviewPopUp={setIsPreviewPopUpOpen}
 *   state={previewState}
 *   account={userAccount}
 *   croppedImage={imageURL}
 * />
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.previewPopUp - State indicating whether the preview pop-up is open.
 * @param {function} props.setPreviewPopUp - A function to set the state of the preview pop-up.
 * @param {Object} props.state - The state object containing language, heading, and description.
 * @param {Object} props.account - The user account object.
 * @param {string} props.croppedImage - The URL of the cropped image.
 *
 * @returns {JSX.Element} The rendered PreviewPopUp component.
 */

const PreviewPopUp = ({
  previewPopUp,
  setPreviewPopUp,
  state,
  account,
  croppedImage,
}) => {
  const content = "Sponsored";

  return (
    <div className="bg-grey-100 bg-opacity-70  fixed flex justify-center items-center right-0 top-0 h-full w-full">
      <div className="bg-white flex flex-col h-70per w-1/2 z-50 p-7.5 rounded-20">
        <div className="flex justify-between items-center">
          <p className="text-black-100 font-ManropeBold text-ft4">Preview</p>
          <Close
            onClick={() =>
              setPreviewPopUp != null && setPreviewPopUp(!previewPopUp)
            }
            className="cursor-pointer"
          />
        </div>
        <div className="flex py-5 gap-2.5 overflow-x-scroll scrollbar-hide overflow-y-hidden">
          {Languages.map((value) => (
            <div
              className={`flex items-center rounded-20 px-2 pb-1.5 pt-1.25 h-8.75 text-ft2 text-black-100 cursor-pointer ${
                value.name === utility.capitalizeFirstLetter(state?.language)
                  ? "bg-orange-50 font-ManropeBold"
                  : "bg-grey-50 font-ManropeRegular"
              }`}
            >
              {value.name}
            </div>
          ))}
        </div>
        <div className="flex pt-5 gap-5">
          <div
            style={{
              backgroundImage: `url(${croppedImage})`,
            }}
            className="flex flex-col justify-between w-2/3 h-100 bg-no-repeat bg-cover rounded-20 p-5"
          >
            <div className="flex justify-end">
              <p className="rounded-20 bg-grey-300 blur-background font-ManropeMedium text-ft0 text-white px-2 py-0.75">
                {content}
              </p>
            </div>
            <div className="flex flex-col gap-7.5">
              <div className="flex flex-col gap-3.75">
                {!!account && (
                  <div className="flex gap-3 items-center">
                    <img
                      className="w-11 h-11 rounded-20"
                      src={account?.profilePhoto}
                      alt="netflix"
                    />
                    <p className="text-white font-ManropeBold text-ft2">
                      {account?.fullName}
                    </p>
                  </div>
                )}
                <p className="text-ft5 font-ManropeBold text-white">
                  {state?.heading?.length > 40
                    ? state?.heading.slice(0, 40) + "..."
                    : state?.heading || "-"}
                </p>
                <p className="font-ManropeRegular text-ft3 text-white">
                  {state?.description?.length > 120
                    ? state?.description.slice(0, 120) + "..."
                    : state?.description || "-"}
                </p>
              </div>
              <button className="w-full bg-orange-50 rounded-full p-5 h-16.25 font-ManropeBold text-black-100 text-ft2">
                View
              </button>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${croppedImage})`,
            }}
            className="flex flex-col justify-between w-1/3 h-97.5 bg-cover bg-no-repeat rounded-20 p-5"
          >
            <div className="flex justify-end">
              <p className="rounded-20 bg-grey-300 blur-background font-ManropeMedium text-ft0 text-white px-2 py-0.75">
                {content}
              </p>
            </div>
            <div className="flex flex-col gap-3.75">
              <div className="flex flex-col gap-4.25">
                {!!account && (
                  <div className="flex gap-3 items-center">
                    <img
                      className="w-11 h-11 rounded-20"
                      src={account?.profilePhoto}
                      alt="netflix"
                    />
                    <p className="text-white font-ManropeBold text-ft2">
                      {account?.fullName}
                    </p>
                  </div>
                )}
                <p className="text-ft4 font-ManropeBold text-white h-10 truncate">
                  {state?.heading || "-"}
                </p>
                <p className="font-ManropeRegular text-ft3 text-white h-10 truncate">
                  {state?.description || "-"}
                </p>
              </div>
              <button className="w-full bg-orange-50 rounded-full p-5 h-14 font-ManropeBold text-black-100 text-ft2">
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PreviewPopUp;
