import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import ImageLogo from "../../../../../../assets/Icons/image-logo.svg";
import { ReactComponent as AddImage } from "../../../../../../assets/Icons/plus-orange.svg";
import ShowToast from "../../../../../../common/showToast";
import {
  failureMessage,
  toastType,
} from "../../../../../../constant/commonArray";
import Cross from "../../../../../../assets/Icons/close-white.svg";

/**
 * TypeImage component for handling image uploads and management within a poll.
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.state - Component state object.
 * @param {Function} props.setState - Function to set component state.
 * @param {number} props.idx - Index of the poll category.
 * @returns {JSX.Element} TypeImage component JSX.
 */
const TypeImage = ({ state, setState, idx }) => {
  const fileInputRef = useRef(null);
  const [index, setIndex] = useState(null);

  const { getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxSize: 50 * 1024 * 1024,
    disablePreview: false,
    disabled: false,

    onDrop: (acceptedFiles, fileRejections) => {
      const fileTypeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-invalid-type"
      );
      const fileSizeError = fileRejections.find(
        (fileRejection) => fileRejection.errors[0].code === "file-too-large"
      );

      if (fileTypeError) {
        ShowToast({
          message: failureMessage.FILE_TYPE_ERROR,
          type: toastType.ERROR,
        });
      } else if (fileSizeError) {
        ShowToast({
          message: failureMessage.FILE_SIZE_EXCEEDED_50MB,
          type: toastType.ERROR,
        });
      } else {
        setState((prevState) => {
          const updatedPolls = [...prevState.polls];
          if (idx >= 0 && idx < updatedPolls.length) {
            const pollToUpdate = { ...updatedPolls[idx] };
            const updatedImages = [...pollToUpdate.images];
            updatedImages[index] = acceptedFiles[0];
            updatedPolls[idx] = {
              ...pollToUpdate,
              images: updatedImages,
            };
          }
          return { ...prevState, polls: updatedPolls };
        });
      }
    },
  });

  /**
   * Handles click event on image placeholder to trigger file input.
   * @param {number} index - Index of the image placeholder.
   */

  const handleSpanClick = (index) => {
    fileInputRef.current.click();
    setIndex(index);
  };

  /**
   * Handles click event to delete an image.
   * @param {number} index - Index of the image to delete.
   */

  const handleDeleteClick = (index) => {
    if (state?.polls[idx]?.images?.length === 1) {
      setState((prevState) => {
        const updatedPollCategory = [...prevState.polls];
        updatedPollCategory[idx].images = [
          { dummyImages: "" },
          {
            dummyImages: "",
          },
        ];
        return { ...prevState, polls: updatedPollCategory };
      });
    } else {
      setState((prevState) => {
        const updatedPollCategory = [...prevState.polls];
        updatedPollCategory[idx].images = updatedPollCategory[
          idx
        ].images.filter((_, i) => i !== index);
        return { ...prevState, polls: updatedPollCategory };
      });
    }
  };

  const handleMoreImage = () => {
    if (state?.polls[idx]?.images?.length <= 5) {
      setState((prevState) => {
        const updatedPollCategory = [...prevState.polls];
        updatedPollCategory[idx].images = [
          ...updatedPollCategory[idx].images,
          { dummyImages: "" },
        ];
        return { ...prevState, polls: updatedPollCategory };
      });
    } else {
      ShowToast({
        message: "You can add only 6 images",
        type: toastType.ERROR,
      });
    }
  };

  useEffect(() => {
    if (state?.polls[idx]?.images?.length === 0) {
      setState((prevState) => {
        const updatedPollCategory = [...prevState.polls];
        updatedPollCategory[idx].images = [
          ...updatedPollCategory[idx].images,
          { dummyImages: "" },
          { dummyImages: "" },
        ];
        return { ...prevState, polls: updatedPollCategory };
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="flex flex-wrap gap-10">
        {state?.polls[idx]?.images?.length > 0 ? (
          state?.polls[idx]?.images?.map((image, index) => (
            <div key={index}>
              {image?.dummyImages !== "" && image ? (
                <div className="flex flex-col relative items-center justify-center w-40 h-40  rounded-20 cursor-pointer">
                  <img
                    src={
                      image instanceof File
                        ? URL.createObjectURL(image)
                        : image.value
                    }
                    alt=""
                    className="rounded-20 w-40 h-40 object-fill"
                  />

                  <div
                    onClick={() => handleDeleteClick(index)}
                    className="w-6 h-6 cursor-pointer rounded-full bg-black-50 p-0.5 absolute top-2 right-2"
                  >
                    <img src={Cross} alt="check" />
                  </div>
                </div>
              ) : (
                <div className="flex relative flex-col w-40 h-40 bg-grey-50 rounded-20 cursor-pointer">
                  <div
                    onClick={() => handleSpanClick(index)}
                    className="flex justify-center items-center flex-col gap-1 h-40"
                  >
                    <img src={ImageLogo} alt="" />
                    <span className="text-black-100 text-ft3 font-ManropeMedium">
                      Add image
                    </span>
                  </div>
                  <input
                    {...getInputProps()}
                    key={state?.polls[idx]?.images?.length}
                    ref={(el) => (fileInputRef.current = el)}
                    type="file"
                    className="hidden"
                    accept=".svg, .png, .jpg"
                    multiple
                  />
                  {state?.polls[idx]?.images?.length > 2 && (
                    <div
                      onClick={() => handleDeleteClick(index)}
                      className="w-6 h-6 cursor-pointer rounded-full bg-black-50 p-0.5 absolute top-2 right-2"
                    >
                      <img src={Cross} alt="check" />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="flex flex-wrap gap-10">
            <div
              onClick={() => handleSpanClick(0)}
              className="flex flex-col items-center justify-center w-40 h-40 bg-grey-50 rounded-20 cursor-pointer"
            >
              <img src={ImageLogo} alt="" />
              <span className="text-black-100 text-ft3 font-ManropeMedium">
                Add image
              </span>
              <input
                {...getInputProps()}
                key={state?.polls[idx]?.images?.length}
                ref={fileInputRef}
                type="file"
                className="hidden"
                accept=".svg, .png, .jpg"
                multiple
              />
            </div>
            <div
              onClick={() => handleSpanClick(1)}
              className="flex flex-col items-center justify-center w-40 h-40 bg-grey-50 rounded-20 cursor-pointer"
            >
              <img src={ImageLogo} alt="" />
              <span className="text-black-100 text-ft3 font-ManropeMedium">
                Add image
              </span>
              <input
                {...getInputProps()}
                key={state?.polls[idx]?.images?.length}
                ref={fileInputRef}
                type="file"
                className="hidden"
                accept=".svg, .png, .jpg"
                multiple
              />
            </div>
          </div>
        )}
      </div>
      <div
        onClick={handleMoreImage}
        className="flex gap-2.5 items-center cursor-pointer pt-5"
      >
        <label>
          <AddImage />
        </label>
        <span className="text-orange-50 font-ManropeBold text-ft2">
          Add more
        </span>
      </div>
    </div>
  );
};

export default TypeImage;
